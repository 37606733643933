export const LatencyChartLabelsByProperty = {
  clientRetransmission: {
    med: 'CLIENT RETRANSMISSION',
    min: 'CLIENT RETRANSMISSION MIN',
    max: 'CLIENT RETRANSMISSION MAX',
  },
  serverRetransmission: {
    med: 'SERVER RETRANSMISSION',
    min: 'SERVER RETRANSMISSION MIN',
    max: 'SERVER RETRANSMISSION MAX',
  },
  roundTrip: {
    med: 'ROUND TRIP',
    min: 'ROUND TRIP MIN',
    max: 'ROUND TRIP MAX',
  },
};

interface HiddenLatencyChartLabelsInterface {
  [key: string]: boolean;
}

export const HiddenLatencyChartLabels: HiddenLatencyChartLabelsInterface = Object.entries(
  LatencyChartLabelsByProperty
).reduce((previousValue, currentValue) => {
  return {
    ...previousValue,
    [currentValue[1].min]: true,
    [currentValue[1].max]: true,
  };
}, {});

interface HiddenLatencyChartLabelsByMainLabelInterface {
  [key: string]: string[];
}

export const HiddenLatencyChartLabelsByMainLabel: HiddenLatencyChartLabelsByMainLabelInterface =
  Object.entries(LatencyChartLabelsByProperty).reduce((previousValue, currentValue) => {
    return {
      ...previousValue,
      [currentValue[1].med]: [currentValue[1].min, currentValue[1].max],
    };
  }, {});

export const LatencyChartTension = 0.3;
