import Divider from '../../components/Divider/Divider';
import Select from 'components/Katana/components/Select/Select';
import IconButton from '../../components/IconButton/IconButton';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import Button from '../../components/Button/Button';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import {getPaginationText} from './utils/getPaginationText';

interface Props {
  canPreviousPage: boolean;
  canNextPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageSize: number;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  pageCount: number;
  pageSizeOptions: number[];
  rowCount: number;
}

export default function Pagination(props: Props) {
  const {
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageIndex,
    gotoPage,
    pageSize,
    pageCount,
    pageSizeOptions,
    rowCount,
  } = props;

  return (
    <div className="flex flex-col gap-2">
      <Divider />
      <div className="text-body-medium flex justify-between px-4 pb-2 text-light-on-surface dark:text-dark-on-surface">
        <div className="flex items-center gap-4">
          <span>Page Size</span>
          <Select
            options={pageSizeOptions.map(o => String(o))}
            onChange={value => setPageSize(Number(value))}
            initialSelectedValue={String(pageSize)}
          />
        </div>
        <div className="flex items-center gap-2">
          <IconButton
            icon={<FirstPageRoundedIcon />}
            label="Go to first page"
            variant="outlined"
            disabled={!canPreviousPage}
            onClick={() => gotoPage(0)}
          />
          <Button
            label="Prev"
            variant="outlined"
            startIcon={<KeyboardBackspaceRoundedIcon />}
            onClick={previousPage}
            disabled={!canPreviousPage}
          />
          <span className="min-w-[125px] text-center">
            {getPaginationText(pageIndex, pageSize, rowCount)}
          </span>
          <Button
            label="Next"
            variant="outlined"
            endIcon={<KeyboardBackspaceRoundedIcon className="rotate-180" />}
            onClick={nextPage}
            disabled={!canNextPage}
          />
          <IconButton
            icon={<LastPageRoundedIcon />}
            label="Go to last page"
            variant="outlined"
            disabled={!canNextPage}
            onClick={() => gotoPage(pageCount - 1)}
          />
        </div>
      </div>
    </div>
  );
}
