import IconComponent, {IconProps} from '../IconComponent';

export default function PathChangeIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="PathChangeIcon"
      >
        <path
          d="M8.66248 6.18325L7.81665 5.33742L9.68331 3.49992H8.74998C8.5847 3.49992 8.44626 3.44411 8.33465 3.3325C8.22265 3.2205 8.16665 3.08186 8.16665 2.91659C8.16665 2.75131 8.22265 2.61267 8.33465 2.50067C8.44626 2.38906 8.5847 2.33325 8.74998 2.33325H11.0833C11.2486 2.33325 11.387 2.38906 11.4986 2.50067C11.6106 2.61267 11.6666 2.75131 11.6666 2.91659V5.24992C11.6666 5.4152 11.6106 5.55364 11.4986 5.66525C11.387 5.77725 11.2486 5.83325 11.0833 5.83325C10.918 5.83325 10.7796 5.77725 10.668 5.66525C10.556 5.55364 10.5 5.4152 10.5 5.24992V4.31659L8.66248 6.18325ZM6.99998 11.6666C6.8347 11.6666 6.69626 11.6106 6.58465 11.4986C6.47265 11.387 6.41665 11.2485 6.41665 11.0833V7.23325L3.49998 4.31659V5.24992C3.49998 5.4152 3.44417 5.55364 3.33256 5.66525C3.22056 5.77725 3.08192 5.83325 2.91665 5.83325C2.75137 5.83325 2.61273 5.77725 2.50073 5.66525C2.38912 5.55364 2.33331 5.4152 2.33331 5.24992V2.91659C2.33331 2.75131 2.38912 2.61267 2.50073 2.50067C2.61273 2.38906 2.75137 2.33325 2.91665 2.33325H5.24998C5.41526 2.33325 5.5539 2.38906 5.6659 2.50067C5.77751 2.61267 5.83331 2.75131 5.83331 2.91659C5.83331 3.08186 5.77751 3.2205 5.6659 3.3325C5.5539 3.44411 5.41526 3.49992 5.24998 3.49992H4.31665L7.58331 6.76659V11.0833C7.58331 11.2485 7.52751 11.387 7.4159 11.4986C7.3039 11.6106 7.16526 11.6666 6.99998 11.6666Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
