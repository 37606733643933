import styled from 'styled-components';
import {startCase} from 'lodash';

interface TextProps {
  bold: boolean;
}

const Text = styled.p<TextProps>`
  font-weight: ${props => (props.bold ? '700' : '400')};
  text-transform: capitalize;
`;

interface Props {
  health: string;
}

export function Health({health}: Props) {
  return <Text bold={health === 'excellent'}>{startCase(health)}</Text>;
}
