import classnames from 'classnames';

interface Props {
  variant?: 'filled' | 'outlined';
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export default function FormField({
  variant = 'filled',
  placeholder,
  value,
  onChange,
  disabled = false,
}: Props) {
  const filledStyles = 'rounded-t border-b p-4 pb-[15px]';
  const outlinedStyles = 'border rounded p-[15px]';
  const disabledStyles = 'opacity-50 disabled:pointer-events-none';

  return (
    <input
      className={classnames(
        'w-full border-gray-200 bg-gray-900 text-gray-200 hover:opacity-90 focus:border-purple-200 focus:outline-none active:border-purple-200',
        {[filledStyles]: variant === 'filled'},
        {[outlinedStyles]: variant === 'outlined'},
        {[disabledStyles]: disabled}
      )}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  );
}
