import ObservationDetailTable from '../../common/ObservationDetailTable/ObservationDetailTable';
import {
  useSelectedAnomalyUtilizationMetrics,
  useSelectedAnomalyUtilizationMetricsCount,
} from '../../../../data/InterfaceUtilization';
import {getReadableFileSizeString} from '../../../../utils/getReadableFileSizeString';
import Timestamp from '../../../common/Timestamp';
import {FlaggedPairDeviceInterfaceUtilization} from '../../../../openapi-schema/schemaTS';
import {ComponentTitleWrapper, Container} from 'components/common/TableElements/TableElements';
import ComponentTitle from 'components/common/ComponentTitle/ComponentTitle';
import {createColumnHelper} from '@tanstack/react-table';

const initialSortBy = [
  {
    name: 'timestamp',
    desc: true,
  },
];

const columnHelper = createColumnHelper<FlaggedPairDeviceInterfaceUtilization>();

export default function InterfaceAnomalyTable() {
  const columns = [
    columnHelper.accessor('source_int_name', {
      header: 'Device 1 Interface',
      size: 1,
      enableSorting: false,
      cell: props => <p>{props.getValue() === 'Unknown' ? '—' : props.getValue()}</p>,
    }),
    columnHelper.accessor('source_node_name', {
      header: 'Device 1 Name',
      size: 1,
      enableSorting: false,
      cell: props => <p>{props.getValue() === 'Unknown' ? '—' : props.getValue()}</p>,
    }),
    columnHelper.accessor('target_int_name', {
      header: 'Device 2 Interface',
      size: 1,
      enableSorting: false,
      cell: props => <p>{props.getValue() === 'Unknown' ? '—' : props.getValue()}</p>,
    }),
    columnHelper.accessor('target_node_name', {
      header: 'Device 2 Name',
      size: 1,
      enableSorting: false,
      cell: props => <p>{props.getValue() === 'Unknown' ? '—' : props.getValue()}</p>,
    }),
    columnHelper.accessor('input_utilization', {
      header: 'Utilization In',
      size: 1,
      cell: props => <p>{`${(props.getValue() ?? 0).toFixed(2)}%`}</p>,
    }),
    columnHelper.accessor('output_utilization', {
      header: 'Utilization Out',
      size: 1,
      cell: props => <p>{`${(props.getValue() ?? 0).toFixed(2)}%`}</p>,
    }),
    columnHelper.accessor('int_speed', {
      header: 'Speed',
      size: 1,
      cell: props => <p>{getReadableFileSizeString(props.getValue())}</p>,
    }),
    columnHelper.accessor('out_discards', {
      header: 'Output Drops',
      size: 0.5,
    }),
    columnHelper.accessor('in_errors', {
      header: 'In Errors',
      size: 0.5,
    }),
    columnHelper.accessor('out_errors', {
      header: 'Out Errors',
      size: 0.5,
    }),
    columnHelper.accessor('timestamp', {
      header: 'Timestamp',
      size: 2,
      cell: props => <Timestamp timestamp={props.getValue()} />,
    }),
  ];

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="Utilization Metrics" />
      </ComponentTitleWrapper>
      <ObservationDetailTable
        tableDataQuery={useSelectedAnomalyUtilizationMetrics}
        dataCountQuery={useSelectedAnomalyUtilizationMetricsCount}
        columns={columns}
        initialSortBy={initialSortBy}
      />
    </Container>
  );
}
