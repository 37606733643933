import {maxBy, minBy, uniq} from 'lodash';
import {useCallback, useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {selectedEdgeIdsState, selectedNodeIdsState} from '../../../atoms/dashboard';
import {selectedAnomalyDataState} from '../../../atoms/selectedAnomaly';
import {
  defaultNetworkRingEdgeAttributes,
  defaultNetworkRingNodesAttributes,
} from '../../../constants/TopologyContants';
import {useLatencyKPIs} from '../../../data/KPI';
import {useNetworkRingDCs} from '../../../data/Latency';
import {NetworkRingEntry} from '../../../openapi-schema/schemaTS';
import CircleOptions from '../CytoscapeViewer/layouts/defaults/CircleOptions';
import NetworkRingStylesheet from '../CytoscapeViewer/layouts/defaults/NetworkRingStylesheet';
import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TopologyBase from '../TopologyBase/TopologyBase';
import {getEdgeWidth} from './utils/getEdgeWidth';

export default function TopologyNetworkRing() {
  const {data: APIdata, isLoading, isError, refetch} = useNetworkRingDCs();
  const {data: LatencyKPIs} = useLatencyKPIs();
  const selectedNodeIds = useRecoilValue(selectedNodeIdsState);
  const selectedEdgeIds = useRecoilValue(selectedEdgeIdsState);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);

  const NodesList = useMemo(() => {
    if (!APIdata) return undefined;
    const allDCs = APIdata.flatMap(element => [element.client_site, element.server_site]);
    const uniqDCs = uniq(allDCs);
    return [...uniqDCs, 'Self'];
  }, [APIdata]);

  const EdgesWithSelfConnection = useMemo(() => {
    if (!APIdata) return [];
    return APIdata.map(element =>
      element.server_site === element.client_site ? {...element, client_site: 'Self'} : element
    );
  }, [APIdata]);

  const formatNodes = useCallback(
    (NodesList: string[] | undefined) => {
      if (!NodesList) return [];

      const mainNode = selectedAnomalyData?.affected_resources[0];

      return NodesList.map(dc => {
        const isMainNode = dc === mainNode;

        return {
          data: {
            ...defaultNetworkRingNodesAttributes,
            id: dc,
            label: dc,
            bgColor: isMainNode ? 'rgba(33, 159, 216, 1)' : 'white',
            borderWidth: isMainNode ? 1 : 0,
            width: isMainNode ? 9 : 8,
            height: isMainNode ? 9 : 8,
          },
        };
      });
    },
    [selectedAnomalyData]
  );

  const formatEdges = useCallback(
    (connections: NetworkRingEntry[]) => {
      if (!connections) return [];

      const mainNode = selectedAnomalyData?.affected_resources[0];

      const byteRateLargest = Number(maxBy(connections, 'avg_byte_rate')?.avg_byte_rate);
      const byteRateSmallest = Number(minBy(connections, 'avg_byte_rate')?.avg_byte_rate);

      return connections.map(entry => {
        const isConnectedToMainNode =
          entry.client_site === mainNode || entry.server_site === mainNode;

        return {
          data: {
            ...defaultNetworkRingEdgeAttributes,
            id: `${entry.client_site}_${entry.server_site}`,
            source: entry.client_site,
            target: entry.server_site,
            lineColor: isConnectedToMainNode ? 'rgba(61, 106, 126, 1)' : 'rgba(28, 28, 28, 1)',
            zIndex: isConnectedToMainNode ? 1 : 0,
            width: getEdgeWidth(byteRateSmallest, byteRateLargest, entry.avg_byte_rate),
            label: LatencyKPIs?.delay_current_average
              ? `${LatencyKPIs?.delay_current_average.toFixed(2)}  ms`
              : '',
          },
        };
      });
    },
    [selectedAnomalyData, LatencyKPIs]
  );

  const drawUnderlayCanvasElements = (ctx: CanvasRenderingContext2D) => {
    ctx.save();
    ctx.strokeStyle = 'rgb(57, 57, 57)';
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.arc(0, 0, 100, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.restore();
  };

  const graphElements = {
    nodes: formatNodes(NodesList),
    edges: formatEdges(EdgesWithSelfConnection),
  };

  if (isError) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <TopologyBase
      selectedNodeIds={selectedNodeIds}
      selectedEdgeIds={selectedEdgeIds}
      layout={CircleOptions}
      stylesheet={NetworkRingStylesheet}
      graphElements={graphElements}
      drawUnderlayCanvasElements={drawUnderlayCanvasElements}
      lockNodes={true}
    />
  );
}
