import {reviewPanelContentState} from 'atoms/ObservationSelectionPageContent';
import TabGroup from 'components/Katana/components/Tabs/TabGroup';
import {useRecoilState} from 'recoil';
import config from 'utils/AppConfigurationService';
import DetailedAnomalyTable from '../DetailedAnomalyTable/DetailedAnomalyTable';
import IncidentsPage from './components/IncidentsPage';

export default function ReviewPanel() {
  const [reviewPanelContent, setReviewPanelContent] = useRecoilState(reviewPanelContentState);

  return (
    <div className="flex h-full flex-col overflow-hidden rounded-md border border-dark-outline-variant bg-black shadow-elevation-1">
      <TabGroup
        tabData={[
          {
            label: 'Incidents',
            onClick: () => setReviewPanelContent('Incidents'),
            disabled: !config.featureFlags.incidents,
          },
          {label: 'Observations', onClick: () => setReviewPanelContent('Observations')},
        ]}
        initialSelectedTabIndex={reviewPanelContent === 'Incidents' ? 0 : 1}
      />
      <div className="min-h-0 flex-1">
        {reviewPanelContent === 'Incidents' ? <IncidentsPage /> : <DetailedAnomalyTable />}
      </div>
    </div>
  );
}
