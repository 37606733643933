import {EventObjectCore} from 'cytoscape';

export const topologyPadding = 30;

// This function zooms the topology to a certain zoom level when the graph is ready.
// If the all the topology elements won't fit in the viewport, the graph will zoom
// out with a padding of 30px around each side of the viewport.

// Need to provide zoom level because node sizes are different for each use case.
export const topologyOnReadyFit = (event: EventObjectCore, zoomLevel = 1) => {
  const cy = event.cy;
  cy.zoom(zoomLevel);
  cy.center();

  const {h, w} = cy.elements().renderedBoundingBox();

  if (h >= cy.height() - topologyPadding || w >= cy.width() - topologyPadding) {
    cy.fit(undefined, topologyPadding);
  }
};
