import {Duration} from 'luxon';

export const formatNeighborDropTime = (duration: Duration) => {
  const shiftedDuration = duration.shiftTo('days', 'hours', 'minutes', 'seconds');
  const days = shiftedDuration.get('day');
  const hours = shiftedDuration.get('hours');
  const minutes = shiftedDuration.get('minutes');
  const seconds = shiftedDuration.get('seconds').toFixed(0); //round to save space in KPI box

  if (days) return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  if (hours) return `${hours}h ${minutes}m ${seconds}s`;
  if (minutes) return `${minutes}m ${seconds}s`;
  return `${seconds}s`;
};

export const formatIncidentTime = (duration: Duration) => {
  const shiftedDuration = duration.shiftTo('days', 'hours', 'minutes');
  const days = shiftedDuration.get('day');
  const hours = shiftedDuration.get('hours');
  const minutes = shiftedDuration.get('minutes');

  const daysOut = days ? `${days}d` : '';
  const hoursOut = hours ? `${hours}h` : '';
  const minutesOut = minutes ? `${Math.round(minutes)}m` : '';
  const tokens = [daysOut, hoursOut, minutesOut].filter(Boolean);
  return tokens.join(' ') || '0m';
};
