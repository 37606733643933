import FilterChip from 'components/Katana/components/Chips/FilterChip/FilterChip';
import {
  Filters,
  FiltersData,
} from 'components/Katana/patterns/TableSearchWithFilters/TableSearchWithFilters';
import React from 'react';

interface Props {
  filterValues: Filters;
  setFilterValues: (filters: Filters) => void;
  defaultFilters: Filters;
  filtersData: FiltersData;
}

export default function AppliedFiltersList({
  filterValues,
  setFilterValues,
  filtersData,
  defaultFilters,
}: Props) {
  const onCloseIndividual = (category: string, filter: string) => {
    setFilterValues({
      ...filterValues,
      [category]: {
        ...filterValues[category],
        [filter]: false,
      },
    });
  };

  const onCloseGroup = (category: string) => {
    const defaultFilterGroupValues = defaultFilters[category];

    setFilterValues({
      ...filterValues,
      [category]: defaultFilterGroupValues,
    });
  };

  const filteredCategories = filtersData.flat().filter(category => {
    return Object.keys(defaultFilters[category.category]).some(
      filter => filterValues[category.category][filter]
    );
  });

  return (
    <div className="flex flex-wrap items-center gap-2">
      <span className="text-label-large mr-2 flex-grow text-white">Filtering by:</span>
      {filteredCategories.map(category => {
        const activeFilters = Object.keys(defaultFilters[category.category]).filter(
          filter => filterValues[category.category][filter]
        );

        return (
          <React.Fragment key={category.category}>
            {activeFilters.length <= 2 ? (
              activeFilters.map(filter => (
                <FilterChip
                  key={filter}
                  label={filter}
                  icon={category.filterIconFunction(filter)}
                  onDelete={() => onCloseIndividual(category.category, filter)}
                />
              ))
            ) : (
              <FilterChip
                key={category.category}
                label={category.groupedCategoryLabel}
                icon={
                  <div className="m-0 h-[18px] w-[18px] p-[2px]">
                    <div className="grid h-full place-content-center rounded-full bg-dark-primary-fixed-dim text-12 text-dark-surface">
                      {activeFilters.length}
                    </div>
                  </div>
                }
                onDelete={() => onCloseGroup(category.category)}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
