import {useMemo} from 'react';
import styled from 'styled-components';
import ErrorState from '../../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {useRecoilValue} from 'recoil';
import {timelineState} from '../../../../atoms/dashboard';
import {DateTime} from 'luxon';
import {useAnomalyCountFillReportingDashboard} from '../../../../data/ReportingDashboard';
import {Line} from 'react-chartjs-2';
import {selectedTimezoneState} from '../../../../atoms/selectedTimezone';
import {binnedTimestampFormat} from '../../../../constants/timeFormats';
import {
  chartHorizontalAxisOptions,
  chartHorizontalTickOptions,
  chartVerticalAxisOptions,
  coreChartOptions,
} from '../../../../constants/chartOptions';
import {getChartXAxisTickLabels} from '../../../../utils/getChartXAxisTickLabels';
import {ButtonDataFilter} from '../../../ReportingDashboard/ReportingDashboardSummary/components/SectionGroup';
import {ChartOptions} from 'chart.js';

const Container = styled.div`
  width: 100%;
  height: calc(100% - 50px);

  canvas {
    padding: 10px;
    background-color: black;
  }
`;

interface Props {
  filter: ButtonDataFilter;
}

export default function LineChart({filter}: Props) {
  const {
    data: APIdata,
    isLoading,
    isError,
    refetch,
  } = useAnomalyCountFillReportingDashboard(filter);
  const {selectedInterval} = useRecoilValue(timelineState);
  const selectedTimezone = useRecoilValue(selectedTimezoneState);

  const tableData = useMemo(() => {
    if (APIdata?.length) {
      return APIdata.map(element => {
        return {
          x: DateTime.fromFormat(element.bin_key, binnedTimestampFormat, {zone: 'UTC'}).toMillis(),
          y: element.value,
        };
      });
    } else {
      return [
        {x: selectedInterval.start.toMillis(), y: 0},
        {x: selectedInterval.end.toMillis(), y: 0},
      ];
    }
  }, [APIdata, selectedInterval]);

  if (isError) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }

  const options: ChartOptions<'line'> = {
    ...coreChartOptions,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        ...chartVerticalAxisOptions,
      },
      x: {
        ...chartHorizontalAxisOptions,
        min: tableData[0]?.x,
        max: tableData[tableData.length - 1]?.x,
        ticks: {
          ...chartHorizontalTickOptions,
          callback: value =>
            getChartXAxisTickLabels(Number(value), selectedInterval, selectedTimezone),
        },
      },
    },
  };

  const data = {
    datasets: [
      {
        data: tableData,
        borderColor: 'rgba(16, 170, 204, 1)',
        backgroundColor: 'rgba(16, 170, 204, 0.3)',
        borderWidth: 3,
        fill: true,
        yAxisID: 'y',
        pointRadius: 0,
        hitRadius: 0,
      },
    ],
  };

  return (
    <Container>
      <Line options={options} data={data} />
    </Container>
  );
}
