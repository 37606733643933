import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {selectedTemplatesState} from '../../../../../atoms/SelectedTemplates';
import {
  BACKGROUND_COLOR_CONTENT_HEADER,
  BACKGROUND_COLOR_MAIN,
  BACKGROUND_CONTENT,
} from '../colors';
import TemplateDisplayContent from './TemplateDisplayContent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background: ${BACKGROUND_COLOR_MAIN};
`;

const TemplateList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Template = styled.div`
  background: ${BACKGROUND_CONTENT};
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${BACKGROUND_COLOR_CONTENT_HEADER};
  padding: 8px 6px 8px 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: white;
  white-space: pre-wrap;
`;

export default function TemplateDisplay() {
  const templates = useRecoilValue(selectedTemplatesState);

  return (
    <Container>
      <TemplateList>
        {templates.map((template, index) => {
          return (
            <Template key={index}>
              <Header>
                <TitleContainer>
                  <Title>{`Template:  ${template.template_id}`}</Title>
                  <Title>{`Version:  ${template.template_version}`}</Title>
                </TitleContainer>
              </Header>
              <TemplateDisplayContent template={template} />
            </Template>
          );
        })}
        {/* <Template>
          <Header>
            <TitleContainer>
              <Title>System Variable</Title>
            </TitleContainer>
          </Header>
          <SystemVariable />
        </Template> */}
      </TemplateList>
    </Container>
  );
}
