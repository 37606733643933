import {useQuery, useMutation, useQueryClient} from 'react-query';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {selectedFingerprintState} from '../atoms/selectedFingerprint';
import {snackbarDataState} from '../atoms/snackbarData';
import useNavigateKeepParams from '../helpers/hooks/useNavigateKeepParams';
import {
  ExecuteScriptAsyncResponse,
  Fingerprint,
  FingerprintRequest,
  FingerprintResponse,
  Fingerprints,
  ScriptExecutionInfo,
  ScriptExecutionDetail,
  SortField,
  ScriptInfoSortSpecification,
} from '../openapi-schema/fingerprintSchemaTS';
import {
  postFingerprint,
  getFingerprintObservations,
  getScriptExecutionInfo,
  getAnsibleScript,
  executeScript,
  getAnsibleScriptOutput,
  getScriptExecutionInfoSingleMatch,
} from '../utils/fingerprintAPIUtils';
import {getFingerprintsList} from '../utils/fingerprintAPIUtils';
import {getLogsByAnomalyId, getTemplatesByAnomalyId} from '../utils/PythonApiUtils';
import config from '../utils/AppConfigurationService';
import {ServiceNowModalActions} from '../components/ServiceNow/ServiceNowModal/ServiceNowModalActions';
import {selectedAnomalyIdState} from 'atoms/selectedAnomaly';
import {timelineState} from 'atoms/dashboard';
import useDebouncedInput from 'helpers/hooks/useDebouncedInput';
import {ARPopupOpenState} from 'atoms/ARPopupOpen';
import {templatesSelectionSortingState} from 'atoms/TemplatesSelectionSorting';

export function useFingerprintsList() {
  return useQuery(['fingerprintList'], () => getFingerprintsList({includeScripts: true}), {
    staleTime: 10 * 1000,
  });
}

function useFingerprintListMutateAddFingerprint() {
  const setSnackbarData = useSetRecoilState(snackbarDataState);
  const setSelectedFingerprint = useSetRecoilState(selectedFingerprintState);
  const navigateKeepParams = useNavigateKeepParams();

  return useMutation(
    (data: FingerprintRequest): Promise<FingerprintResponse> => postFingerprint({...data}),
    {
      onSuccess: (fingerprintResponse: FingerprintResponse, fingerprintRequestData) => {
        setSnackbarData({
          severity: 'success',
          message: 'New Fingerprint Created!',
          open: true,
          subtitle: 'Click to view',
          subtitleCallback: () => {
            setSelectedFingerprint({
              ...fingerprintRequestData,
              fingerprint_id: fingerprintResponse.fingerprint_id,
              version: fingerprintResponse.version,
            });
            navigateKeepParams({pathname: '/service-now', clearStartEnd: true});
          },
        });
      },
      onError: error => {
        setSnackbarData({
          severity: 'error',
          message: 'Automation Failed',
          open: true,
        });
      },
    }
  );
}

export const useAddFingerprint = () => {
  const mutation = useFingerprintListMutateAddFingerprint();

  return (fingerprintData: FingerprintRequest) => {
    mutation.mutate(fingerprintData);
  };
};

function useFingerprintMutateUpdate() {
  const queryClient = useQueryClient();
  const setSnackbarData = useSetRecoilState(snackbarDataState);
  const setSelectedFingerprint = useSetRecoilState(selectedFingerprintState);

  interface MutationData {
    updatedFingerprint: FingerprintRequest;
    originalFingerprint: Fingerprint | undefined;
    snackbarMessage: string;
    snackbarSubtitle: string | undefined;
  }

  return useMutation(
    (data: MutationData): Promise<FingerprintResponse> => {
      setSelectedFingerprint({
        ...data.updatedFingerprint,
        automation_case: data.originalFingerprint?.automation_case,
      });
      return postFingerprint({...data.updatedFingerprint});
    },
    {
      onSuccess: (fingerprintResponse: FingerprintResponse, variables: MutationData) => {
        queryClient.setQueryData(['fingerprintList'], (oldList: Fingerprints | undefined) => {
          if (!oldList || !oldList.fingerprints) return {...oldList};

          const idx = oldList.fingerprints.findIndex(
            (fingerprint: Fingerprint) =>
              fingerprint.fingerprint_id === variables.updatedFingerprint.fingerprint_id
          );

          if (idx > -1) {
            return {
              ...oldList,
              fingerprints: [
                ...oldList.fingerprints.slice(0, idx),
                {
                  ...variables.updatedFingerprint,
                  automation_case: variables.originalFingerprint?.automation_case,
                },
                ...oldList.fingerprints.slice(idx + 1),
              ],
            };
          }
          return {...oldList};
        });
        setSnackbarData({
          severity: 'success',
          message: variables.snackbarMessage,
          subtitle: variables.snackbarSubtitle,
          open: true,
        });
      },
      onError: (error, variables) => {
        setSelectedFingerprint(variables.originalFingerprint);
        setSnackbarData({
          severity: 'error',
          message: 'Fingerprint Update Failed',
          open: true,
        });
      },
    }
  );
}

export const useUpdateFingerprint = () => {
  const mutation = useFingerprintMutateUpdate();
  const selectedFingerprint = useRecoilValue(selectedFingerprintState);

  return (
    fingerprintData: FingerprintRequest,
    snackbarMessage: string,
    snackbarSubtitle?: string
  ) => {
    mutation.mutate({
      updatedFingerprint: fingerprintData,
      originalFingerprint: selectedFingerprint,
      snackbarMessage: snackbarMessage,
      snackbarSubtitle: snackbarSubtitle,
    });
  };
};

export function useFingerprintLogs(
  templateId: string,
  templateVersion: number,
  anomaly_id: string
) {
  return useQuery(['fingerprintLogs', templateId, templateVersion, anomaly_id], () =>
    getLogsByAnomalyId({
      anomaly_id: anomaly_id,
      template_id: templateId,
      template_version: templateVersion,
      limit: 1,
      sort_fields: [
        {
          desc: true,
          name: 'observed',
        },
        {
          name: 'explanatory_reasons.syslog_priority',
          desc: true,
        },
      ],
    })
  );
}

export function useFingerprintsObservationsList(
  pageIndex = 0,
  limit = config.apiResultLimit,
  sortState: SortField[]
) {
  const selectedFingerprint = useRecoilValue(selectedFingerprintState);

  const fingerprint_id = selectedFingerprint?.fingerprint_id;
  const offset = pageIndex * limit;

  return useQuery(
    ['fingerprintObservationsList', fingerprint_id, offset, limit, sortState],
    () =>
      getFingerprintObservations({
        offset,
        limit,
        fingerprint_id,
        sort_fields: sortState,
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useScriptExecutionDataList(
  pageIndex = 0,
  limit = config.apiResultLimit,
  sortState: ScriptInfoSortSpecification[]
) {
  const offset = pageIndex * limit;

  return useQuery(
    ['scriptExecutionDataList', offset, limit, sortState],
    () =>
      getScriptExecutionInfo({
        offset,
        limit,
        sort_fields: sortState,
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useAnsibleScriptData(ansible_script_id: string, ansible_script_version: number) {
  return useQuery(['ansibleScriptData', ansible_script_id, ansible_script_version], () =>
    getAnsibleScript(ansible_script_id, ansible_script_version)
  );
}

function useScriptExecutionMutate() {
  const queryClient = useQueryClient();
  const setSnackbarData = useSetRecoilState(snackbarDataState);

  return useMutation(
    (data: ScriptExecutionInfo): Promise<FingerprintResponse> =>
      executeScript(String(data.match_id)),
    {
      onSuccess: (
        executeScriptResponse: ExecuteScriptAsyncResponse,
        scriptExecutionRequestData
      ) => {
        queryClient.setQueriesData(
          ['scriptExecutionDataList'],
          (oldList: ScriptExecutionDetail | undefined) => {
            if (!oldList || !oldList.script_execution_details) return {...oldList};

            const idx = oldList.script_execution_details.findIndex(
              script => script.match_id === scriptExecutionRequestData.match_id
            );

            if (idx > -1) {
              return {
                ...oldList,
                script_execution_details: [
                  ...oldList.script_execution_details.slice(0, idx),
                  {
                    ...oldList.script_execution_details[idx],
                    status: 'RUNNING',
                  },
                  ...oldList.script_execution_details.slice(idx + 1),
                ],
              } as ScriptExecutionDetail;
            }
            return {...oldList};
          }
        );
        setSnackbarData({
          severity: 'success',
          message: `Running ${scriptExecutionRequestData.fingerprint_name}`,
          open: true,
        });
      },
      onError: error => {
        setSnackbarData({
          severity: 'error',
          message: 'Script Execution Failed.',
          open: true,
        });
      },
    }
  );
}

export const useExecuteScript = () => {
  const mutation = useScriptExecutionMutate();

  return (scriptExecutionInfo: ScriptExecutionInfo) => {
    mutation.mutate(scriptExecutionInfo);
  };
};

export function useScriptExecutionOutput(fingerprintMatchId: string) {
  return useQuery(['scriptExecutionOutput', fingerprintMatchId], () =>
    getAnsibleScriptOutput(fingerprintMatchId)
  );
}

export function useScriptExecutionDetails(fingerprintMatchId: string) {
  const {showResult} = ServiceNowModalActions();

  return useQuery(
    ['scriptExecutionDetails', fingerprintMatchId],
    () => getScriptExecutionInfoSingleMatch(fingerprintMatchId),
    {
      onSuccess: data => showResult(data),
    }
  );
}

export function useFingerprintCreationLogs(templateId: string, templateVersion: number) {
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['ARlogs', templateId, templateVersion, debouncedStart, debouncedEnd, selectedAnomalyId],
    () =>
      getLogsByAnomalyId({
        anomaly_id: selectedAnomalyId,
        template_id: templateId,
        template_version: templateVersion,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        limit: 1,
        sort_fields: [
          {
            desc: true,
            name: 'observed',
          },
          {
            name: 'explanatory_reasons.syslog_priority',
            desc: true,
          },
        ],
      })
  );
}

export function useFingerprintCreationTemplateList(
  pageIndex = 0,
  limit: number = config.apiResultLimit
) {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const ARPopupOpen = useRecoilValue(ARPopupOpenState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const templatesSelectionSorting = useRecoilValue(templatesSelectionSortingState);
  const offset = pageIndex * limit;

  return useQuery(
    [
      'automationTemplatesList',
      anomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      templatesSelectionSorting,
      limit,
    ],
    () =>
      getTemplatesByAnomalyId({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_id: anomalyId,
        offset: offset,
        limit: limit,
        sort_fields: templatesSelectionSorting,
      }),
    {enabled: ARPopupOpen}
  );
}
