import {useEffect, useMemo, useRef, useState} from 'react';
import TableSortArrow from '../../../common/TableSortArrow/TableSortArrow';
import Pagination from '../../../common/Pagination/Pagination';
import {timelineState} from '../../../../atoms/dashboard';
import {useRecoilValue} from 'recoil';
import {pageSizeOptionsMedium} from '../../../../constants/PageSizeOptions';
import {isEmpty} from 'lodash';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {convertSortToApiSchema} from '../../../../utils/convertSortToApiSchema';
import {EmptyDataMessage} from '../../../../constants/queryLifecycleMessages';
import {UseQueryResult} from 'react-query';
import {IntValue, SortField} from '../../../../openapi-schema/schemaTS';
import {scrollToTop} from '../../../../utils/scrollToTop';
import {
  Table,
  HeaderWrapper,
  Header,
  HeaderRow,
  TableBody,
  TableRow,
  TableData,
} from 'components/common/TableElements/TableElements';
import {convertSortToReactTableSchema} from 'utils/convertSortToReactTableSchema';
import {SortedPagedQuery} from 'data/types';
import {
  Cell,
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

interface Props {
  tableDataQuery: SortedPagedQuery;
  columns: ColumnDef<any, any>[];
  dataCountQuery?: () => UseQueryResult<IntValue, unknown>;
  initialSortBy?: SortField[];
  pageSizeOptions?: number[];
  rowAttributes?: (
    row: Row<any>,
    data: any[]
  ) => {
    isDisabled?: boolean;
  };
  cellAttributes?: (cell: Cell<any, any>) => {
    highlight?: boolean;
  };
  pagination?: boolean;
}

export default function ObservationDetailTable({
  tableDataQuery,
  dataCountQuery,
  columns,
  rowAttributes,
  cellAttributes,
  pageSizeOptions = pageSizeOptionsMedium,
  initialSortBy,
  pagination = true,
}: Props) {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const [sortState, setSortState] = useState(initialSortBy);
  const timeline = useRecoilValue(timelineState);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const query = tableDataQuery(APIPageIndex, APIPageSize, sortState);
  const dataCount = dataCountQuery ? dataCountQuery().data : undefined;
  const {data: APIdata} = query;

  const data = useMemo(() => (APIdata ? APIdata : []), [APIdata]);

  const initialState = useMemo(() => {
    return {
      sorting: sortState ? convertSortToReactTableSchema(sortState) : [],
      pagination: {
        pageIndex: 0,
        pageSize: pageSizeOptions[0],
      },
    };
  }, [pageSizeOptions, sortState]);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
    getTotalSize,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    pageCount: Math.ceil((dataCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  useEffect(() => {
    if (!setAPIPageIndex || !setAPIPageSize) return;
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
  }, [pageIndex, pageSize, setAPIPageIndex, setAPIPageSize]);

  useEffect(() => {
    setPageIndex(0);
  }, [pageSize, timeline, sorting, setPageIndex]);

  useEffect(() => {
    if (!setSortState) return;
    const newSortBy = convertSortToApiSchema(sorting);
    setSortState(newSortBy);
  }, [setSortState, sorting]);

  return (
    <>
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  style={{
                    ...getHeaderStyles(header),
                    flexBasis: `${(header.column.getSize() / getTotalSize()) * 100}%`,
                  }}
                  isGroupedHeader={header.column.id !== header.column.getLeafColumns()[0].id}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={query}
            isEmpty={isEmpty(data)}
            emptyMessage={EmptyDataMessage}
          >
            {getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                isHighlighted={Boolean(row.original?.observed)}
                {...(rowAttributes ? rowAttributes(row, data) : {})}
                noSelect
              >
                {row.getVisibleCells().map(cell => (
                  <TableData
                    key={cell.id}
                    style={{
                      ...getCellStyles(cell),
                      flexBasis: `${(cell.column.getSize() / getTotalSize()) * 100}%`,
                    }}
                    {...(cellAttributes ? cellAttributes(cell) : {})}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      {pagination && (
        <Pagination
          paginationMethods={{
            canPreviousPage: getCanPreviousPage(),
            canNextPage: getCanNextPage(),
            nextPage,
            previousPage,
            setPageSize,
            pageIndex,
            pageSize,
            gotoPage: setPageIndex,
            pageCount: getPageCount(),
          }}
          pageSizeOptions={pageSizeOptions}
        />
      )}
    </>
  );
}
