import styled from 'styled-components';
import Button from './Button';
import {ButtonData} from './SectionGroup';

const Container = styled.div`
  width: 100%;
  margin: 10px 0 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

interface Props {
  data: ButtonData[];
}

export default function ButtonGroup({data}: Props) {
  return (
    <Container>
      {data.map(kpi => (
        <Button data={kpi} value={kpi.value} key={kpi.text} />
      ))}
    </Container>
  );
}
