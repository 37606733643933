import {DateTime, Interval} from 'luxon';
import {getTimestampFormat} from '../components/common/Timeline/utils/getTimestampFormat';

export const getChartXAxisTickLabels = (
  value: number,
  selectedInterval: Interval,
  selectedTimezone: string
) => {
  const tick = DateTime.fromMillis(value, {zone: 'UTC'}).setZone(selectedTimezone);
  const timestampFormat = getTimestampFormat(selectedInterval, tick);

  return tick.toFormat(timestampFormat);
};
