export const minEdgeWidth = 1;
export const maxEdgeWidth = 4;

export const getEdgeWidth = (
  byteRateSmallest: number,
  byteRateLargest: number,
  avg_byte_rate: number
) => {
  //linear normalization general bounds
  //https://stats.stackexchange.com/questions/178626/how-to-normalize-data-between-1-and-1

  if (byteRateLargest === byteRateSmallest) return minEdgeWidth;

  //normalize bounds to powers of e
  const normalizedByteRate =
    (Math.pow(Math.E, maxEdgeWidth) - Math.pow(Math.E, minEdgeWidth)) *
      ((avg_byte_rate - byteRateSmallest) / (byteRateLargest - byteRateSmallest)) +
    Math.pow(Math.E, minEdgeWidth);

  //return log of of normalized byte rate which results in value between minEdgeWidth and maxEdgeWidth
  return Math.log(normalizedByteRate);
};
