import {EventObjectNode} from 'cytoscape';
import {uniqBy} from 'lodash';
import {useDevicesISIS, useTopologyData} from '../../../data/Topology';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';
import {Container, Header, Text, HiddenElement} from './TopologyTooltipElements';

interface Props {
  event: EventObjectNode;
}

export default function ISISGroupTooltip({event}: Props) {
  const {data: IsisData} = useDevicesISIS();
  const {data: topologyElements} = useTopologyData();

  const nodeAreaAddress = event.target.data().area;

  const IsisNodeIdIpPairs = IsisData?.flatMap(peer => {
    if (!peer.isis_area_address) return [];
    return [
      {id: peer.node_id, ip: peer.ip, area: peer.isis_area_address},
      {
        id: peer.isis_adj_node_ids[0],
        ip: peer.isis_adj_ip_addr_address,
        area: peer.isis_area_address,
      },
    ];
  });
  const uniqIsisNodeIdIpPairs = uniqBy(IsisNodeIdIpPairs, 'id');
  const IsisNodeNameIpList = uniqIsisNodeIdIpPairs.flatMap(pair => {
    const nodeName = topologyElements?.nodesByIP?.[pair.ip]?.[0]?.data?.name;
    if (pair.area === nodeAreaAddress && !!nodeName) {
      return {
        ...pair,
        nodeName: nodeName,
      };
    }
    return [];
  });

  return (
    <Tooltip
      tooltipContent={
        <Container>
          <Header>IS-IS Area {nodeAreaAddress} Device List</Header>
          {IsisNodeNameIpList?.map(node => {
            return (
              <Text key={node.id}>
                {node.nodeName}: {node.ip}
              </Text>
            );
          })}
        </Container>
      }
      mainContent={
        <HiddenElement x={event.originalEvent.offsetX} y={event.originalEvent.offsetY} />
      }
      onCanvas={true}
      maxWidth={'none'}
    />
  );
}
