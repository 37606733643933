import styled from 'styled-components';
import Tooltip from '../../../../Katana/components/Tooltip/Tooltip';
import {LogAnomalyExplanatoryReasons} from '../../../../../openapi-schema/schemaTS';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 19px 4px 0 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  gap: 1px;
`;

interface CircleProps {
  active: boolean;
}

const Circle = styled.div<CircleProps>`
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: white;
  opacity: ${props => (props.active ? '1' : '0.5')};
`;

const TooltipTextWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TooltipText = styled.p``;

interface Props {
  explanatory_reasons: LogAnomalyExplanatoryReasons;
}

export default function ERIndicator({explanatory_reasons}: Props) {
  return (
    <Tooltip
      tooltipContent={
        <TooltipTextWrapper>
          <Column>
            <TooltipText>Pattern Stability Tracker:</TooltipText>
            <TooltipText>Anomaly Detection Trees:</TooltipText>
            <TooltipText>Threshold Rarity Gauge:</TooltipText>
          </Column>
          <Column>
            <TooltipText>
              {explanatory_reasons?.invariants_miner_prediction ? 'Yes' : 'No'}
            </TooltipText>
            <TooltipText>
              {explanatory_reasons?.isolation_forest_prediction ? 'Yes' : 'No'}
            </TooltipText>
            <TooltipText>
              {explanatory_reasons?.quantile_scarcity_prediction ? 'Yes' : 'No'}
            </TooltipText>
          </Column>
        </TooltipTextWrapper>
      }
      mainContent={
        <Container>
          <Row>
            <Circle active={Boolean(explanatory_reasons?.invariants_miner_prediction)} />
          </Row>
          <Row>
            <Circle active={Boolean(explanatory_reasons?.isolation_forest_prediction)} />
            <Circle active={Boolean(explanatory_reasons?.quantile_scarcity_prediction)} />
          </Row>
        </Container>
      }
    />
  );
}
