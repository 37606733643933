import TabGroup from 'components/Katana/components/Tabs/TabGroup';
import GraphWrapper from '../GraphWrapper/GraphWrapper';
import Divider from 'components/Katana/components/Divider/Divider';
import Sensei from 'components/common/Sensei/Sensei';
import {useRecoilState} from 'recoil';
import {explorePanelContentState} from 'atoms/ObservationSelectionPageContent';
import config from 'utils/AppConfigurationService';

export default function ExplorePanel() {
  const [explorePanelContent, setExplorePanelContent] = useRecoilState(explorePanelContentState);

  return (
    <div className="flex h-full flex-col overflow-hidden rounded-md border border-dark-outline-variant bg-black shadow-elevation-1">
      <TabGroup
        tabData={[
          {
            label: 'Sensei',
            onClick: () => setExplorePanelContent('Sensei'),
            disabled: !config.featureFlags.Sensei,
          },
          {label: 'Topology', onClick: () => setExplorePanelContent('Topology')},
        ]}
        initialSelectedTabIndex={explorePanelContent === 'Sensei' ? 0 : 1}
      />
      <Divider />
      <div className="min-h-0 flex-1">
        {explorePanelContent === 'Sensei' ? <Sensei /> : <GraphWrapper />}
      </div>
    </div>
  );
}
