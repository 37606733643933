import {
  useSelectedAnomalyRelatedAnomaliesLatency,
  useSelectedAnomalyRelatedAnomaliesLatencyCount,
} from '../../../../data/Latency';
import AssociatedAnomalies from '../../common/AssociatedAnomalies/AssociatedAnomalies';

export default function AssociatedAnomaliesLatency() {
  const anomalyListQuery = useSelectedAnomalyRelatedAnomaliesLatency;
  const anomalyCountQuery = useSelectedAnomalyRelatedAnomaliesLatencyCount;

  return (
    <AssociatedAnomalies
      anomalyListQuery={anomalyListQuery}
      anomalyCountQuery={anomalyCountQuery}
    />
  );
}
