import IconComponent, {IconProps} from '../IconComponent';

export default function NoneClassIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="NoneClassIcon"
      >
        <path
          d="M6.99992 3.79167C8.07251 3.7881 9.12434 4.08729 10.0345 4.65484C10.9446 5.22239 11.6761 6.03526 12.1449 7C11.1824 8.96583 9.21659 10.2083 6.99992 10.2083C4.78325 10.2083 2.81742 8.96583 1.85492 7C2.3237 6.03526 3.0552 5.22239 3.96535 4.65484C4.87549 4.08729 5.92732 3.7881 6.99992 3.79167ZM6.99992 2.625C4.08325 2.625 1.59242 4.43917 0.583252 7C1.59242 9.56083 4.08325 11.375 6.99992 11.375C9.91658 11.375 12.4074 9.56083 13.4166 7C12.4074 4.43917 9.91658 2.625 6.99992 2.625ZM6.99992 5.54167C7.38669 5.54167 7.75763 5.69531 8.03112 5.9688C8.30461 6.24229 8.45825 6.61323 8.45825 7C8.45825 7.38677 8.30461 7.75771 8.03112 8.0312C7.75763 8.30469 7.38669 8.45833 6.99992 8.45833C6.61314 8.45833 6.24221 8.30469 5.96872 8.0312C5.69523 7.75771 5.54158 7.38677 5.54158 7C5.54158 6.61323 5.69523 6.24229 5.96872 5.9688C6.24221 5.69531 6.61314 5.54167 6.99992 5.54167ZM6.99992 4.375C5.55325 4.375 4.37492 5.55333 4.37492 7C4.37492 8.44667 5.55325 9.625 6.99992 9.625C8.44659 9.625 9.62492 8.44667 9.62492 7C9.62492 5.55333 8.44659 4.375 6.99992 4.375Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
