import {
  useSelectedAnomalyRelatedAnomaliesPathChange,
  useSelectedAnomalyRelatedAnomaliesPathChangeCount,
} from '../../../../data/PathChange';
import AssociatedAnomalies from '../../common/AssociatedAnomalies/AssociatedAnomalies';

export default function AssociatedAnomaliesPathChange() {
  const anomalyListQuery = useSelectedAnomalyRelatedAnomaliesPathChange;
  const anomalyCountQuery = useSelectedAnomalyRelatedAnomaliesPathChangeCount;

  return (
    <AssociatedAnomalies
      anomalyListQuery={anomalyListQuery}
      anomalyCountQuery={anomalyCountQuery}
    />
  );
}
