import {mapKeys, mapValues} from 'lodash';
import {AnomalyTypeBucket} from 'openapi-schema/schemaTS';
import {atom} from 'recoil';
import config from '../utils/AppConfigurationService';
import {Filters} from 'components/Katana/patterns/TableSearchWithFilters/TableSearchWithFilters';

const {EXTREME, HIGH, MEDIUM, LOW, NONE} = config.Priority;
const {READ, UNREAD, STARRED} = config.Status;
const {ANOMALY, ISSUE, NONE: NoneClass} = config.Class;

export const filterGroupKeys = {
  Type: 'Type',
  Priority: 'Priority',
  Status: 'Status',
  Class: 'Observation Class',
};

//Order of filters is not guaranteed
export const defaultAnomalySelectionFilters: Filters = {
  [filterGroupKeys.Type]: mapValues(
    mapKeys(AnomalyTypeBucket, (value, key) => AnomalyTypeBucket[key]),
    () => false
  ),
  [filterGroupKeys.Priority]: {
    [EXTREME]: false,
    [HIGH]: false,
    [MEDIUM]: false,
    [LOW]: false,
    [NONE]: false,
  },
  [filterGroupKeys.Status]: {
    [UNREAD]: false,
    [READ]: false,
    [STARRED]: false,
  },
  [filterGroupKeys.Class]: {
    [ANOMALY]: false,
    [ISSUE]: false,
    [NoneClass]: false,
  },
};

export const anomalySelectionFiltersState = atom({
  key: 'anomalySelectionFilters',
  default: defaultAnomalySelectionFilters,
});
