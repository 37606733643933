import {isEmpty} from 'lodash';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useFingerprintsObservationsList} from '../../../../data/AutoRemediation';
import useNavigateKeepParams from '../../../../helpers/hooks/useNavigateKeepParams';
import {FingerprintObservation, SortField} from '../../../../openapi-schema/fingerprintSchemaTS';
import {PriorityClassificationBucket} from '../../../../openapi-schema/schemaTS';
import Timestamp from '../../../common/Timestamp';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import PriorityData from '../../../AnomalySelection/DetailedAnomalyTable/components/PriorityData';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {
  Container,
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TableRow,
  TableData,
  ComponentTitleWrapper,
} from '../../../common/TableElements/TableElements';
import Status from '../../ServiceNowTableElements/Status';
import Ticket from '../../ServiceNowTableElements/Ticket';
import TableSortArrow from '../../../common/TableSortArrow/TableSortArrow';
import {pageSizeOptionsLarge as pageSizeOptions} from '../../../../constants/PageSizeOptions';
import Pagination from '../../../common/Pagination/Pagination';
import {scrollToTop} from '../../../../utils/scrollToTop';
import {convertSortToReactTableSchema} from '../../../../utils/convertSortToReactTableSchema';
import {convertSortToApiSchema} from '../../../../utils/convertSortToApiSchema';
import AffectedResourceWithTooltip from '../../../AnomalySelection/DetailedAnomalyTable/components/AffectedResourceWithTooltip';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  Row,
  flexRender,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const defaultSortFields = [
  {
    name: 'priority',
    desc: true,
  },
  {
    name: 'timestamp',
    desc: true,
  },
];

const columnHelper = createColumnHelper<FingerprintObservation>();

export default function MatchingObservationsTable() {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const [sortState, setSortState] = useState(defaultSortFields);
  const query = useFingerprintsObservationsList(
    APIPageIndex,
    APIPageSize,
    sortState as SortField[]
  );
  const navigateKeepParams = useNavigateKeepParams();
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const {data: APIdata} = query;

  const data = useMemo(
    () => (APIdata?.observation_details ? [...APIdata.observation_details] : []),
    [APIdata]
  );

  const columns = [
    columnHelper.accessor('priority', {
      header: 'Priority',
      size: 1,
      cell: props => <PriorityData priority={props.getValue() as PriorityClassificationBucket} />,
    }),
    columnHelper.accessor('affected_resources', {
      header: 'Affected',
      size: 1,
      cell: props => (
        <AffectedResourceWithTooltip affectedResources={props.getValue() as string[]} />
      ),
    }),
    columnHelper.accessor('observation_timestamp', {
      header: 'Timestamp',
      size: 1,
      cell: props => <Timestamp timestamp={props.getValue() as string} />,
    }),
    columnHelper.accessor('change_form_url', {
      header: 'Ticket',
      size: 1,
      cell: props => (
        <Ticket ticket_id={props.row.original.change_form_id} url={String(props.getValue())} />
      ),
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      size: 1,
      cell: props => <Status status={props.getValue()} />,
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(defaultSortFields, {
        timestamp: 'observation_timestamp',
      }),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
    };
  }, []);

  const {
    getHeaderGroups,
    getRowModel,
    resetRowSelection,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    enableRowSelection: true,
    pageCount: Math.ceil((APIdata?.total_number_of_observations || 0) / APIPageSize) || 1,
    manualPagination: true,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  const handleRowClick = (row: Row<{}>) => {
    row.toggleSelected(!row.getIsSelected());
  };

  const handleRowDoubleClick = (observation_id: string | undefined) => {
    if (!observation_id) return;

    navigateKeepParams({
      pathname: `/anomaly/${observation_id}`,
      clearStartEnd: true,
    });
  };

  useEffect(() => {
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
    resetRowSelection(true);
  }, [pageIndex, pageSize, resetRowSelection]);

  useEffect(() => {
    setPageIndex(0);
    resetRowSelection(true);
  }, [sorting, pageSize, setPageIndex, resetRowSelection]);

  useEffect(() => {
    // formats sorting object for query
    const replacements = {observation_timestamp: 'timestamp'};
    const newSortBy = convertSortToApiSchema(sorting, replacements);
    setSortState(newSortBy);
  }, [sorting]);

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="Matching Observations" />
      </ComponentTitleWrapper>
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={query}
            isEmpty={isEmpty(data)}
            emptyMessage="No matching observations found."
          >
            {getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                onDoubleClick={() => handleRowDoubleClick(row.original.observation_id)}
                onClick={() => handleRowClick(row)}
                isSelected={row.getIsSelected()}
              >
                {row.getVisibleCells().map(cell => (
                  <TableData key={cell.id} style={{...getCellStyles(cell)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </Container>
  );
}
