export const getPaginationText = (pageIndex: number, pageSize: number, rowCount: number) => {
  if (rowCount === 0) {
    return 'No Entries';
  }

  const startNumber = pageIndex * pageSize + 1;
  const endNumber = Math.min((pageIndex + 1) * pageSize, rowCount);

  return `${startNumber} - ${endNumber} of ${rowCount}`;
};
