import {atom} from 'recoil';
import {SortField} from '../openapi-schema/schemaTS';
export const reportingDashboardSortingStateDefault = [
  {
    name: 'priority_classification',
    desc: true,
  },
  {
    name: 'min_timestamp',
    desc: true,
  },
];
export const reportingDashboardSortingState = atom({
  key: 'reportingDashboardSorting',
  default: reportingDashboardSortingStateDefault as SortField[],
});
