import {EventObjectEdge} from 'cytoscape';
import {PathTraceDataEdge} from '../../../openapi-schema/schemaTS';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';
import styled from 'styled-components';
import {Container, GroupWrapper, Header, HiddenElement} from './TopologyTooltipElements';
import {DisplayElement} from './PathChangeNodeTooltip';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {groupBy} from 'lodash';

const PathLine = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const getPathDelayValue = (delay: number) => {
  if (delay) return `${delay.toFixed(2)} ms`;
  return '';
};

interface Props {
  event: EventObjectEdge;
}

export default function PathChangeEdgeTooltip({event}: Props) {
  const edge = event.target.data() as PathTraceDataEdge;
  const groupedNodes = groupBy(event.cy.nodes(), e => e.data()?.merge_id);

  return (
    <Tooltip
      tooltipContent={
        <Container>
          <GroupWrapper>
            <Header>PATH</Header>
            <PathLine>
              <DisplayElement
                value={groupedNodes?.[edge.source_merge_id]?.[0]?.data()?.hop_ip_address}
              />
              <ArrowRightAltIcon />
              <DisplayElement
                value={groupedNodes?.[edge.target_merge_id]?.[0]?.data()?.hop_ip_address}
              />
            </PathLine>
          </GroupWrapper>
          <GroupWrapper>
            <Header>PATH DELAY</Header>
            <DisplayElement
              value={getPathDelayValue(
                groupedNodes?.[edge.target_merge_id]?.[0]?.data()?.avg_response_time
              )}
            />
          </GroupWrapper>
        </Container>
      }
      mainContent={
        <HiddenElement x={event.originalEvent.offsetX} y={event.originalEvent.offsetY} />
      }
      onCanvas={true}
      maxWidth={'none'}
    />
  );
}
