import {
  useLogTemplatesPathChange,
  useLogTemplatesPathChangeCount,
} from '../../../../data/PathChange';
import AssociatedLogsTable from '../../common/AssociatedLogsTable/AssociatedLogsTable';

export default function AssociatedLogsPathChange() {
  return (
    <AssociatedLogsTable
      logListQuery={useLogTemplatesPathChange}
      logCountQuery={useLogTemplatesPathChangeCount}
    />
  );
}
