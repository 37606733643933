import {atom} from 'recoil';
import config from '../utils/AppConfigurationService';

interface effectArgs {
  setSelf: (arg: any) => void;
  onSet: (arg: any) => void;
}

const localStorageEffect =
  (key: string) =>
  ({setSelf, onSet}: effectArgs) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        try {
          localStorage.setItem(key, JSON.stringify(newValue));
        } catch (e) {
          return;
        }
      }
    });
  };

export const selectedTimezoneState = atom({
  key: 'selectedTimezone',
  default: config.timezoneCodes.UTC,
  effects: [localStorageEffect('timezone')],
});
