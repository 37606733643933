import styled from 'styled-components';
import envConfig from '../../../config/env.config';

const CLEARANCE_LEVEL = envConfig.clearanceLevel || 'No clearance level configured';
const CLEARANCE_LEVEL_COLOR = envConfig.clearanceLevelColor || '#00ff00';
export const CLEARANCE_BANNER_HEIGHT = '14px';

const ClearanceContainer = styled.div`
  clear: both;
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 9pt;
  line-height: ${CLEARANCE_BANNER_HEIGHT};
`;

const ClearanceHeader = styled.div`
  background-color: ${CLEARANCE_LEVEL_COLOR};
`;

export default function ClearanceBanner() {
  return (
    <ClearanceContainer>
      <ClearanceHeader>{CLEARANCE_LEVEL}</ClearanceHeader>
    </ClearanceContainer>
  );
}
