import TextField from '@mui/material/TextField';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import styled from 'styled-components';
import {selectedTimezoneState} from '../../../../atoms/selectedTimezone';
import {useRecoilValue} from 'recoil';
import {DateTime} from 'luxon';
import {monthDayYear} from '../../../../constants/timeFormats';

interface ContainerProps {
  error?: boolean;
}

const Container = styled.div<ContainerProps>`
  .MuiOutlinedInput-root {
    border: ${props =>
      props.error ? '1px solid rgba(205, 62, 56, 1)' : '1px solid rgba(78, 78, 78, 1)'};
    border-radius: 1px;
    padding: 6px;
  }

  .MuiOutlinedInput-input {
    padding: 0;
    color: white;
    font-size: 14px;
    font-weight: 400;
    height: 16px;
    width: 82px;
  }

  .MuiButtonBase-root {
    padding: 0;
    margin-right: 0px;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    color: white;
  }

  fieldset {
    display: none;
  }

  label {
    display: none;
  }
`;

const CALENDAR_DAYS_NUMBERS = `
  .MuiCalendarPicker-root,
  .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin
`;
const CALENDAR_DAYS_LETTERS = `
  .MuiCalendarPicker-root
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium,
  .MuiTypography-root.MuiTypography-caption
`;
const SELECTED_DAY = `.MuiButtonBase-root:focus.MuiPickersDay-root`;

interface CalendarProps {
  date: DateTime;
  setCustomDate: (date: DateTime) => void;
  error?: boolean;
}

export default function Calendar({date, setCustomDate, error}: CalendarProps) {
  const selectedTimezone = useRecoilValue(selectedTimezoneState);

  return (
    <Container error={error}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DesktopDatePicker
          PopperProps={{
            sx: {
              [CALENDAR_DAYS_NUMBERS]: {
                background: 'rgba(42, 42, 42, 1)',
                color: 'white',
              },
              [CALENDAR_DAYS_LETTERS]: {
                color: 'white',
              },
              [SELECTED_DAY]: {
                background: '#1565c0',
              },
            },
          }}
          inputFormat={monthDayYear}
          value={date.setZone(selectedTimezone)}
          onChange={(newValue, inputString) => {
            if (newValue?.isValid) {
              if (inputString) {
                setCustomDate(
                  DateTime.fromFormat(inputString, monthDayYear, {zone: selectedTimezone})
                );
              } else {
                setCustomDate(newValue);
              }
            }
          }}
          renderInput={params => (
            <TextField {...params} label="calendar input" autoComplete="off" />
          )}
          disableMaskedInput
        />
      </LocalizationProvider>
    </Container>
  );
}
