import {useRef, useMemo, useState, useEffect} from 'react';
import styled from 'styled-components';
import ErrorState from '../../../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import {DateTime} from 'luxon';
import {useRecoilValue} from 'recoil';
import {selectedTimezoneState} from '../../../../../atoms/selectedTimezone';
import {performanceMetricsToggleState} from '../../../../../atoms/performanceMetricsToggle';
import {timelineState} from '../../../../../atoms/dashboard';
import annotationPlugin from 'chartjs-plugin-annotation';
import {binnedTimestampFormat} from 'constants/timeFormats';
import {Line} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
  ChartOptions,
  ChartData,
  BarElement,
} from 'chart.js';
import {
  selectedAnomalyDataState,
  selectedAnomalyIdState,
} from '../../../../../atoms/selectedAnomaly';
import LegendTooltip from './LegendTooltip';
import {getChartLegendHeight} from '../../../../../utils/getChartHeight';
import {
  chartHorizontalAxisOptions,
  chartHorizontalTickOptions,
  chartLegendOptions,
  chartTooltipOptions,
  chartVerticalAxisOptions,
  chartVerticalAxisTickOptions,
  coreChartOptions,
} from '../../../../../constants/chartOptions';
import {HiddenLatencyChartLabelsByMainLabel} from '../../../../../constants/LatencyChartLabels';
import {LatencyChartMinMaxToggleState} from '../../../../../atoms/LatencyChartMinMaxToggle';
import config from '../../../../../utils/AppConfigurationService';
import useNavigateKeepParams from '../../../../../helpers/hooks/useNavigateKeepParams';
import {getChartXAxisTickLabels} from '../../../../../utils/getChartXAxisTickLabels';
import {getChartTooltipContent} from '../../../../../utils/getChartTooltipContent';
import {isEmpty} from 'lodash';
import NoResults from '../../../../common/NoResults/NoResults';
import {EmptyDataMessage} from '../../../../../constants/queryLifecycleMessages';
import {UseQueryResult} from 'react-query';
import {HotlAnomaly} from '../../../../../openapi-schema/schemaTS';
import {ChartJSOrUndefined} from 'react-chartjs-2/dist/types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin
);

const Container = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  position: relative;
`;

interface CoverProps {
  legendHeight: number;
}

const Cover = styled.div<CoverProps>`
  background: black;
  width: 100%;
  height: ${props => `calc(100% - ${props.legendHeight}px)`};
  position: absolute;
  top: 0;
`;

interface Props {
  query: UseQueryResult<any[], unknown>;
  data: ChartData<'line'>;
  anomalies?: HotlAnomaly[] | undefined;
  chartMinMax: {
    min: number;
    max: number;
  };
}

export default function PerformanceMetricsChart({query, data, anomalies, chartMinMax}: Props) {
  const chartRef = useRef<ChartJSOrUndefined<'line'>>(null);
  const [tooltip, setTooltip] = useState<{x: number; y: number} | undefined>(undefined);
  const {data: APIdata, isLoading, isError, isSuccess, refetch} = query;
  const selectedTimezone = useRecoilValue(selectedTimezoneState);
  const performanceMetricsToggle = useRecoilValue(performanceMetricsToggleState);
  const {selectedInterval} = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const LatencyChartMinMaxToggle = useRecoilValue(LatencyChartMinMaxToggleState);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);
  const navigateKeepParams = useNavigateKeepParams();

  const minimumThreshold = useMemo(() => {
    if (!APIdata || APIdata.length < 2) return 0;
    return (
      (DateTime.fromFormat(APIdata[APIdata?.length - 1].bin_key, binnedTimestampFormat).toMillis() -
        DateTime.fromFormat(APIdata[0].bin_key, binnedTimestampFormat).toMillis()) *
      0.02
    );
  }, [APIdata]);

  const options: ChartOptions<'line'> = {
    ...coreChartOptions,
    plugins: {
      legend: {
        ...chartLegendOptions,
        onHover: evt => {
          if (!evt.native) return;
          document.body.style.cursor = 'pointer';
          const {x, y} = evt.native as MouseEvent;
          setTooltip({x, y});
        },
        onLeave: () => {
          document.body.style.cursor = 'auto';
          setTooltip(undefined);
        },
        onClick: (e, legendItem, legend) => {
          const clickedLabelIndex = legendItem.datasetIndex;
          const chartInstance = legend.chart;

          const toggle = (labels: string[]) => {
            labels.forEach((label: string) => {
              const associatedLabelIndex = chartInstance.data.datasets.findIndex(
                dataset => dataset.label === label
              );

              if (chartInstance.isDatasetVisible(clickedLabelIndex)) {
                chartInstance.hide(associatedLabelIndex);
                chartInstance.data.datasets[associatedLabelIndex].hidden = true;
              } else if (LatencyChartMinMaxToggle) {
                chartInstance.show(associatedLabelIndex);
                chartInstance.data.datasets[associatedLabelIndex].hidden = false;
              }
            });
          };

          if (selectedAnomalyData?.anomaly_type === config.AnomalyTypes.LATENCY) {
            toggle(HiddenLatencyChartLabelsByMainLabel[legendItem.text]);
          }

          if (chartInstance.isDatasetVisible(clickedLabelIndex)) {
            chartInstance.hide(clickedLabelIndex);
            legendItem.hidden = true;
          } else {
            chartInstance.show(clickedLabelIndex);
            legendItem.hidden = false;
          }
        },
      },
      tooltip: {
        ...chartTooltipOptions,
        callbacks: {
          title: () => '',
          label: context => getChartTooltipContent(context, selectedTimezone),
        },
      },
      annotation: {
        annotations:
          performanceMetricsToggle && APIdata
            ? anomalies?.map(a => {
                const anomalyStart = DateTime.fromISO(a.min_timestamp, {zone: 'UTC'}).toMillis();
                const anomalyEnd = DateTime.fromISO(a.max_timestamp, {zone: 'UTC'}).toMillis();
                const anomalyMiddle = (anomalyStart + anomalyEnd) / 2;
                const anomalyWidth = anomalyStart - anomalyEnd;
                const isTooSmall = anomalyWidth < minimumThreshold;

                return {
                  type: 'box',
                  xScaleID: 'x',
                  yScaleID: 'left',
                  xMin: isTooSmall ? anomalyMiddle - minimumThreshold / 2 : anomalyStart,
                  xMax: isTooSmall ? anomalyMiddle + minimumThreshold / 2 : anomalyEnd,
                  yMin: 0,
                  yMax: 10000000,
                  backgroundColor:
                    a.anomaly_id === selectedAnomalyId
                      ? 'rgba(128, 30, 17, 1)'
                      : 'rgba(64, 15, 8, 1)',
                  borderWidth: 0,
                  drawTime: 'beforeDraw',
                  adjustScaleRange: false,
                  dblclick: () => {
                    if (a.anomaly_id === selectedAnomalyId) return;
                    navigateKeepParams({pathname: `/anomaly/${a.anomaly_id}`, clearStartEnd: true});
                  },
                  enter: () => {
                    if (a.anomaly_id === selectedAnomalyId) return;
                    document.body.style.cursor = 'pointer';
                  },
                };
              })
            : [],
        leave: () => (document.body.style.cursor = 'auto'),
      },
    },
    scales: {
      percentage_left: {
        ...chartVerticalAxisOptions,
        position: 'left',
        ticks: {
          ...chartVerticalAxisTickOptions,
          callback: value => `${value}%`,
        },
        min: 0,
      },
      ms_right: {
        ...chartVerticalAxisOptions,
        position: 'right',
        ticks: {
          ...chartVerticalAxisTickOptions,
          callback: value => `${value}ms`,
        },
        min: 0,
      },
      right: {
        ...chartVerticalAxisOptions,
        position: 'right',
        ticks: {
          ...chartVerticalAxisTickOptions,
        },
        min: 0,
      },
      left: {
        ...chartVerticalAxisOptions,
        position: 'left',
        ticks: {
          ...chartVerticalAxisTickOptions,
        },
        min: 0,
      },
      ms_left: {
        ...chartVerticalAxisOptions,
        position: 'left',
        ticks: {
          ...chartVerticalAxisTickOptions,
          callback: value => `${value}ms`,
        },
        min: 0,
      },
      x: {
        ...chartHorizontalAxisOptions,
        min: chartMinMax.min,
        max: chartMinMax.max,
        ticks: {
          ...chartHorizontalTickOptions,
          callback: value =>
            getChartXAxisTickLabels(Number(value), selectedInterval, selectedTimezone),
        },
      },
    },
  };

  const chartLegendHeight = getChartLegendHeight(chartRef.current);

  useEffect(() => {
    if (!chartRef.current?.legend) return;
    const chartInstance = chartRef.current.legend.chart;
    if (selectedAnomalyData?.anomaly_type !== config.AnomalyTypes.LATENCY) return;

    Object.keys(HiddenLatencyChartLabelsByMainLabel).forEach(mainLabel => {
      HiddenLatencyChartLabelsByMainLabel[mainLabel].forEach(label => {
        const labelIndex = chartInstance.data.datasets.findIndex(
          dataset => dataset.label === label
        );

        if (
          LatencyChartMinMaxToggle &&
          chartInstance.isDatasetVisible(
            chartInstance.data.datasets.findIndex(dataset => dataset.label === mainLabel)
          )
        ) {
          chartInstance?.show(labelIndex);
          chartInstance.data.datasets[labelIndex].hidden = false;
        } else {
          chartInstance?.hide(labelIndex);
          chartInstance.data.datasets[labelIndex].hidden = true;
        }
      });
    });
  }, [LatencyChartMinMaxToggle, selectedAnomalyData?.anomaly_type]);

  return (
    <Container>
      <Line options={options} data={data} ref={chartRef} />
      {isEmpty(APIdata) && isSuccess && (
        <Cover legendHeight={chartLegendHeight}>
          <NoResults message={EmptyDataMessage} />
        </Cover>
      )}
      {isLoading && (
        <Cover legendHeight={chartLegendHeight}>
          <LoadingSpinner />
        </Cover>
      )}
      {isError && (
        <Cover legendHeight={chartLegendHeight}>
          <ErrorState retry={refetch} />
        </Cover>
      )}
      {tooltip && <LegendTooltip tooltipData={tooltip} />}
    </Container>
  );
}
