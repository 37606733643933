import config from '../utils/AppConfigurationService';
import {DateTime} from 'luxon';
import openTelemetry from '@opentelemetry/api';
import UAParser from 'ua-parser-js';
import {withErrorBoundary} from 'react-error-boundary';
import ErrorState from '../components/Katana/components/ErrorState/ErrorState';
import Button from '../components/Katana/components/Button/Button';
import {ComponentType} from 'react';

const parser = new UAParser();
const res = parser.getResult() || {};
const sendErrorTrace = (error: Error) => {
  try {
    if (!config?.tracing?.url) {
      console.group();
      console.warn('No tracing URL set in the config! Error reporting is disabled');
      console.error('Caught error in Fallback:', error);
      console.groupEnd();
      return;
    }
    const now = DateTime.now();
    const tracer = openTelemetry.trace.getTracer('fallback_error_reporter');
    tracer.startActiveSpan(error.name, span => {
      span.setAttributes({
        'error.message': error.message,
        'error.stack': error.stack || '',
        'error.name': error.name,
        'client-time': now.toISO(),
        'client-tz': now.toFormat('ZZZZ'),
        'window.location.pathname': window.location.pathname,
        'window.location.search': window.location.search,
        'window.height': window.innerHeight,
        'window.width': window.innerWidth,
        'window.screen.height': window.screen.height,
        'window.screen.width': window.screen.width,
        'window.screen.orientation': window.screen.orientation.type,
        'user-agent': res.ua,
        'ua.browser.name': res.browser?.name,
        'ua.browser.version': res.browser?.version,
        'ua.os.name': res.os?.name,
        'ua.os.version': res.os?.version,
        'ua.engine.name': res.engine?.name,
        'ua.engine.version': res.engine?.version,
        'ua.device.model': res.device?.model,
        'ua.device.type': res.device?.type,
        'ua.device.vendor': res.device?.vendor,
        'ua.cpu.architecture': res.cpu?.architecture,
      });
      span.end();
    });
  } catch (e) {
    console.error('Unhandled error with sending error to server', e);
  }
};

const withTracingErrorBoundary = (component: ComponentType) => {
  return withErrorBoundary(component, {
    fallback: (
      <ErrorState message={'Unable to display this dashboard'}>
        <Button variant="text" href={window.origin} label="Go to AIOps Home" />
      </ErrorState>
    ),
    onError: (error, info) => {
      sendErrorTrace(error);
    },
  });
};

export {sendErrorTrace, withTracingErrorBoundary};
