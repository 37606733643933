import {useCallback} from 'react';
import styled from 'styled-components';
import {useSelectedAnomaly} from '../../../../data/Anomalies';
import KPIErrorState from '../../../Katana/components/KPIErrorState/KPIErrorState';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import config from '../../../../utils/AppConfigurationService';
import {
  useLogAnomalyKPIs,
  useInterfaceUtilizationKPIs,
  useLatencyKPIs,
  useBGPKPIs,
  useISISKPIs,
  useExchangeLogAnomalyKPIs,
} from '../../../../data/KPI';
import {Duration} from 'luxon';
import {formatNeighborDropTime} from '../../../../utils/formatTime';
import SentimentTooltip from './SentimentTooltip';
import CriticalityTooltip from './CriticalityTooltip';
import KPIBox from 'components/common/KPIBox/KPIBox';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 10px;
  background-color: rgba(34, 36, 41, 1);
`;

function LogAnomalyKPIList() {
  const {data, isError, isLoading, refetch} = useLogAnomalyKPIs();
  if (isError) {
    return <KPIErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (data) {
    return (
      <Container>
        <KPIBox
          measure="Template Count"
          value={data?.template_log_count}
          tooltipContent={
            <p>
              The number of unique <b>Log Templates</b> in the selected time window.
            </p>
          }
          tooltipMaxWidth="320px"
        />
        <KPIBox
          measure="Log Volume"
          value={data?.host_log_count}
          tooltipContent={'The number of individual log messages in the selected time window.'}
          tooltipMaxWidth="320px"
        />
        <KPIBox
          measure="7 Day Sentiment"
          value={data?.negative_sentiment?.toFixed(2)}
          tooltipContent={<SentimentTooltip />}
          tooltipMaxWidth="350px"
        />
        <KPIBox
          measure="7 Day Criticality"
          value={data?.criticality?.toFixed(2)}
          tooltipContent={<CriticalityTooltip />}
          tooltipMaxWidth="350px"
        />
      </Container>
    );
  } else {
    return <KPIErrorState retry={refetch} />;
  }
}

function ExchangeLogAnomalyKPIList() {
  const {data, isError, isLoading, refetch} = useExchangeLogAnomalyKPIs();
  if (isError) {
    return <KPIErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (data) {
    return (
      <Container>
        <KPIBox
          measure="Template Count"
          value={data?.template_log_count}
          tooltipContent={
            <p>
              The number of unique <b>Log Templates</b> in the selected time window.
            </p>
          }
          tooltipMaxWidth="320px"
        />
        <KPIBox
          measure="Log Volume"
          value={data?.host_log_count}
          tooltipContent={'The number of individual log messages in the selected time window.'}
          tooltipMaxWidth="320px"
        />
        <KPIBox
          measure="7 Day Sentiment"
          value={data?.negative_sentiment?.toFixed(2)}
          tooltipContent={<SentimentTooltip />}
          tooltipMaxWidth="350px"
        />
        <KPIBox
          measure="7 Day Criticality"
          value={data?.criticality?.toFixed(2)}
          tooltipContent={<CriticalityTooltip />}
          tooltipMaxWidth="350px"
        />
      </Container>
    );
  } else {
    return <KPIErrorState retry={refetch} />;
  }
}

function InterfaceUtilizationKPIList() {
  const {data, isError, isLoading, refetch} = useInterfaceUtilizationKPIs();
  const formatRange = useCallback((min: number, max: number) => {
    return `${(min ?? 0).toFixed(1)} - ${(max ?? 0).toFixed(1)}%`;
  }, []);

  if (isError) {
    return <KPIErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (data) {
    const {min_in, max_in, min_out, max_out} = data?.utilization_range ?? {};
    const {avg_in, avg_out} = data?.utilization_historical_average ?? {};
    return (
      <Container>
        <KPIBox measure="Utilization Range In" value={formatRange(min_in, max_in)} />
        <KPIBox measure="Utilization Range Out" value={formatRange(min_out, max_out)} />
        <KPIBox
          measure="7 Day Average Utilization In/Out"
          value={`${(avg_in ?? 0).toFixed(1)}% / ${(avg_out ?? 0).toFixed(1)}%`}
        />
        <KPIBox measure="Related Device Count" value={data?.related_device_count} />
      </Container>
    );
  } else {
    return <KPIErrorState retry={refetch} />;
  }
}

function LatencyKPIList() {
  const {data, isError, isLoading, refetch} = useLatencyKPIs();

  if (isError) {
    return <KPIErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (data) {
    return (
      <Container>
        <KPIBox
          measure="7 Day Delay Avg"
          value={`${data?.delay_historical_average?.toFixed(2)} ms`}
        />
        <KPIBox
          measure="Observation Delay Avg"
          value={`${data?.delay_current_average?.toFixed(2)} ms`}
        />
        <KPIBox measure="Related Anomalous Devices" value={data?.related_device_count} />
      </Container>
    );
  } else {
    return <KPIErrorState retry={refetch} />;
  }
}

function BGPNeighborKPIList() {
  const {data, isError, isLoading, refetch} = useBGPKPIs();

  if (isError) {
    return <KPIErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (data) {
    return (
      <Container>
        <KPIBox measure="BGP Identifier" value={data.bgp_identifier} />
        <KPIBox measure="Local AS" value={data.local_as} />
        <KPIBox
          measure="System Uptime"
          value={formatNeighborDropTime(Duration.fromObject({milliseconds: data.sys_up_time_ms}))}
          small
        />
        <KPIBox measure="External BGP Peers" value={data.external_bgp_peers} />
        <KPIBox measure="Internal BGP Peers" value={data.internal_bgp_peers} />
        <KPIBox
          measure="Avg Peer Established Time"
          value={formatNeighborDropTime(
            Duration.fromObject({milliseconds: data.avg_peer_established_time})
          )}
          small
        />
      </Container>
    );
  } else {
    return <KPIErrorState retry={refetch} />;
  }
}

function ISISNeighborKPIList() {
  const {data, isError, isLoading, refetch} = useISISKPIs();

  if (isError) {
    return <KPIErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (data) {
    return (
      <Container>
        <KPIBox measure="System Name" value={data.system_name} />
        <KPIBox
          measure="System Uptime"
          value={formatNeighborDropTime(Duration.fromObject({milliseconds: data.sys_up_time_ms}))}
          small
        />
      </Container>
    );
  } else {
    return <KPIErrorState retry={refetch} />;
  }
}

export default function AnomalyDetailKPI() {
  const {data, isError, isLoading, isIdle, refetch} = useSelectedAnomaly();

  if (isError) return <KPIErrorState retry={refetch} />;
  if (isLoading || isIdle) return <LoadingSpinner />;
  if (data) {
    let KPIBlock = <KPIErrorState retry={refetch} />;
    if (data.anomaly_type === config.AnomalyTypes.LOG) {
      KPIBlock = <LogAnomalyKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.LATENCY) {
      KPIBlock = <LatencyKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.INTERFACE) {
      KPIBlock = <InterfaceUtilizationKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.NEIGHBOR_DROP_BGP) {
      KPIBlock = <BGPNeighborKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.NEIGHBOR_DROP_ISIS) {
      KPIBlock = <ISISNeighborKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.EXCHANGE_LOG) {
      KPIBlock = <ExchangeLogAnomalyKPIList />;
    }

    return KPIBlock;
  } else {
    return <KPIErrorState retry={refetch} />;
  }
}
