import {useResetRecoilState, useSetRecoilState} from 'recoil';
import {stepWizardCurrentPageState} from '../../atoms/StepWizardCurrentPage';

const useStepWizardActions = () => {
  const setStepWizardPage = useSetRecoilState(stepWizardCurrentPageState);
  const resetstepWizardCurrentPage = useResetRecoilState(stepWizardCurrentPageState);

  const resetStepWizard = () => {
    resetstepWizardCurrentPage();
  };

  const goToNextPage = (onLastPage: boolean, onSubmit: () => void) => {
    if (onLastPage) {
      onSubmit();
    } else {
      setStepWizardPage(currVal => currVal + 1);
    }
  };

  const goToPreviousPage = () => {
    setStepWizardPage(currVal => currVal - 1);
  };

  return {resetStepWizard, goToNextPage, goToPreviousPage};
};

export default useStepWizardActions;
