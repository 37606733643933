import {useRecoilState} from 'recoil';
import {snackbarDataState} from '../../../../../atoms/snackbarData';
import SnackbarNotification from '../SnackbarNotification';

export default function SnackbarNotificationWrapper() {
  const [snackbarData, setSnackbarData] = useRecoilState(snackbarDataState);

  const handleClose = () => {
    setSnackbarData({...snackbarData, open: false});
  };

  const handleSubtitleCallback = () => {
    setSnackbarData({...snackbarData, open: false});
    if (!snackbarData.subtitleCallback) return;
    snackbarData.subtitleCallback();
  };

  return (
    <SnackbarNotification
      severity={snackbarData.severity}
      message={snackbarData.message}
      subtitle={snackbarData.subtitle}
      duration={snackbarData.duration}
      open={snackbarData.open}
      handleClose={handleClose}
      subtitleCallback={snackbarData.subtitleCallback ? handleSubtitleCallback : undefined}
    />
  );
}
