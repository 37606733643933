import styled from 'styled-components';
import {RecoilState, useRecoilState} from 'recoil';
import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';
import {ColumnToggleIcon} from 'components/Katana/Icons';

interface ContainerProps {
  selectorOpen: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-content: center;
  background: ${props => (props.selectorOpen ? 'rgba(222, 166, 255, 0.2)' : 'inherit')};

  :hover {
    background: rgba(222, 166, 255, 0.2);
  }
`;

interface Props {
  recoilState: RecoilState<boolean>;
}
export default function ColumnToggle({recoilState}: Props) {
  const [selectorOpen, setSelectorOpen] = useRecoilState(recoilState);

  return (
    <TitleTooltip
      header="Edit table columns"
      tooltipContent="Select which columns you want to display in this table."
      mainContent={
        <Container
          selectorOpen={selectorOpen}
          id={recoilState.key}
          onClick={() => setSelectorOpen(currVal => !currVal)}
        >
          <ColumnToggleIcon />
        </Container>
      }
      maxWidth="250px"
    />
  );
}
