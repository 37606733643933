import IconComponent, {IconProps} from '../IconComponent';

export default function FiberChannelIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="FiberChannelIcon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4L13 7L10.8276 7H4V9L1 6H4H10V4Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
