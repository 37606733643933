import {LegendItem} from 'chart.js';
import {HiddenLatencyChartLabels} from './LatencyChartLabels';

export const coreChartOptions = {
  responsive: true,
  interaction: {
    mode: 'nearest' as const,
    intersect: true,
  },
  maintainAspectRatio: false,
  animation: false as const,
};

export const chartLegendOptions = {
  position: 'bottom' as const,
  align: 'center' as const,
  labels: {
    usePointStyle: true,
    pointStyle: 'circle',
    color: 'white',
    padding: 20,
    boxWidth: 11,
    boxHeigh: 11,
    font: {
      weight: '700',
    },
    filter: (item: LegendItem) => !HiddenLatencyChartLabels[item.text],
  },
};

export const chartTooltipOptions = {
  backgroundColor: 'rgba(63, 63, 63, 0.8)',
  bodyFont: {
    size: 14,
    weight: '500',
  },
  usePointStyle: true,
  cornerRadius: 0,
  padding: 5,
  caretPadding: 20,
  caretSize: 0,
  displayColors: false,
  xAlign: 'center' as const,
  yAlign: 'bottom' as const,
};

export const chartVerticalAxisTickOptions = {
  color: 'white',
  padding: 0,
  font: {
    size: 12,
    weight: '700',
  },
};

export const chartVerticalAxisOptions = {
  type: 'linear' as const,
  display: 'auto' as const,
  beginAtZero: true,
  grid: {
    drawOnChartArea: false,
    borderColor: 'rgba(116, 116, 116, 1)',
  },
  ticks: chartVerticalAxisTickOptions,
};

export const chartHorizontalTickOptions = {
  includeBounds: false,
  color: 'white',
  font: {
    size: 12,
    weight: '500',
  },
};

export const chartHorizontalAxisOptions = {
  type: 'linear' as const,
  grid: {
    drawOnChartArea: false,
    borderColor: 'rgba(116, 116, 116, 1)',
  },
  ticks: chartHorizontalTickOptions,
};
