import styled from 'styled-components';
import {AnomalyTemplate, HydratedLogElement} from '../../../../../openapi-schema/schemaTS';
import {SystemVariableData} from './SystemVariable';
import VariableAssignmentRow from './VariableAssignmentRow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface RowProps {
  animationActive?: boolean;
}

export const Row = styled.div<RowProps>`
  display: flex;
  gap: 8px;
  animation: ${props => (props.animationActive ? 'pulse 1.5s ease alternate' : '')};
`;

const HeaderText = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
`;

export const RowText = styled.p`
  font-size: 13px;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ConatinerWithArrow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 25px;
  min-width: 0;
`;

export const IconWrapper = styled.div`
  display: grid;
  place-content: center;
`;

export const VariableColumnSize = 4;
export const ValueColumnSize = 4;
export const AliasColumnSize = 5;

interface Props {
  variablesArray: HydratedLogElement[] | SystemVariableData[] | undefined;
  template?: AnomalyTemplate;
}

export default function VariableAssignment({variablesArray, template}: Props) {
  return (
    <Container>
      <Row>
        <HeaderText style={{flex: VariableColumnSize}}>Variable Name</HeaderText>
        <HeaderText style={{flex: ValueColumnSize}}>Value</HeaderText>
        <HeaderText style={{flex: AliasColumnSize}}>Attached Alias</HeaderText>
      </Row>
      <Content>
        {variablesArray?.map((element: HydratedLogElement | SystemVariableData, index: number) => (
          <VariableAssignmentRow
            key={element.value + String(index)}
            element={element}
            template={template}
            index={index}
          />
        ))}
      </Content>
    </Container>
  );
}
