import {Duration} from 'luxon';
import {
  useSelectedAnomalyISISAdjacencies,
  useSelectedAnomalyISISAdjacenciesCount,
} from '../../../../data/ISIS';
import {FlaggedNeighborDropIsisAdjacency} from '../../../../openapi-schema/schemaTS';
import {formatNeighborDropTime} from '../../../../utils/formatTime';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import ObservationDetailTable from '../../common/ObservationDetailTable/ObservationDetailTable';
import {Container, ComponentTitleWrapper} from 'components/common/TableElements/TableElements';
import {createColumnHelper} from '@tanstack/react-table';

const initialSortBy = [
  {
    name: 'node_name',
    desc: true,
  },
];

const columnHelper = createColumnHelper<FlaggedNeighborDropIsisAdjacency>();

export default function ISISNeighborDropAnomalyTable() {
  const columns = [
    columnHelper.accessor('node_name', {
      header: 'Name',
      size: 1,
    }),
    columnHelper.accessor('isis_adj_neighbor_sys_type', {
      header: 'Type',
      size: 1,
    }),
    columnHelper.accessor('isis_adj_ip_addr_address', {
      header: 'Remote Address',
      size: 1,
    }),
    columnHelper.accessor('isis_adj_state', {
      header: 'State',
      size: 1,
    }),
    columnHelper.accessor('isis_adj_neighbor_snpa_address', {
      header: 'SNPA Address',
      size: 1,
    }),
    columnHelper.accessor('isis_adj_last_up_time_ms', {
      header: 'Last Up Time',
      size: 1,
      cell: props => (
        <p>{formatNeighborDropTime(Duration.fromObject({milliseconds: props.getValue()}))}</p>
      ),
    }),
  ];

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="IS-IS Adjacencies" />
      </ComponentTitleWrapper>
      <ObservationDetailTable
        tableDataQuery={useSelectedAnomalyISISAdjacencies}
        dataCountQuery={useSelectedAnomalyISISAdjacenciesCount}
        columns={columns}
        initialSortBy={initialSortBy}
      />
    </Container>
  );
}
