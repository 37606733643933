import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import Footer from './components/Footer';
import ProgressBar from './components/ProgressBar';
import useStepWizardActions from '../../../helpers/hooks/useStepWizardActions';
import {stepWizardCurrentPageState} from '../../../atoms/StepWizardCurrentPage';
import PageTitle from './components/PageTitle';
import {BannerAwareDialog} from '../BannerAwareDialog/BannerAwareDialog';

const Container = styled.div`
  background: rgba(25, 25, 25, 1);
  color: white;
  display: flex;
  flex-direction: column;
  width: min(100%, 1300px);
  height: min(100%, 900px);
`;

const MainContentWrapper = styled.div`
  flex-grow: 1;
  min-height: 0;
  padding: 0 10px 10px 10px;
`;

export interface StepWizardPage {
  stepLabel: string;
  subtitle?: React.ReactNode;
  nextButton?: string;
  component: JSX.Element;
  tooltip?: React.ReactNode;
  isNextDisabled: boolean;
}

interface Props {
  open: boolean;
  pages: StepWizardPage[];
  onExit: () => void;
  onClose: () => void;
  onSubmit: () => void;
}

export default function StepWizard({open, pages, onExit, onClose, onSubmit}: Props) {
  const stepWizardCurrentPage = useRecoilValue(stepWizardCurrentPageState);
  const {resetStepWizard} = useStepWizardActions();

  const {stepLabel, subtitle, component} = pages[stepWizardCurrentPage];

  return (
    <BannerAwareDialog
      TransitionProps={{
        // Resets the state of the popup after the
        // transition animation is finished.
        onExited: () => {
          onExit();
          resetStepWizard();
        },
      }}
      open={open}
      maxWidth={false}
    >
      <Container>
        <ProgressBar pages={pages} onClose={onClose} />
        <PageTitle title={stepLabel} subtitle={subtitle} />
        <MainContentWrapper>{component}</MainContentWrapper>
        <Footer pages={pages} onSubmit={onSubmit} onClose={onClose} />
      </Container>
    </BannerAwareDialog>
  );
}
