import ChatMessage from 'components/Katana/components/ChatMessage/ChatMessage';
import SenseiInProgress from './SenseiInProgress';
import {useRecoilValue} from 'recoil';
import {senseiChatSessionState} from 'atoms/sensei';
import {useAuth} from 'react-oidc-context';
import {Fragment, useEffect, useRef} from 'react';
import {Bar} from 'react-chartjs-2';
import {isUserQuestion} from '../../../helpers/senseiHelpers';

export default function ChatLog() {
  const chatEndRef = useRef<HTMLDivElement>(null);
  const auth = useAuth();
  const senseiChatMessages = useRecoilValue(senseiChatSessionState);
  const senseiIsThinking =
    senseiChatMessages.length === 0 ||
    isUserQuestion(senseiChatMessages[senseiChatMessages.length - 1]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [senseiChatMessages]);

  return (
    <div className="flex w-full flex-col gap-5 overflow-auto p-4">
      {senseiChatMessages.map((message, index) => {
        const isUserMessage = isUserQuestion(message);

        if (isUserMessage) {
          return (
            <ChatMessage
              key={String(message?.request_id) + index}
              name={auth.user?.profile.name || 'User'}
              side={'right'}
              sender={'user'}
              content={message.questions[0]}
            />
          );
        } else {
          const textResponse = message.responses?.find(response => response.data_type === 'TEXT');
          const chartReponse = message.responses?.find(
            response => response.data_type === 'CHART_JS'
          );

          return (
            <Fragment key={String(message?.request_id) + index}>
              {textResponse && (
                <ChatMessage
                  name={'Sensei'}
                  side={'left'}
                  sender={'Sensei'}
                  content={(textResponse?.data as string) || ''}
                />
              )}
              {chartReponse && (
                // TODO wrap in error handling component
                //@ts-ignore -- types are not provided for data property
                <Bar data={chartReponse.data.data} options={chartReponse.data.options} />
              )}
            </Fragment>
          );
        }
      })}
      {senseiIsThinking && <SenseiInProgress message="" />}
      <div ref={chatEndRef} />
    </div>
  );
}
