import {InterfaceMetrics, NodeMetrics} from 'constants/WhatIfMetrics';

const WarningLevelDifference = 5;
export const whatIfTopologyColors = {
  EdgeHigh: 'rgba(205, 62, 56, 1)',
  EdgeMedium: 'rgba(237, 178, 28, 1)',
  EdgeDefault: 'rgba(58, 60, 66, 1)',
  EdgeSelected: 'rgba(45, 98, 235, 1)',
  NodeHighBackground: 'rgba(205, 62, 56, 1)',
  NodeHighBorder: 'rgba(255, 129, 136)',
  NodeMediumBackground: 'rgba(237, 178, 28, 1)',
  NodeMediumBorder: 'rgb(244, 209, 119)',
  NodeDefaultBackground: 'rgba(65, 71, 74, 1)',
  NodeDefaultBorder: 'rgba(141, 145, 146, 1)',
  NodeSelectedBackground: 'rgba(45, 98, 235, 1)',
  NodeSelectedBorder: 'rgba(255, 255, 255, 1)',
};

export const getNodeColors = (
  scaledMetric: number | undefined,
  warningHighThreshold: number,
  selectedMetric: string
) => {
  if (selectedMetric in InterfaceMetrics || scaledMetric === undefined) {
    return {
      background: whatIfTopologyColors.NodeDefaultBackground,
      border: whatIfTopologyColors.NodeDefaultBorder,
    };
  }

  const warningMediumThreshold = warningHighThreshold - WarningLevelDifference;

  if (scaledMetric >= warningHighThreshold)
    return {
      background: whatIfTopologyColors.NodeHighBackground,
      border: whatIfTopologyColors.NodeHighBorder,
    };
  if (scaledMetric >= warningMediumThreshold)
    return {
      background: whatIfTopologyColors.NodeMediumBackground,
      border: whatIfTopologyColors.NodeMediumBorder,
    };
  return {
    background: whatIfTopologyColors.NodeDefaultBackground,
    border: whatIfTopologyColors.NodeDefaultBorder,
  };
};

export const getEdgeColor = (
  scaledMetric: number | undefined,
  warningHighThreshold: number,
  selectedMetric: string
) => {
  if (selectedMetric in NodeMetrics || scaledMetric === undefined) {
    return whatIfTopologyColors.EdgeDefault;
  }

  const warningMediumThreshold = warningHighThreshold - WarningLevelDifference;

  if (scaledMetric >= warningHighThreshold) {
    return whatIfTopologyColors.EdgeHigh;
  }
  if (scaledMetric >= warningMediumThreshold) {
    return whatIfTopologyColors.EdgeMedium;
  }
  return whatIfTopologyColors.EdgeDefault;
};
