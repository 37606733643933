export const tryNumber = (inputValue: any, defaultValue: number): number => {
  const number = parseFloat(String(inputValue));
  return !isNaN(number) ? number : defaultValue;
};

export const tryBoolean = (inputValue: any, defaultValue: boolean): boolean => {
  return String(inputValue)?.toLowerCase() === 'true' ?? defaultValue;
};

export const tryString = (inputValue: any, defaultValue: string): string => {
  return inputValue && typeof inputValue === 'string' ? String(inputValue) : defaultValue;
};
