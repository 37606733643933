import useARActions from '../../../../helpers/hooks/useARActions';
import {ARPopupOpenState} from '../../../../atoms/ARPopupOpen';
import {pageSizeOptionsMedium} from '../../../../constants/PageSizeOptions';
import StepWizard from '../../../common/StepWizard/StepWizard';
import {useRecoilState} from 'recoil';
import {useEffect} from 'react';
import {useFingerprintCreationTemplateList} from 'data/AutoRemediation';

export default function AutomateWindow() {
  useFingerprintCreationTemplateList(0, pageSizeOptionsMedium[0]);
  const [ARPopupOpen, setARPopupOpen] = useRecoilState(ARPopupOpenState);
  const {resetAllStates, handleLastPageSubmit, getStepWizardPages, closePopup} = useARActions();

  useEffect(() => () => setARPopupOpen(false), [setARPopupOpen]);

  return (
    <StepWizard
      open={ARPopupOpen}
      pages={getStepWizardPages()}
      onExit={resetAllStates}
      onClose={closePopup}
      onSubmit={handleLastPageSubmit}
    />
  );
}
