import useTopologyActions from '../../../../helpers/hooks/useTopologyActions';
import useNavigateKeepParams from '../../../../helpers/hooks/useNavigateKeepParams';
import {HotlAnomaly} from '../../../../openapi-schema/schemaTS';
import {TableRow as Container, TableData} from '../../../common/TableElements/TableElements';
import {Row, flexRender} from '@tanstack/react-table';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

interface Props {
  row: Row<HotlAnomaly>;
}

export default function TableRow({row}: Props) {
  const {selectAnomaly} = useTopologyActions();
  const navigateKeepParams = useNavigateKeepParams();
  const read = Boolean(row.original.feedback?.read);

  const onClick = (row: Row<HotlAnomaly>) => {
    if (row.getIsSelected()) return;

    row.toggleSelected(true);
    selectAnomaly({anomaly: row.original, detailsPage: false});
  };

  return (
    <Container
      onDoubleClick={() =>
        navigateKeepParams({pathname: `/anomaly/${row.original.anomaly_id}`, clearStartEnd: true})
      }
      onClick={() => onClick(row)}
      isRead={read}
      isSelected={row.getIsSelected()}
    >
      {row.getVisibleCells().map(cell => (
        <TableData key={cell.id} style={{...getCellStyles(cell)}}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableData>
      ))}
    </Container>
  );
}
