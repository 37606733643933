import {atom} from 'recoil';

export const defaultsnmpMetricsTableSortingState = [
  {
    name: 'node_name',
    desc: false,
  },
];

export const snmpMetricsTableSortingState = atom({
  key: 'snmpMetricsTableSorting',
  default: defaultsnmpMetricsTableSortingState,
});
