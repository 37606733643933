import IconComponent, {IconProps} from '../IconComponent';

export default function ReadStatusIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="ReadStatusIcon"
      >
        <path
          d="M1.80841 4.76581L6.77666 1.81123C6.83443 1.77696 6.89931 1.75645 6.96628 1.75129C7.03324 1.74613 7.1005 1.75646 7.16283 1.78148L7.2235 1.81123L12.1917 4.76581C12.373 4.87355 12.5256 5.02336 12.6367 5.20258C12.7478 5.3818 12.8141 5.58514 12.8299 5.79539L12.8334 5.89456V10.3547C12.8334 10.839 12.6482 11.3049 12.3156 11.6569C11.983 12.0088 11.5284 12.2202 11.0449 12.2476L10.9376 12.25H3.06258C2.57833 12.25 2.11243 12.0647 1.76045 11.7322C1.40846 11.3996 1.19708 10.9449 1.16966 10.4615L1.16675 10.3541V5.89339C1.16675 5.46348 1.37675 5.06331 1.72558 4.81889L1.80841 4.76581ZM11.9584 6.36414L7.2025 8.84623C7.15062 8.87327 7.09384 8.88962 7.03553 8.89433C6.97721 8.89904 6.91855 8.89201 6.863 8.87364L6.79766 8.84623L2.04175 6.36414V10.3541C2.04176 10.6103 2.1381 10.8572 2.31164 11.0456C2.48519 11.2341 2.72326 11.3504 2.97858 11.3715L3.06258 11.375H10.9376C11.1939 11.375 11.4408 11.2785 11.6292 11.1049C11.8177 10.9312 11.934 10.693 11.9549 10.4376L11.9584 10.3541V6.36414ZM7.00008 2.69673L2.27975 5.50256L7.00008 7.96481L11.7192 5.50198L7.00008 2.69673Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
