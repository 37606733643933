import {atom} from 'recoil';
import {SortField} from '../openapi-schema/schemaTS';

export const anomalyTableSortingDefaultState = [
  {
    name: 'priority_classification',
    desc: true,
  },
  {
    name: 'min_timestamp',
    desc: true,
  },
];
export const anomalyTableSortingState = atom({
  key: 'anomalyTableSorting',
  default: anomalyTableSortingDefaultState as SortField[],
});
