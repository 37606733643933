import styled from 'styled-components';
import {
  FingerprintObservationStatus,
  ScriptExecutionInfoStatus,
} from '../../../openapi-schema/fingerprintSchemaTS';

interface TextProps {
  status: string | undefined;
}

const Text = styled.p<TextProps>`
  font-size: 14px;
  line-height: 26px;
  color: ${props => (props.status === 'error' ? 'rgba(205, 62, 56, 1)' : 'white')};
  font-weight: ${props => (props.status === 'error' ? 700 : 400)};
  text-transform: capitalize;
`;

const getText = (status: FingerprintObservationStatus | ScriptExecutionInfoStatus | undefined) => {
  if (status === 'RUNNING') return 'Running...';
  if (status === 'NA') return 'N/A';
  return status;
};

interface Props {
  status: FingerprintObservationStatus | ScriptExecutionInfoStatus | undefined;
}

export default function Status({status}: Props) {
  return <Text status={status?.toLocaleLowerCase()}>{getText(status)?.toLocaleLowerCase()}</Text>;
}
