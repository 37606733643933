import {useEffect} from 'react';
import styled from 'styled-components';
import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';
import PriorityGroup from './components/PriorityGroup';
import TypeGroup from './components/TypeGroup';
import {useResetRecoilState} from 'recoil';
import {selectedReportingKPIstate} from '../../../atoms/selectedReportingKPI';
import {useReportingDashboardSummaryCounts} from '../../../data/ReportingDashboard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding: 10px;
  background: black;
  color: white;
  overflow: auto;
`;

export default function ReportingDashboardSummary() {
  const {data: APIdata, isError, isLoading, refetch} = useReportingDashboardSummaryCounts();
  const resetSelectedReportingKPI = useResetRecoilState(selectedReportingKPIstate);

  useEffect(() => {
    return () => resetSelectedReportingKPI();
  }, [resetSelectedReportingKPI]);

  if (isError) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <PriorityGroup data={APIdata?.priority_distribution} anomalyCount={APIdata?.anomaly_count} />
      <TypeGroup data={APIdata?.type_distribution} anomalyCount={APIdata?.anomaly_count} />
    </Container>
  );
}
