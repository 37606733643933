import styled from 'styled-components';

const Container = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: white;
`;

const TitleText = styled.p``;

const SiteList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  overflow: auto;
`;

const LegendItemContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  align-self: flex-start;
`;

interface CircleProps {
  color: string;
}

const Circle = styled.div<CircleProps>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: ${props => props.color};
`;

interface ItemProps {
  color: string;
  hide: boolean | undefined;
}

const ItemText = styled.p<ItemProps>`
  text-transform: uppercase;
  color: ${props => (props.hide ? 'rgba(97, 93, 104, 1)' : props.color)};
  text-decoration: ${props => (props.hide ? 'line-through' : 'none')};
`;

interface Props {
  legendItems: {text: string; hidden: boolean; color: string}[];
  toggleData: (datasetIndex: string) => void;
}

export function ScatterplotLegend({legendItems, toggleData}: Props) {
  return (
    <Container>
      <TitleText>Risk Durations</TitleText>
      <SiteList>
        {legendItems &&
          legendItems.map(item => (
            <LegendItemContainer key={item.text} onClick={() => toggleData(item.text)}>
              <Circle color={item.hidden ? 'rgba(97, 93, 104, 1)' : item.color} />
              <ItemText color={item.color} hide={item.hidden}>
                {item.text}
              </ItemText>
            </LegendItemContainer>
          ))}
      </SiteList>
    </Container>
  );
}
