import styled from 'styled-components';
import {Snackbar, Slide, SlideProps} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {getSnackbarSeverityColor} from './utils/getSnackbarSeverityColor';
import {getSnackbarIcon} from './utils/getSnackbarIcon';
import {SnackbarSeverity} from '../../../../atoms/snackbarData';

const Container = styled.div`
  .MuiPaper-root {
    padding: 0;
    min-width: 0;
  }

  .MuiSnackbarContent-message {
    padding: 0;
    width: 100%;
  }
`;

interface ContainerProps {
  severityColor: string;
}

const MessageContainer = styled.div<ContainerProps>`
  display: flex;
  background: ${props => props.severityColor};
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;
`;

const StatusIconContainer = styled.div`
  background: rgba(0, 0, 0, 0.1);
  padding: 5.5px;
  display: grid;
  place-content: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 6px 40px 7px 8px;
  gap: 4px;
`;

const MainMessage = styled.p`
  font-weight: 600;
  line-height: 19px;
  font-size: 14px;
`;

interface SubtitleProps {
  pointer: boolean;
}
const Subtitle = styled.p<SubtitleProps>`
  background: inherit;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  line-height: 18px;
`;

const CloseIconWrapper = styled.button`
  display: grid;
  place-content: center;
  position: absolute;
  cursor: pointer;
  border: none;
  background: inherit;
  color: white;
  top: 5px;
  right: 5px;
`;

function TransitionUp(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

interface Props {
  severity: SnackbarSeverity;
  message: string;
  open: boolean;
  subtitle?: string;
  duration?: number;
  handleClose: () => void;
  subtitleCallback?: () => void;
}

export default function SnackbarNotification({
  severity,
  message,
  open,
  subtitle,
  duration,
  handleClose,
  subtitleCallback,
}: Props) {
  return (
    <Container>
      <Snackbar
        open={open}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        TransitionComponent={TransitionUp}
        autoHideDuration={duration || 6000}
        disableWindowBlurListener={true}
        onClose={handleClose}
        message={
          <MessageContainer severityColor={getSnackbarSeverityColor(severity)}>
            <StatusIconContainer>{getSnackbarIcon(severity)}</StatusIconContainer>
            <TextContainer>
              <MainMessage>{message}</MainMessage>
              {subtitle && (
                <Subtitle onClick={subtitleCallback} pointer={Boolean(subtitleCallback)}>
                  {subtitle}
                </Subtitle>
              )}
            </TextContainer>
            <CloseIconWrapper onClick={handleClose}>
              <CloseIcon sx={{fontSize: '15px'}} />
            </CloseIconWrapper>
          </MessageContainer>
        }
      />
    </Container>
  );
}
