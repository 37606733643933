import IconComponent, {IconProps} from '../IconComponent';

export default function MediumPriorityColorIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 20}} className={className}>
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="MediumPriorityColorIcon"
      >
        <path
          d="M9.99997 1.66602C9.5833 1.66602 9.16663 1.82435 8.82497 2.15768L2.1583 8.82435C1.49997 9.47435 1.49997 10.5243 2.1583 11.1743L8.82497 17.841C9.47497 18.4993 10.525 18.4993 11.175 17.841L17.8416 11.1743C18.5 10.5243 18.5 9.47435 17.8416 8.82435L11.175 2.15768C10.8333 1.82435 10.4166 1.66602 9.99997 1.66602Z"
          fill="#F08018"
        />
        <rect x="9" y="6" width="2" height="5" fill="white" />
        <rect x="9" y="12" width="2" height="2" fill="white" />
      </svg>
    </IconComponent>
  );
}
