import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {stepWizardCurrentPageState} from '../../../../atoms/StepWizardCurrentPage';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export interface ProgressBarElementProps {
  pageNumber: number;
  currentPage: number;
}

const Circle = styled.div<ProgressBarElementProps>`
  width: 30px;
  height: 30px;
  border-radius: 1000px;
  font-size: 12px;
  font-weight: 700;
  background: ${props =>
    props.pageNumber - 1 === props.currentPage ? 'rgba(120, 83, 203, 1)' : 'inherit'};
  border: ${props =>
    props.pageNumber - 1 <= props.currentPage
      ? '1px solid rgba(120, 83, 203, 1)'
      : '1px solid rgba(255, 255, 255, 0.4)'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props =>
    props.pageNumber - 1 === props.currentPage
      ? 'rgba(255, 255, 255, 0.87)'
      : 'rgba(255, 255, 255, 0.6)'};
`;

const Label = styled.p<ProgressBarElementProps>`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: ${props =>
    props.pageNumber - 1 === props.currentPage
      ? 'rgba(255, 255, 255, 0.87)'
      : 'rgba(255, 255, 255, 0.6)'};
`;

interface Props {
  title: string;
  pageNumber: number;
}

export default function ProgressBarStep({title, pageNumber}: Props) {
  const stepWizardCurrentPage = useRecoilValue(stepWizardCurrentPageState);

  return (
    <Container>
      <Circle pageNumber={pageNumber} currentPage={stepWizardCurrentPage}>
        {pageNumber}
      </Circle>
      <Label pageNumber={pageNumber} currentPage={stepWizardCurrentPage}>
        {title}
      </Label>
    </Container>
  );
}
