import styled from 'styled-components';
import TooltipDataEntry from './TooltipDataEntry';

const Box = styled.div`
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export interface TooltipDataPair {
  heading: string;
  value: string;
}

interface Props {
  entries: TooltipDataPair[];
}

export default function TooltipBox({entries}: Props) {
  return (
    <Box>
      {entries.map(entry => {
        return <TooltipDataEntry key={entry.heading} heading={entry.heading} value={entry.value} />;
      })}
    </Box>
  );
}
