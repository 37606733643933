import useNavigateKeepParams from '../../../helpers/hooks/useNavigateKeepParams';
import AppHeader from '../../Katana/patterns/AppHeader/AppHeader';
import {useLocation} from 'react-router-dom';
import {selectedDataCenterId} from '../../../atoms/selectedDatacenter';
import {useRecoilValue} from 'recoil';
import UserButton from './components/UserButton';

const reportingDashboardLink = '/reporting-dashboard';
const serviceNowLink = '/service-now';
const whatIfCalculatorLink = '/what-if-calculator';
const hardwareMonitoringLink = '/hardware-monitoring';
const securityLink = '/security';

const getInitialSelectedTabIndex = (pathname: string) => {
  if (pathname.includes('datacenter') || pathname.includes('anomaly')) return 0;
  if (pathname.includes(reportingDashboardLink)) return 1;
  if (pathname.includes(whatIfCalculatorLink)) return 2;
  if (pathname.includes(serviceNowLink)) return 3;
  if (pathname.includes(hardwareMonitoringLink)) return 4;
  if (pathname.includes(securityLink)) return 5;
};

export default function Header() {
  const selectedDataCenter = useRecoilValue(selectedDataCenterId);
  const location = useLocation();
  const navigateKeepParams = useNavigateKeepParams();

  return (
    <AppHeader
      tabGroupProps={{
        initialSelectedTabIndex: getInitialSelectedTabIndex(location.pathname),
        tabData: [
          {
            label: 'Dashboard',
            onClick: () => navigateKeepParams({pathname: `/datacenter/${selectedDataCenter}`}),
          },
          {
            label: 'Analysis',
            onClick: () => navigateKeepParams({pathname: reportingDashboardLink}),
          },
          {
            label: 'What If Calculator',
            onClick: () => navigateKeepParams({pathname: whatIfCalculatorLink}),
          },
          {
            label: 'Fingerprints',
            onClick: () => navigateKeepParams({pathname: serviceNowLink, clearStartEnd: true}),
          },
          {
            label: 'Hardware Monitoring',
            onClick: () =>
              navigateKeepParams({pathname: hardwareMonitoringLink, clearStartEnd: true}),
          },
          {
            label: 'Security',
            onClick: () => navigateKeepParams({pathname: securityLink, clearStartEnd: true}),
          },
        ],
      }}
      accountDropdownComponent={<UserButton />}
    />
  );
}
