import styled from 'styled-components';
import Slider from './Slider';
import {whatIfSliderValueState} from '../../../../atoms/whatIfSliderValue';
import {whatIfThresholdLevelState} from '../../../../atoms/whatIfThresholdLevel';

const SlidersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
`;

const sliderData = [
  {
    marks: [
      {value: 0, label: ''},
      {value: 50, label: ''},
      {value: 100, label: ''},
      {value: 150, label: ''},
      {value: 200, label: ''},
      {value: 250, label: ''},
      {value: 300, label: ''},
      {value: 350, label: ''},
      {value: 400, label: ''},
      {value: 450, label: ''},
      {value: 500, label: ''},
    ],
    maxValue: 500,
    atomState: whatIfSliderValueState,
    title: 'Scale Factor',
    step: 20,
    showSign: true,
  },
  {
    marks: [
      {value: 0, label: ''},
      {value: 10, label: ''},
      {value: 20, label: ''},
      {value: 30, label: ''},
      {value: 40, label: ''},
      {value: 50, label: ''},
      {value: 60, label: ''},
      {value: 70, label: ''},
      {value: 80, label: ''},
      {value: 90, label: ''},
      {value: 100, label: ''},
    ],
    maxValue: 100,
    atomState: whatIfThresholdLevelState,
    title: 'Warning Level',
    step: 5,
    showSign: false,
  },
];

export default function Sliders() {
  return (
    <SlidersWrapper>
      {sliderData.map(sliderData => (
        <Slider key={sliderData.title} data={sliderData} />
      ))}
    </SlidersWrapper>
  );
}
