import {useRecoilState} from 'recoil';
import styled from 'styled-components';
import {fingerprintDetailsState} from '../../../../../atoms/fingerprintDetails';
import Dropdown from '../../../../common/Dropdown/Dropdown';
import {
  AutomationLevels,
  AutomationLevelDropdownOptions,
} from '../../../../ServiceNow/FingerprintDetails/components/FingerprintInfo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Input = styled.input`
  background: inherit;
  color: white;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 10px;
  border: 1px solid rgba(64, 64, 64, 1);
  border-radius: 1px;
  outline: none;
  max-width: 635px;

  :focus {
    box-shadow: none;
    border: 1px solid rgba(64, 64, 64, 1);
  }

  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const TextArea = styled(Input).attrs({
  as: 'textarea',
})`
  resize: vertical;
  min-height: 28px;
  height: 60px;
  max-height: 250px;
`;

const InputTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;

const DropdownWrapper = styled.div`
  min-width: 190px;
`;

const DropdownSection = styled.div`
  display: flex;
  gap: 20px;
`;

export default function FingerprintDetailsPage() {
  const [fingerprintDetails, setFingerprintDetails] = useRecoilState(fingerprintDetailsState);

  return (
    <Container>
      <InputContainer>
        <InputTitle>Fingerprint Name *</InputTitle>
        <Input
          placeholder="Enter a fingerprint name"
          value={fingerprintDetails.name}
          onChange={e =>
            setFingerprintDetails(currVal => {
              return {...currVal, name: e.target.value};
            })
          }
        />
      </InputContainer>
      <InputContainer>
        <InputTitle>Description (optional)</InputTitle>
        <TextArea
          placeholder="Enter a short description"
          value={fingerprintDetails.description}
          onChange={e =>
            setFingerprintDetails(currVal => {
              return {...currVal, description: e.target.value};
            })
          }
        />
      </InputContainer>
      <DropdownSection>
        <InputContainer>
          <InputTitle>Automation Level</InputTitle>
          <DropdownWrapper>
            <Dropdown
              variant="outline"
              dropdownOptions={AutomationLevelDropdownOptions}
              selectedValue={AutomationLevels.silent}
              onChange={value =>
                setFingerprintDetails(currVal => {
                  return {
                    ...currVal,
                    classification: (() => {
                      if (value === AutomationLevels.auto) return 'AUTO';
                      if (value === AutomationLevels.manual) return 'MANUAL';
                      return 'SILENT';
                    })(),
                  };
                })
              }
            />
          </DropdownWrapper>
        </InputContainer>
        <InputContainer>
          <InputTitle>Category</InputTitle>
          <DropdownWrapper>
            <Dropdown variant="outline" dropdownOptions={['Network']} onChange={() => {}} />
          </DropdownWrapper>
        </InputContainer>
      </DropdownSection>
    </Container>
  );
}
