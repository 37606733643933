import {useEffect, useState, useCallback, useRef} from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import Dropdown from '../../Dropdown/Dropdown';
import Calendar from './Calendar';
import Clock from './Clock';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {timelineState} from '../../../../atoms/dashboard';
import calculateTimelineInterval from '../../../../utils/calculateTimelineInterval';
import useSetTimelineStates from '../../../../helpers/hooks/useSetTimelineStates';
import {DateTime, Interval} from 'luxon';
import {inLiveModeState} from '../../../../atoms/inLiveMode';
import {ClickAwayListener} from '@mui/material';
import {selectedTimezoneState} from '../../../../atoms/selectedTimezone';
import {calculateCustomDateTime} from '../utils/calculateCustomDateTime';
import {WarningIcon} from 'components/Katana/Icons';

const Container = styled.div`
  width: 280px;
  border: 1px solid rgba(93, 93, 93, 1);
  border-radius: 2px;
  position: absolute;
  z-index: 50;
  top: 4px;
  left: 4px;
  color: white;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  background: rgba(32, 34, 38, 1);
  padding: 16px 10px 10px 10px;
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
`;

const Title = styled.p`
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
`;

const CloseIconWrapper = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
`;

const Footer = styled.div`
  padding: 10px;
  background: rgba(25, 25, 25, 1);
  margin-top: auto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  gap: 10px;
`;

const CancelButton = styled.p`
  padding: 6px 10px;
  border: 1px solid rgba(64, 64, 64, 1);
  cursor: pointer;
  margin-left: auto;
`;

const SaveButton = styled.button`
  padding: 6px 10px;
  background: rgba(67, 79, 203, 1);
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: white;
  border: none;
`;

const TrailingSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const TextGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
`;

const CustomRangeWrapper = styled.div`
  margin-top: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DateGroup = styled.div``;

const rangeOptions = [
  'Custom Range',
  'LIVE',
  'Last Hour',
  'Last 3 Hours',
  'Last 6 Hours',
  'Last 12 Hours',
  'Last 24 Hours',
  'Last 7 Days',
  'Last 30 Days',
];

const trailingOptions = ['1 Hour', '3 Hours', '6 Hours', '12 Hours'];

interface TimesliderRangeDialogProps {
  setRangePopupOpen: (value: boolean) => void;
}

export default function TimesliderRangeDialog({setRangePopupOpen}: TimesliderRangeDialogProps) {
  const timeline = useRecoilValue(timelineState);
  const setInLiveMode = useSetRecoilState(inLiveModeState);
  const [range, setRange] = useState(rangeOptions[0]);
  const [trailing, setTrailing] = useState(trailingOptions[0]);
  const [customStartTime, setCustomStartTime] = useState(timeline.selectedInterval.start);
  const [customEndTime, setCustomEndTime] = useState(timeline.selectedInterval.end);
  const [customStartDate, setCustomStartDate] = useState(timeline.selectedInterval.start);
  const [customEndDate, setCustomEndDate] = useState(timeline.selectedInterval.end);
  const setTimelineStates = useSetTimelineStates();
  const selectedTimezone = useRecoilValue(selectedTimezoneState);

  const customEndTimeRef = useRef(customEndTime);
  const startIsGreaterThanEndRef = useRef<() => boolean>(() => false);
  startIsGreaterThanEndRef.current = useCallback(() => {
    return (
      calculateCustomDateTime(customStartDate, customStartTime, selectedTimezone) >=
      calculateCustomDateTime(customEndDate, customEndTime, selectedTimezone)
    );
  }, [customEndDate, customEndTime, customStartDate, customStartTime, selectedTimezone]);

  useEffect(() => {
    //This effect enforces a default minimum in the time picker of 1 minute. This prevents the dialog
    //from displaying an error when it is opened when the start and end of the timeline are within one
    //minute of each other.
    if (startIsGreaterThanEndRef.current()) {
      setCustomEndTime(customEndTimeRef.current.plus({minutes: 1}));
    }
  }, []);

  const saveOptions = useCallback(() => {
    const updateTimelineStates = (start: DateTime, end: DateTime) => {
      const newTimelineInterval = calculateTimelineInterval(start, end);

      const newTimeline = {
        selectedInterval: Interval.fromDateTimes(start, end),
        timelineInterval: newTimelineInterval,
      };

      setInLiveMode(false);
      setTimelineStates(newTimeline);
    };

    const calculateLastXinterval = (days: number) => {
      const now = DateTime.now();
      const newStartTime = now.minus({days: days});
      const newEndTime = now;

      updateTimelineStates(newStartTime, newEndTime);
    };

    const calculateCustomInterval = () => {
      const newStartTime = calculateCustomDateTime(
        customStartDate,
        customStartTime,
        selectedTimezone
      );
      const newEndTime = calculateCustomDateTime(customEndDate, customEndTime, selectedTimezone);

      updateTimelineStates(newStartTime, newEndTime);
    };

    const calculateLiveInterval = (trailing: string) => {
      switch (trailing) {
        case '1 Hour':
          calculateLastXinterval(1 / 24);
          break;
        case '3 Hours':
          calculateLastXinterval(3 / 24);
          break;
        case '6 Hours':
          calculateLastXinterval(6 / 24);
          break;
        default:
          calculateLastXinterval(12 / 24);
          break;
      }
    };

    switch (range) {
      case 'Last Hour':
        calculateLastXinterval(1 / 24);
        break;
      case 'Last 3 Hours':
        calculateLastXinterval(3 / 24);
        break;
      case 'Last 6 Hours':
        calculateLastXinterval(6 / 24);
        break;
      case 'Last 12 Hours':
        calculateLastXinterval(12 / 24);
        break;
      case 'Last 24 Hours':
        calculateLastXinterval(1);
        break;
      case 'Last 7 Days':
        calculateLastXinterval(7);
        break;
      case 'Last 30 Days':
        calculateLastXinterval(30);
        break;
      case 'LIVE':
        calculateLiveInterval(trailing);
        setInLiveMode(true);
        break;
      default:
        calculateCustomInterval();
        break;
    }

    setRangePopupOpen(false);
  }, [
    range,
    setRangePopupOpen,
    setInLiveMode,
    setTimelineStates,
    customStartDate,
    customStartTime,
    selectedTimezone,
    customEndDate,
    customEndTime,
    trailing,
  ]);

  const startGreaterThanEnd = startIsGreaterThanEndRef.current();

  return (
    <ClickAwayListener onClickAway={() => setRangePopupOpen(false)}>
      <Container>
        <Content>
          <TitleRow>
            <Title>Configure Time Slider</Title>
            <CloseIconWrapper onClick={() => setRangePopupOpen(false)}>
              <CloseIcon />
            </CloseIconWrapper>
          </TitleRow>
          <Dropdown dropdownOptions={rangeOptions} onChange={setRange} variant={'outline'} />
          {range === 'LIVE' && (
            <TrailingSelectionWrapper>
              <Text>trailing selection</Text>
              <Dropdown
                dropdownOptions={trailingOptions}
                onChange={setTrailing}
                variant={'outline'}
              />
            </TrailingSelectionWrapper>
          )}
          {range === 'Custom Range' && (
            <CustomRangeWrapper>
              <Row>
                <DateGroup>
                  <Text>start date</Text>
                  <Calendar date={customStartDate} setCustomDate={setCustomStartDate} />
                </DateGroup>
                <DateGroup>
                  <Text>start time</Text>
                  <Clock date={customStartTime} setCustomTime={setCustomStartTime} />
                </DateGroup>
              </Row>
              <Row>
                <DateGroup>
                  <TextGroup>
                    <Text>end date</Text>
                    {startGreaterThanEnd && <WarningIcon />}
                  </TextGroup>
                  <Calendar
                    date={customEndDate}
                    setCustomDate={setCustomEndDate}
                    error={startGreaterThanEnd}
                  />
                </DateGroup>
                <DateGroup>
                  <Text>end time</Text>
                  <Clock date={customEndTime} setCustomTime={setCustomEndTime} />
                </DateGroup>
              </Row>
            </CustomRangeWrapper>
          )}
        </Content>
        <Footer>
          <CancelButton onClick={() => setRangePopupOpen(false)}>Cancel</CancelButton>
          <SaveButton
            onClick={saveOptions}
            disabled={range === 'Custom Range' && startGreaterThanEnd}
          >
            Save
          </SaveButton>
        </Footer>
      </Container>
    </ClickAwayListener>
  );
}
