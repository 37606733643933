import {useEffect, useMemo, useState} from 'react';
import {debounce, throttle} from 'lodash';

export default function useDebouncedInput(value: any, delay = 500, useThrottle = false) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debouncedSet = useMemo(() => {
    if (!useThrottle) {
      return debounce(setDebouncedValue, delay, {
        leading: true,
        trailing: true,
      });
    } else {
      return throttle(setDebouncedValue, delay, {
        leading: true,
        trailing: true,
      });
    }
  }, [delay, useThrottle]);

  useEffect(() => {
    debouncedSet(value);
  }, [debouncedSet, value]);

  return debouncedValue;
}
