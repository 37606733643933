import {uniq} from 'lodash';
import {ErPathTraceDataGraph, PathTraceDataGraph} from 'openapi-schema/schemaTS';
import {useMemo} from 'react';
import styled from 'styled-components';
import {getRandomColors} from '../../../../utils/randomColor';
import {calculateBorderColor} from '../TopologyElementStyles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  color: white;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const NoData = styled.div`
  min-height: 10px;
  min-width: 10px;
  background: rgba(28, 28, 28, 1);
  border: 1px dashed rgba(51, 51, 51, 1);
  border-radius: 100%;
`;

interface NodeCircleProps {
  background: string;
  border: string;
}

const NodeCircle = styled.div<NodeCircleProps>`
  min-height: 10px;
  min-width: 10px;
  background: ${props => props.background};
  border: ${props => `1px solid ${props.border}`};
  border-radius: 100%;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 26px;
`;

interface Props {
  data: ErPathTraceDataGraph | PathTraceDataGraph | undefined;
}

export default function HopLegend({data}: Props) {
  const providerInfo = useMemo(() => {
    const uniqProviders = uniq(data?.nodes.flatMap(node => node.provider || []));
    return uniqProviders.map(provider => {
      return {
        text: provider,
        background: getRandomColors({seed: provider}),
        border: calculateBorderColor(provider),
      };
    });
  }, [data]);

  return (
    <Container>
      <Row>
        <NoData />
        <Text>No data available</Text>
      </Row>
      <Row>
        <NodeCircle background="rgba(65, 71, 74, 1)" border="rgba(141, 145, 146, 1)" />
        <Text>Limited ICMP data</Text>
      </Row>
      {providerInfo.map(provider => (
        <Row key={provider.text}>
          <NodeCircle background={provider.background} border={provider.border} />
          <Text>{provider.text}</Text>
        </Row>
      ))}
    </Container>
  );
}
