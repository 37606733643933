import {useLogTemplatesVTC, useLogTemplatesVTCCount} from 'data/VTC';
import AssociatedLogsTable from '../common/AssociatedLogsTable/AssociatedLogsTable';

export default function AssociatedLogsVTC() {
  return (
    <AssociatedLogsTable
      logListQuery={useLogTemplatesVTC}
      logCountQuery={useLogTemplatesVTCCount}
    />
  );
}
