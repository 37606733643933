import {useState} from 'react';
import IconWrapper from 'components/common/Sensei/IconWrapper';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

interface Props {
  onLikeClick?: () => void;
  onDislikeClick?: () => void;
}

export default function LikeDislikeVote({onLikeClick, onDislikeClick}: Props) {
  const [vote, setVote] = useState<'like' | 'dislike' | undefined>();

  return (
    <div className="flex gap-[10px] text-16">
      <IconWrapper
        clickEvent={() => {
          setVote(currVal => (currVal === 'like' ? undefined : 'like'));
          if (onLikeClick) {
            onLikeClick();
          }
        }}
      >
        {vote === 'like' ? (
          <ThumbUpAltIcon sx={{fontSize: 'inherit'}} />
        ) : (
          <ThumbUpAltOutlinedIcon sx={{fontSize: 'inherit'}} />
        )}
      </IconWrapper>
      <IconWrapper
        clickEvent={() => {
          setVote(currVal => (currVal === 'dislike' ? undefined : 'dislike'));
          if (onDislikeClick) {
            onDislikeClick();
          }
        }}
      >
        {vote === 'dislike' ? (
          <ThumbDownAltIcon sx={{fontSize: 'inherit'}} />
        ) : (
          <ThumbDownAltOutlinedIcon sx={{fontSize: 'inherit'}} />
        )}
      </IconWrapper>
    </div>
  );
}
