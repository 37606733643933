import {ReactElement, useState} from 'react';
import {Tooltip as MuiTooltip} from '@mui/material';
import styled from 'styled-components';

const Container = styled.span`
  display: inline;
`;

interface Props {
  tooltipContent: ReactElement | '';
  mainContent: ReactElement;
  maxWidth?: string;
  onCanvas?: boolean;
}

export default function Tooltip({tooltipContent, mainContent, maxWidth, onCanvas = false}: Props) {
  const [renderTooltip, setRenderTooltip] = useState(onCanvas);

  const mainContentWrapper = (
    <Container
      onMouseEnter={() => setRenderTooltip(true)}
      onMouseLeave={() => setRenderTooltip(false)}
    >
      {mainContent}
    </Container>
  );

  if (!renderTooltip) {
    return mainContentWrapper;
  }

  return (
    <MuiTooltip
      disableInteractive
      placement="bottom-start"
      title={tooltipContent}
      open={onCanvas || undefined}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            padding: '10px',
            fontSize: '14px',
            background: 'rgba(28, 28, 28, 1)',
            border: '1px solid rgba(141, 145, 146, 1)',
            borderRadius: '2px',
            maxWidth: maxWidth || '400px',
          },
        },
      }}
      role="tooltip"
    >
      {mainContent}
    </MuiTooltip>
  );
}
