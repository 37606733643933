import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import differenceWith from 'lodash/differenceWith';
import differenceBy from 'lodash/differenceBy';
import {usePrevious} from '../../../helpers/hooks/usePrevious';

import {Container} from './styles';
import cytoscape from 'cytoscape';
import cyCanvas from 'cytoscape-canvas';
import {ReactCytoscape} from 'react-cytoscape';
import cola from 'cytoscape-cola';
import popper from 'cytoscape-popper';

import './styles.scss';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/themes/translucent.css';
import {useSetRecoilState} from 'recoil';
import {cyCoreState} from '../../../atoms/cyCore';
import {topologyPadding} from 'utils/topologyOnReadyFit';

// Cytoscape initialization
cytoscape.use(cola);
cytoscape.use(popper);
cyCanvas(cytoscape);

const MemoReactCytoscape = React.memo(ReactCytoscape, (props, nextProps) => {
  if (props.interval && props.interval !== nextProps.interval) {
    return false;
  }

  if (props.elements?.nodes?.length !== nextProps.elements?.nodes?.length) {
    return false;
  }

  return true;
});

const emptyArray = [];
const emptyObject = {};
const emptyFunction = () => {};

const CytoscapeViewer = props => {
  const {
    elements,
    style,
    layout,
    interval,
    options = emptyObject,
    id = 0,
    zoomSelectedElements = false,
    lockNodes = false,
    selectedNodeIds = emptyArray,
    selectedEdgeIds = emptyArray,
    clickBackground = emptyFunction,
    onMouseOverNode = emptyFunction,
    onMouseOutNode = emptyFunction,
    onMouseOverEdge = emptyFunction,
    onMouseOutEdge = emptyFunction,
    onNodeTap = emptyFunction,
    onEdgeTap = emptyFunction,
    onNodeTapStart = emptyFunction,
    onNodeTapEnd = emptyFunction,
    drawUnderlayCanvasElements = emptyFunction,
  } = props;
  const previousProps = usePrevious({...props});
  const cyCurrentReference = useRef(null);
  const contextReference = useRef({});
  const setCyCore = useSetRecoilState(cyCoreState);

  const renderOverlayCanvasElements = useCallback(cyReference => {
    if (!contextReference.current.layer) {
      contextReference.current.layer = cyReference.cyCanvas();
    }
    if (!contextReference.current.canvas) {
      contextReference.current.canvas = contextReference.current.layer.getCanvas();
    }
    if (!contextReference.current.context) {
      contextReference.current.context = contextReference.current.canvas.getContext('2d');
    }

    cyReference.removeListener('render cyCanvas.resize');

    cyReference.on('render cyCanvas.resize', function () {
      contextReference.current.layer.resetTransform(contextReference.current.context);
      contextReference.current.layer.clear(contextReference.current.context);
      contextReference.current.layer.setTransform(contextReference.current.context);
    });
  }, []);

  const renderUnderlayCanvasElements = useCallback(
    cyReference => {
      const bottomLayer = cyReference.cyCanvas({
        zIndex: -1,
      });
      const canvas = bottomLayer.getCanvas();
      const ctx = canvas.getContext('2d');

      cyReference.on('render cyCanvas.resize', function () {
        bottomLayer.resetTransform(ctx);
        bottomLayer.clear(ctx);
        bottomLayer.setTransform(ctx);
        drawUnderlayCanvasElements(ctx);
      });
    },
    [drawUnderlayCanvasElements]
  );

  const createCyRef = useCallback(
    cy => {
      cy.autounselectify(false);
      cyCurrentReference.current = cy;
      contextReference.current.layer = null;
      contextReference.current.canvas = null;
      contextReference.current.context = null;
      renderOverlayCanvasElements(cy);
      renderUnderlayCanvasElements(cy);

      cy.on('tap', 'node', onNodeTap);
      cy.on('tapstart', 'node', onNodeTapStart);
      cy.on('tapend', 'node', onNodeTapEnd);
      cy.on('tap', 'edge', onEdgeTap);
      cy.on('mouseover', 'node', onMouseOverNode);
      cy.on('mouseout', 'node', onMouseOutNode);
      cy.on('mouseover', 'edge', onMouseOverEdge);
      cy.on('mouseout', 'edge', onMouseOutEdge);
      cy.on('tap', event => {
        const evtTarget = event.target;
        if (evtTarget === cy) {
          clickBackground();
        }
      });

      setCyCore(cy);
    },
    [
      clickBackground,
      onEdgeTap,
      onMouseOutEdge,
      onMouseOutNode,
      onMouseOverEdge,
      onMouseOverNode,
      onNodeTap,
      onNodeTapEnd,
      onNodeTapStart,
      renderOverlayCanvasElements,
      renderUnderlayCanvasElements,
      setCyCore,
    ]
  );

  useEffect(() => {
    if (cyCurrentReference?.current) {
      const cy = cyCurrentReference.current;
      const cyRefSelectedNodes = cy.$('node:selected');
      const cyRefSelectedNodesIds = cyRefSelectedNodes.map(p => p.data().id);
      const unSelectedNodes = differenceBy(cyRefSelectedNodesIds, selectedNodeIds);
      const selectedNodeIdsSet = new Set(selectedNodeIds);

      unSelectedNodes.forEach(id => {
        const node = cy.getElementById(id);
        node.selectify();
        node.unselect();
      });

      cy.nodes().forEach(node => {
        node.selectify();
        if (selectedNodeIdsSet.has(node.data().id)) {
          node.select();
        }

        node.unselectify();
      });

      // selectedNodeIds.forEach(id => {
      //   const node = cy.getElementById(id);
      //   if (!node.selected()) {
      //     node.select();
      //   }
      // });

      const cyRefEdgeNodes = cy.$('edge:selected');
      const cyRefSelectedEdgeIds = cyRefEdgeNodes.map(p => p.data().id);
      const unSelectedEdges = differenceBy(cyRefSelectedEdgeIds, selectedEdgeIds);
      const selectedEdgesSet = new Set(selectedEdgeIds);

      unSelectedEdges.forEach(id => {
        const edge = cy.getElementById(id);
        edge.selectify();
        edge.unselect();
      });

      // selectedEdgeIds.forEach(id => {
      //   const edge = cy.getElementById(id);
      //   if (!edge.selected()) {
      //     edge.select();
      //   }
      // });

      cy.edges().forEach(edge => {
        edge.selectify();
        const source_node_name = `${edge.data().source_node_name}_${edge.data().source_int_name}`;
        const target_node_name = `${edge.data().target_node_name}_${edge.data().target_int_name}`;
        const source_hostname = `${edge.data().sourceIp}_${edge.data().source_int_name}`;
        const target_hostname = `${edge.data().targetIp}_${edge.data().target_int_name}`;
        const edge_id = `${edge.data().id}`;

        if (
          selectedEdgesSet.has(source_hostname) ||
          selectedEdgesSet.has(target_hostname) ||
          selectedEdgesSet.has(source_node_name) ||
          selectedEdgesSet.has(target_node_name) ||
          selectedEdgesSet.has(edge_id)
        ) {
          edge.select();
        }

        edge.unselectify();
      });
    }
  }, [cyCurrentReference, selectedNodeIds, selectedEdgeIds]);

  const computedOptions = useMemo(
    () => ({
      wheelSensitivity: 0.1,
      maxZoom: 1e50,
      minZoom: 1e-50,
      hideEdgesOnViewport: false,
      hideLabelsOnViewport: true,
      motionBlur: true,
      selectionType: 'single',
      ...options,
    }),
    [options]
  );

  useEffect(() => {
    if (cyCurrentReference.current && zoomSelectedElements) {
      const cy = cyCurrentReference.current;

      cy.maxZoom(1);
      cy.fit(cy.$(':selected'), topologyPadding);
      cy.maxZoom(1e50);
    }
  }, [selectedNodeIds, selectedEdgeIds, zoomSelectedElements]);

  useEffect(() => {
    if (cyCurrentReference.current && !!elements) {
      const newNodes = differenceWith(
        elements.nodes,
        previousProps ? previousProps.elements?.nodes : [],
        (nodeA, nodeB) => nodeA.data.id === nodeB.data.id
      );

      const removedNodes = differenceWith(
        previousProps ? previousProps.elements?.nodes : [],
        elements.nodes,
        (nodeA, nodeB) => nodeA.data.id === nodeB.data.id
      );

      cyCurrentReference.current.add({nodes: newNodes});

      if (removedNodes.length) {
        cyCurrentReference.current.remove(
          cyCurrentReference.current.nodes().filter(node => {
            const nodeData = node.data();
            return removedNodes.some(removednode => {
              return nodeData.id === removednode.data.id;
            });
          })
        );
      }

      const newEdges = differenceWith(
        elements.edges,
        previousProps ? previousProps.elements?.edges : [],
        (edgeA, edgeB) =>
          edgeA.data.source === edgeB.data.source && edgeA.data.target === edgeB.data.target
      );

      const removedEdges = differenceWith(
        previousProps ? previousProps.elements?.edges : [],
        elements.edges,
        (edgeA, edgeB) =>
          edgeA.data.source === edgeB.data.source && edgeA.data.target === edgeB.data.target
      );

      cyCurrentReference.current.add({edges: newEdges});

      if (removedEdges.length) {
        cyCurrentReference.current.remove(
          cyCurrentReference.current.edges().filter(edge => {
            const edgeData = edge.data();
            return removedEdges.some(removedEdge => {
              return (
                edgeData.source === removedEdge.data.source &&
                edgeData.target === removedEdge.data.target
              );
            });
          })
        );
      }

      if (newNodes.length || removedNodes.length || newEdges.length || removedEdges.length) {
        const cyLayout = cyCurrentReference.current.layout({
          ...layout,
        });
        cyLayout.run();
      }

      const idToData = [...elements.edges, ...elements.nodes].reduce((accumulator, element) => {
        accumulator[element.data.id] = element.data;
        return accumulator;
      }, {});
      cyCurrentReference.current.nodes().forEach(node => {
        const currentNodeData = node.data();
        const nodeData = idToData[currentNodeData.id];
        node.data(nodeData);
        if (lockNodes) {
          node.lock();
        }
      });

      cyCurrentReference.current.edges().forEach(edge => {
        const currentNodeData = edge.data();
        const edgeData = idToData[currentNodeData.id];
        edge.data(edgeData);
      });
    }
  }, [elements, layout, lockNodes, previousProps]);

  useEffect(() => {
    if (cyCurrentReference.current) {
      const cyLayout = cyCurrentReference.current.layout({
        ...layout,
      });
      cyLayout.run();
    }
  }, [layout]);

  return (
    <React.Fragment>
      <Container>
        {elements !== null && (
          <MemoReactCytoscape
            containerID={`cytoscape-${id}`}
            elements={elements}
            cytoscapeOptions={computedOptions}
            style={style}
            layout={layout}
            cyRef={createCyRef}
            interval={interval}
            options={computedOptions}
            data-testid="cytoscape"
          />
        )}
      </Container>
    </React.Fragment>
  );
};

export default CytoscapeViewer;
