import {useSearchParams} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';
import {serviceNowModalContentState} from '../../../atoms/serviceNowModalContent';
import {serviceNowModalOpenState} from '../../../atoms/serviceNowModalOpen';
import {snackbarDataState} from '../../../atoms/snackbarData';
import {useExecuteScript} from '../../../data/AutoRemediation';
import {ScriptExecutionInfo} from '../../../openapi-schema/fingerprintSchemaTS';
import ConfirmScriptModal from './components/ConfirmScriptModal';
import LoadingModal from './components/LoadingModal';
import RunScriptModal from './components/RunScriptModal';
import ViewResultsModal from './components/ViewResultsModal';

export const ServiceNowModalActions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setServiceNowModalOpen = useSetRecoilState(serviceNowModalOpenState);
  const setServiceNowModalContent = useSetRecoilState(serviceNowModalContentState);
  const setSnackbarData = useSetRecoilState(snackbarDataState);
  const executeScript = useExecuteScript();
  const searchParamsObject = Object.fromEntries(Array.from(searchParams));

  const openModal = (scriptExecutionInfo: ScriptExecutionInfo) => {
    const status = scriptExecutionInfo.status as string;

    if (status === 'RUNNING') return;
    if (status === 'PENDING') {
      setServiceNowModalContent(<RunScriptModal scriptExecutionInfo={scriptExecutionInfo} />);
    } else {
      setServiceNowModalContent(<ViewResultsModal scriptExecutionInfo={scriptExecutionInfo} />);
    }

    setServiceNowModalOpen(true);
    setSearchParams(
      {...searchParamsObject, match_id: String(scriptExecutionInfo.match_id)},
      {replace: true}
    );
  };

  const closeModal = () => {
    delete searchParamsObject.match_id;
    setSearchParams({...searchParamsObject}, {replace: true});
    setServiceNowModalOpen(false);
  };

  const goToNextPage = (scriptExecutionInfo: ScriptExecutionInfo) => {
    setServiceNowModalContent(<ConfirmScriptModal scriptExecutionInfo={scriptExecutionInfo} />);
  };

  const goToPreviousPage = (scriptExecutionInfo: ScriptExecutionInfo) => {
    setServiceNowModalContent(<RunScriptModal scriptExecutionInfo={scriptExecutionInfo} />);
  };

  const runScript = (scriptExecutionInfo: ScriptExecutionInfo) => {
    closeModal();
    executeScript(scriptExecutionInfo);
  };

  const openModalFromMatchIdParam = () => {
    if (!searchParams.get('match_id')) return;

    setServiceNowModalContent(<LoadingModal match_id={String(searchParams.get('match_id'))} />);
    setServiceNowModalOpen(true);
  };

  const showResult = (data: ScriptExecutionInfo) => {
    if (!data) {
      closeModal();
      setSnackbarData({
        severity: 'error',
        message: 'Script execution request not found',
        subtitle: 'Please check your URL and try again',
        open: true,
      });
    } else if ((data.status as string) === 'RUNNING') {
      closeModal();
      setSnackbarData({
        severity: 'warning',
        message: 'This script is currently running...',
        subtitle: 'Please wait until the process completes',
        open: true,
      });
    } else {
      openModal(data);
    }
  };

  return {
    openModal,
    closeModal,
    goToNextPage,
    goToPreviousPage,
    runScript,
    openModalFromMatchIdParam,
    showResult,
  };
};
