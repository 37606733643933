import {EventObjectEdge} from 'cytoscape';
import {useRecoilValue} from 'recoil';
import {selectedWhatIfMetricState} from '../../../atoms/selectedWhatIfMetric';
import {InterfaceMetrics} from '../../../constants/WhatIfMetrics';
import {TopologyEdgeData} from '../../../data/types';
import {EdgeScaledWhatIfMetricData} from '../../WhatIfCalculator/TopologyWhatIf/utils/whatIfScalingFunctionsTooltip';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';
import {
  Container,
  GroupWrapper,
  Row,
  Header,
  Text,
  HiddenElement,
  Line,
} from './TopologyTooltipElements';

interface Props {
  event: EventObjectEdge;
  scaledMetricData: EdgeScaledWhatIfMetricData | undefined;
}

export default function WhatIfEdgeTooltip({event, scaledMetricData}: Props) {
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);
  const edge = event.target.data() as TopologyEdgeData;

  return (
    <Tooltip
      tooltipContent={
        <Container>
          <Row>
            <Text>Status:</Text>
            <Text>{edge.status}</Text>
          </Row>
          <Line />
          <GroupWrapper>
            <Row>
              <Header>{edge.source_node_name}:</Header>
              <Header>{edge.source_int_name}</Header>
            </Row>
            {selectedWhatIfMetric in InterfaceMetrics && (
              <GroupWrapper>
                {!scaledMetricData?.int1 ? (
                  <Row>
                    <Text>No {selectedWhatIfMetric} data</Text>
                  </Row>
                ) : (
                  <>
                    {scaledMetricData.int1.map(metric => (
                      <Row key={metric.name}>
                        <Text>Scaled {metric.name}:</Text>
                        <Text>{metric.value?.toFixed(1)}</Text>
                      </Row>
                    ))}
                  </>
                )}
              </GroupWrapper>
            )}
          </GroupWrapper>
          <GroupWrapper>
            <Row>
              <Header>{edge.target_node_name}:</Header>
              <Header>{edge.target_int_name}</Header>
            </Row>
            {selectedWhatIfMetric in InterfaceMetrics && (
              <GroupWrapper>
                {!scaledMetricData?.int2 ? (
                  <Row>
                    <Text>No {selectedWhatIfMetric} data</Text>
                  </Row>
                ) : (
                  <>
                    {scaledMetricData.int2.map(metric => (
                      <Row key={metric.name}>
                        <Text>Scaled {metric.name}:</Text>
                        <Text>{metric.value?.toFixed(1)}</Text>
                      </Row>
                    ))}
                  </>
                )}
              </GroupWrapper>
            )}
          </GroupWrapper>
        </Container>
      }
      mainContent={
        <HiddenElement x={event.originalEvent.offsetX} y={event.originalEvent.offsetY} />
      }
      onCanvas={true}
      maxWidth={'none'}
    />
  );
}
