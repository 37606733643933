import {selector} from 'recoil';
import {timelineState} from '../atoms/dashboard';
import {inLiveModeState} from '../atoms/inLiveMode';

export const timelineRangeTextState = selector({
  key: 'timelineRangeText',
  get: ({get}) => {
    const inLiveMode = get(inLiveModeState);
    const timeline = get(timelineState);
    const interval = timeline.selectedInterval;

    if (inLiveMode) return 'LIVE';
    if (interval.length('hours') === 1) return 'Last Hour';
    if (interval.length('hours') === 3) return 'Last 3 Hours';
    if (interval.length('hours') === 6) return 'Last 6 Hours';
    if (interval.length('hours') === 12) return 'Last 12 Hours';
    if (interval.length('hours') === 24) return 'Last 24 Hours';
    if (interval.length('days') === 7) return 'Last 7 Days';
    if (interval.length('days') === 30) return 'Last 30 Days';
    return 'Custom Range';
  },
});
