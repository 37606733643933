import SectionGroup, {ButtonData, ButtonDataFilter} from './SectionGroup';
import {AnomalyTypeBucket, CountPercentDistribution} from '../../../../openapi-schema/schemaTS';
import config from '../../../../utils/AppConfigurationService';
import ObservationTypeIcon from 'components/common/ObservationTypeIcon/ObservationTypeIcon';

const getButtonText = (type: string) => {
  if (type === config.AnomalyTypes.INTERFACE) return 'Interface';
  if (type === config.AnomalyTypes.NEIGHBOR_DROP_BGP) return 'BGP';
  if (type === config.AnomalyTypes.NEIGHBOR_DROP_ISIS) return 'ISIS';
  return type;
};

interface Props {
  data: CountPercentDistribution[] | undefined;
  anomalyCount: number | undefined;
}

export default function PriorityGroup({data, anomalyCount}: Props) {
  const dataByBucket = data?.reduce((acc, currVal) => {
    return {...acc, [currVal.bucket]: currVal.count};
  }, {} as {[key in AnomalyTypeBucket]: number});

  const allTypesData: ButtonData = {
    text: 'All Types',
    filter: {
      anomaly_types: [],
    },
    title: 'All Observations',
    value: anomalyCount,
  };

  const typeData: ButtonData[] = Object.keys(dataByBucket || {}).map(type => {
    return {
      text: getButtonText(type),
      filter: {
        anomaly_types: [type],
      } as ButtonDataFilter,
      icon: (
        <ObservationTypeIcon type={type as AnomalyTypeBucket} className="text-[15px] text-white" />
      ),
      title: `${type} Observations`,
      value: dataByBucket?.[type],
    };
  });

  const buttonGroupData = [allTypesData, ...typeData];

  return <SectionGroup data={buttonGroupData} title={'Type'} />;
}
