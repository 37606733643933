import IconComponent, {IconProps} from '../IconComponent';

export default function VtcIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="VtcIcon">
        <path
          d="M3.49996 9.33331H8.16663V9.01248C8.16663 8.5847 7.95274 8.23956 7.52496 7.97706C7.09718 7.71456 6.53329 7.58331 5.83329 7.58331C5.13329 7.58331 4.5694 7.71456 4.14163 7.97706C3.71385 8.23956 3.49996 8.5847 3.49996 9.01248V9.33331ZM5.83329 6.99998C6.15413 6.99998 6.42888 6.88565 6.65754 6.65698C6.88621 6.42831 7.00035 6.15376 6.99996 5.83331C6.99996 5.51248 6.88563 5.23773 6.65696 5.00906C6.42829 4.7804 6.15374 4.66626 5.83329 4.66665C5.51246 4.66665 5.23771 4.78098 5.00904 5.00965C4.78038 5.23831 4.66624 5.51287 4.66663 5.83331C4.66663 6.15415 4.78096 6.4289 5.00963 6.65756C5.23829 6.88623 5.51285 7.00037 5.83329 6.99998ZM2.33329 11.6666C2.01246 11.6666 1.73771 11.5523 1.50904 11.3236C1.28038 11.095 1.16624 10.8204 1.16663 10.5V3.49998C1.16663 3.17915 1.28096 2.9044 1.50963 2.67573C1.73829 2.44706 2.01285 2.33293 2.33329 2.33331H9.33329C9.65413 2.33331 9.92888 2.44765 10.1575 2.67631C10.3862 2.90498 10.5003 3.17954 10.5 3.49998V6.12498L12.8333 3.79165V10.2083L10.5 7.87498V10.5C10.5 10.8208 10.3856 11.0956 10.157 11.3242C9.92829 11.5529 9.65374 11.667 9.33329 11.6666H2.33329Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
