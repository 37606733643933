import {selectedNodeIdsState} from 'atoms/dashboard';
import {selectedAnomalyDataState} from 'atoms/selectedAnomaly';
import ErrorState from 'components/Katana/components/ErrorState/ErrorState';
import {EventObjectNode} from 'cytoscape';
import {useTopologyDataFiberChannel} from 'data/Topology';
import {TopologyElements} from 'data/types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {getTopologyElementBackgroundColor} from 'utils/getTopologyElementBackgroundColor';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TopologyBase from '../TopologyBase/TopologyBase';
import SimpleNetworkDeviceTooltip from '../TopologyTooltips/SimpleNetworkDeviceTooltip';
import {HotlErAnomalyFiberChannel} from 'openapi-schema/schemaTS';

export default function TopologyFiberChannel() {
  const {data: topologyElements, isLoading, isError, refetch} = useTopologyDataFiberChannel();
  const [tooltipComponent, setTooltipComponent] = useState<JSX.Element | undefined>();
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);
  const [selectedNodeIds, setSelectedNodeIds] = useRecoilState(selectedNodeIdsState);

  const mouseOverNode = useCallback((event: EventObjectNode) => {
    setTooltipComponent(<SimpleNetworkDeviceTooltip event={event} />);
  }, []);

  const mouseOutNode = useCallback((event: EventObjectNode) => {
    setTooltipComponent(undefined);
  }, []);

  const formatNodes = useCallback(
    (topologyElements: TopologyElements) => {
      const highlightedBackgroundColor = getTopologyElementBackgroundColor(
        selectedAnomalyData?.priority_classification
      );

      return topologyElements.nodes.map(node => {
        return {
          ...node,
          data: {
            ...node.data,
            selectedBackgroundColor: highlightedBackgroundColor,
          },
        };
      });
    },
    [selectedAnomalyData]
  );

  const graphElements = useMemo(() => {
    return {
      nodes: formatNodes(topologyElements),
      edges: [],
    };
  }, [formatNodes, topologyElements]);

  useEffect(() => {
    const anomalous_entry = (selectedAnomalyData as HotlErAnomalyFiberChannel)
      ?.anomalous_entries?.[0];

    if (topologyElements.interfacesBySourceIntId?.[anomalous_entry]) {
      setSelectedNodeIds([
        topologyElements.interfacesBySourceIntId?.[anomalous_entry][0].source_node_id,
      ]);
    }
  }, [selectedAnomalyData, topologyElements, setSelectedNodeIds]);

  if (isError) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <TopologyBase
      mouseOverNode={mouseOverNode}
      mouseOutNode={mouseOutNode}
      selectedNodeIds={selectedNodeIds}
      selectedEdgeIds={[]}
      graphElements={graphElements}
      tooltipComponent={tooltipComponent}
    />
  );
}
