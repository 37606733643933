import Tooltip from 'components/Katana/components/Tooltip/Tooltip';
import styled from 'styled-components';

const TooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;

const TooltipText = styled.div`
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;

  b {
    font-weight: 700;
  }
`;

interface Props {
  header: string;
  tooltipContent: React.ReactNode;
  mainContent: JSX.Element;
  maxWidth?: string;
}

export default function TitleTooltip({header, tooltipContent, mainContent, maxWidth}: Props) {
  return (
    <Tooltip
      tooltipContent={
        tooltipContent ? (
          <TooltipContentWrapper>
            <HeaderText>{header}</HeaderText>
            <TooltipText>{tooltipContent}</TooltipText>
          </TooltipContentWrapper>
        ) : (
          ''
        )
      }
      mainContent={mainContent}
      maxWidth={maxWidth}
    />
  );
}
