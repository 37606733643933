import {IconButton as MuiIconButton} from '@mui/material';
import classNames from 'classnames';
import {MouseEvent, ReactNode} from 'react';

const baseClasses = 'p-2 [&>*]:text-24 border-solid dark:disabled:text-white/40';
const standardDarkClasses =
  'dark:text-dark-on-surface-variant dark:hover:bg-[#2A292B] dark:active:bg-[#2A292B] dark:focus:bg-[#2A292B]';
const filledDarkClasses =
  'dark:text-dark-on-primary dark:bg-dark-primary dark:hover:bg-[#CFA0EF] dark:active:bg-[#CFA0EF] dark:focus:bg-[#CFA0EF] dark:disabled:bg-dark-on-surface/12';
const tonalDarkClasses =
  'dark:bg-dark-secondary-container dark:text-dark-on-secondary-container dark:hover:bg-[#8C4319] dark:active:bg-[#8C4319] dark:focus:bg-[#8C4319] dark:disabled:bg-dark-on-surface/12';
const outlinedDarkClasses =
  'border p-[7px] dark:border-dark-outline dark:text-dark-on-surface-variant dark:hover:bg-[#2A292B] dark:active:bg-[#2A292B] dark:focus:bg-[#2A292B] dark:disabled:border-dark-on-surface/12';

interface Props {
  icon: ReactNode;
  label: string;
  variant?: 'standard' | 'filled' | 'tonal' | 'outlined';
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  id?: string;
}

export default function IconButton({
  icon,
  variant = 'standard',
  disabled = false,
  label,
  onClick,
  id,
}: Props) {
  return (
    <MuiIconButton
      className={classNames(baseClasses, {
        [standardDarkClasses]: variant === 'standard',
        [filledDarkClasses]: variant === 'filled',
        [tonalDarkClasses]: variant === 'tonal',
        [outlinedDarkClasses]: variant === 'outlined',
      })}
      disabled={disabled}
      onClick={onClick}
      aria-label={label}
      {...{id}}
    >
      {icon}
    </MuiIconButton>
  );
}
