import {useDevicesBGP, useDevicesISIS, useTopologyData} from '../../../data/Topology';
import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TopologyWAN from '../TopologyWAN/TopologyWAN';

export default function TopologyWANDetailPage() {
  const {isLoading: isLoadingBGPdevices, isError: isErrorBGPdevices} = useDevicesBGP();
  const {isLoading: isLoadingISISdevices, isError: isErrorISIS} = useDevicesISIS();
  const {isLoading, isError, refetch} = useTopologyData();

  if (isError || isErrorBGPdevices || isErrorISIS) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading || isLoadingBGPdevices || isLoadingISISdevices) {
    return <LoadingSpinner />;
  }
  return <TopologyWAN isDetailsPage={true} />;
}
