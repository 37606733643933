import {useTopologyData} from 'data/Topology';
import {isEmpty} from 'lodash';
import {useRecoilValue} from 'recoil';
import {cyCoreState} from '../../../atoms/cyCore';
import Autocomplete from '../../Katana/components/Autocomplete/Autocomplete';

export default function NodeSearch() {
  const {data} = useTopologyData();

  const cyCore = useRecoilValue(cyCoreState);

  const sortedNodes = data.nodes
    .map(node => node.data.name)
    .sort((a, b) => {
      return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    });

  const handleChange = (value: string | null) => {
    const nameArray = cyCore.nodes().filter(node => node.data().name === value);
    if (isEmpty(nameArray)) return;

    cyCore.animate({
      zoom: 1,
      center: {
        eles: nameArray,
      },
    });
  };

  return (
    <Autocomplete
      label="Search Nodes"
      options={sortedNodes}
      onChange={handleChange}
      noOptionsText="No nodes found"
    />
  );
}
