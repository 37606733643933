import CytoscapeViewer from '../CytoscapeViewer/CytoscapeViewer';
import HierarchyHorizontal from '../CytoscapeViewer/layouts/HierarchyHorizontal';
import {TopologyEdgeData, TopologyNodeData} from '../../../data/types';
import {EventObjectEdge, EventObjectNode, LayoutOptions, Stylesheet} from 'cytoscape';
import {useCallback, useMemo} from 'react';
import groupBy from 'lodash/groupBy';

interface TopologyBaseNode {
  data: {
    id: string;
  };
}

interface TopologyBaseEdge {
  data: {
    id: string;
    source: string;
    target: string;
  };
}

interface Props {
  onNodeClick?: (data: TopologyNodeData) => void;
  onEdgeClick?: (data: TopologyEdgeData) => void;
  clickBackground?: () => void;
  mouseOverNode?: (event: EventObjectNode) => void;
  mouseOverEdge?: (event: EventObjectEdge) => void;
  mouseOutNode?: (event: EventObjectNode) => void;
  mouseOutEdge?: (event: EventObjectEdge) => void;
  mouseDownNode?: (event: EventObjectNode) => void;
  mouseUpNode?: (event: EventObjectNode) => void;
  drawUnderlayCanvasElements?: (ctx: CanvasRenderingContext2D) => void;
  graphElements: {nodes: TopologyBaseNode[]; edges: TopologyBaseEdge[]};
  selectedNodeIds: string[];
  selectedEdgeIds: string[];
  stylesheet?: Stylesheet[];
  layout?: LayoutOptions;
  zoomToSelectedElements?: boolean;
  tooltipComponent?: JSX.Element | undefined;
  lockNodes?: boolean;
}

const emptyFunction = () => {};
const emptyArray = [] as any[];

export default function TopologyBase({
  onNodeClick = emptyFunction,
  onEdgeClick = emptyFunction,
  clickBackground = emptyFunction,
  mouseOverNode = emptyFunction,
  mouseOverEdge = emptyFunction,
  mouseOutNode = emptyFunction,
  mouseOutEdge = emptyFunction,
  mouseDownNode = emptyFunction,
  mouseUpNode = emptyFunction,
  drawUnderlayCanvasElements = emptyFunction,
  tooltipComponent = undefined,
  selectedNodeIds = emptyArray,
  selectedEdgeIds = emptyArray,
  graphElements: {edges, nodes},
  layout = HierarchyHorizontal.layout.options,
  stylesheet = HierarchyHorizontal.layout.stylesheet,
  zoomToSelectedElements = false,
  lockNodes = false,
}: Props) {
  const safeGraphElements = useMemo(() => {
    const nodesById = groupBy(nodes, 'data.id');
    return {
      nodes: nodes,
      edges: edges.filter(e => {
        return !!nodesById[e.data.source] && !!nodesById[e.data.target];
      }),
    };
  }, [edges, nodes]);

  const onNodeTap = useCallback(
    (event: EventObjectNode) => onNodeClick(event.target.data()),
    [onNodeClick]
  );
  const onEdgeTap = useCallback(
    (event: EventObjectEdge) => onEdgeClick(event.target.data()),
    [onEdgeClick]
  );
  const onMouseOverNode = useCallback(
    (event: EventObjectNode) => mouseOverNode(event),
    [mouseOverNode]
  );
  const onMouseOverEdge = useCallback(
    (event: EventObjectEdge) => mouseOverEdge(event),
    [mouseOverEdge]
  );
  const onMouseOutNode = useCallback(
    (event: EventObjectNode) => mouseOutNode(event),
    [mouseOutNode]
  );
  const onMouseOutEdge = useCallback(
    (event: EventObjectEdge) => mouseOutEdge(event),
    [mouseOutEdge]
  );
  const onNodeTapStart = useCallback(
    (event: EventObjectNode) => mouseDownNode(event),
    [mouseDownNode]
  );
  const onNodeTapEnd = useCallback((event: EventObjectNode) => mouseUpNode(event), [mouseUpNode]);

  return (
    <>
      <CytoscapeViewer
        elements={safeGraphElements}
        style={stylesheet}
        layout={layout}
        selectedNodeIds={selectedNodeIds}
        selectedEdgeIds={selectedEdgeIds}
        clickBackground={clickBackground}
        onMouseOverNode={onMouseOverNode}
        onMouseOutNode={onMouseOutNode}
        onMouseOverEdge={onMouseOverEdge}
        onMouseOutEdge={onMouseOutEdge}
        onNodeTap={onNodeTap}
        onEdgeTap={onEdgeTap}
        onNodeTapStart={onNodeTapStart}
        onNodeTapEnd={onNodeTapEnd}
        drawUnderlayCanvasElements={drawUnderlayCanvasElements}
        zoomSelectedElements={zoomToSelectedElements}
        lockNodes={lockNodes}
      />
      {tooltipComponent}
    </>
  );
}
