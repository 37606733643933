import {getMinutes} from 'date-fns';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import {timelineState} from '../../../../atoms/dashboard';

const addLabel = (timeline, tick) => {
  const LessThan2Hours = timeline.timelineInterval.length('hours') < 2;
  const LessThan30Minutes = timeline.timelineInterval.length('minutes') < 30;
  const LessThan10Minutes = timeline.timelineInterval.length('minutes') < 10;
  const LessThan2Minutes = timeline.timelineInterval.length('minutes') < 2;
  const LessThan45Seconds = timeline.timelineInterval.length('seconds') < 45;
  const LessThan15Seconds = timeline.timelineInterval.length('seconds') < 15;

  const isFullHour = !getMinutes(tick.value);
  const isDivBy20Minutes = !(DateTime.fromMillis(tick.value).minute % 15);
  const isDivBy5Minutes = !(DateTime.fromMillis(tick.value).minute % 5);
  const isDivBy60Seconds = !(DateTime.fromMillis(tick.value).second % 60);
  const isDivBy20Seconds = !(DateTime.fromMillis(tick.value).second % 20);
  const isDivBy10Seocnds = !(DateTime.fromMillis(tick.value).second % 10);
  const isDivBy1000Millis = !(DateTime.fromMillis(tick.value).millisecond % 1000);

  if (!LessThan2Hours) return isFullHour;
  if (!LessThan30Minutes) return isDivBy20Minutes;
  if (!LessThan10Minutes) return isDivBy5Minutes;
  if (!LessThan2Minutes) return isDivBy60Seconds;
  if (!LessThan45Seconds) return isDivBy20Seconds;
  if (!LessThan15Seconds) return isDivBy10Seocnds;
  return isDivBy1000Millis;
};

const Tick = ({tick, count, format}) => {
  const timeline = useRecoilValue(timelineState);

  const tickLabelStyle = {
    marginLeft: `${-(100 / count) / 2}%`,
    width: `${100 / count}%`,
    left: `${tick.percent}%`,
  };

  return (
    <>
      <div
        className={`react_time_range__tick_marker${addLabel(timeline, tick) ? '__large' : ''}`}
        style={{left: `${tick.percent}%`}}
      />
      {addLabel(timeline, tick) && (
        <div className="react_time_range__tick_label" style={tickLabelStyle}>
          {format(tick.value)}
        </div>
      )}
    </>
  );
};

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  count: PropTypes.number.isRequired,
  format: PropTypes.func.isRequired,
};

Tick.defaultProps = {format: d => d};

export default Tick;
