import React, {ReactElement} from 'react';

class InfiniteLoopError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InfiniteLoopError';
  }
}

class NoGlobalFlagError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NoGlobalFlagError';
  }
}

/**
 *
 * @param text A string on which to test the input regex. If there are no matches
 * on the input string, then the string will be returned.
 * @param regex The regular expression used to identify where to inject styled components.
 * The g flag must be included or this will cause an infinite loop!
 * @param InjectedComponent A styled component (typically a span) that accepts a text node as a child.
 * This styled component is used for regex matches that pass the maching function test.
 * If the UnmatchedVariable and matchingFunction params are not provided, this StyledComponent will be used for
 * all matches.
 * @returns An array of spans and styled components. The styled components contain the text node for any
 * regex match.
 */
export const injectComponent = (
  text: string,
  regex: RegExp,
  InjectedComponent: React.ComponentType<any>
): ReactElement[] | string => {
  const matches = text.match(regex);
  if (!matches) {
    return text;
  }

  try {
    if (!regex.global) {
      throw new NoGlobalFlagError('The g flag must be included in the regular expression.');
    }

    let result: RegExpExecArray | null;
    const components: ReactElement[] = [];
    let lastIndex = 0;
    // The while loop will go into an infinite loop if the regex that is passed in does
    // not include the global flag becuase the regex object's lastIndex property won't be
    // updated on every iteration.
    // The number of matches will never exceed the length of the string.
    let iteration = 0;

    while ((result = regex.exec(text))) {
      iteration++;
      if (iteration > text.length) {
        throw new InfiniteLoopError(
          `Styled component injection caused an infinte loop. Ensure the regex object
          is updating its lastIndex property.`
        );
      }
      const matchIndex = result.index;
      const length = result[0].length;
      const injectionText = text
        .slice(matchIndex, matchIndex + length)
        .replace(result[0], result[1]);

      components.push(
        <span key={`${text.slice(lastIndex, matchIndex)}:${iteration}`}>
          {text.slice(lastIndex, matchIndex)}
        </span>
      );

      components.push(
        <InjectedComponent key={`${injectionText}:${iteration}`}>{injectionText}</InjectedComponent>
      );

      lastIndex = matchIndex + length;
    }

    components.push(
      <span key={`${text.slice(lastIndex, text.length)}:${iteration}`}>
        {text.slice(lastIndex, text.length)}
      </span>
    );

    return components;
  } catch (err) {
    console.error('Error while injecting styled component into log.\n', err);
    return text;
  }
};
