import {DateTime, Duration} from 'luxon';

export const calculateCustomDateTime = (
  date: DateTime,
  time: DateTime,
  selectedTimezone: string
) => {
  time = time.setZone(selectedTimezone);
  const timeDuration = Duration.fromObject({hour: time.hour, minute: time.minute});
  return date.setZone(selectedTimezone).startOf('day').plus(timeDuration);
};
