import {useEffect, useMemo, useRef, useState} from 'react';
import {
  Container,
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TableData,
  TableRow,
  ComponentTitleWrapper,
} from 'components/common/TableElements/TableElements';
import QueryLifecycleWrapper from '../../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {isEmpty} from 'lodash';
import TableSortArrow from '../../../../common/TableSortArrow/TableSortArrow';
import Checkbox from 'components/common/Checkbox/Checkbox';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {selectedTemplatesState} from '../../../../../atoms/SelectedTemplates';
import {pageSizeOptionsMedium as pageSizeOptions} from '../../../../../constants/PageSizeOptions';
import Pagination from '../../../../common/Pagination/Pagination';
import {
  defaultTemplatesSelectionSorting,
  templatesSelectionSortingState,
} from '../../../../../atoms/TemplatesSelectionSorting';
import {convertSortToApiSchema} from '../../../../../utils/convertSortToApiSchema';
import {convertSortToReactTableSchema} from '../../../../../utils/convertSortToReactTableSchema';
import {EmptyTemplatesMessage} from '../../../../../constants/queryLifecycleMessages';
import {AnomalyTemplate} from '../../../../../openapi-schema/schemaTS';
import useARActions from '../../../../../helpers/hooks/useARActions';
import {scrollToTop} from '../../../../../utils/scrollToTop';
import ComponentTitle from 'components/common/ComponentTitle/ComponentTitle';
import styled from 'styled-components';
import {useSelectedLogAnomalyTemplateListCount} from 'data/Log';
import {useFingerprintCreationTemplateList} from 'data/AutoRemediation';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const CheckboxWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const columnHelper = createColumnHelper<AnomalyTemplate>();

export default function TemplatesSelectionPage() {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const selectedTemplates = useRecoilValue(selectedTemplatesState);
  const query = useFingerprintCreationTemplateList(APIPageIndex, APIPageSize);
  const {data: templatesCount} = useSelectedLogAnomalyTemplateListCount();
  const {data: APIdata} = query;
  const setTemplatesSelectionSorting = useSetRecoilState(templatesSelectionSortingState);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const {toggleTemplate} = useARActions();

  const data = useMemo(() => (APIdata ? APIdata : []), [APIdata]);

  const columns = [
    columnHelper.display({
      header: undefined,
      id: 'checkbox',
      size: 0.1,
      cell: props => (
        <CheckboxWrapper>
          <Checkbox checked={selectedTemplates.includes(props.row.original)} />
        </CheckboxWrapper>
      ),
    }),
    columnHelper.accessor('template_version', {
      header: 'Version',
      size: 0.5,
      enableSorting: false,
    }),
    columnHelper.accessor('template_id', {
      header: 'ID',
      size: 1.5,
      enableSorting: false,
    }),
    columnHelper.accessor('template_literal', {
      header: 'Template',
      size: 8.5,
    }),
    columnHelper.accessor('log_count', {
      header: 'Logs',
      size: 0.75,
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(defaultTemplatesSelectionSorting),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
    };
  }, []);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    pageCount: Math.ceil((templatesCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableHiding: true,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  useEffect(() => {
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
  }, [pageIndex, pageSize]);

  useEffect(() => {
    setPageIndex(0);
  }, [pageSize, sorting, setPageIndex]);

  useEffect(() => {
    const newSortBy = convertSortToApiSchema(sorting);
    setTemplatesSelectionSorting(newSortBy);
  }, [sorting, setTemplatesSelectionSorting]);

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="Anomalous Log Templates" />
      </ComponentTitleWrapper>
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={query}
            isEmpty={isEmpty(data)}
            emptyMessage={EmptyTemplatesMessage}
          >
            {getRowModel().rows.map(row => (
              <TableRow key={row.id} onClick={() => toggleTemplate(row.original)}>
                {row.getVisibleCells().map(cell => (
                  <TableData key={cell.id} style={{...getCellStyles(cell)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </Container>
  );
}
