import {useLogTemplatesBGP, useLogTemplatesBGPCount} from '../../../../data/BGP';
import AssociatedLogsTable from '../../common/AssociatedLogsTable/AssociatedLogsTable';

export default function AssociatedLogsBGP() {
  return (
    <AssociatedLogsTable
      logListQuery={useLogTemplatesBGP}
      logCountQuery={useLogTemplatesBGPCount}
    />
  );
}
