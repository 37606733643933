import StarIcon from '@mui/icons-material/Star';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import useSaveFeedback from '../../../../../helpers/hooks/useSaveFeedback';

const Container = styled.div`
  cursor: pointer;
  display: grid;
  place-content: center;
`;

interface Props {
  initialStarred: boolean;
  size: string;
  unselectedColor: string;
  anomalyId?: string;
}

export default function Star(props: Props) {
  const {initialStarred, size, unselectedColor, anomalyId} = props;
  const [starred, setStarred] = useState(initialStarred);
  const saveFeedback = useSaveFeedback();
  const handleClick = () => {
    if (setStarred && anomalyId) {
      setStarred(!starred);
      saveFeedback({anomaly_id: anomalyId, starred: !starred}, false);
    }
  };

  useEffect(() => {
    setStarred(initialStarred);
  }, [initialStarred]);

  return (
    <Container onClick={handleClick}>
      <StarIcon
        sx={{
          fontSize: size,
          color: starred ? 'rgba(255, 199, 56, 1)' : unselectedColor,
          opacity: starred ? 1 : 0.5,
        }}
      />
    </Container>
  );
}
