import {Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button} from '@mui/material';
import {WselFlaggedUser} from '../../../../openapi-schema/schemaTS';
import {DateTime} from 'luxon';
import {useRecoilValue} from 'recoil';
import {selectedTimezoneState} from '../../../../atoms/selectedTimezone';
import {hourMinute, monthDayYearLong} from '../../../../constants/timeFormats';
import SecurityIcon from '@mui/icons-material/Security';

interface Props {
  isOpen: boolean;
  close: () => void;
  action: () => void;
  user: WselFlaggedUser;
}

// TODO: This component was built before the design artifacts for tailwind were ready. Reimplement once the Katana Dialog has been implemented.
const ManageUserModal = ({isOpen, close, user, action}: Props) => {
  const selectedTimezone = useRecoilValue(selectedTimezoneState);
  const userTimestamp = DateTime.fromISO(user.timestamp, {zone: 'UTC'}).setZone(selectedTimezone);
  return (
    <Dialog
      onClose={() => close()}
      open={isOpen}
      disableEscapeKeyDown={true}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SecurityIcon
          sx={{
            color: '#A9C7FF',
            marginBottom: '16px',
            height: '24px',
          }}
        ></SecurityIcon>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '24px',
            marginBottom: '16px',
          }}
        >
          Manage Flagged User
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            color: '#CDC3CE',
            fontSize: '14px',
            lineHeight: '20px',
            '& span': {
              fontWeight: 700,
            },
          }}
        >
          The user <span>{user.user_name}</span> in the <span>{user.domain_name}</span> domain has
          been flagged for suspicious activity on{' '}
          <span>
            {userTimestamp.toFormat(monthDayYearLong)} at {userTimestamp.toFormat(hourMinute)}
          </span>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          sx={{
            color: `#E1B6FF`,
            marginRight: 'auto',
            textTransform: 'capitalize',
          }}
        >
          Close
        </Button>
        <Button
          onClick={action}
          sx={{
            color: `#E1B6FF`,
            textTransform: 'capitalize',
          }}
        >
          Unflag
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageUserModal;
