import {Duration, DateTime, Interval} from 'luxon';
import {
  AnomalyCategoryBucket,
  AnomalyClassificationBucket,
  AnomalyTypeBucket,
  DeviceCategoryBucket,
  PriorityClassificationBucket,
} from '../openapi-schema/schemaTS';

import envConfig from '../config/env.config';

class AppConfigurationService {
  timelineWindowScaleFactor = 6;
  timelineZoomFactor = 5;
  timelineWindowRepositionFactor = 5;
  timelineWindowShiftFactor = 30;

  timezoneCodes = {
    UTC: 'UTC',
    Local: 'local',
  };

  priorityThreshold = {
    HIGH: 3,
    MED: 2,
    ROUTINE: 1,
    LOW: 1,
  };

  defaultDatacenter = envConfig.defaultDc;

  oidc = {
    ...envConfig.oidc,
  };

  api = {
    ...envConfig.api,
  };

  fpApi = {
    ...envConfig.fpApi,
  };

  tracing = {
    ...envConfig.tracing,
  };

  timelineSizeHours = envConfig.timeLineSizeHours;

  timelinePaddingHours = envConfig.timeLineSizePaddingHours;

  defaultTimelineWindow: {selectedInterval: Interval; timelineInterval: Interval} = {
    selectedInterval: Interval.before(
      DateTime.utc(),
      Duration.fromObject({hours: this.timelineSizeHours})
    ),
    timelineInterval: Interval.before(
      DateTime.utc().plus({hours: this.timelinePaddingHours}),
      Duration.fromObject({hours: this.timelineSizeHours + 2 * this.timelinePaddingHours})
    ),
  };

  liveModeIntervalSeconds = envConfig.liveModeUpdateIntervalSeconds;

  AnomalyTypes = {
    LOG: AnomalyTypeBucket.Log,
    LATENCY: AnomalyTypeBucket.Latency,
    INTERFACE: AnomalyTypeBucket.Interface_Utilization,
    NEIGHBOR_DROP_BGP: AnomalyTypeBucket.BGP_Neighbor_Drop,
    NEIGHBOR_DROP_ISIS: AnomalyTypeBucket['IS-IS_Neighbor_Drop'],
    PATH_CHANGE: AnomalyTypeBucket.Path_Change,
    VIDEO_VOIP: AnomalyTypeBucket['Video/VoIP'],
    FIBER_CHANNEL: AnomalyTypeBucket.Fiber_Channel,
    EXCHANGE_LOG: AnomalyTypeBucket.Exchange_Log,
  };

  Priority = {
    EXTREME: PriorityClassificationBucket.extreme,
    HIGH: PriorityClassificationBucket.high,
    MEDIUM: PriorityClassificationBucket.medium,
    LOW: PriorityClassificationBucket.low,
    NONE: PriorityClassificationBucket.none,
    UNDEFINED: PriorityClassificationBucket.undefined,
  };

  DefaultPriorities = [
    this.Priority.EXTREME,
    this.Priority.HIGH,
    this.Priority.MEDIUM,
    this.Priority.LOW,
    this.Priority.NONE,
  ];

  Status = {
    UNREAD: 'Unread',
    READ: 'Read',
    STARRED: 'Starred',
  };

  Class = {
    ISSUE: AnomalyClassificationBucket.issue,
    ANOMALY: AnomalyClassificationBucket.anomaly,
    UNDEFINED: AnomalyClassificationBucket.undefined,
    NONE: AnomalyClassificationBucket.none,
  };

  DefaultClasses = [this.Class.ANOMALY, this.Class.ISSUE, this.Class.NONE];

  // Resources will need to be a DISTINCT query from the DB once they start changing
  ResourceTypes = {
    ROUTER: DeviceCategoryBucket.Router,
    SWITCH: DeviceCategoryBucket.Switch,
    SWITCHROUTER: DeviceCategoryBucket.SwitchRouter,
    SERVER: DeviceCategoryBucket.Server,
    HOST: DeviceCategoryBucket.Host,
    LOCATION: DeviceCategoryBucket.Location,
  };

  AnomalyCategoryTypes = {
    APPLICATION: AnomalyCategoryBucket.Application_Error,
    USER: AnomalyCategoryBucket.User_Error,
    NETWORK: AnomalyCategoryBucket.Network_Error,
    UNKNOWN: AnomalyCategoryBucket.Unknown,
  };

  PriorityColors = {
    [PriorityClassificationBucket.extreme]: 'rgb(233, 24, 149)',
    [PriorityClassificationBucket.high]: 'rgb(205, 62, 56)',
    [PriorityClassificationBucket.medium]: 'rgb(240, 128, 24)',
    [PriorityClassificationBucket.low]: 'rgb(120, 83, 203)',
    [PriorityClassificationBucket.none]: 'rgb(33, 159, 216)',
    [PriorityClassificationBucket.undefined]: 'rgb(75, 76, 85)',
  };

  topologyDuration = Duration.fromObject({
    days: Number(envConfig.topologyDuration) || 2,
  });

  apiResultLimit = envConfig.resultLimit;

  featureFlags = {
    Sensei: envConfig.features.sensei,
    rbac: envConfig.features.rbac,
    incidents: envConfig.features.incidents,
  };
}

const SingletonAppConfigurationService = new AppConfigurationService();
export default SingletonAppConfigurationService;
