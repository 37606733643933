import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 20px;
  display: grid;
  place-content: center;
  background: rgba(56, 58, 62, 1);
  border-radius: 2px;
`;

const Title = styled.p`
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
`;

interface Props {
  title: string;
}

export default function SectionTitle({title}: Props) {
  return (
    <Container>
      <Title>{title}</Title>
    </Container>
  );
}
