import IconComponent, {IconProps} from '../IconComponent';

export default function LogIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="LogIcon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1C3.44772 1 3 1.44771 3 2V11C3 11.5523 3.44772 12 4 12H10C10.5523 12 11 11.5523 11 11V4L8 1H4ZM7.42678 1.92678C7.26929 1.76929 7 1.88083 7 2.10355V5H9.89645C10.1192 5 10.2307 4.73072 10.0732 4.57322L7.42678 1.92678Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
