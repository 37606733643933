import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';
import styled from 'styled-components';

const Container = styled.span``;

const List = styled.ul`
  margin-left: 16px;
  list-style-type: disc;
`;

const ListItem = styled.li``;

function StatusStateTooltip() {
  return (
    <Container>
      The current state of the testpoint being instrumented.
      <List>
        <ListItem>
          <b>normal</b>: The environment is good, such as low temperature.
        </ListItem>
        <ListItem>
          <b>warning</b>: The environment is bad, such as temperature above normal operation range
          but not too high.
        </ListItem>
        <ListItem>
          <b>critical</b>: The environment is very bad, such as temperature much higher than normal
          operation limit.
        </ListItem>
        <ListItem>
          <b>shutdown</b>: The environment is the worst, the system should be shutdown immediately.
        </ListItem>
        <ListItem>
          <b>notPresent</b>: The environmental monitor is not present, such as temperature sensors
          do not exist.
        </ListItem>
        <ListItem>
          <b> notFunctioning</b>: The environmental monitor does not function properly, such as a
          temperature sensor generates abnormal data like 1000 C.
        </ListItem>
      </List>
    </Container>
  );
}

export function DeviceTooltip() {
  return (
    <TitleTooltip
      header="Device"
      tooltipContent="The name of the device."
      mainContent={<span>Device</span>}
      maxWidth="250px"
    />
  );
}

export function TemperatureTooltip() {
  return (
    <TitleTooltip
      header="Temperature"
      tooltipContent="The recorded measurement of the testpoint being instrumented."
      mainContent={<span>Temperature</span>}
      maxWidth="250px"
    />
  );
}

export function FanTooltip() {
  return (
    <TitleTooltip
      header="Fan Status"
      tooltipContent={<StatusStateTooltip />}
      mainContent={<span>Fan</span>}
    />
  );
}

export function PowerTooltip() {
  return (
    <TitleTooltip
      header="Power Status"
      tooltipContent={<StatusStateTooltip />}
      mainContent={<span>Power</span>}
    />
  );
}
