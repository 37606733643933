import AnomalyDetailKPI from '../../common/AnomalyDetailKPI/AnomalyDetailKPI';
import WordCloudComponent from '../WordCloud/WordCloudComponent';
import TopologyDetailPage from '../../common/TopologyDetailPage/TopologyDetailPage';
import GetLogTableComponent from '../GetLogTableComponent/GetLogTableComponent';
import GoldenLayout from '../../../common/GoldenLayout/GoldenLayout';

const LogDetailDashboard = () => {
  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              component: () => <GetLogTableComponent />,
              width: 74,
            },
            {
              type: 'column',
              width: 26,
              content: [
                {
                  component: () => <AnomalyDetailKPI />,
                  height: 30,
                },
                {
                  component: () => <WordCloudComponent />,
                  height: 40,
                },
                {
                  component: () => <TopologyDetailPage />,
                  height: 30,
                },
              ],
            },
          ],
        },
      ]}
      padding="7px 10px 10px 10px"
    />
  );
};

export default LogDetailDashboard;
