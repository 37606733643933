import styled from 'styled-components';
import {Outlet} from 'react-router-dom';
import Header from '../../components/common/Header/Header';
import ClearanceBanner from '../../components/common/ClearanceBanner/ClearanceBanner';
import SnackbarNotificationWrapper from '../../components/Katana/components/SnackbarNotification/Components/SnackbarNotificationWrapper';
import WarningModal from '../../components/common/WarningModal/WarningModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export default function BaseLayout() {
  return (
    <Container>
      <ClearanceBanner />
      <Header />
      <Outlet />
      <SnackbarNotificationWrapper />
      <WarningModal />
    </Container>
  );
}
