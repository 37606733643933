import {atom} from 'recoil';

interface FingerprintDetails {
  name: string;
  description: string;
  classification: 'AUTO' | 'MANUAL' | 'SILENT';
  category: 'Network';
}

export const fingerprintDetailsState = atom({
  key: 'fingerprintDetails',
  default: {
    name: '',
    description: '',
    classification: 'SILENT',
    category: 'Network',
  } as FingerprintDetails,
});
