import {Container, Text, IconWrapper} from './Run';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styled from 'styled-components';

const ViewContainer = styled(Container)`
  background: rgba(43, 43, 43, 1);

  :hover {
    background: rgba(88, 88, 88, 1);
  }
`;

export default function View() {
  return (
    <ViewContainer>
      <Text>View</Text>
      <IconWrapper>
        <VisibilityIcon sx={{fontSize: '16px'}} />
      </IconWrapper>
    </ViewContainer>
  );
}
