import {BigContainer, CancelButton, ContentContainer, FooterContainer} from './ModalElements';
import ModalHeader from './ModalHeader';
import styled from 'styled-components';
import {ServiceNowModalActions} from '../ServiceNowModalActions';
import {useAnsibleScriptData, useScriptExecutionOutput} from '../../../../data/AutoRemediation';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {isEmpty} from 'lodash';
import ScriptContent from '../../../AnomalyDetail/Log/AutomateWindow/components/ScriptContent';
import FingerprintDetailsScriptVariable from '../../FingerprintDetails/components/FingerprintDetailsScriptVariable';
import {useState} from 'react';
import {ScriptExecutionInfo} from '../../../../openapi-schema/fingerprintSchemaTS';
import AccordionToggle, {
  AccordionContainer,
  ContentWrapper,
  ToggleWrapper,
} from '../../../Katana/components/AccordionToggle/AccordionToggle';

const Container = styled(ContentContainer)`
  ${AccordionContainer} {
    background: black;
    border: 1px solid rgba(137, 134, 142, 1);
    color: white;
    min-height: 40px;
    gap: 0;
  }

  ${ToggleWrapper} {
    padding: 6px 14px;
  }

  ${ContentWrapper} {
    overflow: auto;
  }
`;

interface Props {
  scriptExecutionInfo: ScriptExecutionInfo;
}

export default function ViewResultsModal({scriptExecutionInfo}: Props) {
  const {closeModal} = ServiceNowModalActions();
  const [activeAccordion, setActiveAccordion] = useState('result');
  const resultsQuery = useScriptExecutionOutput(String(scriptExecutionInfo.match_id));
  const scriptDataQuery = useAnsibleScriptData(
    String(scriptExecutionInfo.ansible_script_id),
    Number(scriptExecutionInfo.ansible_script_version)
  );

  return (
    <BigContainer>
      <ModalHeader
        title="View Result"
        subtitle="The output below was returned after executing the attached script"
      />
      <Container>
        <AccordionToggle
          toggleText="View Attached Script"
          openOnRender={activeAccordion === 'script'}
          onToggle={() => setActiveAccordion('script')}
        >
          <QueryLifecycleWrapper
            query={scriptDataQuery}
            isEmpty={isEmpty(scriptDataQuery.data?.script_body)}
            emptyMessage="No script found for this Fingerprint"
            wrapWithTableRow={false}
          >
            <ScriptContent
              fileContent={scriptDataQuery.data?.script_body}
              injectedComponent={FingerprintDetailsScriptVariable}
            />
          </QueryLifecycleWrapper>
        </AccordionToggle>
        <AccordionToggle
          toggleText="View Result"
          openOnRender={activeAccordion === 'result'}
          onToggle={() => setActiveAccordion('result')}
        >
          <QueryLifecycleWrapper
            query={resultsQuery}
            isEmpty={isEmpty(resultsQuery.data?.script_output)}
            emptyMessage="No output found for this executed script."
            wrapWithTableRow={false}
          >
            <ScriptContent fileContent={resultsQuery.data?.script_output} />
          </QueryLifecycleWrapper>
        </AccordionToggle>
      </Container>
      <FooterContainer>
        <CancelButton onClick={closeModal} style={{marginLeft: 'auto'}}>
          Close
        </CancelButton>
      </FooterContainer>
    </BigContainer>
  );
}
