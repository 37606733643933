import GoldenLayout from 'components/common/GoldenLayout/GoldenLayout';
import TopologyDetailPage from '../common/TopologyDetailPage/TopologyDetailPage';
import AssociatedAnomaliesFiberChannel from './AssociatedAnomaliesFiberChannel';
import AssociatedLogsFiberChannel from './AssociatedLogsFiberChannel';
import FiberChannelAnomalyTable from './FiberChannelAnomalyTable';
import FiberChannelUtilizationChart from './FiberChannelUtilizationChart';

export default function FiberChannelDashboard() {
  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              type: 'column',
              width: 67,
              content: [
                {
                  component: () => <FiberChannelAnomalyTable />,
                  height: 60,
                },
                {
                  component: () => <AssociatedLogsFiberChannel />,
                  height: 40,
                },
              ],
            },
            {
              type: 'column',
              width: 33,
              content: [
                {
                  component: () => <FiberChannelUtilizationChart />,
                  height: 30,
                },
                {
                  component: () => <AssociatedAnomaliesFiberChannel />,
                  height: 40,
                },
                {
                  component: () => <TopologyDetailPage />,
                  height: 30,
                },
              ],
            },
          ],
        },
      ]}
      padding="7px 10px 10px 10px"
    />
  );
}
