import {Router, Switch, SwitchRouter} from 'constants/imageLinks';
import config from 'utils/AppConfigurationService';

export const getTopologyNodeIcon = (type: string) => {
  const {ROUTER, SWITCHROUTER} = config.ResourceTypes;
  switch (type) {
    case SWITCHROUTER.toLowerCase():
      return SwitchRouter;
    case ROUTER.toLowerCase():
      return Router;
    default:
      return Switch;
  }
};
