import {SortAscendingIcon, SortDescendingIcon, UnsortedIcon} from 'components/Katana/Icons';
import {Header} from '@tanstack/react-table';

interface Props {
  column: Header<any, unknown>;
}

const getSortIcon = (column: Header<any, any>) => {
  const sortDirection = column.column.getIsSorted();

  if (sortDirection) {
    if (sortDirection === 'desc') return <SortDescendingIcon className="ml-0.5" />;
    else return <SortAscendingIcon className="ml-0.5" />;
  }

  return <UnsortedIcon className="ml-0.5" />;
};

export default function TableSortArrow({column}: Props) {
  return <>{column.column.getCanSort() && getSortIcon(column)}</>;
}
