import {atom} from 'recoil';
import {TopologyEdgeData, TopologyNodeData} from '../data/types';

export const whatIfSelectedNodeDataState = atom({
  key: 'whatIfSelectedNodeData',
  default: undefined as TopologyNodeData | undefined,
});

export const whatIfSelectedEdgeDataState = atom({
  key: 'whatIfSelectedEdgeeData',
  default: undefined as TopologyEdgeData | undefined,
});
