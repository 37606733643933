import {
  BigContainer,
  CancelButton,
  ConfirmButton,
  ContentContainer,
  FooterContainer,
} from './ModalElements';
import ModalHeader from './ModalHeader';
import styled from 'styled-components';
import {ServiceNowModalActions} from '../ServiceNowModalActions';
import {useAnsibleScriptData} from '../../../../data/AutoRemediation';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {isEmpty} from 'lodash';
import ScriptContent from '../../../AnomalyDetail/Log/AutomateWindow/components/ScriptContent';
import FingerprintDetailsScriptVariable from '../../FingerprintDetails/components/FingerprintDetailsScriptVariable';
import {ScriptExecutionInfo} from '../../../../openapi-schema/fingerprintSchemaTS';

const Bold = styled.span`
  font-weight: 700;
`;

const ContentBlock = styled.div`
  background: black;
  border: 1px solid rgba(137, 134, 142, 1);
  height: 100%;
  color: white;
  overflow: auto;
  padding: 0 14px;
`;

interface Props {
  scriptExecutionInfo: ScriptExecutionInfo;
}

export default function RunScriptModal({scriptExecutionInfo}: Props) {
  const query = useAnsibleScriptData(
    String(scriptExecutionInfo.ansible_script_id),
    Number(scriptExecutionInfo.ansible_script_version)
  );
  const {closeModal, goToNextPage} = ServiceNowModalActions();

  return (
    <BigContainer>
      <ModalHeader
        title={`Run Script for ${scriptExecutionInfo.fingerprint_name}`}
        subtitle={
          <>
            Review the script attached to this <Bold>Fingerprint</Bold>. Click <Bold>Next</Bold>{' '}
            when ready to execute this script.
          </>
        }
      />
      <ContentContainer>
        <ContentBlock>
          <QueryLifecycleWrapper
            query={query}
            isEmpty={isEmpty(query.data?.script_body)}
            emptyMessage="No script found for this Fingerprint"
            wrapWithTableRow={false}
          >
            <ScriptContent
              fileContent={query.data?.script_body}
              injectedComponent={FingerprintDetailsScriptVariable}
            />
          </QueryLifecycleWrapper>
        </ContentBlock>
      </ContentContainer>
      <FooterContainer>
        <CancelButton onClick={closeModal} style={{marginRight: 'auto'}}>
          Close
        </CancelButton>
        <ConfirmButton onClick={() => goToNextPage(scriptExecutionInfo)}>Next</ConfirmButton>
      </FooterContainer>
    </BigContainer>
  );
}
