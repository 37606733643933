import {useQuery} from 'react-query';
import {useRecoilValue} from 'recoil';
import {associatedAnomaliesSortingState} from '../atoms/associatedAnomaliesSorting';
import {timelineState} from '../atoms/dashboard';
import {logTableSortingState} from '../atoms/logTableSorting';
import {selectedAnomalyIdState} from '../atoms/selectedAnomaly';
import useDebouncedInput from '../helpers/hooks/useDebouncedInput';
import config from '../utils/AppConfigurationService';
import {
  getBGPPeers,
  getBGPPeersCount,
  getLogTemplatesBGP,
  getLogTemplatesBGPCount,
  getRelatedAnomaliesBGP,
  getRelatedAnomaliesBGPCount,
} from '../utils/PythonApiUtils';
import {LogListQuery, SortedPagedQuery} from './types';

export const useSelectedAnomalyBGPPeers: SortedPagedQuery = (
  pageIndex = 0,
  limit = config.apiResultLimit,
  sortFields = []
) => {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'selectedAnomalyBGPPeers',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      sortFields,
    ],
    () =>
      getBGPPeers({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: sortFields,
      })
  );
};

export function useSelectedAnomalyBGPPeersCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['selectedAnomalyBGPPeersCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getBGPPeersCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useSelectedAnomalyRelatedAnomaliesBGP(
  pageIndex = 0,
  limit = config.apiResultLimit
) {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const associatedAnomaliesSorting = useRecoilValue(associatedAnomaliesSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'relatedAnomaliesBGP',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      associatedAnomaliesSorting,
    ],
    () =>
      getRelatedAnomaliesBGP({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: associatedAnomaliesSorting,
      })
  );
}
export function useSelectedAnomalyRelatedAnomaliesBGPCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['relatedAnomaliesBGPCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getRelatedAnomaliesBGPCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export const useLogTemplatesBGP: LogListQuery = (pageIndex = 0, limit = config.apiResultLimit) => {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const sortFields = useRecoilValue(logTableSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['logTemplatesBGP', selectedAnomalyId, debouncedStart, debouncedEnd, offset, limit, sortFields],
    () =>
      getLogTemplatesBGP({
        anomaly_id: selectedAnomalyId,
        offset: offset,
        limit: limit,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        sort_fields: sortFields,
      })
  );
};

export function useLogTemplatesBGPCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['logTemplatesBGPCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getLogTemplatesBGPCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}
