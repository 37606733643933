import {useLogTemplatesFiberChannel, useLogTemplatesFiberChannelCount} from 'data/FiberChannel';
import AssociatedLogsTable from '../common/AssociatedLogsTable/AssociatedLogsTable';

export default function AssociatedLogsFiberChannel() {
  return (
    <AssociatedLogsTable
      logListQuery={useLogTemplatesFiberChannel}
      logCountQuery={useLogTemplatesFiberChannelCount}
    />
  );
}
