import styled from 'styled-components';
import useURLSynchronizedTimeline from '../../helpers/hooks/useURLSynchronizedTimeline';
import WhatIfControls from './WhatIfControls/WhatIfControls';
import TopologyWhatIf from './TopologyWhatIf/TopologyWhatIf';
import WhatIfChartWrapper from './WhatIfChart/components/WhatIfChartWrapper';
import {withTracingErrorBoundary} from '../../tracing/errorReporting';

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .lm_item {
    background: rgba(25, 25, 25, 1);
  }
`;

const MainContent = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const TopologyWrapper = styled.div`
  background: rgba(25, 25, 25, 1);
  width: 100%;
  height: 100%;
  position: relative;
`;

function WhatIfCalculator() {
  useURLSynchronizedTimeline();

  return (
    <Container>
      <WhatIfControls />
      <MainContent>
        <TopologyWrapper>
          <TopologyWhatIf />
        </TopologyWrapper>
        <WhatIfChartWrapper />
      </MainContent>
    </Container>
  );
}
export default withTracingErrorBoundary(WhatIfCalculator);
