import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {useRecoilState, useRecoilValue} from 'recoil';
import {warningModalOpenState} from '../../../atoms/warningModalOpen';
import {warningModalDataState} from '../../../atoms/warningModalData';
import {BannerAwareDialog} from '../BannerAwareDialog/BannerAwareDialog';
import {useLocation} from 'react-router-dom';
import {useCallback, useEffect} from 'react';

interface ModalElementProps {
  small: boolean;
}

const Container = styled.div<ModalElementProps>`
  display: flex;
  flex-direction: column;
  background: rgba(25, 25, 25, 1);
  color: white;
  width: min(100%, ${props => (props.small ? '400px' : '500px')});
`;

const Header = styled.div`
  width: 100%;
  padding: 18px 10px 16px 10px;
  background: rgba(32, 34, 38, 1);
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
  position: relative;
`;

const CloseIconWrapper = styled.div`
  display: grid;
  place-content: center;
  position: absolute;
  cursor: pointer;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
`;

const Body = styled.div<ModalElementProps>`
  padding: 30px 10px;
  font-size: 15px;
  line-height: 20px;
  text-align: ${props => (props.small ? 'center' : 'left')};
  display: flex;
  justify-content: center;
`;

const Text = styled.p<ModalElementProps>`
  max-width: ${props => (props.small ? '280px' : 'auto')};

  b {
    font-weight: 700;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  background: rgba(32, 34, 38, 1);
`;

const ConfirmButton = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding: 6px 10px;
  border: none;
  color: white;
  cursor: pointer;
  background: rgba(67, 79, 203, 1);
`;

const CancelButton = styled(ConfirmButton)`
  background: rgba(75, 76, 85, 1);
  margin-left: auto;
`;

export default function WarningModal() {
  const [warningModalOpen, setWarningModalOpen] = useRecoilState(warningModalOpenState);
  const warningModalData = useRecoilValue(warningModalDataState);
  const location = useLocation();

  const closeWarningModal = useCallback(() => setWarningModalOpen(false), [setWarningModalOpen]);
  const isSmall = warningModalData.modalSize === 'small';

  useEffect(() => closeWarningModal(), [closeWarningModal, location.pathname]);

  return (
    <BannerAwareDialog open={warningModalOpen} maxWidth={false}>
      <Container small={isSmall}>
        <Header>
          <Text small={isSmall}>{warningModalData.headerText}</Text>
          <CloseIconWrapper onClick={closeWarningModal}>
            <CloseIcon />
          </CloseIconWrapper>
        </Header>
        <Body small={isSmall}>
          <Text small={isSmall}>{warningModalData.warningMessage}</Text>
        </Body>
        <Footer>
          <CancelButton onClick={closeWarningModal}>
            {warningModalData.cancelButtonText}
          </CancelButton>
          <ConfirmButton onClick={() => warningModalData.confirmButtonCallback()}>
            {warningModalData.confirmButtonText}
          </ConfirmButton>
        </Footer>
      </Container>
    </BannerAwareDialog>
  );
}
