import styled from 'styled-components';

export const BigContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(30, 30, 30, 1);
  width: min(700px, 100%);
  height: 100%;
`;

export const SmallContainer = styled(BigContainer)`
  width: min(480px, 100%);
  height: min(450px, 100%);
`;

export const FooterContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  background: rgba(30, 30, 30, 1);
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
`;

export const ConfirmButton = styled.button`
  color: white;
  padding: 8px;
  background: rgba(67, 79, 203, 1);
  border-radius: 2px;
  border: none;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
`;

export const CancelButton = styled(ConfirmButton)`
  background: inherit;
  color: rgba(208, 207, 210, 1);
  border: 1px solid rgba(208, 207, 210, 1);
  padding: 7px;
`;

export const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;

  ::-webkit-scrollbar {
    background: rgba(30, 30, 30, 1);
  }
`;
