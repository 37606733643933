import styled from 'styled-components';

interface ContainerProps {
  x: number;
  y: number;
}

export const HiddenElement = styled.div<ContainerProps>`
  position: absolute;
  top: ${props => `${props.y}px`};
  left: ${props => `${props.x}px`};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  line-height: 16px;
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.p`
  font-weight: 700;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const Text = styled.p``;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: white;
`;
