import Title from 'components/Katana/components/Title/Title';
import CapabilityCard from './CapabilityCard';
import {useRecoilValue} from 'recoil';
import {senseiChatActionsState} from 'atoms/sensei';

const data = [
  {
    title: 'Data Visualization',
    mainText: "Show me a graph of hosts with logs containing 'error' in the past 30 days",
  },
  {
    title: 'Anomaly Detection',
    mainText: 'Have there been any unusual patterns in the logs this week?',
  },
  {
    title: 'Root Cause Analysis',
    mainText: 'Why is there so much latency on hostname?',
  },
];

export default function Capabilities() {
  const senseiChatActions = useRecoilValue(senseiChatActionsState);

  return (
    <div className="flex w-full flex-col gap-4">
      <Title title="Explore Capabilities" />
      <div className="flex items-start gap-4">
        {data.map(element => (
          <CapabilityCard
            title={element.title}
            mainText={element.mainText}
            onClick={() => {
              senseiChatActions?.sendMessage({
                questions: [element.mainText],
                request_type: 'SenseiQuestionRequest',
              });
            }}
            key={element.mainText}
          />
        ))}
      </div>
    </div>
  );
}
