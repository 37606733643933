import {TooltipModel} from 'chart.js';
import {HiddenElement} from 'components/common/TopologyTooltips/TopologyTooltipElements';
import Tooltip from 'components/Katana/components/Tooltip/Tooltip';
import {MtbfDeviceHealthOverview} from 'openapi-schema/schemaTS';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DeviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p``;

interface Props {
  tooltipData: TooltipModel<'scatter'>;
}

export default function ScatterplotTooltip({tooltipData}: Props) {
  return (
    <Tooltip
      tooltipContent={
        <Container>
          {tooltipData.dataPoints.map((data, index) => {
            const rawData = data.raw as MtbfDeviceHealthOverview;

            return (
              <DeviceWrapper key={rawData.node_id}>
                <Text>Device: {rawData.node_name}</Text>
                <Text>Health: {rawData.health_status}</Text>
              </DeviceWrapper>
            );
          })}
        </Container>
      }
      mainContent={<HiddenElement x={tooltipData.caretX} y={tooltipData.caretY} />}
      onCanvas={true}
      maxWidth={'none'}
    />
  );
}
