import {
  Title,
  TitleRow,
  DropdownText,
  BadgeWrapper,
  BadgeText,
  Container,
  ValueWrapper,
  DropdownTitle,
} from './InfoBoxStyles';
import config from '../../../../../utils/AppConfigurationService';
import {startCase} from 'lodash';
import {Select, MenuItem} from '@mui/material';
import {useState} from 'react';
import useSaveFeedback from '../../../../../helpers/hooks/useSaveFeedback';
import {PriorityClassificationBucket} from '../../../../../openapi-schema/schemaTS';
import ObservationPriorityIcon from 'components/common/ObservationPriorityIcon/ObservationPriorityIcon';

const PriorityColors = config.PriorityColors;

const getIconInfo = (priority: PriorityClassificationBucket) => {
  return {
    color: PriorityColors[priority],
    icon: <ObservationPriorityIcon priority={priority} />,
  };
};

const dropdownOptions = config.DefaultPriorities.map(priority => {
  return {
    icon: <ObservationPriorityIcon priority={priority} color />,
    text: startCase(priority),
    value: priority,
  };
});

interface Props {
  initialPriorityBucket: PriorityClassificationBucket;
  anomalyId: string;
}

export default function Priority({initialPriorityBucket, anomalyId}: Props) {
  const [priorityBucket, setPriorityBucket] = useState(initialPriorityBucket);
  const saveFeedback = useSaveFeedback();

  return (
    <Container>
      <TitleRow>
        <Title>Priority</Title>
      </TitleRow>
      <Select
        MenuProps={{disablePortal: true}}
        onChange={e => {
          const newPriorityBucket = e.target.value as PriorityClassificationBucket;
          setPriorityBucket(newPriorityBucket);
          saveFeedback({anomaly_id: anomalyId, priority_classification: newPriorityBucket}, false);
        }}
        value={priorityBucket}
        renderValue={() => (
          <ValueWrapper style={{marginTop: '4px'}}>
            <BadgeWrapper color={getIconInfo(priorityBucket).color}>
              {getIconInfo(priorityBucket).icon}
              <BadgeText>{priorityBucket}</BadgeText>
            </BadgeWrapper>
          </ValueWrapper>
        )}
      >
        <DropdownTitle>priority</DropdownTitle>
        {dropdownOptions.map(option => (
          <MenuItem key={option.text} value={option.value}>
            {option.icon}
            <DropdownText>{option.text}</DropdownText>
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
}
