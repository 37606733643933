import {snackbarDataState} from 'atoms/snackbarData';
import {isEmpty} from 'lodash';
import {useRecoilTransaction_UNSTABLE} from 'recoil';
import {selectedEdgeIdsState, selectedNodeIdsState} from '../../atoms/dashboard';
import {selectedAnomalyDataState, selectedAnomalyIdState} from '../../atoms/selectedAnomaly';
import {HotlAnomaly, HotlErAnomalyResponseResult} from '../../openapi-schema/schemaTS';
import config from '../../utils/AppConfigurationService';
import useSelectedNodes from './useSelectedNodes';

const useTopologyActions = () => {
  const {
    selectedNodesLog,
    selectedNodesInterfaceUtilization,
    selectedNodesLatency,
    selectedEdgesLatency,
  } = useSelectedNodes();

  const unselectElement = useRecoilTransaction_UNSTABLE(
    ({reset}) =>
      () => {
        reset(selectedAnomalyIdState);
        reset(selectedEdgeIdsState);
        reset(selectedNodeIdsState);
      },
    []
  );

  interface SelectAnomalyParams {
    anomaly: HotlAnomaly | HotlErAnomalyResponseResult;
    detailsPage: boolean;
  }

  const selectAnomaly = useRecoilTransaction_UNSTABLE(
    ({set, reset}) =>
      ({anomaly, detailsPage}: SelectAnomalyParams) => {
        if (!anomaly) {
          reset(selectedEdgeIdsState);
          reset(selectedNodeIdsState);
          return;
        }
        set(selectedAnomalyIdState, anomaly.anomaly_id);
        set(selectedAnomalyDataState, anomaly);

        if (
          anomaly.anomaly_type === config.AnomalyTypes.LOG ||
          anomaly.anomaly_type === config.AnomalyTypes.EXCHANGE_LOG
        ) {
          const selectedNodes = selectedNodesLog(anomaly);
          set(selectedNodeIdsState, selectedNodes);
          reset(selectedEdgeIdsState);
          if (!detailsPage && isEmpty(selectedNodes)) {
            set(snackbarDataState, {
              severity: 'default',
              message: `Cannot display ${anomaly.affected_resources[0]} on the topology`,
              open: true,
              subtitle: 'The affected host or device is not connected to the visible network',
              duration: 3000,
            });
          }
        } else if (anomaly.anomaly_type === config.AnomalyTypes.INTERFACE) {
          set(selectedNodeIdsState, selectedNodesInterfaceUtilization(anomaly));
          set(selectedEdgeIdsState, [anomaly.affected_resources[0]]);
        } else if (anomaly.anomaly_type === config.AnomalyTypes.LATENCY) {
          set(selectedNodeIdsState, selectedNodesLatency(anomaly));
          set(selectedEdgeIdsState, selectedEdgesLatency(anomaly));
        } else {
          reset(selectedEdgeIdsState);
          reset(selectedNodeIdsState);
        }
      },
    [
      selectedNodesLog,
      selectedNodesInterfaceUtilization,
      selectedNodesLatency,
      selectedEdgesLatency,
    ]
  );

  return {unselectElement, selectAnomaly};
};

export default useTopologyActions;
