import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.p`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

interface ValueProps {
  color?: string;
}

const Value = styled.p<ValueProps>`
  font-size: 16px;
  line-height: 18px;
  color: ${props => props.color || 'inherit'};
`;

interface Props {
  title: string;
  value: string;
  color?: string;
}

export default function Stat({title, value, color}: Props) {
  return (
    <Container>
      <Title>{title}</Title>
      <Value color={color}>{value}</Value>
    </Container>
  );
}
