import FlaggedUsersTable from './components/FlaggedUsersTable';
import TableTitleRow from './components/TableTitleRow';
import {Container} from 'components/common/TableElements/TableElements';

export default function flaggedUsersPane() {
  return (
    <Container>
      <TableTitleRow />
      <FlaggedUsersTable />
    </Container>
  );
}
