import classNames from 'classnames';

const baseClasses = 'text-[#CF91FF] inline-block';
const largeClasses = 'text-title-large';
const mediumClasses = 'text-title-medium';
const smallClasses = 'text-title-small';

interface Props {
  title: string;
  size?: 'large' | 'medium' | 'small';
}

export default function Title({title, size = 'large'}: Props) {
  return (
    <span
      className={classNames(baseClasses, {
        [largeClasses]: size === 'large',
        [mediumClasses]: size === 'medium',
        [smallClasses]: size === 'small',
      })}
    >
      {title}
    </span>
  );
}
