import {groupBy, isEmpty, uniq} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {selectedEdgeIdsState, selectedNodeIdsState} from '../../../atoms/dashboard';
import {selectedAnomalyDataState} from '../../../atoms/selectedAnomaly';
import {useSelectedAnomaly} from '../../../data/Anomalies';
import {useDevicesBGP, useDevicesISIS, useTopologyData} from '../../../data/Topology';
import {TopologyElements} from '../../../data/types';
import useSelectNodesNeighborDrop from '../../../helpers/hooks/useSelectNodesNeighborDrop';
import {TopologyBgpPeers, TopologyIsisAdjacencies} from '../../../openapi-schema/schemaTS';
import {getTopologyElementBackgroundColor} from '../../../utils/getTopologyElementBackgroundColor';
import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TopologyBase from '../TopologyBase/TopologyBase';
import config from '../../../utils/AppConfigurationService';
import {EventObjectEdge, EventObjectNode} from 'cytoscape';
import {
  ASNodeAttributes,
  defaultEdgeAttributes,
  defaultNodeAttributes,
} from '../../../constants/TopologyContants';
import SimpleNetworkDeviceTooltip from '../TopologyTooltips/SimpleNetworkDeviceTooltip';
import SimpleNetworkInterfaceTooltip from '../TopologyTooltips/SimpleNetworkInterfaceTooltip';
import RemoteASNodeTooltip from '../TopologyTooltips/RemoteASNodeTooltip';
import LocalASNodeTooltip from '../TopologyTooltips/LocalASNodeTooltip';
import ISISGroupTooltip from '../TopologyTooltips/ISISGroupTooltip';
import {WanTopologyCloud, WanTopologyCloudRed} from 'constants/imageLinks';

const tooltipTypes = {
  default: 'default',
  remoteAS: 'remoteAS',
  localAS: 'localAS',
  ISISbox: 'ISISbox',
};

interface Props {
  isDetailsPage?: boolean;
}

export default function TopologyWAN({isDetailsPage = false}: Props) {
  const {data: topologyElements, isLoading, isError, refetch} = useTopologyData();
  const {data: deviceBGPdata, isLoading: isLoadingBGP, isError: isErrorBGP} = useDevicesBGP();
  const {data: deviceISISdata, isLoading: isLoadingISIS, isError: isErrorISIS} = useDevicesISIS();
  const {data: selectedAnomalyDataFull} = useSelectedAnomaly();
  const [tooltipComponent, setTooltipComponent] = useState<JSX.Element | undefined>();
  const selectedNodeIds = useRecoilValue(selectedNodeIdsState);
  const selectedEdgeIds = useRecoilValue(selectedEdgeIdsState);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);
  const {selectNodesBGP, selectNodesISIS} = useSelectNodesNeighborDrop();

  const mouseOverNode = useCallback(
    (event: EventObjectNode) => {
      const node = event.target.data();
      const tooltip = node.tooltip;
      if (isDetailsPage || !tooltip) return;
      if (tooltip === tooltipTypes.default) {
        setTooltipComponent(<SimpleNetworkDeviceTooltip event={event} />);
      } else if (tooltip === tooltipTypes.remoteAS) {
        setTooltipComponent(<RemoteASNodeTooltip event={event} AS={node.id} />);
      } else if (tooltip === tooltipTypes.localAS) {
        setTooltipComponent(<LocalASNodeTooltip event={event} AS={node.id} />);
      } else if (tooltip === tooltipTypes.ISISbox) {
        setTooltipComponent(<ISISGroupTooltip event={event} />);
      }
    },
    [isDetailsPage]
  );

  const mouseOutNode = useCallback(
    (event: EventObjectNode) => {
      if (isDetailsPage) return;
      setTooltipComponent(undefined);
    },
    [isDetailsPage]
  );

  const mouseOverEdge = useCallback(
    (event: EventObjectEdge) => {
      if (isDetailsPage || event.target.data().noTooltip) return;
      setTooltipComponent(<SimpleNetworkInterfaceTooltip event={event} />);
    },
    [isDetailsPage]
  );

  const mouseOutEdge = useCallback(
    (event: EventObjectEdge) => {
      if (isDetailsPage) return;
      setTooltipComponent(undefined);
    },
    [isDetailsPage]
  );

  const formatNodes = useCallback(
    (
      topologyElements: TopologyElements,
      deviceBGPdata: TopologyBgpPeers[] | undefined,
      deviceISISdata: TopologyIsisAdjacencies[] | undefined
    ) => {
      if (!deviceBGPdata || !deviceISISdata) return [];

      const highlightedBackgroundColor = getTopologyElementBackgroundColor(
        selectedAnomalyData?.priority_classification
      );
      const groupedDevices = groupBy(deviceBGPdata, 'node_id');
      const ISISNodesList = deviceISISdata
        .flatMap(peer => [peer.node_id, peer.isis_adj_node_ids])
        .flat();
      const uniqueISISNodesList = uniq(ISISNodesList);

      const topologyNodes = topologyElements.nodes.map(node => {
        return {
          ...node,
          data: {
            ...node.data,
            selectedBackgroundColor: highlightedBackgroundColor,
            tooltip: tooltipTypes.default,
            parent:
              groupedDevices[node.data.id]?.[0].local_as ||
              (uniqueISISNodesList.includes(node.data.id)
                ? `${node.data.id}_ISIS_Compound`
                : undefined),
          },
        };
      });

      const remoteAsList = deviceBGPdata.flatMap(peer =>
        peer.peer_type === 'EBGP' && isEmpty(peer.peer_remote_node_ids) ? [peer.peer_remote_as] : []
      );
      const uniqueRemoteASLIST = uniq(remoteAsList);

      const RemoteAsNodes = uniqueRemoteASLIST.map(AS => {
        return {
          data: {
            ...defaultNodeAttributes,
            ...ASNodeAttributes,
            id: String(AS),
            label: `AS ${AS}`,
            icon: selectedNodeIds.includes(String(AS)) ? WanTopologyCloudRed : WanTopologyCloud,
            tooltip: tooltipTypes.remoteAS,
          },
        };
      });

      const localAsList = deviceBGPdata.map(peer => peer.local_as);
      const uniqueLocalASList = uniq(localAsList);

      const LocalAsNodes = uniqueLocalASList.map(AS => {
        return {
          data: {
            ...defaultNodeAttributes,
            ...ASNodeAttributes,
            id: String(AS),
            label: `AS ${AS}`,
            tooltip: tooltipTypes.localAS,
          },
        };
      });

      const isisAreaByNodeId = deviceISISdata?.reduce((acc, value) => {
        if (!value.isis_area_address) return acc;

        acc[value.node_id] = value.isis_area_address;
        acc[value.isis_adj_node_ids[0]] = value.isis_area_address;

        return acc;
      }, {} as {[key: string]: string});

      const ISISCompoundNodes = uniqueISISNodesList.map(node => {
        return {
          data: {
            ...defaultNodeAttributes,
            ...ASNodeAttributes,
            id: `${node}_ISIS_Compound`,
            bgColor: 'black',
            selectedBackgroundColor: 'black',
            borderColor: 'white',
            selectedBorderColor: highlightedBackgroundColor,
            tooltip: tooltipTypes.ISISbox,
            label: `IS-IS Area ${isisAreaByNodeId[node]}`,
            area: isisAreaByNodeId[node],
          },
        };
      });

      return [...topologyNodes, ...RemoteAsNodes, ...LocalAsNodes, ...ISISCompoundNodes];
    },
    [selectedAnomalyData, selectedNodeIds]
  );

  const formatEdges = useCallback(
    (topologyElements: TopologyElements, deviceBGPdata: TopologyBgpPeers[] | undefined) => {
      const highlightedLinecolor = getTopologyElementBackgroundColor(
        selectedAnomalyData?.priority_classification
      );

      const topologyEdges = topologyElements.edges.map(edge => {
        return {
          ...edge,
          data: {
            ...edge.data,
            selectedLineColor: highlightedLinecolor,
          },
        };
      });

      if (!deviceBGPdata) return topologyEdges;

      const groupedPeerTypes = groupBy(deviceBGPdata, 'peer_type');
      const EBGP = groupedPeerTypes.EBGP;
      const IBGP = groupedPeerTypes.IBGP;

      if (!EBGP || !IBGP) return topologyEdges;

      const uniqEBGPEdges = EBGP.reduce((acc, curr) => {
        const edgeID = `${curr.node_id}_${curr.peer_remote_as}`;
        return {
          ...acc,
          [edgeID]: curr,
        };
      }, {} as {[key: string]: TopologyBgpPeers});

      const EBGPEdges = Object.values(uniqEBGPEdges).map(peer => {
        return {
          data: {
            ...defaultEdgeAttributes,
            id: `${peer.node_id}_${peer.peer_remote_as}`,
            source: peer.node_id,
            target: String(peer.peer_remote_as),
            selectedLineColor: highlightedLinecolor,
            label: `AS ${peer.local_as} - AS ${peer.peer_remote_as}`,
            noTooltip: true,
          },
        };
      });

      //It has been said that all IBGP observation will have an existing interface. Everything below can be removed if that is true.
      //Filter out IBGP records that have an existing interface connection on the topology
      const IBGPRecordsToAdd = IBGP.flatMap(peer => {
        return peer.peer_remote_node_ids.flatMap(remote_node_id => {
          if (
            topologyElements.edgesBySource?.[peer.node_id]?.some(
              edge => edge.data.target_node_id === remote_node_id
            ) ||
            topologyElements.edgesBySource?.[remote_node_id]?.some(
              edge => edge.data.target_node_id === peer.node_id
            )
          ) {
            return [];
          }
          return {
            ...peer,
            peer_remote_node_ids: [remote_node_id],
          };
        });
      });

      //Remove duplicate IBGP peers that need to be added to the topology
      const uniqIBGPEdges = IBGPRecordsToAdd.reduce((acc, curr) => {
        const edgeID = `${curr.node_id}_${curr.peer_remote_node_ids[0]}`;
        return {
          ...acc,
          [edgeID]: curr,
        };
      }, {} as {[key: string]: TopologyBgpPeers});

      //Create edge object
      const IBGPEdges = Object.values(uniqIBGPEdges).map(peer => {
        return {
          data: {
            ...defaultEdgeAttributes,
            id: `${peer.node_id}_${peer.peer_remote_node_ids[0]}`,
            source: peer.node_id,
            target: peer.peer_remote_node_ids[0],
            selectedLineColor: highlightedLinecolor,
            label: '',
            noTooltip: true,
          },
        };
      });

      return [...topologyEdges, ...EBGPEdges, ...IBGPEdges];
    },
    [selectedAnomalyData]
  );

  const graphElements = useMemo(() => {
    return {
      nodes: formatNodes(topologyElements, deviceBGPdata, deviceISISdata),
      edges: formatEdges(topologyElements, deviceBGPdata),
    };
  }, [formatNodes, topologyElements, deviceBGPdata, deviceISISdata, formatEdges]);

  useEffect(() => {
    if (selectedAnomalyDataFull?.anomaly_type === config.AnomalyTypes.NEIGHBOR_DROP_BGP) {
      selectNodesBGP(selectedAnomalyDataFull);
    } else if (selectedAnomalyDataFull?.anomaly_type === config.AnomalyTypes.NEIGHBOR_DROP_ISIS) {
      selectNodesISIS(selectedAnomalyDataFull);
    }
  }, [
    selectedAnomalyDataFull,
    topologyElements,
    deviceBGPdata,
    deviceISISdata,
    selectNodesBGP,
    selectNodesISIS,
  ]);

  if (isError || isErrorBGP || isErrorISIS) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading || isLoadingBGP || isLoadingISIS) {
    return <LoadingSpinner />;
  }

  return (
    <TopologyBase
      mouseOverNode={mouseOverNode}
      mouseOutNode={mouseOutNode}
      mouseOverEdge={mouseOverEdge}
      mouseOutEdge={mouseOutEdge}
      selectedNodeIds={selectedNodeIds}
      selectedEdgeIds={selectedEdgeIds}
      graphElements={graphElements}
      zoomToSelectedElements={isDetailsPage}
      tooltipComponent={tooltipComponent}
    />
  );
}
