import {atom} from 'recoil';
import {HotlAnomaly} from '../openapi-schema/schemaTS';

export const selectedAnomalyIdState = atom({
  key: 'selectedAnomalyId',
  default: '',
});

export const selectedAnomalyDataState = atom({
  key: 'selectedAnomalyData',
  default: null as HotlAnomaly | null,
});
