import styled from 'styled-components';

type SpinnerProps = {
  bgColor?: string;
};

const Container = styled.div<SpinnerProps>`
  width: 100%;
  height: 100%;
  background: ${({bgColor}) => bgColor || 'black'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .lds-dual-ring {
    max-height: 80px;
    height: 60%;
    min-height: 40px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 6px solid white;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function LoadingSpinner(props: SpinnerProps) {
  return (
    <Container {...props} data-testid="loading-spinner">
      <div className="lds-dual-ring"></div>
    </Container>
  );
}
