import {EventObjectNode} from 'cytoscape';
import {useDevicesBGP} from '../../../data/Topology';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';
import {Container, Header, Text, HiddenElement} from './TopologyTooltipElements';

interface Props {
  event: EventObjectNode;
  AS: string;
}

export default function RemoteASNodeTooltip({event, AS}: Props) {
  const {data} = useDevicesBGP();
  const ip = data?.find(peer => String(peer.peer_remote_as) === AS)?.peer_remote_addr;

  return (
    <Tooltip
      tooltipContent={
        <Container>
          <Header>AS {AS} ADDRESS</Header>
          {ip ? <Text>{ip}</Text> : <Text>Remote address not found</Text>}
        </Container>
      }
      mainContent={
        <HiddenElement x={event.originalEvent.offsetX} y={event.originalEvent.offsetY} />
      }
      onCanvas={true}
      maxWidth={'none'}
    />
  );
}
