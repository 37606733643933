import IconComponent, {IconProps} from './IconComponent';

export default function UnsortedIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 18}} className={className}>
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="UnsortedIcon"
      >
        <path d="M6 11L10 16L14 11H6Z" fill="#666666" />
        <path d="M6 9L10 4L14 9H6Z" fill="#666666" />
      </svg>
    </IconComponent>
  );
}
