import {tryBoolean, tryNumber, tryString} from '../utils/conversions';

declare global {
  interface Window {
    sliceup: any;
  }
}

/**
 * Expose the defined environment variables or default
 * window.sliceup is defined in index.html and gets dynamically generated at runtime during app startup
 */
const {sliceup} = window;
export default {
  api: {
    // URL for the Web API
    url: tryString(sliceup?.API_URL, 'http://dashboard-api-buzz.kube.sliceup.co'),
  },
  senseiApi: {
    url: tryString(sliceup?.API_WS_URL, 'wss://sensei-api.buzz.kube.sliceup.co/v1/sensei/ws'),
  },
  fpApi: {
    // URL for the fingerprint microservice
    url: tryString(sliceup?.FINGERPRINT_API_URL, 'http://fingerprint-ms.kube.sliceup.co'),
  },
  oidc: {
    url: tryString(sliceup?.OIDC_URL, 'http://keycloak-buzz.kube.sliceup.co/realms/sliceup'),
    clientId: tryString(sliceup?.OIDC_CLIENT_ID, 'sliceup-dashboard'),
  },
  tracing: {
    // Tracing disabled when url is undefined
    url: tryString(sliceup?.TRACING_URL, '') || undefined,
    // Service name to Tempo traces
    serviceName: tryString(sliceup?.TRACING_SERVICE_NAME, 'expero-web'),
  },
  // Datacenter name to use in requests and urls
  defaultDc: tryString(sliceup?.DEFAULT_DC, 'Langley'),
  // Base limit applied to queries
  resultLimit: tryNumber(sliceup?.RESULT_LIMIT, 200),
  // Number of days to request for topology data
  topologyDuration: tryNumber(sliceup?.TOPOLOGY_DURATION, 2),
  // Default hours to select in the timeline
  timeLineSizeHours: tryNumber(sliceup?.TIMELINE_SIZE_HOURS, 1),
  // Hours to pad either side of timeline
  timeLineSizePaddingHours: tryNumber(sliceup?.TIMELINE_SIZE_PADDING_HOURS, 0.25),
  // Update interval for live mode
  liveModeUpdateIntervalSeconds: tryNumber(sliceup?.LIVE_MODE_UPDATE_INTERVAL_SECONDS, 60),
  // Display text for clearance level banner
  clearanceLevel: tryString(sliceup?.CLEARANCE_LEVEL, 'UNCLASSIFIED'),
  // Background color for clearance level banner
  clearanceLevelColor: tryString(sliceup?.CLEARANCE_LEVEL_COLOR, '#009900'),
  // Toggles for features under active development, should always default to false
  features: {
    // Sensei chat feature -- defaults to false
    sensei: tryBoolean(sliceup?.SENSEI, false),
    // RBAC features like the admin control panel -- defaults to false
    rbac: tryBoolean(sliceup?.RBAC, false),
    // Incidents feature -- defaults to false
    incidents: tryBoolean(sliceup?.INCIDENTS, false),
  },
};
