import React from 'react';
import {AlwaysOnSampler, BatchSpanProcessor, WebTracerProvider} from '@opentelemetry/sdk-trace-web';
import {ZoneContextManager} from '@opentelemetry/context-zone';
import {XMLHttpRequestInstrumentation} from '@opentelemetry/instrumentation-xml-http-request';
import {registerInstrumentations} from '@opentelemetry/instrumentation';
import {Resource} from '@opentelemetry/resources';
import {OTLPTraceExporter} from '@opentelemetry/exporter-trace-otlp-http';
import {CompressionAlgorithm, OTLPExporterNodeConfigBase} from '@opentelemetry/otlp-exporter-base';
import {W3CTraceContextPropagator} from '@opentelemetry/core';
import config from '../utils/AppConfigurationService';

if (config.tracing.url) {
  const collectorOptions: OTLPExporterNodeConfigBase = {
    url: `${config.tracing.url}/v1/traces`,
    headers: {
      'Content-Type': 'application/json',
    },
    compression: 'gzip' as CompressionAlgorithm,
    concurrencyLimit: 10,
  };

  // Trace provider (Main application trace)
  const providerWithZone = new WebTracerProvider({
    resource: new Resource({
      'service.name': config.tracing.serviceName,
    }),
    sampler: new AlwaysOnSampler(),
  });

  const otlpTraceExporter = new OTLPTraceExporter(collectorOptions);

  const webApiUrlRegex = new RegExp(`${config.api.url}.*`, 'g');
  const oidcRegex = new RegExp(`${config.oidc.url}.*`, 'g');
  const fpApiUrlRegex = new RegExp(`${config.fpApi.url}.*`, 'g');
  const xmlHttpRequestInstrumentation = new XMLHttpRequestInstrumentation({
    enabled: true,
    ignoreUrls: [oidcRegex],
    propagateTraceHeaderCorsUrls: [webApiUrlRegex, fpApiUrlRegex],
  });

  providerWithZone.addSpanProcessor(new BatchSpanProcessor(otlpTraceExporter));

  providerWithZone.register({
    contextManager: new ZoneContextManager(),
    propagator: new W3CTraceContextPropagator(),
  });

  registerInstrumentations({
    instrumentations: [xmlHttpRequestInstrumentation],
  });
} else {
  console.warn('No tracing url set in the config! Tracing is disabled');
}

type TraceProviderProps = {
  children?: React.ReactNode;
};

export default function TraceProvider({children}: TraceProviderProps) {
  return <>{children}</>;
}
