import styled from 'styled-components';
import Stat from './Stat';
import {
  MtbfDeviceComponents,
  MtbfDeviceComponentsResponseResult,
  MtbfDeviceParent,
} from '../../../../../openapi-schema/schemaTS';
import {round, startCase} from 'lodash';
import {getColor} from '../../../HealthStatusUtils';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin: 40px 0 70px 0;
`;

const percent = (value: number | undefined) => {
  return `${round(value || 0, 2)}%`;
};

interface Props {
  deviceData: MtbfDeviceComponentsResponseResult | MtbfDeviceComponents | undefined;
}

export default function StatGroup({deviceData}: Props) {
  const parent = deviceData?.parent || ({} as MtbfDeviceParent);

  return (
    <Container>
      <Stat title="Vendor" value={parent?.device_vendor || ''} />
      <Stat title="Primary Device Model" value={parent?.device_model || ''} />
      <Stat title="IP Address" value={parent?.ip || ''} />
      <Stat title="Host Name" value={parent?.system_name || ''} />
      <Stat
        title="health"
        value={startCase(parent?.health_status || '')}
        color={getColor(parent.health_status)}
      />
      <Stat
        title="risk 1M / 3M / 1Y"
        value={[
          parent?.percent_failure_30_days,
          parent?.percent_failure_90_days,
          parent?.percent_failure_1_year,
        ]
          .map(percent)
          .join(' / ')}
      />
      <Stat title="impact" value={`${parent?.connections || 0} devices`} />
      <Stat title="Device Description" value={parent?.device_description || ''} />
    </Container>
  );
}
