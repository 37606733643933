import {ReactComponent as SliceUpLogo} from '../../Katana/brand/slice-up.svg';

export default function ({children}) {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-8 bg-dark-background">
      <SliceUpLogo className="h-12 w-auto text-white" />
      {children}
    </div>
  );
}
