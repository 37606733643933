import styled from 'styled-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useRecoilValue} from 'recoil';
import {selectedAnomalyIdState} from '../../../../atoms/selectedAnomaly';
import useNavigateKeepParams from '../../../../helpers/hooks/useNavigateKeepParams';

const Container = styled.button`
  background-color: rgba(67, 79, 203, 1);
  padding: 2px 0 2px 10px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border: none;
  border-radius: 1px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  gap: 2px;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  margin-left: auto;
`;

const Text = styled.p``;

export default function InspectAnomaly() {
  const selectedAnomaly = useRecoilValue(selectedAnomalyIdState);
  const navigateKeepParams = useNavigateKeepParams();

  const handleClick = () => {
    if (selectedAnomaly)
      navigateKeepParams({pathname: `/anomaly/${selectedAnomaly}`, clearStartEnd: true});
  };

  return (
    <Container onClick={handleClick} disabled={!selectedAnomaly}>
      <Text>Inspect Observation</Text>
      <ChevronRightIcon />
    </Container>
  );
}
