import Timestamp from 'components/common/Timestamp';
import ComponentTitle from 'components/common/ComponentTitle/ComponentTitle';
import {
  useSelectedAnomalyVTCPairsData,
  useSelectedAnomalyVTCPairsDataCount,
  useVTCAnomalyData,
} from 'data/VTC';
import {HotlErAnomalyVtc, VtcPairCall} from 'openapi-schema/schemaTS';
import {useCallback} from 'react';
import ObservationDetailTable from '../../common/ObservationDetailTable/ObservationDetailTable';
import VTCRoundedCell from './VTCRoundedCell';
import {useSelectedAnomaly} from '../../../../data/Anomalies';
import {CalleeMetrics, CallerMetrics, RouteLatency} from './TableMetrics';
import QueryLifecycleWrapper from 'components/common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {Container, ComponentTitleWrapper} from 'components/common/TableElements/TableElements';
import {Cell, createColumnHelper} from '@tanstack/react-table';

const initialSortBy = [
  {
    name: 'timestamp',
    desc: true,
  },
];

const columnHelper = createColumnHelper<VtcPairCall>();

export default function VTCAnomalyTable() {
  const selectedAnomalyQuery = useSelectedAnomaly();
  const {data: vtcData} = useVTCAnomalyData();

  const selectedAnomaly = selectedAnomalyQuery.data as HotlErAnomalyVtc | undefined;

  const cellAttributes = useCallback(
    (cell: Cell<VtcPairCall, unknown>) => {
      const subtype = selectedAnomaly?.explanatory_reasons?.subtype;
      const columnAndSubtypeAreSame =
        (cell.column.id === 'caller_mos' && subtype === 'caller') ||
        (cell.column.id === 'callee_mos' && subtype === 'callee');
      const isObserved = Boolean(cell.row.original.observed);

      return {
        highlight: columnAndSubtypeAreSame && isObserved,
      };
    },
    [selectedAnomaly]
  );

  const columns = [
    columnHelper.group({
      id: 'RouteLatency',
      header: () => <RouteLatency />,
      size: 3,
      columns: [
        columnHelper.accessor('timestamp', {
          header: 'Timestamp',
          size: 3,
          cell: props => <Timestamp timestamp={props.getValue()} />,
        }),
      ],
    }),
    columnHelper.group({
      id: 'callerMetrics',
      header: () => <CallerMetrics />,
      size: 4,
      columns: [
        columnHelper.accessor('caller_mos', {
          header: 'EMOS',
          size: 1,
          enableSorting: false,
          cell: props => <VTCRoundedCell value={props.getValue()} />,
        }),
        columnHelper.accessor('caller_latency_ms', {
          header: 'Latency (ms)',
          size: 1,
          enableSorting: false,
          cell: props => <VTCRoundedCell value={props.getValue()} />,
        }),
        columnHelper.accessor('caller_jitter_ms', {
          header: 'Jitter (ms)',
          size: 1,
          enableSorting: false,
          cell: props => <VTCRoundedCell value={props.getValue()} />,
        }),
        columnHelper.accessor('caller_pct_loss', {
          header: 'Packet Loss',
          size: 1,
          enableSorting: false,
          cell: props => <p>{props.getValue().toFixed(1)}%</p>,
        }),
      ],
    }),
    columnHelper.group({
      id: 'calleeMetrics',
      header: () => <CalleeMetrics />,
      size: 4,
      columns: [
        columnHelper.accessor('callee_mos', {
          header: 'EMOS',
          size: 1,
          enableSorting: false,
          cell: props => <VTCRoundedCell value={props.getValue()} />,
        }),
        columnHelper.accessor('callee_latency_ms', {
          header: 'Latency (ms)',
          size: 1,
          enableSorting: false,
          cell: props => <VTCRoundedCell value={props.getValue()} />,
        }),
        columnHelper.accessor('callee_jitter_ms', {
          header: 'Jitter (ms)',
          size: 1,
          enableSorting: false,
          cell: props => <VTCRoundedCell value={props.getValue()} />,
        }),
        columnHelper.accessor('callee_pct_loss', {
          header: 'Packet Loss',
          size: 1,
          enableSorting: false,
          cell: props => <p>{props.getValue().toFixed(1)}%</p>,
        }),
      ],
    }),
  ];

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="Video/VoIP Metrics" />
      </ComponentTitleWrapper>
      <QueryLifecycleWrapper
        query={useVTCAnomalyData()}
        wrapWithTableRow={false}
        isEmpty={vtcData === undefined}
        emptyMessage="No data found for this observation"
      >
        <ObservationDetailTable
          tableDataQuery={useSelectedAnomalyVTCPairsData}
          dataCountQuery={useSelectedAnomalyVTCPairsDataCount}
          columns={columns}
          initialSortBy={initialSortBy}
          cellAttributes={cellAttributes}
        />
      </QueryLifecycleWrapper>
    </Container>
  );
}
