import {Menu, MenuItem} from '@mui/material';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AutomateWindow from '../../../Log/AutomateWindow/AutomateWindow';
import {useRecoilState, useResetRecoilState, useSetRecoilState} from 'recoil';
import config from '../../../../../utils/AppConfigurationService';
import {logDetailViewState, LOGS, TEMPLATE} from '../../../../../atoms/LogDetailView';
import useSaveFeedback from '../../../../../helpers/hooks/useSaveFeedback';
import {HotlAnomaly} from '../../../../../openapi-schema/schemaTS';
import {DropdownContainer} from '../../../../common/Dropdown/Dropdown';
import {ARPopupOpenState} from '../../../../../atoms/ARPopupOpen';

const Container = styled.div`
  margin: 5px 0 0 auto;
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const IconWrapper = styled.div`
  display: grid;
  place-content: center;
  width: 16px;
  height: 16px;
`;

interface Props {
  selectedAnomaly: HotlAnomaly;
}

export default function OptionsDropdown({selectedAnomaly}: Props) {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const setARPopupOpen = useSetRecoilState(ARPopupOpenState);
  const [logDetailView, setLogDetailView] = useRecoilState(logDetailViewState);
  const resetLogDetailView = useResetRecoilState(logDetailViewState);
  const saveFeedback = useSaveFeedback();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markReadUnread = () => {
    saveFeedback(
      {anomaly_id: selectedAnomaly.anomaly_id, read: !selectedAnomaly?.feedback?.read},
      false
    );
    setAnchorEl(null);
  };

  const openAutomateWindow = () => {
    setARPopupOpen(true);
    setAnchorEl(null);
  };

  const toggleLogView = () => {
    if (logDetailView === TEMPLATE) {
      setLogDetailView(LOGS);
    } else {
      setLogDetailView(TEMPLATE);
    }
    setAnchorEl(null);
  };

  useEffect(() => () => resetLogDetailView(), [resetLogDetailView]);

  return (
    <Container>
      <DropdownContainer filled={true} large={true}>
        <Button onClick={handleClick}>
          Options
          <IconWrapper>
            <ArrowDropDownIcon sx={{fontSize: '18px'}} />
          </IconWrapper>
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disablePortal={true}
        >
          <MenuItem onClick={markReadUnread}>
            {selectedAnomaly?.feedback?.read ? 'Mark Unread' : 'Mark Read'}
          </MenuItem>
          {(selectedAnomaly?.anomaly_type === config.AnomalyTypes.LOG ||
            selectedAnomaly?.anomaly_type === config.AnomalyTypes.EXCHANGE_LOG) && (
            <MenuItem onClick={toggleLogView}>
              {logDetailView === TEMPLATE ? 'View Logs' : 'View Templates'}
            </MenuItem>
          )}
          {selectedAnomaly?.anomaly_type === config.AnomalyTypes.LOG && (
            <MenuItem onClick={openAutomateWindow}>Create Fingerprint...</MenuItem>
          )}
        </Menu>
      </DropdownContainer>
      <AutomateWindow />
    </Container>
  );
}
