import config from '../utils/AppConfigurationService';
import {
  FullWselUserFlagEvent,
  SetWselUserFlagEvent,
  SortField,
  WselFlaggedUser,
} from '../openapi-schema/schemaTS';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {timelineState} from '../atoms/dashboard';
import useDebouncedInput from '../helpers/hooks/useDebouncedInput';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  createSecurityFlaggedUser,
  getSecurityEventData,
  getSecurityEventDataCount,
  getSecurityEventLog,
  getSecurityFlaggedUsers,
  getSecurityFlaggedUsersCount,
} from '../utils/PythonApiUtils';
import {selectedDataCenterId} from '../atoms/selectedDatacenter';
import {
  securityFilterArraysState,
  securityTokenizedSearch,
  selectedSecurityAnomalyIdState,
} from '../atoms/securityState';
import {snackbarDataState} from '../atoms/snackbarData';
import {useCallback} from 'react';

export function useSecurityEventData(
  pageIndex = 0,
  limit = config.apiResultLimit,
  sort_fields: SortField[]
) {
  const DCId = useRecoilValue(selectedDataCenterId);
  const search_tokens = useRecoilValue(securityTokenizedSearch);
  const filters = useRecoilValue(securityFilterArraysState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const offset = pageIndex * limit;

  return useQuery(
    [
      'securityEventData',
      offset,
      limit,
      sort_fields,
      debouncedStart,
      debouncedEnd,
      search_tokens,
      filters,
    ],
    () =>
      getSecurityEventData({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: sort_fields,
        dc_id: DCId,
        priority_types: filters.priorityFilter,
        search_tokens: search_tokens,
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useSecurityEventDataCount() {
  const DCId = useRecoilValue(selectedDataCenterId);
  const search_tokens: string[] = useRecoilValue(securityTokenizedSearch);
  const filters = useRecoilValue(securityFilterArraysState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  return useQuery(
    ['securityEventDataCount', debouncedStart, debouncedEnd, search_tokens, filters],
    () =>
      getSecurityEventDataCount({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        dc_id: DCId,
        priority_types: filters.priorityFilter,
        search_tokens: search_tokens,
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useSecurityEventLog() {
  const anomalyID = useRecoilValue(selectedSecurityAnomalyIdState);
  return useQuery(
    ['securityEventLog', anomalyID],
    () =>
      getSecurityEventLog({
        anomaly_id: anomalyID,
      }),
    {
      // keepPreviousData: true,
      enabled: !!anomalyID,
    }
  );
}

export function useSecurityFlaggedUsers(
  pageIndex = 0,
  limit = config.apiResultLimit,
  sort_fields: SortField[]
) {
  const offset = pageIndex * limit;

  return useQuery(
    ['securityFlaggedUsers', offset, limit, sort_fields],
    () =>
      getSecurityFlaggedUsers({
        offset: offset,
        limit: limit,
        sort_fields: sort_fields,
      }),
    {
      keepPreviousData: true,
      refetchInterval: 5 * 60 * 1000,
    }
  );
}

export function useSecurityFlaggedUsersCount() {
  return useQuery(['securityEventFlaggedUsersCount'], () => getSecurityFlaggedUsersCount({}), {
    keepPreviousData: true,
    refetchInterval: 5 * 60 * 1000,
  });
}
interface MutationData {
  payload: SetWselUserFlagEvent;
  showSnackbar: boolean;
  index: number;
}

function useMutateFlaggedUser() {
  const queryClient = useQueryClient();
  const setSnackbarData = useSetRecoilState(snackbarDataState);

  return useMutation(
    (data: MutationData): Promise<any> => {
      return createSecurityFlaggedUser({...data.payload});
    },
    {
      onSuccess: (flagRecord: FullWselUserFlagEvent, variables: MutationData) => {
        queryClient.setQueriesData(
          'securityFlaggedUsers',
          (oldList: WselFlaggedUser[] | undefined): WselFlaggedUser[] => {
            if (!oldList) return [] as WselFlaggedUser[];
            // The query cache (oldList) is READ-only
            const updatedCache = [...oldList];

            if (updatedCache[variables.index].user_sid === flagRecord.user_sid) {
              updatedCache.splice(variables.index, 1);
            } else {
              console.warn('No user found with sid, taking no action', flagRecord.user_sid);
            }

            return updatedCache;
          }
        );
        if (variables.showSnackbar) {
          setSnackbarData({
            severity: 'success',
            message: 'Update Successful',
            open: true,
          });
        }
      },
      onError: error => {
        setSnackbarData({
          severity: 'error',
          message: 'Update Failed',
          open: true,
        });
      },
    }
  );
}

export const useSetUserFlag = () => {
  const {mutate} = useMutateFlaggedUser();

  return useCallback(
    (props: MutationData) => {
      mutate(props);
    },
    [mutate]
  );
};
