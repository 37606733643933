import React from 'react';
import styled from 'styled-components';
import ProgressBarStep, {ProgressBarElementProps} from './ProgressBarStep';
import CloseIcon from '@mui/icons-material/Close';
import {useRecoilValue} from 'recoil';
import {stepWizardCurrentPageState} from '../../../../atoms/StepWizardCurrentPage';
import {StepWizardPage} from '../StepWizard';

const Container = styled.div`
  background: rgba(32, 34, 38, 1);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 18px 68px 16px 10px;
  position: relative;
  min-height: 50px;
  font-size: 15px;
  line-height: 16px;
`;

const ProgressBarLine = styled.div<ProgressBarElementProps>`
  height: 2px;
  background: ${props =>
    props.pageNumber <= props.currentPage ? 'rgba(120, 83, 203, 0.4)' : 'rgba(56, 56, 56, 1)'};
  flex: 1;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

interface Props {
  pages: StepWizardPage[];
  onClose: () => void;
}

export default function ProgressBar({pages, onClose}: Props) {
  const stepWizardCurrentPage = useRecoilValue(stepWizardCurrentPageState);

  return (
    <Container>
      {pages.map((page: StepWizardPage, index: number) => (
        <React.Fragment key={page.stepLabel}>
          <ProgressBarStep title={page.stepLabel} pageNumber={index + 1} />
          {index !== pages.length - 1 && (
            <ProgressBarLine pageNumber={index + 1} currentPage={stepWizardCurrentPage} />
          )}
        </React.Fragment>
      ))}
      <IconContainer onClick={onClose}>
        <CloseIcon />
      </IconContainer>
    </Container>
  );
}
