import {timelineState} from 'atoms/dashboard';
import useDebouncedInput from 'helpers/hooks/useDebouncedInput';
import {SortField} from 'openapi-schema/schemaTS';
import {useQuery} from 'react-query';
import {useRecoilValue} from 'recoil';
import {
  getDeviceHealthOverview,
  getHardwareCatalogData,
  getHardwareCatalogDataCount,
  getHardwareCatalogDeviceDetailsData,
  getLogSignsData,
  getLogSignsDataCount,
  getSnmpData,
  getSnmpDataCount,
} from 'utils/PythonApiUtils';
import config from '../utils/AppConfigurationService';

export function useHardwareCatalogData(
  pageIndex = 0,
  limit = config.apiResultLimit,
  sort_fields: SortField[]
) {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const offset = pageIndex * limit;

  return useQuery(
    ['hardwareCatalogData', offset, limit, sort_fields, debouncedStart, debouncedEnd],
    () =>
      getHardwareCatalogData({
        start: start.minus({day: 7}).setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: sort_fields,
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useHardwareCatalogDataCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  return useQuery(
    ['hardwareCatalogDataCount', debouncedStart, debouncedEnd],
    () =>
      getHardwareCatalogDataCount({
        start: start.minus({day: 7}).setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useHardwareCatalogDeviceDetailsData(node_id = '') {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  return useQuery(
    ['hardwareCatalogDeviceDetailsData', node_id, debouncedStart, debouncedEnd],
    () =>
      getHardwareCatalogDeviceDetailsData({
        node_id: node_id,
        start: start.minus({day: 7}).setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useSnmpData(
  node_id = '',
  pageIndex = 0,
  limit = config.apiResultLimit,
  sort_fields: SortField[]
) {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const offset = pageIndex * limit;

  return useQuery(
    ['snmpData', node_id, offset, limit, sort_fields, debouncedStart, debouncedEnd],
    () =>
      getSnmpData({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        node_id: node_id,
        offset: offset,
        limit: limit,
        sort_fields: sort_fields,
      }),
    {
      keepPreviousData: true,
      enabled: node_id !== '',
    }
  );
}

export function useSnmpDataCount(node_id = '') {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['snmpDataCount', node_id, debouncedStart, debouncedEnd],
    () =>
      getSnmpDataCount({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        node_id: node_id,
      }),
    {
      keepPreviousData: true,
      enabled: node_id !== '',
    }
  );
}

export function useLogSignsData(
  node_id = '',
  pageIndex = 0,
  limit = config.apiResultLimit,
  sort_fields: SortField[]
) {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const offset = pageIndex * limit;

  return useQuery(
    ['logSignsData', node_id, offset, limit, sort_fields, debouncedStart, debouncedEnd],
    () =>
      getLogSignsData({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        node_id: node_id,
        offset: offset,
        limit: limit,
        sort_fields: sort_fields,
      }),
    {
      keepPreviousData: true,
      enabled: node_id !== '',
    }
  );
}

export function useLogSignsDataCount(node_id = '') {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['logSignsDataCount', node_id, debouncedStart, debouncedEnd],
    () =>
      getLogSignsDataCount({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        node_id: node_id,
      }),
    {
      keepPreviousData: true,
      enabled: node_id !== '',
    }
  );
}

export function useDeviceHealthOverviewData() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['deviceHealthOverviewData', debouncedStart, debouncedEnd], () =>
    getDeviceHealthOverview({
      start: start.minus({day: 7}).setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
}
