import {UserFeedbackServiceNowStatus} from 'openapi-schema/schemaTS';
import styled from 'styled-components';
import {Title, TitleRow, Container} from './InfoBoxStyles';

const Status = styled.div`
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
  padding: 2px 6px;
  border-radius: 1000px;
  background: rgba(80, 80, 80, 1);
`;

interface Props {
  serviceNowStatus: UserFeedbackServiceNowStatus | undefined;
}

export default function ServiceNowStatus({serviceNowStatus}: Props) {
  return (
    <Container>
      <TitleRow>
        <Title>SN Status</Title>
      </TitleRow>
      <Status>{serviceNowStatus || 'Pending'}</Status>
    </Container>
  );
}
