import {Sensei} from 'constants/imageLinks';
import Capabilities from './Capabilities';
import CYAStatements from './CYAStatements';

export default function SenseiGreeting() {
  return (
    <div className="flex h-full w-full max-w-[550px] flex-col items-center justify-between">
      <div className="flex flex-col items-center">
        <img src={Sensei} className="pb-8" alt="Sensei" />
        <p className="text-headline-small">Greetings, Ninja!</p>
        <p className="text-body-large text-gray-200">
          I am <b className="text-white">Sensei</b>, a conversational AI designed to enlighten and
          assist you.
        </p>
      </div>
      <Capabilities />
      <CYAStatements />
    </div>
  );
}
