// TODO: Reference the API schema enum levels once they are defined
export const getColor = (status: string) => {
  if (status === 'excellent') return 'rgba(82, 191, 163, 1)';
  if (status === 'good') return 'rgb(146, 191, 82)';
  if (status === 'fair') return 'rgba(243, 153, 70, 1)';
  if (status === 'poor') return 'rgb(243, 113, 70)';
  return 'rgba(205, 62, 56, 1)';
};
export const HealthStatusLevels = {
  excellent: 5,
  good: 4,
  fair: 3,
  poor: 2,
  replacement_candidate: 1,
};
