import {atom} from 'recoil';

export type SnackbarSeverity = 'default' | 'success' | 'error' | 'warning';

interface SnackbarData {
  severity: SnackbarSeverity;
  message: string;
  open: boolean;
  subtitle?: string;
  duration?: number;
  subtitleCallback?: () => void;
}

export const snackbarDataState = atom({
  key: 'snackbarData',
  default: {
    severity: 'default',
    message: '',
    open: false,
    subtitleCallback: undefined,
    subtitle: '',
    duration: undefined,
  } as SnackbarData,
});
