import {useSetRecoilState} from 'recoil';
import {
  ScriptVariableDisplayName,
  scriptVariableDisplayState,
  ScriptVariableDisplayValue,
} from '../../../../../atoms/scriptVariableDisplay';
import Dropdown from '../../../../common/Dropdown/Dropdown';

const dropdownOptions = [ScriptVariableDisplayName, ScriptVariableDisplayValue];

export default function ScriptVariableDisplayDropdown() {
  const setScriptVariableDisplay = useSetRecoilState(scriptVariableDisplayState);

  const handleChange = (value: string) => {
    setScriptVariableDisplay(value);
  };

  return (
    <Dropdown dropdownOptions={dropdownOptions} onChange={handleChange} title="Show" size="small" />
  );
}
