import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 100%;

  .lds-dual-ring {
    height: 80%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 2px solid white;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function MiniLoadingSpinner() {
  return (
    <Container>
      <div className="lds-dual-ring"></div>
    </Container>
  );
}
