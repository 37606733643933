import styled from 'styled-components';
import StatDropdown from './components/StatDropdown';
import MetricDropdown from './components/MetricDropdown';
import Sliders from './components/Sliders';

const Container = styled.div`
  width: 100%;
  padding: 12px 10px 10px 10px;
  background: rgba(34, 36, 41, 1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const DatacenterInfoWrapper = styled.div`
  display: flex;
  gap: 2px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  align-items: center;
`;

const WhatIfTitle = styled.p`
  background: rgba(53, 53, 53, 1);
  padding: 6px 10px;
`;

const DescriptionText = styled.p`
  display: flex;
  font-size: 13px;
  line-height: 16px;
  max-width: 800px;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
`;

export default function WhatIfControls() {
  return (
    <Container>
      <LeftContent>
        <DatacenterInfoWrapper>
          <WhatIfTitle>What If Calculator</WhatIfTitle>
        </DatacenterInfoWrapper>
        <DescriptionText>
          From here you can preview how certain adjustments will affect the topology. These
          predictions are estimates only and may not reflect actual performance under these
          conditions. Yellow highlighting indicates values exceed 5 percentage points less than the
          warning value.
        </DescriptionText>
      </LeftContent>
      <RightContent>
        <StatDropdown />
        <MetricDropdown />
        <Sliders />
      </RightContent>
    </Container>
  );
}
