import {useLogTemplatesISIS, useLogTemplatesISISCount} from '../../../../data/ISIS';
import AssociatedLogsTable from '../../common/AssociatedLogsTable/AssociatedLogsTable';

export default function AssociatedLogsISIS() {
  return (
    <AssociatedLogsTable
      logListQuery={useLogTemplatesISIS}
      logCountQuery={useLogTemplatesISISCount}
    />
  );
}
