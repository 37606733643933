import {useLogTemplatesLatency, useLogTemplatesLatencyCount} from '../../../../data/Latency';
import AssociatedLogsTable from '../../common/AssociatedLogsTable/AssociatedLogsTable';

export default function AssociatedLogsLatency() {
  return (
    <AssociatedLogsTable
      logListQuery={useLogTemplatesLatency}
      logCountQuery={useLogTemplatesLatencyCount}
    />
  );
}
