import {useRecoilValue} from 'recoil';
import {selectedReportingKPIstate} from '../../../atoms/selectedReportingKPI';
import TableBase from './components/TableBase';
import ComponentTitle from '../../common/ComponentTitle/ComponentTitle';
import {UseQueryResult} from 'react-query';
import {HotlAnomaly, IntValue} from '../../../openapi-schema/schemaTS';
import {Dispatch, SetStateAction, useMemo, useState} from 'react';
import Timestamp from '../../common/Timestamp';
import TypeData from '../../AnomalySelection/DetailedAnomalyTable/components/TypeData';
import PriorityData from '../../AnomalySelection/DetailedAnomalyTable/components/PriorityData';
import {convertSortToReactTableSchema} from '../../../utils/convertSortToReactTableSchema';
import {reportingDashboardSortingStateDefault} from '../../../atoms/reportingDashboardSorting';
import {pageSizeOptionsLarge} from '../../../constants/PageSizeOptions';
import {
  useReportingDashboardAnomaliesCount,
  useReportingDashboardAnomaliesList,
} from '../../../data/ReportingDashboard';
import {Container, ComponentTitleWrapper} from 'components/common/TableElements/TableElements';
import {ColumnDef, InitialTableState, createColumnHelper} from '@tanstack/react-table';

const columnHelper = createColumnHelper<HotlAnomaly>();

export interface ReportingDashboardTableProps {
  query: UseQueryResult<HotlAnomaly[], unknown>;
  columns: ColumnDef<HotlAnomaly, any>[];
  initialState: InitialTableState;
  anomalyCount: IntValue | undefined;
  pageSizeOptions: number[];
  APIPageSize: number;
  setAPIPageIndex: Dispatch<SetStateAction<number>>;
  setAPIPageSize: Dispatch<SetStateAction<number>>;
}

const pageSizeOptions = pageSizeOptionsLarge;

export default function ReportingDashboardMainTable() {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const query = useReportingDashboardAnomaliesList(APIPageIndex, APIPageSize);
  const {data: anomalyCount} = useReportingDashboardAnomaliesCount();
  const selectedReportingKPI = useRecoilValue(selectedReportingKPIstate);

  const columns = [
    columnHelper.accessor('min_timestamp', {
      header: 'Timestamp',
      size: 1,
      cell: props => <Timestamp timestamp={props.getValue()} />,
    }),
    columnHelper.accessor('anomaly_type', {
      header: 'Type',
      size: 1,
      cell: props => <TypeData type={props.getValue()} />,
    }),
    columnHelper.accessor('priority_classification', {
      header: 'Priority',
      size: 1,
      cell: props => <PriorityData priority={props.getValue()} />,
    }),
    columnHelper.accessor('category', {
      header: 'Category',
      size: 1,
      enableSorting: false,
    }),
    columnHelper.accessor('affected_resources', {
      header: 'Affected',
      size: 1,
      cell: props => <p>{props.getValue()[0]}</p>,
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(reportingDashboardSortingStateDefault),
      pagination: {pageSize: pageSizeOptions[0], pageIndex: 0},
    };
  }, []);

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title={selectedReportingKPI.title} />
      </ComponentTitleWrapper>
      <TableBase
        query={query}
        columns={columns}
        initialState={initialState}
        anomalyCount={anomalyCount}
        pageSizeOptions={pageSizeOptions}
        APIPageSize={APIPageSize}
        setAPIPageIndex={setAPIPageIndex}
        setAPIPageSize={setAPIPageSize}
      />
    </Container>
  );
}
