import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';

export function HealthTooltip() {
  return (
    <TitleTooltip
      header="Health"
      tooltipContent="The predicted health of this device 1 year out based on historical RMA data, device logs, and SNMP
    data."
      mainContent={<span>Health (1Y)</span>}
      maxWidth="250px"
    />
  );
}

interface Props {
  description: string;
  titleDuration: string;
}

export function RiskTooltip({description, titleDuration}: Props) {
  return (
    <TitleTooltip
      header="Risk"
      tooltipContent={`The chance of this device experiencing a component failure within ${description}.`}
      mainContent={<span>Risk ({titleDuration})</span>}
      maxWidth="250px"
    />
  );
}
