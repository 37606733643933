import {atom} from 'recoil';
import {WhatIfAVG} from '../constants/WhatIfStats';

export enum WhatIfStats {
  WhatIfAVG = 'Average',
  WhatIfMAX = 'Max',
  WhatIf1Sigma = '1 Sigma',
  WhatIf2Sigma = '2 Sigma',
}

export const selectedWhatIfStatState = atom({
  key: 'selectedWhatIfStat',
  default: WhatIfAVG as WhatIfStats,
});
