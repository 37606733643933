import {ChartData} from 'chart.js';
import {max} from 'lodash';
import {useMemo} from 'react';
import {UseQueryResult} from 'react-query';
import {useRecoilValue} from 'recoil';
import {timelineState} from '../../../../atoms/dashboard';
import {whatIfSliderValueState} from '../../../../atoms/whatIfSliderValue';
import {whatIfThresholdLevelState} from '../../../../atoms/whatIfThresholdLevel';
import {
  WhatIfChartBlue,
  WhatIfChartRed,
  WhatIfChartYellow,
} from '../../../../constants/WhatIfChartColors';
import WhatIfChart from '../WhatIfChart';
import {FormattedData} from './GetWhatIfChartData';

interface Props {
  query: UseQueryResult<any, unknown>;
  actualData: FormattedData[];
}
export default function ChartSetupDefault({query, actualData}: Props) {
  const whatIfSliderValue = useRecoilValue(whatIfSliderValueState);
  const whatIfThresholdLevel = useRecoilValue(whatIfThresholdLevelState);
  const {selectedInterval} = useRecoilValue(timelineState);

  const scalingFactor = whatIfSliderValue / 100;

  const whatIfData = useMemo(() => {
    return actualData.map(element => {
      return {
        x: element?.x,
        y: element?.y * (scalingFactor + 1),
      };
    });
  }, [actualData, scalingFactor]);

  const data: ChartData<'line'> = useMemo(() => {
    return {
      datasets: [
        {
          label: 'ACTUAL',
          data: actualData,
          borderColor: WhatIfChartBlue,
          backgroundColor: WhatIfChartBlue,
          yAxisID: 'y',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: WhatIfChartBlue,
          pointHoverBorderColor: 'white',
          borderJoinStyle: 'bevel',
        },
        {
          label: 'WHAT-IF',
          data: whatIfData,
          borderColor: WhatIfChartYellow,
          backgroundColor: WhatIfChartYellow,
          yAxisID: 'y',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: WhatIfChartYellow,
          pointHoverBorderColor: 'white',
          borderJoinStyle: 'bevel',
        },
      ],
    };
  }, [actualData, whatIfData]);

  const scaleSizes = {
    xmin: actualData.length === 1 ? selectedInterval.start.toMillis() : actualData[0]?.x,
    xmax:
      actualData.length === 1
        ? selectedInterval.end.toMillis()
        : actualData[actualData.length - 1]?.x,
    ymax: Number(
      ((max([max(whatIfData.map(d => d?.y)), whatIfThresholdLevel]) as number) * 1.1).toFixed()
    ),
  };

  const singlePoints =
    actualData.length === 1
      ? [
          {
            type: 'point',
            xValue: whatIfData[0].x,
            yValue: whatIfData[0]?.y,
            backgroundColor:
              whatIfData[0]?.y >= whatIfThresholdLevel ? WhatIfChartRed : WhatIfChartYellow,
            radius: 4,
            borderWidth: 0,
          },
          {
            type: 'point',
            xValue: actualData[0].x,
            yValue: actualData[0]?.y,
            backgroundColor:
              actualData[0]?.y >= whatIfThresholdLevel ? WhatIfChartRed : WhatIfChartBlue,
            radius: 4,
            borderWidth: 0,
          },
        ]
      : [];

  return (
    <WhatIfChart query={query} data={data} scaleSizes={scaleSizes} singlePoints={singlePoints} />
  );
}
