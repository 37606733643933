import styled from 'styled-components';
import Tooltip from '../../../Katana/components/Tooltip/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {LinkIcon} from 'components/Katana/Icons';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

interface Props {
  serviceNowLink: string;
}

export default function Ticket({serviceNowLink = ''}: Props) {
  if (!serviceNowLink) {
    return (
      <Container>
        <LinkIcon className="text-[18px] opacity-20" />
      </Container>
    );
  } else {
    return (
      <Tooltip
        tooltipContent={
          <Container>
            <OpenInNewIcon sx={{fontSize: '16px', margin: '1px 4px'}} />
            <p>{serviceNowLink}</p>
          </Container>
        }
        mainContent={
          <Container>
            <a
              href={
                /^https?:\/\/(.*)/.test(serviceNowLink)
                  ? serviceNowLink
                  : `https://${serviceNowLink}`
              }
              target="_blank"
              rel="noreferrer"
            >
              <LinkIcon className="text-[18px]" />
            </a>
          </Container>
        }
      />
    );
  }
}
