import {
  useSelectedAnomalyRelatedAnomaliesBGP,
  useSelectedAnomalyRelatedAnomaliesBGPCount,
} from '../../../../data/BGP';
import AssociatedAnomalies from '../../common/AssociatedAnomalies/AssociatedAnomalies';

export default function AssociatedAnomaliesBGP() {
  const anomalyListQuery = useSelectedAnomalyRelatedAnomaliesBGP;
  const anomalyCountQuery = useSelectedAnomalyRelatedAnomaliesBGPCount;

  return (
    <AssociatedAnomalies
      anomalyListQuery={anomalyListQuery}
      anomalyCountQuery={anomalyCountQuery}
    />
  );
}
