import AnomalyDetailKPI from '../../common/AnomalyDetailKPI/AnomalyDetailKPI';
import GoldenLayout from '../../../common/GoldenLayout/GoldenLayout';
import BGPNeighborDropAnomalyTable from '../BGPNeighborDropAnomalyTable/BGPNeighborDropAnomalyTable';
import TopologyDetailPage from '../../common/TopologyDetailPage/TopologyDetailPage';
import AssociatedAnomaliesBGP from '../AssociatedAnomaliesBGP/AssociatedAnomaliesBGP';
import AssociatedLogsBGP from '../AssociatedLogsBGP/AssociatedLogsBGP';

export default function BGPNeighborDropDetailDashboard() {
  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              type: 'column',
              width: 74,
              content: [
                {
                  component: () => <BGPNeighborDropAnomalyTable />,
                  height: 50,
                },
                {
                  component: () => <AssociatedLogsBGP />,
                  height: 50,
                },
              ],
            },
            {
              type: 'column',
              width: 26,
              content: [
                {
                  component: () => <AnomalyDetailKPI />,
                  height: 30,
                },
                {
                  component: () => <AssociatedAnomaliesBGP />,
                  height: 40,
                },
                {
                  component: () => <TopologyDetailPage />,
                  height: 30,
                },
              ],
            },
          ],
        },
      ]}
      padding="7px 10px 10px 10px"
    />
  );
}
