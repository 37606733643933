import styled from 'styled-components';
import {round} from 'lodash';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const Text = styled.p``;

interface Props {
  risk: number;
}

export default function Risk({risk}: Props) {
  return (
    <Container>
      <Text>{round(risk, 2)}%</Text>
    </Container>
  );
}
