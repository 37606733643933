import ComponentTitle from 'components/common/ComponentTitle/ComponentTitle';
import Dropdown from 'components/common/Dropdown/Dropdown';
import {binnedTimestampFormat} from 'constants/timeFormats';
import {useVTCAnomalyData, useVTCPingData, useVTCServicePerformanceChartData} from 'data/VTC';
import {DateTime} from 'luxon';
import {AggregatedVtcMetrics} from 'openapi-schema/schemaTS';
import {useMemo, useState} from 'react';
import styled from 'styled-components';
import PerformanceMetricsChart from '../common/PerformanceMetricsChartWrapper/components/PerformanceMetricsChart';

const Container = styled.div`
  height: 100%;
  background: black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Delay = 'Delay (ms)';
const Jitter = 'Jitter (ms)';
const PacketLoss = 'Packet Loss (%)';
const EMOS = 'EMOS';
const Ping = 'Ping/RTT (ms)';
const dropdownOptions = [Delay, Jitter, PacketLoss, EMOS, Ping];

const getCalleeProperty = (metric: string): keyof AggregatedVtcMetrics => {
  if (metric === Delay) return 'avg_callee_latency_ms';
  if (metric === Jitter) return 'avg_callee_jitter_ms';
  if (metric === PacketLoss) return 'avg_callee_pct_loss';
  return 'avg_callee_mos';
};

const getCallerProperty = (metric: string): keyof AggregatedVtcMetrics => {
  if (metric === Delay) return 'avg_caller_latency_ms';
  if (metric === Jitter) return 'avg_caller_jitter_ms';
  if (metric === PacketLoss) return 'avg_caller_pct_loss';
  return 'avg_caller_mos';
};

const getAxis = (metric: string) => {
  if (metric === Delay || metric === Jitter) return 'ms_left';
  if (metric === PacketLoss) return 'percentage_left';
  return 'left';
};

export default function ServicePerformanceChart() {
  const query = useVTCServicePerformanceChartData();
  const pingQuery = useVTCPingData();
  const {data: anomalyData} = useVTCAnomalyData();
  const [selectedMetric, setSelectedMetric] = useState(EMOS);
  const {data: APIdata} = query;
  const {data: PingData} = pingQuery;

  const formattedData = useMemo(() => {
    if (!APIdata?.length) {
      return {
        callee: [],
        caller: [],
      };
    }

    return {
      caller: APIdata.map(element => {
        return {
          x: DateTime.fromFormat(element.bin_key, binnedTimestampFormat, {
            zone: 'UTC',
          }).toMillis(),
          y: Number(element[getCallerProperty(selectedMetric)]),
        };
      }),
      callee: APIdata.map(element => {
        return {
          x: DateTime.fromFormat(element.bin_key, binnedTimestampFormat, {
            zone: 'UTC',
          }).toMillis(),
          y: Number(element[getCalleeProperty(selectedMetric)]),
        };
      }),
    };
  }, [selectedMetric, APIdata]);

  const formattedPingData = useMemo(() => {
    if (!PingData?.length) {
      return [];
    }

    return PingData.map(element => {
      return {
        x: DateTime.fromFormat(element.bin_key, binnedTimestampFormat, {
          zone: 'UTC',
        }).toMillis(),
        y: element.avg_ping_avg_ms,
      };
    });
  }, [PingData]);

  const data = useMemo(() => {
    return {
      datasets: [
        {
          data: formattedData.caller,
          label: anomalyData?.caller_host || 'Caller',
          borderColor: 'rgba(64, 187, 214, 1)',
          backgroundColor: 'rgba(64, 187, 214, 1)',
          yAxisID: getAxis(selectedMetric),
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: 'rgba(64, 187, 214, 1)',
          pointHoverBorderColor: 'white',
        },
        {
          data: formattedData.callee,
          label: anomalyData?.callee_host || 'Callee',
          borderColor: 'rgba(246, 179, 116, 1)',
          backgroundColor: 'rgba(246, 179, 116, 1)',
          yAxisID: getAxis(selectedMetric),
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: 'rgba(246, 179, 116, 1)',
          pointHoverBorderColor: 'white',
        },
      ],
    };
  }, [selectedMetric, formattedData, anomalyData]);

  const pingData = useMemo(() => {
    return {
      datasets: [
        {
          data: formattedPingData,
          label: 'Ping',
          borderColor: 'rgba(246, 179, 116, 1)',
          backgroundColor: 'rgba(246, 179, 116, 1)',
          yAxisID: 'ms_left',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: 'rgba(246, 179, 116, 1)',
          pointHoverBorderColor: 'white',
        },
      ],
    };
  }, [formattedPingData]);

  const chartMinMax = {
    min: formattedData.callee[0]?.x,
    max: formattedData.callee[formattedData.callee.length - 1]?.x,
  };

  const chartMinMaxPing = {
    min: formattedPingData[0]?.x,
    max: formattedPingData[formattedPingData.length - 1]?.x,
  };

  return (
    <Container>
      <HeaderWrapper>
        <ComponentTitle title="Service Performance" />
        <Dropdown
          dropdownOptions={dropdownOptions}
          selectedValue={selectedMetric}
          onChange={value => setSelectedMetric(value)}
        />
      </HeaderWrapper>
      {selectedMetric !== Ping ? (
        <PerformanceMetricsChart query={query} data={data} chartMinMax={chartMinMax} />
      ) : (
        <PerformanceMetricsChart query={pingQuery} data={pingData} chartMinMax={chartMinMaxPing} />
      )}
    </Container>
  );
}
