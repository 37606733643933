import IconComponent, {IconProps} from './IconComponent';

export default function ErrorIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 48}} className={className}>
      <svg
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="ErrorIcon"
      >
        <path d="M22 19H26V29H22V19Z" fill="#CD3E38" />
        <path d="M26 31H22V35H26V31Z" fill="#CD3E38" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.46881 42.0005C3.92815 42.0005 2.96603 40.3317 3.73796 38.9984L22.2691 6.99012C23.0394 5.65955 24.9604 5.65954 25.7308 6.99011L44.2619 38.9984C45.0338 40.3317 44.0717 42.0005 42.531 42.0005H5.46881ZM40.7966 39.0005L23.9999 9.98805L7.20326 39.0005H40.7966Z"
          fill="#CD3E38"
        />
      </svg>
    </IconComponent>
  );
}
