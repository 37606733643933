import FullSizeTableElement from '../../AnomalySelection/DetailedAnomalyTable/components/FullSizeTableElement';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import NoResults from '../NoResults/NoResults';
import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import React from 'react';
import {UseQueryResult} from 'react-query';

interface Props {
  children: JSX.Element | JSX.Element[];
  query: UseQueryResult;
  isEmpty: boolean;
  emptyMessage: string;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
  wrapWithTableRow?: boolean;
}

export default function QueryLifecycleWrapper({
  children,
  query,
  isEmpty,
  emptyMessage,
  actionButtonText,
  onActionButtonClick,
  wrapWithTableRow = true,
}: Props): JSX.Element {
  const {isLoading, isPreviousData, isError, refetch} = query;

  let finalComponent = <React.Fragment>{children}</React.Fragment>;

  if (isLoading || isPreviousData) {
    finalComponent = <LoadingSpinner />;
  } else if (isError) {
    finalComponent = <ErrorState retry={refetch} />;
  } else if (isEmpty) {
    finalComponent = (
      <NoResults
        message={emptyMessage}
        actionButtonText={actionButtonText}
        onActionButtonClick={onActionButtonClick}
      />
    );
  }

  if (wrapWithTableRow && (isLoading || isPreviousData || isError || isEmpty)) {
    finalComponent = <FullSizeTableElement>{finalComponent}</FullSizeTableElement>;
  }

  return finalComponent;
}
