import {
  useSelectedAnomalyRelatedAnomaliesVTC,
  useSelectedAnomalyRelatedAnomaliesVTCCount,
} from 'data/VTC';
import AssociatedAnomalies from '../common/AssociatedAnomalies/AssociatedAnomalies';

export default function AssociatedAnomaliesVTC() {
  const anomalyListQuery = useSelectedAnomalyRelatedAnomaliesVTC;
  const anomalyCountQuery = useSelectedAnomalyRelatedAnomaliesVTCCount;

  return (
    <AssociatedAnomalies
      anomalyListQuery={anomalyListQuery}
      anomalyCountQuery={anomalyCountQuery}
    />
  );
}
