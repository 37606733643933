import {useState} from 'react';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {selectedFingerprintState} from '../../../../atoms/selectedFingerprint';
import AccordionToggle from '../../../Katana/components/AccordionToggle/AccordionToggle';
import ScriptContent from '../../../AnomalyDetail/Log/AutomateWindow/components/ScriptContent';
import {
  Template,
  Header,
  TitleContainer,
  Title,
} from '../../../AnomalyDetail/Log/AutomateWindow/components/TemplateDisplay';
import FingerprintDetailsScriptVariable from './FingerprintDetailsScriptVariable';
import TemplateContent from './TemplateContent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 10px;
  padding: 0 10px;
`;

const ToggleWrapper = styled.div``;

const ContentContainer = styled.div`
  max-height: 260px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default function LogsAndScript() {
  const selectedFingerprint = useRecoilValue(selectedFingerprintState);
  const [activeAccordion, setActiveAccordion] = useState('');

  return (
    <Container>
      <ToggleWrapper>
        <AccordionToggle
          toggleText={`View Log Templates (${selectedFingerprint?.template_info?.template_ids?.length})`}
          openOnRender={activeAccordion === 'templates'}
          onToggle={() => setActiveAccordion('templates')}
        >
          <ContentContainer>
            {selectedFingerprint?.template_info?.template_ids?.map((template, index) => {
              return (
                <Template key={index} style={{background: 'inherit'}}>
                  <Header>
                    <TitleContainer>
                      <Title>{`Template:  ${template.template_id}`}</Title>
                      <Title>{`Version:  ${template.template_version}`}</Title>
                    </TitleContainer>
                  </Header>
                  <TemplateContent
                    template_id={String(template.template_id)}
                    template_version={Number(template.template_version)}
                    anomaly_id={String(selectedFingerprint.observation_id)}
                  />
                </Template>
              );
            })}
          </ContentContainer>
        </AccordionToggle>
      </ToggleWrapper>
      {selectedFingerprint?.ansible_script?.script_body && (
        <ToggleWrapper>
          <AccordionToggle
            toggleText="View Attached Script"
            openOnRender={activeAccordion === 'script'}
            onToggle={() => setActiveAccordion('script')}
          >
            <ContentContainer>
              <ScriptContent
                fileContent={selectedFingerprint?.ansible_script?.script_body}
                injectedComponent={FingerprintDetailsScriptVariable}
              />
            </ContentContainer>
          </AccordionToggle>
        </ToggleWrapper>
      )}
    </Container>
  );
}
