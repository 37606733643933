import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';
import React, {ReactElement} from 'react';

interface Props {
  tooltipContent: React.ReactNode;
  mainContent: ReactElement;
}

export default function RequiredFieldTooltip({tooltipContent, mainContent}: Props) {
  return (
    <TitleTooltip
      header="Required Fields"
      tooltipContent={tooltipContent}
      mainContent={mainContent}
    />
  );
}
