export const WhatIfUtilization = 'Utilization';
export const WhatIfCPU = 'CPU Usage';
export const WhatIfMemory = 'Memory Usage';
export const WhatIfTCAM = 'TCAM Usage';

export const NodeMetrics = {
  [WhatIfCPU]: true,
  [WhatIfMemory]: true,
  [WhatIfTCAM]: true,
};

export const InterfaceMetrics = {
  [WhatIfUtilization]: true,
};

export const WhatIfNodeMetricsKeys = {
  [WhatIfCPU]: 'cpu_usage_percent',
  [WhatIfMemory]: 'memory_usage_percent',
  [WhatIfTCAM]: 'TCAM_utilization',
};
