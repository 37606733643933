import {useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {selectedAnomalyIdState} from '../../atoms/selectedAnomaly';
import {useEffect} from 'react';

const useURLSelectedAnomaly = () => {
  const params = useParams();
  const [selectedAnomalyID, setSelectedAnomalyID] = useRecoilState(selectedAnomalyIdState);

  useEffect(() => {
    if (params.anomalyID !== selectedAnomalyID) {
      setSelectedAnomalyID(params.anomalyID || '');
    }
  }, [selectedAnomalyID, params.anomalyID, setSelectedAnomalyID]);
};

export default useURLSelectedAnomaly;
