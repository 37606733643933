import {ThemeProvider, createTheme} from '@mui/material';

const getTheme = (rootSelector: string) => {
  const rootElement = document.querySelector(rootSelector);

  const theme = createTheme({
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiDialog: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
  });

  return theme;
};

interface Props {
  children: JSX.Element;
  rootSelector?: string;
}

export default function MuiThemeProvider({children, rootSelector = '#root'}: Props) {
  return <ThemeProvider theme={getTheme(rootSelector)}>{children}</ThemeProvider>;
}
