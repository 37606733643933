import {associatedAnomaliesSortingState} from 'atoms/associatedAnomaliesSorting';
import {timelineState} from 'atoms/dashboard';
import {logTableSortingState} from 'atoms/logTableSorting';
import {selectedAnomalyIdState} from 'atoms/selectedAnomaly';
import {selectedWordCloudWordsState} from 'atoms/selectedWordCloudWords';
import useDebouncedInput from 'helpers/hooks/useDebouncedInput';
import {SortField} from 'openapi-schema/schemaTS';
import {useQuery} from 'react-query';
import {useRecoilValue} from 'recoil';
import {
  getExchangeLogsByAnomalyId,
  getExchangeLogsByAnomalyIdCount,
  getExchangeLogTemplatesByAnomalyId,
  getExchangeLogTemplatesByAnomalyIdCount,
  getExchangeLogWordcloudByTemplates,
  getRelatedAnomaliesExchangeLog,
  getRelatedAnomaliesExchangeLogCount,
} from 'utils/PythonApiUtils';
import config from '../utils/AppConfigurationService';
import {LogCountQuery, LogListQuery, LogTemplateCountQuery, LogTemplateListQuery} from './types';

export const useExchangeLogSelectedAnomalyTemplateList: LogTemplateListQuery = (
  pageIndex = 0,
  limit: number = config.apiResultLimit,
  sort_fields: SortField[] = []
) => {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const selectedWordCloudWords = useRecoilValue(selectedWordCloudWordsState);
  const offset = pageIndex * limit;

  return useQuery(
    [
      'exchangeLogSelectedAnomalyTemplateList',
      anomalyId,
      debouncedStart,
      debouncedEnd,
      selectedWordCloudWords,
      offset,
      sort_fields,
      limit,
    ],
    () =>
      getExchangeLogTemplatesByAnomalyId({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_id: anomalyId,
        words: selectedWordCloudWords,
        offset: offset,
        limit: limit,
        sort_fields: sort_fields,
      })
  );
};

export const useExchangeLogSelectedAnomalyTemplateListCount: LogTemplateCountQuery = () => {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const selectedWordCloudWords = useRecoilValue(selectedWordCloudWordsState);

  return useQuery(
    [
      'exchangeLogSelectedAnomalyTemplateListCount',
      anomalyId,
      debouncedStart,
      debouncedEnd,
      selectedWordCloudWords,
    ],
    () =>
      getExchangeLogTemplatesByAnomalyIdCount({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_id: anomalyId,
        words: selectedWordCloudWords,
      }),
    {
      keepPreviousData: true,
    }
  );
};

export const useExchangeLogSelectedAnomalyLogList: LogListQuery = (
  pageIndex = 0,
  limit = config.apiResultLimit,
  templateId = undefined,
  templateVersion = undefined
) => {
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const sortFields = useRecoilValue(logTableSortingState);
  const offset = pageIndex * limit;
  const selectedWordCloudWords = useRecoilValue(selectedWordCloudWordsState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'exchangeLogSelectedAnomalyLogList',
      templateId,
      templateVersion,
      debouncedStart,
      debouncedEnd,
      selectedAnomalyId,
      offset,
      limit,
      sortFields,
      selectedWordCloudWords,
    ],
    () =>
      getExchangeLogsByAnomalyId({
        anomaly_id: selectedAnomalyId,
        template_id: templateId,
        template_version: templateVersion,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: sortFields,
        words: selectedWordCloudWords,
      })
  );
};

export const useExchangeLogSelectedAnomalyLogListCount: LogCountQuery = (
  templateId = undefined,
  templateVersion = undefined
) => {
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedWordCloudWords = useRecoilValue(selectedWordCloudWordsState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'exchangeLogSelectedAnomalyLogListCount',
      templateId,
      templateVersion,
      debouncedStart,
      debouncedEnd,
      selectedAnomalyId,
      selectedWordCloudWords,
    ],
    () =>
      getExchangeLogsByAnomalyIdCount({
        anomaly_id: selectedAnomalyId,
        template_id: templateId,
        template_version: templateVersion,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        words: selectedWordCloudWords,
      }),
    {
      keepPreviousData: true,
    }
  );
};

export function useExchangeLogWordCloudData() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['exchangeLogWordCloudData', anomalyId, debouncedStart, debouncedEnd],
    () =>
      getExchangeLogWordcloudByTemplates({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_id: anomalyId,
      }),
    {
      enabled: !!anomalyId,
    }
  );
}

export function useSelectedAnomalyRelatedAnomaliesExchangeLog(
  pageIndex = 0,
  limit = config.apiResultLimit
) {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const associatedAnomaliesSorting = useRecoilValue(associatedAnomaliesSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'relatedAnomaliesExchangeLog',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      associatedAnomaliesSorting,
    ],
    () =>
      getRelatedAnomaliesExchangeLog({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: associatedAnomaliesSorting,
      })
  );
}
export function useSelectedAnomalyRelatedAnomaliesExchangeLogCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['relatedAnomaliesExchangeLogCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getRelatedAnomaliesExchangeLogCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}
