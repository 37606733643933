import {useQuery} from 'react-query';
import {useRecoilValue} from 'recoil';
import {selectedAnomalyIdState} from '../atoms/selectedAnomaly';
import {
  getDevicesPathChange,
  getLogTemplatesPathChange,
  getLogTemplatesPathChangeCount,
  getPathDelay,
  getRelatedAnomaliesPathChange,
  getRelatedAnomaliesPathChangeCount,
} from '../utils/PythonApiUtils';
import {timelineState} from '../atoms/dashboard';
import useDebouncedInput from '../helpers/hooks/useDebouncedInput';
import {getPathInformation} from '../utils/PythonApiUtils';
import config from '../utils/AppConfigurationService';
import {logTableSortingState} from '../atoms/logTableSorting';
import {associatedAnomaliesSortingState} from '../atoms/associatedAnomaliesSorting';
import {LogListQuery, SortedPagedQuery} from './types';

export function useDevicesPathChange() {
  const selectedAnomalyID = useRecoilValue(selectedAnomalyIdState);

  return useQuery(['devicesPathChange', selectedAnomalyID], () =>
    getDevicesPathChange({
      anomaly_id: selectedAnomalyID,
    })
  );
}

export const usePathInformation: SortedPagedQuery = () => {
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['pathInformation', debouncedStart, debouncedEnd, selectedAnomalyId], () =>
    getPathInformation({
      anomaly_id: selectedAnomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
};

export function usePathDelay() {
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);

  return useQuery(['pathDelay', selectedAnomalyId], () =>
    getPathDelay({
      anomaly_id: selectedAnomalyId,
    })
  );
}

export const useLogTemplatesPathChange: LogListQuery = (
  pageIndex = 0,
  limit = config.apiResultLimit
) => {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const sortFields = useRecoilValue(logTableSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'logTemplatesPathChange',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      sortFields,
    ],
    () =>
      getLogTemplatesPathChange({
        anomaly_id: selectedAnomalyId,
        offset: offset,
        limit: limit,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        sort_fields: sortFields,
      })
  );
};

export function useLogTemplatesPathChangeCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['logTemplatesPathChangeCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getLogTemplatesPathChangeCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useSelectedAnomalyRelatedAnomaliesPathChange(
  pageIndex = 0,
  limit = config.apiResultLimit
) {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const associatedAnomaliesSorting = useRecoilValue(associatedAnomaliesSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'relatedAnomaliesPathChange',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      associatedAnomaliesSorting,
    ],
    () =>
      getRelatedAnomaliesPathChange({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: associatedAnomaliesSorting,
      })
  );
}

export function useSelectedAnomalyRelatedAnomaliesPathChangeCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['relatedAnomaliesPathChangeCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getRelatedAnomaliesPathChangeCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}
