import {SnackbarSeverity} from '../../../../../atoms/snackbarData';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export const getSnackbarIcon = (severity: SnackbarSeverity | undefined) => {
  if (severity === 'success') return <CheckCircleIcon sx={{fontSize: '15px'}} />;
  if (severity === 'error') return <ErrorOutlineIcon sx={{fontSize: '15px'}} />;
  if (severity === 'warning') return <WarningRoundedIcon sx={{fontSize: '15px'}} />;
  return <InfoOutlinedIcon sx={{fontSize: '15px'}} />;
};
