import IconComponent, {IconProps} from '../IconComponent';

export default function StarredStatusIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="StarredStatusIcon"
      >
        <path
          d="M3.398 12.8333L4.34591 8.73542L1.16675 5.97917L5.36675 5.61458L7.00008 1.75L8.63342 5.61458L12.8334 5.97917L9.65425 8.73542L10.6022 12.8333L7.00008 10.6604L3.398 12.8333Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
