import {useEffect} from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import {useSelectedAnomaly} from '../../../data/Anomalies';
import useTopologyActions from '../../../helpers/hooks/useTopologyActions';
import TopologyLogAndUtilization from '../TopologyLogAndUtilization/TopologyLogAndUtilization';
import {useTopologyData} from 'data/Topology';

export default function LogUtilizationDetailPageTopology() {
  const {data: topologyElements, isLoading, isError, refetch} = useTopologyData();
  const {data: selectedAnomalyData} = useSelectedAnomaly();
  const {selectAnomaly} = useTopologyActions();

  useEffect(() => {
    if (!topologyElements.edgesBySource || !selectedAnomalyData) return;
    selectAnomaly({anomaly: selectedAnomalyData, detailsPage: true});
  }, [topologyElements, selectedAnomalyData, selectAnomaly]);

  if (isError) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return <TopologyLogAndUtilization isDetailsPage={true} />;
}
