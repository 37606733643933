import styled from 'styled-components';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {LinkIcon} from 'components/Katana/Icons';

const Container = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  height: 100%;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Link = styled.a`
  height: 100%;
  display: block;
`;

interface Props {
  ticket_id: string | undefined;
  url: string | undefined;
}

export default function Ticket({ticket_id, url}: Props) {
  if (!ticket_id) {
    return <Text style={{opacity: 0.5}}>None</Text>;
  }

  if (!url) {
    return <Text>{ticket_id}</Text>;
  }

  return (
    <Tooltip
      tooltipContent={
        <Container>
          <OpenInNewIcon sx={{fontSize: '16px', margin: '1px 4px'}} />
          <Text>{url}</Text>
        </Container>
      }
      mainContent={
        <Link
          href={/^https?:\/\/(.*)/.test(url) ? url : `https://${url}`}
          target="_blank"
          rel="noreferrer"
        >
          <Container>
            <LinkIcon className="text-[16px]" />
            <Text>{ticket_id}</Text>
          </Container>
        </Link>
      }
    />
  );
}
