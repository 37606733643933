import React from 'react';
import {atom} from 'recoil';

interface WarningModalData {
  modalSize: 'small' | 'large';
  headerText: string;
  warningMessage: React.ReactNode;
  cancelButtonText: string;
  confirmButtonText: string;
  confirmButtonCallback: () => void;
}

export const warningModalDataState = atom({
  key: 'warningModalDataState',
  default: {
    modalSize: 'small',
    headerText: 'Confirm Cancel',
    warningMessage: 'Are you sure you want to cancel?',
    cancelButtonText: 'Back',
    confirmButtonText: 'Confirm',
    confirmButtonCallback: () => {},
  } as WarningModalData,
});
