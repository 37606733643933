import {useState} from 'react';
import styled from 'styled-components';
import TimesliderRangeDialog from './TimesliderRangeDialog';
import {useRecoilValue} from 'recoil';
import {timelineRangeTextState} from '../../../../selectors/timelineRangeText';
import {selectedTimezoneState} from '../../../../atoms/selectedTimezone';
import {dateFull} from '../../../../constants/timeFormats';
import {rangeTimelineState} from '../../../../atoms/dashboard';
import {DateTime} from 'luxon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Container = styled.div`
  height: 100%;
  background: black;
  border-right: 1px solid rgba(51, 51, 51, 1);
  padding: 4px;
  color: white;
  position: relative;
`;

const CustomRangeButton = styled.div`
  background: rgba(34, 36, 41, 1);
  border: 1px solid rgba(58, 53, 66, 1);
  padding: 1px 4px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  flex-grow: 1;
  cursor: pointer;
  margin-bottom: 3px;

  &:hover {
    color: rgba(215, 153, 251, 1);
    background: rgba(50, 43, 62, 1);
    border: 1px solid rgba(215, 153, 251, 1);
  }
`;

const CustomRangeButtonText = styled.p``;

const IconWrapper = styled.div`
  display: grid;
  place-content: center;
`;

const StartEndWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const StartEnd = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;

const Date = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;

export default function RangeSelector() {
  const [rangePopupOpen, setRangePopupOpen] = useState(false);
  const timelineRangeText = useRecoilValue(timelineRangeTextState);
  const rangeTimeline = useRecoilValue(rangeTimelineState);
  const selectedTimezone = useRecoilValue(selectedTimezoneState);

  const formatTime = (time: DateTime) => `${time.setZone(selectedTimezone).toFormat(dateFull)}`;

  return (
    <Container>
      <CustomRangeButton onClick={() => setRangePopupOpen(true)}>
        <CustomRangeButtonText>{timelineRangeText}</CustomRangeButtonText>
        <IconWrapper data-testid="more icon">
          <ArrowDropDownIcon sx={{fontSize: '18px'}} />
        </IconWrapper>
      </CustomRangeButton>
      {rangePopupOpen && <TimesliderRangeDialog setRangePopupOpen={setRangePopupOpen} />}
      <StartEndWrapper>
        <StartEnd>Start Date</StartEnd>
        <Date data-testid="Start Date">{formatTime(rangeTimeline.selectedInterval.start)}</Date>
      </StartEndWrapper>
      <StartEndWrapper>
        <StartEnd>End Date</StartEnd>
        <Date>{formatTime(rangeTimeline.selectedInterval.end)}</Date>
      </StartEndWrapper>
    </Container>
  );
}
