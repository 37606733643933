import {Duration} from 'luxon';
import {useSelectedAnomalyBGPPeers, useSelectedAnomalyBGPPeersCount} from '../../../../data/BGP';
import {FlaggedNeighborDropBgpPeer} from '../../../../openapi-schema/schemaTS';
import {formatNeighborDropTime} from '../../../../utils/formatTime';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import ObservationDetailTable from '../../common/ObservationDetailTable/ObservationDetailTable';
import Tooltip from '../../../Katana/components/Tooltip/Tooltip';
import {Container, ComponentTitleWrapper} from 'components/common/TableElements/TableElements';
import {createColumnHelper} from '@tanstack/react-table';

const initialSortBy = [
  {
    name: 'peer_fsm_established_time_ms',
    desc: true,
  },
];

const columnHelper = createColumnHelper<FlaggedNeighborDropBgpPeer>();

export default function BGPNeighborDropAnomalyTable() {
  const columns = [
    columnHelper.accessor('peer_type', {
      header: 'Type',
      size: 1,
    }),
    columnHelper.accessor('peer_identifier', {
      header: 'BGP Identifier',
      size: 1,
    }),
    columnHelper.accessor('peer_state', {
      header: 'State',
      size: 1,
    }),
    columnHelper.accessor('peer_remote_as', {
      header: 'Remote AS',
      size: 1,
    }),
    columnHelper.accessor('peer_remote_addr', {
      header: 'Remote Address',
      size: 1,
    }),
    columnHelper.accessor('ip', {
      header: 'Local Address',
      size: 1,
    }),
    columnHelper.accessor('peer_fsm_established_time_ms', {
      header: () => (
        <Tooltip
          mainContent={<span>Established Time</span>}
          tooltipContent={
            <span>
              The duration of the latest connection established between the device and the specified
              peer during the timeframe selected via the timeline.
            </span>
          }
        />
      ),
      size: 1,
      cell: props => (
        <p>{formatNeighborDropTime(Duration.fromObject({milliseconds: props.getValue()}))}</p>
      ),
    }),
  ];

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="BGP Peers" />
      </ComponentTitleWrapper>
      <ObservationDetailTable
        tableDataQuery={useSelectedAnomalyBGPPeers}
        dataCountQuery={useSelectedAnomalyBGPPeersCount}
        columns={columns}
        initialSortBy={initialSortBy}
      />
    </Container>
  );
}
