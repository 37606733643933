import Tooltip from '../../../Katana/components/Tooltip/Tooltip';
import TooltipBox, {
  TooltipDataPair,
} from '../../../Katana/components/Tooltip/components/TooltipBox';
import styled from 'styled-components';
import {findNodeByHostnameOrIP} from 'utils/findNodeByHostnameOrIP';
import {useTopologyData} from 'data/Topology';
import {TopologyElements} from 'data/types';

const RECORD_DELIMITER = ', ';

const Container = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  affectedResources: string[];
  lookupName?: boolean;
  enhancedTooltip?: boolean;
}

interface AffectedResourceProps {
  affectedResources: string[];
  enhancedTooltip?: boolean;
  topologyElements: TopologyElements;
}

interface TopologyDetails {
  resourceName: string;
  deviceCategory: string;
  ip: string;
  status: string;
}

function AffectedResourceTooltipContent({
  affectedResources,
  enhancedTooltip = false,
  topologyElements,
}: AffectedResourceProps) {
  const tooltipEntries: TooltipDataPair[] = [];
  // At this point we only support enhanced details for a single affected resource.
  // In the future, we can display all details organized in the tooltip, or something else.
  if (enhancedTooltip && affectedResources.length === 1) {
    affectedResources.forEach(affectedResource => {
      const topologyDetails = findTopologyDetails(affectedResource, topologyElements);
      if (topologyDetails.deviceCategory && topologyDetails.status) {
        tooltipEntries.push(
          ...[
            {heading: 'Name', value: topologyDetails.resourceName},
            {heading: 'Device', value: topologyDetails.deviceCategory},
            {heading: 'IP', value: topologyDetails.ip},
            {heading: 'Status', value: topologyDetails.status},
          ]
        );
      }
    });
  }

  return (
    <>
      {tooltipEntries.length > 1 ? (
        <TooltipBox entries={tooltipEntries} />
      ) : (
        affectedResources.join(RECORD_DELIMITER)
      )}
    </>
  );
}

function findTopologyDetails(
  affectedResource: string,
  topologyElements: TopologyElements
): TopologyDetails {
  const topologyNode = findNodeByHostnameOrIP(affectedResource, topologyElements);

  return {
    resourceName: topologyNode?.data?.name ?? affectedResource,
    deviceCategory: topologyNode?.data?.device_category ?? '',
    ip: topologyNode?.data?.ip ?? '',
    status: topologyNode?.data?.status ?? '',
  };
}

/**
 * Generate the resource name based on the configuration.
 * @param lookupName The flag to indicate whether or not to lookup the name.
 * @param affectedResources The affected resources.
 * @param topologyElements The network topology elements to search
 * @returns string
 */
function generateResourceName(
  lookupName: boolean,
  affectedResources: string[],
  topologyElements: TopologyElements
): string {
  if (!lookupName) return affectedResources.join(RECORD_DELIMITER);
  const enhancedDetails: string[] = [];
  affectedResources.forEach(affectedResource => {
    const topologyDetails = findTopologyDetails(affectedResource, topologyElements);
    enhancedDetails.push(topologyDetails.resourceName);
  });

  return enhancedDetails.join(RECORD_DELIMITER);
}

export default function AffectedResourceWithTooltip({
  affectedResources,
  lookupName = false,
  enhancedTooltip = false,
}: Props) {
  const {data: topologyElements} = useTopologyData();
  const resourceName = generateResourceName(lookupName, affectedResources, topologyElements);

  return (
    <Tooltip
      tooltipContent={
        <AffectedResourceTooltipContent
          affectedResources={affectedResources}
          enhancedTooltip={enhancedTooltip}
          topologyElements={topologyElements}
        />
      }
      mainContent={<Container>{resourceName}</Container>}
    />
  );
}
