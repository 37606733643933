import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import config from '../../../utils/AppConfigurationService';
import {useRecoilValue} from 'recoil';
import {selectedAnomalyDataState, selectedAnomalyIdState} from '../../../atoms/selectedAnomaly';
import {AnomalyTypeBucket} from '../../../openapi-schema/schemaTS';
import styled from 'styled-components';
import ComponentTitle from '../../common/ComponentTitle/ComponentTitle';
import InspectAnomaly from './components/InspectAnomaly';
import TopologyWAN from '../../common/TopologyWAN/TopologyWAN';
import LoadingMessage from './components/LoadingMessage';
import TopologyLogAndUtilization from '../../common/TopologyLogAndUtilization/TopologyLogAndUtilization';
import TopologyNetworkRing from '../../common/TopologyNetworkRing/TopologyNetworkRing';
import TopologyPathChange from '../../common/TopologyPathChange/TopologyPathChange';
import NoResults from '../../common/NoResults/NoResults';
import NetworkRingLegend from '../../common/TopologyNetworkRing/components/NetworkRingLegend';
import PathLegend from '../../common/TopologyPathTrace/components/PathLegend';
import NodeSearch from '../NodeSearch/NodeSearch';
import TopologyVTC from 'components/common/TopologyVTC/TopologyVTC';
import PathChangeLegend from 'components/common/TopologyPathChange/PathChangeLegend';
import VTCLegend from 'components/common/TopologyVTC/VTCLegend';
import TopologyFiberChannel from 'components/common/TopologyFiberChannel/TopologyFiberChannel';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  position: relative;
`;

const GraphHeader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 2;
  padding: 10px;
  width: 100%;
  gap: 10px;
`;

const {
  LOG,
  INTERFACE,
  LATENCY,
  NEIGHBOR_DROP_BGP,
  NEIGHBOR_DROP_ISIS,
  PATH_CHANGE,
  VIDEO_VOIP,
  FIBER_CHANNEL,
  EXCHANGE_LOG,
} = config.AnomalyTypes;

const getGraph = (type: AnomalyTypeBucket | undefined) => {
  if (type === LOG || type === INTERFACE || type === EXCHANGE_LOG) {
    return <TopologyLogAndUtilization />;
  }
  if (type === NEIGHBOR_DROP_BGP || type === NEIGHBOR_DROP_ISIS) {
    return <TopologyWAN />;
  }
  if (type === LATENCY) return <TopologyNetworkRing />;
  if (type === PATH_CHANGE) return <TopologyPathChange />;
  if (type === VIDEO_VOIP) return <TopologyVTC />;
  if (type === FIBER_CHANNEL) return <TopologyFiberChannel />;
  return <ErrorState message="Unknown observation type" />;
};

const getTitle = (type: AnomalyTypeBucket | undefined) => {
  if (type === NEIGHBOR_DROP_BGP || type === NEIGHBOR_DROP_ISIS) {
    return 'WAN Topology';
  }
  if (type === LATENCY) return 'Network Ring';
  if (type === PATH_CHANGE) return 'Path Change Analysis';
  if (type === VIDEO_VOIP) return 'Video/VoIP Path Trace';
  return 'Topology';
};

const getOverlays = (type: AnomalyTypeBucket | undefined) => {
  if (type === NEIGHBOR_DROP_BGP || type === NEIGHBOR_DROP_ISIS || type === FIBER_CHANNEL) {
    return <LoadingMessage />;
  }
  if (type === LATENCY) {
    return <NetworkRingLegend />;
  }
  if (type === PATH_CHANGE) {
    return (
      <>
        <PathLegend />
        <PathChangeLegend />
      </>
    );
  }
  if (type === VIDEO_VOIP) {
    return (
      <>
        <PathLegend />
        <VTCLegend />
      </>
    );
  }
};

export default function GraphWrapper() {
  const selectedAnomalyID = useRecoilValue(selectedAnomalyIdState);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);
  const selectedAnomalyType = selectedAnomalyData?.anomaly_type;

  if (!selectedAnomalyID) {
    return (
      <NoResults
        header="Select an Observation"
        message={
          <>
            A visualization of the data will be shown here. For more details, click{' '}
            <b>Inspect Observation</b>
          </>
        }
      />
    );
  }

  return (
    <Container>
      <GraphHeader>
        <ComponentTitle title={getTitle(selectedAnomalyType)} />
        {(selectedAnomalyType === LOG ||
          selectedAnomalyType === INTERFACE ||
          selectedAnomalyType === NEIGHBOR_DROP_BGP ||
          selectedAnomalyType === NEIGHBOR_DROP_ISIS ||
          selectedAnomalyType === EXCHANGE_LOG) && <NodeSearch />}
        <InspectAnomaly />
      </GraphHeader>
      {getGraph(selectedAnomalyType)}
      {getOverlays(selectedAnomalyType)}
    </Container>
  );
}
