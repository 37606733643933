import styled from 'styled-components';

const Container = styled.span``;

const List = styled.ul`
  margin-left: 16px;
  list-style-type: disc;
`;

const ListItem = styled.li``;

export default function ScarcityTooltip() {
  return (
    <Container>
      The <b>Scarcity</b> level (0-3) measures the rarity of a log message both locally (from a
      specific device or host) and globally (across all devices).
      <List>
        <ListItem>
          Level 0 - <b>None</b>: The message is common both locally and globally.
        </ListItem>
        <ListItem>
          Level 1 - <b>Global</b>: The message is rare globally, but common to the device/host.
        </ListItem>
        <ListItem>
          Level 2 - <b>Local</b>: The message is rare locally, but common across devices/hosts.
        </ListItem>
        <ListItem>
          Level 3 - <b>Both</b>: This message is rare both for the device/host and across the
          network.
        </ListItem>
      </List>
    </Container>
  );
}
