import {atom} from 'recoil';

interface SelectedInterface {
  int_name: string;
  node_name: string;
}

export const selectedWhatIfInterfaceState = atom({
  key: 'selectedWhatIfInterface',
  default: null as SelectedInterface | null,
});
