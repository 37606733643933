import {useRecoilValue} from 'recoil';
import {timelineState} from '../atoms/dashboard';
import {
  getAnomaliesByDatacenterId,
  getAnomaliesCount,
  getAnomalyBinCountsFill,
  getOverviewKPIs,
} from '../utils/PythonApiUtils';
import {useQuery} from 'react-query';
import {selectedDataCenterId} from '../atoms/selectedDatacenter';
import config from '../utils/AppConfigurationService';
import {reportingDashboardSortingState} from '../atoms/reportingDashboardSorting';
import useDebouncedInput from '../helpers/hooks/useDebouncedInput';
import {ButtonDataFilter} from '../components/ReportingDashboard/ReportingDashboardSummary/components/SectionGroup';
import {selectedReportingKPIstate} from '../atoms/selectedReportingKPI';

export function useReportingDashboardSummaryCounts() {
  const DC_ID = useRecoilValue(selectedDataCenterId);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['reportingDashboardSummaryCounts', DC_ID, debouncedStart, debouncedEnd],
    () =>
      getOverviewKPIs({
        dc_id: DC_ID,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        classification_types: config.DefaultClasses,
        priority_types: config.DefaultPriorities,
        read: undefined,
        starred: undefined,
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useAnomalyCountFillReportingDashboard(filter = {} as ButtonDataFilter) {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const DC_ID = useRecoilValue(selectedDataCenterId);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['anomalyCountFillReportingDashboard', debouncedStart, debouncedEnd, filter],
    () => {
      return getAnomalyBinCountsFill({
        dc_id: DC_ID,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        total_bins: 100,
        classification_types: config.DefaultClasses,
        priority_types: config.DefaultPriorities,
        read: undefined,
        starred: undefined,
        ...filter,
      });
    }
  );
}

export function useReportingDashboardAnomaliesList(pageIndex = 0, limit = config.apiResultLimit) {
  const DCId = useRecoilValue(selectedDataCenterId);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const reportingDashboardSorting = useRecoilValue(reportingDashboardSortingState);
  const selectedReportingKPI = useRecoilValue(selectedReportingKPIstate);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'reportingDashboardAnomaliesList',
      DCId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      reportingDashboardSorting,
      selectedReportingKPI.filter,
    ],
    () =>
      getAnomaliesByDatacenterId({
        dc_id: DCId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: reportingDashboardSorting,
        classification_types: config.DefaultClasses,
        read: undefined,
        starred: undefined,
        priority_types: selectedReportingKPI.filter.priority_types || config.DefaultPriorities,
        anomaly_types: selectedReportingKPI.filter.anomaly_types || undefined,
      })
  );
}

export function useReportingDashboardAnomaliesCount() {
  const DCId = useRecoilValue(selectedDataCenterId);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const reportingDashboardSorting = useRecoilValue(reportingDashboardSortingState);
  const selectedReportingKPI = useRecoilValue(selectedReportingKPIstate);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'reportingDashboardAnomaliesCount',
      DCId,
      debouncedStart,
      debouncedEnd,
      reportingDashboardSorting,
      selectedReportingKPI.filter,
    ],
    () =>
      getAnomaliesCount({
        dc_id: DCId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        classification_types: config.DefaultClasses,
        read: undefined,
        starred: undefined,
        priority_types: selectedReportingKPI.filter.priority_types || config.DefaultPriorities,
        anomaly_types: selectedReportingKPI.filter.anomaly_types || undefined,
      })
  );
}
