import IconComponent, {IconProps} from '../IconComponent';

export default function NeighborDropIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="NeighborDropIcon"
      >
        <path
          d="M11.55 13.1833L10.0042 11.6667H3.79171C2.89726 11.6667 2.13893 11.3556 1.51671 10.7333C0.894485 10.1111 0.583374 9.35278 0.583374 8.45833C0.583374 7.70972 0.814374 7.04375 1.27637 6.46041C1.73799 5.87708 2.33337 5.50278 3.06254 5.3375C3.09171 5.25972 3.12087 5.18428 3.15004 5.11116C3.17921 5.03844 3.20837 4.95833 3.23754 4.87083L0.816707 2.45L1.63337 1.63333L12.3667 12.3667L11.55 13.1833ZM3.79171 10.5H8.83754L4.14171 5.80416C4.12226 5.91111 4.10768 6.01319 4.09796 6.11041C4.08824 6.20764 4.08337 6.30972 4.08337 6.41666H3.79171C3.22782 6.41666 2.74657 6.61597 2.34796 7.01458C1.94935 7.41319 1.75004 7.89444 1.75004 8.45833C1.75004 9.02222 1.94935 9.50347 2.34796 9.90208C2.74657 10.3007 3.22782 10.5 3.79171 10.5ZM12.6 10.9375L11.7542 10.1208C11.9195 9.98472 12.0435 9.82683 12.1264 9.64716C12.2088 9.46711 12.25 9.26528 12.25 9.04166C12.25 8.63333 12.1091 8.28819 11.8271 8.00625C11.5452 7.7243 11.2 7.58333 10.7917 7.58333H9.91671V6.41666C9.91671 5.60972 9.63243 4.92178 9.06387 4.35283C8.49493 3.78428 7.80698 3.5 7.00004 3.5C6.73754 3.5 6.48476 3.5315 6.24171 3.5945C5.99865 3.65789 5.76532 3.75764 5.54171 3.89375L4.69587 3.04791C5.03615 2.81458 5.3984 2.63705 5.78262 2.51533C6.16646 2.394 6.57226 2.33333 7.00004 2.33333C8.13754 2.33333 9.10237 2.72941 9.89454 3.52158C10.6871 4.31414 11.0834 5.27916 11.0834 6.41666C11.7542 6.49444 12.3109 6.78358 12.7535 7.28408C13.1956 7.78497 13.4167 8.37083 13.4167 9.04166C13.4167 9.42083 13.3438 9.77316 13.198 10.0987C13.0521 10.4246 12.8528 10.7042 12.6 10.9375Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
