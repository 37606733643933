import calculateTimelineInterval from '../../utils/calculateTimelineInterval';
import useSetTimelineStates from './useSetTimelineStates';
import {DateTime, Interval} from 'luxon';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {selectedTimezoneState} from '../../atoms/selectedTimezone';
import {inLiveModeState} from '../../atoms/inLiveMode';
import {useSearchParams} from 'react-router-dom';
import {rangeTimelineState, timelineState} from '../../atoms/dashboard';
import useNewUrlTime from './useNewUrlTime';
import config from '../../utils/AppConfigurationService';
import {AnomalyTypeBucket, HotlErAnomalyResponseResult} from '../../openapi-schema/schemaTS';
import {useGranularEffect} from 'granular-hooks';

const adjustTimeframe = (anomalyType: string, start: DateTime, end: DateTime) => {
  if (
    anomalyType === AnomalyTypeBucket.Latency ||
    anomalyType === AnomalyTypeBucket.Interface_Utilization ||
    anomalyType === AnomalyTypeBucket.Fiber_Channel ||
    anomalyType === AnomalyTypeBucket['Video/VoIP']
  ) {
    const startTime = start.minus({minutes: 45});
    const endTime = DateTime.fromMillis(
      Math.min(DateTime.now().toMillis(), end.plus({minutes: 15}).toMillis())
    );
    return [startTime, endTime];
  }
  if (start.equals(end)) {
    end = start.plus({minutes: 5});
  }
  return [start, end];
};

const useAnomalyDetailUrlTime = (data: HotlErAnomalyResponseResult | undefined) => {
  const setTimelineStates = useSetTimelineStates();
  const setInLiveMode = useSetRecoilState(inLiveModeState);
  const selectedTimezone = useRecoilValue(selectedTimezoneState);
  const [searchParams] = useSearchParams();
  const setTimeline = useSetRecoilState(timelineState);
  const setNewUrlTime = useNewUrlTime();
  const setRangeTimeline = useSetRecoilState(rangeTimelineState);

  const {min_timestamp, max_timestamp, anomaly_type} = data || {};
  useGranularEffect(
    () => {
      if (!data) return;
      let paramStart = searchParams.get('start') || '';
      let paramEnd = searchParams.get('end') || '';
      paramStart = paramStart.slice(0, 10) + paramStart.slice(10).replaceAll('-', ':');
      paramEnd = paramEnd.slice(0, 10) + paramEnd.slice(10).replaceAll('-', ':');

      const urlStartDT = DateTime.fromISO(paramStart, {zone: 'UTC'}).setZone(selectedTimezone);
      const urlEndDT = DateTime.fromISO(paramEnd, {zone: 'UTC'}).setZone(selectedTimezone);
      const obsStartDT = DateTime.fromISO(min_timestamp || '', {zone: 'UTC'}).setZone(
        selectedTimezone
      );
      const obsEndDT = DateTime.fromISO(max_timestamp || '', {zone: 'UTC'}).setZone(
        selectedTimezone
      );

      let startTime: DateTime;
      let endTime: DateTime;

      if (urlStartDT.isValid && urlEndDT.isValid && urlStartDT.toMillis() < urlEndDT.toMillis()) {
        startTime = urlStartDT;
        endTime = urlEndDT;
      } else if (obsStartDT.isValid && obsEndDT.isValid) {
        [startTime, endTime] = adjustTimeframe(anomaly_type || '', obsStartDT, obsEndDT);
      } else {
        startTime = config.defaultTimelineWindow.selectedInterval.start;
        endTime = config.defaultTimelineWindow.selectedInterval.end;
      }

      setNewUrlTime(startTime.toISO(), endTime.toISO());

      const newTimelineInterval = calculateTimelineInterval(startTime, endTime);
      const newTimeline = {
        selectedInterval: Interval.fromDateTimes(startTime, endTime),
        timelineInterval: newTimelineInterval,
      };
      setTimeline(newTimeline);
      setRangeTimeline(newTimeline);
      setTimelineStates(newTimeline);
      setInLiveMode(false);
    },
    [min_timestamp, max_timestamp, anomaly_type],
    [
      setInLiveMode,
      setNewUrlTime,
      setRangeTimeline,
      setTimeline,
      setTimelineStates,
      searchParams,
      selectedTimezone,
    ]
  );
};

export default useAnomalyDetailUrlTime;
