import styled from 'styled-components';
import ReportingDashboardSummary from './ReportingDashboardSummary/ReportingDashboardSummary';
import useURLSynchronizedTimeline from '../../helpers/hooks/useURLSynchronizedTimeline';
import AnomaliesCountLineChart from '../common/AnomaliesCountLineChart/AnomaliesCountLineChart';
import GoldenLayout from '../common/GoldenLayout/GoldenLayout';
import ReportingDashboardMainTable from './ReportingDashboardMainTable/ReportingDashboardMainTable';
import {withTracingErrorBoundary} from '../../tracing/errorReporting';

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const SummaryWrapper = styled.div`
  width: 320px;
  height: 100%;
`;

function ReportingDashboard() {
  useURLSynchronizedTimeline();

  return (
    <Container>
      <SummaryWrapper>
        <ReportingDashboardSummary />
      </SummaryWrapper>
      <GoldenLayout
        content={[
          {
            type: 'column',
            content: [
              {
                component: () => <AnomaliesCountLineChart />,
              },
              {
                component: () => <ReportingDashboardMainTable />,
              },
            ],
          },
        ]}
        width="calc(100% - 320px)"
      />
    </Container>
  );
}

export default withTracingErrorBoundary(ReportingDashboard);
