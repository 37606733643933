import IconComponent, {IconProps} from '../IconComponent';

export default function UnknownColorIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 20}} className={className}>
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="UnknownColorIcon"
      >
        <path
          d="M9.99984 1.66797C8.90549 1.66797 7.82186 1.88352 6.81081 2.30231C5.79976 2.7211 4.8811 3.33492 4.10728 4.10875C2.54448 5.67155 1.6665 7.79117 1.6665 10.0013C1.6665 12.2114 2.54448 14.3311 4.10728 15.8939C4.8811 16.6677 5.79976 17.2815 6.81081 17.7003C7.82186 18.1191 8.90549 18.3346 9.99984 18.3346C12.21 18.3346 14.3296 17.4567 15.8924 15.8939C17.4552 14.3311 18.3332 12.2114 18.3332 10.0013C18.3332 8.90695 18.1176 7.82332 17.6988 6.81227C17.28 5.80123 16.6662 4.88257 15.8924 4.10875C15.1186 3.33492 14.1999 2.7211 13.1889 2.30231C12.1778 1.88352 11.0942 1.66797 9.99984 1.66797Z"
          fill="#4B4C55"
        />
        <path
          d="M9.08325 15.5H10.9166V13.6667H9.08325V15.5ZM9.99992 4.5C9.02746 4.5 8.09483 4.88631 7.40719 5.57394C6.71956 6.26157 6.33325 7.19421 6.33325 8.16667H8.16659C8.16659 7.68044 8.35974 7.21412 8.70356 6.8703C9.04737 6.52649 9.51369 6.33333 9.99992 6.33333C10.4861 6.33333 10.9525 6.52649 11.2963 6.8703C11.6401 7.21412 11.8333 7.68044 11.8333 8.16667C11.8333 10 9.08325 9.77083 9.08325 12.75H10.9166C10.9166 10.6875 13.6666 10.4583 13.6666 8.16667C13.6666 7.19421 13.2803 6.26157 12.5926 5.57394C11.905 4.88631 10.9724 4.5 9.99992 4.5Z"
          fill="white"
        />
      </svg>
    </IconComponent>
  );
}
