import styled from 'styled-components';
import {Dialog} from '@mui/material';
import {CLEARANCE_BANNER_HEIGHT} from '../ClearanceBanner/ClearanceBanner';

export const BannerAwareDialog = styled(Dialog)`
  .MuiDialog-paper {
    overflow: hidden;
    border-radius: 0;
    margin: 0;
    width: 90vw;
    height: 80vh;
    background: none;
    justify-content: center;
    align-items: center;
  }

  // Prevents the clearance banner from being dimmed by the dialog backdrop
  .MuiBackdrop-root {
    top: ${CLEARANCE_BANNER_HEIGHT};
  }
`;
