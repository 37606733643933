import {
  defaultsnmpMetricsTableSortingState,
  snmpMetricsTableSortingState,
} from 'atoms/snmpMetricsTableSorting';
import {hardwareCatalogSelectedDeviceState} from 'atoms/hardwareCatalogSelectedDevice';
import Pagination from 'components/common/Pagination/Pagination';
import QueryLifecycleWrapper from 'components/common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {
  Container,
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TableData,
  TableRow,
} from 'components/common/TableElements/TableElements';
import TableSortArrow from 'components/common/TableSortArrow/TableSortArrow';
import {pageSizeOptionsLarge} from 'constants/PageSizeOptions';
import {useSnmpData, useSnmpDataCount} from 'data/HardwareMonitoring';
import {isEmpty} from 'lodash';
import {NodeHardwareMetrics} from 'openapi-schema/schemaTS';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {convertSortToApiSchema} from 'utils/convertSortToApiSchema';
import {convertSortToReactTableSchema} from 'utils/convertSortToReactTableSchema';
import {scrollToTop} from 'utils/scrollToTop';
import FanAndPowerCell from './FanAndPowerCell';
import {DeviceTooltip, FanTooltip, PowerTooltip, TemperatureTooltip} from './HeaderTooltips';
import TemperatureCell from './TemperatureCell';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const temperatureSort = 'temperature.temperature_status_value';
const fanSort = 'fan.fan_status_state';
const powerSort = 'power_supply.power_supply_status_state';
const temperatureId = 'Temperature';
const fanId = 'Fan';
const powerId = 'Power';

const pageSizeOptions = pageSizeOptionsLarge;

const columnHelper = createColumnHelper<NodeHardwareMetrics>();

export default function SnmpTable() {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const [sortState, setSortState] = useRecoilState(snmpMetricsTableSortingState);
  const selectedDeviceId = useRecoilValue(hardwareCatalogSelectedDeviceState);
  const snmpDataQuery = useSnmpData(selectedDeviceId, APIPageIndex, APIPageSize, sortState);
  const {data: APIdata} = snmpDataQuery;
  const {data: deviceCount} = useSnmpDataCount(selectedDeviceId);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const data = useMemo(() => (APIdata ? [...APIdata] : []), [APIdata]);

  const columns = [
    columnHelper.accessor('node_name', {
      header: () => <DeviceTooltip />,
      size: 2,
    }),
    columnHelper.accessor(row => row.temperature?.temperature_status_value, {
      header: () => <TemperatureTooltip />,
      size: 1,
      id: temperatureId,
      cell: props => <TemperatureCell temperature={props.getValue()} />,
    }),
    columnHelper.accessor(row => row.fan?.fan_status_state, {
      header: () => <FanTooltip />,
      size: 1,
      id: fanId,
      cell: props => <FanAndPowerCell value={props.getValue()} />,
    }),
    columnHelper.accessor(row => row.power_supply?.power_supply_status_state, {
      header: () => <PowerTooltip />,
      size: 1,
      id: powerId,
      cell: props => <FanAndPowerCell value={props.getValue()} />,
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(defaultsnmpMetricsTableSortingState),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
    };
  }, []);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    pageCount: Math.ceil((deviceCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableHiding: true,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  useEffect(() => {
    // updates pagination values for query
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
  }, [pageIndex, pageSize]);

  useEffect(() => {
    // formats sorting object for query
    const replacements = {[temperatureId]: temperatureSort, [fanId]: fanSort, [powerId]: powerSort};
    const newSortBy = convertSortToApiSchema(sorting, replacements);
    setSortState(newSortBy);
  }, [setSortState, sorting]);

  useEffect(() => {
    // resets table when any change causes query to run
    setPageIndex(0);
  }, [sorting, pageSize, setPageIndex, selectedDeviceId]);

  return (
    <Container>
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={snmpDataQuery}
            isEmpty={isEmpty(data)}
            emptyMessage={
              isEmpty(selectedDeviceId) ? 'Select a device to get started' : 'No results found'
            }
          >
            {getRowModel().rows.map(row => (
              <TableRow key={row.id} noSelect>
                {row.getVisibleCells().map(cell => (
                  <TableData key={cell.id} style={{...getCellStyles(cell)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </Container>
  );
}
