import {
  Container,
  TitleRow,
  Title,
  ValueWrapper,
  DropdownText,
  BadgeText,
  DropdownTitle,
} from './InfoBoxStyles';
import {Select, MenuItem} from '@mui/material';
import useSaveFeedback from '../../../../../helpers/hooks/useSaveFeedback';
import {useState} from 'react';
import config from '../../../../../utils/AppConfigurationService';
import {AnomalyCategoryBucket} from '../../../../../openapi-schema/schemaTS';

const dropdownOptions = Object.values(config.AnomalyCategoryTypes);

interface Props {
  initialCategory: AnomalyCategoryBucket;
  anomalyId: string;
}

export default function Category({initialCategory, anomalyId}: Props) {
  const [category, setCategory] = useState(initialCategory);
  const saveFeedback = useSaveFeedback();

  return (
    <Container>
      <TitleRow>
        <Title>Category</Title>
      </TitleRow>
      <Select
        MenuProps={{disablePortal: true}}
        onChange={e => {
          const newCategory = e.target.value as AnomalyCategoryBucket;
          setCategory(newCategory);
          saveFeedback({anomaly_id: anomalyId, category: newCategory}, false);
        }}
        value={category}
        renderValue={() => (
          <ValueWrapper style={{marginTop: '2px'}}>
            <BadgeText>{category}</BadgeText>
          </ValueWrapper>
        )}
      >
        <DropdownTitle>category</DropdownTitle>
        {dropdownOptions.map(option => (
          <MenuItem key={option} value={option as AnomalyCategoryBucket}>
            <DropdownText>{option}</DropdownText>
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
}
