import styled from 'styled-components';
import SnmpTable from './SnmpTable/SnmpTable';
import {ComponentTitleWrapper} from '../../common/TableElements/TableElements';
import ComponentTitle from '../../common/ComponentTitle/ComponentTitle';
import {useCallback, useState} from 'react';
import LogSignsTable from './LogsTable/LogSignsTable';

const Container = styled.div`
  background: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default function LogsSnmpTable() {
  const [selectedView, setSelectedView] = useState('logs');

  const clickHandler = useCallback(
    (tab: string) => {
      setSelectedView(tab);
    },
    [setSelectedView]
  );

  const getBackground = tab => {
    return selectedView === tab ? 'light' : 'dark';
  };
  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle
          title="Logs"
          background={getBackground('logs')}
          onClick={() => clickHandler('logs')}
        />
        <ComponentTitle
          title="SNMP Metrics"
          background={getBackground('snmp')}
          onClick={() => clickHandler('snmp')}
        />
      </ComponentTitleWrapper>
      {selectedView === 'logs' ? <LogSignsTable /> : <SnmpTable />}
    </Container>
  );
}
