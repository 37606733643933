import {useState} from 'react';
import useSaveFeedback from '../../../../../helpers/hooks/useSaveFeedback';
import TextInputField from '../../../../Katana/components/TextInputField/TextInputField';

interface Props {
  initialServiceNowLink: string;
  anomalyId: string;
}

export default function SerivceNowTicket({initialServiceNowLink, anomalyId}: Props) {
  const [ticketNumber, setTicketNumber] = useState(initialServiceNowLink);
  const saveFeedback = useSaveFeedback();

  const submitForm = (input: string) => {
    setTicketNumber(input);
    if (input !== ticketNumber) {
      saveFeedback({anomaly_id: anomalyId, service_now_link: input}, true);
    }
  };

  return (
    <TextInputField
      inputValue={ticketNumber}
      onSubmit={submitForm}
      label="SN Ticket"
      maxWidth={400}
    />
  );
}
