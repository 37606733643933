import styled from 'styled-components';
import {isEmpty} from 'lodash';
import {useEffect, useMemo} from 'react';
import {useRecoilState, useResetRecoilState} from 'recoil';
import {selectedFingerprintState} from '../../../atoms/selectedFingerprint';
import {useFingerprintsList} from '../../../data/AutoRemediation';
import {Fingerprint} from '../../../openapi-schema/fingerprintSchemaTS';
import ComponentTitle from '../../common/ComponentTitle/ComponentTitle';
import QueryLifecycleWrapper from '../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import TableSortArrow from '../../common/TableSortArrow/TableSortArrow';
import AutomationLevel from './components/AutomationLevel';
import {
  Container,
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TableRow,
  TableData,
  ComponentTitleWrapper,
  TextAlign,
} from '../../common/TableElements/TableElements';
import {
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const Footer = styled.div`
  background: rgba(24, 24, 24, 1);
  padding: 12px 10px 11px 10px;
`;

const FooterText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

type MetaType = {
  textAlign: TextAlign;
};
const columnHelper = createColumnHelper<Fingerprint>();

export default function FingerprintTable() {
  const query = useFingerprintsList();
  const [selectedFingerprint, setSelectedFingerprint] = useRecoilState(selectedFingerprintState);
  const resetSelectedFingerprint = useResetRecoilState(selectedFingerprintState);
  const {data: APIdata} = query;

  const data = useMemo(() => (APIdata?.fingerprints ? [...APIdata.fingerprints] : []), [APIdata]);

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      size: 2.5,
    }),
    columnHelper.accessor('description', {
      header: 'Description',
      size: 4.5,
    }),
    columnHelper.accessor(row => row.automation_case?.fingerprint_match_count, {
      header: 'Matches',
      size: 1,
      cell: props => <p>{props.getValue()?.toLocaleString() || 0}</p>,
      meta: {
        textAlign: 'right',
      },
    }),
    columnHelper.accessor(row => row.automation_case?.tickets_created, {
      header: 'Tickets',
      size: 1,
      cell: props => <p>{props.getValue()?.toLocaleString() || 0}</p>,
      meta: {
        textAlign: 'right',
      },
    }),
    columnHelper.accessor(row => row.automation_case?.script_execution_count, {
      header: 'Executed',
      size: 1,
      cell: props => <p>{props.getValue()?.toLocaleString() || 0}</p>,
      meta: {
        textAlign: 'right',
      },
    }),
    columnHelper.accessor(row => row.automation_case?.script_error_count, {
      header: 'Errors',
      size: 1,
      cell: props => <p>{props.getValue()?.toLocaleString() || 0}</p>,
      meta: {
        textAlign: 'right',
      },
    }),
    columnHelper.accessor('classification', {
      header: 'Automation',
      size: 1.5,
      cell: props => <AutomationLevel level={props.getValue()} />,
      meta: {
        textAlign: 'center',
      },
    }),
  ];

  const initialState = useMemo(() => {
    const selectedRowIds = data.reduce((acc, curr, index) => {
      return {...acc, [index]: selectedFingerprint?.fingerprint_id === curr.fingerprint_id};
    }, {}) as {[key: number]: boolean};

    return {
      rowSelection: selectedRowIds,
    };
  }, [data, selectedFingerprint?.fingerprint_id]);

  const {getHeaderGroups, getRowModel} = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    initialState: initialState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: false,
    enableMultiRowSelection: false,
  });

  const handleRowClick = (row: Row<Fingerprint>) => {
    if (row.getIsSelected()) {
      resetSelectedFingerprint();
    } else {
      setSelectedFingerprint(row.original);
    }
    row.toggleSelected(!row.getIsSelected());
  };

  useEffect(() => () => resetSelectedFingerprint(), [resetSelectedFingerprint]);

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="Automated Cases" />
      </ComponentTitleWrapper>
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  textAlign={(header.column.columnDef.meta as MetaType)?.textAlign}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody>
          <QueryLifecycleWrapper
            query={query}
            isEmpty={isEmpty(data)}
            emptyMessage="No Fingerprints found."
          >
            {getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                onClick={() => handleRowClick(row)}
                isSelected={row.getIsSelected()}
                isDisabled={row.original.enabled === 'N'}
              >
                {row.getVisibleCells().map(cell => (
                  <TableData key={cell.id} style={{...getCellStyles(cell)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Footer>
        <FooterText>{data.length} Automated Cases</FooterText>
      </Footer>
    </Container>
  );
}
