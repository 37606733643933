import {EventObjectEdge} from 'cytoscape';
import {TopologyEdgeData} from '../../../data/types';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';
import {
  Container,
  GroupWrapper,
  Row,
  Header,
  Text,
  HiddenElement,
  Line,
} from './TopologyTooltipElements';

interface Props {
  event: EventObjectEdge;
}

export default function SimpleNetworkInterfaceTooltip({event}: Props) {
  const edge = event.target.data() as TopologyEdgeData;

  return (
    <Tooltip
      tooltipContent={
        <Container>
          <Row>
            <Text>Status:</Text>
            <Text>{edge.status}</Text>
          </Row>
          <Line />
          <GroupWrapper>
            <Row>
              <Header>{edge.source_node_name}:</Header>
              <Header>{edge.source_int_name}</Header>
            </Row>
            <Row>
              <Header>{edge.target_node_name}:</Header>
              <Header>{edge.target_int_name}</Header>
            </Row>
          </GroupWrapper>
        </Container>
      }
      mainContent={
        <HiddenElement x={event.originalEvent.offsetX} y={event.originalEvent.offsetY} />
      }
      onCanvas={true}
      maxWidth={'none'}
    />
  );
}
