import {
  LifecycleStatus,
  senseiChatActionsState,
  senseiChatSessionState,
  senseiLifecycleState,
} from 'atoms/sensei';
import {SenseiQuestionRequest} from 'openapi-schema/senseiSchemaTS';
import {useCallback, useEffect, useRef} from 'react';
import {useAuth} from 'react-oidc-context';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {isEqual} from 'lodash';

interface WebSocketConnectionDetails {
  keepAliveIntervalMS?: number;
  maxReconnectAttempts?: number;
  reconnectDelayMS?: number;
}

export const useSenseiWebSocket = ({
  keepAliveIntervalMS = 5000,
  maxReconnectAttempts = 100,
  reconnectDelayMS = 2000,
}: WebSocketConnectionDetails) => {
  // TODO: if multiple sensei chat windows are needed, refactor this to create instances of a custom
  //  connection object, each with a separate history, lifecycle, etc
  const setSenseiLifecycle = useSetRecoilState(senseiLifecycleState);
  const setSenseiChatActions = useSetRecoilState(senseiChatActionsState);
  const [senseiChatMessages, setSenseiChatMessages] = useRecoilState(senseiChatSessionState);
  const senseiChatMessagesRef = useRef(senseiChatMessages);
  const auth = useAuth();
  const authRef = useRef(auth);
  const websocket = useRef<WebSocket>();
  const requestId = useRef(0);
  const reconnectAttempts = useRef<number>(0);

  const sendRawMessage = (message: string) => {
    // Only send if the socket is opened.
    if (websocket.current && websocket.current?.readyState === WebSocket.OPEN) {
      websocket.current.send(message);
    }
  };

  const createWebSocket = useCallback(() => {
    setSenseiLifecycle(LifecycleStatus.isLoading);
    websocket.current = new WebSocket(window.sliceup.API_WS_URL);

    websocket.current.onopen = () => {
      setSenseiLifecycle(LifecycleStatus.isSuccess);
    };

    websocket.current.onclose = event => {
      setSenseiLifecycle(LifecycleStatus.isError);
      setTimeout(() => {
        if (reconnectAttempts.current > maxReconnectAttempts) {
          console.error(`Exceeded the maximum reconnection attempts of: ${maxReconnectAttempts}.`);
          return;
        }
        console.warn(
          `On reconnect attempt ${reconnectAttempts.current}/${maxReconnectAttempts}...`
        );
        createWebSocket();
        reconnectAttempts.current = reconnectAttempts.current + 1;
      }, reconnectDelayMS);
    };

    websocket.current.onerror = event => {
      // Force close on error to trigger reconnect process. Improve this more over time.
      websocket.current?.close();
    };

    websocket.current.onmessage = event => {
      const senseiEvent = JSON.parse(event.data);

      if (senseiEvent.response_type === 'SenseiErrorResponse') {
        setSenseiLifecycle(LifecycleStatus.isError);
      }
      if (
        senseiEvent.response_type === 'SenseiAuthenticationResponse' ||
        senseiEvent.response_type === 'SenseiKeepAliveResponse' ||
        (senseiEvent.response_type === 'SenseiGeneralResponse' &&
          senseiChatMessagesRef.current.length)
      ) {
        return;
      }
      if (senseiEvent.response_type === 'SenseiAuthenticationChallenge') {
        if (!senseiEvent.auth_challenge) return;

        const authRequest = {
          request_type: 'SenseiAuthenticationRequest',
          auth_token: `Bearer ${authRef.current.user?.access_token}`,
          auth_challenge: senseiEvent.auth_challenge,
          request_id: String(requestId.current),
        };
        sendRawMessage(JSON.stringify(authRequest));
        requestId.current = requestId.current + 1;
      } else {
        setSenseiChatMessages(chatHistory => {
          if (senseiEvent.request_id === null && isEqual(senseiEvent, chatHistory[0])) {
            // Sensei sends the same welcome message with every reconnect, discard
            return chatHistory;
          }
          return [...chatHistory, senseiEvent];
        });
      }
    };
  }, [setSenseiLifecycle, reconnectDelayMS, maxReconnectAttempts, setSenseiChatMessages]);

  const sendRequest = useCallback((request: any) => {
    // Add on the request id before sending the request.
    request.request_id = requestId.current.toString();
    sendRawMessage(JSON.stringify(request));
    requestId.current = requestId.current + 1;
  }, []);

  const sendMessage = useCallback(
    (request: SenseiQuestionRequest) => {
      request.request_id = requestId.current.toString();
      setSenseiChatMessages(chatHistory => [...chatHistory, request]);
      sendRawMessage(JSON.stringify(request));
      requestId.current = requestId.current + 1;
    },
    [setSenseiChatMessages]
  );

  useEffect(() => {
    createWebSocket();
    const keepAliveTimer = setInterval(() => {
      sendRequest({
        request_type: 'SenseiKeepAliveRequest',
      });
    }, keepAliveIntervalMS);

    // TODO: we need a way to reestablish the same connection. Closing the websocket in this hook
    //  has the effect of wiping the session memory.
    return () => {
      if (websocket.current) {
        websocket.current.close();
      }

      clearInterval(keepAliveTimer);
    };
  }, [createWebSocket, keepAliveIntervalMS, sendRequest, setSenseiChatMessages]);

  useEffect(() => {
    createWebSocket();
    setSenseiChatActions({createWebSocket, sendMessage});
  }, [createWebSocket, setSenseiChatActions, sendMessage]);
};
