import IconWrapper from 'components/common/Sensei/IconWrapper';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

const statementData = [
  {
    icon: <VisibilityOffOutlinedIcon />,
    title: 'Private',
    text: 'We do not collect, store, or have access to your data.',
  },
  {
    icon: <VerifiedUserOutlinedIcon />,
    title: 'Secure',
    text: 'Data will never leave your network.',
  },
  {
    icon: <FavoriteBorderOutlinedIcon />,
    title: 'Ethical',
    text: 'Sensei is trained to only discuss relevant topics.',
  },
];

export default function CYAStatements() {
  return (
    <div className="flex w-full flex-col gap-8">
      <div className="flex justify-between gap-8">
        {statementData.map(statement => (
          <div className="flex flex-1 flex-col gap-2" key={statement.title}>
            <div className="flex gap-6">
              <IconWrapper>{statement.icon}</IconWrapper>
              <p className="leading-28 text-16">{statement.title}</p>
            </div>
            <p className="leading-16 text-12 text-gray-200">{statement.text}</p>
          </div>
        ))}
      </div>
      <p className="leading-16 text-12 text-gray-200">
        We understand the importance of maintaining the integrity, confidentiality, and security of
        your sensitive information.
      </p>
    </div>
  );
}
