import Timeline from 'components/common/Timeline/Timeline';
import AnomaliesTable from './components/AnomaliesTable';
import TableTitleRow from './components/TableTitleRow';
import {Container} from 'components/common/TableElements/TableElements';

export default function DetailedAnomalyTable() {
  return (
    <Container>
      <div className="pl-1 pr-2 pt-2">
        <Timeline />
      </div>
      <TableTitleRow />
      <AnomaliesTable />
    </Container>
  );
}
