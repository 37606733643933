import {
  useSelectedAnomalyRelatedAnomaliesFiberChannel,
  useSelectedAnomalyRelatedAnomaliesFiberChannelCount,
} from 'data/FiberChannel';
import AssociatedAnomalies from '../common/AssociatedAnomalies/AssociatedAnomalies';

export default function AssociatedAnomaliesFiberChannel() {
  const anomalyListQuery = useSelectedAnomalyRelatedAnomaliesFiberChannel;
  const anomalyCountQuery = useSelectedAnomalyRelatedAnomaliesFiberChannelCount;

  return (
    <AssociatedAnomalies
      anomalyListQuery={anomalyListQuery}
      anomalyCountQuery={anomalyCountQuery}
    />
  );
}
