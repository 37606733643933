import QueryLifecycleWrapper from 'components/common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {useDeviceHealthOverviewData} from 'data/HardwareMonitoring';
import {isEmpty} from 'lodash';
import styled from 'styled-components';
import ComponentTitle from '../../common/ComponentTitle/ComponentTitle';
import {DeviceHealthScatterplot} from './components/DeviceHealthScatterplot';
import {RiskFailureBarChart} from './components/RiskFailureBarChart';

const Container = styled.div`
  background: black;
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleRowWrapper = styled.div`
  display: flex;
`;

export function DeviceHealthOverview() {
  const query = useDeviceHealthOverviewData();

  return (
    <Container>
      <TitleRowWrapper>
        <ComponentTitle title="Device Health Overview" />
      </TitleRowWrapper>
      <QueryLifecycleWrapper
        query={query}
        isEmpty={isEmpty(query.data)}
        wrapWithTableRow={false}
        emptyMessage="No devices found"
      >
        <>
          <RiskFailureBarChart />
          <DeviceHealthScatterplot />
        </>
      </QueryLifecycleWrapper>
    </Container>
  );
}
