import IconComponent, {IconProps} from '../IconComponent';

export default function LowPriorityIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="LowPriorityIcon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 1.3125C6.25311 1.3125 5.51353 1.45961 4.82349 1.74544C4.13345 2.03126 3.50646 2.4502 2.97833 2.97833C1.91172 4.04494 1.3125 5.49158 1.3125 7C1.3125 8.50842 1.91172 9.95506 2.97833 11.0217C3.50646 11.5498 4.13345 11.9687 4.82349 12.2546C5.51353 12.5404 6.25311 12.6875 7 12.6875C8.50842 12.6875 9.95506 12.0883 11.0217 11.0217C12.0883 9.95506 12.6875 8.50842 12.6875 7C12.6875 6.25311 12.5404 5.51353 12.2546 4.82349C11.9687 4.13345 11.5498 3.50646 11.0217 2.97833C10.4935 2.4502 9.86655 2.03126 9.17651 1.74544C8.48647 1.45961 7.74689 1.3125 7 1.3125ZM7.875 3.5V7.875H6.125V3.5H7.875ZM7.875 10.5V8.75H6.125V10.5H7.875Z"
          fill="currentColor"
        />
        <rect x="6.125" y="3.5" width="1.75" height="4.375" fill="none" />
        <rect x="6.125" y="8.75" width="1.75" height="1.75" fill="none" />
      </svg>
    </IconComponent>
  );
}
