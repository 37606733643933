import {DateTime} from 'luxon';
import {tryParseInvalidISO} from './tryParseInvalidISO';

export const getValidTimestamp = (currentTimestamp: string, fallbackTimestamp: string): string => {
  const parsedTimestamp = tryParseInvalidISO(currentTimestamp);
  const validTimeStamp = DateTime.fromISO(parsedTimestamp).isValid;
  if (!validTimeStamp) {
    console.group();
    console.warn(`Invalid timestamp in URL: ${currentTimestamp}`);
    console.warn(`Syncing url and timeline with fallback timestamp: ${fallbackTimestamp}`);
    console.groupEnd();
  }
  return validTimeStamp ? parsedTimestamp : fallbackTimestamp;
};
