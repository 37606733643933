import IconComponent, {IconProps} from '../IconComponent';

export default function ExtremePriorityIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="ExtremePriorityIcon"
      >
        <path
          d="M2.2002 16.0592L3.8802 11.9992L2.2002 7.93922L6.2602 6.25922L7.9402 2.19922L12.0002 3.87922L16.0602 2.19922L17.7402 6.25922L21.8002 7.93922L20.1202 11.9992L21.8002 16.0592L17.7402 17.7392L16.0602 21.7992L12.0002 20.1192L7.9402 21.7992L6.2602 17.7392L2.2002 16.0592ZM13.0002 16.9992V14.9992H11.0002V16.9992H13.0002ZM13.0002 12.9992V6.99922H11.0002V12.9992H13.0002Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
