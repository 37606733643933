import * as api from '../openapi-schema/schemaTS';

export async function getMetadataHostmap() {
  const post = await api.hostIpMapMetadataHostmapPost();
  return post.data.result;
}

export async function getDatacentersById(payload: api.DatacentersByIDRequest) {
  const post = await api.datacentersByIdDatacentersPost(payload);
  return post.data.result;
}

export async function getAnomalyCategories() {
  const post = await api.anomalyCategoriesAnomalyCategoriesPost();
  return post.data;
}

export async function getAnomaliesByDatacenterId(payload: api.AnomaliesByDCRequest) {
  const post = await api.anomaliesAnomaliesPost(payload);
  return post.data.result;
}

export async function getAnomaliesCount(payload: api.WindowedAnomalyListFilters) {
  const post = await api.anomaliesCountAnomaliesCountPost(payload);
  return post.data.result;
}

export async function getAnomalyById(payload: api.AnomalyID) {
  const post = await api.anomalyByIdAnomalyPost(payload);
  return post.data.result;
}

export async function getAnomalyBinCountsFill(payload: api.AnomalyBinCountRequest) {
  const post = await api.anomalyBinCountsFillAnomaliesAggregatedTimeBinsFillPost(payload);
  return post.data.result;
}

// Log anomaly endpoints
export async function getTemplatesByAnomalyId(payload: api.TemplatesByAnomalyRequest) {
  const post = await api.templatesByAnomalyAnomalyLogDataTemplatesPost(payload);
  return post.data.result;
}

export async function getTemplatesByAnomalyIdCount(payload: api.TemplatesByAnomalyCountRequest) {
  const post = await api.templatesByAnomalyCountAnomalyLogDataTemplatesCountPost(payload);
  return post.data.result;
}

export async function getLogsByAnomalyId(payload: api.TemplatesByLogRequest) {
  const post = await api.logsByTemplateCompareAnomalyLogDataTemplatesComparePost(payload);
  return post.data.result;
}

export async function getLogsByAnomalyIdCount(payload: api.TemplatesByLogFilters) {
  const post = await api.logsByTemplateCompareCountAnomalyLogDataTemplatesCompareCountPost(payload);
  return post.data.result;
}

export async function getWordcloudByTemplates(payload: api.WindowedAnomalyID) {
  const post = await api.wordcloudByTemplatesAnomalyLogDataWordcloudPost(payload);
  return post.data.result;
}

// Exchange Log anomaly endpoints
export async function getExchangeLogTemplatesByAnomalyId(payload: api.TemplatesByAnomalyRequest) {
  const post = await api.exchangeTemplatesByAnomalyAnomalyExchangelogDataTemplatesPost(payload);
  return post.data.result;
}

export async function getExchangeLogTemplatesByAnomalyIdCount(
  payload: api.TemplatesByAnomalyCountRequest
) {
  const post = await api.exchangeTemplatesByAnomalyCountAnomalyExchangelogDataTemplatesCountPost(
    payload
  );
  return post.data.result;
}

export async function getExchangeLogsByAnomalyId(payload: api.TemplatesByLogRequest) {
  const post = await api.exchangeLogsByTemplateCompareAnomalyExchangelogDataTemplatesComparePost(
    payload
  );
  return post.data.result;
}

export async function getExchangeLogsByAnomalyIdCount(payload: api.TemplatesByLogFilters) {
  const post =
    await api.exchangeLogsByTemplateCompareCountAnomalyExchangelogDataTemplatesCompareCountPost(
      payload
    );
  return post.data.result;
}

export async function getExchangeLogWordcloudByTemplates(payload: api.WindowedAnomalyID) {
  const post = await api.exchangeWordcloudByTemplatesAnomalyExchangelogDataWordcloudPost(payload);
  return post.data.result;
}

export async function getRelatedAnomaliesExchangeLog(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.exchangeAssociatedAnomaliesAnomalyExchangelogRelatedAnomaliesPost(payload);
  return post.data.result;
}

export async function getRelatedAnomaliesExchangeLogCount(payload: api.WindowedAnomalyID) {
  const post =
    await api.exchangeAssociatedAnomaliesCountAnomalyExchangelogRelatedAnomaliesCountPost(payload);
  return post.data.result;
}

//Internal endpoints
export async function getInternalLateLogsData(payload: api.Windowed) {
  const post = await api.internalLateLogsInternalLogLatePost(payload);
  return post.data.result;
}

// Latency anomaly endpoints
export async function getLatencyPairMetrics(payload: api.LatencyPairMetricsRequest) {
  const post = await api.latencyPairMetricsAnomalyLatencyDataPairsPost(payload);
  return post.data.result;
}

export async function getLatencyPairMetricsCount(payload: api.LatencyPairMetricsCountRequest) {
  const post = await api.latencyPairMetricsCountAnomalyLatencyDataPairsCountPost(payload);
  return post.data.result;
}

export async function getLatencyPerformanceMetricsByAnomalyId(
  payload: api.BinnedWindowedAnomalyID
) {
  const post = await api.latenciesBinAggregatedAnomalyLatencyDataAggregatedTimeBinsFillPost(
    payload
  );
  return post.data.result;
}

export async function getLatencyBoxplotDataByAnomalyId(payload: api.BinnedWindowedAnomalyID) {
  const post = await api.latenciesSummaryAggregatedAnomalyLatencyDataAggregatedTimeBinsSummaryPost(
    payload
  );
  return post.data.result;
}

export async function getRelatedAnomaliesLatency(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.latenciesAssociatedAnomaliesAnomalyLatencyRelatedAnomaliesPost(payload);
  return post.data.result;
}

export async function getRelatedAnomaliesLatencyCount(payload: api.WindowedAnomalyID) {
  const post = await api.latenciesAssociatedAnomaliesCountAnomalyLatencyRelatedAnomaliesCountPost(
    payload
  );
  return post.data.result;
}

export async function getLogTemplatesLatency(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.latenciesAssociatedLogsAnomalyLatencyRelatedDataTemplatesPost(payload);
  return post.data.result;
}

export async function getLogTemplatesLatencyCount(payload: api.WindowedAnomalyID) {
  const post = await api.latenciesAssociatedLogsCountAnomalyLatencyRelatedDataTemplatesCountPost(
    payload
  );
  return post.data.result;
}

export async function getNetworkRingDCs(payload: api.Windowed) {
  const post = await api.latenciesNetworkRingAnomalyLatencyRingSitesUnidirectionalPost(payload);
  return post.data.result;
}

// Interface Utilization anomaly endpoints
export async function getUtilizationMetricsByAnomalyId(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.utilizationByAnomalyAnomalyUtilizationDataPost(payload);
  return post.data.result;
}

export async function getUtilizationMetricsByAnomalyIdCount(payload: api.WindowedAnomalyID) {
  const post = await api.utilizationByAnomalyCountAnomalyUtilizationDataCountPost(payload);
  return post.data.result;
}

export async function getHistoricalUtilizationMetricsByAnomalyId(
  payload: api.BinnedWindowedAnomalyID
) {
  const post = await api.utilizationByAnomalyAggregatedAnomalyUtilizationDataAggregatedTimeBinsPost(
    payload
  );
  return post.data.result;
}

export async function getRelatedAnomaliesUtilization(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.utilizationRelatedAnomaliesAnomalyUtilizationRelatedAnomaliesPost(payload);
  return post.data.result;
}

export async function getRelatedAnomaliesUtilizationCount(payload: api.WindowedAnomalyID) {
  const post =
    await api.utilizationRelatedAnomaliesCountAnomalyUtilizationRelatedAnomaliesCountPost(payload);
  return post.data.result;
}

export async function getLogTemplatesUtilization(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.utilizationRelatedLogsAnomalyUtilizationRelatedDataTemplatesPost(payload);
  return post.data.result;
}

export async function getLogTemplatesUtilizationCount(payload: api.WindowedAnomalyID) {
  const post = await api.utilizationRelatedLogsCountAnomalyUtilizationRelatedDataTemplatesCountPost(
    payload
  );
  return post.data.result;
}

// Fiber Channel anomaly endpoints
export async function getFiberChannelMetricsByAnomalyId(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.fiberchannelUtilizationByAnomalyAnomalyFiberchannelDataPost(payload);
  return post.data.result;
}

export async function getFiberChannelMetricsByAnomalyIdCount(payload: api.WindowedAnomalyID) {
  const post = await api.fiberchannelUtilizationByAnomalyCountAnomalyFiberchannelDataCountPost(
    payload
  );
  return post.data.result;
}

export async function getHistoricalFiberChannelMetricsByAnomalyId(
  payload: api.BinnedWindowedAnomalyID
) {
  const post =
    await api.fiberchannelUtilizationByAnomalyAggregatedAnomalyFiberchannelDataAggregatedTimeBinsPost(
      payload
    );
  return post.data.result;
}

export async function getRelatedAnomaliesFiberChannel(payload: api.SortedPagedWindowedAnomalyID) {
  const post =
    await api.fiberchannelUtilizationRelatedAnomaliesAnomalyFiberchannelRelatedAnomaliesPost(
      payload
    );
  return post.data.result;
}

export async function getRelatedAnomaliesFiberChannelCount(payload: api.WindowedAnomalyID) {
  const post =
    await api.fiberchannelUtilizationRelatedAnomaliesCountAnomalyFiberchannelRelatedAnomaliesCountPost(
      payload
    );
  return post.data.result;
}

export async function getLogTemplatesFiberChannel(payload: api.SortedPagedWindowedAnomalyID) {
  const post =
    await api.fiberchannelUtilizationRelatedLogsAnomalyFiberchannelRelatedDataTemplatesPost(
      payload
    );
  return post.data.result;
}

export async function getLogTemplatesFiberChannelCount(payload: api.WindowedAnomalyID) {
  const post =
    await api.fiberchannelUtilizationRelatedLogsCountAnomalyFiberchannelRelatedDataTemplatesCountPost(
      payload
    );
  return post.data.result;
}

// BGP anomaly endpoints
export async function getBGPPeers(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.neighbordropBgpPeersAnomalyNeighbordropBgpPeersPost(payload);
  return post.data.result;
}

export async function getBGPPeersCount(payload: api.WindowedAnomalyID) {
  const post = await api.neighbordropBgpPeersCountAnomalyNeighbordropBgpPeersCountPost(payload);
  return post.data.result;
}

export async function getRelatedAnomaliesBGP(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.neighbordropBgpRelatedAnomaliesAnomalyNeighbordropBgpRelatedAnomaliesPost(
    payload
  );
  return post.data.result;
}

export async function getRelatedAnomaliesBGPCount(payload: api.WindowedAnomalyID) {
  const post =
    await api.neighbordropBgpRelatedAnomaliesCountAnomalyNeighbordropBgpRelatedAnomaliesCountPost(
      payload
    );
  return post.data.result;
}

export async function getLogTemplatesBGP(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.neighbordropBgpRelatedLogsAnomalyNeighbordropBgpRelatedDataTemplatesPost(
    payload
  );
  return post.data.result;
}

export async function getLogTemplatesBGPCount(payload: api.WindowedAnomalyID) {
  const post =
    await api.neighbordropBgpRelatedLogsCountAnomalyNeighbordropBgpRelatedDataTemplatesCountPost(
      payload
    );
  return post.data.result;
}

// ISIS anomaly endpoints
export async function getISISAdjacencies(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.neighbordropIsisAdjacenciesAnomalyNeighbordropIsisAdjacenciesPost(payload);
  return post.data.result;
}

export async function getISISAdjacenciesCount(payload: api.WindowedAnomalyID) {
  const post =
    await api.neighbordropIsisAdjacenciesCountAnomalyNeighbordropIsisAdjacenciesCountPost(payload);
  return post.data.result;
}

export async function getISISAdjacenciesDowntimes(payload: api.WindowedAnomalyID) {
  const post =
    await api.neighbordropIsisAdjacenciesStatusDownAnomalyNeighbordropIsisAdjacenciesDowntimePost(
      payload
    );
  return post.data.result;
}

export async function getRelatedAnomaliesISIS(payload: api.SortedPagedWindowedAnomalyID) {
  const post =
    await api.neighbordropIsisRelatedAnomaliesAnomalyNeighbordropIsisRelatedAnomaliesPost(payload);
  return post.data.result;
}

export async function getRelatedAnomaliesISISCount(payload: api.WindowedAnomalyID) {
  const post =
    await api.neighbordropIsisRelatedAnomaliesCountAnomalyNeighbordropIsisRelatedAnomaliesCountPost(
      payload
    );
  return post.data.result;
}

export async function getLogTemplatesISIS(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.neighbordropIsisRelatedLogsAnomalyNeighbordropIsisRelatedDataTemplatesPost(
    payload
  );
  return post.data.result;
}

export async function getLogTemplatesISISCount(payload: api.WindowedAnomalyID) {
  const post =
    await api.neighbordropIsisRelatedLogsCountAnomalyNeighbordropIsisRelatedDataTemplatesCountPost(
      payload
    );
  return post.data.result;
}

// Path Change endpoints
export async function getPathInformation(payload: api.WindowedAnomalyID) {
  const post = await api.pathchangePathInfoAnomalyPathchangeDataAggregatedPathPost(payload);
  return post.data.result;
}

export async function getPathDelay(payload: api.AnomalyID) {
  const post = await api.pathchangePathDelayAnomalyPathchangeDataAggregatedDelayPost(payload);
  return post.data.result;
}

export async function getRelatedAnomaliesPathChange(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.pathchangeRelatedAnomaliesAnomalyPathchangeRelatedAnomaliesPost(payload);
  return post.data.result;
}

export async function getRelatedAnomaliesPathChangeCount(payload: api.WindowedAnomalyID) {
  const post = await api.pathchangeRelatedAnomaliesCountAnomalyPathchangeRelatedAnomaliesCountPost(
    payload
  );
  return post.data.result;
}

export async function getLogTemplatesPathChange(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.pathchangeRelatedLogsAnomalyPathchangeRelatedDataTemplatesPost(payload);
  return post.data.result;
}

export async function getLogTemplatesPathChangeCount(payload: api.WindowedAnomalyID) {
  const post = await api.pathchangeRelatedLogsCountAnomalyPathchangeRelatedDataTemplatesCountPost(
    payload
  );
  return post.data.result;
}

// VTC endpoints
export async function getVTCAnomalydata(payload: api.AnomalyID) {
  const post = await api.vtcDataByAnomalyAnomalyVtcDataPost(payload);
  return post.data.result;
}

export async function getVTCPairsData(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.vtcPairCallsByAnomalyAnomalyVtcPairsPost(payload);
  return post.data.result;
}

export async function getVTCPairsDataCount(payload: api.WindowedAnomalyID) {
  const post = await api.vtcPairCallsByAnomalyCountAnomalyVtcPairsCountPost(payload);
  return post.data.result;
}

export async function getRelatedAnomaliesVTC(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.vtcRelatedAnomaliesAnomalyVtcRelatedAnomaliesPost(payload);
  return post.data.result;
}

export async function getRelatedAnomaliesVTCCount(payload: api.WindowedAnomalyID) {
  const post = await api.vtcRelatedAnomaliesCountAnomalyVtcRelatedAnomaliesCountPost(payload);
  return post.data.result;
}

export async function getLogTemplatesVTC(payload: api.SortedPagedWindowedAnomalyID) {
  const post = await api.vtcRelatedLogsAnomalyVtcRelatedDataTemplatesPost(payload);
  return post.data.result;
}

export async function getLogTemplatesVTCCount(payload: api.WindowedAnomalyID) {
  const post = await api.vtcRelatedLogsCountAnomalyVtcRelatedDataTemplatesCountPost(payload);
  return post.data.result;
}

export async function getVTCServicePerformanceChartData(payload: api.BinnedWindowedAnomalyID) {
  const post = await api.vtcPairCallsMetricsAggregatedFilledAnomalyVtcDataAggregatedTimeBinsPost(
    payload
  );
  return post.data.result;
}

export async function getVTCPingData(payload: api.BinnedWindowedAnomalyID) {
  const post = await api.vtcPairPingMetricsAggregatedFilledAnomalyVtcDataPingAggregatedTimeBinsPost(
    payload
  );
  return post.data.result;
}

// Hardware Lifecycle endpoints
export async function getHardwareCatalogData(payload: api.SortedPagedWindowed) {
  const post = await api.mtbfHardwareCatalogMtbfDataHardwareCatalogPost(payload);
  return post.data.result;
}

export async function getHardwareCatalogDataCount(payload: api.Windowed) {
  const post = await api.mtbfHardwareCatalogCountMtbfDataHardwareCatalogCountPost(payload);
  return post.data.result;
}

export async function getHardwareCatalogDeviceDetailsData(payload: api.WindowedNodeID) {
  const post = await api.mtbfDeviceComponentsMtbfDataDeviceComponentsPost(payload);
  return post.data.result;
}

export async function getSnmpData(payload: api.SortedPagedWindowedNodeID) {
  const post = await api.mtbfHardwareMetricsMtbfDataHardwareMetricsPost(payload);
  return post.data.result;
}

export async function getSnmpDataCount(payload: api.WindowedNodeID) {
  const post = await api.mtbfHardwareMetricsCountMtbfDataHardwareMetricsCountPost(payload);
  return post.data.result;
}

export async function getLogSignsData(payload: api.SortedPagedWindowedNodeID) {
  const post = await api.mtbfAssociatedLogsMtbfRelatedDataTemplatesPost(payload);
  return post.data.result;
}

export async function getLogSignsDataCount(payload: api.WindowedNodeID) {
  const post = await api.mtbfAssociatedLogsCountMtbfRelatedDataTemplatesCountPost(payload);
  return post.data.result;
}

export async function getDeviceHealthOverview(payload: api.Windowed) {
  const post = await api.mtbfDeviceHealthOverviewMtbfDataDeviceHealthPost(payload);
  return post.data.result;
}

// Security endpoints
export async function getSecurityEventData(payload: api.WselAnomaliesRequest) {
  const post = await api.wselSecurityEventsWselDataSecurityEventsPost(payload);
  return post.data.result;
}

export async function getSecurityEventDataCount(payload: api.WselAnomaliesRequest) {
  const post = await api.wselSecurityEventsCountWselDataSecurityEventsCountPost(payload);
  return post.data.result;
}
export async function getSecurityEventLog(payload: api.AnomalyID) {
  const post = await api.wselSecurityEventLogWselDataRelatedTemplatePost(payload);
  return post.data.result;
}

export async function getSecurityFlaggedUsers(payload: api.SortedPagedWindowed) {
  const post = await api.getWselFlaggedUsersWselFlaggedUsersPost(payload);
  return post.data.result;
}

export async function getSecurityFlaggedUsersCount(payload: api.SortedPagedWindowed) {
  const post = await api.getWselFlaggedUsersCountWselFlaggedUsersCountPost(payload);
  return post.data.result;
}

export async function createSecurityFlaggedUser(payload: api.SetWselUserFlagEvent) {
  const post = await api.createWselUserFlagEventWselFlaggedUsersEventCreatePost(payload);
  return post.data.result;
}

export async function existsSecurityFlaggedUser(payload: api.WselUserFlagEventID) {
  const post = await api.existsWselUserFlagEventWselFlaggedUsersEventExistsPost(payload);
  return post.data.result;
}

export async function deleteSecurityFlaggedUser(payload: api.WselUserFlagEventID) {
  const post = await api.deleteWselUserFlagEventWselFlaggedUsersEventDeletePost(payload);
  // Nothing to return
  return post.data;
}

// KPI endpoints
export async function getOverviewKPIs(payload: api.WindowedAnomalyListFilters) {
  const post = await api.kpiOverviewKpiOverviewPost(payload);
  return post.data.result;
}

export async function getLogAnomalyKPIs(payload: api.KPILogAnomalyRequest) {
  const post = await api.kpiByLogAnomalyKpiAnomalyLogPost(payload);
  return post.data.result;
}

export async function getExchangeLogAnomalyKPIs(payload: api.KPILogAnomalyRequest) {
  const post = await api.kpiByExchangeLogAnomalyKpiAnomalyExchangelogPost(payload);
  return post.data.result;
}

export async function getInterfaceUtilizationKPIs(payload: api.WindowedAnomalyID) {
  const post = await api.kpiByUtilizationAnomalyKpiAnomalyUtilizationPost(payload);
  return post.data.result;
}

export async function getFiberChannelKPIs(payload: api.WindowedAnomalyID) {
  const post = await api.kpiByFiberchannelAnomalyKpiAnomalyFiberchannelPost(payload);
  return post.data.result;
}

export async function getLatencyKPIs(payload: api.WindowedAnomalyID) {
  const post = await api.kpiByLatencyAnomalyKpiAnomalyLatencyPost(payload);
  return post.data.result;
}

export async function getBGPKPIs(payload: api.WindowedAnomalyID) {
  const post = await api.kpiByNeighbordropBgpAnomalyKpiAnomalyNeighbordropBgpPost(payload);
  return post.data.result;
}

export async function getISISKPIs(payload: api.WindowedAnomalyID) {
  const post = await api.kpiByNeighbordropIsisAnomalyKpiAnomalyNeighbordropIsisPost(payload);
  return post.data.result;
}

export async function getVTCKPIs(payload: api.WindowedAnomalyID) {
  const post = await api.kpiByVtcAnomalyKpiAnomalyVtcPost(payload);
  return post.data.result;
}

// Topology endpoints
export async function getDevices(payload: api.Windowed) {
  const post = await api.devicesRawTopologyDevicesRawPost(payload);
  return post.data.result;
}

export async function getDevicesBGP(payload: api.Windowed) {
  const post = await api.topologyBgpPeersTopologyBgpPeersPost(payload);
  return post.data.result;
}

export async function getDevicesISIS(payload: api.Windowed) {
  const post = await api.topologyIsisPeersTopologyIsisAdjacenciesPost(payload);
  return post.data.result;
}

export async function getDevicesPathChange(payload: api.AnomalyID) {
  const post = await api.topologyPathchangeGraphTopologyPathchangeGraphPost(payload);
  return post.data.result;
}

export async function getDevicesVTC(payload: api.AnomalyID) {
  const post = await api.topologyVtcGraphTopologyVtcGraphPost(payload);
  return post.data.result;
}

export async function getDevicesCPU(payload: api.NodeDevicePerformanceUsageRequest) {
  const post =
    await api.devicesDevicePerformanceSummaryAggregatedTopologyDevicesPerformanceAggregatedTimeBinsSummaryPost(
      payload
    );
  return post.data.result;
}

export async function getDevicesTCAM(payload: api.NodeTcamUsageRequest) {
  const post =
    await api.devicesTcamSummaryAggregatedTopologyDevicesTcamAggregatedTimeBinsSummaryPost(payload);
  return post.data.result;
}

export async function getInterfaces(payload: api.Windowed) {
  const post = await api.interfacesFullTopologyInterfacesUnidirectionalPost(payload);
  return post.data.result;
}

export async function getInterfacesFiberChannel(payload: api.Windowed) {
  const post = await api.interfacesFiberchannelTopologyInterfacesFiberchannelPost(payload);
  return post.data.result;
}

export async function getInterfacesUtilization(payload: api.InterfaceUtilizationUsageRequest) {
  const post =
    await api.interfaceUtilizationSummaryAggregatedTopologyInterfacesUtilizationAggregatedTimeBinsSummaryPost(
      payload
    );
  return post.data.result;
}

export async function postUserFeedback(payload: api.BaseUserFeedback) {
  const post = await api.feedbackCreateFeedbackCreatePost(payload);
  return post.data.result;
}
