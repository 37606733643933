import {EventObjectNode} from 'cytoscape';
import {useRecoilValue} from 'recoil';
import {selectedWhatIfMetricState} from '../../../atoms/selectedWhatIfMetric';
import {NodeMetrics} from '../../../constants/WhatIfMetrics';
import {TopologyNodeData} from '../../../data/types';
import {ScaledWhatIfMetricData} from '../../WhatIfCalculator/TopologyWhatIf/utils/whatIfScalingFunctionsTooltip';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';
import {
  Container,
  GroupWrapper,
  Row,
  Header,
  Text,
  Line,
  HiddenElement,
} from './TopologyTooltipElements';

interface Props {
  event: EventObjectNode;
  scaledMetricData: ScaledWhatIfMetricData[] | undefined;
}

export default function WhatIfNodeTooltip({event, scaledMetricData}: Props) {
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);
  const node = event.target.data() as TopologyNodeData;

  return (
    <Tooltip
      tooltipContent={
        <Container>
          <GroupWrapper>
            <Row>
              <Header>Name:</Header>
              <Header>{node.name}</Header>
            </Row>
            <Row>
              <Text>Device:</Text>
              <Text>{node.device_category}</Text>
            </Row>
            <Row>
              <Text>IP:</Text>
              <Text>{node.ip}</Text>
            </Row>
            <Row>
              <Text>Status:</Text>
              <Text>{node.status}</Text>
            </Row>
          </GroupWrapper>
          {selectedWhatIfMetric in NodeMetrics && (
            <>
              <Line />
              <GroupWrapper>
                {!scaledMetricData ? (
                  <Row>
                    <Text>No {selectedWhatIfMetric} data</Text>
                  </Row>
                ) : (
                  <>
                    {scaledMetricData.map(metric => {
                      return (
                        <Row key={metric.name}>
                          <Text>Scaled {metric.name}:</Text>
                          <Text>{metric.value?.toFixed(1)}%</Text>
                        </Row>
                      );
                    })}
                  </>
                )}
              </GroupWrapper>
            </>
          )}
        </Container>
      }
      mainContent={
        <HiddenElement x={event.originalEvent.offsetX} y={event.originalEvent.offsetY} />
      }
      onCanvas={true}
      maxWidth={'none'}
    />
  );
}
