import styled from 'styled-components';
import {FingerprintClassification} from '../../../../openapi-schema/fingerprintSchemaTS';

const Text = styled.p`
  text-transform: capitalize;
  text-align: center;
`;

interface Props {
  level: FingerprintClassification | undefined;
}

export default function AutomationLevel({level}: Props) {
  return <Text>{level?.toLocaleLowerCase()}</Text>;
}
