import ChatInputForm from './ChatInputForm';
import SenseiGreeting from './SenseiGreeting';
import {useRecoilValue} from 'recoil';
import {
  LifecycleStatus,
  senseiChatActionsState,
  senseiChatSessionState,
  senseiLifecycleState,
} from 'atoms/sensei';
import ChatLog from './ChatLog';
import {useSenseiWebSocket} from 'data/Sensei';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ErrorState from 'components/Katana/components/ErrorState/ErrorState';

export default function Sensei() {
  useSenseiWebSocket({});
  const senseiChatMessages = useRecoilValue(senseiChatSessionState);
  const senseiLifecycle = useRecoilValue(senseiLifecycleState);
  const senseiChatActions = useRecoilValue(senseiChatActionsState);

  const getContent = () => {
    if (senseiLifecycle === LifecycleStatus.isLoading) {
      return <LoadingSpinner />;
    }
    if (senseiLifecycle === LifecycleStatus.isError) {
      return <ErrorState retry={senseiChatActions?.createWebSocket() || undefined} />;
    }

    return senseiChatMessages.length > 1 ? <ChatLog /> : <SenseiGreeting />;
  };

  return (
    <div className="flex h-full flex-col items-center gap-4 bg-black text-white">
      {getContent()}
      <ChatInputForm />
    </div>
  );
}
