import {useEffect} from 'react';
import {useRecoilValue, useResetRecoilState, useRecoilState} from 'recoil';
import styled from 'styled-components';
import {LatencyChartMinMaxToggleState} from '../../../../atoms/LatencyChartMinMaxToggle';
import {performanceMetricsToggleState} from '../../../../atoms/performanceMetricsToggle';
import {selectedAnomalyDataState} from '../../../../atoms/selectedAnomaly';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import SwitchComponent from '../../../Katana/components/SwitchComponent/SwitchComponent';
import config from '../../../../utils/AppConfigurationService';

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: black;

  canvas {
    padding: 10px;
    background-color: black;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 4px 10px;
`;

interface SwitchContainerProps {
  disabled?: boolean;
}

const SwitchContainer = styled.div<SwitchContainerProps>`
  display: flex;
  gap: 6px;
  align-items: center;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
`;

const SwitchLabel = styled.p`
  font-size: 13px;
  color: white;
`;

const SwitchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
`;

interface Props {
  children: JSX.Element;
}

export default function PerformanceMetricsChartWrapper({children}: Props) {
  const [performanceMetricsToggle, setPerformanceMetricsToggle] = useRecoilState(
    performanceMetricsToggleState
  );
  const resetPerformanceMetricsToggle = useResetRecoilState(performanceMetricsToggleState);
  const [latencyChartMinMaxToggle, setLatencyChartMinMaxToggle] = useRecoilState(
    LatencyChartMinMaxToggleState
  );
  const resetLatencyChartMinMaxToggle = useResetRecoilState(LatencyChartMinMaxToggleState);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);

  useEffect(() => {
    return () => {
      resetPerformanceMetricsToggle();
      resetLatencyChartMinMaxToggle();
    };
  }, [resetLatencyChartMinMaxToggle, resetPerformanceMetricsToggle]);

  return (
    <ChartContainer>
      <HeaderWrapper>
        <ComponentTitle title={'Overview Metrics'} />
        <SwitchesWrapper>
          <SwitchContainer>
            <SwitchLabel>Highlight Observations</SwitchLabel>
            <SwitchComponent
              handleChange={() => setPerformanceMetricsToggle(prev => !prev)}
              checked={performanceMetricsToggle}
            />
          </SwitchContainer>
          {selectedAnomalyData?.anomaly_type === config.AnomalyTypes.LATENCY && (
            <SwitchContainer>
              <SwitchLabel>Show min/max</SwitchLabel>
              <SwitchComponent
                handleChange={() => setLatencyChartMinMaxToggle(prev => !prev)}
                checked={latencyChartMinMaxToggle}
              />
            </SwitchContainer>
          )}
        </SwitchesWrapper>
      </HeaderWrapper>
      {children}
    </ChartContainer>
  );
}
