import styled from 'styled-components';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';

export const Container = styled.div<Props>`
  display: flex;
  border-radius: 4px;
  cursor: ${props => (props.status === 'RUNNING' ? 'default' : 'pointer')};
  background: rgba(49, 52, 81, 1);
  align-items: center;
  width: 61px;
  height: 22px;
  padding: 0 4px;
  justify-content: space-between;
  opacity: ${props => (props.status === 'RUNNING' ? 0.4 : 1)};

  :hover {
    background: ${props =>
      props.status === 'RUNNING' ? 'rgba(49, 52, 81, 1)' : 'rgba(174, 51, 247, 1)'};
  }
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;

export const IconWrapper = styled.div`
  display: grid;
  place-content: center;
`;

interface Props {
  status?: string;
}

export default function Run({status}: Props) {
  return (
    <Container status={status}>
      <Text>Run...</Text>
      <IconWrapper>
        <PlaylistPlayIcon sx={{fontSize: '16px'}} />
      </IconWrapper>
    </Container>
  );
}
