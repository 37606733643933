import AnomalyDetailKPI from '../../common/AnomalyDetailKPI/AnomalyDetailKPI';
import AssociatedAnomaliesISIS from '../AssociatedAnomaliesISIS/AssociatedAnomaliesISIS';
import AssociatedLogsISIS from '../AssociatedLogsISIS/AssociatedLogsISIS';
import GoldenLayout from '../../../common/GoldenLayout/GoldenLayout';
import ISISAdjacencyStatusChart from '../ISISAdjacencyStatusChart/ISISAdjacencyStatusChart';
import ISISNeighborDropAnomalyTable from '../ISISNeighborDropAnomalyTable/ISISNeighborDropAnomalyTable';
import TopologyDetailPage from '../../common/TopologyDetailPage/TopologyDetailPage';

export default function ISISNeighborDropDetailDashboard() {
  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              type: 'column',
              width: 74,
              content: [
                {
                  type: 'row',
                  content: [
                    {
                      component: () => <ISISNeighborDropAnomalyTable />,
                      width: 60,
                    },
                    {
                      component: () => <ISISAdjacencyStatusChart />,
                      width: 40,
                    },
                  ],
                },
                {
                  component: () => <AssociatedLogsISIS />,
                  height: 50,
                },
              ],
            },
            {
              type: 'column',
              width: 26,
              content: [
                {
                  component: () => <AnomalyDetailKPI />,
                  height: 15,
                },
                {
                  component: () => <AssociatedAnomaliesISIS />,
                  height: 42.5,
                },
                {
                  component: () => <TopologyDetailPage />,
                  height: 42.5,
                },
              ],
            },
          ],
        },
      ]}
      padding="7px 10px 10px 10px"
    />
  );
}
