import styled from 'styled-components';
import {useScriptExecutionDetails} from '../../../../data/AutoRemediation';
import ErrorState from '../../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import {ServiceNowModalActions} from '../ServiceNowModalActions';
import {
  BigContainer,
  CancelButton,
  ConfirmButton,
  ContentContainer,
  FooterContainer,
} from './ModalElements';
import ModalHeader from './ModalHeader';

const ContentBlock = styled.div`
  background: black;
  border: 1px solid rgba(137, 134, 142, 1);
  height: 100%;
`;

interface Props {
  match_id: string;
}

export default function LoadingModal({match_id}: Props) {
  const {isLoading, isError, refetch} = useScriptExecutionDetails(match_id);
  const {closeModal} = ServiceNowModalActions();

  return (
    <BigContainer>
      <ModalHeader title="Loading Script information" />
      <ContentContainer>
        <ContentBlock>
          {isLoading && <LoadingSpinner />}
          {isError && <ErrorState retry={refetch} />}
        </ContentBlock>
      </ContentContainer>
      <FooterContainer>
        <CancelButton onClick={closeModal} style={{marginRight: 'auto'}}>
          Close
        </CancelButton>
        <ConfirmButton disabled={true}>Next</ConfirmButton>
      </FooterContainer>
    </BigContainer>
  );
}
