import styled from 'styled-components';
import {
  Row,
  ConatinerWithArrow,
  RowText,
  IconWrapper,
  ValueColumnSize,
  AliasColumnSize,
  VariableColumnSize,
} from './VariableAssignment';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import Dropdown from '../../../../common/Dropdown/Dropdown';
import {AnomalyTemplate, HydratedLogElement} from '../../../../../openapi-schema/schemaTS';
import {SystemVariableData} from './SystemVariable';
import {useRecoilValue} from 'recoil';
import {UsedAliases, usedAliasesState} from '../../../../../atoms/usedAliases';
import {uploadedAliasListState} from '../../../../../atoms/uploadedAliasList';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {VAR_MATCHING_REGEX} from '../../../../../constants/AutoRemediation';
import useARActions from '../../../../../helpers/hooks/useARActions';

const DropdownWrapper = styled.div`
  min-width: 0;
`;

const regex = new RegExp(VAR_MATCHING_REGEX, 'i');
const NoAliasMessage = 'No Alias Selected';

const getVariableName = (element: HydratedLogElement | SystemVariableData) => {
  if ('variables_key' in element) {
    return regex.exec(element?.variables_key as string)?.[1] || '$';
  } else if ('name' in element) {
    return element?.name;
  }
  return '$';
};

const getAliasDropdownList = (
  uploadedAliasList: string[],
  usedAliases: UsedAliases,
  selectedAlias: string
) => {
  const availableAliases = uploadedAliasList.filter(alias => !(alias in usedAliases));
  const aliasDropdownList = [NoAliasMessage];

  if (selectedAlias !== NoAliasMessage) {
    aliasDropdownList.push(selectedAlias);
  }

  return aliasDropdownList.concat(availableAliases);
};

interface Props {
  element: HydratedLogElement | SystemVariableData;
  template: AnomalyTemplate | undefined;
  index: number;
}

export default function VariableAssignmentRow({element, template, index}: Props) {
  const uploadedAliasList = useRecoilValue(uploadedAliasListState);
  const usedAliases = useRecoilValue(usedAliasesState);
  const [animationActive, setAnimationActive] = useState(false);
  const changeFromDropdownRef = useRef(true);
  const {removeAliasFromUsedAliasList, addAliasToUsedAliasList} = useARActions();

  const selectedAlias = useMemo(() => {
    for (const alias in usedAliases) {
      if (
        usedAliases[alias].template_id === template?.template_id &&
        usedAliases[alias].template_version === template?.template_version &&
        usedAliases[alias].index === index
      ) {
        return alias;
      }
    }
    return NoAliasMessage;
  }, [template, index, usedAliases]);

  const handleChange = useCallback(
    (value: string, prevValue: string) => {
      removeAliasFromUsedAliasList(prevValue);
      if (value !== NoAliasMessage) {
        addAliasToUsedAliasList(value, element as HydratedLogElement, template, index);
      }
      changeFromDropdownRef.current = true;
    },
    [template, index, element, addAliasToUsedAliasList, removeAliasFromUsedAliasList]
  );

  useEffect(() => {
    if (changeFromDropdownRef.current) {
      changeFromDropdownRef.current = false;
    } else {
      setAnimationActive(true);
    }
  }, [selectedAlias]);

  return (
    <Row animationActive={animationActive} onAnimationEnd={() => setAnimationActive(false)}>
      <ConatinerWithArrow style={{flex: VariableColumnSize}}>
        <RowText>{getVariableName(element)}</RowText>
        <IconWrapper>
          <TrendingFlatIcon sx={{fontSize: '20px'}} />
        </IconWrapper>
      </ConatinerWithArrow>
      <ConatinerWithArrow style={{flex: ValueColumnSize}}>
        <RowText>{element.value}</RowText>
        <IconWrapper>
          <TrendingFlatIcon sx={{fontSize: '20px'}} />
        </IconWrapper>
      </ConatinerWithArrow>
      <DropdownWrapper style={{flex: AliasColumnSize}}>
        <Dropdown
          dropdownOptions={getAliasDropdownList(uploadedAliasList, usedAliases, selectedAlias)}
          onChange={handleChange}
          selectedValue={selectedAlias in usedAliases ? selectedAlias : NoAliasMessage}
          variant={'outline'}
          size={'small'}
        />
      </DropdownWrapper>
    </Row>
  );
}
