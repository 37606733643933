import {atom} from 'recoil';
import config from '../utils/AppConfigurationService';
import {Timeline} from './dashboard';

export const overviewTimelineState = atom({
  key: 'overviewTimeline',
  default: {
    selectedInterval: config.defaultTimelineWindow.selectedInterval,
    timelineInterval: config.defaultTimelineWindow.timelineInterval,
  } as Timeline,
});
