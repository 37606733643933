import IconComponent, {IconProps} from './IconComponent';

export default function GhostIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: '#E1B6FF', fontSize: 48}} className={className}>
      <svg
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="GhostIcon"
      >
        <path
          d="M24 4C14.06 4 6 12.06 6 22V44L12 38L18 44L24 38L30 44L36 38L42 44V22C42 12.06 33.94 4 24 4ZM38 34.34L36 32.34L33.18 35.18L30 38.34L26.82 35.18L24 32.34L21.18 35.18L18 38.34L14.82 35.18L12 32.34L10 34.34V22C10 14.28 16.28 8 24 8C31.72 8 38 14.28 38 22V34.34ZM22 20C22 22.22 20.22 24 18 24C15.78 24 14 22.22 14 20C14 17.78 15.8 16 18 16C20.2 16 22 17.8 22 20ZM34 20C34 22.22 32.22 24 30 24C27.78 24 26 22.22 26 20C26 17.78 27.8 16 30 16C32.2 16 34 17.8 34 20Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
