import {useState} from 'react';
import useSaveFeedback from '../../../../../helpers/hooks/useSaveFeedback';
import TextInputField from '../../../../Katana/components/TextInputField/TextInputField';

interface Props {
  name: string;
  anomalyId: string;
}

export default function Name(props: Props) {
  const {name, anomalyId} = props;
  const [nameText, setNameText] = useState(name);
  const saveFeedback = useSaveFeedback();

  const submitForm = (input: string) => {
    setNameText(input);
    if (input !== nameText) {
      saveFeedback({anomaly_id: anomalyId, name: input}, true);
    }
  };

  return <TextInputField inputValue={nameText} onSubmit={submitForm} label="Name" maxWidth={400} />;
}
