import classnames from 'classnames';

interface UserInfoProps {
  userName: string;
  email: string;
  size?: 'small' | 'medium';
  onClick?: () => void;
  className?: string;
}
export default function UserInfo({
  userName,
  email,
  className,
  onClick,
  size = 'medium',
}: UserInfoProps) {
  const initials = (userName || '')
    .split(' ')
    .map(t => t[0])
    .join('');

  return (
    <div
      className={classnames('flex items-center gap-1 rounded-full', className, {
        'cursor-pointer hover:bg-dark-surface-bright': !!onClick,
      })}
      onClick={onClick}
    >
      {/*TODO Once Tailwind is set up with MUI, switch to MUI Avatar*/}
      <div
        className={classnames(
          'flex h-10 w-10 select-none items-center justify-center rounded-full bg-blue-400 text-center font-title font-semibold leading-6 tracking-[0.15px] text-dark-on-surface',
          {
            'h-8 w-8': size === 'small',
            'text-sm': size === 'small',
          }
        )}
      >
        {initials}
      </div>
      <div className="flex-col px-4">
        <div
          className={classnames('text-title-medium', {
            'text-sm': size === 'small',
          })}
        >
          {userName}
        </div>
        <div
          className={classnames('text-body-small text-dark-on-surface-variant', {
            'text-xs': size === 'small',
          })}
        >
          {email}
        </div>
      </div>
    </div>
  );
}
