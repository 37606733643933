import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import envConfig from '../config/env.config';
import {User} from 'oidc-client-ts';
import {authStorage, CheckSession} from '../auth/AuthProviderWrapper';

function getUser() {
  const oidcStorage = authStorage.getItem(
    `oidc.user:${envConfig.oidc.url}:${envConfig.oidc.clientId}`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
}

function makeRequestHandler(
  axiosInstance: AxiosInstance
): <T>(config: AxiosRequestConfig) => Promise<AxiosResponse<T>> {
  return <T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    const source = Axios.CancelToken.source();
    return axiosInstance({...config, cancelToken: source.token}).then(res => res);
  };
}

function withInterceptors(instance: AxiosInstance) {
  instance.interceptors.request.use(config => {
    const baseURL = config.baseURL;
    const path = config.url || '';

    /* eslint-disable no-console */
    if (process.env.NODE_ENV === 'development') {
      console.log(`${path}: Beginning API query`);
      console.log(`${path}: URL: `, `${baseURL}${path}`);
      console.log(`${path}: Payload: `, config.data || config.params);
    }
    /* eslint-enable */

    return {
      ...config,
      baseURL,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${getUser()?.access_token}`,
      },
    } as InternalAxiosRequestConfig;
  });

  instance.interceptors.response.use(
    axiosResponse => {
      const path = axiosResponse.config.url || '';

      /* eslint-disable no-console */
      if (process.env.NODE_ENV === 'development') {
        const queryTime = axiosResponse.headers?.['x-process-time'];
        if (queryTime) {
          console.log(
            `${path}: Request executed in ${(parseFloat(queryTime) ?? 0).toFixed(
              2
            )} seconds. More info`,
            axiosResponse.data.info
          );
        }
        if (Array.isArray(axiosResponse.data?.result)) {
          console.log(`${path}: ${axiosResponse.data?.result?.length} records returned`);
        }
        console.log(
          `${path}: First record: `,
          axiosResponse.data?.result?.[0] ?? axiosResponse.data?.result
        );
      }
      /* eslint-enable */

      return {
        ...axiosResponse,
      };
    },
    error => {
      const errString = error.toString();
      if (errString.includes('500')) {
        console.error(`Error making request to API`, error);
      }
      if (errString.includes('401')) {
        console.error('Authentication failed', error);
        CheckSession();
      }
    }
  );

  return instance;
}

const WEB_AXIOS_INSTANCE = withInterceptors(
  Axios.create({
    baseURL: `${envConfig.api.url}`,
  })
);
const webHandler = makeRequestHandler(WEB_AXIOS_INSTANCE);
export const webRequestHandler = <T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return webHandler(config);
};

const SENSEI_AXIOS_INSTANCE = withInterceptors(
  Axios.create({
    baseURL: `${envConfig.senseiApi.url}`,
  })
);
const senseiHandler = makeRequestHandler(SENSEI_AXIOS_INSTANCE);
export const senseiRequestHandler = <T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return senseiHandler(config);
};

const FP_AXIOS_INSTANCE = withInterceptors(
  Axios.create({
    baseURL: `${envConfig.fpApi.url}`,
  })
);
const fingerprintHandler = makeRequestHandler(FP_AXIOS_INSTANCE);
export const fingerprintRequestHandler = <T>(
  config: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return fingerprintHandler(config);
};
