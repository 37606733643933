import {
  useLatencyChartAnomalies,
  useSelectedAnomalyLatencyBoxplotData,
} from '../../../../data/Latency';
import {DateTime} from 'luxon';
import {binnedTimestampFormat} from '../../../../constants/timeFormats';
import PerformanceMetricsChart from '../../common/PerformanceMetricsChartWrapper/components/PerformanceMetricsChart';
import {
  LatencyChartLabelsByProperty,
  LatencyChartTension,
} from '../../../../constants/LatencyChartLabels';
import {useCallback} from 'react';
import {useRecoilValue} from 'recoil';
import {selectedAnomalyDataState} from '../../../../atoms/selectedAnomaly';
import {NumericSummary, SummaryLatency} from '../../../../openapi-schema/schemaTS';
import {ChartData, ChartDataset} from 'chart.js';

interface FormattedDataByStats {
  med: {
    x: number;
    y: number;
  }[];
  max: {
    x: number;
    y: number;
  }[];
  min: {
    x: number;
    y: number;
  }[];
}

const defaultDatasetOptions = {
  pointRadius: 0,
  hitRadius: 6,
  hoverRadius: 10,
  hoverBorderWidth: 2,
  pointHoverBorderColor: 'white',
  tension: LatencyChartTension,
  hidden: false,
  yAxisID: 'percentage_left',
};

const minMaxDatasetOptions = {
  hitRadius: 0,
  borderWidth: 0,
};

const getColorProperties = (color: string) => {
  return {
    borderColor: color,
    backgroundColor: color,
    pointHoverBackgroundColor: color,
  };
};

export default function LatencyChart() {
  const query = useSelectedAnomalyLatencyBoxplotData();
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);
  const {data: anomalies} = useLatencyChartAnomalies();
  const {data: APIdata} = query;

  const formatData = useCallback(
    (property: keyof SummaryLatency, stat: keyof NumericSummary) => {
      if (!APIdata?.length) {
        return [];
      }
      return APIdata.map(element => {
        return {
          x: DateTime.fromFormat(element.bin_key, binnedTimestampFormat, {
            zone: 'UTC',
          }).toMillis(),
          y: (element?.[property] as NumericSummary)[stat],
        };
      });
    },
    [APIdata]
  );

  const formatDataByStats = (property: keyof SummaryLatency) => {
    return {
      med: formatData(property, 'median'),
      max: formatData(property, 'max'),
      min: formatData(property, 'min'),
    };
  };

  const lineChartWithVariabilityBands = (
    data: FormattedDataByStats,
    color: string,
    property: {
      med: string;
      min: string;
      max: string;
    },
    extraOptions?: Partial<ChartDataset<'line'>>
  ) => {
    return [
      {
        ...defaultDatasetOptions,
        ...getColorProperties(`rgba(${color}, 1)`),
        ...extraOptions,
        label: property.med,
        data: data.med,
      },
      {
        ...defaultDatasetOptions,
        ...minMaxDatasetOptions,
        ...getColorProperties(`rgba(${color}, 0.4)`),
        ...extraOptions,
        label: property.max,
        data: data.max,
      },
      {
        ...defaultDatasetOptions,
        ...minMaxDatasetOptions,
        ...getColorProperties(`rgba(${color}, 0.4)`),
        ...extraOptions,
        label: property.min,
        data: data.min,
        fill: '-1',
      },
    ];
  };

  const clientRetransmissionData = formatDataByStats('client_retransmission_percent');
  const serverRetransmissionData = formatDataByStats('server_retransmission_percent');
  const roundTripData = formatDataByStats('avg_network_rt_response_time');

  const data: ChartData<'line'> = {
    datasets: [
      ...lineChartWithVariabilityBands(
        clientRetransmissionData,
        '16, 170, 204',
        LatencyChartLabelsByProperty.clientRetransmission
      ),
      ...lineChartWithVariabilityBands(
        serverRetransmissionData,
        '237, 106, 161',
        LatencyChartLabelsByProperty.serverRetransmission
      ),
      ...lineChartWithVariabilityBands(
        roundTripData,
        '158, 61, 255',
        LatencyChartLabelsByProperty.roundTrip,
        {
          yAxisID: 'ms_right',
          hidden: true,
        }
      ),
    ],
  };

  const chartMinMax = {
    min: clientRetransmissionData.med[0]?.x,
    max: clientRetransmissionData.med[clientRetransmissionData.med.length - 1]?.x,
  };

  return (
    <PerformanceMetricsChart
      query={query}
      data={data}
      anomalies={selectedAnomalyData ? anomalies?.concat(selectedAnomalyData) : anomalies}
      chartMinMax={chartMinMax}
    />
  );
}
