import {useSetRecoilState} from 'recoil';
import styled from 'styled-components';
import {selectedWhatIfInterfaceState} from '../../../../atoms/selectedWhatIfInterface';
import Dropdown from '../../../common/Dropdown/Dropdown';
import {TopologyEdgeData} from '../../../../data/types';

const Container = styled.div``;

interface Props {
  selectedTopologyElement: TopologyEdgeData;
}

export default function InterfaceDropdown({selectedTopologyElement}: Props) {
  const setSelectedWhatIfInterface = useSetRecoilState(selectedWhatIfInterfaceState);

  const interface1 = {
    int_name: selectedTopologyElement?.source_int_name,
    node_name: selectedTopologyElement?.source_node_name,
  };

  const interface2 = {
    int_name: selectedTopologyElement?.target_int_name,
    node_name: selectedTopologyElement?.target_node_name,
  };

  const dropdownOptions = [
    `${interface1.node_name} - ${interface1.int_name}`,
    `${interface2.node_name} - ${interface2.int_name}`,
  ];

  return (
    <Container>
      <Dropdown
        dropdownOptions={dropdownOptions}
        onChange={(value: string) =>
          setSelectedWhatIfInterface(value === dropdownOptions[0] ? interface1 : interface2)
        }
        selectedValue={dropdownOptions[0]}
      />
    </Container>
  );
}
