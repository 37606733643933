import {useMemo, useEffect, useRef, useState} from 'react';
import TableSortArrow from '../../../common/TableSortArrow/TableSortArrow';
import {formatLog, FormattedLogTemplate} from '../../../../utils/formatLogs';
import {useRecoilState, useRecoilValue} from 'recoil';
import Pagination from '../../../common/Pagination/Pagination';
import {timelineState} from '../../../../atoms/dashboard';
import {isEmpty} from 'lodash';
import {logTableSortingState} from '../../../../atoms/logTableSorting';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {convertSortToApiSchema} from '../../../../utils/convertSortToApiSchema';
import {convertSortToReactTableSchema} from '../../../../utils/convertSortToReactTableSchema';
import {scrollToTop} from '../../../../utils/scrollToTop';
import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';
import {
  Container,
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TableRow,
  TableData,
} from 'components/common/TableElements/TableElements';
import Timestamp from 'components/common/Timestamp';
import {pageSizeOptionsLarge} from 'constants/PageSizeOptions';
import {selectedWordCloudWordsState} from 'atoms/selectedWordCloudWords';
import {useLogSignsData, useLogSignsDataCount} from '../../../../data/HardwareMonitoring';
import {hardwareCatalogSelectedDeviceState} from '../../../../atoms/hardwareCatalogSelectedDevice';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const columnHelper = createColumnHelper<FormattedLogTemplate>();

interface Props {
  pageSizeOptions?: number[];
  template_id?: string;
  template_version?: number;
  showEventId?: boolean;
}

export default function LogSignsTable({pageSizeOptions = pageSizeOptionsLarge}: Props) {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const timeline = useRecoilValue(timelineState);
  const [logTableSorting, setLogTableSorting] = useRecoilState(logTableSortingState);
  const selectedWordcloudWords = useRecoilValue(selectedWordCloudWordsState);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const selectedDeviceId = useRecoilValue(hardwareCatalogSelectedDeviceState);
  const query = useLogSignsData(selectedDeviceId, APIPageIndex, APIPageSize, logTableSorting);
  const {data: APIdata} = query;
  const {data: logCount} = useLogSignsDataCount(selectedDeviceId);

  const data = useMemo(() => formatLog(APIdata), [APIdata]);

  const columns = [
    columnHelper.accessor('timestamp', {
      header: () => (
        <TitleTooltip
          header="Timestamp"
          mainContent={<span>Timestamp</span>}
          tooltipContent="The date and time the log message was generated by the device or host."
          maxWidth="300px"
        />
      ),
      size: 1.3,
      cell: props => <Timestamp timestamp={props.getValue()} />,
    }),
    columnHelper.accessor('formatted_template_literal', {
      header: () => (
        <TitleTooltip
          header="Log Message"
          mainContent={<span>Log Message</span>}
          tooltipContent="The content of the log message with highlighted sections identifying different tokens in the message template."
          maxWidth="300px"
        />
      ),
      size: 6,
      enableSorting: false,
      cell: props => props.getValue(),
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(logTableSorting),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
    };
  }, [logTableSorting, pageSizeOptions]);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    pageCount: Math.ceil((logCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableHiding: true,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  useEffect(() => {
    setPageIndex(0);
  }, [pageSize, timeline, sorting, setPageIndex, selectedWordcloudWords, selectedDeviceId]);

  useEffect(() => {
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
  }, [pageIndex, pageSize, setAPIPageIndex, setAPIPageSize]);

  useEffect(() => {
    const replacements = {};
    const newSortBy = convertSortToApiSchema(sorting, replacements);

    setLogTableSorting(newSortBy);
  }, [setLogTableSorting, sorting]);

  return (
    <Container>
      <Table onClick={e => e.stopPropagation()}>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={query}
            isEmpty={isEmpty(data)}
            emptyMessage={
              isEmpty(selectedDeviceId)
                ? 'Select a device to get started'
                : 'No logs for this device in the time range'
            }
          >
            {getRowModel().rows.map(row => (
              <TableRow key={row.id} isHighlighted={Boolean(row.original.observed)} noSelect>
                {row.getVisibleCells().map(cell => (
                  <TableData
                    key={cell.id}
                    style={{...getCellStyles(cell)}}
                    wrapText={cell.column.columnDef.header === 'Log Message'}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </Container>
  );
}
