import {WhatIfNodeMetricsKeys, WhatIfTCAM} from 'constants/WhatIfMetrics';
import {
  NumericSummary,
  SummaryInterfaceUtilization,
  SummaryNodeTcamUsage,
} from 'openapi-schema/schemaTS';

export const getMaxScaledEdgeValue = (
  elementData: {
    int1: SummaryInterfaceUtilization | undefined;
    int2: SummaryInterfaceUtilization | undefined;
  },
  statToggle: string,
  sliderValue: number
): number | undefined => {
  if (elementData.int1 === undefined && elementData.int2 === undefined) return undefined;

  const maxUtilization = Math.max(
    elementData.int1?.input_utilization?.[statToggle as keyof NumericSummary] || 0,
    elementData.int1?.output_utilization?.[statToggle as keyof NumericSummary] || 0,
    elementData.int2?.input_utilization?.[statToggle as keyof NumericSummary] || 0,
    elementData.int2?.output_utilization?.[statToggle as keyof NumericSummary] || 0
  );

  return scaleValue(maxUtilization, sliderValue);
};

export const getMaxScaledNodeValue = (
  elementData: any,
  statToggle: string,
  sliderValue: number,
  selectedMetric: string
): number | undefined => {
  if (!elementData) return undefined;

  if (selectedMetric === WhatIfNodeMetricsKeys[WhatIfTCAM]) {
    return getMaxScaledTCAMValue(elementData, statToggle as keyof NumericSummary, sliderValue);
  }

  return scaleValue(elementData[selectedMetric]?.[statToggle], sliderValue);
};

export const getMaxScaledTCAMValue = (
  nodeData: SummaryNodeTcamUsage,
  statToggle: keyof NumericSummary,
  sliderValue: number
): number => {
  const maxMetricValue = Math.max(
    ...nodeData.metrics.map(metric => metric.numeric_summary[statToggle])
  );
  const scaledMaxMetricValue = scaleValue(maxMetricValue, sliderValue);

  return scaledMaxMetricValue;
};

export const scaleValue = (value: number, factor: number): number => value + (value * factor) / 100;
