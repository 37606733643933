import {TooltipItem} from 'chart.js';
import {DateTime} from 'luxon';
import {dateFull} from '../constants/timeFormats';

export const getChartTooltipContent = (context: TooltipItem<'line'>, selectedTimezone: string) => {
  const label = context.dataset.label;
  const timestamp = context.parsed.x;

  return `${label}: ${Number(context.formattedValue.replace(',', '')).toFixed(
    1
  )}   ${DateTime.fromMillis(timestamp, {zone: 'UTC'})
    .setZone(selectedTimezone)
    .toFormat(dateFull)}`;
};
