import Button from 'components/Katana/components/Button/Button';
import FilterChip from 'components/Katana/components/Chips/FilterChip/FilterChip';
import {SearchBarMenuComponentProps} from 'components/Katana/components/SearchBar/SearchBar';
import {
  Filters,
  FiltersData,
} from 'components/Katana/patterns/TableSearchWithFilters/TableSearchWithFilters';
import {useState} from 'react';

interface Props extends SearchBarMenuComponentProps {
  filterValues: Filters;
  setFilterValues: (filters: Filters) => void;
  defaultFilters: Filters;
  filtersData: FiltersData;
}

export default function FiltersMenu({
  closeMenu,
  filterValues,
  setFilterValues,
  filtersData,
  defaultFilters,
}: Props) {
  const [filters, setFilters] = useState(filterValues);

  const clearFilters = () => {
    setFilters(defaultFilters);
  };

  const applyFilters = () => {
    setFilterValues(filters);
    closeMenu();
  };

  const onChipClick = (category: string, filter: string, isSelected: boolean) => {
    setFilters({
      ...filters,
      [category]: {
        ...filters[category],
        [filter]: !isSelected,
      },
    });
  };

  const filtersCount = Object.keys(filters).reduce((prev, currVal) => {
    const count = Object.values(filters[currVal]).filter(value => value).length;
    return prev + count;
  }, 0);

  return (
    <div className="flex flex-col gap-3">
      {filtersData.map(filterGroup => (
        <div key={filterGroup[0].category} className="flex gap-8">
          {filterGroup.map(group => (
            <div key={group.category} className="flex flex-col gap-2">
              <span className="text-label-large text-white">{group.category}</span>
              <div className="flex flex-wrap gap-2">
                {Object.keys(defaultFilters[group.category]).map(filter => {
                  const isSelected = filters[group.category][filter];

                  return (
                    <FilterChip
                      key={filter}
                      label={filter}
                      icon={group.filterIconFunction(filter)}
                      selected={isSelected}
                      onClick={() => onChipClick(group.category, filter, isSelected)}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      ))}
      <div className="flex items-center">
        <span className="text-label-medium text-white">
          {filtersCount} filter{filtersCount === 1 ? '' : 's'} selected
        </span>
        <div className="ml-auto flex gap-4">
          <Button label="Clear Filters" variant="text" onClick={clearFilters} />
          <Button label="Apply" onClick={applyFilters} />
        </div>
      </div>
    </div>
  );
}
