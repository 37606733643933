import styled from 'styled-components';
import Tooltip from '../../../Katana/components/Tooltip/Tooltip';
import {DateTime} from 'luxon';
import {selectedTimezoneState} from '../../../../atoms/selectedTimezone';
import {useRecoilValue} from 'recoil';
import {monthDayYear} from '../../../../constants/timeFormats';
import PersonIcon from '@mui/icons-material/Person';
import {HotlAnomaly} from '../../../../openapi-schema/schemaTS';

interface IconProps {
  opacity: number;
}

const IconContainer = styled.div<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.opacity}%;
  color: white;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const UserDateWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const Text = styled.p`
  b {
    text-transform: capitalize;
  }
`;

const ChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const ChangeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  width: 70px;
`;

interface Props {
  anomaly: HotlAnomaly;
}

export default function Feedback({anomaly}: Props) {
  const feedback = anomaly.feedback;
  const showPriority =
    feedback?.priority_classification &&
    anomaly.system_priority_classification !== feedback.priority_classification;
  const showCategory = feedback?.category && anomaly.system_category !== feedback.category;
  const showUserFeedback = showPriority || showCategory;
  const selectedTimezone = useRecoilValue(selectedTimezoneState);

  if (!showUserFeedback) {
    return (
      <IconContainer opacity={20}>
        <PersonIcon sx={{fontSize: '18px'}} />
      </IconContainer>
    );
  } else {
    return (
      <Tooltip
        tooltipContent={
          <TooltipContainer>
            <UserDateWrapper>
              <Text>User Feedback Provided</Text>
              <Text>
                {DateTime.fromISO(feedback.timestamp, {zone: 'UTC'})
                  .setZone(selectedTimezone)
                  .toFormat(monthDayYear)}
              </Text>
            </UserDateWrapper>
            <ChangeContainer>
              {showPriority && (
                <ChangeWrapper>
                  <Title>Priority</Title>
                  <Text>
                    Changed <b>{anomaly.system_priority_classification}</b> to{' '}
                    <b>{feedback.priority_classification}</b>
                  </Text>
                </ChangeWrapper>
              )}
              {showCategory && (
                <ChangeWrapper>
                  <Title>Category</Title>
                  <Text>
                    Changed <b>{anomaly.system_category}</b> to <b>{feedback.category}</b>
                  </Text>
                </ChangeWrapper>
              )}
            </ChangeContainer>
          </TooltipContainer>
        }
        mainContent={
          <IconContainer opacity={100}>
            <PersonIcon sx={{fontSize: '18px'}} />
          </IconContainer>
        }
      />
    );
  }
}
