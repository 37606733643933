import {useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {selectedAnomalyIdState} from '../../../../atoms/selectedAnomaly';
import {useAnomalyCount, useDCAnomalyList} from '../../../../data/Anomalies';
import TableSortArrow from '../../../common/TableSortArrow/TableSortArrow';
import TypeData from './TypeData';
import PriorityData from './PriorityData';
import Timestamp from '../../../common/Timestamp';
import Indicators from './Indicators';
import {
  anomalyTableSortingDefaultState,
  anomalyTableSortingState,
} from '../../../../atoms/AnomalyTableSorting';
import Pagination from 'components/Katana/patterns/Pagination/Pagination';
import {isEmpty, startCase} from 'lodash';
import {timelineState} from '../../../../atoms/dashboard';
import {anomalySelectionFiltersState} from '../../../../atoms/anomalySelectionFilters';
import {pageSizeOptionsLarge} from '../../../../constants/PageSizeOptions';
import TableRow from './TableRow';
import useTopologyActions from '../../../../helpers/hooks/useTopologyActions';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {convertSortToApiSchema} from '../../../../utils/convertSortToApiSchema';
import {convertSortToReactTableSchema} from '../../../../utils/convertSortToReactTableSchema';
import AffectedResourceWithTooltip from './AffectedResourceWithTooltip';
import {EmptyObservationsFiltersMessage} from '../../../../constants/queryLifecycleMessages';
import {HotlAnomaly} from '../../../../openapi-schema/schemaTS';
import {UseQueryResult} from 'react-query';
import {scrollToTop} from '../../../../utils/scrollToTop';
import {AnomalyTypeBucket} from '../../../../openapi-schema/schemaTS';
import {
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
} from 'components/common/TableElements/TableElements';
import {affectedResourcesSearchState} from 'atoms/affectedResourcesSearch';
import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';
import {useTopologyData} from 'data/Topology';
import {anomalyFilterPopupOpenState} from 'atoms/AnomalyFilterPopup';
import {createColumnHelper, flexRender, getCoreRowModel} from '@tanstack/react-table';
import {useReactTable} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';

const pageSizeOptions = pageSizeOptionsLarge;

const columnHelper = createColumnHelper<HotlAnomaly>();

export default function AnomaliesTable() {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const {data: APIdata, isLoading} = useDCAnomalyList(APIPageIndex, APIPageSize);
  const {data: anomalyCount} = useAnomalyCount();
  const {isLoading: topologyIsLoading} = useTopologyData();
  const selectedAnomalyID = useRecoilValue(selectedAnomalyIdState);
  const setAnomalyTableSorting = useSetRecoilState(anomalyTableSortingState);
  const setAnomalyFilterPopupOpen = useSetRecoilState(anomalyFilterPopupOpenState);
  const anomalySelectionFilters = useRecoilValue(anomalySelectionFiltersState);
  const timeline = useRecoilValue(timelineState);
  const affectedResourcesSearch = useRecoilValue(affectedResourcesSearchState);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const {unselectElement} = useTopologyActions();

  const data = useMemo(() => (APIdata ? [...APIdata] : []), [APIdata]);

  const columns = [
    columnHelper.accessor('anomaly_type', {
      header: () => (
        <TitleTooltip
          header="Observation Type"
          mainContent={<span>Type</span>}
          tooltipContent={
            <span>
              The type of event captured:
              <ul style={{marginLeft: '16px', listStyleType: 'disc'}}>
                {Object.values(AnomalyTypeBucket).map((type, idx) => {
                  return <li key={idx}>{type}</li>;
                })}
              </ul>
            </span>
          }
        />
      ),
      size: 1,
      enableSorting: false,
      cell: props => <TypeData type={props.getValue()} />,
    }),
    columnHelper.accessor('affected_resources', {
      header: () => (
        <TitleTooltip
          header="Affected Resources"
          mainContent={<span>Affected</span>}
          tooltipContent="The device(s) or host(s) affected by the event."
        />
      ),
      size: 1.25,
      cell: props => (
        <AffectedResourceWithTooltip affectedResources={props.getValue()} enhancedTooltip={true} />
      ),
    }),
    columnHelper.accessor('priority_classification', {
      header: () => (
        <TitleTooltip
          header="Priority"
          mainContent={<span>Priority</span>}
          tooltipContent="The priority of the event based on its expected impact. This can be updated by a
            user to teach the system how to prioritize similar events in the future."
        />
      ),
      size: 0.8,
      cell: props => <PriorityData priority={props.getValue()} />,
    }),
    columnHelper.accessor('anomaly_classification', {
      header: () => (
        <TitleTooltip
          header="Class"
          mainContent={<span>Class</span>}
          tooltipContent="Observations may be classified as Anomalies, which are uncommon problems detected on
        the host system; or Routine Issues, which have been seen to occur with relative
        frequency."
        />
      ),
      size: 0.8,
      cell: props => <p>{startCase(props.getValue())}</p>,
    }),
    columnHelper.accessor('category', {
      header: () => (
        <TitleTooltip
          header="Category"
          mainContent={<span>Category</span>}
          tooltipContent="The detected or assigned category of event."
        />
      ),
      size: 0.75,
      enableSorting: false,
    }),
    columnHelper.accessor('min_timestamp', {
      header: () => (
        <TitleTooltip
          header="Timestamp"
          mainContent={<span>Timestamp</span>}
          tooltipContent="The time that this event was detected."
        />
      ),
      size: 1.5,
      cell: props => <Timestamp timestamp={props.getValue()} />,
    }),
    columnHelper.display({
      header: 'Indicators',
      size: 0.8,
      cell: props => <Indicators observation={props.row.original} />,
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(anomalyTableSortingDefaultState),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
    };
  }, []);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
    resetRowSelection,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    enableRowSelection: true,
    pageCount: Math.ceil((anomalyCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableMultiRowSelection: false,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  useEffect(() => {
    // updates pagination values for query
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
  }, [pageIndex, pageSize]);

  useEffect(() => {
    // formats sorting object for query
    const replacements = {};
    const newSortBy = convertSortToApiSchema(sorting, replacements);
    setAnomalyTableSorting(newSortBy);
  }, [setAnomalyTableSorting, sorting]);

  useEffect(() => {
    // resets table when any change causes query to run
    setPageIndex(0);
    unselectElement();
    resetRowSelection(true);
  }, [
    sorting,
    pageSize,
    anomalySelectionFilters,
    timeline,
    affectedResourcesSearch,
    setPageIndex,
    unselectElement,
    resetRowSelection,
  ]);

  useEffect(() => {
    // resets table when page index is changed
    unselectElement();
    resetRowSelection(true);
  }, [pageIndex, resetRowSelection, unselectElement]);

  useEffect(() => {
    // unselects all rows if there is no selected observation
    if (!selectedAnomalyID) {
      resetRowSelection(true);
    }
  }, [selectedAnomalyID, resetRowSelection]);

  return (
    <>
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={
              {
                ...useDCAnomalyList(APIPageIndex, APIPageSize),
                isLoading: isLoading || topologyIsLoading,
              } as UseQueryResult<HotlAnomaly[], unknown>
            }
            isEmpty={isEmpty(data)}
            emptyMessage={EmptyObservationsFiltersMessage}
            actionButtonText="View Filters"
            onActionButtonClick={() => setAnomalyFilterPopupOpen(true)}
          >
            {getRowModel().rows.map(row => (
              <TableRow key={row.id} row={row} />
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        setPageSize={setPageSize}
        nextPage={nextPage}
        previousPage={previousPage}
        canPreviousPage={getCanPreviousPage()}
        canNextPage={getCanNextPage()}
        pageIndex={pageIndex}
        gotoPage={setPageIndex}
        pageSize={pageSize}
        pageCount={getPageCount()}
        pageSizeOptions={pageSizeOptions}
        rowCount={anomalyCount?.value || 0}
      />
    </>
  );
}
