import styled from 'styled-components';

const ToolTipHeading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
`;

const ToolTipValue = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
`;

const ToolTipPairContainer = styled.div``;

interface Props {
  heading: string;
  value: string;
}
export default function TooltipDataEntry({heading, value}: Props) {
  return (
    <ToolTipPairContainer>
      <ToolTipHeading>{heading}</ToolTipHeading>
      <ToolTipValue>{value}</ToolTipValue>
    </ToolTipPairContainer>
  );
}
