import {selector} from 'recoil';
import {affectedResourcesSearchState} from '../atoms/affectedResourcesSearch';
import {trim} from 'lodash';

export const tokenizedSearch = selector({
  key: 'tokenizedAffectedResourceSearch',
  get: ({get}) => {
    const searchString = get(affectedResourcesSearchState);

    return searchString.split(',').map(trim);
  },
});
