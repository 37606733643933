import useURLSynchronizedTimeline from 'helpers/hooks/useURLSynchronizedTimeline';
import {withTracingErrorBoundary} from 'tracing/errorReporting';
import GoldenLayout from '../common/GoldenLayout/GoldenLayout';
import {DeviceHealthOverview} from './DeviceHealthOverview/DeviceHealthOverview';
import HardwareCatalogPage from './HardwareCatalog/HardwareCatalogPage';
import LogsSnmpTable from './LogsSnmpTable/LogsSnmpTable';

function HardwareMonitoring() {
  useURLSynchronizedTimeline();

  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              type: 'column',
              content: [
                {
                  component: () => <HardwareCatalogPage />,
                },
              ],
            },
            {
              type: 'column',
              content: [
                {
                  component: () => <LogsSnmpTable />,
                },
                {
                  component: () => <DeviceHealthOverview />,
                },
              ],
            },
          ],
        },
      ]}
    />
  );
}

export default withTracingErrorBoundary(HardwareMonitoring);
