import IconComponent, {IconProps} from '../IconComponent';

export default function UnreadStatusIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="UnreadStatusIcon"
      >
        <path
          d="M12.2501 4.66669C12.7142 4.66669 13.1593 4.48231 13.4875 4.15412C13.8157 3.82594 14.0001 3.38082 14.0001 2.91669C14.0001 2.45256 13.8157 2.00744 13.4875 1.67925C13.1593 1.35106 12.7142 1.16669 12.2501 1.16669C11.786 1.16669 11.3408 1.35106 11.0126 1.67925C10.6845 2.00744 10.5001 2.45256 10.5001 2.91669C10.5001 3.38082 10.6845 3.82594 11.0126 4.15412C11.3408 4.48231 11.786 4.66669 12.2501 4.66669ZM3.06258 2.33335H9.68983C9.57119 2.85242 9.61335 3.3953 9.8107 3.88982C10.0081 4.38435 10.3512 4.80709 10.7947 5.10185L7.00008 7.08927L1.17608 4.03844C1.22143 3.58956 1.42538 3.17157 1.75128 2.85957C2.07717 2.54757 2.50365 2.36203 2.95408 2.33627L3.06258 2.33335ZM7.20308 7.97069L11.8872 5.5166C12.2025 5.56014 12.523 5.54651 12.8334 5.47635V9.77085C12.8334 10.2551 12.6482 10.721 12.3156 11.073C11.983 11.425 11.5284 11.6364 11.0449 11.6638L10.9376 11.6667H3.06258C2.57833 11.6667 2.11243 11.4814 1.76045 11.1489C1.40846 10.8163 1.19708 10.3617 1.16966 9.87819L1.16675 9.77085V5.02135L6.79708 7.97069C6.85972 8.0035 6.92937 8.02063 7.00008 8.02063C7.07079 8.02063 7.14044 8.0035 7.20308 7.97069Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
