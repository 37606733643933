import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {selectedFingerprintState} from '../../../atoms/selectedFingerprint';
import ComponentTitle from '../../common/ComponentTitle/ComponentTitle';
import FingerprintDefinition from '../../Katana/components/DefinitionTooltip/Definitions/FingerprintDefinition';
import ObservationsDefinition from '../../Katana/components/DefinitionTooltip/Definitions/ObservationsDefinition';
import NoResults from '../../common/NoResults/NoResults';
import FingerprintInfo from './components/FingerprintInfo';
import LogsAndScript from './components/LogsAndScript';
import MatchingObservationsTable from './components/MatchingObservationsTable';

const Container = styled.div`
  height: 100%;
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
`;

const ComponentTitleWrapper = styled.div`
  padding: 10px;
`;

export default function FingerprintDetails() {
  const selectedFingerprint = useRecoilValue(selectedFingerprintState);

  if (!selectedFingerprint) {
    return (
      <NoResults
        header="Select a Fingerprint"
        message={
          <span style={{display: 'block', textAlign: 'center'}}>
            The <FingerprintDefinition /> details will appear here. You can edit settings and
            inspect all matching <ObservationsDefinition />.
          </span>
        }
      />
    );
  }

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title={`${selectedFingerprint?.name ?? 'Fingerprint'} Details`} />
      </ComponentTitleWrapper>
      <FingerprintInfo />
      <LogsAndScript />
      <MatchingObservationsTable />
    </Container>
  );
}
