import {ChartOptions} from 'chart.js';
import {BoxAnnotationOptions} from 'chartjs-plugin-annotation';
import {max, maxBy} from 'lodash';
import {useMemo} from 'react';
import {Scatter} from 'react-chartjs-2';
import styled from 'styled-components';
import {
  chartHorizontalAxisOptions,
  chartHorizontalTickOptions,
  chartVerticalAxisOptions,
  chartVerticalAxisTickOptions,
  coreChartOptions,
} from '../../../../constants/chartOptions';
import {usePathDelay} from '../../../../data/PathChange';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import ErrorState from '../../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import PathDelayLegend from './PathDelayLegend';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: black;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 10px 12px 10px;
`;

const ChartWrapper = styled.div`
  flex: 1;
  min-height: 0;
  padding: 0 8px;
  position: relative;
`;

const Cover = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: black;
  top: 0;
  left: 0;
`;

const pointStyles = {
  pointRadius: 4,
  pointHitRadius: 4,
  pointHoverRadius: 4,
};

export default function PathDelayChart() {
  const {data: APIdata, isLoading, isError, refetch} = usePathDelay();

  const data = useMemo(() => {
    if (!APIdata)
      return {
        datasets: [],
      };

    const preferredHopData = {
      label: 'Typical Delay',
      data: APIdata?.preferred_hops.map(hop => {
        return {
          x: hop.hop_number,
          y: hop.avg_response_time,
        };
      }),
      ...pointStyles,
      pointBackgroundColor: 'rgba(174, 51, 247, 1)',
      pointHoverBackgroundColor: 'rgba(174, 51, 247, 1)',
    };

    const anomalousHopData = {
      label: 'Observed Delay',
      data: APIdata?.anomalous_hops.map(hop => {
        return {
          x: hop.hop_number,
          y: hop.avg_response_time,
        };
      }),
      ...pointStyles,
      pointBackgroundColor: 'rgba(24, 160, 251, 1)',
      pointHoverBackgroundColor: 'rgba(24, 160, 251, 1)',
    };

    return {
      datasets: [preferredHopData, anomalousHopData],
    };
  }, [APIdata]);

  const options: ChartOptions<'scatter'> = useMemo(() => {
    return {
      ...coreChartOptions,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        annotation: {
          annotations: APIdata?.anomalous_hops.map(hop => {
            return {
              type: 'box',
              xMin: hop.hop_number - 0.5,
              xMax: hop.hop_number + 0.5,
              yMin: 0,
              yMax: 10000000,
              backgroundColor: 'rgba(205, 62, 56, 0.35)',
              borderWidth: 0,
              adjustScaleRange: false,
            } as BoxAnnotationOptions;
          }),
        },
      },
      scales: {
        y: {
          ...chartVerticalAxisOptions,
          display: true,
          position: 'left',
          suggestedMax: APIdata
            ? Math.round(
                Number(
                  max([
                    maxBy(APIdata.preferred_hops, 'avg_response_time')?.avg_response_time,
                    maxBy(APIdata.anomalous_hops, 'avg_response_time')?.avg_response_time,
                  ])
                ) * 1.1
              )
            : 1,
          title: {
            display: true,
            text: 'Delay',
            color: 'white',
            padding: {
              bottom: 8,
            },
          },
          ticks: {
            ...chartVerticalAxisTickOptions,
            callback: value => `${value}ms`,
          },
        },
        x: {
          ...chartHorizontalAxisOptions,
          min: 0,
          max: APIdata?.preferred_hops.length ? APIdata?.preferred_hops.length : 1,
          grid: {
            drawOnChartArea: false,
            borderColor: 'rgba(116, 116, 116, 1)',
            tickLength: 4,
          },
          title: {
            display: true,
            text: 'Hops',
            color: 'white',
          },
          ticks: {
            ...chartHorizontalTickOptions,
            padding: 0,
          },
        },
      },
    };
  }, [APIdata]);

  return (
    <Container>
      <HeaderWrapper>
        <ComponentTitle title="Path Delay" />
      </HeaderWrapper>
      <ChartWrapper>
        <Scatter options={options} data={data} />
        {isLoading && (
          <Cover>
            <LoadingSpinner />
          </Cover>
        )}
        {isError && (
          <Cover>
            <ErrorState retry={refetch} />
          </Cover>
        )}
      </ChartWrapper>
      <PathDelayLegend />
    </Container>
  );
}
