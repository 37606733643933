import {useState, ChangeEvent} from 'react';
import {useRecoilValue} from 'recoil';
import {uploadedFileState} from '../../../../../atoms/UploadedFile';
import styled from 'styled-components';
import {BACKGROUND_COLOR_CONTENT_HEADER, BACKGROUND_CONTENT} from '../colors';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ScriptUploader from './ScriptUploader';
import ScriptContent from './ScriptContent';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import ScriptVariableDisplayDropdown from './ScriptVariableDisplayDropdown';
import useARActions from '../../../../../helpers/hooks/useARActions';
import ScriptVariable from './ScriptVariable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  background: ${BACKGROUND_COLOR_CONTENT_HEADER};
  padding: 4px 8px 4px 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 32px;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 14px;
  margin-right: auto;
`;

const Content = styled.div`
  height: 100%;
  overflow: auto;
`;

const UploadScriptButton = styled.label`
  display: flex;
  background: inherit;
  color: white;
  border: none;
  font-size: 14px;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;

const UploadScriptButtonText = styled.p``;

const FileContentWrapper = styled.div`
  height: max-content;
  min-height: 100%;
  min-width: 100%;
  width: max-content;
  background: ${BACKGROUND_CONTENT};
  padding: 7px 10px;
`;

export default function ScriptDisplay() {
  const file = useRecoilValue(uploadedFileState);
  const [loading, setLoading] = useState(false);
  const {uploadFile, setSnackbarFileUpload} = useARActions();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    uploadFile(e.target.files || [], setLoading)
      .then(result => setSnackbarFileUpload(result, undefined))
      .catch(error => setSnackbarFileUpload(undefined, error));
  };

  return (
    <Container>
      <Header>
        <Title>Script</Title>
        {file && (
          <>
            <UploadScriptButton htmlFor="file-upload">
              <FileUploadIcon />
              <UploadScriptButtonText>Upload Another Script</UploadScriptButtonText>
            </UploadScriptButton>
            <Input onChange={handleChange} id="file-upload" type="file" accept=".yml,.yaml" />
            <ScriptVariableDisplayDropdown />
          </>
        )}
      </Header>
      <Content>
        {(() => {
          if (loading) return <LoadingSpinner />;
          if (file)
            return (
              <FileContentWrapper>
                <ScriptContent fileContent={file.fileContent} injectedComponent={ScriptVariable} />
              </FileContentWrapper>
            );
          return <ScriptUploader setLoading={setLoading} />;
        })()}
      </Content>
    </Container>
  );
}
