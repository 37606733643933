import React, {useEffect, useMemo, useRef, useState} from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TableSortArrow from '../../../common/TableSortArrow/TableSortArrow';
import {isEmpty} from 'lodash';
import {
  Container,
  ComponentTitleWrapper,
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TableData,
  InnerTableRowWrapper,
  InnerTableDataWrapper,
  TableRow,
} from 'components/common/TableElements/TableElements';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import Pagination from '../../../common/Pagination/Pagination';
import {pageSizeOptionsMedium as pageSizeOptions} from '../../../../constants/PageSizeOptions';
import {timelineState} from '../../../../atoms/dashboard';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {
  defaultTemplateTableSorting,
  templateTableSortingState,
} from '../../../../atoms/logTableSorting';
import {convertSortToApiSchema} from '../../../../utils/convertSortToApiSchema';
import {convertSortToReactTableSchema} from '../../../../utils/convertSortToReactTableSchema';
import {EmptyTemplatesMessage} from '../../../../constants/queryLifecycleMessages';
import {AnomalyTemplate} from '../../../../openapi-schema/schemaTS';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import {scrollToTop} from '../../../../utils/scrollToTop';
import styled from 'styled-components';
import {LogTemplateCountQuery, LogTemplateListQuery} from 'data/types';
import {getScarcityCellText} from 'utils/getScarcityCellText';
import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';
import ScarcityTooltip from 'components/AnomalyDetail/common/LogsTable/ScarcityTooltip';
import InnerTable from './components/InnerTable';
import {selectedWordCloudWordsState} from 'atoms/selectedWordCloudWords';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {Row} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const IconWrapper = styled.div`
  display: grid;
  place-content: center;
`;

const TemplateWrapper = styled.p`
  white-space-collapse: preserve;
`;

const ScarcityId = 'Scarcity';
const ScarcitySort = 'explanatory_reasons.scarcity_details';

const columnHelper = createColumnHelper<AnomalyTemplate>();

interface Props {
  templatesListQuery: LogTemplateListQuery;
  templatesCountQuery: LogTemplateCountQuery;
}

export default function LogTemplatesTable({templatesListQuery, templatesCountQuery}: Props) {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const templatesListQueryResult = templatesListQuery(APIPageIndex, APIPageSize);
  const {data: APIdata} = templatesListQueryResult;
  const {data: templatesCount} = templatesCountQuery();
  const timeline = useRecoilValue(timelineState);
  const selectedWordcloudWords = useRecoilValue(selectedWordCloudWordsState);
  const setTemplatesTableSorting = useSetRecoilState(templateTableSortingState);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const columns = [
    columnHelper.display({
      header: undefined,
      id: 'expander',
      cell: props => (
        <span {...props.row.getToggleExpandedHandler()}>
          {props.row.getIsExpanded() ? (
            <IconWrapper>
              <ArrowDropDownIcon sx={{fontSize: '24px', color: 'white'}} />
            </IconWrapper>
          ) : (
            <IconWrapper>
              <ArrowRightIcon sx={{fontSize: '24px', color: 'rgba(152, 152, 152, 1)'}} />
            </IconWrapper>
          )}
        </span>
      ),
      size: 0.1,
    }),
    columnHelper.accessor('template_id', {
      header: 'ID',
      size: 2,
    }),
    columnHelper.accessor('template_version', {
      header: 'Version',
      size: 0.7,
    }),
    columnHelper.accessor(row => row.explanatory_reasons?.scarcity_details, {
      header: () => (
        <TitleTooltip
          header="Scarcity"
          mainContent={<span>Scarcity</span>}
          tooltipContent={<ScarcityTooltip />}
          maxWidth="300px"
        />
      ),
      id: ScarcityId,
      size: 0.9,
      cell: props => <p>{getScarcityCellText(props.getValue())}</p>,
    }),
    columnHelper.accessor('template_literal', {
      header: 'Template',
      size: 8,
      cell: props => <TemplateWrapper>{props.getValue()}</TemplateWrapper>,
    }),
    columnHelper.accessor('total_count', {
      header: 'Global\n30 Days',
      size: 1,
    }),
    columnHelper.accessor('host_count', {
      header: 'Host 30 Days',
      size: 1,
    }),
    columnHelper.accessor('log_count', {
      header: 'Observed\nLogs Count',
      size: 1.3,
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(defaultTemplateTableSorting),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
    };
  }, []);

  const data = useMemo(() => (APIdata ? APIdata : []), [APIdata]);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
    toggleAllRowsExpanded,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    pageCount: Math.ceil((templatesCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableHiding: true,
    enableExpanding: true,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  const handleRowClick = (row: Row<AnomalyTemplate>) => {
    toggleAllRowsExpanded(false);
    row.toggleExpanded(!row.getIsExpanded());
  };

  useEffect(() => {
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
  }, [pageIndex, pageSize]);

  useEffect(() => {
    setPageIndex(0);
  }, [timeline, pageSize, sorting, setPageIndex, selectedWordcloudWords]);

  useEffect(() => {
    const replacements = {[ScarcityId]: ScarcitySort};
    const newSortBy = convertSortToApiSchema(sorting, replacements);
    setTemplatesTableSorting(newSortBy);
  }, [sorting, setTemplatesTableSorting]);

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="Anomalous Log Templates" />
      </ComponentTitleWrapper>
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={templatesListQueryResult}
            isEmpty={isEmpty(data)}
            emptyMessage={EmptyTemplatesMessage}
          >
            {getRowModel().rows.map(row => (
              <React.Fragment key={row.id}>
                <TableRow
                  isOpened={row.getIsExpanded()}
                  isExpandable
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableData key={cell.id} style={{...getCellStyles(cell)}} wrapText>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableData>
                  ))}
                </TableRow>
                {row.getIsExpanded() && (
                  <InnerTableRowWrapper>
                    <InnerTableDataWrapper>
                      <InnerTable templateRow={row.original} />
                    </InnerTableDataWrapper>
                  </InnerTableRowWrapper>
                )}
              </React.Fragment>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </Container>
  );
}
