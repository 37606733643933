import {senseiChatActionsState, senseiChatSessionState} from 'atoms/sensei';
import Button from 'components/Katana/components/Button/Button';
import FormField from 'components/Katana/components/FormField/FormField';
import {useState} from 'react';
import {useRecoilValue} from 'recoil';
import {isUserQuestion} from '../../../helpers/senseiHelpers';

export default function ChatInputForm() {
  const senseiChatActions = useRecoilValue(senseiChatActionsState);
  const senseiChatMessages = useRecoilValue(senseiChatSessionState);
  const [input, setInput] = useState('');

  const isDisabled =
    senseiChatMessages.length === 0 ||
    isUserQuestion(senseiChatMessages[senseiChatMessages.length - 1]);

  const handleSubmit = () => {
    if (input === '') return;

    setInput('');
    senseiChatActions?.sendMessage({
      questions: [input],
      request_type: 'SenseiQuestionRequest',
    });
  };

  return (
    <form
      className="mt-auto flex w-full items-end gap-4 p-4 pt-0"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <FormField value={input} onChange={value => setInput(value)} disabled={isDisabled} />
      <Button label="Send" onClick={handleSubmit} disabled={isDisabled} />
    </form>
  );
}
