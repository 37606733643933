import {useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilValue} from 'recoil';
import TableSortArrow from '../../../common/TableSortArrow/TableSortArrow';
import Timestamp from '../../../common/Timestamp';
import Pagination from '../../../common/Pagination/Pagination';
import {isEmpty} from 'lodash';
import {timelineState} from 'atoms/dashboard';
import {anomalySelectionFiltersState} from 'atoms/anomalySelectionFilters';
import {pageSizeOptionsLarge} from 'constants/PageSizeOptions';
import TableRow from './TableRow';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {convertSortToApiSchema} from 'utils/convertSortToApiSchema';
import {convertSortToReactTableSchema} from 'utils/convertSortToReactTableSchema';
import {WselFlaggedUser} from '../../../../openapi-schema/schemaTS';
import {UseQueryResult} from 'react-query';
import {scrollToTop} from 'utils/scrollToTop';
import {
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TextWithTooltip,
} from 'components/common/TableElements/TableElements';
import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';
import {useSecurityFlaggedUsers, useSecurityFlaggedUsersCount} from 'data/Security';
import {
  securityAffectedResourcesSearchState,
  selectedSecurityAnomalyIdState,
} from 'atoms/securityState';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';

const pageSizeOptions = pageSizeOptionsLarge;
const userTableSortingDefaultState = [
  {
    name: 'timestamp',
    desc: true,
  },
];

const columnHelper = createColumnHelper<WselFlaggedUser>();

export default function FlaggedUsersTable() {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const [sortingState, setSortingState] = useState(userTableSortingDefaultState);

  const {data: APIdata, isLoading} = useSecurityFlaggedUsers(
    APIPageIndex,
    APIPageSize,
    sortingState
  );
  const {data: dataCount} = useSecurityFlaggedUsersCount();
  const selectedSecurityAnomalyID = useRecoilValue(selectedSecurityAnomalyIdState);
  const anomalySelectionFilters = useRecoilValue(anomalySelectionFiltersState);
  const timeline = useRecoilValue(timelineState);
  const affectedResourcesSearch = useRecoilValue(securityAffectedResourcesSearchState);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const data = useMemo(() => (APIdata ? [...APIdata] : []), [APIdata]);

  const columns = [
    columnHelper.accessor('user_name', {
      header: () => (
        <TitleTooltip
          header="User Name"
          mainContent={<span>User Name</span>}
          tooltipContent={<span>Windows Security Event User Name</span>}
        />
      ),
      size: 1.5,
    }),
    columnHelper.accessor('domain_name', {
      header: () => (
        <TitleTooltip
          header="Domain Name"
          mainContent={<span>Domain Name</span>}
          tooltipContent={<span>Windows User Domain Name</span>}
        />
      ),
      size: 1,
    }),
    columnHelper.accessor('last_event_id', {
      header: () => (
        <TitleTooltip
          header="Flagged Event ID"
          mainContent={<span>Flagged Event ID</span>}
          tooltipContent={<span>The most recent Windows Event ID which flagged this user</span>}
        />
      ),
      size: 1,
      cell: props => (
        <TextWithTooltip
          text={props.getValue()}
          tooltip={props.row.original.last_event_description}
        />
      ),
    }),
    columnHelper.accessor('timestamp', {
      header: () => (
        <TitleTooltip
          header="Timestamp"
          mainContent={<span>Timestamp</span>}
          tooltipContent="When this user was flagged"
        />
      ),
      size: 1,
      cell: props => <Timestamp timestamp={props.getValue()} />,
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(userTableSortingDefaultState),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
    };
  }, []);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
    resetRowSelection,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    enableRowSelection: true,
    pageCount: Math.ceil((dataCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableMultiRowSelection: false,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  useEffect(() => {
    // updates pagination values for query
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
  }, [pageIndex, pageSize]);

  useEffect(() => {
    // formats sorting object for query
    const replacements = {};
    const newSortBy = convertSortToApiSchema(sorting, replacements);
    setSortingState(newSortBy);
  }, [setSortingState, sorting]);

  useEffect(() => {
    // resets table when any change causes query to run
    setPageIndex(0);
    resetRowSelection(true);
  }, [
    sorting,
    pageSize,
    anomalySelectionFilters,
    timeline,
    affectedResourcesSearch,
    setPageIndex,
    resetRowSelection,
  ]);

  useEffect(() => {
    // resets table when page index is changed
    resetRowSelection(true);
  }, [pageIndex, resetRowSelection]);

  useEffect(() => {
    // unselects all rows if there is no selected observation
    if (!selectedSecurityAnomalyID) {
      resetRowSelection(true);
    }
  }, [selectedSecurityAnomalyID, resetRowSelection]);

  return (
    <>
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={
              {
                ...useSecurityFlaggedUsers(APIPageIndex, APIPageSize, sortingState),
                isLoading: isLoading,
              } as UseQueryResult<WselFlaggedUser[], unknown>
            }
            isEmpty={isEmpty(data)}
            emptyMessage={'Refine the time range to identify compromised users'}
          >
            {getRowModel().rows.map(row => (
              <TableRow row={row} key={row.id} />
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
}
