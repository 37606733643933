import styled from 'styled-components';

interface ContainerProps {
  live?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  height: 58px;
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;

  .react_time_range__time_range_container {
    height: 100%;
    background: rgba(35, 35, 35, 1);
    padding: 0;
    overflow: hidden;
    width: 100%;

    * {
      transform: none;
    }

    > div {
      height: 100%;
    }

    .react_time_range__rail__inner {
      background: rgba(23, 23, 23, 1);
      border: none;
    }

    .react_time_range__rail__outer {
      height: 100%;
    }

    .react_time_range__rail__inner {
      height: 100%;
    }

    .react_time_range__track {
      background-color: ${props =>
        props.live ? 'rgba(151, 37, 22, 0.6)' : 'rgba(0, 118, 255, 0.6)'} !important;
      border: none !important;
      height: 58px;
      border-right: ${props => (props.live ? '2px solid rgba(214, 50, 28, 1)' : 'none')} !important;
      transition: background-color 0.5s ease;
    }

    .react_time_range__handle_container {
      transform: translate(-50%, 50%);
      display: none;
    }

    .react_time_range__tick_label {
      color: white;
      font-size: 12px;
      font-weight: 500;
      margin-top: 42px;
      z-index: 10;
      pointer-events: none;
    }

    .react_time_range__tick_marker__large {
      margin-top: 28px;
    }

    .react_time_range__track__disabled {
      background-image: none;
      background: rgba(49, 49, 49, 0.7);
      border: none;
      border-left: ${props => (props.live ? 'none' : '2px solid rgba(130, 130, 130, 1)')};
      height: 60px;
    }
  }
`;
