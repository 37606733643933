import SelectionDashboard from './SelectionDashboard/Dashboard';
import useURLSynchronizedTimeline from '../../helpers/hooks/useURLSynchronizedTimeline';
import useURLSelectedDC from '../../helpers/hooks/useURLSelectedDC';
import {withTracingErrorBoundary} from '../../tracing/errorReporting';

function AnomalySelection() {
  useURLSynchronizedTimeline();
  useURLSelectedDC();

  return <SelectionDashboard />;
}

export default withTracingErrorBoundary(AnomalySelection);
