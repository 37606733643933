export const tryParseInvalidISO = (timestamp: string | null | undefined): string => {
  let notNullTimestamp = timestamp || '';
  const twoDigitRegex = /(?<=-|T|:)\d{1,2}/g;
  const matches = [...Array.from(notNullTimestamp.matchAll(twoDigitRegex))];

  let replacementsCount = 0;
  matches.forEach(number => {
    if (number[0].length < 2) {
      const index = number.index;
      if (index) {
        notNullTimestamp =
          notNullTimestamp.substring(0, index + replacementsCount) +
          '0' +
          notNullTimestamp.substring(index + replacementsCount);
        replacementsCount++;
      }
    }
  });

  return notNullTimestamp;
};
