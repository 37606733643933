import {Interval} from 'luxon';
import {atom, DefaultValue, selector} from 'recoil';
import config from '../utils/AppConfigurationService';

export const selectedNodeIdsState = atom({
  key: 'selectedNodeIds',
  default: [] as string[],
});

export const selectedEdgeIdsState = atom({
  key: 'selectedEdgeIds',
  default: [] as string[],
});

export interface Timeline {
  timelineInterval: Interval;
  selectedInterval: Interval;
}

const timelineRaw = atom({
  key: 'timelineRaw',
  default: {
    selectedInterval: config.defaultTimelineWindow.selectedInterval,
    timelineInterval: config.defaultTimelineWindow.timelineInterval,
  } as Timeline,
});

export const timelineState = selector<Timeline>({
  key: 'ProxySelector',
  get: ({get}) => get(timelineRaw) as Timeline,
  set: ({set}, newTimeline) => {
    if (newTimeline instanceof DefaultValue) {
      set(timelineRaw, newTimeline);
      return;
    }
    if (!newTimeline.selectedInterval.isValid) {
      console.warn(
        'Attempted setting timeline with invalid selected interval!',
        JSON.parse(JSON.stringify(newTimeline.selectedInterval))
      );
      return;
    } else if (!newTimeline.timelineInterval.isValid) {
      console.warn(
        'Attempted setting timeline with invalid timeline interval!',
        JSON.parse(JSON.stringify(newTimeline.timelineInterval))
      );
      return;
    } else {
      set(timelineRaw, newTimeline);
    }
  },
});

export const rangeTimelineState = atom({
  key: 'rangeTimeline',
  default: {
    selectedInterval: config.defaultTimelineWindow.selectedInterval,
    timelineInterval: config.defaultTimelineWindow.timelineInterval,
  } as Timeline,
});
