import {Tabs as MuiTabs} from '@mui/material';
import {useEffect, useState} from 'react';
import {Tab as MuiTab} from '@mui/material';
import classNames from 'classnames';

const tabBaseClasses =
  'min-w-[120px] min-h-[48px] [&>svg]:m-0 gap-y-0.5 gap-x-2 text-label-large normal-case disabled:opacity-50 hover:bg-[#E4DDE1] hover:dark:bg-[#2B282B]';
const tabUnselectedClasses = 'text-light-on-surface-variant dark:text-dark-on-surface-variant';
const tabSelectedClasses = 'text-light-on-surface dark:text-dark-on-surface';

interface TabData {
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  iconPosition?: 'bottom' | 'end' | 'start' | 'top';
}

interface Props {
  tabData: TabData[];
  initialSelectedTabIndex?: number;
}

export default function TabGroup({tabData, initialSelectedTabIndex = 0}: Props) {
  const [value, setValue] = useState(initialSelectedTabIndex);

  useEffect(() => {
    setValue(initialSelectedTabIndex);
  }, [initialSelectedTabIndex]);

  return (
    <MuiTabs value={value} TabIndicatorProps={{className: 'bg-light-primary dark:bg-dark-primary'}}>
      {tabData.map((tab, index) => (
        <MuiTab
          {...tab}
          key={`${tab?.label}_${index}`}
          className={classNames(tabBaseClasses, {
            [tabSelectedClasses]: value === index,
            [tabUnselectedClasses]: value !== index,
          })}
          onClick={() => {
            setValue(index);
            if (tab.onClick) {
              tab.onClick();
            }
          }}
        />
      ))}
    </MuiTabs>
  );
}
