import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import UserInfo from '../Header/components/UserInfo';
import {useState} from 'react';
import Dropdown from '../Dropdown/Dropdown';
import useUserManagement from '../../../controllers/useUserManagement';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface Props {
  isOpen: boolean;
  close: () => void;
  action: () => void;
}
export const ManageUserAccessModal = ({isOpen, close, action}: Props) => {
  const [hasChanges] = useState(false);
  const {roles, users} = useUserManagement();

  return (
    <Dialog
      onClose={() => close()}
      open={isOpen}
      disableEscapeKeyDown={true}
      PaperProps={{className: 'dark:bg-dark-surface'}}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SecurityIcon
          sx={{
            color: '#A9C7FF',
            marginBottom: '16px',
            height: '24px',
          }}
        ></SecurityIcon>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '24px',
            marginBottom: '16px',
          }}
        >
          Manage User Access
        </Typography>
        <Typography
          sx={{
            color: '#CDC3CE',
            fontSize: '14px',
            lineHeight: '20px',
            '& span': {
              fontWeight: 700,
            },
          }}
        >
          All users with access to SliceUp are listed below. To update a users’ role, change it
          using the dropdown selection and then click <span>Update</span>.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className="w-full overflow-hidden">
          <div className="text-base font-semibold text-white">
            <div className="grid grid-cols-3">
              <div className="col-span-2 p-2">Name</div>
              <div className="col-span-1 p-2">Assigned Role</div>
            </div>
          </div>
          {roles.isLoading || users.isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="max-h-48 overflow-y-auto">
              {users.data &&
                users.data.map((person, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-3 items-center border-b border-dark-outline-variant"
                  >
                    <div className="col-span-2 p-2">
                      <UserInfo userName={person.name} email={person.email} />
                    </div>
                    <div className="col-span-1 p-2">
                      <Dropdown
                        dropdownOptions={roles.data || []}
                        onChange={() => {}}
                        selectedValue={person.role}
                      />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          sx={{
            color: `#E1B6FF`,
            marginRight: 'auto',
            textTransform: 'capitalize',
          }}
        >
          Close
        </Button>
        <Button
          onClick={action}
          sx={{
            color: `#E1B6FF`,
            textTransform: 'capitalize',
          }}
          disabled={!hasChanges}
        >
          Reset
        </Button>
        <Button
          onClick={action}
          sx={{
            color: `#E1B6FF`,
            textTransform: 'capitalize',
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
