import DefinitionTooltip from '../DefinitionTooltip';

export default function ObservationsDefinition() {
  return (
    <DefinitionTooltip
      word="Observations"
      definition={
        <span>
          An <b>Observation</b> is a data point representing an instance of network activity that
          may be of interest. <b>Observations</b> should be reviewed to determine if they are
          anomalous situations, routine issues, or benign network activity.
        </span>
      }
    />
  );
}
