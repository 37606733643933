import {EventObjectNode} from 'cytoscape';
import {PathTraceDataNode} from '../../../openapi-schema/schemaTS';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';
import styled from 'styled-components';
import {Container, GroupWrapper, Header, HiddenElement} from './TopologyTooltipElements';

const Text = styled.p`
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
`;

const Unknown = styled(Text)`
  opacity: 0.6;
`;

const getLocation = (node: PathTraceDataNode) => {
  if (!node.city) return '';
  if (!node.iso_code) return node.city;
  return `${node.city}, ${node.iso_code}`;
};

interface DisplayElementProps {
  value: string;
}

export function DisplayElement({value}: DisplayElementProps) {
  if (value) {
    return <Text>{value}</Text>;
  }

  return <Unknown>Unknown</Unknown>;
}

interface Props {
  event: EventObjectNode;
}

export default function PathChangeNodeTooltip({event}: Props) {
  const node = event.target.data() as PathTraceDataNode;

  const data = [
    {header: 'HOSTNAME', value: node.hop_host_name},
    {header: 'IP ADDRESS', value: node.hop_ip_address},
    {header: 'SERVICE PROVIDER', value: node.provider},
    {header: 'LOCATION', value: getLocation(node)},
  ];

  return (
    <Tooltip
      tooltipContent={
        <Container>
          {data.map(element => (
            <GroupWrapper key={element.header}>
              <Header>{element.header}</Header>
              <DisplayElement value={element.value} />
            </GroupWrapper>
          ))}
        </Container>
      }
      mainContent={
        <HiddenElement x={event.originalEvent.offsetX} y={event.originalEvent.offsetY} />
      }
      onCanvas={true}
      maxWidth={'none'}
    />
  );
}
