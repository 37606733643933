import styled from 'styled-components';
import Scale from './Scale';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function SentimentTooltip() {
  return (
    <Container>
      <p>
        Compares the frequency of negative words in the current observation to the frequency of
        negative words in observations from the same host over the past week. Higher values could
        mean a higher concentration of negative sentiment compared to the recent past.
      </p>
      <Scale />
      <p>
        <b>Note</b>: A result of zero could mean there were no negative words in this (or previous)
        observation(s).
      </p>
    </Container>
  );
}
