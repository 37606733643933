import RoomIcon from '@mui/icons-material/Room';

interface Props {
  hop: number | string;
}

export default function HopCell({hop}: Props) {
  if (hop === 0) {
    return <RoomIcon className="relative right-1 text-[18px]" />;
  }
  return <>{hop}</>;
}
