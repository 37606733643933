import {atom} from 'recoil';

interface File {
  fileContent: string;
  name: string;
  size: number;
  type: string;
  lastModified: number;
}

export const uploadedFileState = atom({
  key: 'uploadedFileState',
  default: null as null | File,
});

export const hoverState = atom({
  key: 'hoverState',
  default: false,
});
