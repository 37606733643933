import styled from 'styled-components';
import {
  BACKGROUND_COLOR_UPLOADER,
  BACKGROUND_COLOR_HOVER,
  BORDER_COLOR,
  BORDER_COLOR_HOVER,
  ICON_COLOR,
} from '../colors';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {DragEvent, ChangeEvent} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {uploadedFileState, hoverState} from '../../../../../atoms/UploadedFile';
import useARActions from '../../../../../helpers/hooks/useARActions';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledInput = styled.input`
  display: none;
`;

interface LabelProps {
  $file: boolean;
  $isHovering: boolean;
}

const StyledLabel = styled.label<LabelProps>`
  background: ${BACKGROUND_COLOR_UPLOADER};
  border: 3px solid ${BORDER_COLOR};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  gap: 9px;
  cursor: pointer;
  // Prevent mousing over child elements from triggering,
  // the label's onDragExit event.
  & > * {
    pointer-events: none;
  }
  ${props =>
    (props.$isHovering || props.$file) &&
    `
      background: ${BACKGROUND_COLOR_HOVER};
      border: 3px solid ${BORDER_COLOR_HOVER};
    `}
`;

const InputText = styled.p`
  font-size: 16px;
  line-height: 16px;
`;

const InputSubtext = styled.p`
  font-size: 13px;
  line-height: 16px;
  opacity: 0.87;
  margin-top: 23px;
`;

interface Props {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ScriptUploader({setLoading}: Props) {
  const file = useRecoilValue(uploadedFileState);
  const [isHovering, setIsHovering] = useRecoilState(hoverState);
  const {uploadFile, setSnackbarFileUpload} = useARActions();

  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    setLoading(true);
    e.preventDefault();
    uploadFile(e.dataTransfer.files, setLoading)
      .then(result => setSnackbarFileUpload(result, undefined))
      .catch(error => setSnackbarFileUpload(undefined, error));
  };

  const preventDefaultDrag = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>, hoverState: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setIsHovering(hoverState);
  };

  const handleFilePicker = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    uploadFile(e.target.files || [], setLoading)
      .then(result => setSnackbarFileUpload(result, undefined))
      .catch(error => setSnackbarFileUpload(undefined, error));
  };

  return (
    <Container onDragEnter={e => handleDrag(e, true)} onDragLeave={e => handleDrag(e, false)}>
      <StyledLabel
        htmlFor="file-upload"
        onDrop={handleDrop}
        onDragOver={preventDefaultDrag}
        $isHovering={isHovering}
        $file={Boolean(file)}
      >
        <FileUploadIcon
          sx={{
            color: ICON_COLOR,
            fontSize: '100px',
          }}
        />
        <InputText>
          Drag to upload or <u>browse</u>
        </InputText>
        <InputSubtext>Supported files: YAML, YML</InputSubtext>
        <StyledInput onChange={handleFilePicker} id="file-upload" type="file" accept=".yml,.yaml" />
      </StyledLabel>
    </Container>
  );
}
