import {
  useSelectedAnomalyPerformanceMetrics,
  useUtilizationChartAnomalies,
} from '../../../../data/InterfaceUtilization';
import {DateTime} from 'luxon';
import {binnedTimestampFormat} from '../../../../constants/timeFormats';
import PerformanceMetricsChart from '../../common/PerformanceMetricsChartWrapper/components/PerformanceMetricsChart';
import {useRecoilValue} from 'recoil';
import {selectedAnomalyDataState} from '../../../../atoms/selectedAnomaly';
import {ChartData} from 'chart.js';
import {useMemo} from 'react';
import {AggregatedInterfaceUtilization} from '../../../../openapi-schema/schemaTS';

function useFormattedIUField(
  APIdata: undefined | AggregatedInterfaceUtilization[],
  field: keyof AggregatedInterfaceUtilization
) {
  return useMemo(() => {
    return APIdata && APIdata?.length > 1
      ? APIdata?.map(element => {
          return {
            x: DateTime.fromFormat(element.bin_key, binnedTimestampFormat, {
              zone: 'UTC',
            }).toMillis(),
            y: Number(element[field]) || 0,
          };
        })
      : [];
  }, [APIdata, field]);
}

export default function HistoricalPerformanceChart() {
  const query = useSelectedAnomalyPerformanceMetrics();
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);
  const {data: anomalies} = useUtilizationChartAnomalies();
  const {data: APIdata} = query;

  const utilizationInData = useFormattedIUField(APIdata, 'input_utilization');
  const utilizationOutData = useFormattedIUField(APIdata, 'output_utilization');
  const inErrors = useFormattedIUField(APIdata, 'in_errors');
  const outErrors = useFormattedIUField(APIdata, 'out_errors');

  const data: ChartData<'line'> = useMemo(() => {
    return {
      datasets: [
        {
          label: 'INPUT UTILIZATION',
          data: utilizationInData,
          borderColor: 'rgba(16, 170, 204, 1)',
          backgroundColor: 'rgba(16, 170, 204, 1)',
          yAxisID: 'percentage_left',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: 'rgba(16, 170, 204, 1)',
          pointHoverBorderColor: 'white',
        },
        {
          label: 'OUTPUT UTILIZATION',
          data: utilizationOutData,
          borderColor: 'rgba(237, 106, 161, 1)',
          backgroundColor: 'rgba(237, 106, 161, 1)',
          yAxisID: 'percentage_left',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: 'rgba(237, 106, 161, 1)',
          pointHoverBorderColor: 'white',
        },
        {
          label: 'IN ERRORS',
          data: inErrors,
          borderColor: 'rgba(45, 98, 235, 1)',
          backgroundColor: 'rgba(45, 98, 235, 1)',
          yAxisID: 'right',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: 'rgba(45, 98, 235, 1)',
          pointHoverBorderColor: 'white',
        },
        {
          label: 'OUT ERRORS',
          data: outErrors,
          borderColor: 'rgba(240, 154, 24, 1)',
          backgroundColor: 'rgba(240, 154, 24, 1)',
          yAxisID: 'right',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: 'rgba(240, 154, 24, 1)',
          pointHoverBorderColor: 'white',
        },
      ],
    };
  }, [utilizationInData, utilizationOutData, inErrors, outErrors]);

  const chartMinMax = {
    min: utilizationInData[0]?.x,
    max: utilizationInData[utilizationInData.length - 1]?.x,
  };

  return (
    <PerformanceMetricsChart
      query={query}
      data={data}
      anomalies={selectedAnomalyData ? anomalies?.concat(selectedAnomalyData) : anomalies}
      chartMinMax={chartMinMax}
    />
  );
}
