import styled from 'styled-components';
import {DefaultErrorMessage} from '../../../../constants/queryLifecycleMessages';
import Button from '../Button/Button';
import {ErrorIcon} from 'components/Katana/Icons';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  background-color: black;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
`;

const Title = styled.p`
  font-size: 30px;
  text-align: center;
  line-height: 35px;
  margin-bottom: 4px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  text-align: center;
  line-height: 19px;
  margin-bottom: 10px;
`;

interface Props {
  message?: string;
  retry?: () => void;
  children?: JSX.Element;
}

export default function ErrorState({retry, message = DefaultErrorMessage, children}: Props) {
  return (
    <Container data-testid="error-state">
      <Content>
        <ErrorIcon className="mb-[2px]" />
        <Title>Something Went Wrong</Title>
        <Subtitle>{message}</Subtitle>
        {retry && <Button onClick={retry} variant="text" label="Try Again" />}
        {children}
      </Content>
    </Container>
  );
}
