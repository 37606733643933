import styled from 'styled-components';
import {VAR_MATCHING_REGEX} from '../../../../../constants/AutoRemediation';
import {injectComponent} from '../../../../../utils/injectComponent';

const ScriptContainer = styled.div`
  font-size: 13px;
  line-height: 30px;
  font-weight: 400;
  display: flex;
  gap: 10px;

  * {
    font-family: 'Roboto Mono';
  }
`;

const LineWithVar = styled.code`
  display: flex;
  align-items: center;
`;

const IndexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CodeLinesColumn = styled.pre``;

const LineIndex = styled.code`
  text-align: right;
`;

interface Props {
  fileContent: string | undefined;
  injectedComponent?: React.ComponentType<any>;
}

export default function ScriptContent({fileContent, injectedComponent}: Props) {
  return (
    <ScriptContainer>
      <IndexColumn>
        {(fileContent || '').split('\n').map((_line: string, index: number) => {
          return <LineIndex key={index}>{index + 1}</LineIndex>;
        })}
      </IndexColumn>
      <CodeLinesColumn>
        {(fileContent || '').split('\n').map((line: string, index: number) => {
          return (
            <LineWithVar key={line + String(index)}>
              {injectedComponent
                ? injectComponent(line, VAR_MATCHING_REGEX, injectedComponent)
                : line}
            </LineWithVar>
          );
        })}
      </CodeLinesColumn>
    </ScriptContainer>
  );
}
