export default [
  {
    selector: 'node',
    style: {
      shape: 'ellipse',
      label: 'data(label)',
      width: 'data(width)',
      height: 'data(height)',
      color: 'white',
      fontSize: 12,
      backgroundColor: 'data(bgColor)',
      borderWidth: 2,
      borderColor: 'data(borderColor)',
      textValign: 'center',
      textHalign: 'center',
      overlayOpacity: 0,
      backgroundImage: 'data(icon)',
      backgroundImageOpacity: 'data(backgroundImageOpacity)',
      borderStyle: 'data(borderStyle)',
    },
  },
  {
    selector: 'edge',
    style: {
      curveStyle: 'straight',
      width: 'data(lineWidth)',
      lineColor: 'data(lineColor)',
      overlayOpacity: 0,
    },
  },
  {
    selector: 'core',
    style: {
      activeBgSize: 0,
    },
  },
  {
    selector: 'edge:selected',
    style: {
      width: 4,
      lineColor: 'rgba(205, 62, 56, 1)',
    },
  },
  {
    selector: '.node-hover',
    style: {
      backgroundColor: 'rgba(110, 28, 159, 1)',
      borderColor: 'rgba(125, 51, 169, 1)',
    },
  },
  {
    selector: '.edge-hover',
    style: {
      lineColor: 'rgba(210, 145, 251, 1)',
    },
  },
];
