import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import ComponentTitle from '../ComponentTitle/ComponentTitle';
import LineChart from './components/LineChart';
import {selectedReportingKPIstate} from '../../../atoms/selectedReportingKPI';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  padding: 10px;
`;

export default function AnomaliesCountLineChart() {
  const selectedReportingKPI = useRecoilValue(selectedReportingKPIstate);

  return (
    <Container>
      <TitleWrapper>
        <ComponentTitle title={selectedReportingKPI.title} />
      </TitleWrapper>
      <LineChart filter={selectedReportingKPI.filter} />
    </Container>
  );
}
