import {ReactNode} from 'react';
import classnames from 'classnames';

interface Props {
  children: ReactNode;
  clickEvent?: () => void;
}

export default function IconWrapper({children, clickEvent}: Props) {
  return (
    <div
      className={classnames('grid', 'place-content-center', {
        'cursor-pointer': Boolean(clickEvent),
      })}
      onClick={clickEvent}
    >
      {children}
    </div>
  );
}
