export const updateSelectedWordCloudWords = (
  selectedWordCloudWords: string[],
  clickedWord: string
) => {
  const clickedWordIndex = selectedWordCloudWords.findIndex(word => word === clickedWord);
  if (clickedWordIndex > -1) {
    const selectedWordsCopy = [...selectedWordCloudWords];
    selectedWordsCopy.splice(clickedWordIndex, 1);
    return selectedWordsCopy;
  } else {
    return selectedWordCloudWords.concat([clickedWord]);
  }
};
