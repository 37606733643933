import EastIcon from '@mui/icons-material/East';
import IconWrapper from 'components/common/Sensei/IconWrapper';

interface Props {
  title: string;
  mainText: string;
  onClick: () => void;
}

export default function CapabilityCard({title, mainText, onClick}: Props) {
  return (
    <div className="flex w-[225px] min-w-[225px] flex-col gap-2 rounded border border-[#6e5a78] bg-gradient-to-r from-[#312636] via-[#523C5E] to-[#312636] px-4 py-2">
      <p className="leading-20">{title}</p>
      <p className="leading-16 text-14">{mainText}</p>
      <div className="flex cursor-pointer items-center justify-between gap-2" onClick={onClick}>
        <p className="text-12 leading-[14px] text-purple-100">Click to try it out</p>
        <IconWrapper>
          <EastIcon sx={{fontSize: '14px', color: 'rgba(235, 204, 253, 1)'}} />
        </IconWrapper>
      </div>
    </div>
  );
}
