import TableSortArrow from 'components/common/TableSortArrow/TableSortArrow';
import {useEffect, useMemo, useRef, useState} from 'react';
import {
  Container,
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TableData,
  TableRow,
} from '../../../../common/TableElements/TableElements';
import {Health} from './Health';
import {HealthTooltip, RiskTooltip} from './ColumnHeaderTooltips';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {deviceInformationOpenState} from 'atoms/deviceInformationOpen';
import {hardwareCatalogSelectedDeviceState} from 'atoms/hardwareCatalogSelectedDevice';
import {useHardwareCatalogData, useHardwareCatalogDataCount} from 'data/HardwareMonitoring';
import {MtbfCatalogStatus} from 'openapi-schema/schemaTS';
import QueryLifecycleWrapper from 'components/common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {isEmpty} from 'lodash';
import Pagination from 'components/common/Pagination/Pagination';
import {pageSizeOptionsLarge} from 'constants/PageSizeOptions';
import {convertSortToReactTableSchema} from 'utils/convertSortToReactTableSchema';
import {convertSortToApiSchema} from 'utils/convertSortToApiSchema';
import {scrollToTop} from 'utils/scrollToTop';
import Risk from './Risk';
import {ColumnSelectorPopupWrapper} from '../../../../common/ColumnSelector/ColumnSelectorPopup';
import {hwlcColumnSelectorOpenState} from '../../../../../atoms/hardwareCatalogColumnSlectorOpenState';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  InitialTableState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import {useReactTable} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const getIsRowHighlighted = (deviceData: MtbfCatalogStatus) => {
  const threshold = 70;
  return deviceData.percent_failure_1_year > threshold;
};

const columnHelper = createColumnHelper<MtbfCatalogStatus>();

const defaultSortState = [
  {
    name: 'name',
    desc: false,
  },
];

const pageSizeOptions = pageSizeOptionsLarge;

export function HardwareCatalogTable() {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const [sortState, setSortState] = useState(defaultSortState);
  const hardwareCatalogQuery = useHardwareCatalogData(APIPageIndex, APIPageSize, sortState);
  const {data: APIdata} = hardwareCatalogQuery;
  const {data: deviceCount} = useHardwareCatalogDataCount();
  const setDeviceInformationOpen = useSetRecoilState(deviceInformationOpenState);
  const [selectedDeviceId, setSelectedDeviceId] = useRecoilState(
    hardwareCatalogSelectedDeviceState
  );
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const data = useMemo(() => (APIdata ? [...APIdata] : []), [APIdata]);

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      size: 1,
    }),
    columnHelper.accessor('ip', {
      header: 'IP',
      size: 1,
    }),
    columnHelper.accessor('device_model', {
      header: 'Model',
      size: 1,
    }),
    columnHelper.accessor('health_status', {
      header: () => <HealthTooltip />,
      size: 1,
      cell: props => <Health health={props.getValue()} />,
    }),
    columnHelper.accessor('percent_failure_1_year', {
      header: () => <RiskTooltip description="1 year" titleDuration="1Y" />,
      size: 1,
      cell: props => <Risk risk={props.getValue()} />,
    }),
    columnHelper.accessor('percent_failure_90_days', {
      header: () => <RiskTooltip description="3 months" titleDuration="3M" />,
      size: 1,
      cell: props => <Risk risk={props.getValue()} />,
      id: '3M',
      enableHiding: true,
    }),
    columnHelper.accessor('percent_failure_30_days', {
      header: () => <RiskTooltip description="1 month" titleDuration="1M" />,
      size: 1,
      cell: props => <Risk risk={props.getValue()} />,
      id: '1M',
      enableHiding: true,
    }),
  ];

  const initialState: InitialTableState = useMemo(() => {
    const selectedRowIndex = data.findIndex(node => node.node_id === selectedDeviceId);

    return {
      sorting: convertSortToReactTableSchema(defaultSortState),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
      columnVisibility: {'1M': false, '3M': false},
      rowSelection: {[selectedRowIndex]: true},
    };
  }, [data, selectedDeviceId]);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
    getAllColumns,
    setColumnVisibility,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    enableRowSelection: true,
    pageCount: Math.ceil((deviceCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableMultiRowSelection: false,
    enableHiding: true,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  useEffect(() => {
    // updates pagination values for query
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
  }, [pageIndex, pageSize]);

  useEffect(() => {
    // formats sorting object for query
    const replacements = {};
    const newSortBy = convertSortToApiSchema(sorting, replacements);
    setSortState(newSortBy);
  }, [setSortState, sorting]);

  useEffect(() => {
    // resets table when any change causes query to run
    setPageIndex(0);
  }, [sorting, pageSize, setPageIndex]);

  return (
    <Container>
      <ColumnSelectorPopupWrapper
        columns={getAllColumns()}
        hideColumn={setColumnVisibility}
        recoilState={hwlcColumnSelectorOpenState}
      />
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
              <th>
                <ArrowRightIcon sx={{visibility: 'hidden'}} />
              </th>
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={hardwareCatalogQuery}
            isEmpty={isEmpty(data)}
            emptyMessage="No devices found"
          >
            {getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                isSelected={row.getIsSelected()}
                isHighlighted={getIsRowHighlighted(row.original)}
                onClick={() => {
                  setSelectedDeviceId(row.original.node_id);
                  setDeviceInformationOpen(true);
                }}
              >
                {row.getVisibleCells().map(cell => (
                  <TableData key={cell.id} style={{...getCellStyles(cell)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
                <td>
                  <ArrowRightIcon sx={{color: 'rgba(124, 117, 126, 1)'}} />
                </td>
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </Container>
  );
}
