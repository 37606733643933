import {atom} from 'recoil';
import {ButtonData} from '../components/ReportingDashboard/ReportingDashboardSummary/components/SectionGroup';
import config from '../utils/AppConfigurationService';

export const defaultSelectedReportingKPI = {
  text: 'All Priorities',
  filter: {
    priority_types: config.DefaultPriorities,
  },
  title: 'All Observations',
  value: 0,
};

export const selectedReportingKPIstate = atom({
  key: 'selectedReportingKPI',
  default: defaultSelectedReportingKPI as ButtonData,
});
