import styled from 'styled-components';
import {HotlAnomaly} from '../../../../openapi-schema/schemaTS';
import Star from '../../../AnomalyDetail/common/DetailViewHeader/components/Star';
import Feedback from './Feedback';
import Ticket from './Ticket';

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  height: 100%;
`;

const Indicator = styled.div``;

interface Props {
  observation: HotlAnomaly;
}

export default function Indicators(props: Props) {
  const {observation} = props;
  return (
    <Container>
      <Indicator>
        <Ticket serviceNowLink={observation.feedback?.service_now_link || ''} />
      </Indicator>
      <Indicator>
        <Feedback anomaly={observation} />
      </Indicator>
      <Indicator>
        <Star
          initialStarred={observation.feedback?.starred || false}
          unselectedColor="rgba(102, 102, 102, 1)"
          size="18px"
        />
      </Indicator>
    </Container>
  );
}
