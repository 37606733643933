import styled from 'styled-components';
import Tooltip from '../../../Katana/components/Tooltip/Tooltip';
import {startCase} from 'lodash';
import {PriorityClassificationBucket} from '../../../../openapi-schema/schemaTS';
import ObservationPriorityIcon from 'components/common/ObservationPriorityIcon/ObservationPriorityIcon';

const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const PriorityText = styled.p`
  margin-left: 6px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  priority: PriorityClassificationBucket;
}

export default function PriorityData({priority}: Props) {
  return (
    <Tooltip
      tooltipContent={<p>Priority: {startCase(priority)}</p>}
      mainContent={
        <Container>
          <ObservationPriorityIcon priority={priority} color />
          <PriorityText>{priority}</PriorityText>
        </Container>
      }
    />
  );
}
