import {VAR_MATCHING_REGEX} from '../../../../constants/AutoRemediation';
import {injectComponent} from '../../../../utils/injectComponent';
import {
  TemplateLiteral,
  Variable,
} from '../../../AnomalyDetail/Log/AutomateWindow/components/TemplateDisplayContent';
import styled from 'styled-components';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {isEmpty} from 'lodash';
import {EmptyLogsMessage} from '../../../../constants/queryLifecycleMessages';
import {useFingerprintLogs} from '../../../../data/AutoRemediation';

const Container = styled.div`
  padding: 7px 10px;
`;

interface Props {
  template_id: string;
  template_version: number;
  anomaly_id: string;
}

export default function TemplateContent({template_id, template_version, anomaly_id}: Props) {
  const query = useFingerprintLogs(template_id, template_version, anomaly_id);

  return (
    <Container>
      <QueryLifecycleWrapper
        query={useFingerprintLogs(template_id, template_version, anomaly_id)}
        isEmpty={isEmpty(query.data)}
        emptyMessage={EmptyLogsMessage}
        wrapWithTableRow={false}
      >
        <TemplateLiteral>
          {injectComponent(
            String(query?.data?.[0]?.template_literal),
            VAR_MATCHING_REGEX,
            Variable
          )}
        </TemplateLiteral>
      </QueryLifecycleWrapper>
    </Container>
  );
}
