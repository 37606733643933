import {atom} from 'recoil';
import {SortField} from '../openapi-schema/schemaTS';

export const defaultTemplatesSelectionSorting = [
  {
    name: 'log_count',
    desc: true,
  },
];

export const templatesSelectionSortingState = atom({
  key: 'templatesSelectionSorting',
  default: defaultTemplatesSelectionSorting as SortField[],
});
