import styled from 'styled-components';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {deviceInformationOpenState} from 'atoms/deviceInformationOpen';
import StatGroup from './StatGroup';
import DeviceComponentsTable from './DeviceComponentsTable';
import {hardwareCatalogSelectedDeviceState} from '../../../../../atoms/hardwareCatalogSelectedDevice';
import {useHardwareCatalogDeviceDetailsData} from '../../../../../data/HardwareMonitoring';
import ErrorState from '../../../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';

const Container = styled.div`
  background: black;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: white;
`;

const BackButton = styled.button`
  display: flex;
  align-self: flex-start;
  gap: 4px;
  align-items: center;
  padding: 3px 7px;
  border: 1px solid rgba(142, 0, 226, 1);
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

const Text = styled.p`
  color: rgba(231, 231, 232, 1);
`;

const Title = styled.p`
  margin-top: 20px;
  font-size: 26px;
  line-height: 33px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

export default function DeviceInformation() {
  const setDeviceInformationOpen = useSetRecoilState(deviceInformationOpenState);
  const [selectedDeviceId] = useRecoilState(hardwareCatalogSelectedDeviceState);
  const {data, isError, isLoading, refetch} = useHardwareCatalogDeviceDetailsData(selectedDeviceId);

  let content = (
    <>
      <Title>
        Showing information for{' '}
        <Bold>{data?.parent?.name || data?.parent?.system_name || 'Unknown'}</Bold>
      </Title>
      <StatGroup deviceData={data} />
      <DeviceComponentsTable deviceData={data} />
    </>
  );
  if (isError) {
    content = <ErrorState retry={refetch} />;
  }
  if (isLoading) {
    content = <LoadingSpinner />;
  }

  return (
    <Container>
      <BackButton onClick={() => setDeviceInformationOpen(false)}>
        <KeyboardBackspaceIcon sx={{fontSize: '20px'}} />
        <Text>Back to Hardware Catalog</Text>
      </BackButton>
      {content}
    </Container>
  );
}
