import {TopologyElements, TopologyNode} from '../data/types';
import ipRegex from 'ip-regex';

/**
 * Find a topology node by attempting to match the affectedResource against IP address, Name, LongName, and SystemName fields
 * @param affectedResource The affected resource.
 * @param topologyElements The topology elements.
 * @returns TopologyNode | undefined
 */
export function findNodeByHostnameOrIP(
  affectedResource: string,
  topologyElements: TopologyElements
): TopologyNode | undefined {
  // Check if affectedResource is an IPv4 or IPv6 address
  if (ipRegex().test(affectedResource)) {
    if (topologyElements?.nodesByIP?.[affectedResource]) {
      return topologyElements?.nodesByIP?.[affectedResource][0];
    }
  }
  if (topologyElements?.nodesByName?.[affectedResource]) {
    return topologyElements?.nodesByName?.[affectedResource][0];
  }
  if (topologyElements?.nodesByLongName?.[affectedResource]) {
    return topologyElements?.nodesByLongName?.[affectedResource][0];
  }
  if (topologyElements?.nodesBySystemName?.[affectedResource]) {
    return topologyElements?.nodesBySystemName?.[affectedResource][0];
  }

  return undefined;
}
