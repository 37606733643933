import {SortField} from '../openapi-schema/schemaTS';

export const convertSortToReactTableSchema = (
  sortBy: SortField[],
  replacements: {[key: string]: string} = {}
) => {
  const newSortBy = sortBy.map((element: SortField) => {
    return {
      id: replacements[element.name] || element.name,
      desc: element.desc || false,
    };
  });

  return newSortBy;
};
