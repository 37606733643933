import LogsTable from '../LogsTable/LogsTable';
import {Container, ComponentTitleWrapper} from 'components/common/TableElements/TableElements';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import {pageSizeOptionsMedium} from 'constants/PageSizeOptions';
import {LogCountQuery, LogListQuery} from 'data/types';

const pageSizeOptions = pageSizeOptionsMedium;

interface Props {
  logListQuery: LogListQuery;
  logCountQuery: LogCountQuery;
}

export default function AssociatedLogsTable({logListQuery, logCountQuery}: Props) {
  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="Associated Logs" />
      </ComponentTitleWrapper>
      <LogsTable
        logListQuery={logListQuery}
        logCountQuery={logCountQuery}
        pageSizeOptions={pageSizeOptions}
      />
    </Container>
  );
}
