import {useEffect, useState} from 'react';
import {
  useSelectedAnomalyLatencyPairMetrics,
  useSelectedAnomalyLatencyPairsMetricsCount,
} from '../../../../data/Latency';
import ObservationDetailTable from '../../common/ObservationDetailTable/ObservationDetailTable';
import {Container, ComponentTitleWrapper} from '../../../common/TableElements/TableElements';
import Tooltip from '../../../Katana/components/Tooltip/Tooltip';
import styled from 'styled-components';
import Timestamp from '../../../common/Timestamp';
import {FlaggedLatency} from '../../../../openapi-schema/schemaTS';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import {useSelectedAnomaly} from 'data/Anomalies';
import {createColumnHelper} from '@tanstack/react-table';

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipRow = styled.div``;

const initialSortBy = [
  {
    name: 'timestamp',
    desc: true,
  },
];

const columnHelper = createColumnHelper<FlaggedLatency>();

function LatencyAnomalyTable() {
  const [data, setData] = useState<FlaggedLatency | undefined>(undefined);
  const query = useSelectedAnomalyLatencyPairMetrics();
  const {data: anomalyData} = useSelectedAnomaly();

  const columns = [
    columnHelper.accessor('avg_network_rt_response_time', {
      header: 'Avg Round Trip',
      size: 1,
      cell: props => <p>{`${props.getValue()?.toFixed(2)} ms`}</p>,
    }),
    columnHelper.accessor('total_retransmission_percent', {
      header: 'Total Retransmission',
      size: 1,
      cell: props => <p>{`${props.getValue()?.toFixed(2)} %`}</p>,
    }),
    columnHelper.accessor('total_bytes', {
      header: 'Total Bytes',
      size: 1,
    }),
    columnHelper.accessor('byte_rate', {
      header: 'Byte Rate',
      size: 1,
      cell: props => <p>{`${props.getValue().toFixed(2)}`}</p>,
    }),
    columnHelper.accessor('timestamp', {
      header: 'Timestamp',
      size: 1,
      cell: props => <Timestamp timestamp={props.getValue()} />,
    }),
  ];

  useEffect(() => {
    if (query.data?.[0]) {
      setData(query.data?.[0]);
    }
  }, [query.data]);

  return (
    <Container>
      <ComponentTitleWrapper>
        <Tooltip
          tooltipContent={
            data ? (
              <TooltipContainer>
                <TooltipRow>
                  {data?.client_site} subnet: {data?.client_site_subnets}
                </TooltipRow>
                <TooltipRow>
                  {data?.server_site} subnet: {data?.server_site_subnets}
                </TooltipRow>
              </TooltipContainer>
            ) : (
              ''
            )
          }
          mainContent={
            <span style={{display: 'inline-block'}}>
              <ComponentTitle
                title={
                  data
                    ? `${data.client_site} to ${data?.server_site}`
                    : `${anomalyData?.affected_resources?.[0]} to ${anomalyData?.affected_resources?.[1]}`
                }
              />
            </span>
          }
        />
      </ComponentTitleWrapper>
      <ObservationDetailTable
        tableDataQuery={useSelectedAnomalyLatencyPairMetrics}
        dataCountQuery={useSelectedAnomalyLatencyPairsMetricsCount}
        columns={columns}
        initialSortBy={initialSortBy}
      />
    </Container>
  );
}

export default LatencyAnomalyTable;
