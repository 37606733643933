import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 10px;

  .MuiSlider-root {
    margin: 0;
    width: 300px;
    margin-left: 10px;

    .MuiSlider-rail {
      background: rgba(77, 78, 81, 1);
      opacity: 1;
    }

    .MuiSlider-track {
      background: rgba(82, 94, 223, 1);
      border: none;
    }

    .MuiSlider-thumb {
      background: white;
      width: 14px;
      height: 24px;
      border-radius: 10000px;
      box-shadow: none;
      z-index: 1;
    }

    .MuiSlider-mark {
      background: rgba(77, 78, 81, 1);
      width: 2px;
      height: 8px;
    }

    .MuiSlider-markActive {
      background: rgba(82, 94, 223, 1);
      width: 2px;
      height: 8px;
    }

    .MuiSlider-markLabel {
      color: white;
      top: 20px;
    }
  }
`;

export const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const InputWrapper = styled.div`
  margin-left: 15px;
  margin-right: 4px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid rgba(64, 64, 64, 1);
  border-radius: 1px;
`;

export const Sign = styled.p`
  width: 8px;
  display: flex;
  justify-content: center;
`;

export const Input = styled.input`
  margin-left: 4px;
  background: inherit;
  color: white;
  border: none;
  outline: none;
  width: 30px;
  padding: 0;

  :focus {
    box-shadow: none;
  }
`;

export const Percentage = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

export const SliderTitle = styled.p`
  margin-right: auto;
  font-size: 13px;
  white-space: nowrap;
`;
