import {useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import TableSortArrow from '../../../common/TableSortArrow/TableSortArrow';
import PriorityData from 'components/AnomalySelection/DetailedAnomalyTable/components/PriorityData';
import Timestamp from '../../../common/Timestamp';
import Pagination from '../../../common/Pagination/Pagination';
import {isEmpty} from 'lodash';
import {timelineState} from '../../../../atoms/dashboard';
import {anomalySelectionFiltersState} from '../../../../atoms/anomalySelectionFilters';
import {pageSizeOptionsLarge} from '../../../../constants/PageSizeOptions';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {convertSortToApiSchema} from '../../../../utils/convertSortToApiSchema';
import {convertSortToReactTableSchema} from '../../../../utils/convertSortToReactTableSchema';
import {EmptyObservationsFiltersMessage} from '../../../../constants/queryLifecycleMessages';
import {ErAnomalyWselLog} from '../../../../openapi-schema/schemaTS';
import {ColumnSelectorPopupWrapper} from '../../../common/ColumnSelector/ColumnSelectorPopup';
import {scrollToTop} from '../../../../utils/scrollToTop';
import {
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TextWithTooltip,
  TableRow,
  TableData,
} from 'components/common/TableElements/TableElements';
import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';
import {useSecurityEventData, useSecurityEventDataCount} from '../../../../data/Security';
import {
  securityAffectedResourcesSearchState,
  securityColumnPopupOpenState,
  securityFilterPopupOpenState,
  selectedSecurityAnomalyIdState,
} from '../../../../atoms/securityState';
import {
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const pageSizeOptions = pageSizeOptionsLarge;
const securityTableSortingDefaultState = [
  {
    name: 'priority_classification',
    desc: true,
  },
  {
    name: 'min_timestamp',
    desc: true,
  },
];

const columnHelper = createColumnHelper<ErAnomalyWselLog>();

export default function SecurityEventsTable() {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const [sortingState, setSortingState] = useState(securityTableSortingDefaultState);
  const securityEventDataQuery = useSecurityEventData(APIPageIndex, APIPageSize, sortingState);
  const {data: APIdata} = securityEventDataQuery;
  const {data: anomalyCount} = useSecurityEventDataCount();
  const [selectedSecurityAnomalyID, setSelectedSecurityAnomalyID] = useRecoilState(
    selectedSecurityAnomalyIdState
  );
  const anomalySelectionFilters = useRecoilValue(anomalySelectionFiltersState);
  const timeline = useRecoilValue(timelineState);
  const affectedResourcesSearch = useRecoilValue(securityAffectedResourcesSearchState);
  const setAnomalyFilterOpen = useSetRecoilState(securityFilterPopupOpenState);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const data = useMemo(() => (APIdata ? [...APIdata] : []), [APIdata]);

  const columns = [
    columnHelper.accessor('anomaly_type', {
      header: () => (
        <TitleTooltip
          header="Model Type"
          mainContent={<span>Model Type</span>}
          tooltipContent={<span>Name of the Windows Security Model</span>}
        />
      ),
      size: 1,
      enableSorting: false,
    }),
    columnHelper.accessor('explanatory_reasons', {
      header: () => (
        <TitleTooltip
          header="Event ID"
          mainContent={<span>Event ID</span>}
          tooltipContent="The Windows Event ID which triggered the event"
        />
      ),
      size: 0.5,
      enableSorting: false,
      cell: props => (
        <TextWithTooltip
          text={props.getValue().event_id}
          tooltip={props.getValue().event_description}
        />
      ),
    }),
    columnHelper.accessor('affected_resources', {
      header: () => (
        <TitleTooltip
          header="Affected Resources"
          mainContent={<span>Affected</span>}
          tooltipContent="The user(s) or host(s) affected by the event."
        />
      ),
      size: 1.25,
      cell: props => <TextWithTooltip text={props.getValue().join(', ')} />,
    }),
    columnHelper.accessor('priority_classification', {
      header: () => (
        <TitleTooltip
          header="Priority"
          mainContent={<span>Priority</span>}
          tooltipContent="The priority of the event based on its expected impact."
        />
      ),
      size: 0.8,
      cell: props => <PriorityData priority={props.getValue()} />,
    }),
    columnHelper.accessor('min_timestamp', {
      header: () => (
        <TitleTooltip
          header="Timestamp"
          mainContent={<span>Timestamp</span>}
          tooltipContent="The time that this event was detected."
        />
      ),
      size: 1,
      cell: props => <Timestamp timestamp={props.getValue()} />,
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(securityTableSortingDefaultState),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
    };
  }, []);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
    resetRowSelection,
    getAllColumns,
    setColumnVisibility,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    enableRowSelection: true,
    pageCount: Math.ceil((anomalyCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableMultiRowSelection: false,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  const handleToggle = (row: Row<ErAnomalyWselLog>) => {
    if (row.getIsSelected()) return;

    row.toggleSelected(!row.getIsSelected());
    setSelectedSecurityAnomalyID(row.original.anomaly_id);
  };

  useEffect(() => {
    // updates pagination values for query
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
    resetRowSelection(true);
  }, [pageIndex, pageSize, resetRowSelection]);

  useEffect(() => {
    // formats sorting object for query
    const replacements = {};
    const newSortBy = convertSortToApiSchema(sorting, replacements);
    setSortingState(newSortBy);
  }, [setSortingState, sorting]);

  useEffect(() => {
    // resets table when any change causes query to run
    setPageIndex(0);
    resetRowSelection(true);
  }, [
    sorting,
    pageSize,
    anomalySelectionFilters,
    timeline,
    affectedResourcesSearch,
    setPageIndex,
    resetRowSelection,
  ]);

  useEffect(() => {
    // unselects all rows if there is no selected observation
    if (!selectedSecurityAnomalyID) {
      resetRowSelection(true);
    }
  }, [selectedSecurityAnomalyID, resetRowSelection]);

  return (
    <>
      <ColumnSelectorPopupWrapper
        columns={getAllColumns()}
        hideColumn={setColumnVisibility}
        recoilState={securityColumnPopupOpenState}
      />
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={securityEventDataQuery}
            isEmpty={isEmpty(data)}
            emptyMessage={EmptyObservationsFiltersMessage}
            actionButtonText="View Filters"
            onActionButtonClick={() => setAnomalyFilterOpen(true)}
          >
            {getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                isRead={true}
                isSelected={row.getIsSelected()}
                onClick={() => handleToggle(row)}
              >
                {row.getVisibleCells().map(cell => (
                  <TableData key={cell.id} style={{...getCellStyles(cell)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
}
