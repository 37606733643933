export default [
  {
    selector: 'node',
    style: {
      shape: 'ellipse',
      label: 'data(label)',
      width: 'data(width)',
      height: 'data(height)',
      color: 'white',
      fontSize: 4,
      fontWeight: 400,
      textMarginY: 1,
      backgroundColor: 'data(bgColor)',
      borderWidth: 'data(borderWidth)',
      borderColor: 'white',
      minZoomedFontSize: 5,
      textValign: 'bottom',
      textHalign: 'center',
      textWrap: 'wrap',
      textMaxWidth: 80,
      overlayOpacity: 0,
    },
  },
  {
    selector: 'edge',
    style: {
      color: 'white',
      curveStyle: 'straight',
      fontSize: 3.5,
      fontWeight: 400,
      width: 'data(width)',
      lineColor: 'data(lineColor)',
      textRotation: 'autorotate',
      minZoomedFontSize: 15,
      overlayOpacity: 0,
      zIndex: 'data(zIndex)',
    },
  },
  {
    selector: 'node:selected',
    style: {
      backgroundColor: 'rgba(205, 62, 56, 1)',
      borderWidth: 0,
    },
  },
  {
    selector: 'edge:selected',
    style: {
      lineColor: 'rgba(205, 62, 56, 1)',
      label: 'data(label)',
      zIndex: 1,
    },
  },
  {
    selector: 'core',
    style: {
      activeBgSize: 0,
    },
  },
];
