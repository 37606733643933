import {useTopologyData} from 'data/Topology';
import {HotlAnomaly} from '../../openapi-schema/schemaTS';
import {findNodeByHostnameOrIP} from '../../utils/findNodeByHostnameOrIP';

const useSelectedNodes = () => {
  const {data: topologyElements} = useTopologyData();

  const selectedNodesLog = (anomaly: HotlAnomaly): string[] => {
    const affected_resource = anomaly?.affected_resources?.[0];

    const topologyNode = findNodeByHostnameOrIP(affected_resource, topologyElements);
    const nodeId = topologyNode?.data?.device_id;
    if (nodeId) {
      return [nodeId];
    }

    const selectedSubnetId =
      topologyElements?.hostToSubnetMap?.[affected_resource] ||
      topologyElements?.ipToSubnetMap?.[affected_resource];
    //empty subnet ID case
    if (!selectedSubnetId?.[0]) {
      return [];
    }

    //default case
    const subnetEdges = topologyElements?.edgesBySubnet?.[selectedSubnetId?.[0]];
    let highlightedNodes = subnetEdges?.map(edge => [edge.data.source, edge.data.target]).flat();
    if (!highlightedNodes) return [];
    highlightedNodes = Array.from(new Set(highlightedNodes));
    return highlightedNodes;
  };

  const selectedNodesInterfaceUtilization = (anomaly: HotlAnomaly): string[] => {
    const [node_name, int_name] = anomaly.affected_resources[0].split('_');

    const highlightedEdge = topologyElements.edges.find(edge => {
      return (
        (int_name === edge.data.source_int_name && node_name === edge.data.source_node_name) ||
        (int_name === edge.data.target_int_name && node_name === edge.data.target_node_name) ||
        (int_name === edge.data.source_int_name && node_name === edge.data.sourceIp) ||
        (int_name === edge.data.target_int_name && node_name === edge.data.targetIp)
      );
    })?.data;

    return highlightedEdge ? [highlightedEdge.source_node_id, highlightedEdge.target_node_id] : [];
  };

  const selectedNodesLatency = (anomaly: HotlAnomaly) => {
    if (anomaly.affected_resources[0] === anomaly.affected_resources[1]) {
      return ['Self'];
    }

    return [anomaly.affected_resources[1]];
  };

  const selectedEdgesLatency = (anomaly: HotlAnomaly) => {
    if (anomaly.affected_resources[0] === anomaly.affected_resources[1]) {
      return [`Self_${anomaly.affected_resources[0]}`];
    }

    return [
      `${anomaly.affected_resources[0]}_${anomaly.affected_resources[1]}`,
      `${anomaly.affected_resources[1]}_${anomaly.affected_resources[0]}`,
    ];
  };

  return {
    selectedNodesLog,
    selectedNodesInterfaceUtilization,
    selectedNodesLatency,
    selectedEdgesLatency,
  };
};

export default useSelectedNodes;
