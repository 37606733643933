import styled from 'styled-components';
import useURLSelectedAnomaly from '../../helpers/hooks/useURLSelectedAnomaly';
import useAnomalyDetailUrlTime from '../../helpers/hooks/useAnomalyDetailUrlTime';
import {useSelectedAnomaly} from '../../data/Anomalies';
import LogDetailDashboard from './Log/LogDetailDashboard/LogDetailDashboard';
import LatencyDetailDashboard from './Latency/LatencyDetailDashboard/LatencyDetailDashboard';
import InterfaceDetailDashboard from './InterfaceUtilization/InterfaceDetailDashboard/InterfaceDetailDashboard';
import NoResults from '../common/NoResults/NoResults';
import LoadingSpinner from '../common/LoadingSpinner/LoadingSpinner';
import DetailViewHeader from './common/DetailViewHeader/DetailViewHeader';
import ErrorState from '../Katana/components/ErrorState/ErrorState';
import config from '../../utils/AppConfigurationService';
import {AnomalyTypeBucket} from '../../openapi-schema/schemaTS';
import BGPNeighborDropDetailDashboard from './BGPNeighborDrop/BGPNeighborDropDetailDashboard/BGPNeighborDropDetailDashboard';
import ISISNeighborDropDetailDashboard from './ISISNeighborDrop/ISISNeighborDropDetailDashboard/ISISNeighborDropDetailDashboard';
import PathChangeDetailDashboard from './PathChange/PathChangeDetailDashboard';
import VTCDetailDashboard from './VTC/VTCDetailDashboard';
import FiberChannelDashboard from './FiberChannel/FiberChannelDashboard';
import ExchangeLogDetailDashboard from './ExchangeLog/ExchangeLogDetailDashboard';
import {withTracingErrorBoundary} from '../../tracing/errorReporting';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 120px);
`;

const getDashboard = (type: AnomalyTypeBucket) => {
  if (type === config.AnomalyTypes.LOG) return <LogDetailDashboard />;
  else if (type === config.AnomalyTypes.LATENCY) return <LatencyDetailDashboard />;
  else if (type === config.AnomalyTypes.INTERFACE) return <InterfaceDetailDashboard />;
  else if (type === config.AnomalyTypes.NEIGHBOR_DROP_BGP)
    return <BGPNeighborDropDetailDashboard />;
  else if (type === config.AnomalyTypes.NEIGHBOR_DROP_ISIS)
    return <ISISNeighborDropDetailDashboard />;
  else if (type === config.AnomalyTypes.PATH_CHANGE) return <PathChangeDetailDashboard />;
  else if (type === config.AnomalyTypes.VIDEO_VOIP) return <VTCDetailDashboard />;
  else if (type === config.AnomalyTypes.FIBER_CHANNEL) return <FiberChannelDashboard />;
  else if (type === config.AnomalyTypes.EXCHANGE_LOG) return <ExchangeLogDetailDashboard />;
  return <NoResults message="Unknown Observation type, please select a different Observation" />;
};

function AnomalyDetail() {
  const {data, isLoading, isError, refetch} = useSelectedAnomaly();
  useURLSelectedAnomaly();
  useAnomalyDetailUrlTime(data);

  if (isError) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (data) {
    const selectedAnomaly = data;
    return (
      <Container>
        <DetailViewHeader selectedAnomaly={selectedAnomaly} />
        {getDashboard(selectedAnomaly.anomaly_type)}
      </Container>
    );
  } else {
    return <NoResults message="No observation found with this ID" />;
  }
}

export default withTracingErrorBoundary(AnomalyDetail);
