import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px 10px 20px 10px;
`;

const Title = styled.p`
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
`;

const Subtitle = styled.p`
  font-size: 13px;
  line-height: 16px;
  max-width: 850px;
  white-space: pre-wrap;

  b {
    font-weight: 700;
  }
`;

interface Props {
  title: string;
  subtitle?: React.ReactNode;
}

export default function PageTitle({title, subtitle}: Props) {
  return (
    <Container>
      <Title data-testid="StepWizardPageTitle">{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
}
