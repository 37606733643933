import {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import TableSortArrow from '../../../../common/TableSortArrow/TableSortArrow';
import {useRecoilState} from 'recoil';
import {selectedWordCloudWordsState} from '../../../../../atoms/selectedWordCloudWords';
import {isEmpty} from 'lodash';
import {updateSelectedWordCloudWords} from '../utils/updateSelectedWordCloudWords';
import {WordcloudItemTemplates} from '../../../../../openapi-schema/schemaTS';
import {
  Container as TableWrapper,
  Header,
  HeaderRow,
  HeaderWrapper,
  Table,
  TableBody,
  TableData,
  TableRow,
} from '../../../../common/TableElements/TableElements';
import {
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const columnHelper = createColumnHelper<WordcloudItemTemplates>();

interface Props {
  data: WordcloudItemTemplates[];
}

export default function List({data}: Props) {
  const [selectedWordCloudWords, setSelectedWordCloudWords] = useRecoilState(
    selectedWordCloudWordsState
  );

  const columns = [
    columnHelper.accessor('text', {header: 'text', size: 1}),
    columnHelper.accessor('value', {header: 'Occurrences', size: 1}),
  ];

  const initialState = useMemo(() => {
    const selectedRowIds = data.reduce((acc, curr, index) => {
      return {...acc, [index]: selectedWordCloudWords.includes(curr.text)};
    }, {});
    return {
      rowSelection: selectedRowIds,
      sorting: [
        {
          id: 'value',
          desc: true,
        },
      ],
    };
  }, [data, selectedWordCloudWords]);

  const {getHeaderGroups, getRowModel, toggleAllRowsSelected} = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    initialState: initialState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: false,
    enableMultiRowSelection: false,
  });

  const handleToggle = (row: Row<WordcloudItemTemplates>) => {
    const updatedWordList = updateSelectedWordCloudWords(selectedWordCloudWords, row.original.text);
    setSelectedWordCloudWords(updatedWordList);
    row.toggleSelected(!row.getIsSelected());
  };

  useEffect(() => {
    if (isEmpty(selectedWordCloudWords)) {
      toggleAllRowsSelected(false);
    }
  }, [selectedWordCloudWords, toggleAllRowsSelected]);

  return (
    <Container>
      <TableWrapper>
        <Table>
          <HeaderWrapper>
            {getHeaderGroups().map(headerGroup => (
              <HeaderRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Header
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{...getHeaderStyles(header)}}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <TableSortArrow column={header} />
                  </Header>
                ))}
              </HeaderRow>
            ))}
          </HeaderWrapper>
          <TableBody>
            {getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                onClick={() => handleToggle(row)}
                isSelected={row.getIsSelected()}
              >
                {row.getVisibleCells().map(cell => (
                  <TableData key={cell.id} style={{...getCellStyles(cell)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Container>
  );
}
