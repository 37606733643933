import {Chip as MuiChip} from '@mui/material';
import classNames from 'classnames';
import {ReactElement} from 'react';
import CloseIcon from '@mui/icons-material/Close';

const baseClasses =
  'text-label-large rounded-lg h-8 [&>span]:px-2 [&>svg]:text-[18px] [&>svg]:m-0 capitalize';
const unselectedClasses =
  'px-2 text-light-on-surface-variant bg-light-surface  [&>svg:first-of-type]:text-light-primary-fixed-variant';
const darkUnselectedClasses =
  'dark:text-dark-on-surface-variant dark:bg-dark-surface  [&>svg:first-of-type]:dark:text-dark-primary-fixed-dim';
const unselectedClickableClasses =
  'hover:bg-[#E4DDE1] focus:bg-[#E4DDE1] active:bg-[#E4DDE1] dark:hover:bg-[#353039] dark:focus:bg-[#353039] dark:active:bg-[#353039]';
const selectedClasses =
  'px-2 border-none text-light-on-primary-container bg-light-primary-container [&>svg]:text-inherit';
const darkSelectedClasses = 'dark:text-dark-on-primary-container dark:bg-dark-outline-variant';
const selectedClickableClasses =
  'hover:bg-[#D8C3E4] focus:bg-[#D8C3E4] active:bg-[#D8C3E4] dark:hover:bg-[#615B62] dark:focus:bg-[#615B62] dark:active:bg-[#615B62]';
const outlinedClasses = 'border border-light-outline border-solid px-[7px]';
const darkOutlinedClasses = 'dark:border-dark-outline';
const outlinedClickableClasses =
  'focus:border-light-on-surface focus:dark:border-dark-inverse-surface';
const elevatedClasses = 'border-none shadow-elevation-1';
const elevatedClickableClasses = 'hover:shadow-elevation-2';
const disabledClasses = 'shadow-none opacity-40';

interface Props {
  label: string;
  variant?: 'outlined' | 'elevated';
  icon?: ReactElement;
  selectedIcon?: ReactElement;
  onDelete?: () => void;
  disabled?: boolean;
  onClick?: () => void;
  selected?: boolean;
}

export default function ChipBase({
  label,
  variant = 'outlined',
  icon,
  selectedIcon,
  onDelete,
  onClick,
  disabled = false,
  selected = false,
}: Props) {
  return (
    <MuiChip
      className={classNames(baseClasses, {
        [`${outlinedClasses} ${darkOutlinedClasses}`]: variant === 'outlined' && !selected,
        [elevatedClasses]: variant === 'elevated',
        [`${selectedClasses} ${darkSelectedClasses}`]: selected,
        [`${unselectedClasses} ${darkUnselectedClasses}`]: !selected,
        [selectedClickableClasses]: selected && Boolean(onClick),
        [unselectedClickableClasses]: !selected && Boolean(onClick),
        [outlinedClickableClasses]: variant === 'outlined' && Boolean(onClick),
        [elevatedClickableClasses]: variant === 'elevated' && Boolean(onClick),
        [disabledClasses]: disabled,
      })}
      label={label}
      icon={selected && selectedIcon ? selectedIcon : icon}
      disabled={disabled}
      onDelete={onDelete}
      deleteIcon={<CloseIcon className="text-inherit" />}
      clickable={Boolean(onClick)}
      onClick={onClick}
    />
  );
}
