import {ReactElement} from 'react';
import ChipBase from '../ChipBase';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  label: string;
  variant?: 'outlined' | 'elevated';
  icon?: ReactElement;
  onDelete?: () => void;
  disabled?: boolean;
  onClick?: () => void;
  selected?: boolean;
}

export default function FilterChip(props: Props) {
  return <ChipBase {...props} selectedIcon={<CheckIcon />} />;
}
