import styled from 'styled-components';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';

const Container = styled.div`
  display: flex;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
`;

const TitleWrapper = styled.div``;

export default function TableTitleRow() {
  return (
    <Container>
      <TitleWrapper>
        <ComponentTitle title="Flagged Users" />
      </TitleWrapper>
    </Container>
  );
}
