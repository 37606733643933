import styled from 'styled-components';

export const PopperBody = styled.div`
  background: rgba(63, 63, 63, 0.7);
  color: white;
  justify-content: space-between;
  white-space: nowrap;
  padding: 5px;
  position: relative;
  top: 10px;
`;
