interface PageIndexProps {
  pageCount: number;
  pageIndex: number;
  width: number | undefined;
}

const PageIndex = (props: PageIndexProps) => {
  const {pageCount, pageIndex, width} = props;
  const content =
    !width || width < 410
      ? `${pageIndex + 1}/${pageCount}`
      : `Page ${pageIndex + 1} of ${pageCount}`;

  return <p>{content}</p>;
};

export default PageIndex;
