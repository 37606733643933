import styled from 'styled-components';
import {MouseEventHandler} from 'react';

interface ContainerProps {
  $background: ComponentTitleBackground;
}

const Title = styled.span<ContainerProps>`
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
  color: white;
  padding: 6px 10px;
  background-color: ${props =>
    props.$background === 'dark' ? 'rgba(32, 32, 32, 1)' : 'rgba(43, 43, 43, 1)'};
  display: inline-block;
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
`;

type ComponentTitleBackground = 'dark' | 'light';

interface Props {
  title: string;
  background?: ComponentTitleBackground;
  onClick?: MouseEventHandler;
}

export default function ComponentTitle({title, background = 'light', onClick}: Props) {
  return (
    <Title data-testid="performanceChartTitle" $background={background} onClick={onClick}>
      {title}
    </Title>
  );
}
