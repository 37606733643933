import {WhatIfStats} from 'atoms/selectedWhatIfStat';
import {WhatIfCPU, WhatIfMemory, WhatIfNodeMetricsKeys} from '../../../../constants/WhatIfMetrics';
import {WhatIfStatDataKeys} from '../../../../constants/WhatIfStats';
import {
  NumericSummary,
  SummaryInterfaceUtilization,
  SummaryNodeTcamUsageMetric,
} from '../../../../openapi-schema/schemaTS';
import {scaleValue} from './whatIfScalingFunctions';

export interface ScaledWhatIfMetricData {
  name: string | undefined;
  value: number | undefined;
}

export const whatIfScalingFunctionNodeTooltip = (
  elementData: any,
  statToggle: WhatIfStats,
  sliderValue: number,
  selectedMetric: string
): ScaledWhatIfMetricData[] | undefined => {
  if (!elementData) return undefined;
  const selectedStat = WhatIfStatDataKeys[statToggle] as keyof NumericSummary;
  const metricKey = WhatIfNodeMetricsKeys[selectedMetric as keyof typeof WhatIfNodeMetricsKeys];

  if (selectedMetric === WhatIfCPU || selectedMetric === WhatIfMemory) {
    return [
      {
        name: selectedMetric,
        value: scaleValue(elementData[metricKey]?.[selectedStat], sliderValue),
      },
    ];
  }

  return elementData.metrics.map((metric: SummaryNodeTcamUsageMetric) => {
    return {
      name: metric.resource_description,
      value: scaleValue(metric.numeric_summary[selectedStat], sliderValue),
    };
  });
};

export interface EdgeScaledWhatIfMetricData {
  int1: ScaledWhatIfMetricData[] | undefined;
  int2: ScaledWhatIfMetricData[] | undefined;
}

export const whatIfScalingFunctionEdgeTooltip = (
  elementData: {
    int1: SummaryInterfaceUtilization | undefined;
    int2: SummaryInterfaceUtilization | undefined;
  },
  statToggle: WhatIfStats,
  sliderValue: number
): EdgeScaledWhatIfMetricData => {
  const selectedStat = WhatIfStatDataKeys[statToggle] as keyof NumericSummary;

  let int1: ScaledWhatIfMetricData[] | undefined;
  let int2: ScaledWhatIfMetricData[] | undefined;

  if (elementData.int1) {
    int1 = [
      {
        name: 'Util In',
        value: scaleValue(elementData.int1?.input_utilization[selectedStat], sliderValue),
      },
      {
        name: 'Util Out',
        value: scaleValue(elementData.int1?.output_utilization[selectedStat], sliderValue),
      },
    ];
  }

  if (elementData.int2) {
    int2 = [
      {
        name: 'Util In',
        value: scaleValue(elementData.int2?.input_utilization[selectedStat], sliderValue),
      },
      {
        name: 'Util Out',
        value: scaleValue(elementData.int2?.output_utilization[selectedStat], sliderValue),
      },
    ];
  }

  return {int1, int2};
};
