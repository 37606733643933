import {ClickAwayListener} from '@mui/material';
import {Dispatch, KeyboardEvent, MouseEvent, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

const InputWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
`;

const Input = styled.input`
  flex: 1;
  min-width: 0;
  background: inherit;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(215, 153, 251, 1);
  padding: 0 0 1px 0;
  color: white;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 2px;

  :focus {
    border: none;
    border-bottom: 1px solid rgba(215, 153, 251, 1);
    box-shadow: none;
  }
`;

const IconWrapper = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
`;

const iconSX = {
  fontSize: '16px',
  opacity: '0.4',
  color: 'white !important',
  margin: '0 2px',
  '&:hover': {opacity: '1'},
};

interface InputComponentProps {
  inputOpen: boolean;
  setInputOpen: Dispatch<SetStateAction<boolean>>;
  inputValue: string;
  onSubmit: (input: string) => void;
}

export function InputComponent({
  inputOpen,
  setInputOpen,
  inputValue,
  onSubmit,
}: InputComponentProps) {
  const [input, setInput] = useState(inputValue);

  const handleConfirmClick = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    submitInput();
  };

  const handleCancelClick = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    closeInput();
  };

  const closeInput = () => {
    setInputOpen(false);
    setInput(inputValue);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submitInput();
    }
  };

  const submitInput = () => {
    setInputOpen(false);
    onSubmit(input);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (inputOpen) {
          input !== inputValue ? onSubmit(input) : closeInput();
        }
      }}
    >
      <InputWrapper>
        <Input
          autoFocus
          value={input}
          onChange={e => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <IconWrapper>
          <CheckCircleIcon sx={iconSX} onClick={handleConfirmClick} />
        </IconWrapper>
        <IconWrapper>
          <CloseIcon sx={iconSX} onClick={handleCancelClick} />
        </IconWrapper>
      </InputWrapper>
    </ClickAwayListener>
  );
}
