import styled from 'styled-components';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  background: black;
  color: white;
`;

export const ComponentTitleWrapper = styled.div`
  padding: 10px;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

export const HeaderWrapper = styled.thead`
  width: calc(100% - 6px);
  display: block;
`;

export const HeaderRow = styled.tr`
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;
  min-height: 26px;
  text-transform: uppercase;
  margin-bottom: 4px;
  display: flex;
`;

export type TextAlign = 'left' | 'center' | 'right';

interface HeaderProps {
  textAlign?: TextAlign;
  highlight?: boolean;
  wrapText?: boolean;
  isGroupedHeader?: boolean;
}

export const Header = styled.th<HeaderProps>`
  display: flex;
  justify-content: ${props =>
    props.textAlign === 'right'
      ? 'flex-end'
      : props.textAlign === 'center'
      ? 'center'
      : 'flex-start'};
  align-items: center;
  white-space: pre-line;
  user-select: none;
  text-align: left;
  padding: ${props => (props.isGroupedHeader ? 0 : '0 10px')};
`;

export const TableBody = styled.tbody`
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface TableRowProps {
  isSelected?: boolean;
  isDisabled?: boolean;
  noSelect?: boolean;
  isRead?: boolean;
  isHighlighted?: boolean;
  isExpandable?: boolean;
  isOpened?: boolean;
}

export const TableRow = styled.tr<TableRowProps>`
  flex: initial !important;
  display: flex;
  background: ${props => {
    if (props.isSelected) return 'rgba(47, 47, 47, 1)';
    if (props.isHighlighted) return 'rgba(205, 62, 56, 0.21)';
    if (props.isOpened) return 'rgb(70,70,70)';
    return 'inherit';
  }};
  cursor: ${props => (props.noSelect ? 'default' : 'pointer')};
  color: ${props => {
    if (props.isDisabled) return 'rgba(255, 255, 255, 0.4)';
    if (props.isRead) return 'rgba(255, 255, 255, 0.8)';
    return 'white';
  }};
  font-weight: ${props => (props.isRead || props.isRead === undefined ? '400' : '700')};
  border: ${props => {
    if (props.isHighlighted) return '1px solid rgba(205, 62, 56, 1)';
    if (props.isOpened) return '1px solid white';
    return '1px solid black';
  }};
  border-bottom: ${props => {
    if (props.isOpened) return 'none';
    if (props.isHighlighted) return '1px solid rgba(205, 62, 56, 1)';
    return '1px solid black';
  }};
  padding: ${props => (props.isExpandable ? '12px 0' : '0')};
`;

export const TableData = styled.td<HeaderProps>`
  text-align: ${props => props.textAlign};
  font-size: 14px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${props => (props.wrapText ? 'wrap' : 'nowrap')};
  background: ${props => (props.highlight ? 'rgb(255 0 0 / 25%)' : '')};
  padding: 0 10px;
`;

interface InnerTableRowWrapperProps {
  isHighlighted?: boolean;
}

export const InnerTableRowWrapper = styled.tr<InnerTableRowWrapperProps>`
  display: block;
  padding: 0 10px 10px 10px;
  background: ${props => (props.isHighlighted ? 'rgba(205, 62, 56, 0.21)' : 'rgb(70, 70, 70)')};
  border: ${props => (props.isHighlighted ? '1px solid rgba(205, 62, 56, 1)' : '1px solid white')};
  border-top: none;
`;

export const InnerTableDataWrapper = styled.td`
  display: block;
`;

const ContentText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  text: string | undefined;
  tooltip?: string;
}

export const TextWithTooltip = ({text, tooltip}: Props) => (
  <Tooltip
    tooltipContent={<p>{tooltip ?? text}</p>}
    mainContent={<ContentText>{text}</ContentText>}
  />
);
