import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {overviewTimelineState} from '../../atoms/overviewTimeline';
import {useCallback, useRef} from 'react';

interface InputProps {
  pathname: string;
  additionalParams?: {[key: string]: any};
  replace?: boolean;
  clearStartEnd?: boolean;
}

const useNavigateKeepParams = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const overviewTimeline = useRecoilValue(overviewTimelineState);
  const selectedIntervalRef = useRef(overviewTimeline.selectedInterval);
  selectedIntervalRef.current = overviewTimeline.selectedInterval;

  return useCallback(
    (input: InputProps) => {
      const {pathname, additionalParams, replace, clearStartEnd} = input;
      const searchParamsObject = Object.fromEntries(Array.from(searchParamsRef.current));

      if (clearStartEnd) {
        delete searchParamsObject.start;
        delete searchParamsObject.end;
      } else {
        searchParamsObject.start = selectedIntervalRef.current.start
          .setZone('UTC')
          .toISO()
          .replaceAll(':', '-');
        searchParamsObject.end = selectedIntervalRef.current.end
          .setZone('UTC')
          .toISO()
          .replaceAll(':', '-');
      }

      navigate(
        {
          pathname,
          search: createSearchParams({...searchParamsObject, ...additionalParams}).toString(),
        },
        {replace: replace ?? false}
      );
    },
    [navigate]
  );
};

export default useNavigateKeepParams;
