import styled from 'styled-components';
import TitleTooltip from '../TitleTooltip/TitleTooltip';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: black;
  padding: 0 8px;
  gap: 6px;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  flex-grow: 1;
  border-radius: 8px;
  border: 1px solid #4b454d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
`;

interface ValueProps {
  small?: boolean;
}

const Value = styled.div<ValueProps>`
  font-size: ${props => (props.small ? '22px' : '30px')};
  line-height: 32px;
`;

const Measure = styled.p`
  font-size: 14px;
  line-height: 16px;
  opacity: 0.7;
`;

interface Props {
  value: React.ReactNode;
  measure: string;
  tooltipContent?: React.ReactNode;
  tooltipMaxWidth?: string;
  small?: boolean;
}

export default function KPIBox({
  measure,
  value,
  tooltipContent,
  tooltipMaxWidth,
  small = false,
}: Props) {
  return (
    <Container>
      <Value small={small}>{value ?? '—'}</Value>
      {tooltipContent ? (
        <TitleTooltip
          header={measure}
          tooltipContent={tooltipContent}
          mainContent={<Measure>{measure}</Measure>}
          maxWidth={tooltipMaxWidth}
        />
      ) : (
        <Measure>{measure}</Measure>
      )}
    </Container>
  );
}
