import {useCallback, useMemo, useState} from 'react';
import TopologyBase from '../TopologyBase/TopologyBase';
import {useRecoilValue} from 'recoil';
import {selectedNodeIdsState, selectedEdgeIdsState} from '../../../atoms/dashboard';
import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {TopologyElements} from '../../../data/types';
import {EventObjectEdge, EventObjectNode} from 'cytoscape';
import {getTopologyElementBackgroundColor} from '../../../utils/getTopologyElementBackgroundColor';
import {selectedAnomalyDataState} from '../../../atoms/selectedAnomaly';
import SimpleNetworkDeviceTooltip from '../TopologyTooltips/SimpleNetworkDeviceTooltip';
import SimpleNetworkInterfaceTooltip from '../TopologyTooltips/SimpleNetworkInterfaceTooltip';
import {useTopologyData} from 'data/Topology';

interface Props {
  isDetailsPage?: boolean;
}

export default function TopologyLogAndUtilization({isDetailsPage = false}: Props) {
  const {data: topologyElements, isLoading, isError, refetch} = useTopologyData();
  const [tooltipComponent, setTooltipComponent] = useState<JSX.Element | undefined>();
  const selectedNodeIds = useRecoilValue(selectedNodeIdsState);
  const selectedEdgeIds = useRecoilValue(selectedEdgeIdsState);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);

  const mouseOverNode = useCallback(
    (event: EventObjectNode) => {
      if (isDetailsPage) return;
      setTooltipComponent(<SimpleNetworkDeviceTooltip event={event} />);
    },
    [isDetailsPage]
  );

  const mouseOverEdge = useCallback(
    (event: EventObjectEdge) => {
      if (isDetailsPage) return;
      setTooltipComponent(<SimpleNetworkInterfaceTooltip event={event} />);
    },
    [isDetailsPage]
  );

  const mouseOutEdge = useCallback(
    (event: EventObjectEdge) => {
      if (isDetailsPage) return;
      setTooltipComponent(undefined);
    },
    [isDetailsPage]
  );

  const mouseOutNode = useCallback(
    (event: EventObjectNode) => {
      if (isDetailsPage) return;
      setTooltipComponent(undefined);
    },
    [isDetailsPage]
  );

  const formatNodes = useCallback(
    (topologyElements: TopologyElements) => {
      const highlightedBackgroundColor = getTopologyElementBackgroundColor(
        selectedAnomalyData?.priority_classification
      );

      return topologyElements.nodes.map(node => {
        return {
          ...node,
          data: {
            ...node.data,
            selectedBackgroundColor: highlightedBackgroundColor,
          },
        };
      });
    },
    [selectedAnomalyData]
  );

  const formatEdges = useCallback(
    (topologyElements: TopologyElements) => {
      const highlightedLinecolor = getTopologyElementBackgroundColor(
        selectedAnomalyData?.priority_classification
      );
      return topologyElements.edges.map(edge => {
        return {
          ...edge,
          data: {
            ...edge.data,
            selectedLineColor: highlightedLinecolor,
          },
        };
      });
    },
    [selectedAnomalyData]
  );

  const graphElements = useMemo(() => {
    return {
      nodes: formatNodes(topologyElements),
      edges: formatEdges(topologyElements),
    };
  }, [formatNodes, topologyElements, formatEdges]);

  if (isError) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <TopologyBase
      mouseOverNode={mouseOverNode}
      mouseOverEdge={mouseOverEdge}
      mouseOutNode={mouseOutNode}
      mouseOutEdge={mouseOutEdge}
      selectedNodeIds={selectedNodeIds}
      selectedEdgeIds={selectedEdgeIds}
      zoomToSelectedElements={isDetailsPage}
      graphElements={graphElements}
      tooltipComponent={tooltipComponent}
    />
  );
}
