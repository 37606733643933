import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {
  whatIfSelectedEdgeDataState,
  whatIfSelectedNodeDataState,
} from '../../../../atoms/whatIfSelectedTopologyElements';
import GetWhatIfChartData from './GetWhatIfChartData';
import NoResults from 'components/common/NoResults/NoResults';

const ChartWrapper = styled.div`
  position: absolute;
  width: 640px;
  height: 440px;
  top: 0;
  right: 0;
  border: 10px solid rgba(25, 25, 25, 1);
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: black;
  color: white;
`;

export default function WhatIfChartWrapper() {
  const whatIfSelectedNodeData = useRecoilValue(whatIfSelectedNodeDataState);
  const whatIfSelectedEdgeData = useRecoilValue(whatIfSelectedEdgeDataState);

  return (
    <ChartWrapper>
      {whatIfSelectedNodeData || whatIfSelectedEdgeData ? (
        <GetWhatIfChartData />
      ) : (
        <NoResults
          header="Metrics Analysis"
          message="Select a topology element to explore the data"
        />
      )}
    </ChartWrapper>
  );
}
