import DefinitionTooltip from '../DefinitionTooltip';

export default function FingerprintDefinition() {
  return (
    <DefinitionTooltip
      word="Fingerprint"
      definition={
        <span>
          A <b>Fingerprint</b> is a collection of <b>Log Templates</b> that are used to identify and
          categorize <b>Observations</b> allowing you to quickly and efficiently manage network
          anomalies and resolve incidents with minimal manual intervention.
        </span>
      }
    />
  );
}
