import TextField from '@mui/material/TextField';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import styled from 'styled-components';
import {useRecoilValue} from 'recoil';
import {selectedTimezoneState} from '../../../../atoms/selectedTimezone';
import {DateTime} from 'luxon';

const Container = styled.div`
  .MuiOutlinedInput-root {
    border: 1px solid rgba(78, 78, 78, 1);
    border-radius: 1px;
    padding: 6px;
  }

  .MuiOutlinedInput-input {
    padding: 0;
    color: white;
    font-size: 14px;
    font-weight: 400;
    height: 16px;
    width: 82px;
  }

  .MuiButtonBase-root {
    padding: 0;
    margin-right: 0px;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    color: white;
  }

  fieldset {
    display: none;
  }

  label {
    display: none;
  }
`;

const CLOCK_ARROW = `
  .MuiClockPicker-arrowSwitcher
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium
`;
const CLOCK_ARROW_DISABLED = `
  .MuiClockPicker-arrowSwitcher
  .Mui-disabled
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium
`;
const CLOCK_BACKGROUND = `
  .MuiClockPicker-root
`;
const CLOCK_FACE = `${CLOCK_BACKGROUND} > div:last-of-type`;
const CLOCK_NUMBERS = `${CLOCK_BACKGROUND} span`;

interface ClockProps {
  date: DateTime;
  setCustomTime: (date: DateTime) => void;
}

export default function Clock({date, setCustomTime}: ClockProps) {
  const selectedTimezone = useRecoilValue(selectedTimezoneState);

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <TimePicker
          PopperProps={{
            sx: {
              [CLOCK_ARROW]: {
                color: 'white',
              },
              [CLOCK_ARROW_DISABLED]: {
                color: 'rgba(255, 255, 255, 0.5)',
              },
              [CLOCK_BACKGROUND]: {
                backgroundColor: 'rgba(42, 42, 42, 1)',
              },
              [CLOCK_FACE]: {
                'div:first-of-type': {
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                },
              },
              [CLOCK_NUMBERS]: {
                color: 'white',
              },
            },
          }}
          value={date.setZone(selectedTimezone)}
          ampm={false}
          onChange={(newValue, inputString) => {
            if (newValue?.isValid) {
              if (inputString) {
                setCustomTime(DateTime.fromFormat(inputString, 'HH:mm', {zone: selectedTimezone}));
              } else {
                setCustomTime(newValue);
              }
            }
          }}
          renderInput={params => <TextField {...params} label="time input" autoComplete="off" />}
        />
      </LocalizationProvider>
    </Container>
  );
}
