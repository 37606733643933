import styled from 'styled-components';
import GoldenLayout from '../../../../common/GoldenLayout/GoldenLayout';
import {BACKGROUND_COLOR_MAIN} from '../colors';
import TemplateDisplay from './TemplateDisplay';
import ScriptDisplay from './ScriptDisplay';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default function VariableAssigmentPage() {
  return (
    <Container>
      <GoldenLayout
        background={BACKGROUND_COLOR_MAIN}
        content={[
          {
            type: 'row',
            width: 100,
            content: [
              {
                type: 'row',
                content: [
                  {
                    type: 'column',
                    component: () => <ScriptDisplay />,
                    width: 50,
                  },
                  {
                    type: 'column',
                    component: () => <TemplateDisplay />,
                    width: 50,
                  },
                ],
              },
            ],
          },
        ]}
        padding="0px"
      />
    </Container>
  );
}
