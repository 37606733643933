import IconComponent, {IconProps} from '../IconComponent';

export default function LatencyIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="LatencyIcon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 2C3 1.44772 3.44772 1 4 1H10C10.5523 1 11 1.44772 11 2V4L9 7L11 10V12C11 12.5523 10.5523 13 10 13H4C3.44772 13 3 12.5523 3 12V10L5 7L3 4V2ZM4.5 3C4.29399 3 4.17639 3.23519 4.3 3.4L6.8 6.73333C6.9 6.86667 7.1 6.86667 7.2 6.73333L9.7 3.4C9.82361 3.23519 9.70601 3 9.5 3H4.5Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
