import AnomalyDetailKPI from '../../common/AnomalyDetailKPI/AnomalyDetailKPI';
import InterfaceChart from '../InterfaceChart/InterfaceChart';
import InterfaceAnomalyTable from '../InterfaceAnomalyTable/InterfaceAnomalyTable';
import TopologyDetailPage from '../../common/TopologyDetailPage/TopologyDetailPage';
import AssociatedAnomaliesInterface from '../AssociatedAnomaliesInterface/AssociatedAnomaliesInterface';
import AssociatedLogsUtilization from '../AssociatedLogsUtilization/AssociatedLogsUtilization';
import PerformanceMetricsChartWrapper from '../../common/PerformanceMetricsChartWrapper/PerformanceMetricsChartWrapper';
import GoldenLayout from '../../../common/GoldenLayout/GoldenLayout';

const InterfaceDetailDashboard = () => {
  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              type: 'column',
              width: 74,
              content: [
                {
                  type: 'row',
                  content: [
                    {
                      component: () => <InterfaceAnomalyTable />,
                      width: 60,
                    },
                    {
                      component: () => (
                        <PerformanceMetricsChartWrapper>
                          <InterfaceChart />
                        </PerformanceMetricsChartWrapper>
                      ),
                      width: 40,
                    },
                  ],
                },
                {
                  component: () => <AssociatedLogsUtilization />,
                  height: 50,
                },
              ],
            },
            {
              type: 'column',
              width: 26,
              content: [
                {
                  component: () => <AnomalyDetailKPI />,
                  height: 30,
                },
                {
                  component: () => <AssociatedAnomaliesInterface />,
                  height: 40,
                },
                {
                  component: () => <TopologyDetailPage />,
                  height: 30,
                },
              ],
            },
          ],
        },
      ]}
      padding="7px 10px 10px 10px"
    />
  );
};

export default InterfaceDetailDashboard;
