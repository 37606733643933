import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';
import styled from 'styled-components';

const Word = styled.span`
  font-weight: 700;
  text-decoration: underline;
  text-transform: capitalize;
  cursor: default;

  :hover {
    color: rgba(215, 153, 251, 1);
  }
`;

interface Props {
  definition: React.ReactNode;
  word: string;
}

export default function DefinitionTooltip({word, definition}: Props) {
  return (
    <TitleTooltip
      header="Definition"
      tooltipContent={definition}
      mainContent={<Word>{word}</Word>}
    />
  );
}
