import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {ServiceNowModalActions} from '../ServiceNowModalActions';

const Container = styled.div`
  background: rgba(30, 30, 30, 1);
  color: rgba(231, 231, 232, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  height: 64px;
  padding: 0 52px 0 16px;
  position: relative;
`;

const HeaderTitle = styled.p`
  font-size: 20px;
  line-height: 28px;
`;

const HeaderSubtitle = styled.p`
  font-size: 12px;
  line-height: 14px;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
`;

interface Props {
  title: string;
  subtitle?: string | JSX.Element;
}
export default function ModalHeader({title, subtitle}: Props) {
  const {closeModal} = ServiceNowModalActions();

  return (
    <Container>
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderSubtitle>{subtitle}</HeaderSubtitle>
      <IconContainer onClick={closeModal}>
        <CloseIcon />
      </IconContainer>
    </Container>
  );
}
