import {ReactNode} from 'react';
import styled from 'styled-components';

const Row = styled.tr`
  height: 100%;
`;

const TableData = styled.td`
  height: 100%;
  display: block;
`;

interface Props {
  children: ReactNode;
}

export default function FullSizeTableElement({children}: Props) {
  return (
    <Row data-testid="full-size-table-element">
      <TableData>{children}</TableData>
    </Row>
  );
}
