import {Location, Navigate, Outlet, useLocation} from 'react-router-dom';
import {useAuth} from 'react-oidc-context';
import Splash from '../components/common/LoginPage/Splash';
import LoadingSpinner from '../components/common/LoadingSpinner/LoadingSpinner';

export interface LocationState {
  from: Location;
}

const PrivateRoute = () => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.isLoading && !auth.isAuthenticated) {
    return (
      <Splash>
        <div className="h-10">
          <LoadingSpinner bgColor={'transparent'} />
        </div>
      </Splash>
    );
  }

  if (auth.isAuthenticated && !auth.error) {
    return <Outlet />;
  } else {
    // Keep the requested url so that we can send the user there after logging in
    return <Navigate to="/login" state={{from: location} as LocationState} replace />;
  }
};

export default PrivateRoute;
