import {DateTime, Interval} from 'luxon';
import AppConfig from './AppConfigurationService';

const calculateTimelineInterval = (startTime: DateTime, endTime: DateTime): Interval => {
  const range = Interval.fromDateTimes(startTime, endTime).length('milliseconds');
  const startIntervalTime = startTime.minus(range / AppConfig.timelineWindowScaleFactor);
  const endIntervalTime = endTime.plus(range / AppConfig.timelineWindowScaleFactor);

  return Interval.fromDateTimes(startIntervalTime, endIntervalTime);
};

export default calculateTimelineInterval;
