import config from './AppConfigurationService';
import {PriorityClassificationBucket} from '../openapi-schema/schemaTS';

export const getTopologyElementBackgroundColor = (
  priority: PriorityClassificationBucket | undefined
): string => {
  if (priority === undefined) {
    return config.PriorityColors.undefined;
  }
  return config.PriorityColors[priority];
};
