import {atom} from 'recoil';

export const TEMPLATE = 'Templates';
export const LOGS = 'Logs';

const defaultViewState = LOGS;

export const logDetailViewState = atom({
  key: 'logDetailView',
  default: defaultViewState,
});
