import {monthDayYear, hourMinute, hourMinuteSec, monthDay} from '../../../../constants/timeFormats';
import {DateTime, Interval} from 'luxon';

export const getTimestampFormat = (interval: Interval, time: DateTime) => {
  if (interval.length('hours') <= 1) {
    return hourMinuteSec;
  }
  if (interval.length('hours') <= 24) {
    return hourMinute;
  }
  if (interval.length('days') <= 9) {
    if (time.get('hour') === 0) {
      return monthDay;
    }
    return hourMinute;
  }
  if (interval.length('years') <= 1) {
    return monthDay;
  }

  return monthDayYear;
};
