import styled from 'styled-components';
import {GhostIcon} from 'components/Katana/Icons';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
`;

const Title = styled.p`
  font-size: 30px;
  line-height: 35px;
  margin-top: 2px;
  margin-bottom: 4px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 19px;
  width: 500px;
  max-width: 90%;
  text-align: center;
`;

const ActionButton = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: rgba(33, 159, 216, 1);
  cursor: pointer;
  line-height: 19px;
  margin-top: 10px;
`;

interface Props {
  header?: string;
  message?: React.ReactNode;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
}

const NoResults = ({header, message, actionButtonText, onActionButtonClick}: Props) => {
  const title = header || 'No Results';
  const subtitle = message ?? 'No results found, adjust the timeline or filters';

  return (
    <Container data-testid="no-results">
      <GhostIcon />
      <Title data-testid="no-results-title">{title}</Title>
      <Subtitle data-testid="no-results-subtitle">{subtitle}</Subtitle>
      {actionButtonText && onActionButtonClick && (
        <ActionButton data-testid="no-results-action-button" onClick={onActionButtonClick}>
          {actionButtonText}
        </ActionButton>
      )}
    </Container>
  );
};

export default NoResults;
