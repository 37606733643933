import styled from 'styled-components';
import {useSelectedAnomaly} from '../../../../data/Anomalies';
import MiniLoadingSpinner from '../../../common/MiniLoadingSpinner/MiniLoadingSpinner';

const Container = styled.div`
  display: flex;
  color: white;
  gap: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 10px;
  align-items: center;
`;

const LoadingMessageText = styled.p`
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;
`;

export default function LoadingMessage() {
  const {isLoading} = useSelectedAnomaly();

  return (
    <>
      {isLoading && (
        <Container>
          <MiniLoadingSpinner />
          <LoadingMessageText>Loading affected topology elements...</LoadingMessageText>
        </Container>
      )}
    </>
  );
}
