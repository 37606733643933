import WatchLaterIcon from '@mui/icons-material/WatchLater';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {ClickAwayListener} from '@mui/material';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {selectedTimezoneState} from '../../../../atoms/selectedTimezone';
import config from '../../../../utils/AppConfigurationService';
import {Dispatch, SetStateAction, MouseEventHandler} from 'react';
import {useAuth} from 'react-oidc-context';
import classnames from 'classnames';
import UserInfo from './UserInfo';
import {manageuserAccessModalOpen} from '../../../../atoms/adminSettings';
import {noop} from 'lodash';

interface Props {
  userName: string;
  setAccountDropdownOpen: Dispatch<SetStateAction<boolean>>;
}

export default function AccountDropdown({userName, setAccountDropdownOpen}: Props) {
  const auth = useAuth();
  const [selectedTimezone, setSelectedTimezone] = useRecoilState(selectedTimezoneState);

  const logout = function () {
    auth.signoutRedirect({
      post_logout_redirect_uri: window.location.origin,
    });
  };

  const changeTimezone = () => {
    selectedTimezone === config.timezoneCodes.UTC
      ? setSelectedTimezone(config.timezoneCodes.Local)
      : setSelectedTimezone(config.timezoneCodes.UTC);
  };

  return (
    <ClickAwayListener onClickAway={() => setAccountDropdownOpen(false)}>
      <div className="absolute right-2 top-full z-50 flex flex-col rounded-md border border-dark-outline-variant bg-dark-surface text-white shadow-elevation-1">
        <UserInfo
          className="px-4 py-4"
          userName={userName}
          email={auth.user?.profile.email || ''}
        />
        <SectionHeader title="User Preferences" />
        <MenuItem icon={<WatchLaterIcon />} text={'Show Local Time'} onClick={changeTimezone}>
          <div className="ml-6 mr-1 h-6 w-6">
            {selectedTimezone === config.timezoneCodes.UTC ? (
              <ToggleOffOutlinedIcon sx={{fontSize: '24px', opacity: '0.5'}} />
            ) : (
              <ToggleOnIcon sx={{fontSize: '24px'}} />
            )}
          </div>
        </MenuItem>
        <RBACMenuItems callback={() => setAccountDropdownOpen(false)} />
        <SectionHeader />
        <MenuItem
          icon={<LogoutOutlinedIcon />}
          text={'Sign out'}
          onClick={logout}
          className="text-label-medium text-error-60"
        />
      </div>
    </ClickAwayListener>
  );
}

interface MenuItemProps {
  icon: JSX.Element;
  text: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  children?: JSX.Element | JSX.Element[];
  className?: string;
}
function MenuItem({icon, text, onClick, children, className}: MenuItemProps) {
  const wrapperClass = classnames(
    'flex cursor-pointer items-center gap-1 h-[56px] px-4 hover:bg-dark-primary/5',
    className
  );
  return (
    <div className={wrapperClass} onClick={onClick}>
      <div className="h-6 w-6">{icon}</div>
      <div className="pl-4 text-sm font-medium">{text}</div>
      {children}
    </div>
  );
}

interface SectionHeaderProps {
  title?: string;
}
function SectionHeader({title}: SectionHeaderProps) {
  const textStyles = 'p-2 px-4 tracking-widest text-base';
  const sectionClass = classnames(
    'text-dark-primary text-label-medium border-b border-b-neutral-20',
    title && textStyles
  );
  return <div className={sectionClass}>{title}</div>;
}

interface RBACMenuItemsProps {
  callback?: () => void;
}
function RBACMenuItems({callback = noop}: RBACMenuItemsProps) {
  const setManageUserAccessModalOpen = useSetRecoilState(manageuserAccessModalOpen);
  // TODO replace temporary stand-in with proper RBAC checking
  const rbacEnabled = config.featureFlags.rbac;
  if (rbacEnabled) {
    return (
      <>
        <SectionHeader title="Admin Settings" />
        <MenuItem
          icon={<PeopleAltOutlinedIcon />}
          text="Manage User Access"
          onClick={() => {
            setManageUserAccessModalOpen(true);
            callback();
          }}
        />
      </>
    );
  } else {
    return <></>;
  }
}
