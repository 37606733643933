import {BORDER_WIDTH_LARGE} from '../../../../../constants/TopologyContants';

export default [
  {
    selector: 'node',
    style: {
      label: 'data(label)',
      backgroundImage: 'data(icon)',
      width: 'data(width)',
      height: 'data(height)',
      color: 'white',
      fontSize: 'data(fontSize)',
      textMarginY: 5,
      backgroundColor: 'data(bgColor)',
      borderWidth: 'data(borderWidth)',
      borderColor: 'data(borderColor)',
      minZoomedFontSize: 10,
      textValign: 'bottom',
      textHalign: 'center',
      padding: 20,
      textWrap: 'wrap',
      textMaxWidth: 90,
      opacity: 1,
      borderStyle: 'data(borderStyle)',
      shape: 'data(shape)',
      backgroundWidth: 'data(backgroundWidth)',
      backgroundHeight: 'data(backgroundHeight)',
    },
  },
  {
    selector: 'edge',
    style: {
      label: 'data(label)',
      color: 'white',
      curveStyle: 'bezier',
      fontSize: 10,
      fontWeight: 500,
      width: 12,
      lineColor: 'data(lineColor)',
      textRotation: 'autorotate',
      minZoomedFontSize: 10,
      opacity: 1,
    },
  },
  {
    selector: 'node:selected',
    style: {
      backgroundColor: 'data(selectedBackgroundColor)',
      borderColor: 'data(selectedBorderColor)',
      borderWidth: BORDER_WIDTH_LARGE,
      width: 'data(selectedWidth)',
      height: 'data(selectedHeight)',
    },
  },
  {
    selector: 'edge:selected',
    style: {
      width: 14,
      lineColor: 'data(selectedLineColor)',
    },
  },
  {
    selector: 'core',
    style: {
      activeBgSize: 0,
    },
  },
  {
    selector: ':parent',
    style: {
      borderStyle: 'dashed',
      backgroundImageOpacity: 0,
      fontSize: 16,
    },
  },
];
