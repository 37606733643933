import IconComponent, {IconProps} from '../IconComponent';

export default function LowPriorityColorIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 20}} className={className}>
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="LowPriorityColorIcon"
      >
        <path
          d="M9.99984 1.66602C8.90549 1.66602 7.82186 1.88156 6.81081 2.30035C5.79976 2.71914 4.8811 3.33297 4.10728 4.10679C2.54448 5.6696 1.6665 7.78921 1.6665 9.99935C1.6665 12.2095 2.54448 14.3291 4.10728 15.8919C4.8811 16.6657 5.79976 17.2796 6.81081 17.6983C7.82186 18.1171 8.90549 18.3327 9.99984 18.3327C12.21 18.3327 14.3296 17.4547 15.8924 15.8919C17.4552 14.3291 18.3332 12.2095 18.3332 9.99935C18.3332 8.905 18.1176 7.82137 17.6988 6.81032C17.28 5.79927 16.6662 4.88061 15.8924 4.10679C15.1186 3.33297 14.1999 2.71914 13.1889 2.30035C12.1778 1.88156 11.0942 1.66602 9.99984 1.66602Z"
          fill="#7853CB"
        />
        <rect x="9" y="6" width="2" height="5" fill="white" />
        <rect x="9" y="12" width="2" height="2" fill="white" />
      </svg>
    </IconComponent>
  );
}
