import SearchBar from 'components/Katana/components/SearchBar/SearchBar';
import Title from 'components/Katana/components/Title/Title';
import AppliedFiltersList from 'components/Katana/patterns/TableSearchWithFilters/components/AppliedFiltersList';
import FiltersMenu from 'components/Katana/patterns/TableSearchWithFilters/components/FiltersMenu';
import {isEqual} from 'lodash';

export interface Filters {
  [key: string]: {[key: string]: boolean};
}

export type FiltersData = {
  category: string;
  groupedCategoryLabel: string;
  filterIconFunction: (filter: string) => JSX.Element;
}[][];

interface Props {
  tableTitle: string;
  onSearchBarSubmit: (input: string) => void;
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
  inputTextValue: string;
  filtersData: FiltersData;
  defaultFilters: Filters;
  filterValues: Filters;
  setFilterValues: (filters: Filters) => void;
}

export default function TableSearchWithFilters({
  tableTitle,
  onSearchBarSubmit,
  menuOpen,
  setMenuOpen,
  inputTextValue,
  filtersData,
  defaultFilters,
  filterValues,
  setFilterValues,
}: Props) {
  const noFiltersApplied = isEqual(filterValues, defaultFilters);
  const filterProps = {filtersData, defaultFilters, filterValues, setFilterValues};

  return (
    <div className="mx-2 flex flex-col gap-4 pb-4 pt-3">
      <div className="relative flex flex-row-reverse items-center ">
        <div className="absolute left-0">
          <Title title={tableTitle} />
        </div>
        <SearchBar
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          inputTextValue={inputTextValue}
          onSubmit={onSearchBarSubmit}
          MenuComponent={defaultMenuProps => <FiltersMenu {...defaultMenuProps} {...filterProps} />}
        />
      </div>
      {!noFiltersApplied && <AppliedFiltersList {...filterProps} />}
    </div>
  );
}
