import {MenuItem as MuiMenuItem} from '@mui/material';
import classNames from 'classnames';
import {MouseEventHandler, ReactNode} from 'react';

const baseClasses =
  'text-body-large px-3 text-light-on-surface dark:text-dark-inverse-surface disabled:opacity-40 hover:bg-light-on-surface/08 dark:hover:bg-light-on-primary/08';
const selectedClasses = 'bg-light-surface-container-highest dark:bg-dark-surface-container-highest';
const smallClasses = 'py-2';
const mediumClasses = 'py-3';
const largeClasses = 'py-4';

type MenuItemSize = 'small' | 'medium' | 'large';

interface Props {
  children: ReactNode;
  value?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLLIElement>;
  size?: MenuItemSize;
  leadingElement?: ReactNode;
  trailingElement?: ReactNode;
}

export default function MenuItem({
  children,
  selected,
  disabled = false,
  size = 'small',
  leadingElement,
  trailingElement,
  ...props
}: Props) {
  return (
    <MuiMenuItem
      {...props} // The MUI Select component injects props to its child elements
      className={classNames(baseClasses, {
        [selectedClasses]: selected,
        [smallClasses]: size === 'small',
        [mediumClasses]: size === 'medium',
        [largeClasses]: size === 'large',
      })}
      selected={selected}
      disabled={disabled}
    >
      <div className="flex w-full items-center gap-3">
        {leadingElement && <div className="flex h-6 w-6 [&>*]:text-24">{leadingElement}</div>}
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">{children}</div>
        {trailingElement && (
          <div className="ml-auto flex h-6 w-6 [&>*]:text-24">{trailingElement}</div>
        )}
      </div>
    </MuiMenuItem>
  );
}
