import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {stepWizardCurrentPageState} from '../../../../atoms/StepWizardCurrentPage';
import useStepWizardActions from '../../../../helpers/hooks/useStepWizardActions';
import {StepWizardPage} from '../StepWizard';
import RequiredFieldTooltip from './RequiredFieldTooltip';

const Container = styled.div`
  background: rgba(32, 34, 38, 1);
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px 13px 10px 10px;
  align-items: center;
  line-height: 16px;

  button {
    font-weight: 500;
    padding: 6px 10px;
    border: none;
    color: white;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }
`;

const NavButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  gap: 10px;
`;

const PurpleButton = styled.button`
  background: rgba(67, 79, 203, 1);
`;

const GrayButton = styled.button`
  background: rgba(75, 76, 85, 1);
`;

interface Props {
  pages: StepWizardPage[];
  onClose: () => void;
  onSubmit: () => void;
}

export default function Footer({pages, onSubmit, onClose}: Props) {
  const stepWizardCurrentPage = useRecoilValue(stepWizardCurrentPageState);
  const {goToNextPage, goToPreviousPage} = useStepWizardActions();

  const {tooltip, isNextDisabled, nextButton} = pages[stepWizardCurrentPage];

  return (
    <Container>
      <GrayButton onClick={onClose}>Cancel</GrayButton>
      <NavButtonsWrapper>
        {stepWizardCurrentPage !== 0 && <GrayButton onClick={goToPreviousPage}>Back</GrayButton>}
        <RequiredFieldTooltip
          tooltipContent={isNextDisabled && !!tooltip ? tooltip : ''}
          mainContent={
            <div>
              <PurpleButton
                onClick={() => goToNextPage(stepWizardCurrentPage === pages.length - 1, onSubmit)}
                disabled={isNextDisabled}
              >
                {nextButton || 'Next'}
              </PurpleButton>
            </div>
          }
        />
      </NavButtonsWrapper>
    </Container>
  );
}
