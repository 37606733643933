import styled from 'styled-components';
import SectionTitle from './SectionTitle';
import ButtonGroup from './ButtonGroup';
import {AnomalyTypeBucket, PriorityClassificationBucket} from '../../../../openapi-schema/schemaTS';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export interface ButtonDataFilter {
  priority_types?: PriorityClassificationBucket[];
  anomaly_types?: AnomalyTypeBucket[];
}

export interface ButtonData {
  text: string;
  icon?: JSX.Element;
  filter: ButtonDataFilter;
  title: string;
  value: number | undefined;
}

interface Props {
  data: ButtonData[];
  title: string;
}

export default function SectionGroup({data, title}: Props) {
  return (
    <Container>
      <SectionTitle title={title} />
      <ButtonGroup data={data} />
    </Container>
  );
}
