export const WhatIfAVG = 'Average';
export const WhatIfMAX = 'Max';
export const WhatIf1Sigma = '1 Sigma';
export const WhatIf2Sigma = '2 Sigma';

export const WhatIfStatDataKeys = {
  [WhatIfAVG]: 'avg',
  [WhatIfMAX]: 'max',
  [WhatIf1Sigma]: 'one_sigma',
  [WhatIf2Sigma]: 'two_sigma',
};
