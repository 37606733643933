import {ErrorIcon} from 'components/Katana/Icons';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
`;

const TryAgain = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
`;

interface Props {
  retry: () => void;
}

export default function KPIErrorState({retry}: Props) {
  return (
    <Container>
      <ErrorIcon className="mb-[5px] text-[36px]" />
      <TryAgain onClick={() => retry()}>Try Again</TryAgain>
    </Container>
  );
}
