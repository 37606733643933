import {Interval} from 'luxon';
import AppConfig from '../utils/AppConfigurationService';
import {Timeline} from 'atoms/dashboard';

const panTimeline = (
  direction: 1 | -1,
  timeline: Timeline,
  setInLiveMode: (value: boolean) => void,
  setTimelineStates: (value: Timeline) => void
) => {
  let startIntervalTime = timeline.timelineInterval.start;
  let endIntervalTime = timeline.timelineInterval.end;
  const range = timeline.timelineInterval.length('milliseconds');
  const shiftFactor = (direction * range) / AppConfig.timelineWindowShiftFactor;
  const repositionFactor = (direction * range) / AppConfig.timelineWindowRepositionFactor;

  const startTime = timeline.selectedInterval.start.plus({
    milliseconds: shiftFactor,
  });
  const endTime = timeline.selectedInterval.end.plus({
    milliseconds: shiftFactor,
  });

  if (startTime < startIntervalTime || endTime > endIntervalTime) {
    startIntervalTime = startIntervalTime.plus({
      milliseconds: repositionFactor,
    });
    endIntervalTime = endIntervalTime.plus({
      milliseconds: repositionFactor,
    });
  }

  setInLiveMode(false);

  const newTimeline = {
    selectedInterval: Interval.fromDateTimes(startTime, endTime),
    timelineInterval: Interval.fromDateTimes(startIntervalTime, endIntervalTime),
  };
  setTimelineStates(newTimeline);
};

export default panTimeline;
