import {useQueries, useQuery} from 'react-query';
import {useMemo} from 'react';
import groupBy from 'lodash/groupBy';
import {
  getDevices,
  getDevicesBGP,
  getDevicesCPU,
  getDevicesISIS,
  getDevicesTCAM,
  getInterfaces,
  getInterfacesFiberChannel,
  getInterfacesUtilization,
  getMetadataHostmap,
} from '../utils/PythonApiUtils';
import {useRecoilValue} from 'recoil';
import {topologyWindowState} from '../atoms/topologyWindow';
import {TopologyEdge, TopologyElements, TopologyNode} from './types';
import useDebouncedInput from '../helpers/hooks/useDebouncedInput';
import {timelineState} from '../atoms/dashboard';
import {selectedWhatIfMetricState} from '../atoms/selectedWhatIfMetric';
import {WhatIfCPU, WhatIfMemory, WhatIfTCAM, WhatIfUtilization} from '../constants/WhatIfMetrics';
import {selectedWhatIfInterfaceState} from '../atoms/selectedWhatIfInterface';
import {defaultEdgeAttributes, defaultNodeAttributes} from '../constants/TopologyContants';
import {
  whatIfSelectedEdgeDataState,
  whatIfSelectedNodeDataState,
} from '../atoms/whatIfSelectedTopologyElements';
import {mapValues} from 'lodash';
import {getTopologyNodeIcon} from 'utils/getTopologyNodeIcon';

export function useTopologyData() {
  const window = useRecoilValue(topologyWindowState);

  const res = useQueries([
    {
      queryKey: ['deviceData', window],
      queryFn: () => getDevices({start: window.start, end: window.end}),
      enabled: !!window.start,
    },
    {
      queryKey: ['interfaceData', window],
      queryFn: () => getInterfaces({start: window.start, end: window.end}),
      enabled: !!window.start,
    },
    {
      queryKey: ['metadataHostmap'],
      queryFn: () => getMetadataHostmap(),
    },
  ]);

  const isLoading = res.some(r => r.isLoading);
  const isError = res.some(r => r.isError);
  const deviceData = useMemo(() => res[0]?.data ?? [], [res]);
  const interfaceData = useMemo(() => res[1]?.data ?? [], [res]);
  const hostmapMetaData = useMemo(() => res[2]?.data ?? [], [res]);

  // useMemo for calculation with data, isLoading, and isError dependencies
  const customData = useMemo(() => {
    if (isLoading || isError || !deviceData.length || !interfaceData.length) {
      return {
        nodes: [],
        edges: [],
      };
    }

    const nodesByID = groupBy(deviceData, 'device_id');
    const hostMap = groupBy(hostmapMetaData, 'host');
    const ipMap = groupBy(hostmapMetaData, 'ip');

    const FinalNodes: TopologyNode[] = deviceData.map(node => {
      return {
        data: {
          ...node,
          ...defaultNodeAttributes,
          id: node.device_id,
          icon: getTopologyNodeIcon(node.device_category),
          label: `${node.device_category}\n${node.name}`,
        },
      };
    });

    const allEdges = interfaceData.reduce((acc, interfaceInfo) => {
      const targetNode = nodesByID[interfaceInfo.target_node_id]?.[0];
      const sourceNode = nodesByID[interfaceInfo.source_node_id]?.[0];

      if (
        targetNode?.device_id === interfaceInfo.target_node_id &&
        sourceNode?.device_id === interfaceInfo.source_node_id
      ) {
        // Only add edges where the source and target are verified in the nodes
        acc.push({
          data: {
            ...defaultEdgeAttributes,
            source: sourceNode.device_id,
            sourceIp: sourceNode.ip,
            target: targetNode.device_id,
            targetIp: targetNode.ip,
            ...interfaceInfo,
            id: `${interfaceInfo.source_int_id}${interfaceInfo.target_int_id}`,
            label: `${interfaceInfo.source_int_name} - ${interfaceInfo.target_int_name}` ?? '',
            opacity: 0.9,
          },
        });
      }

      return acc;
    }, [] as TopologyEdge[]);

    return {
      nodes: FinalNodes,
      edges: allEdges,
      edgesBySource: groupBy(allEdges, e => e.data.source),
      edgesByTarget: groupBy(allEdges, e => e.data.target),
      edgesBySubnet: groupBy(allEdges, e => e.data.subnet_id),
      nodesByIP: groupBy(FinalNodes, e => e.data.ip),
      nodesByName: groupBy(FinalNodes, e => e.data.name),
      nodesByLongName: groupBy(FinalNodes, e => e.data.long_name),
      nodesBySystemName: groupBy(FinalNodes, e => e.data.system_name),
      hostToSubnetMap: mapValues(hostMap, hostList => hostList.map(h => h.subnet_id)),
      ipToSubnetMap: mapValues(ipMap, hostList => hostList.map(h => h.subnet_id)),
    } as TopologyElements;
  }, [deviceData, interfaceData, hostmapMetaData, isLoading, isError]);

  const data = useMemo(() => {
    return {
      data: customData,
      isError,
      isLoading,
      refetch: () => {
        res.map(r => r.refetch());
      },
    };
  }, [customData, isError, isLoading, res]);

  return data;
}

export function useDeviceCPU() {
  const whatIfSelectedNodeData = useRecoilValue(whatIfSelectedNodeDataState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['deviceCPU', whatIfSelectedNodeData, debouncedStart, debouncedEnd],
    () =>
      getDevicesCPU({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        node_name: whatIfSelectedNodeData?.name,
        total_bins: 100,
      }),
    {
      enabled: selectedWhatIfMetric === WhatIfCPU || selectedWhatIfMetric === WhatIfMemory,
    }
  );
}

export function useTopologyCpuMem() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  const query = useQuery(
    ['topologyCpuMem', debouncedStart, debouncedEnd],
    () =>
      getDevicesCPU({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        total_bins: 1,
      }),
    {
      keepPreviousData: true,
      enabled: selectedWhatIfMetric === WhatIfCPU || selectedWhatIfMetric === WhatIfMemory,
    }
  );

  const CpuMemData = query?.data ?? [];

  return {
    ...query,
    data: {
      elementData: CpuMemData,
      nodesById: groupBy(CpuMemData, 'node_id'),
    },
  };
}

export function useTopologyUtilization() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  const query = useQuery(
    ['topologyUtilization', debouncedStart, debouncedEnd],
    () =>
      getInterfacesUtilization({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        total_bins: 1,
      }),
    {
      keepPreviousData: true,
      enabled: selectedWhatIfMetric === WhatIfUtilization,
    }
  );

  const utilizationData = query?.data ?? [];

  return {
    ...query,
    data: {
      elementData: utilizationData,
      interfaceIdentifierByNames: groupBy(utilizationData, d => `${d.node_name}_${d.int_name}`),
    },
  };
}

export function useDeviceUtilization() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedWhatIfInterface = useRecoilValue(selectedWhatIfInterfaceState);
  const whatIfSelectedEdgeData = useRecoilValue(whatIfSelectedEdgeDataState);
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  const interface_identifiers =
    whatIfSelectedEdgeData && selectedWhatIfInterface ? [selectedWhatIfInterface] : [];

  return useQuery(
    ['deviceUtilization', debouncedStart, debouncedEnd, interface_identifiers],
    () =>
      getInterfacesUtilization({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        total_bins: 100,
        interface_name_identifiers: interface_identifiers,
      }),
    {
      enabled: selectedWhatIfMetric === WhatIfUtilization && interface_identifiers.length > 0,
    }
  );
}

export function useTopologyTCAM() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  const query = useQuery(
    ['topologyTCAM', debouncedStart, debouncedEnd],
    () =>
      getDevicesTCAM({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        total_bins: 1,
      }),
    {
      keepPreviousData: true,
      enabled: selectedWhatIfMetric === WhatIfTCAM,
    }
  );

  const TCAMdata = query?.data ?? [];

  return {
    ...query,
    data: {
      elementData: TCAMdata,
      nodesById: groupBy(TCAMdata, 'node_id'),
    },
  };
}

export function useDeviceTCAM() {
  const whatIfSelectedNodeData = useRecoilValue(whatIfSelectedNodeDataState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['deviceTCAM', whatIfSelectedNodeData, debouncedStart, debouncedEnd],
    () =>
      getDevicesTCAM({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        node_name: whatIfSelectedNodeData?.name,
        total_bins: 100,
      }),
    {
      enabled: selectedWhatIfMetric === WhatIfTCAM,
    }
  );
}

export function useDevicesBGP() {
  const window = useRecoilValue(topologyWindowState);

  return useQuery(['devicesBGP', window], () =>
    getDevicesBGP({
      start: window.start,
      end: window.end,
    })
  );
}

export function useDevicesISIS() {
  const window = useRecoilValue(topologyWindowState);

  return useQuery(['devicesISIS', window], () =>
    getDevicesISIS({
      start: window.start,
      end: window.end,
    })
  );
}

export function useTopologyDataFiberChannel() {
  const window = useRecoilValue(topologyWindowState);

  const res = useQueries([
    {
      queryKey: ['deviceData', window],
      queryFn: () => getDevices({start: window.start, end: window.end}),
      enabled: !!window.start,
    },
    {
      queryKey: ['interfaceDataFiberChannel', window],
      queryFn: () => getInterfacesFiberChannel({start: window.start, end: window.end}),
      enabled: !!window.start,
    },
  ]);

  const isLoading = res.some(r => r.isLoading);
  const isError = res.some(r => r.isError);
  const deviceData = useMemo(() => res[0]?.data ?? [], [res]);
  const interfaceData = useMemo(() => res[1]?.data ?? [], [res]);
  // useMemo for calculation with data, isLoading, and isError dependencies
  const customData = useMemo(() => {
    if (isLoading || isError || !deviceData.length || !interfaceData.length) {
      return {
        nodes: [],
        edges: [],
      };
    }

    const interfacesByNodeName = groupBy(interfaceData, 'source_node_id');

    const filteredNodes = deviceData.filter(node => interfacesByNodeName[node.device_id]);
    const FinalNodes: TopologyNode[] = filteredNodes.map(node => {
      return {
        data: {
          ...node,
          ...defaultNodeAttributes,
          id: node.device_id,
          icon: getTopologyNodeIcon(node.device_category),
          label: `${node.device_category}\n${node.name}`,
        },
      };
    });

    return {
      nodes: FinalNodes,
      edges: [],
      interfacesBySourceIntId: groupBy(interfaceData, 'source_int_id'),
    } as TopologyElements;
  }, [deviceData, interfaceData, isLoading, isError]);

  const data = useMemo(() => {
    return {
      data: customData,
      isError,
      isLoading,
      refetch: () => {
        res.map(r => r.refetch());
      },
    };
  }, [customData, isError, isLoading, res]);

  return data;
}
