import TimelineRangeSlider from '../TimelineRangeSlider/TimelineRangeSlider';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Container} from './Timeline.styles';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {
  rangeTimelineState,
  Timeline as TimelineType,
  timelineState,
} from '../../../atoms/dashboard';
import RangeSelector from './components/RangeSelector';
import ZoomInOut from './components/ZoomInOut';
import styled from 'styled-components';
import AppConfig from '../../../utils/AppConfigurationService';
import useSetTimelineStates from '../../../helpers/hooks/useSetTimelineStates';
import {DateTime, Duration, Interval} from 'luxon';
import {selectedTimezoneState} from '../../../atoms/selectedTimezone';
import {inLiveModeState} from '../../../atoms/inLiveMode';
import Histogram from './components/Histogram';
import {getChartXAxisTickLabels} from 'utils/getChartXAxisTickLabels';

const TimelineWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
`;

export const createTimeline = (
  [start, end]: (Date | undefined)[],
  timeline: TimelineType,
  inLiveMode: boolean,
  setInLiveMode: (value: boolean) => void
) => {
  if (!start || isNaN(start.valueOf()) || !end || isNaN(end.valueOf())) {
    return timeline;
  }
  if (end.getTime() - start.getTime() < 0.01 * timeline.timelineInterval.length('milliseconds')) {
    const newEnd = DateTime.fromJSDate(end);
    const newStart = newEnd.minus(0.01 * timeline.timelineInterval.length('milliseconds'));
    return {
      ...timeline,
      selectedInterval: Interval.fromDateTimes(newStart, newEnd),
    };
  } else {
    if (inLiveMode && end.getTime() !== timeline.selectedInterval.end.toMillis()) {
      setInLiveMode(false);
    }
    return {
      ...timeline,
      selectedInterval: Interval.fromDateTimes(
        DateTime.fromJSDate(start),
        DateTime.fromJSDate(end)
      ),
    };
  }
};

const Timeline = () => {
  const [timeline] = useRecoilState<TimelineType>(timelineState);
  const [error, setError] = useState<Error | null>(null);
  const setTimelineStates = useSetTimelineStates();
  const selectedTimezone = useRecoilValue(selectedTimezoneState);
  const [inLiveMode, setInLiveMode] = useRecoilState(inLiveModeState);
  const [now, setNow] = useState(DateTime.utc().toMillis());
  const setRangeTimeline = useSetRecoilState(rangeTimelineState);

  useEffect(() => {
    setNow(DateTime.utc().toMillis());
    const liveInterval = setInterval(() => {
      setNow(DateTime.utc().toMillis());
      if (!inLiveMode) return;
      const range = timeline.selectedInterval.length('milliseconds');
      const newSelectedInterval = Interval.before(DateTime.utc(), Duration.fromMillis(range));
      const offset = DateTime.utc().diff(timeline.selectedInterval.end);
      const newTimelineInterval = timeline.timelineInterval.mapEndpoints(endpoint =>
        endpoint.plus(offset)
      );
      setTimelineStates({
        ...timeline,
        selectedInterval: newSelectedInterval,
        timelineInterval: newTimelineInterval,
      });
    }, AppConfig.liveModeIntervalSeconds * 1000);
    return () => clearInterval(liveInterval);
  }, [inLiveMode, setTimelineStates, timeline]);

  const disabledInterval = useMemo(() => {
    return [
      {
        start: now,
        end: DateTime.fromMillis(now).plus({years: 1000}).toMillis(),
      },
    ];
  }, [now]);

  const onSlideEnd = useCallback(
    ([start, end]: Date[]) => {
      const newTimeline = createTimeline([start, end], timeline, inLiveMode, setInLiveMode);
      setTimelineStates(newTimeline);
    },
    [timeline, inLiveMode, setInLiveMode, setTimelineStates]
  );

  const errorHandler = useCallback((error: Error) => {
    setError(error);
  }, []);

  const onUpdate = useCallback(
    (timelineData: {error: Error; time: Date[]}) => {
      const {error, time} = timelineData;
      if (error) {
        errorHandler(error);
      }
      const newTimeline = createTimeline(time, timeline, inLiveMode, setInLiveMode);
      setRangeTimeline(newTimeline);
    },
    [timeline, inLiveMode, setInLiveMode, setRangeTimeline, errorHandler]
  );

  const formatTick = useCallback(
    (ms: number) => {
      return getChartXAxisTickLabels(ms, timeline.timelineInterval, selectedTimezone);
    },
    [timeline.timelineInterval, selectedTimezone]
  );

  return (
    //TODO: change how Live mode is detected
    <Container live={inLiveMode}>
      <RangeSelector />
      <TimelineWrapper>
        {timeline && (
          <TimelineRangeSlider
            error={error}
            ticksNumber={12}
            selectedInterval={[
              timeline.selectedInterval.start.toJSDate(),
              timeline.selectedInterval.end.toJSDate(),
            ]}
            timelineInterval={[
              timeline.timelineInterval.start.toJSDate(),
              timeline.timelineInterval.end.toJSDate(),
            ]}
            onUpdateCallback={onUpdate}
            onErrorCallback={errorHandler}
            onSlideEndCallback={onSlideEnd}
            formatTick={formatTick}
            step={1}
            disabledIntervals={disabledInterval}
            timezone={selectedTimezone}
          />
        )}
        <Histogram />
      </TimelineWrapper>
      <ZoomInOut />
    </Container>
  );
};

export default Timeline;
