import Checkbox from '../Checkbox/Checkbox';
import styled from 'styled-components';
import {BACKGROUND_COLOR_HEADER} from '../../AnomalyDetail/Log/AutomateWindow/colors';
import {RecoilState, useRecoilState} from 'recoil';
import {Popover} from '@mui/material';
import {Column, Updater, VisibilityState} from '@tanstack/react-table';

const Container = styled.div`
  min-width: 120px;
  background-color: ${BACKGROUND_COLOR_HEADER};
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  gap: 7px;

  :hover {
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
`;

interface SelectorProps {
  hideColumn: (updater: Updater<VisibilityState>) => void;
  columns: Column<any>[];
}

function ColumnSelectorPopup(props: SelectorProps) {
  const {hideColumn, columns} = props;

  const handleChange = (column: Column<any>) => {
    const isVisibile = column.getIsVisible();
    hideColumn(state => {
      return {
        ...state,
        [column.id]: !isVisibile,
      };
    });
  };

  return (
    <Container>
      {columns.map(column => {
        return (
          <Item key={column.id} onClick={() => handleChange(column)}>
            <Checkbox checked={column.getIsVisible()} label={column.id} />
          </Item>
        );
      })}
    </Container>
  );
}

interface SelectorWrapperProps extends SelectorProps {
  recoilState: RecoilState<boolean>;
}

export function ColumnSelectorPopupWrapper({
  columns,
  hideColumn,
  recoilState,
}: SelectorWrapperProps) {
  const [selectorOpen, setSelectorOpen] = useRecoilState(recoilState);

  return (
    <Popover
      id="Popover"
      open={selectorOpen}
      anchorEl={document.getElementById(recoilState.key)}
      anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
      onClose={() => setSelectorOpen(false)}
      sx={{
        '.MuiPaper-root': {
          border: '1px solid rgba(93, 93, 93, 1)',
          borderRadius: '2px',
        },
      }}
    >
      <ColumnSelectorPopup columns={columns} hideColumn={hideColumn} />
    </Popover>
  );
}
