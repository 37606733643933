import {
  ExtremePriorityColorIcon,
  ExtremePriorityIcon,
  HighPriorityColorIcon,
  HighPriorityIcon,
  LowPriorityColorIcon,
  LowPriorityIcon,
  MediumPriorityColorIcon,
  MediumPriorityIcon,
  NonePriorityColorIcon,
  NonePriorityIcon,
  UnknownColorIcon,
  UnknownIcon,
} from 'components/Katana/Icons';
import {PriorityClassificationBucket} from 'openapi-schema/schemaTS';
import config from 'utils/AppConfigurationService';

interface Props {
  priority: PriorityClassificationBucket;
  color?: boolean;
  className?: string;
}

export default function ObservationPriorityIcon({priority, color, className}: Props) {
  const {EXTREME, HIGH, MEDIUM, LOW, NONE} = config.Priority;

  if (color) {
    if (priority === EXTREME) return <ExtremePriorityColorIcon className={className} />;
    if (priority === HIGH) return <HighPriorityColorIcon className={className} />;
    if (priority === MEDIUM) return <MediumPriorityColorIcon className={className} />;
    if (priority === LOW) return <LowPriorityColorIcon className={className} />;
    if (priority === NONE) return <NonePriorityColorIcon className={className} />;
    return <UnknownColorIcon className={className} />;
  }

  if (priority === EXTREME) return <ExtremePriorityIcon className={className} />;
  if (priority === HIGH) return <HighPriorityIcon className={className} />;
  if (priority === MEDIUM) return <MediumPriorityIcon className={className} />;
  if (priority === LOW) return <LowPriorityIcon className={className} />;
  if (priority === NONE) return <NonePriorityIcon className={className} />;
  return <UnknownIcon className={className} />;
}
