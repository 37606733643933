export const getReadableFileSizeString = (fileSizeInBytes: number): string => {
  if (isNaN(fileSizeInBytes)) {
    return '\u2014'
  }
  if (fileSizeInBytes === 0) {
    return '0 kbps'
  }
  let i = -1;
  const byteUnits = [' kbps', ' Mbps', ' Gbps', ' Tbps', ' Pbps', ' Ebps', ' Zbps', ' Ybps'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1000;
    i++;
  } while (fileSizeInBytes > 1000);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};
