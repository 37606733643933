import styled from 'styled-components';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import {HardwareCatalogTable} from './HardwareCatalogTable/HardwareCatalogTable';
import ColumnToggle from '../../../common/ColumnSelector/ColumnToggle';
import {hwlcColumnSelectorOpenState} from '../../../../atoms/hardwareCatalogColumnSlectorOpenState';

const Container = styled.div`
  background: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleAndSerachWrapper = styled.div`
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export function HardwareCatalog() {
  return (
    <Container>
      <TitleAndSerachWrapper>
        <TitleWrapper>
          <ComponentTitle title="Hardware Catalog" />
          <ColumnToggle recoilState={hwlcColumnSelectorOpenState} />
        </TitleWrapper>
        {/* <CatalogSearchBar /> */}
      </TitleAndSerachWrapper>
      <HardwareCatalogTable />
    </Container>
  );
}
