import styled from 'styled-components';
import ComponentTitle from '../../common/ComponentTitle/ComponentTitle';
import NoResults from 'components/common/NoResults/NoResults';
import {useSecurityEventLog} from '../../../data/Security';
import {useRecoilValue} from 'recoil';
import {selectedSecurityAnomalyIdState} from '../../../atoms/securityState';
import QueryLifecycleWrapper from '../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {isEmpty} from 'lodash';
import {HydratedLog} from '../../../utils/formatLogs';

const Container = styled.div`
  background: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const HeaderWrapper = styled.div`
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LogWrapper = styled.div`
  color: white;
  padding: 10px;
  overflow-y: scroll;
`;

export function LogDetail() {
  const anomalyID = useRecoilValue(selectedSecurityAnomalyIdState);
  const query = useSecurityEventLog();
  const {data} = query;

  const titleText = data?.event_id ? `Log Details: Event ID ${data.event_id}` : 'Log Details';

  return (
    <Container>
      <HeaderWrapper>
        <TitleWrapper>
          <ComponentTitle title={titleText} />
        </TitleWrapper>
      </HeaderWrapper>
      {!anomalyID ? (
        <NoResults
          header={'Select a Security Event'}
          message={'Additional details related to the event will be shown here.'}
        />
      ) : (
        <QueryLifecycleWrapper
          query={query}
          isEmpty={isEmpty(data)}
          wrapWithTableRow={false}
          emptyMessage={'No log found for this security event'}
        >
          <LogWrapper>
            <HydratedLog log={data} options={{highlightObserved: false}} />
          </LogWrapper>
        </QueryLifecycleWrapper>
      )}
    </Container>
  );
}
