import IconComponent, {IconProps} from '../IconComponent';

export default function MediumPriorityIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="MediumPriorityIcon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.17745 1.51085C6.41662 1.27752 6.70829 1.16669 6.99995 1.16669C7.29162 1.16669 7.58329 1.27752 7.82245 1.51085L12.4891 6.17752C12.95 6.63252 12.95 7.36752 12.4891 7.82252L7.82245 12.4892C7.36745 12.95 6.63245 12.95 6.17745 12.4892L1.51079 7.82252C1.04995 7.36752 1.04995 6.63252 1.51079 6.17752L6.17745 1.51085ZM6.29996 4.20002H7.69996V7.70002H6.29996V4.20002ZM6.29996 8.40002H7.69996V9.80002H6.29996V8.40002Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
