import {useRecoilValue} from 'recoil';
import {logDetailViewState, LOGS, TEMPLATE} from '../../../../atoms/LogDetailView';
import AllLogsTable from '../AllLogsTable/AllLogsTable';
import ErrorState from '../../../Katana/components/ErrorState/ErrorState';
import LogTemplatesTable from '../LogTemplatesTable/LogTemplatesTable';
import {selectedAnomalyDataState} from 'atoms/selectedAnomaly';
import config from '../../../../utils/AppConfigurationService';
import {
  useExchangeLogSelectedAnomalyLogList,
  useExchangeLogSelectedAnomalyLogListCount,
  useExchangeLogSelectedAnomalyTemplateList,
  useExchangeLogSelectedAnomalyTemplateListCount,
} from 'data/ExchangeLog';
import {
  useSelectedLogAnomalyLogMessageList,
  useSelectedLogAnomalyLogMessageListCount,
  useSelectedLogAnomalyTemplateList,
  useSelectedLogAnomalyTemplateListCount,
} from 'data/Log';

export default function GetLogTableComponent() {
  const logDetailView = useRecoilValue(logDetailViewState);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);

  if (logDetailView === TEMPLATE) {
    return (
      <LogTemplatesTable
        templatesListQuery={
          selectedAnomalyData?.anomaly_type === config.AnomalyTypes.LOG
            ? useSelectedLogAnomalyTemplateList
            : useExchangeLogSelectedAnomalyTemplateList
        }
        templatesCountQuery={
          selectedAnomalyData?.anomaly_type === config.AnomalyTypes.LOG
            ? useSelectedLogAnomalyTemplateListCount
            : useExchangeLogSelectedAnomalyTemplateListCount
        }
      />
    );
  }
  if (logDetailView === LOGS) {
    return (
      <AllLogsTable
        logListQuery={
          selectedAnomalyData?.anomaly_type === config.AnomalyTypes.LOG
            ? useSelectedLogAnomalyLogMessageList
            : useExchangeLogSelectedAnomalyLogList
        }
        logCountQuery={
          selectedAnomalyData?.anomaly_type === config.AnomalyTypes.LOG
            ? useSelectedLogAnomalyLogMessageListCount
            : useExchangeLogSelectedAnomalyLogListCount
        }
        showEventId={selectedAnomalyData?.anomaly_type === config.AnomalyTypes.EXCHANGE_LOG}
      />
    );
  } else return <ErrorState />;
}
