import IconComponent, {IconProps} from '../IconComponent';

export default function HighPriorityColorIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 20}} className={className}>
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="HighPriorityColorIcon"
      >
        <path
          d="M1.70252 15.9983C1.31655 16.665 1.79761 17.4993 2.56794 17.4993H17.4324C18.2027 17.4993 18.6838 16.665 18.2978 15.9983L10.8656 3.16084C10.4804 2.49556 9.5199 2.49556 9.13474 3.16084L1.70252 15.9983Z"
          fill="#CD3E38"
        />
        <rect x="9" y="7" width="2" height="5" fill="white" />
        <rect x="9" y="13" width="2" height="2" fill="white" />
      </svg>
    </IconComponent>
  );
}
