import {atom} from 'recoil';
import {WhatIfUtilization} from '../constants/WhatIfMetrics';

export enum WhatIfMetrics {
  WhatIfCPU = 'CPU Usage',
  WhatIfMemory = 'Memory Usage',
  WhatIfUtilization = 'Utilization',
  WhatIfTCAM = 'TCAM Usage',
}

export const selectedWhatIfMetricState = atom({
  key: 'selectedWhatIfMetric',
  default: WhatIfUtilization as WhatIfMetrics,
});
