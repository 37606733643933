import {
  AnomalyClassIcon,
  IssueClassIcon,
  NoneClassIcon,
  UnknownIcon,
} from 'components/Katana/Icons';
import {AnomalyClassificationBucket} from 'openapi-schema/schemaTS';
import config from 'utils/AppConfigurationService';

interface Props {
  obsClass: AnomalyClassificationBucket;
  className?: string;
}

export default function ObservationClassIcon({obsClass, className}: Props) {
  const {ANOMALY, ISSUE, NONE} = config.Class;

  if (obsClass === ANOMALY) return <AnomalyClassIcon className={className} />;
  if (obsClass === ISSUE) return <IssueClassIcon className={className} />;
  if (obsClass === NONE) return <NoneClassIcon className={className} />;
  return <UnknownIcon className={className} />;
}
