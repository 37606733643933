import {ComponentProps} from 'react';
import {ReactComponent as SliceUpLogo} from '../../brand/slice-up.svg';
import TabGroup from '../../components/Tabs/TabGroup';

interface Props {
  tabGroupProps: ComponentProps<typeof TabGroup>;
  accountDropdownComponent?: JSX.Element;
  onLogoClick?: () => void;
}

export default function AppHeader({tabGroupProps, accountDropdownComponent, onLogoClick}: Props) {
  return (
    <div
      className={
        'relative flex items-center gap-10 border-b border-light-outline-variant bg-light-surface pl-4 pr-3 dark:border-dark-outline-variant dark:bg-dark-surface'
      }
    >
      <SliceUpLogo
        onClick={onLogoClick}
        className={onLogoClick ? 'cursor-pointer' : 'cursor-auto'}
        data-testid="SliceUp-logo"
      />
      <TabGroup {...tabGroupProps} />
      <div className="ml-auto">{accountDropdownComponent}</div>
    </div>
  );
}
