import {isEqual} from 'lodash';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {
  useRecoilState,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import {ARPopupOpenState} from '../../atoms/ARPopupOpen';
import {fingerprintDetailsState} from '../../atoms/fingerprintDetails';
import {selectedAnomalyIdState} from '../../atoms/selectedAnomaly';
import {selectedTemplatesState} from '../../atoms/SelectedTemplates';
import {snackbarDataState} from '../../atoms/snackbarData';
import {uploadedAliasListState} from '../../atoms/uploadedAliasList';
import {hoverState, uploadedFileState} from '../../atoms/UploadedFile';
import {usedAliasesState} from '../../atoms/usedAliases';
import {warningModalDataState} from '../../atoms/warningModalData';
import {warningModalOpenState} from '../../atoms/warningModalOpen';
import FingerprintDetailsPage from '../../components/AnomalyDetail/Log/AutomateWindow/components/FingerprintDetailsPage';
import TemplatesSelectionPage from '../../components/AnomalyDetail/Log/AutomateWindow/components/TemplatesSelectionPage';
import VariableAssigmentPage from '../../components/AnomalyDetail/Log/AutomateWindow/components/VariableAssignmentPage';
import LogTemplatesDefinition from '../../components/Katana/components/DefinitionTooltip/Definitions/LogTemplatesDefinition';
import ObservationsDefinition from '../../components/Katana/components/DefinitionTooltip/Definitions/ObservationsDefinition';
import TemplateVariablesDefinition from '../../components/Katana/components/DefinitionTooltip/Definitions/TemplateVariablesDefinition';
import {useAddFingerprint} from '../../data/AutoRemediation';
import {AnomalyTemplate, HydratedLogElement} from '../../openapi-schema/schemaTS';
import {getAliasList} from '../../utils/getAliasList';

const useARActions = () => {
  const resetARPopupOpen = useResetRecoilState(ARPopupOpenState);
  const resetUploadedAliasList = useResetRecoilState(uploadedAliasListState);
  const resetUsedaliasesList = useResetRecoilState(usedAliasesState);
  const resetUploadedFile = useResetRecoilState(uploadedFileState);
  const resetIsHovering = useResetRecoilState(hoverState);
  const [file, setFile] = useRecoilState(uploadedFileState);
  const [uploadedAliasList, setUploadedAliasList] = useRecoilState(uploadedAliasListState);
  const [usedAliases, setUsedAliases] = useRecoilState(usedAliasesState);
  const selectedTemplates = useRecoilValue(selectedTemplatesState);
  const fingerprintDetails = useRecoilValue(fingerprintDetailsState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const setSnackbarData = useSetRecoilState(snackbarDataState);
  const setWarningModalData = useSetRecoilState(warningModalDataState);
  const setWarningModalOpen = useSetRecoilState(warningModalOpenState);
  const addFingerprint = useAddFingerprint();

  const resetAllStates = useRecoilTransaction_UNSTABLE(
    ({reset}) =>
      () => {
        reset(uploadedFileState);
        reset(selectedTemplatesState);
        reset(hoverState);
        reset(uploadedAliasListState);
        reset(usedAliasesState);
        reset(fingerprintDetailsState);
      },
    []
  );

  const getStepWizardPages = () => {
    return [
      {
        stepLabel: 'Enter Fingerprint Details',
        subtitle: (
          <>
            A <b>Fingerprint</b> is a collection of <LogTemplatesDefinition /> that are used to
            identify and categorize <ObservationsDefinition /> allowing you to quickly and
            efficiently manage network anomalies and resolve incidents with minimal manual
            intervention.
            <br />
            <br />
            Once a <b>Fingerprint</b> has been created, future incidents that match the defined
            pattern can be handled according to the Automation Level that is set. This allows you to
            automatically open tickets and run an attached script; or simply queue them up for
            manual review and remediation later.
          </>
        ),
        component: <FingerprintDetailsPage />,
        tooltip: (
          <>
            Please enter a value for all
            <br />
            <b>required fields</b> marked with an *.
          </>
        ),
        isNextDisabled: !fingerprintDetails.name,
      },
      {
        stepLabel: 'Select Associated Log Templates',
        subtitle: (
          <>
            Choose which <LogTemplatesDefinition /> should be used for matching messages in future{' '}
            <ObservationsDefinition />.
          </>
        ),
        component: <TemplatesSelectionPage />,
        tooltip: (
          <>
            Please select <b>at least one</b>
            <br />
            log template to proceed.
          </>
        ),
        isNextDisabled: selectedTemplates.length === 0,
      },
      {
        stepLabel: 'Assign Variables',
        subtitle: (
          <>
            Upload a script using the panel on the left. Then, select which{' '}
            <TemplateVariablesDefinition /> you want the script to ingest. Add an alias from the
            script under the “Attached Alias” dropdown to assign it to the selected template
            variable.
          </>
        ),
        component: <VariableAssigmentPage />,
        nextButton: 'Finish',
        tooltip: (
          <>
            Please assign all <b>Template Variables</b> to their respective aliases after uploading
            a script for this Fingerprint.
          </>
        ),
        isNextDisabled: !file || uploadedAliasList.length !== Object.keys(usedAliases).length,
      },
    ];
  };

  const closePopup = () => {
    setWarningModalOpen(true);
    setWarningModalData({
      modalSize: 'small',
      headerText: 'Confirm Cancel',
      warningMessage: 'Changes will not be saved. Do you want to proceed?',
      cancelButtonText: 'Back',
      confirmButtonText: 'Confirm',
      confirmButtonCallback: () => {
        resetARPopupOpen();
        setWarningModalOpen(false);
      },
    });
  };

  const handleLastPageSubmit = () => {
    setWarningModalOpen(true);
    setWarningModalData({
      modalSize: 'small',
      headerText: 'Automate Remediation',
      warningMessage: 'Are you sure you want to submit this auto remediation script?',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Submit',
      confirmButtonCallback: () => {
        resetARPopupOpen();
        setWarningModalOpen(false);
        addFingerprint({
          version: 0,
          name: fingerprintDetails.name,
          description: fingerprintDetails.description,
          classification: fingerprintDetails.classification,
          enabled: 'Y',
          service_now_category: fingerprintDetails.category,
          template_info: {
            template_ids: selectedTemplates.map(template => {
              return {
                template_id: template.template_id,
                template_version: template.template_version,
              };
            }),
          },
          observation_id: selectedAnomalyId,
          ansible_script: {
            //version, description, ansible_script_id, host_names are required for query to run
            version: 0,
            description: fingerprintDetails.description,
            ansible_script_id: '',
            host_names: '',
            script_body: file?.fileContent,
            script_variables: JSON.stringify({
              templates: selectedTemplates.map(template => {
                return {
                  template_id: template.template_id,
                  template_version: template.template_version,
                  variables: Object.entries(usedAliases)
                    .filter(
                      entry =>
                        entry[1].template_version === template.template_version &&
                        entry[1].template_id === template.template_id
                    )
                    .map(entry => {
                      return {
                        variable_name: entry[1].variable_name,
                        variable_key: entry[1].variable_key,
                        key_type: entry[1].key_type,
                      };
                    }),
                };
              }),
            }),
          },
        });
      },
    });
  };

  const toggleTemplate = useRecoilTransaction_UNSTABLE(
    ({reset, get, set}) =>
      (template: AnomalyTemplate) => {
        const selectedTemplates = get(selectedTemplatesState);

        reset(usedAliasesState);
        if (!selectedTemplates.includes(template)) {
          set(selectedTemplatesState, [...selectedTemplates, template]);
        } else {
          set(
            selectedTemplatesState,
            selectedTemplates.filter((item: AnomalyTemplate) => !isEqual(item, template))
          );
        }
      },
    []
  );

  const uploadFile = (files: FileList | [], setLoading: Dispatch<SetStateAction<boolean>>) => {
    return new Promise(function (resolve, reject) {
      if (files.length > 0) {
        setLoading(true);
        resetUploadedAliasList();
        resetUsedaliasesList();
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent) => {
          const fileContent = (e.target as FileReader).result as string;
          // The file type restriction on the html input element only
          // prevents incorrect file types from being selected in the file picker
          if (
            file.type === 'application/x-yaml' ||
            file.name?.toLowerCase().endsWith('.yaml') ||
            file.name?.toLowerCase().endsWith('.yml')
          ) {
            setFile({
              name: file.name,
              size: file.size,
              type: file.type,
              lastModified: file.lastModified,
              fileContent,
            });
            setUploadedAliasList(getAliasList(fileContent));
            resolve('OK');
          } else {
            console.warn(`Error uploading file with type ${file.type}`, file);
            resetUploadedFile();
            resetIsHovering();
            reject('FAIL');
          }
          setLoading(false);
        };
        reader.readAsText(file);
      }
    });
  };

  const setSnackbarFileUpload = (result: unknown, error: string | undefined) => {
    if (result) {
      setSnackbarData({
        severity: 'success',
        message: 'File uploaded successfully',
        open: true,
      });
    } else {
      setSnackbarData({
        severity: 'error',
        message: 'Upload failed',
        subtitle: 'Check if script is a yaml file',
        open: true,
      });
    }
  };

  const removeAliasFromUsedAliasList = useCallback(
    (variableName: string) => {
      setUsedAliases(currVal => {
        const currValCopy = {...currVal};
        delete currValCopy[variableName];
        return currValCopy;
      });
    },
    [setUsedAliases]
  );

  const addAliasToUsedAliasList = useCallback(
    (
      alias: string,
      element: HydratedLogElement,
      template: AnomalyTemplate | undefined,
      index: number
    ) => {
      setUsedAliases(currVal => {
        return {
          ...currVal,
          [alias]: {
            template_id: template?.template_id,
            template_version: template?.template_version,
            index: index,
            example_value: element.value,
            variable_name: alias,
            variable_key: element.variables_key,
            key_type: 'non-numerical',
          },
        };
      });
    },
    [setUsedAliases]
  );

  return {
    resetAllStates,
    closePopup,
    toggleTemplate,
    uploadFile,
    setSnackbarFileUpload,
    removeAliasFromUsedAliasList,
    addAliasToUsedAliasList,
    handleLastPageSubmit,
    getStepWizardPages,
  };
};

export default useARActions;
