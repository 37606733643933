import {
  useSelectedLogAnomalyLogMessageList,
  useSelectedLogAnomalyLogMessageListCount,
} from 'data/Log';
import LogsTable from '../../../common/LogsTable/LogsTable';
import {pageSizeOptionsSmall} from '../../../../../constants/PageSizeOptions';
import {AnomalyTemplate} from '../../../../../openapi-schema/schemaTS';
import {Container, ComponentTitleWrapper} from 'components/common/TableElements/TableElements';
import ComponentTitle from '../../../../common/ComponentTitle/ComponentTitle';
import {useRecoilValue} from 'recoil';
import {selectedAnomalyDataState} from 'atoms/selectedAnomaly';
import config from '../../../../../utils/AppConfigurationService';
import {
  useExchangeLogSelectedAnomalyLogList,
  useExchangeLogSelectedAnomalyLogListCount,
} from 'data/ExchangeLog';

const pageSizeOptions = pageSizeOptionsSmall;

interface Props {
  templateRow: AnomalyTemplate;
}

export default function InnerTable({templateRow}: Props) {
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="Log messages matching this template" />
      </ComponentTitleWrapper>
      <LogsTable
        logListQuery={
          selectedAnomalyData?.anomaly_type === config.AnomalyTypes.LOG
            ? useSelectedLogAnomalyLogMessageList
            : useExchangeLogSelectedAnomalyLogList
        }
        logCountQuery={
          selectedAnomalyData?.anomaly_type === config.AnomalyTypes.LOG
            ? useSelectedLogAnomalyLogMessageListCount
            : useExchangeLogSelectedAnomalyLogListCount
        }
        showEventId={selectedAnomalyData?.anomaly_type === config.AnomalyTypes.EXCHANGE_LOG}
        pageSizeOptions={pageSizeOptions}
        template_id={templateRow?.template_id}
        template_version={templateRow?.template_version}
      />
    </Container>
  );
}
