import {atom} from 'recoil';
import {SortField} from '../openapi-schema/schemaTS';

export const logTableSortingState = atom({
  key: 'logTableSorting',
  default: [
    {
      name: 'timestamp',
      desc: true,
    },
  ] as SortField[],
});

export const defaultTemplateTableSorting = [
  {
    name: 'total_count',
    desc: false,
  },
];

export const templateTableSortingState = atom({
  key: 'templateTableSorting',
  default: defaultTemplateTableSorting as SortField[],
});
