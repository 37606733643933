import {ChartOptions} from 'chart.js';
import {DateTime} from 'luxon';
import {Line} from 'react-chartjs-2';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {timelineState} from '../../../../atoms/dashboard';
import {selectedTimezoneState} from '../../../../atoms/selectedTimezone';
import {
  chartHorizontalAxisOptions,
  chartHorizontalTickOptions,
  chartVerticalAxisOptions,
  chartVerticalAxisTickOptions,
  coreChartOptions,
} from '../../../../constants/chartOptions';
import {useSelectedAnomalyISISAdjacenciesDowntime} from '../../../../data/ISIS';
import {getChartXAxisTickLabels} from '../../../../utils/getChartXAxisTickLabels';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import ErrorState from '../../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import ISISAdjacencyStatusLegend from './components/ISISAdjacencyStatusLegend';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: black;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 10px 12px 10px;
`;

const ChartWrapper = styled.div`
  flex: 1;
  min-height: 0;
  padding: 0 8px;
  position: relative;
`;

const Cover = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: black;
  top: 0;
  left: 0;
`;

export default function ISISAdjacencyStatusChart() {
  const {data: APIdata, isLoading, isError, refetch} = useSelectedAnomalyISISAdjacenciesDowntime();
  const {selectedInterval} = useRecoilValue(timelineState);
  const selectedTimezone = useRecoilValue(selectedTimezoneState);

  const options: ChartOptions<'line'> = {
    ...coreChartOptions,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: APIdata?.map(element => {
          return {
            type: 'box',
            xMin: DateTime.fromISO(element.start).toMillis(),
            xMax: DateTime.fromISO(element.end).toMillis(),
            yMin: 0,
            yMax: 1,
            backgroundColor: 'rgba(205, 62, 56, 0.5)',
            borderColor: 'rgba(205, 62, 56, 1)',
          };
        }),
      },
    },
    scales: {
      y: {
        ...chartVerticalAxisOptions,
        position: 'left',
        min: 0,
        max: 1,
        ticks: {
          ...chartVerticalAxisTickOptions,
        },
      },
      x: {
        ...chartHorizontalAxisOptions,
        min: selectedInterval.start.toMillis(),
        max: selectedInterval.end.toMillis(),
        ticks: {
          ...chartHorizontalTickOptions,
          callback: value =>
            getChartXAxisTickLabels(Number(value), selectedInterval, selectedTimezone),
        },
      },
    },
  };

  const data = {
    datasets: [],
  };

  return (
    <Container>
      <HeaderWrapper>
        <ComponentTitle title="Adjacency Status" />
      </HeaderWrapper>
      <ChartWrapper>
        <Line options={options} data={data} />
        {isLoading && (
          <Cover>
            <LoadingSpinner />
          </Cover>
        )}
        {isError && (
          <Cover>
            <ErrorState retry={refetch} />
          </Cover>
        )}
      </ChartWrapper>
      <ISISAdjacencyStatusLegend />
    </Container>
  );
}
