import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {selectedFingerprintState} from '../../../../atoms/selectedFingerprint';
import {useUpdateFingerprint} from '../../../../data/AutoRemediation';
import {createFingerprintRequestObject} from '../../../../utils/createFingerprintRequest';
import Dropdown from '../../../common/Dropdown/Dropdown';
import TextInputField from '../../../Katana/components/TextInputField/TextInputField';
import EnabledToggle from './EnabledToggle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
  padding: 0 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  padding: 4px 0;
  gap: 30px;
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  opacity: 0.6;
  text-transform: uppercase;
  width: 80px;
`;

const DropdownWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

const getSelectedDropdownValue = (level: string | undefined): string => {
  if (level === 'AUTO') return AutomationLevels.auto;
  if (level === 'MANUAL') return AutomationLevels.manual;
  return AutomationLevels.silent;
};

export const AutomationLevels = {
  auto: 'Auto: Open a new ticket and the run attached script',
  manual: 'Manual: Open a new ticket and queue script for execution',
  silent: 'Silent: Match only - do not open a ticket or run script',
};

export const AutomationLevelDropdownOptions = [
  AutomationLevels.auto,
  AutomationLevels.manual,
  AutomationLevels.silent,
];

export default function FingerprintInfo() {
  const selectedFingerprint = useRecoilValue(selectedFingerprintState);
  const updateFingerprint = useUpdateFingerprint();

  const request = createFingerprintRequestObject(selectedFingerprint);

  const handleDropdownChange = (value: string) => {
    if (value === AutomationLevels.auto) {
      request.classification = 'AUTO';
    } else if (value === AutomationLevels.manual) {
      request.classification = 'MANUAL';
    } else {
      request.classification = 'SILENT';
    }
    updateFingerprint(request, 'Automation Level updated');
  };

  return (
    <Container>
      <TextInputField
        label="Name"
        onSubmit={input => {
          if (input === selectedFingerprint?.name) return;
          request.name = input;
          updateFingerprint(request, 'Fingerprint name updated');
        }}
        inputValue={selectedFingerprint?.name as string}
        variant="inline"
      />
      <TextInputField
        label="Description"
        onSubmit={input => {
          if (input === selectedFingerprint?.description) return;
          request.description = input;
          updateFingerprint(request, 'Fingerprint description updated');
        }}
        inputValue={selectedFingerprint?.description || ''}
        variant="inline"
      />
      <InputWrapper>
        <Label>Enabled</Label>
        <EnabledToggle />
      </InputWrapper>
      <InputWrapper>
        <Label>AUTOMATION LEVEL</Label>
        <DropdownWrapper>
          <Dropdown
            onChange={value => handleDropdownChange(value)}
            selectedValue={getSelectedDropdownValue(selectedFingerprint?.classification)}
            dropdownOptions={AutomationLevelDropdownOptions}
            size="large"
            variant="outline"
          />
        </DropdownWrapper>
      </InputWrapper>
    </Container>
  );
}
