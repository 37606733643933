import {useDeviceHealthOverviewData} from 'data/HardwareMonitoring';
import {groupBy} from 'lodash';
import {useMemo} from 'react';
import styled from 'styled-components';
import {getColor, HealthStatusLevels} from '../../HealthStatusUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const CountsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

interface CountGroupProps {
  color: string;
}

const CountGroup = styled.div<CountGroupProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: ${props => props.color};
`;

const Count = styled.p`
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
`;

const Label = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
`;

const BarWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  width: calc(100% - 20px);
  overflow: hidden;
`;

interface BarProps {
  width: number;
  color: string;
}

const Bar = styled.div<BarProps>`
  height: 16px;
  width: ${props => `${props.width}%`};
  background: ${props => props.color};
`;

export function RiskFailureBarChart() {
  const {data: APIdata} = useDeviceHealthOverviewData();

  const data = useMemo(() => {
    const groupedData = groupBy(APIdata, 'health_status');

    const formattedData = Object.keys(HealthStatusLevels).map(statusLevel => {
      return {
        count: groupedData[statusLevel]?.length || 0,
        label: statusLevel,
        color: getColor(statusLevel),
      };
    });

    const sortedData = formattedData.sort((a, b) => {
      return HealthStatusLevels[a.label] > HealthStatusLevels[b.label] ? -1 : 1;
    });

    return sortedData;
  }, [APIdata]);

  const totalDevices = data.reduce((acc, currVal) => acc + currVal.count, 0);

  return (
    <Container>
      <CountsContainer>
        {data.map(element => (
          <CountGroup color={element.color} key={element.label}>
            <Count>{element.count.toLocaleString()}</Count>
            <Label>{element.label.replaceAll('_', ' ')}</Label>
          </CountGroup>
        ))}
      </CountsContainer>
      <BarWrapper>
        {data.map(element => (
          <Bar
            width={(element.count / totalDevices) * 100}
            color={element.color}
            key={element.label}
          />
        ))}
      </BarWrapper>
    </Container>
  );
}
