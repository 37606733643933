import {CancelButton, ConfirmButton, FooterContainer, SmallContainer} from './ModalElements';
import ModalHeader from './ModalHeader';
import styled from 'styled-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {ServiceNowModalActions} from '../ServiceNowModalActions';
import {ScriptExecutionInfo} from '../../../../openapi-schema/fingerprintSchemaTS';
import {LinkIcon} from 'components/Katana/Icons';

const ContentContainer = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 20px;
`;

const ContentWrapper = styled.div`
  max-width: 380px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Text = styled.p`
  font-size: 15px;
`;

const TicketWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled.a`
  display: flex;
  gap: 10px;
  border: 1px solid rgba(58, 53, 66, 1);
  padding: 9px;
  cursor: pointer;
`;

const LinkWrapperText = styled.p`
  font-size: 14px;
  line-height: 26px;
`;

const IconWrapper = styled.div`
  display: grid;
  place-content: center;
  margin-left: auto;
`;

const TicketWrapperSubText = styled.p`
  font-size: 12px;
  opacity: 0.6;
  text-align: right;
`;

interface Props {
  scriptExecutionInfo: ScriptExecutionInfo;
}

export default function ConfirmScriptModal({scriptExecutionInfo}: Props) {
  const {closeModal, goToPreviousPage, runScript} = ServiceNowModalActions();
  const url = String(scriptExecutionInfo.change_form_url);

  return (
    <SmallContainer>
      <ModalHeader title="Confirm Script Execution" />
      <ContentContainer>
        <ContentWrapper>
          <Text>After the script runs, the following ticket will be updated with the output</Text>
          <TicketWrapper>
            <LinkWrapper
              href={/^https?:\/\/(.*)/.test(url) ? url : `https://${url}`}
              target="_blank"
              rel="noreferrer"
            >
              <LinkIcon />
              <LinkWrapperText>{scriptExecutionInfo.change_form_id}</LinkWrapperText>
              <IconWrapper>
                <OpenInNewIcon sx={{fontSize: '20px'}} />
              </IconWrapper>
            </LinkWrapper>
            <TicketWrapperSubText>Click to open in a new window</TicketWrapperSubText>
          </TicketWrapper>
        </ContentWrapper>
      </ContentContainer>
      <FooterContainer>
        <CancelButton onClick={closeModal} style={{marginRight: 'auto'}}>
          Cancel
        </CancelButton>
        <CancelButton onClick={() => goToPreviousPage(scriptExecutionInfo)}>Back</CancelButton>
        <ConfirmButton onClick={() => runScript(scriptExecutionInfo)}>Confirm</ConfirmButton>
      </FooterContainer>
    </SmallContainer>
  );
}
