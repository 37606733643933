import FingerprintDetails from './FingerprintDetails/FingerprintDetails';
import FingerprintTable from './FingerprintTable/FingerprintTable';
import GoldenLayout from '../common/GoldenLayout/GoldenLayout';
import ScriptExecution from './ScriptExecution/ScriptExecution';
import ServiceNowModal from './ServiceNowModal/ServiceNowModal';
import {withTracingErrorBoundary} from '../../tracing/errorReporting';

function ServiceNow() {
  return (
    <>
      <GoldenLayout
        content={[
          {
            type: 'row',
            content: [
              {
                type: 'column',
                width: 60,
                content: [
                  {
                    component: () => <FingerprintTable />,
                  },
                  {
                    component: () => <ScriptExecution />,
                  },
                ],
              },
              {
                type: 'column',
                content: [
                  {
                    height: 40,
                    component: () => <FingerprintDetails />,
                  },
                ],
              },
            ],
          },
        ]}
      />
      <ServiceNowModal />
    </>
  );
}

export default withTracingErrorBoundary(ServiceNow);
