import styled from 'styled-components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Dropdown from '../Dropdown/Dropdown';
import PageIndex from './PageIndex';
import {useResizeDetector} from 'react-resize-detector';
import {useMemo} from 'react';

const Container = styled.div`
  width: 100%;
  background: rgba(42, 42, 42, 1);
  padding: 4px 5px 4px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  gap: 5px;
`;

const DropdownWrapper = styled.div`
  margin-right: auto;
`;

const PageSize = styled.p``;

interface ButtonProps {
  disabled: boolean;
  padding: string;
}

const Button = styled.button<ButtonProps>`
  background: rgba(75, 76, 85, 1);
  color: white;
  display: flex;
  gap: 4px;
  align-items: center;
  border: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  padding: ${props => props.padding};
`;

const IconWrapper = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
`;

interface ButtonTextProps {
  $disabled: boolean;
}

const ButtonText = styled.label<ButtonTextProps>`
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
`;

interface PaginationMethods {
  canPreviousPage: boolean;
  canNextPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageSize: number;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  pageCount: number;
}

interface PaginationProps {
  paginationMethods: PaginationMethods;
  pageSizeOptions: number[];
}

export default function Pagination({paginationMethods, pageSizeOptions}: PaginationProps) {
  const {
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageIndex,
    gotoPage,
    pageSize,
    pageCount,
  } = paginationMethods;

  const {width, ref} = useResizeDetector();
  const displayText = width && width > 480;

  const dropdownOptions = useMemo(() => pageSizeOptions.map(o => String(o)), [pageSizeOptions]);

  return (
    <Container ref={ref}>
      <PageSize>Page Size:</PageSize>
      <DropdownWrapper>
        <Dropdown
          dropdownOptions={dropdownOptions}
          onChange={(value: string) => setPageSize(Number(value))}
          selectedValue={String(pageSize)}
        />
      </DropdownWrapper>
      <Button
        padding={displayText ? '2px 2px 2px 2px' : '2px'}
        disabled={!canPreviousPage}
        onClick={() => gotoPage(0)}
      >
        <IconWrapper>
          <SkipPreviousIcon />
        </IconWrapper>
      </Button>
      <Button
        padding={displayText ? '2px 10px 2px 2px' : '2px'}
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
        data-testid="prevPageButton"
      >
        <IconWrapper>
          <ChevronLeftIcon />
        </IconWrapper>
        {displayText && <ButtonText $disabled={!canPreviousPage}>Prev</ButtonText>}
      </Button>
      <PageIndex width={width} pageCount={pageCount} pageIndex={pageIndex} />
      <Button
        padding={displayText ? '2px 2px 2px 10px' : '2px'}
        disabled={!canNextPage}
        onClick={() => nextPage()}
        data-testid="nextPageButton"
      >
        {displayText && <ButtonText $disabled={!canNextPage}>Next</ButtonText>}
        <IconWrapper>
          <ChevronRightIcon />
        </IconWrapper>
      </Button>
      <Button
        padding={displayText ? '2px 2px 2px 2px' : '2px'}
        disabled={!canNextPage || !pageCount}
        onClick={() => gotoPage(pageCount - 1)}
      >
        <IconWrapper>
          <SkipNextIcon />
        </IconWrapper>
      </Button>
    </Container>
  );
}
