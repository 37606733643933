import {useEffect, useState} from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface ContainerProps {
  open: boolean;
}

export const AccordionContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: white;
  flex: ${props => (props.open ? 1 : 0)};
`;

export const ToggleWrapper = styled.div`
  cursor: pointer;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  right: 7px;
`;

const IconWrapper = styled.div`
  display: grid;
  place-content: center;
`;

const ToggleText = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  user-select: none;
`;

export const ContentWrapper = styled.div`
  padding: 0 14px;
  height: 100%;
`;

interface Props {
  toggleText: string;
  openOnRender?: boolean;
  onToggle?: () => void;
  children: JSX.Element;
}

export default function AccordionToggle({
  toggleText,
  openOnRender = false,
  onToggle,
  children,
}: Props) {
  const [variableAssignmentOpen, setVariableAssignmnetOpen] = useState(openOnRender);

  const handleClick = () => {
    setVariableAssignmnetOpen(prev => !prev);

    if (onToggle) {
      onToggle();
    }
  };

  useEffect(() => {
    setVariableAssignmnetOpen(openOnRender);
  }, [openOnRender]);

  return (
    <AccordionContainer open={variableAssignmentOpen}>
      <ToggleWrapper onClick={handleClick}>
        <ToggleContainer>
          <IconWrapper>
            {variableAssignmentOpen ? (
              <ArrowDropDownIcon sx={{fontSize: '24px', color: 'rgba(152, 152, 152, 1)'}} />
            ) : (
              <ArrowRightIcon sx={{fontSize: '24px', color: 'rgba(152, 152, 152, 1)'}} />
            )}
          </IconWrapper>
          <ToggleText>{toggleText}</ToggleText>
        </ToggleContainer>
      </ToggleWrapper>
      {variableAssignmentOpen && <ContentWrapper>{children}</ContentWrapper>}
    </AccordionContainer>
  );
}
