import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ForwardIcon from '@mui/icons-material/Forward';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {timelineState} from '../../../../atoms/dashboard';
import AppConfig from '../../../../utils/AppConfigurationService';
import useSetTimelineStates from '../../../../helpers/hooks/useSetTimelineStates';
import {Interval} from 'luxon';
import {inLiveModeState} from '../../../../atoms/inLiveMode';
import panTimeline from '../../../../helpers/panTimeline';
import {useCallback} from 'react';

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row: auto auto;
  gap: 2px;
  background: black;
`;

const Button = styled.div`
  display: grid;
  place-content: center;
  background: rgba(75, 76, 85, 1);
  color: white;
  cursor: pointer;
  width: 35px;
`;

export default function ZoomInOut() {
  const timeline = useRecoilValue(timelineState);
  const setTimelineStates = useSetTimelineStates();
  const setInLiveMode = useSetRecoilState(inLiveModeState);

  const handleClick = (direction: 1 | -1) => {
    const range = timeline.timelineInterval.length('milliseconds');
    const startIntervalTime = timeline.timelineInterval.start.minus({
      milliseconds: (direction * range) / AppConfig.timelineZoomFactor,
    });
    const endIntervalTime = timeline.timelineInterval.end.plus({
      milliseconds: (direction * range) / AppConfig.timelineZoomFactor,
    });

    const newTimeline = {
      ...timeline,
      timelineInterval: Interval.fromDateTimes(startIntervalTime, endIntervalTime),
    };

    setTimelineStates(newTimeline);
  };

  const handlePan = useCallback(
    (direction: 1 | -1) => {
      panTimeline(direction, timeline, setInLiveMode, setTimelineStates);
    },
    [setInLiveMode, setTimelineStates, timeline]
  );

  return (
    <Container>
      <Button onClick={() => handleClick(-1)} data-testid="zoom-in">
        <AddIcon />
      </Button>
      <Button onClick={() => handleClick(1)} data-testid="zoom-out">
        <RemoveIcon />
      </Button>
      <Button
        onClick={() => handlePan(-1)}
        style={{transform: 'rotate(180deg)'}}
        data-testid="pan-left"
      >
        <ForwardIcon />
      </Button>
      <Button onClick={() => handlePan(1)} data-testid="pan-right">
        <ForwardIcon />
      </Button>
    </Container>
  );
}
