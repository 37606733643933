import {
  useSelectedAnomalyRelatedAnomaliesExchangeLog,
  useSelectedAnomalyRelatedAnomaliesExchangeLogCount,
} from 'data/ExchangeLog';
import AssociatedAnomalies from '../common/AssociatedAnomalies/AssociatedAnomalies';

export default function AssociatedAnomaliesExchangeLog() {
  const anomalyListQuery = useSelectedAnomalyRelatedAnomaliesExchangeLog;
  const anomalyCountQuery = useSelectedAnomalyRelatedAnomaliesExchangeLogCount;

  return (
    <AssociatedAnomalies
      anomalyListQuery={anomalyListQuery}
      anomalyCountQuery={anomalyCountQuery}
    />
  );
}
