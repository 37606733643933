import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {RecoilRoot} from 'recoil';
import TraceProvider from './tracing/httpRequestInstrumentation';

import LoginPage from './components/common/LoginPage/LoginPage';
import AnomalySelection from './components/AnomalySelection/AnomalySelection';
import {StyleSheetManager} from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import {GlobalStyles} from './components/common/GlobalStyles/GlobalStyles';
import AnomalyDetail from './components/AnomalyDetail/AnomalyDetail';
import {QueryClient, QueryClientProvider} from 'react-query';
import PrivateRoute from './helpers/PrivateRoute';
import ReportingDashboard from './components/ReportingDashboard/ReportingDashboard';
import {RecoilURLSync} from 'recoil-sync';
import WhatIfCalculator from './components/WhatIfCalculator/WhatIfCalculator';
import ServiceNow from './components/ServiceNow/ServiceNow';
import BaseLayout from './layouts/BaseLayout/BaseLayout';
import LayoutWithTimeline from './layouts/LayoutWithTimeline/LayoutWithTimeline';
import Fallback, {DefaultFallback} from './components/common/Fallback/Fallback';
import {FallbackProps} from 'react-error-boundary';
import HardwareMonitoring from './components/HardwareMonitoring/HardwareMonitoring';
import Security from './components/Security/Security';
import {AuthProviderWrapper} from './auth/AuthProviderWrapper';
import MuiThemeProvider from 'components/common/MuiThemeProvider/MuiThemeProvider';

function AppFallbackComponent(props: FallbackProps) {
  return (
    <div style={{height: '100vh'}}>
      <DefaultFallback {...props} />
    </div>
  );
}

function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 60 * 1000,
    },
  },
});

function App() {
  return (
    <Fallback fallbackComponent={AppFallbackComponent}>
      <RecoilRoot>
        <RecoilURLSync
          location={{part: 'queryParams'}}
          serialize={(x: any) => {
            return x?.toString();
          }}
          deserialize={x => x}
        >
          <TraceProvider>
            <QueryClientProvider client={queryClient}>
              <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                <GlobalStyles />
                <MuiThemeProvider>
                  <Router>
                    <AuthProviderWrapper>
                      <Routes>
                        <Route path="login" element={<LoginPage />} />
                        <Route path="/" element={<PrivateRoute />}>
                          <Route element={<BaseLayout />}>
                            <Route path="datacenter/:datacenterId" element={<AnomalySelection />} />
                            <Route path="service-now" element={<ServiceNow />} />
                            <Route element={<LayoutWithTimeline />}>
                              <Route path="anomaly/:anomalyID" element={<AnomalyDetail />} />
                              <Route path="reporting-dashboard" element={<ReportingDashboard />} />
                              <Route path="what-if-calculator" element={<WhatIfCalculator />} />
                              <Route path="hardware-monitoring" element={<HardwareMonitoring />} />
                              <Route path="security" element={<Security />} />
                              <Route path="*" element={<AnomalySelection />} />
                              <Route index element={<AnomalySelection />} />
                            </Route>
                          </Route>
                        </Route>
                      </Routes>
                    </AuthProviderWrapper>
                  </Router>
                </MuiThemeProvider>
              </StyleSheetManager>
            </QueryClientProvider>
          </TraceProvider>
        </RecoilURLSync>
      </RecoilRoot>
    </Fallback>
  );
}

export default App;
