import {useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {selectedDataCenterId} from '../../atoms/selectedDatacenter';
import {useEffect} from 'react';
import useNavigateKeepParams from './useNavigateKeepParams';

const useURLSelectedDC = () => {
  const params = useParams();
  const navigateKeepParams = useNavigateKeepParams();
  const selectedDatacenter = useRecoilValue(selectedDataCenterId);

  useEffect(() => {
    if (!params.datacenterId || params.datacenterId !== selectedDatacenter.replaceAll(' ', '-')) {
      navigateKeepParams({
        pathname: `/datacenter/${selectedDatacenter.replaceAll(' ', '-')}`,
        replace: true,
      });
    }
  }, [navigateKeepParams, params.datacenterId, selectedDatacenter]);
};

export default useURLSelectedDC;
