import {Divider as MuiDivider} from '@mui/material';
import classNames from 'classnames';
import {ReactNode} from 'react';

const baseClasses =
  'border-light-outline-variant dark:border-dark-outline-variant before:border-light-outline-variant after:border-light-outline-variant before:dark:border-dark-outline-variant after:dark:border-dark-outline-variant';
const horizontalInsetClasses = 'ml-4';
const horizontalMiddleClasses = 'mx-4';
const verticalInsetClasses = 'mt-4';
const verticalMiddleClasses = 'my-4';

interface Props {
  children?: ReactNode;
  flexItem?: boolean;
  orientation?: 'horizontal' | 'vertical';
  textAlign?: 'center' | 'left' | 'right';
  variant?: 'fullWidth' | 'inset' | 'middle';
}

export default function Divider({
  children,
  orientation = 'horizontal',
  variant = 'fullWidth',
  flexItem,
  textAlign,
}: Props) {
  return (
    <MuiDivider
      className={classNames(baseClasses, {
        [horizontalInsetClasses]: variant === 'inset' && orientation === 'horizontal',
        [horizontalMiddleClasses]: variant === 'middle' && orientation === 'horizontal',
        [verticalInsetClasses]: variant === 'inset' && orientation === 'vertical',
        [verticalMiddleClasses]: variant === 'middle' && orientation === 'vertical',
      })}
      variant={variant}
      orientation={orientation}
      flexItem={flexItem}
      textAlign={textAlign}
    >
      {children}
    </MuiDivider>
  );
}
