import {useRecoilValue} from 'recoil';
import {useQuery} from 'react-query';
import config from '../utils/AppConfigurationService';
import {selectedAnomalyDataState, selectedAnomalyIdState} from '../atoms/selectedAnomaly';
import {timelineState} from '../atoms/dashboard';
import {
  getLogTemplatesLatency,
  getRelatedAnomaliesLatency,
  getRelatedAnomaliesLatencyCount,
  getLogTemplatesLatencyCount,
  getAnomaliesByDatacenterId,
  getNetworkRingDCs,
  getLatencyBoxplotDataByAnomalyId,
  getLatencyPairMetrics,
  getLatencyPairMetricsCount,
} from '../utils/PythonApiUtils';
import {associatedAnomaliesSortingState} from '../atoms/associatedAnomaliesSorting';
import {logTableSortingState} from '../atoms/logTableSorting';
import {selectedDataCenterId} from '../atoms/selectedDatacenter';
import useDebouncedInput from '../helpers/hooks/useDebouncedInput';
import {AnomalyTypeBucket} from '../openapi-schema/schemaTS';
import {LogListQuery, SortedPagedQuery} from './types';

export const useSelectedAnomalyLatencyPairMetrics: SortedPagedQuery = (
  pageIndex = 0,
  limit = config.apiResultLimit,
  sortFields = []
) => {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);

  return useQuery(
    [
      'LatencyPairMetrics',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      sortFields,
      selectedAnomalyData?.affected_resources[0],
      selectedAnomalyData?.affected_resources[1],
    ],
    () =>
      getLatencyPairMetrics({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset,
        limit,
        sort_fields: sortFields,
        client_site: selectedAnomalyData?.affected_resources[0] ?? '',
        server_site: selectedAnomalyData?.affected_resources[1] ?? '',
      })
  );
};

export function useSelectedAnomalyLatencyPairsMetricsCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);

  return useQuery(
    [
      'LatencyPairMetricsCount',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      selectedAnomalyData?.affected_resources[0],
      selectedAnomalyData?.affected_resources[1],
    ],
    () =>
      getLatencyPairMetricsCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        client_site: selectedAnomalyData?.affected_resources[0] ?? '',
        server_site: selectedAnomalyData?.affected_resources[1] ?? '',
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useSelectedAnomalyRelatedAnomaliesLatency(
  pageIndex = 0,
  limit = config.apiResultLimit
) {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const associatedAnomaliesSorting = useRecoilValue(associatedAnomaliesSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'relatedAnomaliesLatency',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      associatedAnomaliesSorting,
    ],
    () =>
      getRelatedAnomaliesLatency({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: associatedAnomaliesSorting,
      })
  );
}

export function useSelectedAnomalyRelatedAnomaliesLatencyCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['relatedAnomaliesLatencyCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getRelatedAnomaliesLatencyCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export const useLogTemplatesLatency: LogListQuery = (
  pageIndex = 0,
  limit = config.apiResultLimit
) => {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const sortFields = useRecoilValue(logTableSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'logTemplatesLatency',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      sortFields,
    ],
    () =>
      getLogTemplatesLatency({
        anomaly_id: selectedAnomalyId,
        offset: offset,
        limit: limit,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        sort_fields: sortFields,
      })
  );
};

export function useLogTemplatesLatencyCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['logTemplatesLatencyCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getLogTemplatesLatencyCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useSelectedAnomalyLatencyBoxplotData() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['LatencyBoxplotData', selectedAnomalyId, debouncedStart, debouncedEnd], () =>
    getLatencyBoxplotDataByAnomalyId({
      anomaly_id: selectedAnomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
      total_bins: 100,
    })
  );
}

export function useLatencyChartAnomalies() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const DCId = useRecoilValue(selectedDataCenterId);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);
  const affected_resource = selectedAnomalyData?.affected_resources?.[0];
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['latencyChartAnomalies', debouncedStart, debouncedEnd, affected_resource, DCId],
    () =>
      getAnomaliesByDatacenterId({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_types: [AnomalyTypeBucket.Latency],
        dc_id: DCId,
        affected_resources: affected_resource ? [affected_resource] : undefined,
        priority_types: [
          config.Priority.EXTREME,
          config.Priority.HIGH,
          config.Priority.MEDIUM,
          config.Priority.LOW,
        ],
        classification_types: [config.Class.ANOMALY, config.Class.ISSUE],
      })
  );
}

export function useNetworkRingDCs() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['networkRingDCs', debouncedStart, debouncedEnd], () =>
    getNetworkRingDCs({
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
}
