import {useRecoilValue} from 'recoil';
import {selectedWhatIfMetricState} from '../../../atoms/selectedWhatIfMetric';
import {WhatIfTCAM, WhatIfUtilization} from '../../../constants/WhatIfMetrics';
import {useTopologyCpuMem, useTopologyTCAM, useTopologyUtilization} from '../../../data/Topology';

const useWhatIfTopologyData = () => {
  const {data: cpuMemData} = useTopologyCpuMem();
  const {data: utilizationData} = useTopologyUtilization();
  const {data: TCAMData} = useTopologyTCAM();
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);

  if (selectedWhatIfMetric === WhatIfUtilization) {
    return utilizationData.interfaceIdentifierByNames;
  }
  if (selectedWhatIfMetric === WhatIfTCAM) {
    return TCAMData.nodesById;
  }
  return cpuMemData.nodesById;
};

export default useWhatIfTopologyData;
