import {DateTime} from 'luxon';
import {useSearchParams} from 'react-router-dom';
import {useCallback, useRef} from 'react';

const useNewUrlTime = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramRef = useRef(searchParams);
  paramRef.current = searchParams;

  return useCallback(
    (start: string, end: string) => {
      start = DateTime.fromISO(start, {zone: 'UTC'}).setZone('UTC').toISO().replaceAll(':', '-');
      end = DateTime.fromISO(end, {zone: 'UTC'}).setZone('UTC').toISO().replaceAll(':', '-');

      const searchParamsObject = Object.fromEntries(Array.from(paramRef.current));
      setSearchParams({...searchParamsObject, start, end}, {replace: true});
    },
    [setSearchParams]
  );
};

export default useNewUrlTime;
