import {useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {serviceNowModalContentState} from '../../../atoms/serviceNowModalContent';
import {serviceNowModalOpenState} from '../../../atoms/serviceNowModalOpen';
import {BannerAwareDialog} from '../../common/BannerAwareDialog/BannerAwareDialog';
import {ServiceNowModalActions} from './ServiceNowModalActions';

export default function ServiceNowModal() {
  const [serviceNowModalOpen, setServiceNowModalOpen] = useRecoilState(serviceNowModalOpenState);
  const serviceNowModalContent = useRecoilValue(serviceNowModalContentState);
  const {openModalFromMatchIdParam} = ServiceNowModalActions();

  useEffect(() => {
    openModalFromMatchIdParam();

    return () => setServiceNowModalOpen(false);
    //TODO: Fix lint warning. Can be fixed once PDC-1438 is closed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setServiceNowModalOpen]);

  return (
    <BannerAwareDialog open={serviceNowModalOpen} maxWidth={false}>
      {serviceNowModalContent}
    </BannerAwareDialog>
  );
}
