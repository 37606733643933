import IconWrapper from 'components/common/Sensei/IconWrapper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import {useState} from 'react';

interface Props {
  content: string;
}

export default function CopyButton({content}: Props) {
  const [showCopyIcon, setShowCopyIcon] = useState(true);

  return (
    <div
      className="flex cursor-pointer items-center gap-1"
      onClick={() => {
        navigator.clipboard.writeText(content);
        setShowCopyIcon(false);
        setTimeout(() => {
          setShowCopyIcon(true);
        }, 2000);
      }}
    >
      <IconWrapper>
        {showCopyIcon ? (
          <ContentCopyIcon sx={{fontSize: '16px'}} />
        ) : (
          <CheckIcon sx={{fontSize: '16px'}} />
        )}
      </IconWrapper>
      <p className="h-[14px] text-12 leading-[14px]">{showCopyIcon ? 'Copy' : 'Copied!'}</p>
    </div>
  );
}
