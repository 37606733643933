import {isEmpty, uniq} from 'lodash';
import {useSetRecoilState} from 'recoil';
import {selectedEdgeIdsState, selectedNodeIdsState} from '../../atoms/dashboard';
import {useDevicesBGP, useDevicesISIS, useTopologyData} from '../../data/Topology';
import {
  HotlErAnomalyNeighborDropBgp,
  HotlErAnomalyNeighborDropIsis,
} from '../../openapi-schema/schemaTS';
import {useCallback} from 'react';

const useSelectNodesNeighborDrop = () => {
  const {data: deviceBGPdata} = useDevicesBGP();
  const {data: deviceISISdata} = useDevicesISIS();
  const {data: topologyElements} = useTopologyData();
  const setSelectedNodeIds = useSetRecoilState(selectedNodeIdsState);
  const setSelectedEdgeIds = useSetRecoilState(selectedEdgeIdsState);

  const clearElements = useCallback(() => {
    setSelectedNodeIds([]);
    setSelectedEdgeIds([]);
  }, [setSelectedEdgeIds, setSelectedNodeIds]);

  const getAffectedResourceNodeId = useCallback(
    (affectedResource: string) => {
      return topologyElements.nodesByName?.[affectedResource]?.[0]?.data.id;
    },
    [topologyElements.nodesByName]
  );

  const selectNodesBGP = useCallback(
    (selectedAnomaly: HotlErAnomalyNeighborDropBgp) => {
      const matchingPeer = deviceBGPdata?.find(
        peer => peer.peer_remote_addr === selectedAnomaly.explanatory_reasons.peer_remote_addr
      );

      const affectedResourceNodeId = getAffectedResourceNodeId(
        selectedAnomaly.affected_resources[0]
      );

      if (!affectedResourceNodeId || !matchingPeer) {
        clearElements();
        return;
      }

      if (matchingPeer.peer_type === 'EBGP') {
        setSelectedNodeIds([affectedResourceNodeId, String(matchingPeer?.peer_remote_as)]);
        setSelectedEdgeIds([`${matchingPeer.node_id}_${matchingPeer.peer_remote_as}`]);
      }

      if (matchingPeer.peer_type === 'IBGP') {
        const edgesToHighlight = matchingPeer.peer_remote_node_ids
          .map(remote_id => {
            const matchingSourceEdges = topologyElements.edgesBySource?.[
              affectedResourceNodeId
            ]?.filter(edge => edge.data.target_node_id === remote_id);
            const matchingTargetEdges = topologyElements.edgesBySource?.[remote_id]?.filter(
              edge => edge.data.target_node_id === affectedResourceNodeId
            );

            const edges = [...(matchingSourceEdges || []), ...(matchingTargetEdges || [])].map(
              edge => `${edge.data.source_node_name}_${edge.data.source_int_name}`
            );
            return isEmpty(edges) ? [`${affectedResourceNodeId}_${remote_id}`] : edges;
          })
          .flat();
        const uniqEdgesToHighlight = uniq(edgesToHighlight);
        setSelectedEdgeIds(uniqEdgesToHighlight);
        setSelectedNodeIds([affectedResourceNodeId, matchingPeer.peer_remote_node_ids].flat());
      }
    },
    [
      clearElements,
      deviceBGPdata,
      getAffectedResourceNodeId,
      setSelectedEdgeIds,
      setSelectedNodeIds,
      topologyElements.edgesBySource,
    ]
  );

  const selectNodesISIS = useCallback(
    (selectedAnomaly: HotlErAnomalyNeighborDropIsis) => {
      const matchingAdjacency = deviceISISdata?.find(
        adjacency =>
          adjacency.isis_adj_ip_addr_address ===
          selectedAnomaly.explanatory_reasons.isis_adj_ip_addr_address
      );

      const adjacencyNodeIds = matchingAdjacency?.isis_adj_node_ids;
      const affectedResourceNodeId = getAffectedResourceNodeId(
        selectedAnomaly.affected_resources[0]
      );

      if (!affectedResourceNodeId || !adjacencyNodeIds) {
        clearElements();
        return;
      }

      const selectedNodeIds = [affectedResourceNodeId, adjacencyNodeIds].flat();
      const selectedNodeIdsFormatted = selectedNodeIds.flatMap(id => [id, `${id}_ISIS_Compound`]);

      setSelectedNodeIds(selectedNodeIdsFormatted);
    },
    [clearElements, deviceISISdata, getAffectedResourceNodeId, setSelectedNodeIds]
  );

  return {selectNodesBGP, selectNodesISIS};
};

export default useSelectNodesNeighborDrop;
