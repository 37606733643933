import {useRecoilValue, useSetRecoilState} from 'recoil';
import {selectedDataCenterId} from '../atoms/selectedDatacenter';
import {timelineState} from '../atoms/dashboard';
import {useQuery} from 'react-query';
import {selectedAnomalyDataState, selectedAnomalyIdState} from '../atoms/selectedAnomaly';
import {anomalyFilterArraysState} from '../selectors/anomalyFilterArrays';
import {
  getAnomaliesByDatacenterId,
  getAnomalyById,
  getAnomalyBinCountsFill,
  getAnomaliesCount,
} from '../utils/PythonApiUtils';
import config from '../utils/AppConfigurationService';
import {anomalyTableSortingState} from '../atoms/AnomalyTableSorting';
import {useEffect} from 'react';
import useDebouncedInput from '../helpers/hooks/useDebouncedInput';
import {DateTime, Interval} from 'luxon';
import {tokenizedSearch} from '../selectors/tokenizedAffectedResourceSearch';

export function useDCAnomalyList(pageIndex = 0, limit = config.apiResultLimit) {
  const DCId = useRecoilValue(selectedDataCenterId);
  const filters = useRecoilValue(anomalyFilterArraysState);
  const anomalyTableSorting = useRecoilValue(anomalyTableSortingState);
  const searchTokens = useRecoilValue(tokenizedSearch);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const offset = pageIndex * limit;

  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'anomalyList',
      filters,
      DCId,
      debouncedStart,
      debouncedEnd,
      anomalyTableSorting,
      offset,
      limit,
      searchTokens,
    ],
    () =>
      getAnomaliesByDatacenterId({
        sort_fields: anomalyTableSorting,
        dc_id: DCId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        anomaly_types: filters.anomalyTypeFilter,
        priority_types: filters.priorityFilter,
        read: filters.readFilter,
        starred: filters.starredFilter,
        limit: limit,
        affected_resources: searchTokens,
        classification_types: filters.classFilter,
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useAnomalyCount() {
  const DCId = useRecoilValue(selectedDataCenterId);
  const filters = useRecoilValue(anomalyFilterArraysState);
  const searchTokens = useRecoilValue(tokenizedSearch);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);

  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['anomalyCount', filters, DCId, debouncedStart, debouncedEnd, searchTokens],
    () =>
      getAnomaliesCount({
        dc_id: DCId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_types: filters.anomalyTypeFilter,
        priority_types: filters.priorityFilter,
        read: filters.readFilter,
        starred: filters.starredFilter,
        affected_resources: searchTokens,
        classification_types: filters.classFilter,
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useSelectedAnomaly() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const setSelectedAnomalyData = useSetRecoilState(selectedAnomalyDataState);
  const query = useQuery(
    ['anomaly', anomalyId],
    () =>
      getAnomalyById({
        anomaly_id: anomalyId,
      }),
    {
      enabled: !!anomalyId,
    }
  );

  const {data} = query;

  useEffect(() => {
    if (!data) return;
    setSelectedAnomalyData(data);
  }, [data, setSelectedAnomalyData]);

  useEffect(() => {
    if (data && new Date(data.min_timestamp) > new Date(data.max_timestamp)) {
      throw new Error(
        `Observation ${data.anomaly_id} data is invalid: start time '${data.min_timestamp}' is greater than end time '${data.max_timestamp}'!`
      );
    }
  }, [data]);

  return query;
}

export function useAnomalyCountFill() {
  const {timelineInterval} = useRecoilValue(timelineState);
  const {start, end} = timelineInterval;
  const DCId = useRecoilValue(selectedDataCenterId);
  const debouncedInterval = useDebouncedInput(timelineInterval, 250, true);

  const debouncedStart = debouncedInterval.start.toMillis();
  const debouncedEnd = debouncedInterval.end.toMillis();

  return useQuery(
    ['anomalyCountsFill', debouncedStart, debouncedEnd, DCId, DateTime.now().minute],
    () => {
      return getAnomalyBinCountsFill({
        start: start.setZone('UTC').toISO(),
        end: end
          .plus({
            seconds: Math.max(
              Number(((Interval.fromDateTimes(start, end).length('seconds') * 2) / 100).toFixed()),
              2
            ),
          })
          .setZone('UTC')
          .toISO(),
        dc_id: DCId,
        priority_types: config.DefaultPriorities,
        classification_types: config.DefaultClasses,
        total_bins: 100,
      });
    },
    {
      keepPreviousData: true,
    }
  );
}
