import {useRecoilState} from 'recoil';
import styled from 'styled-components';
import {selectedWhatIfStatState, WhatIfStats} from '../../../../atoms/selectedWhatIfStat';
import {WhatIf1Sigma, WhatIf2Sigma, WhatIfAVG, WhatIfMAX} from '../../../../constants/WhatIfStats';
import Dropdown from '../../../common/Dropdown/Dropdown';

const Container = styled.div``;

const dropdownOptions = [WhatIfAVG, WhatIf1Sigma, WhatIf2Sigma, WhatIfMAX];

export default function StatDropdown() {
  const [selectedWhatIfStat, setSelectedWhatIfStat] = useRecoilState(selectedWhatIfStatState);

  return (
    <Container>
      <Dropdown
        dropdownOptions={dropdownOptions}
        onChange={(value: string) => setSelectedWhatIfStat(value as WhatIfStats)}
        selectedValue={selectedWhatIfStat}
      />
    </Container>
  );
}
