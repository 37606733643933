import {useEffect, useMemo, useRef} from 'react';
import TableSortArrow from '../../../common/TableSortArrow/TableSortArrow';
import Pagination from '../../../common/Pagination/Pagination';
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {timelineState} from '../../../../atoms/dashboard';
import {isEmpty} from 'lodash';
import {reportingDashboardSortingState} from '../../../../atoms/reportingDashboardSorting';
import {selectedReportingKPIstate} from '../../../../atoms/selectedReportingKPI';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {convertSortToApiSchema} from '../../../../utils/convertSortToApiSchema';
import useNavigateKeepParams from '../../../../helpers/hooks/useNavigateKeepParams';
import {selectedAnomalyIdState} from '../../../../atoms/selectedAnomaly';
import {EmptyObservationsMessage} from '../../../../constants/queryLifecycleMessages';
import {HotlAnomaly} from '../../../../openapi-schema/schemaTS';
import {ReportingDashboardTableProps} from '../ReportingDashboardMainTable';
import {scrollToTop} from '../../../../utils/scrollToTop';
import {
  Table,
  HeaderWrapper,
  Header,
  HeaderRow,
  TableBody,
  TableData,
  TableRow,
} from '../../../common/TableElements/TableElements';
import {flexRender, getCoreRowModel, useReactTable, Row} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

export default function TableBase({
  query,
  columns,
  initialState,
  anomalyCount,
  pageSizeOptions,
  APIPageSize,
  setAPIPageIndex,
  setAPIPageSize,
}: ReportingDashboardTableProps) {
  const timeline = useRecoilValue(timelineState);
  const selectedReportingKPI = useRecoilValue(selectedReportingKPIstate);
  const setReportingDashboardSortState = useSetRecoilState(reportingDashboardSortingState);
  const resetReportingDashboardSortState = useResetRecoilState(reportingDashboardSortingState);
  const navigateKeepParams = useNavigateKeepParams();
  const setSelectedAnomalyID = useSetRecoilState(selectedAnomalyIdState);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const {data: APIdata} = query;

  const data = useMemo(() => (APIdata ? APIdata : []), [APIdata]);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
    resetRowSelection,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    enableRowSelection: true,
    pageCount: Math.ceil((anomalyCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableMultiRowSelection: false,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  useEffect(() => {
    setPageIndex(0);
    resetRowSelection(true);
  }, [pageSize, timeline, sorting, selectedReportingKPI, setPageIndex, resetRowSelection]);

  useEffect(() => {
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
    resetRowSelection(true);
  }, [pageIndex, pageSize, setAPIPageIndex, setAPIPageSize, resetRowSelection]);

  useEffect(() => {
    const replacements = {};
    const newSortBy = convertSortToApiSchema(sorting, replacements);
    setReportingDashboardSortState(newSortBy);
  }, [setReportingDashboardSortState, sorting]);

  useEffect(() => {
    return () => resetReportingDashboardSortState();
  }, [resetReportingDashboardSortState]);

  const handleToggle = (row: Row<HotlAnomaly>) => {
    row.toggleSelected(!row.getIsSelected());
  };

  return (
    <>
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={query}
            isEmpty={isEmpty(data)}
            emptyMessage={EmptyObservationsMessage}
          >
            {getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                onClick={() => handleToggle(row)}
                isSelected={row.getIsSelected()}
                onDoubleClick={() => {
                  setSelectedAnomalyID(row.original.anomaly_id);
                  navigateKeepParams({
                    pathname: `/anomaly/${row.original.anomaly_id}`,
                    clearStartEnd: true,
                  });
                }}
              >
                {row.getVisibleCells().map(cell => (
                  <TableData key={cell.id} style={{...getCellStyles(cell)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
}
