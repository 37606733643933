import {ChartOptions, Chart, TooltipModel} from 'chart.js';
import {maxBy} from 'lodash';
import {Scatter} from 'react-chartjs-2';
import styled from 'styled-components';
import {
  chartHorizontalAxisOptions,
  chartHorizontalTickOptions,
  chartVerticalAxisOptions,
  chartVerticalAxisTickOptions,
  coreChartOptions,
} from '../../../../constants/chartOptions';
import zoomPlugin from 'chartjs-plugin-zoom';
import {getRandomColors} from '../../../../utils/randomColor';
import {useMemo, useState} from 'react';
import ScatterplotTooltip from './ScatterplotTooltip';
import {useDeviceHealthOverviewData} from 'data/HardwareMonitoring';
import {MtbfDeviceHealthOverview} from 'openapi-schema/schemaTS';
import {ScatterplotLegend} from './ScatterplotLegend';

Chart.register(zoomPlugin);

const Container = styled.div`
  flex: 1;
  display: flex;
  margin-top: 6px;
  gap: 10px;
  overflow: auto;
  position: relative;
`;

const ScatterplotWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

const datasetOptions = {
  pointRadius: 6,
  pointHitRadius: 6,
  pointHoverRadius: 6,
  clip: 3,
};

export function DeviceHealthScatterplot() {
  const [itemIsHidden, setItemIsHidden] = useState({
    '1 Year': false,
    '3 Months': true,
    '1 Month': true,
  });
  const [tooltipData, setTooltipData] = useState<TooltipModel<'scatter'>>();
  const {data: APIdata} = useDeviceHealthOverviewData();

  const data = useMemo(() => {
    const durations: {key: keyof MtbfDeviceHealthOverview; label: string}[] = [
      {key: 'percent_failure_1_year', label: '1 Year'},
      {key: 'percent_failure_90_days', label: '3 Months'},
      {key: 'percent_failure_30_days', label: '1 Month'},
    ];

    return {
      datasets: durations.map(duration => {
        const color = getRandomColors({seed: duration.label + duration.key});

        return {
          label: duration.label,
          color: color,
          pointBackgroundColor: color,
          pointHoverBackgroundColor: color,
          hidden: itemIsHidden?.[duration.label],
          ...datasetOptions,
          data: APIdata?.map(device => {
            return {
              ...device,
              x: device.connections,
              y: device?.[duration.key],
            };
          }),
        };
      }),
    };
  }, [APIdata, itemIsHidden]);

  const maxXvalue = maxBy(APIdata, 'connections')?.connections;

  const options: ChartOptions<'scatter'> = useMemo(() => {
    return {
      ...coreChartOptions,
      // onClick(event, elements, chart) {
      //   if (elements.length) {
      //     setDeviceInformationOpen(true);
      //   }
      // },
      // onHover(event, elements, chart) {
      //   if (elements.length) {
      //     document.body.style.cursor = 'pointer';
      //   } else {
      //     document.body.style.cursor = 'default';
      //   }
      // },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: context => {
            if (context.tooltip.opacity) {
              setTooltipData({...context.tooltip});
            } else {
              setTooltipData(undefined);
            }
          },
        },
        zoom: {
          pan: {
            enabled: true,
          },
          limits: {
            x: {
              min: 'original',
              max: 'original',
            },
            y: {
              min: 'original',
              max: 'original',
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
          },
        },
      },
      scales: {
        y: {
          ...chartVerticalAxisOptions,
          display: true,
          ticks: {
            ...chartVerticalAxisTickOptions,
            callback: value => `${Math.round(Number(value))}%`,
          },
          type: 'linear',
          title: {
            display: true,
            text: 'Failure Probability',
            color: 'white',
            font: {
              size: 22,
            },
          },
          grid: {
            borderColor: 'rgba(189, 189, 189, 1)',
            color: function (context) {
              if (context.tick.value >= 70) {
                return 'rgba(205, 62, 56, 1)';
              }
              return 'rgba(189, 189, 189, 1)';
            },
            tickColor: 'black',
            borderDash: [2, 2],
          },
          min: 0,
          max: 100,
        },
        x: {
          ...chartHorizontalAxisOptions,
          type: 'linear',
          position: 'bottom',
          ticks: {
            ...chartHorizontalTickOptions,
            includeBounds: true,
            stepSize: 1,
            callback: value => `${Math.round(Number(value))}`,
          },
          title: {
            display: true,
            text: 'Number of Connected Devices (Impact)',
            color: 'white',
            font: {
              size: 22,
            },
          },
          grid: {
            borderColor: 'rgba(189, 189, 189, 1)',
            color: 'rgba(189, 189, 189, 1)',
            drawTicks: false,
            borderDash: [2, 2],
          },
          min: 0,
          max: Number(maxXvalue) + 1,
        },
      },
    };
  }, [maxXvalue]);

  const toggleData = (datasetIndex: string) => {
    setItemIsHidden(currVal => {
      return {
        ...currVal,
        [datasetIndex]: !currVal[datasetIndex],
      };
    });
  };

  return (
    <Container>
      <ScatterplotWrapper>
        <Scatter data={data} options={options} />
      </ScatterplotWrapper>
      <ScatterplotLegend
        legendItems={data.datasets.map(entry => {
          return {
            text: entry.label,
            hidden: entry.hidden,
            color: entry.color,
          };
        })}
        toggleData={toggleData}
      />
      {tooltipData && <ScatterplotTooltip tooltipData={tooltipData} />}
    </Container>
  );
}
