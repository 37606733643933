import {useRecoilState, useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {selectedWhatIfMetricState, WhatIfMetrics} from '../../../../atoms/selectedWhatIfMetric';
import {whatIfAnimationActiveState} from '../../../../atoms/whatIfMetricAnimationActive';
import {
  whatIfSelectedEdgeDataState,
  whatIfSelectedNodeDataState,
} from '../../../../atoms/whatIfSelectedTopologyElements';
import {
  InterfaceMetrics,
  NodeMetrics,
  WhatIfCPU,
  WhatIfMemory,
  WhatIfTCAM,
  WhatIfUtilization,
} from '../../../../constants/WhatIfMetrics';
import useWhatIfTopologyActions from '../../../../helpers/hooks/useWhatIfTopologyActions';
import Dropdown from '../../../common/Dropdown/Dropdown';

interface ContainerProps {
  animationActive: boolean;
}

const Container = styled.div<ContainerProps>`
  animation: ${props => (props.animationActive ? 'pulse 1.5s ease alternate' : '')};
`;

const dropdownOptions = [WhatIfUtilization, WhatIfCPU, WhatIfMemory, WhatIfTCAM];

export default function MetricDropdown() {
  const [selectedWhatIfMetric, setSelectedWhatIfMetric] = useRecoilState(selectedWhatIfMetricState);
  const [whatIfAnimationActive, setWhatIfAnimationActive] = useRecoilState(
    whatIfAnimationActiveState
  );
  const whatIfSelectedNodeData = useRecoilValue(whatIfSelectedNodeDataState);
  const whatIfSelectedEdgeData = useRecoilValue(whatIfSelectedEdgeDataState);
  const {updateStateClickBackground} = useWhatIfTopologyActions();

  const handleChange = (value: string) => {
    if (
      (whatIfSelectedNodeData && value in InterfaceMetrics) ||
      (whatIfSelectedEdgeData && value in NodeMetrics)
    ) {
      updateStateClickBackground();
    }
    setSelectedWhatIfMetric(value as WhatIfMetrics);
  };

  return (
    <Container
      animationActive={whatIfAnimationActive}
      onAnimationEnd={() => setWhatIfAnimationActive(false)}
    >
      <Dropdown
        dropdownOptions={dropdownOptions}
        onChange={handleChange}
        selectedValue={selectedWhatIfMetric}
      />
    </Container>
  );
}
