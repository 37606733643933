import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useEffect, useState} from 'react';
import styled from 'styled-components';

interface DropdownContainerProps {
  filled: boolean;
  large: boolean;
}

const LargeHeight = 28;
const SmallHeight = 24;

export const DropdownContainer = styled.div<DropdownContainerProps>`
  height: ${props => (props.large ? `${LargeHeight}px` : `${SmallHeight}px`)};

  .MuiOutlinedInput-root,
  button {
    background: ${props => (props.filled ? 'rgba(75, 76, 85, 1)' : 'none')};
    border-radius: 1px;
    font-size: ${props => (props.large ? '14px' : '13px')};
    color: white;
    width: 100%;
    height: 100%;
    border: ${props => (props.filled ? 'none' : '1px solid rgba(78, 78, 78, 1)')};
  }

  .MuiSelect-select.MuiSelect-outlined,
  .MuiSelect-select:focus,
  button {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: ${props => (props.filled ? '0 10px' : '0 9px')};
    height: ${props => (props.filled ? '100%' : 'calc(100% + 2px)')};
    line-height: ${props => (props.large ? `${LargeHeight}px` : `${SmallHeight}px`)};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    top: calc(50% - 9px);
    color: white;
  }

  .MuiPaper-root {
    border-radius: 0;
  }

  .MuiList-root {
    padding: 4px 0;
    background: rgba(75, 76, 85, 1);
    color: white;
  }

  .MuiMenuItem-root {
    font-size: ${props => (props.large ? '14px' : '13px')};
    height: ${props => (props.large ? '33px' : '24px')};
    padding: 0 10px;
  }

  .MuiMenuItem-root.Mui-selected,
  .MuiMenuItem-root.Mui-selected:hover,
  .MuiMenuItem-root:hover {
    background: rgba(110, 110, 110, 1);
  }

  .MuiPopover-root {
    cursor: default;
  }
`;

type DropdownStyle = 'fill' | 'outline' | undefined;
type DropdownSize = 'small' | 'large' | undefined;

interface DropdownProps {
  dropdownOptions: string[];
  onChange: (value: string, prevValue: string) => void;
  selectedValue?: string;
  title?: string;
  variant?: DropdownStyle;
  size?: DropdownSize;
}

export default function Dropdown({
  dropdownOptions,
  onChange,
  selectedValue,
  title = '',
  variant = 'fill',
  size = 'large',
}: DropdownProps) {
  const [value, setValue] = useState(selectedValue ?? dropdownOptions[0]);

  useEffect(() => {
    setValue(selectedValue ?? dropdownOptions[0]);
  }, [dropdownOptions, selectedValue]);

  const handleChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as string);
    onChange(event.target.value as string, value);
  };

  return (
    <DropdownContainer filled={variant === 'fill'} large={size === 'large'}>
      {!dropdownOptions.every(option => option !== value) && (
        <Select
          MenuProps={{disablePortal: true}}
          value={value as ''}
          onChange={handleChange}
          renderValue={value => (title ? `${title}: ${value}` : `${value}`)}
        >
          {dropdownOptions.map((option, index) => (
            <MenuItem value={option} key={option || index}>
              {option}
            </MenuItem>
          ))}
        </Select>
      )}
    </DropdownContainer>
  );
}
