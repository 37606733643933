import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {selectedAnomalyDataState} from '../../../../atoms/selectedAnomaly';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import LogUtilizationDetailPageTopology from '../../../common/LogUtilizationDetailPageTopology/LogUtilizationDetailPageTopology';
import config from '../../../../utils/AppConfigurationService';
import {AnomalyTypeBucket} from '../../../../openapi-schema/schemaTS';
import TopologyWANDetailPage from '../../../common/TopologyWANDetailPage/TopologyWANDetailPage';
import TopologyPathChange from '../../../common/TopologyPathChange/PathChangeLegend';
import TopologyVTC from 'components/common/TopologyVTC/TopologyVTC';
import TopologyFiberChannel from 'components/common/TopologyFiberChannel/TopologyFiberChannel';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  position: relative;
`;

const TitleWrapper = styled.div`
  position: absolute;
  z-index: 2;
  margin: 10px;
  top: 0;
  left: 0;
`;

const getTitle = (type: AnomalyTypeBucket | undefined) => {
  if (
    type === config.AnomalyTypes.NEIGHBOR_DROP_BGP ||
    type === config.AnomalyTypes.NEIGHBOR_DROP_ISIS
  ) {
    return 'WAN Topology';
  }
  if (type === config.AnomalyTypes.PATH_CHANGE) return 'Path Change Analysis';
  if (type === config.AnomalyTypes.VIDEO_VOIP) return 'Video/VoIP Path Trace';
  else {
    return 'Topology';
  }
};

const getComponent = (type: AnomalyTypeBucket | undefined) => {
  if (
    type === config.AnomalyTypes.NEIGHBOR_DROP_BGP ||
    type === config.AnomalyTypes.NEIGHBOR_DROP_ISIS
  ) {
    return <TopologyWANDetailPage />;
  }
  if (type === config.AnomalyTypes.PATH_CHANGE) {
    return <TopologyPathChange />;
  }
  if (type === config.AnomalyTypes.VIDEO_VOIP) {
    return <TopologyVTC />;
  }
  if (type === config.AnomalyTypes.FIBER_CHANNEL) {
    return <TopologyFiberChannel />;
  } else {
    return <LogUtilizationDetailPageTopology />;
  }
};

export default function TopologyDetailPage() {
  const selectedAnoamlyData = useRecoilValue(selectedAnomalyDataState);

  return (
    <Container>
      <TitleWrapper>
        <ComponentTitle title={getTitle(selectedAnoamlyData?.anomaly_type)} />
      </TitleWrapper>
      {getComponent(selectedAnoamlyData?.anomaly_type)}
    </Container>
  );
}
