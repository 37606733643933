import {createGlobalStyle} from 'styled-components';

// These will need a new home
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto-mono/400.css';
import '@fontsource/roboto-mono/500.css';

export const ScrollBarStyles = `
  ::-webkit-scrollbar {
    background: black;
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(92, 92, 92, 1);
    border-radius: 1000px;
  }
`;

export const GlobalStyles = createGlobalStyle`
    
  html, body {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  #root {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto';

    ${ScrollBarStyles}
  }

  @keyframes pulse {
    0% {
      box-shadow: 0px 0px 0px 0px rgba(120, 83, 203, 1);
    }
    50% {
      box-shadow: 0px 0px 5px 3px rgba(120, 83, 203, 1);
    }
    100% {
      box-shadow: 0px 0px 0px 0px rgba(120, 83, 203, 1);
    }
  }
`;
