import {AuthProvider, AuthProviderProps, hasAuthParams, useAuth} from 'react-oidc-context';
import envConfig from '../config/env.config';
import {useNavigate} from 'react-router-dom';
import {useEffect, useMemo, useRef, useState} from 'react';
import {WebStorageStateStore} from 'oidc-client-ts';
import {noop, debounce} from 'lodash';

const oidcConfig: AuthProviderProps = {
  authority: envConfig.oidc.url,
  client_id: envConfig.oidc.clientId,
  redirect_uri: `${window.location.origin}`,
  automaticSilentRenew: true,
  response_type: 'code',
  response_mode: 'query',
  loadUserInfo: false,
};

export const authStorage = window.localStorage;
let checkForActiveSession = noop;
export const CheckSession = debounce(() => checkForActiveSession(), 60 * 1000, {leading: true});

const AuthEvents = ({children}) => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinSilent();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  // Provide method outside of React to affirm the session
  useEffect(() => {
    checkForActiveSession = () => {
      auth.signinSilent();
    };
  }, [auth]);

  return <>{children}</>;
};

export const AuthProviderWrapper = ({children}) => {
  const timeoutRef = useRef<NodeJS.Timeout[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = timeoutRef.current || [];
    return () => timeout.forEach(clearTimeout);
  }, []);

  const config = useMemo(() => {
    return {
      ...oidcConfig,
      onSigninCallback: user => {
        // The recommended callback has conflicts with react-router, so we use router calls instead
        // https://github.com/authts/react-oidc-context/blob/f175dcba6ab09871b027d6a2f2224a17712b67c5/src/AuthProvider.tsx#L20-L30
        const redirectPath = localStorage.getItem('redirectPathname') || '/';
        const redirectSearch = localStorage.getItem('redirectSearch') || '';
        timeoutRef.current.push(
          setTimeout(() => {
            navigate(
              {
                pathname: redirectPath,
                search: redirectSearch,
              },
              {replace: true}
            );
          }, 0)
        );
      },
      userStore: new WebStorageStateStore({store: window.localStorage}),
      monitorSession: true,
    };
  }, [navigate]);

  return (
    <AuthProvider {...config}>
      <AuthEvents>{children}</AuthEvents>
    </AuthProvider>
  );
};
