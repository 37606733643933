import styled from 'styled-components';
import Scale from './Scale';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function CriticalityTooltip() {
  return (
    <Container>
      <p>
        Compares the current observation's priority to the highest priority observation seen the
        past week. This can help gauge the relative importance of an observation.
      </p>
      <Scale />
      <p>
        <b>Note</b>: A result of zero could mean there were no valid priorities or all priorities
        were zero.
      </p>
    </Container>
  );
}
