import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 16px;
  padding: 5px 0 10px 0;
  color: white;
  justify-content: center;
`;

const Box = styled.div`
  width: 16px;
  height: 16px;
  background: rgba(205, 62, 56, 0.5);
  border: 1px solid rgba(205, 62, 56, 1);
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
`;

export default function ISISAdjacencyStatusLegend() {
  return (
    <Container>
      <Box />
      <Label>Adjacency down time</Label>
    </Container>
  );
}
