import styled from 'styled-components';
import {ScriptExecutionInfo} from '../../../../openapi-schema/fingerprintSchemaTS';
import {ServiceNowModalActions} from '../../ServiceNowModal/ServiceNowModalActions';
import Run from './Run';
import View from './View';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

interface Props {
  scriptExecutionInfo: ScriptExecutionInfo;
}

export default function Actions({scriptExecutionInfo}: Props) {
  const {openModal} = ServiceNowModalActions();

  const status = scriptExecutionInfo.status as string;

  return (
    <Container onClick={() => openModal(scriptExecutionInfo)}>
      {status === 'PENDING' || status === 'RUNNING' ? <Run status={status} /> : <View />}
    </Container>
  );
}
