import {useCallback, useState} from 'react';
import AccountDropdown from './AccountDropdown';
import {useAuth} from 'react-oidc-context';
import {ManageUserAccessModal} from 'components/common/ManageUserAccessModal/ManageUserAccessModal';
import {useRecoilState} from 'recoil';
import {manageuserAccessModalOpen} from '../../../../atoms/adminSettings';
import UserInfo from './UserInfo';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import classnames from 'classnames';

export default function UserButton() {
  const auth = useAuth();
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [manageUsersOpen, setManageUsersOpen] = useRecoilState(manageuserAccessModalOpen);

  const openAccountMenu = useCallback(() => setAccountDropdownOpen(true), []);

  return (
    <div className="text-white">
      <div
        className={classnames(
          'flex cursor-pointer flex-row items-center p-1 hover:bg-dark-surface-bright',
          {'bg-dark-surface-bright': accountDropdownOpen}
        )}
        onClick={openAccountMenu}
      >
        <UserInfo
          userName={auth.user?.profile.name ?? ''}
          email={auth.user?.profile.email ?? ''}
          size="small"
        />
        <div className="h-6 w-6">
          <SettingsOutlinedIcon viewBox="-1 1 24 24" />
        </div>
      </div>
      {accountDropdownOpen && (
        <AccountDropdown
          userName={auth.user?.profile.name ?? ''}
          setAccountDropdownOpen={setAccountDropdownOpen}
        />
      )}
      <ManageUserAccessModal
        isOpen={manageUsersOpen}
        close={() => {
          setManageUsersOpen(false);
        }}
        action={() => {
          setManageUsersOpen(false);
        }}
      />
    </div>
  );
}
