import GoldenLayout from '../../common/GoldenLayout/GoldenLayout';
import TopologyDetailPage from '../common/TopologyDetailPage/TopologyDetailPage';
import AssociatedAnomaliesPathChange from './components/AssociatedAnomaliesPathChange';
import AssociatedLogsPathChange from './components/AssociatedLogsPathChange';
import PathChangeAnomalyTable from './components/PathInformationTable/PathChangeAnomalyTable';
import PathDelayChart from './components/PathDelayChart';

export default function PathChangeDetailDashboard() {
  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              type: 'column',
              width: 67,
              content: [
                {
                  component: () => <PathChangeAnomalyTable />,
                  height: 60,
                },
                {
                  component: () => <AssociatedLogsPathChange />,
                  height: 40,
                },
              ],
            },
            {
              type: 'column',
              width: 33,
              content: [
                {
                  component: () => <PathDelayChart />,
                  height: 30,
                },
                {
                  component: () => <AssociatedAnomaliesPathChange />,
                  height: 40,
                },
                {
                  component: () => <TopologyDetailPage />,
                  height: 30,
                },
              ],
            },
          ],
        },
      ]}
      padding="7px 10px 10px 10px"
    />
  );
}
