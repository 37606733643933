import {useRecoilTransaction_UNSTABLE} from 'recoil';
import {selectedWhatIfInterfaceState} from '../../atoms/selectedWhatIfInterface';
import {selectedWhatIfMetricState, WhatIfMetrics} from '../../atoms/selectedWhatIfMetric';
import {snackbarDataState} from '../../atoms/snackbarData';
import {whatIfAnimationActiveState} from '../../atoms/whatIfMetricAnimationActive';
import {
  whatIfSelectedEdgeDataState,
  whatIfSelectedNodeDataState,
} from '../../atoms/whatIfSelectedTopologyElements';
import {WhatIfCPU, WhatIfUtilization} from '../../constants/WhatIfMetrics';
import {TopologyEdgeData, TopologyNodeData} from '../../data/types';

const useWhatIfTopologyActions = () => {
  const updateStateNode = useRecoilTransaction_UNSTABLE(
    ({set, reset}) =>
      (data: TopologyNodeData, changeMetric: boolean) => {
        set(whatIfSelectedNodeDataState, data);
        reset(whatIfSelectedEdgeDataState);
        set(selectedWhatIfInterfaceState, null);
        if (changeMetric) {
          set(selectedWhatIfMetricState, WhatIfMetrics.WhatIfCPU);
          set(snackbarDataState, {
            severity: 'default',
            open: true,
            message: `Changed to ${WhatIfCPU}`,
          });
          set(whatIfAnimationActiveState, true);
        }
      },
    []
  );

  const updateStateEdge = useRecoilTransaction_UNSTABLE(
    ({set, reset}) =>
      (data: TopologyEdgeData, changeMetric: boolean) => {
        reset(whatIfSelectedNodeDataState);
        set(whatIfSelectedEdgeDataState, data);
        set(selectedWhatIfInterfaceState, {
          node_name: data.source_node_name,
          int_name: data.source_int_name,
        });
        if (changeMetric) {
          reset(selectedWhatIfMetricState);
          set(snackbarDataState, {
            severity: 'default',
            open: true,
            message: `Changed to ${WhatIfUtilization}`,
          });
          set(whatIfAnimationActiveState, true);
        }
      },
    []
  );

  const updateStateClickBackground = useRecoilTransaction_UNSTABLE(
    ({reset}) =>
      () => {
        reset(whatIfSelectedNodeDataState);
        reset(whatIfSelectedEdgeDataState);
        reset(selectedWhatIfInterfaceState);
      },
    []
  );

  return {updateStateNode, updateStateEdge, updateStateClickBackground};
};

export default useWhatIfTopologyActions;
