import {
  useLogTemplatesUtilization,
  useLogTemplatesUtilizationCount,
} from '../../../../data/InterfaceUtilization';
import AssociatedLogsTable from '../../common/AssociatedLogsTable/AssociatedLogsTable';

export default function AssociatedLogsUtilization() {
  return (
    <AssociatedLogsTable
      logListQuery={useLogTemplatesUtilization}
      logCountQuery={useLogTemplatesUtilizationCount}
    />
  );
}
