export const getWarningModalMessage = (enabled: boolean, classification: string) => {
  if (!enabled) {
    return (
      <>
        While disabled, this <b>Fingerprint</b> will ignore any matching observations, no tickets
        will be opened, and no scripts will be run. If you wish to continue matching observations,
        change the <b>Automation</b> Level instead.
      </>
    );
  }
  if (classification === 'AUTO') {
    return (
      <>
        Once enabled, this <b>Fingerprint</b> will resume matching observations, opening tickets,
        and automatically running the attached script as indicated by the current{' '}
        <b>Automation Level (Auto)</b>.
      </>
    );
  }
  if (classification === 'MANUAL') {
    return (
      <>
        Once enabled, this <b>Fingerprint</b> will resume matching observations, opening tickets,
        and queueing attached scripts for manual execution as indicated by the current{' '}
        <b>Automation Level (Manual)</b>.
      </>
    );
  }
  return (
    <>
      Once enabled, this <b>Fingerprint</b> will resume matching observations, but take no other
      actions as indicated by the current <b>Automation Level (Silent)</b>.
    </>
  );
};
