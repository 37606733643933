import IconComponent, {IconProps} from '../IconComponent';

export default function NonePriorityColorIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 20}} className={className}>
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="NonePriorityColorIcon"
      >
        <path
          d="M10.0003 1.66602C5.41699 1.66602 1.66699 5.41602 1.66699 9.99935C1.66699 14.5827 5.41699 18.3327 10.0003 18.3327C14.5837 18.3327 18.3337 14.5827 18.3337 9.99935C18.3337 5.41602 14.5837 1.66602 10.0003 1.66602Z"
          fill="#219FD8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16699 10.001L8.33366 14.1677L15.8337 6.66771L14.6587 5.48438L8.33366 11.8094L5.34199 8.81771L4.16699 10.001Z"
          fill="white"
        />
      </svg>
    </IconComponent>
  );
}
