import {useMemo, useEffect, useRef, useState} from 'react';
import TableSortArrow from '../../../common/TableSortArrow/TableSortArrow';
import {formatLog, FormattedLogTemplate} from '../../../../utils/formatLogs';
import {useRecoilState, useRecoilValue} from 'recoil';
import Pagination from '../../../common/Pagination/Pagination';
import {timelineState} from '../../../../atoms/dashboard';
import {isEmpty} from 'lodash';
import {logTableSortingState} from '../../../../atoms/logTableSorting';
import QueryLifecycleWrapper from '../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {convertSortToApiSchema} from '../../../../utils/convertSortToApiSchema';
import {convertSortToReactTableSchema} from '../../../../utils/convertSortToReactTableSchema';
import {EmptyLogsMessage} from '../../../../constants/queryLifecycleMessages';
import {scrollToTop} from '../../../../utils/scrollToTop';
import TitleTooltip from 'components/common/TitleTooltip/TitleTooltip';
import {
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TableRow,
  TableData,
} from 'components/common/TableElements/TableElements';
import {getScarcityCellText} from 'utils/getScarcityCellText';
import Timestamp from 'components/common/Timestamp';
import ScarcityTooltip from './ScarcityTooltip';
import {LogCountQuery, LogListQuery} from 'data/types';
import {pageSizeOptionsLarge} from 'constants/PageSizeOptions';
import {selectedWordCloudWordsState} from 'atoms/selectedWordCloudWords';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const ScarcityId = 'Scarcity';
const EventId = 'Event ID';
const ScarcitySort = 'explanatory_reasons.scarcity_details';
const SyslogSort = 'explanatory_reasons.syslog_priority';

const columnHelper = createColumnHelper<FormattedLogTemplate>();

interface Props {
  logListQuery: LogListQuery;
  logCountQuery: LogCountQuery;
  pageSizeOptions?: number[];
  template_id?: string;
  template_version?: number;
  showEventId?: boolean;
}

export default function LogsTable({
  logListQuery,
  logCountQuery,
  pageSizeOptions = pageSizeOptionsLarge,
  template_id,
  template_version,
  showEventId = false,
}: Props) {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const timeline = useRecoilValue(timelineState);
  const [logTableSorting, setLogTableSorting] = useRecoilState(logTableSortingState);
  const selectedWordcloudWords = useRecoilValue(selectedWordCloudWordsState);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const query = logListQuery(APIPageIndex, APIPageSize, template_id, template_version);
  const {data: APIdata} = query;
  const {data: logCount} = logCountQuery(template_id, template_version);

  const data = useMemo(() => formatLog(APIdata), [APIdata]);

  const columns = [
    columnHelper.accessor('timestamp', {
      header: () => (
        <TitleTooltip
          header="Timestamp"
          mainContent={<span>Timestamp</span>}
          tooltipContent="The date and time the log message was generated by the device or host."
          maxWidth="300px"
        />
      ),
      size: 1.3,
      cell: props => <Timestamp timestamp={props.getValue()} />,
    }),
    columnHelper.accessor('formatted_template_literal', {
      header: () => (
        <TitleTooltip
          header="Log Message"
          mainContent={<span>Log Message</span>}
          tooltipContent="The content of the log message with highlighted sections identifying different tokens in the message template."
          maxWidth="300px"
        />
      ),
      size: 6,
      enableSorting: false,
      cell: props => props.getValue(),
    }),
    columnHelper.accessor(row => row.explanatory_reasons?.scarcity_details, {
      header: () => (
        <TitleTooltip
          header="Scarcity"
          mainContent={<span>Scarcity</span>}
          tooltipContent={<ScarcityTooltip />}
          maxWidth="300px"
        />
      ),
      id: ScarcityId,
      size: 1,
      cell: props => <p>{getScarcityCellText(props.getValue())}</p>,
    }),
    columnHelper.accessor('severity', {
      header: 'Severity',
      size: 1,
    }),
    columnHelper.accessor('host', {
      header: () => (
        <TitleTooltip
          header="Host"
          mainContent={<span>Host</span>}
          tooltipContent="The hostname of the device."
          maxWidth="300px"
        />
      ),
      size: 1,
    }),
    columnHelper.accessor('event_id', {
      header: 'Event ID',
      size: 1,
      enableSorting: false,
      id: EventId,
      enableHiding: true,
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(logTableSorting),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
      columnVisibility: {[EventId]: showEventId},
    };
  }, [logTableSorting, pageSizeOptions, showEventId]);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    pageCount: Math.ceil((logCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableHiding: true,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  useEffect(() => {
    setPageIndex(0);
  }, [pageSize, timeline, sorting, setPageIndex, selectedWordcloudWords]);

  useEffect(() => {
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
  }, [pageIndex, pageSize, setAPIPageIndex, setAPIPageSize]);

  useEffect(() => {
    const replacements = {[ScarcityId]: ScarcitySort};
    const newSortBy = convertSortToApiSchema(sorting, replacements);

    const explanatoryReasonsSort = newSortBy.find(
      element => element.name === ScarcitySort || element.name === SyslogSort
    );

    if (explanatoryReasonsSort) {
      newSortBy.unshift({desc: explanatoryReasonsSort.desc, name: 'observed'});
    }

    setLogTableSorting(newSortBy);
  }, [setLogTableSorting, sorting]);

  return (
    <>
      <Table onClick={e => e.stopPropagation()}>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={query}
            isEmpty={isEmpty(data)}
            emptyMessage={EmptyLogsMessage}
          >
            {getRowModel().rows.map(row => (
              <TableRow key={row.id} isHighlighted={Boolean(row.original.observed)} noSelect>
                {row.getVisibleCells().map(cell => (
                  <TableData
                    key={cell.id}
                    style={{...getCellStyles(cell)}}
                    wrapText={cell.column.columnDef.header === 'Log Message'}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
}
