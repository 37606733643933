import AutocompleteMUI from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';

const borderColor = 'rgba(97, 93, 104, 1)';
const focusedBorderColor = 'rgba(215, 153, 251, 1)';

const AutocompleteWrapper = styled.div`
  .MuiAutocomplete-root {
    background: rgba(30, 30, 30, 1);
    border-radius: 10000px;

    label {
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      color: white;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;

      &.Mui-focused,
      &.MuiFormLabel-filled {
        display: none;
      }
    }

    .MuiOutlinedInput-root {
      padding: 0;

      &.Mui-focused {
        &:hover fieldset {
          border: 1px solid ${focusedBorderColor};
        }

        fieldset {
          border: 1px solid ${focusedBorderColor};
        }

        .MuiSvgIcon-root {
          color: rgba(235, 204, 253, 1);
        }
      }

      &:hover {
        fieldset {
          border: 1px solid ${borderColor};
        }
      }

      input {
        height: 16px;
        padding: 6px 10px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;

        &:focus {
          box-shadow: none;
        }
      }

      .MuiAutocomplete-endAdornment {
        top: 50%;
        transform: translateY(-50%);

        button {
          transform: none;

          svg {
            color: white;
            font-size: 20px;
          }
        }
      }

      fieldset {
        border: 1px solid ${borderColor};
        border-radius: 10000px;
        height: 28px;
        top: 0;

        legend {
          display: none;
        }
      }
    }
  }

  .MuiAutocomplete-popper {
    .MuiPaper-root {
      background: rgba(29, 27, 30, 1);
      border: 1px solid rgba(97, 93, 104, 1);
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: white;

      .MuiAutocomplete-listbox {
        max-height: 200px;

        .MuiAutocomplete-option {
          &:hover,
          &.Mui-focusVisible,
          &.Mui-focused,
          &[aria-selected='true'] {
            background: rgba(110, 110, 110, 1);
          }
        }
      }

      .MuiAutocomplete-noOptions {
        color: inherit;
      }
    }
  }
`;

interface Props {
  label: string;
  options: string[];
  onChange: (value: string | null) => void;
  width?: number;
  noOptionsText?: string;
}

export default function Autocomplete({
  label,
  options,
  onChange,
  width = 200,
  noOptionsText = 'No options',
}: Props) {
  return (
    <AutocompleteWrapper>
      <AutocompleteMUI
        disablePortal
        options={options}
        sx={{width: width}}
        renderInput={params => <TextField {...params} label={label} />}
        popupIcon={<SearchIcon />}
        onChange={(event, value, reason) => onChange(value)}
        autoHighlight
        blurOnSelect
        noOptionsText={noOptionsText}
      />
    </AutocompleteWrapper>
  );
}
