import {useRecoilValue, useSetRecoilState} from 'recoil';
import styled from 'styled-components';
import {selectedFingerprintState} from '../../../../atoms/selectedFingerprint';
import {warningModalDataState} from '../../../../atoms/warningModalData';
import {warningModalOpenState} from '../../../../atoms/warningModalOpen';
import {useUpdateFingerprint} from '../../../../data/AutoRemediation';
import {createFingerprintRequestObject} from '../../../../utils/createFingerprintRequest';
import SwitchComponent from '../../../Katana/components/SwitchComponent/SwitchComponent';
import {getWarningModalMessage} from '../utils/getWarningModalMessage';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SwitchLabel = styled.p`
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
`;

export default function EnabledToggle() {
  const selectedFingerprint = useRecoilValue(selectedFingerprintState);
  const setWarningModalOpen = useSetRecoilState(warningModalOpenState);
  const setWarningModalData = useSetRecoilState(warningModalDataState);
  const updateFingerprint = useUpdateFingerprint();
  const request = createFingerprintRequestObject(selectedFingerprint);

  return (
    <Container>
      <SwitchComponent
        handleChange={(event, checked) => {
          setWarningModalOpen(true);
          setWarningModalData({
            modalSize: 'large',
            headerText: `Are you sure you want to ${
              checked ? 'enable' : 'disable'
            } this Fingerprint?`,
            warningMessage: getWarningModalMessage(
              checked,
              String(selectedFingerprint?.classification)
            ),
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Confirm',
            confirmButtonCallback: () => {
              request.enabled = checked ? 'Y' : 'N';
              updateFingerprint(
                request,
                checked ? 'Fingerprint enabled' : 'Fingerprint disabled',
                checked ? 'Now matching observations.' : 'No longer matching observations.'
              );
              setWarningModalOpen(false);
            },
          });
        }}
        checked={selectedFingerprint?.enabled === 'Y'}
      />
      <SwitchLabel>
        {selectedFingerprint?.enabled === 'Y'
          ? 'Recording observations matching this Fingerprint'
          : 'Ignoring observations matching this Fingerprint'}
      </SwitchLabel>
    </Container>
  );
}
