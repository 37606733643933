import ButtonBase from '@mui/material/ButtonBase';
import classnames from 'classnames';
import {MouseEvent, ReactNode} from 'react';

const baseClasses =
  'flex items-center gap-2 rounded-full px-6 h-10 text-label-large duration-200 disabled:cursor-not-allowed disabled:text-light-outline disabled:dark:text-dark-outline-variant  disabled:dark:bg-dark-on-surface/12';
const filledClasses =
  'bg-light-primary text-white disabled:bg-light-on-surface/12 hover:shadow-elevation-1';
const filledDarkClasses =
  'dark:bg-dark-primary dark:text-dark-on-primary dark:hover:bg-[#CFA0EF] dark:hover:shadow-elevation-1';
const outlinedClasses =
  'border border-solid border-light-outline bg-light-surface text-light-primary hover:bg-light-primary/08 focus:border-light-primary focus:bg-light-primary/12  active:border-light-outline active:bg-light-primary/12 disabled:bg-light-surface-container-lowest disabled:border-light-on-surface/12';
const outlinedDarkClasses =
  'dark:border-dark-outline dark:bg-dark-surface dark:text-primary-80 dark:hover:bg-[#353039] dark:focus:border-primary-80 dark:focus:bg-[#353039] dark:active:border-dark-outline dark:active:bg-[#353039] disabled:dark:bg-dark-surface-container-lowest disabled:dark:border-dark-on-surface/12';
const textClasses =
  'bg-transparent text-light-primary hover:bg-light-primary/08 focus:bg-light-primary/12 active:bg-light-primary/12';
const textDarkClasses =
  'dark:text-primary-80 dark:hover:bg-dark-primary/08 dark:focus:bg-dark-primary/12 dark:active:bg-dark-primary/12';
const elevatedClasses =
  'bg-light-surface shadow text-light-primary hover:bg-light-primary/08 hover:shadow-md focus:bg-light-primary/12 active:bg-light-primary/12 disabled:shadow-none';
const elevatedDarkClasses =
  'dark:bg-dark-surface-container dark:hover:bg-[#353039] shadow-elevation-1 dark:text-primary-80 hover:shadow-elevation-2 dark:hover:bg-dark-primary/08 dark:focus:bg-[#353039] dark:active:bg-[#353039] disabled:dark:shadow-none';

export const ButtonVariants = {
  filled: 'filled',
  outlined: 'outlined',
  text: 'text',
  elevated: 'elevated',
} as const;
export type ButtonVariantOptions = (typeof ButtonVariants)[keyof typeof ButtonVariants];
interface Props {
  variant?: ButtonVariantOptions;
  label: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  href?: string;
  id?: string;
}

export default function Button({
  label,
  variant = 'filled',
  disabled = false,
  onClick,
  startIcon,
  endIcon,
  href,
}: Props) {
  return (
    <ButtonBase
      className={classnames(baseClasses, {
        [`${filledClasses} ${filledDarkClasses}`]: variant === 'filled',
        [`${outlinedClasses} ${outlinedDarkClasses}`]: variant === 'outlined',
        [`${textClasses} ${textDarkClasses}`]: variant === 'text',
        [`${elevatedClasses} ${elevatedDarkClasses}`]: variant === 'elevated',
      })}
      disabled={disabled}
      onClick={onClick}
      {...{href}}
    >
      {startIcon && <span className="-ml-2 flex h-6 w-6 [&>*]:text-24">{startIcon}</span>}
      {label}
      {endIcon && <span className="-mr-2 flex h-6 w-6 [&>*]:text-24">{endIcon}</span>}
    </ButtonBase>
  );
}
