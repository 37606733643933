import styled from 'styled-components';

interface Props {
  value: number;
}

const Text = styled.p``;

export default function VTCRoundedCell({value}: Props) {
  return <Text>{value.toFixed(2)}</Text>;
}
