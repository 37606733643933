import styled from 'styled-components';
import {dateFull, hourMinuteSec} from '../../constants/timeFormats';
import {DateTime} from 'luxon';
import Tooltip from '../Katana/components/Tooltip/Tooltip';
import {useRecoilValue} from 'recoil';
import {selectedTimezoneState} from '../../atoms/selectedTimezone';
import {useMemo} from 'react';

const TimestampText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  timestamp: string | undefined;
  fullDate?: boolean;
}

export default function Timestamp({timestamp, fullDate = true}: Props) {
  const selectedTimezone = useRecoilValue(selectedTimezoneState);

  const timestampDateTime = useMemo(() => {
    if (!timestamp) return;
    return DateTime.fromISO(timestamp, {zone: 'UTC'}).setZone(selectedTimezone);
  }, [timestamp, selectedTimezone]);

  if (!timestampDateTime) return <p>—</p>;

  const timestampFull = timestampDateTime.toFormat(dateFull);

  return (
    <Tooltip
      tooltipContent={<p>{timestampFull}</p>}
      mainContent={
        <TimestampText>
          {fullDate ? timestampFull : timestampDateTime.toFormat(hourMinuteSec)}
        </TimestampText>
      }
    />
  );
}
