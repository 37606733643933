import {usePathInformation} from '../../../../../data/PathChange';
import {FlaggedPathTraceHop} from '../../../../../openapi-schema/schemaTS';
import {Container, ComponentTitleWrapper} from 'components/common/TableElements/TableElements';
import ComponentTitle from '../../../../common/ComponentTitle/ComponentTitle';
import ObservationDetailTable from '../../../common/ObservationDetailTable/ObservationDetailTable';
import AvgDelayCell from './AvgDelayCell';
import HopCell from './HopCell';
import PathInformationCell from './PathInformationCell';
import {createColumnHelper, Row} from '@tanstack/react-table';

const columnHelper = createColumnHelper<FlaggedPathTraceHop>();

const isFirstInstanceOfHop = (row: Row<FlaggedPathTraceHop>, data: FlaggedPathTraceHop[]) => {
  const value = row.original.hop_number;
  const index = row.index;

  if (index === 0) return true;
  if (row.original.compressed) return true;
  if (value === data[index - 1].hop_number) {
    return false;
  }
  return true;
};

const formatLocation = (row: FlaggedPathTraceHop) => {
  if (!row.city) return '';
  if (!row.iso_code) return row.city;
  return `${row.city}, ${row.iso_code}`;
};

const rowAttributes = (row: Row<FlaggedPathTraceHop>, data: FlaggedPathTraceHop[]) => {
  return {
    isDisabled: !isFirstInstanceOfHop(row, data),
  };
};

export default function PathChangeAnomalyTable() {
  const columns = [
    columnHelper.accessor('hop_number', {
      header: 'Hop',
      size: 1,
      enableSorting: false,
      cell: props => {
        const getHopDisplay = (rowData: FlaggedPathTraceHop) => {
          if (rowData.compressed)
            return `${rowData.compressed?.start} - ${rowData.compressed?.end}`;
          if (isFirstInstanceOfHop(props.row, props.table.options.data)) return rowData.hop_number;
          return '';
        };

        return <HopCell hop={getHopDisplay(props.row.original)} />;
      },
    }),
    columnHelper.accessor('hop_host_name', {
      header: 'Hostname',
      size: 3,
      enableSorting: false,
      cell: props => <PathInformationCell value={props.getValue()} />,
    }),
    columnHelper.accessor('hop_ip_address', {
      header: 'IP Address',
      size: 1,
      enableSorting: false,
      cell: props => <PathInformationCell value={props.getValue()} />,
    }),
    columnHelper.accessor('avg_avg_response_time', {
      header: 'Avg Delay',
      size: 1,
      enableSorting: false,
      cell: props => <AvgDelayCell delay={props.getValue()} />,
    }),
    columnHelper.accessor('provider', {
      header: 'Service Provider',
      size: 2,
      enableSorting: false,
      cell: props => <PathInformationCell value={props.getValue()} />,
    }),
    columnHelper.accessor('city', {
      header: 'Location',
      size: 1,
      enableSorting: false,
      cell: props => <PathInformationCell value={formatLocation(props.row.original)} />,
    }),
  ];

  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="Path Information" />
      </ComponentTitleWrapper>
      <ObservationDetailTable
        tableDataQuery={usePathInformation}
        columns={columns}
        pagination={false}
        rowAttributes={rowAttributes}
      />
    </Container>
  );
}
