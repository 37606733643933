import {Outlet} from 'react-router-dom';
import Timeline from '../../components/common/Timeline/Timeline';

export default function LayoutWithTimeline() {
  return (
    <>
      <Timeline />
      <Outlet />
    </>
  );
}
