import {atom, selector} from 'recoil';
import {trim, isEmpty} from 'lodash';
import config from '../utils/AppConfigurationService';
import {Filters} from 'components/Katana/patterns/TableSearchWithFilters/TableSearchWithFilters';

export const selectedSecurityAnomalyIdState = atom({
  key: 'selectedSecurityAnomalyId',
  default: '',
});

export const securityAffectedResourcesSearchState = atom({
  key: 'securityAffectedResourcesSearch',
  default: '',
});
export const securityTokenizedSearch = selector({
  key: 'securityTokenizedAffectedResourceSearch',
  get: ({get}) => {
    const searchString = get(securityAffectedResourcesSearchState);

    return searchString.split(',').map(trim);
  },
});

export const securityColumnPopupOpenState = atom({
  key: 'securityColumnPopupOpen',
  default: false,
});

export const securityFilterPopupOpenState = atom({
  key: 'securityFilterPopupOpen',
  default: false,
});

const {EXTREME, HIGH, MEDIUM, LOW, NONE} = config.Priority;

export const securityFilterGroupKeys = {
  Priority: 'Priority',
};

//Order of filters is not guaranteed
export const defaultSecurityEventSelectionFilters: Filters = {
  [securityFilterGroupKeys.Priority]: {
    [EXTREME]: false,
    [HIGH]: false,
    [MEDIUM]: false,
    [LOW]: false,
    [NONE]: false,
  },
};

export const securityFiltersState = atom({
  key: 'securityFilters',
  default: defaultSecurityEventSelectionFilters,
});
export const securityFilterArraysState = selector({
  key: 'securityFilterArrays',
  get: ({get}) => {
    const securityFilters = get(securityFiltersState);

    const {Priority} = securityFilterGroupKeys;

    const getPriorityArray = () => {
      const priorityArray = config.DefaultPriorities.filter(
        priority => securityFilters[Priority][priority]
      );
      return isEmpty(priorityArray) ? config.DefaultPriorities : priorityArray;
    };

    const priorityFilter = getPriorityArray();

    return {
      priorityFilter,
    };
  },
});
