import classnames from 'classnames';
import LikeDislikeVote from './components/LikeDislikeVote/LikeDislikeVote';
import CopyButton from './components/CopyButton';

export interface ChatMessageProps {
  name: string;
  timestamp?: string;
  content: string;
  showFooter?: boolean;
  side?: 'left' | 'right';
  sender?: 'Sensei' | 'user';
  onLikeClick?: () => void;
  onDislikeClick?: () => void;
}

export default function ChatMessage({
  name,
  timestamp,
  content,
  side,
  showFooter = false,
  sender = 'Sensei',
  onLikeClick,
  onDislikeClick,
}: ChatMessageProps) {
  const senseiClasses = 'border-[#434243] bg-[#1e1e1e]';
  const userClasses =
    'border-[#BD69FF] bg-gradient-to-r from-[#503665] via-[#754D95] via-44.41% to-[#49315B]';

  return (
    <div className={classnames('flex', {'justify-end': side === 'right'})}>
      <div
        className={classnames(
          'flex flex-col gap-4 rounded-lg border p-4 text-white',
          sender === 'Sensei' ? senseiClasses : userClasses
        )}
      >
        <div className="flex justify-between">
          <p className="leading-18 text-16 font-bold">{name}</p>
          {timestamp && <p className="leading-16 text-14">{timestamp}</p>}
        </div>
        <p className="leading-18 text-16">{content}</p>
        {showFooter && (
          <div className="flex items-center justify-between border-t-[1px] border-gray-400 pt-[9px] text-gray-400">
            <LikeDislikeVote onLikeClick={onLikeClick} onDislikeClick={onDislikeClick} />
            <CopyButton content={content} />
          </div>
        )}
      </div>
    </div>
  );
}
