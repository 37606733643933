import {useRef, useState, useMemo} from 'react';
import styled from 'styled-components';
import ComponentTitle from '../../common/ComponentTitle/ComponentTitle';
import {Line} from 'react-chartjs-2';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';
import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import LegendTooltip from '../../AnomalyDetail/common/PerformanceMetricsChartWrapper/components/LegendTooltip';
import {useRecoilValue} from 'recoil';
import {selectedTimezoneState} from '../../../atoms/selectedTimezone';
import {timelineState} from '../../../atoms/dashboard';
import {WhatIfChartRed} from '../../../constants/WhatIfChartColors';
import {
  coreChartOptions,
  chartLegendOptions,
  chartTooltipOptions,
  chartVerticalAxisOptions,
  chartHorizontalAxisOptions,
  chartVerticalAxisTickOptions,
  chartHorizontalTickOptions,
} from '../../../constants/chartOptions';
import {whatIfSliderValueState} from '../../../atoms/whatIfSliderValue';
import {whatIfThresholdLevelState} from '../../../atoms/whatIfThresholdLevel';
import {selectedWhatIfMetricState} from '../../../atoms/selectedWhatIfMetric';
import {selectedWhatIfStatState} from '../../../atoms/selectedWhatIfStat';
import {isEmpty} from 'lodash';
import NoResults from '../../common/NoResults/NoResults';
import {getChartXAxisTickLabels} from '../../../utils/getChartXAxisTickLabels';
import {getChartTooltipContent} from '../../../utils/getChartTooltipContent';
import {WhatIfUtilization} from '../../../constants/WhatIfMetrics';
import InterfaceDropdown from './components/InterfaceDropdown';
import {UseQueryResult} from 'react-query';
import {ChartData, ChartOptions} from 'chart.js';
import {LineAnnotationOptions, PointAnnotationOptions} from 'chartjs-plugin-annotation';
import {
  whatIfSelectedEdgeDataState,
  whatIfSelectedNodeDataState,
} from '../../../atoms/whatIfSelectedTopologyElements';

const TitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const ChartWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  min-height: 0;
  position: relative;

  canvas {
    padding: 10px;
    background-color: black;
  }
`;

const Cover = styled.div`
  background: black;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

interface ScaleSizes {
  xmin: number;
  xmax: number;
  ymax: number;
}

interface Props {
  query: UseQueryResult<any, unknown>;
  data: ChartData<'line'>;
  scaleSizes: ScaleSizes;
  singlePoints: PointAnnotationOptions[];
}

export default function WhatIfChart({query, data, scaleSizes, singlePoints}: Props) {
  const {data: APIdata, isLoading, isError, refetch, isSuccess} = query;
  const chartRef = useRef();
  const selectedTimezone = useRecoilValue(selectedTimezoneState);
  const {selectedInterval} = useRecoilValue(timelineState);
  const whatIfSliderValue = useRecoilValue(whatIfSliderValueState);
  const whatIfSelectedNodeData = useRecoilValue(whatIfSelectedNodeDataState);
  const whatIfSelectedEdgeData = useRecoilValue(whatIfSelectedEdgeDataState);
  const whatIfThresholdLevel = useRecoilValue(whatIfThresholdLevelState);
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);
  const selectedWhatIfStat = useRecoilValue(selectedWhatIfStatState);
  const [tooltip, setTooltip] = useState<{x: number; y: number} | undefined>(undefined);

  const scalingFactor = whatIfSliderValue / 100;

  const options: ChartOptions<'line'> = useMemo(() => {
    return {
      ...coreChartOptions,
      plugins: {
        legend: {
          ...chartLegendOptions,
          align: 'start' as const,
          onHover: evt => {
            if (!evt.native) return;
            document.body.style.cursor = 'pointer';
            const {x, y} = evt.native as MouseEvent;
            setTooltip({x, y});
          },
          onLeave: () => {
            document.body.style.cursor = 'auto';
            setTooltip(undefined);
          },
          onClick: function (e, legendItem, legend) {
            const index = legendItem.datasetIndex;
            const ci = legend.chart;
            if (ci.isDatasetVisible(index)) {
              ci.hide(index);
              legendItem.hidden = true;
            } else {
              ci.show(index);
              legendItem.hidden = false;
            }
          },
        },
        tooltip: {
          ...chartTooltipOptions,
          callbacks: {
            title: () => '',
            label: context => getChartTooltipContent(context, selectedTimezone),
          },
        },
        annotation: {
          annotations: [
            {
              type: 'line',
              yMin: whatIfThresholdLevel,
              yMax: whatIfThresholdLevel,
              borderColor: WhatIfChartRed,
              borderWidth: 1,
            } as LineAnnotationOptions,
          ].concat(singlePoints),
        },
      },
      scales: {
        y: {
          ...chartVerticalAxisOptions,
          ticks: {
            ...chartVerticalAxisTickOptions,
            callback: value => `${value}%`,
          },
          min: 0,
          suggestedMax: scaleSizes.ymax,
        },
        x: {
          ...chartHorizontalAxisOptions,
          min: scaleSizes.xmin,
          max: scaleSizes.xmax,
          ticks: {
            ...chartHorizontalTickOptions,
            callback: value =>
              getChartXAxisTickLabels(Number(value), selectedInterval, selectedTimezone),
          },
        },
      },
    };
  }, [selectedTimezone, whatIfThresholdLevel, scaleSizes, selectedInterval, singlePoints]);

  const ChartTitle = useMemo(() => {
    const scaleFactor = `+ ${(scalingFactor * 100).toFixed(0)}%`;
    const device =
      selectedWhatIfMetric === WhatIfUtilization ? '' : `- ${whatIfSelectedNodeData?.name}`;

    return `${scaleFactor} ${selectedWhatIfStat} ${selectedWhatIfMetric} ${device}`;
  }, [scalingFactor, selectedWhatIfMetric, whatIfSelectedNodeData, selectedWhatIfStat]);

  return (
    <>
      <TitleWrapper>
        <ComponentTitle title={ChartTitle} />
        {selectedWhatIfMetric === WhatIfUtilization && whatIfSelectedEdgeData && (
          <InterfaceDropdown selectedTopologyElement={whatIfSelectedEdgeData} />
        )}
      </TitleWrapper>
      <ChartWrapper>
        <Line options={options} data={data} ref={chartRef} />
        {isEmpty(APIdata) && isSuccess && (
          <Cover>
            <NoResults message="No data for this device during the selected time" />
          </Cover>
        )}
        {isLoading && (
          <Cover>
            <LoadingSpinner />
          </Cover>
        )}
        {isError && (
          <Cover>
            <ErrorState retry={refetch} />
          </Cover>
        )}
        {tooltip && <LegendTooltip tooltipData={tooltip} />}
      </ChartWrapper>
    </>
  );
}
