import {TitleRow, Title} from './InfoBoxStyles';
import Star from './Star';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StartWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

interface Props {
  starred: boolean;
  anomalyId: string;
}

export default function DetailViewStar({starred, anomalyId}: Props) {
  return (
    <Container style={{marginRight: '10px'}}>
      <TitleRow>
        <Title>Star</Title>
      </TitleRow>
      <StartWrapper>
        <Star initialStarred={starred} anomalyId={anomalyId} size="20px" unselectedColor="white" />
      </StartWrapper>
    </Container>
  );
}
