import {MapMarker, Router} from './imageLinks';

export const NODE_DEFAULT_BACKGROUND = 'rgba(65, 71, 74, 1)';
export const NODE_DEFAULT_BORDER_COLOR = 'rgba(141, 145, 146, 1)';
export const NODE_DEFAULT_SELECTED_BACKGROUND = 'rgba(45, 98, 235, 1)';
export const NODE_DEFAULT_SELECTED_BORDER_COLOR = 'rgba(255, 255, 255, 1)';
export const EDGE_DEFAULT_COLOR = 'rgba(58, 60, 66, 1)';
export const EDGE_DEFAULT_SELECTED_COLOR = 'rgba(255, 255, 255, 1)';
export const BORDER_WIDTH_LARGE = 6;
export const BORDER_WIDTH_SMALL = 4;
export const NODE_SIZE_LARGE = 16;
export const NODE_SIZE_SMALL = 10;
export const WAN_COMPOUND_NODE_DEFAULT_BACKGROUND = 'rgba(21, 21, 21, 1)';

export const defaultNodeAttributes = {
  bgColor: NODE_DEFAULT_BACKGROUND,
  borderColor: NODE_DEFAULT_BORDER_COLOR,
  selectedBackgroundColor: NODE_DEFAULT_SELECTED_BACKGROUND,
  selectedBorderColor: NODE_DEFAULT_SELECTED_BORDER_COLOR,
  borderWidth: BORDER_WIDTH_SMALL,
  width: NODE_SIZE_SMALL,
  height: NODE_SIZE_SMALL,
  selectedWidth: NODE_SIZE_LARGE,
  selectedHeight: NODE_SIZE_LARGE,
  icon: Router,
  label: '',
  borderStyle: 'solid',
  fontSize: 12,
  shape: 'ellipse',
  backgroundWidth: 'auto',
  backgroundHeight: 'auto',
};

export const defaultEdgeAttributes = {
  lineColor: EDGE_DEFAULT_COLOR,
  selectedLineColor: EDGE_DEFAULT_SELECTED_COLOR,
  label: '',
};

export const ASNodeAttributes = {
  bgColor: WAN_COMPOUND_NODE_DEFAULT_BACKGROUND,
  selectedBackgroundColor: WAN_COMPOUND_NODE_DEFAULT_BACKGROUND,
  borderColor: NODE_DEFAULT_BORDER_COLOR,
  selectedBorderColor: 'rgba(229, 0, 0, 1)',
  width: 55,
  height: 55,
  selectedWidth: 55,
  selectedHeight: 55,
  borderStyle: 'dashed',
  fontSize: 16,
  shape: 'rectangle',
  backgroundWidth: '70%',
  backgroundHeight: '70%',
};

export const defaultNetworkRingNodesAttributes = {
  bgColor: 'white',
  borderWidth: 0,
  width: 8,
  height: 8,
};

export const defaultNetworkRingEdgeAttributes = {
  label: '',
  lineColor: 'rgba(28, 28, 28, 1)',
  width: 1,
  zIndex: 0,
};

export const defaultPathChangeNodeAttributes = {
  label: '',
  bgColor: 'rgba(28, 28, 28, 1)',
  borderColor: 'rgba(50, 50, 50)',
  icon: MapMarker,
  backgroundImageOpacity: 0,
  width: 28,
  height: 28,
  borderStyle: 'dashed',
};

export const defaultPathChangeEdgeAttributes = {
  lineColor: 'rgba(50, 50, 50)',
  lineWidth: 2,
};
