import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 8px;
  margin: 8px 0 18px 0;
  position: relative;
`;

const DarkGreen = styled.div`
  height: 100%;
  width: 17.5%;
  background: rgba(31, 140, 112, 1);
`;

const LightGreen = styled.div`
  height: 100%;
  width: 65%;
  background: rgba(39, 175, 140, 1);
`;

const Yellow = styled.div`
  height: 100%;
  width: 17.5%;
  background: rgba(243, 153, 70, 1);
`;

interface MarkProps {
  left: string;
}

const TickMark = styled.div<MarkProps>`
  position: absolute;
  height: 4px;
  width: 1px;
  background: rgba(217, 217, 217, 1);
  top: 100%;
  left: ${props => props.left};
  transform: translateX(-50%);
`;

const Number = styled.p<MarkProps>`
  position: absolute;
  font-size: 10px;
  top: 100%;
  left: ${props => props.left};
  transform: translateX(-50%);
`;

export default function Scale() {
  return (
    <Container>
      <DarkGreen />
      <LightGreen />
      <Yellow />
      <TickMark left="17.5%" />
      <Number left="17.5%">20</Number>
      <TickMark left="70%" />
      <Number left="70%">80</Number>
    </Container>
  );
}
