import AnomalyDetailKPI from '../../common/AnomalyDetailKPI/AnomalyDetailKPI';
import LatencyChart from '../LatencyChart/LatencyChart';
import LatencyAnomalyTable from '../LatencyAnomalyTable/LatencyAnomalyTable';
import AssociatedAnomaliesLatency from '../AssociatedAnomaliesLatency/AssociatedAnomaliesLatency';
import AssociatedLogsLatency from '../AssociatedLogsLatency/AssociatedLogsLatency';
import PerformanceMetricsChartWrapper from '../../common/PerformanceMetricsChartWrapper/PerformanceMetricsChartWrapper';
import GoldenLayout from '../../../common/GoldenLayout/GoldenLayout';

const LatencyDetailDashboard = () => {
  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              type: 'column',
              width: 74,
              content: [
                {
                  type: 'row',
                  content: [
                    {
                      component: () => <LatencyAnomalyTable />,
                      width: 60,
                    },
                    {
                      component: () => (
                        <PerformanceMetricsChartWrapper>
                          <LatencyChart />
                        </PerformanceMetricsChartWrapper>
                      ),
                      width: 40,
                    },
                  ],
                },
                {
                  component: () => <AssociatedLogsLatency />,
                  height: 50,
                },
              ],
            },
            {
              type: 'column',
              width: 26,
              content: [
                {
                  component: () => <AnomalyDetailKPI />,
                  height: 30,
                },
                {
                  component: () => <AssociatedAnomaliesLatency />,
                  height: 70,
                },
              ],
            },
          ],
        },
      ]}
      padding="7px 10px 10px 10px"
    />
  );
};

export default LatencyDetailDashboard;
