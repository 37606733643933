import Switch from '@mui/material/Switch';
import {styled} from '@mui/material/styles';

const AntSwitch = styled(Switch)(() => ({
  width: 24,
  height: 14,
  padding: 0,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(10px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgba(120, 83, 203, 1)',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'white',
      },
    },
    '&.Mui-disabled+.MuiSwitch-track': {
      opacity: 1,
    },
  },
  '& .MuiSwitch-thumb': {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'white',
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
}));

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked: boolean;
  disabled?: boolean;
}

export default function SwitchComponent({handleChange, checked, disabled}: Props) {
  return <AntSwitch onChange={handleChange} disabled={disabled} checked={checked} />;
}
