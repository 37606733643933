import SecurityEventsTable from './components/SecurityEventsTable';
import TableTitleRow from './components/TableTitleRow';
import {Container} from 'components/common/TableElements/TableElements';

export default function SecurityEventsPane() {
  return (
    <Container>
      <TableTitleRow />
      <SecurityEventsTable />
    </Container>
  );
}
