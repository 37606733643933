import {Sensei} from 'constants/imageLinks';

interface Props {
  message?: string;
}

export default function SenseiInProgress({message}: Props) {
  return (
    <div className="flex items-start gap-5">
      <img className="w-[36px] min-w-[36px]" src={Sensei} alt="Sensei icon" />
      <p className="to white leading-20 rounded-lg border border-[#3A3542] bg-gradient-to-b from-[#222429] p-4 italic">
        {message ? `... ${message} ...` : '...'}
      </p>
    </div>
  );
}
