import {associatedAnomaliesSortingState} from 'atoms/associatedAnomaliesSorting';
import {timelineState} from 'atoms/dashboard';
import {logTableSortingState} from 'atoms/logTableSorting';
import {selectedAnomalyIdState} from 'atoms/selectedAnomaly';
import useDebouncedInput from 'helpers/hooks/useDebouncedInput';
import {useQuery} from 'react-query';
import {useRecoilValue} from 'recoil';
import {
  getDevicesVTC,
  getLogTemplatesVTC,
  getLogTemplatesVTCCount,
  getRelatedAnomaliesVTC,
  getRelatedAnomaliesVTCCount,
  getVTCAnomalydata,
  getVTCPairsData,
  getVTCPairsDataCount,
  getVTCPingData,
  getVTCServicePerformanceChartData,
} from 'utils/PythonApiUtils';
import config from '../utils/AppConfigurationService';
import {LogListQuery, SortedPagedQuery} from './types';

export const useSelectedAnomalyVTCPairsData: SortedPagedQuery = (
  pageIndex = 0,
  limit = config.apiResultLimit,
  sortFields = []
) => {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'selectedAnomalyVTCPairsData',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      sortFields,
    ],
    () =>
      getVTCPairsData({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: sortFields,
      })
  );
};

export function useSelectedAnomalyVTCPairsDataCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['selectedAnomalyVTCPairsDataCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getVTCPairsDataCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useSelectedAnomalyRelatedAnomaliesVTC(
  pageIndex = 0,
  limit = config.apiResultLimit
) {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const associatedAnomaliesSorting = useRecoilValue(associatedAnomaliesSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'relatedAnomaliesVTC',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      associatedAnomaliesSorting,
    ],
    () =>
      getRelatedAnomaliesVTC({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: associatedAnomaliesSorting,
      })
  );
}
export function useSelectedAnomalyRelatedAnomaliesVTCCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['relatedAnomaliesVTCCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getRelatedAnomaliesVTCCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useVTCServicePerformanceChartData() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['VTCServicePerformanceChartData', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getVTCServicePerformanceChartData({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        total_bins: 100,
      })
  );
}

export const useLogTemplatesVTC: LogListQuery = (pageIndex = 0, limit = config.apiResultLimit) => {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const sortFields = useRecoilValue(logTableSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['logTemplatesVTC', selectedAnomalyId, debouncedStart, debouncedEnd, offset, limit, sortFields],
    () =>
      getLogTemplatesVTC({
        anomaly_id: selectedAnomalyId,
        offset: offset,
        limit: limit,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        sort_fields: sortFields,
      })
  );
};

export function useLogTemplatesVTCCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['logTemplatesVTCCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getLogTemplatesVTCCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useVTCAnomalyData() {
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);

  return useQuery(['VTCAnomalyData', selectedAnomalyId], () =>
    getVTCAnomalydata({
      anomaly_id: selectedAnomalyId,
    })
  );
}

export function useVTCPingData() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['VTCPingData', selectedAnomalyId, debouncedStart, debouncedEnd], () =>
    getVTCPingData({
      anomaly_id: selectedAnomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
      total_bins: 100,
    })
  );
}

export function useDevicesVTC() {
  const selectedAnomalyID = useRecoilValue(selectedAnomalyIdState);

  return useQuery(['devicesVTC', selectedAnomalyID], () =>
    getDevicesVTC({
      anomaly_id: selectedAnomalyID,
    })
  );
}
