import IconComponent, {IconProps} from '../IconComponent';

export default function HighPriorityIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="HighPriorityIcon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.45235 10.749C1.06639 11.4156 1.54745 12.25 2.31778 12.25H11.6822C12.4526 12.25 12.9336 11.4156 12.5476 10.749L7.86542 2.6615C7.48026 1.99622 6.51974 1.99622 6.13457 2.6615L1.45235 10.749ZM6.3 4.90001H7.7V8.40001H6.3V4.90001ZM7.7 9.10001H6.3V10.5H7.7V9.10001Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
