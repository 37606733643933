import {EventObjectNode} from 'cytoscape';
import {uniq} from 'lodash';
import {useDevicesBGP} from '../../../data/Topology';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';
import {Container, Header, Text, HiddenElement} from './TopologyTooltipElements';

interface Props {
  event: EventObjectNode;
  AS: string;
}

export default function LocalASNodeTooltip({event, AS}: Props) {
  const {data} = useDevicesBGP();
  const addresses = data?.flatMap(peer => (String(peer.local_as) === AS ? [peer.ip] : []));
  const uniqAddress = uniq(addresses);

  return (
    <Tooltip
      tooltipContent={
        <Container>
          <Header>AS {AS} ADDRESS LIST</Header>
          {uniqAddress?.map(address => {
            return <Text key={address}>{address}</Text>;
          })}
        </Container>
      }
      mainContent={
        <HiddenElement x={event.originalEvent.offsetX} y={event.originalEvent.offsetY} />
      }
      onCanvas={true}
      maxWidth={'none'}
    />
  );
}
