import useURLSynchronizedTimeline from 'helpers/hooks/useURLSynchronizedTimeline';
import GoldenLayout from '../common/GoldenLayout/GoldenLayout';
import {LogDetail} from './LogDetail/LogDetail';
import FlaggedUsersPane from './FlaggedUsersTable/FlaggedUsersPane';
import SecurityEventsPane from './SecurityEventsTable/SecurityEventsPane';
import {useEffect} from 'react';
import {useResetRecoilState} from 'recoil';
import {selectedSecurityAnomalyIdState} from '../../atoms/securityState';
import {withTracingErrorBoundary} from '../../tracing/errorReporting';

function Security() {
  useURLSynchronizedTimeline();
  const resetSelectedSecurityAnomaly = useResetRecoilState(selectedSecurityAnomalyIdState);

  useEffect(() => {
    return () => {
      resetSelectedSecurityAnomaly();
    };
  });

  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              type: 'column',
              content: [
                {
                  component: () => <SecurityEventsPane />,
                },
              ],
            },
            {
              type: 'column',
              content: [
                {
                  component: () => <LogDetail />,
                },
                {
                  component: () => <FlaggedUsersPane />,
                },
              ],
            },
          ],
        },
      ]}
    />
  );
}
export default withTracingErrorBoundary(Security);
