import AnomalySelectionKPI from '../AnomalySelectionKPI/AnomalySelectionKPI';
import GoldenLayout from '../../common/GoldenLayout/GoldenLayout';
import ExplorePanel from '../ExplorePanel/ExplorePanel';
import ReviewPanel from '../ReviewPanel/ReviewPanel';

const SelectionDashboard = () => {
  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              component: () => <ReviewPanel />,
              type: 'column',
              width: 55,
            },
            {
              type: 'column',
              width: 45,
              content: [
                {
                  component: () => <AnomalySelectionKPI />,
                  height: 10,
                },
                {
                  component: () => <ExplorePanel />,
                  height: 90,
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default SelectionDashboard;
