export const Flag = '/Images/flag.svg';
export const MapMarker = '/Images/map-marker.svg';
export const PhoneIncoming = '/Images/phone-incoming.svg';
export const PhoneOutgoing = '/Images/phone-outgoing.svg';
export const Router = '/Images/router.svg';
export const Sensei = '/Images/Sensei.svg';
export const SwitchRouter = '/Images/switch-router.svg';
export const Switch = '/Images/switch.svg';
export const VtcTableArrow = '/Images/VtcTableArrow.svg';
export const WanTopologyCloud = '/Images/WanTopologyCloud.svg';
export const WanTopologyCloudRed = '/Images/WanTopologyCloudRed.svg';
