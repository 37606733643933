import {useQuery} from 'react-query';

const mockUsers = [
  {
    name: 'John Doe',
    role: 'Admin',
    email: 'john.doe@example.com',
  },
  {
    name: 'Jane Smith',
    role: 'User',
    email: 'jane.smith@example.com',
  },
  {
    name: 'Cave Johnson',
    role: 'Admin',
    email: 'cave.johnson@example.com',
  },
  {
    name: 'Alice Brown',
    role: 'User',
    email: 'alice.brown@example.com',
  },
  {
    name: 'Charlie Wilson',
    role: 'User',
    email: 'charlie.wilson@example.com',
  },
];
export function useUserList() {
  return useQuery(['userlist'], () => mockUsers, {
    keepPreviousData: true,
  });
}
export function useAvailableRoles() {
  return useQuery(['availableRoles'], () => ['Admin', 'User'], {
    keepPreviousData: true,
  });
}
