import {useMemo, useEffect, useRef, useState} from 'react';
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import TableSortArrow from '../../../../common/TableSortArrow/TableSortArrow';
import PriorityData from '../../../../AnomalySelection/DetailedAnomalyTable/components/PriorityData';
import Pagination from '../../../../common/Pagination/Pagination';
import {timelineState} from '../../../../../atoms/dashboard';
import {isEmpty} from 'lodash';
import {
  associatedAnomaliesSortingDefaultState,
  associatedAnomaliesSortingState,
} from '../../../../../atoms/associatedAnomaliesSorting';
import QueryLifecycleWrapper from '../../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import {convertSortToApiSchema} from '../../../../../utils/convertSortToApiSchema';
import {convertSortToReactTableSchema} from '../../../../../utils/convertSortToReactTableSchema';
import useNavigateKeepParams from '../../../../../helpers/hooks/useNavigateKeepParams';
import {EmptyObservationsMessage} from '../../../../../constants/queryLifecycleMessages';
import TypeData from '../../../../AnomalySelection/DetailedAnomalyTable/components/TypeData';
import Timestamp from '../../../../common/Timestamp';
import {UseQueryResult} from 'react-query';
import {HotlAnomaly, IntValue} from '../../../../../openapi-schema/schemaTS';
import {scrollToTop} from '../../../../../utils/scrollToTop';
import {
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TableData,
  TableRow,
} from 'components/common/TableElements/TableElements';
import {pageSizeOptionsMedium} from 'constants/PageSizeOptions';
import {
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const pageSizeOptions = pageSizeOptionsMedium;

const columnHelper = createColumnHelper<HotlAnomaly>();

interface Props {
  anomalyListQuery: (pageIndex?: number, limit?: number) => UseQueryResult<HotlAnomaly[], unknown>;
  anomalyCountQuery: () => UseQueryResult<IntValue, unknown>;
}

export default function AssociatedAnomaliesTable({anomalyListQuery, anomalyCountQuery}: Props) {
  const [APIPageSize, setAPIPageSize] = useState(pageSizeOptions[0]);
  const [APIPageIndex, setAPIPageIndex] = useState(0);
  const query = anomalyListQuery(APIPageIndex, APIPageSize);
  const {data: APIdata} = query;
  const {data: anomaliesCount} = anomalyCountQuery();
  const timeline = useRecoilValue(timelineState);
  const setAssociatedAnomaliesSorting = useSetRecoilState(associatedAnomaliesSortingState);
  const resetAssociatedanomaliesSorting = useResetRecoilState(associatedAnomaliesSortingState);
  const navigateKeepParams = useNavigateKeepParams();
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const data = useMemo(() => (APIdata ? APIdata : []), [APIdata]);

  const columns = [
    columnHelper.accessor('min_timestamp', {
      header: 'Timestamp',
      size: 1.5,
      cell: props => <Timestamp timestamp={props.getValue()} />,
    }),
    columnHelper.accessor('priority_classification', {
      header: 'Priority',
      size: 1,
      cell: props => <PriorityData priority={props.getValue()} />,
    }),
    columnHelper.accessor('anomaly_type', {
      header: 'Type',
      size: 1,
      cell: props => <TypeData type={props.getValue()} />,
    }),
  ];

  const initialState = useMemo(() => {
    return {
      sorting: convertSortToReactTableSchema(associatedAnomaliesSortingDefaultState),
      pagination: {
        pageSize: pageSizeOptions[0],
        pageIndex: 0,
      },
    };
  }, []);

  const {
    getHeaderGroups,
    getRowModel,
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getState,
    resetRowSelection,
  } = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState,
    enableSorting: true,
    enableSortingRemoval: false,
    manualSorting: true,
    enableRowSelection: true,
    pageCount: Math.ceil((anomaliesCount?.value || 0) / APIPageSize) || 1,
    manualPagination: true,
    enableMultiRowSelection: false,
  });

  const {
    pagination: {pageIndex, pageSize},
    sorting,
  } = getState();

  useEffect(() => {
    const replacements = {};
    const newSortBy = convertSortToApiSchema(sorting, replacements);
    setAssociatedAnomaliesSorting(newSortBy);
  }, [setAssociatedAnomaliesSorting, sorting]);

  useEffect(() => {
    setPageIndex(0);
    resetRowSelection(true);
  }, [pageSize, timeline, sorting, setPageIndex, resetRowSelection]);

  useEffect(() => {
    setAPIPageIndex(pageIndex);
    setAPIPageSize(pageSize);
    scrollToTop(tableBodyRef);
    resetRowSelection(true);
  }, [pageIndex, pageSize, setAPIPageIndex, setAPIPageSize, resetRowSelection]);

  useEffect(() => {
    return () => resetAssociatedanomaliesSorting();
  }, [resetAssociatedanomaliesSorting]);

  const handleToggle = (row: Row<HotlAnomaly>) => {
    row.toggleSelected(!row.getIsSelected());
  };

  return (
    <>
      <Table>
        <HeaderWrapper>
          {getHeaderGroups().map(headerGroup => (
            <HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Header
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{...getHeaderStyles(header)}}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <TableSortArrow column={header} />
                </Header>
              ))}
            </HeaderRow>
          ))}
        </HeaderWrapper>
        <TableBody ref={tableBodyRef}>
          <QueryLifecycleWrapper
            query={query}
            isEmpty={isEmpty(data)}
            emptyMessage={EmptyObservationsMessage}
          >
            {getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                onDoubleClick={() =>
                  navigateKeepParams({
                    pathname: `/anomaly/${row.original.anomaly_id}`,
                    clearStartEnd: true,
                  })
                }
                onClick={() => handleToggle(row)}
                isSelected={row.getIsSelected()}
                isRead={Boolean(row.original.feedback?.read)}
              >
                {row.getVisibleCells().map(cell => (
                  <TableData key={cell.id} style={{...getCellStyles(cell)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </QueryLifecycleWrapper>
        </TableBody>
      </Table>
      <Pagination
        paginationMethods={{
          canPreviousPage: getCanPreviousPage(),
          canNextPage: getCanNextPage(),
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          gotoPage: setPageIndex,
          pageCount: getPageCount(),
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
}
