import {
  FiberChannelIcon,
  InterfaceIcon,
  LatencyIcon,
  LogIcon,
  NeighborDropIcon,
  PathChangeIcon,
  UnknownIcon,
  VtcIcon,
} from 'components/Katana/Icons';
import {AnomalyTypeBucket} from 'openapi-schema/schemaTS';
import config from 'utils/AppConfigurationService';

interface Props {
  type: AnomalyTypeBucket;
  className?: string;
}

export default function ObservationTypeIcon({type, className}: Props) {
  const {
    LOG,
    EXCHANGE_LOG,
    INTERFACE,
    LATENCY,
    NEIGHBOR_DROP_BGP,
    NEIGHBOR_DROP_ISIS,
    PATH_CHANGE,
    VIDEO_VOIP,
    FIBER_CHANNEL,
  } = config.AnomalyTypes;

  if (type === LOG || type === EXCHANGE_LOG) return <LogIcon className={className} />;
  else if (type === INTERFACE) return <InterfaceIcon className={className} />;
  else if (type === LATENCY) return <LatencyIcon className={className} />;
  else if (type === NEIGHBOR_DROP_BGP || type === NEIGHBOR_DROP_ISIS) {
    return <NeighborDropIcon className={className} />;
  } else if (type === PATH_CHANGE) return <PathChangeIcon className={className} />;
  else if (type === VIDEO_VOIP) return <VtcIcon className={className} />;
  else if (type === FIBER_CHANNEL) return <FiberChannelIcon className={className} />;
  return <UnknownIcon className={className} />;
}
