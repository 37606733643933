import styled from 'styled-components';
import {Portal} from 'react-portal';
import {PopperBody} from '../../../../common/PopperBody/PopperBody';

interface Props {
  tooltipData: {x: number; y: number};
}

const Container = styled.div<Props>`
  position: absolute;
  pointer-events: none;
  left: ${props => `${props.tooltipData.x}px`};
  top: ${props => `${props.tooltipData.y}px`};
  z-index: 1;
`;

export default function LegendTooltip({tooltipData}: Props) {
  return (
    <Portal>
      <Container tooltipData={tooltipData}>
        <PopperBody>Click to toggle</PopperBody>
      </Container>
    </Portal>
  );
}
