import {ChartData} from 'chart.js';
import {max} from 'lodash';
import {useMemo} from 'react';
import {UseQueryResult} from 'react-query';
import {useRecoilValue} from 'recoil';
import {timelineState} from '../../../../atoms/dashboard';
import {whatIfSliderValueState} from '../../../../atoms/whatIfSliderValue';
import {whatIfThresholdLevelState} from '../../../../atoms/whatIfThresholdLevel';
import {
  WhatIfChartBlue,
  WhatIfChartPurple,
  WhatIfChartRed,
  WhatIfChartTurquoise,
  WhatIfChartYellow,
} from '../../../../constants/WhatIfChartColors';
import WhatIfChart from '../WhatIfChart';
import {FormattedData} from './GetWhatIfChartData';

interface Props {
  query: UseQueryResult<any, unknown>;
  actualData: {
    utilizationIn: FormattedData[];
    utilizationOut: FormattedData[];
  };
}
export default function ChartSetupUtilization({query, actualData}: Props) {
  const whatIfSliderValue = useRecoilValue(whatIfSliderValueState);
  const whatIfThresholdLevel = useRecoilValue(whatIfThresholdLevelState);
  const {selectedInterval} = useRecoilValue(timelineState);

  const scalingFactor = whatIfSliderValue / 100;

  const whatIfData = useMemo(() => {
    const scaledUtilizationIn = actualData.utilizationIn.map(element => {
      return {
        x: element?.x,
        y: element?.y * (scalingFactor + 1),
      };
    });
    const scaledUtilizationOut = actualData.utilizationOut.map(element => {
      return {
        x: element?.x,
        y: element?.y * (scalingFactor + 1),
      };
    });
    return {
      utilizationIn: scaledUtilizationIn,
      utilizationOut: scaledUtilizationOut,
    };
  }, [actualData, scalingFactor]);

  const data: ChartData<'line'> = useMemo(() => {
    return {
      datasets: [
        {
          label: 'UTILIZATION IN',
          data: actualData.utilizationIn,
          borderColor: WhatIfChartBlue,
          backgroundColor: WhatIfChartBlue,
          yAxisID: 'y',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: WhatIfChartBlue,
          pointHoverBorderColor: 'white',
          borderJoinStyle: 'bevel',
        },
        {
          label: 'WHAT-IF IN',
          data: whatIfData.utilizationIn,
          borderColor: WhatIfChartYellow,
          backgroundColor: WhatIfChartYellow,
          yAxisID: 'y',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: WhatIfChartYellow,
          pointHoverBorderColor: 'white',
          borderJoinStyle: 'bevel',
        },
        {
          label: 'UTILIZATION OUT',
          data: actualData.utilizationOut,
          borderColor: WhatIfChartPurple,
          backgroundColor: WhatIfChartPurple,
          yAxisID: 'y',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: WhatIfChartPurple,
          pointHoverBorderColor: 'white',
          borderJoinStyle: 'bevel',
        },
        {
          label: 'WHAT-IF OUT',
          data: whatIfData.utilizationOut,
          borderColor: WhatIfChartTurquoise,
          backgroundColor: WhatIfChartTurquoise,
          yAxisID: 'y',
          pointRadius: 0,
          hitRadius: 6,
          hoverRadius: 10,
          hoverBorderWidth: 2,
          pointHoverBackgroundColor: WhatIfChartTurquoise,
          pointHoverBorderColor: 'white',
          borderJoinStyle: 'bevel',
        },
      ],
    };
  }, [actualData, whatIfData]);

  const scaleSizes = {
    xmin:
      actualData.utilizationIn.length === 1
        ? selectedInterval.start.toMillis()
        : actualData.utilizationIn[0]?.x,
    xmax:
      actualData.utilizationIn.length === 1
        ? selectedInterval.end.toMillis()
        : actualData.utilizationIn[actualData.utilizationIn.length - 1]?.x,
    ymax: Number(
      (
        (max([
          max(whatIfData.utilizationIn.map(d => d?.y)),
          max(whatIfData.utilizationOut.map(d => d?.y)),
          whatIfThresholdLevel,
        ]) as number) * 1.1
      ).toFixed()
    ),
  };

  const singlePoints =
    actualData.utilizationIn.length === 1
      ? [
          {
            type: 'point',
            xValue: whatIfData.utilizationIn[0]?.x,
            yValue: whatIfData.utilizationIn[0]?.y,
            backgroundColor:
              whatIfData.utilizationIn[0].y >= whatIfThresholdLevel
                ? WhatIfChartRed
                : WhatIfChartYellow,
            radius: 4,
            borderWidth: 0,
          },
          {
            type: 'point',
            xValue: actualData.utilizationIn[0]?.x,
            yValue: actualData.utilizationIn[0]?.y,
            backgroundColor:
              actualData.utilizationIn[0]?.y >= whatIfThresholdLevel
                ? WhatIfChartRed
                : WhatIfChartBlue,
            radius: 4,
            borderWidth: 0,
          },
          {
            type: 'point',
            xValue: whatIfData.utilizationOut[0]?.x,
            yValue: whatIfData.utilizationOut[0]?.y,
            backgroundColor:
              whatIfData.utilizationOut[0]?.y >= whatIfThresholdLevel
                ? WhatIfChartRed
                : WhatIfChartPurple,
            radius: 4,
            borderWidth: 0,
          },
          {
            type: 'point',
            xValue: actualData.utilizationOut[0]?.x,
            yValue: actualData.utilizationOut[0]?.y,
            backgroundColor:
              actualData.utilizationOut[0]?.y >= whatIfThresholdLevel
                ? WhatIfChartRed
                : WhatIfChartTurquoise,
            radius: 4,
            borderWidth: 0,
          },
        ]
      : [];

  return (
    <WhatIfChart query={query} data={data} scaleSizes={scaleSizes} singlePoints={singlePoints} />
  );
}
