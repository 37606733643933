import IconComponent, {IconProps} from './IconComponent';

export default function LinkIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 18}} className={className}>
      <svg
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="LinkIcon"
      >
        <path
          d="M10.6012 7.39883C10.3001 7.09775 9.97165 6.87879 9.61584 6.68719C9.47898 6.63245 9.34213 6.65982 9.23265 6.76931L9.12317 6.87879C8.87683 7.09775 8.73998 7.39883 8.71261 7.6999C8.68524 7.83675 8.76735 7.97361 8.87683 8.05572C9.06843 8.13783 9.3695 8.32942 9.50635 8.49365C10.4096 9.39687 10.4096 10.8475 9.50635 11.7507L7.45357 13.8035C6.55034 14.7067 5.09971 14.7067 4.19648 13.8035C3.29326 12.9003 3.29326 11.4497 4.19648 10.5464L5.45552 9.28739C5.53763 9.20528 5.565 9.0958 5.53763 8.98631C5.48289 8.71261 5.42815 8.24731 5.40078 7.94624C5.40078 7.67253 5.04497 7.53568 4.85337 7.72727C4.52493 8.05572 4.00489 8.57576 3.12903 9.45161C1.62366 10.957 1.62366 13.393 3.12903 14.871C4.60704 16.3763 7.04301 16.3763 8.54839 14.871C10.7928 12.6266 10.6833 12.7361 10.8475 12.5171C12.0792 11.0391 11.9971 8.79472 10.6012 7.39883ZM14.871 3.12903C13.393 1.62366 10.957 1.62366 9.45161 3.12903C7.20723 5.37341 7.31672 5.26393 7.15249 5.48289C5.92082 6.9609 6.00293 9.20528 7.39883 10.6012C7.6999 10.9022 8.02835 11.1212 8.38416 11.3128C8.52102 11.3675 8.65787 11.3402 8.76735 11.2307L8.87683 11.1212C9.12317 10.9022 9.26002 10.6012 9.28739 10.3001C9.31476 10.1632 9.23265 10.0264 9.12317 9.94428C8.93157 9.86217 8.6305 9.67058 8.49365 9.50635C7.59042 8.60313 7.59042 7.15249 8.49365 6.24927L10.5464 4.19648C11.4497 3.29326 12.9003 3.29326 13.8035 4.19648C14.7067 5.09971 14.7067 6.55034 13.8035 7.45357L12.5445 8.71261C12.4624 8.79472 12.435 8.9042 12.4624 9.01369C12.5171 9.28739 12.5718 9.75269 12.5992 10.0538C12.5992 10.3275 12.955 10.4643 13.1466 10.2727C13.4751 9.94428 13.9951 9.42424 14.871 8.54839C16.3763 7.04301 16.3763 4.60704 14.871 3.12903Z"
          fill="white"
        />
      </svg>
    </IconComponent>
  );
}
