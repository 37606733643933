import IconComponent, {IconProps} from '../IconComponent';

export default function ExtremePriorityColorIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 20}} className={className}>
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="ExtremePriorityColorIcon"
      >
        <path
          d="M1.83301 13.382L3.23301 9.9987L1.83301 6.61536L5.21634 5.21536L6.61634 1.83203L9.99967 3.23203L13.383 1.83203L14.783 5.21536L18.1663 6.61536L16.7663 9.9987L18.1663 13.382L14.783 14.782L13.383 18.1654L9.99967 16.7654L6.61634 18.1654L5.21634 14.782L1.83301 13.382Z"
          fill="#E91895"
        />
        <rect x="9" y="6" width="2" height="5" fill="white" />
        <rect x="9" y="12" width="2" height="2" fill="white" />
      </svg>
    </IconComponent>
  );
}
