import {DateTime, Duration} from 'luxon';
import {atom} from 'recoil';
import config from '../utils/AppConfigurationService';

export const topologyWindowState = atom({
  key: 'topologyWindow',
  default: {
    start: undefined as string | undefined,
    end: undefined as string | undefined,
  },
  effects: [
    ({setSelf}) => {
      const now = DateTime.now();
      const nextHour = now.plus({hour: 1}).startOf('hour');
      const millisecondsUntilNextHour = nextHour.diff(now).milliseconds;

      const updateWindow = () => {
        const now = DateTime.now();
        const start = now.minus(config.topologyDuration);

        setSelf({
          start: start.setZone('UTC').toISO(),
          end: now.setZone('UTC').toISO(),
        });
      };

      let intervalID;
      updateWindow();
      setTimeout(() => {
        updateWindow();
        intervalID = setInterval(() => {
          updateWindow();
        }, Duration.fromObject({hour: 1}).toMillis());
      }, millisecondsUntilNextHour);
      return () => {
        clearInterval(intervalID);
      };
    },
  ],
});
