import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  canvas {
    left: 0;
    top: 0;
  }

  > div {
    z-index: 0;
  }
`;
