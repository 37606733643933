import {
  ReadStatusIcon,
  StarredStatusIcon,
  UnknownIcon,
  UnreadStatusIcon,
} from 'components/Katana/Icons';
import config from 'utils/AppConfigurationService';

interface Props {
  status: string;
  className?: string;
}

export default function ObservationStatusIcon({status, className}: Props) {
  const {UNREAD, READ, STARRED} = config.Status;

  if (status === UNREAD) return <UnreadStatusIcon className={className} />;
  if (status === READ) return <ReadStatusIcon className={className} />;
  if (status === STARRED) return <StarredStatusIcon className={className} />;
  return <UnknownIcon className={className} />;
}
