import {useMemo} from 'react';
import VariableAssignment from './VariableAssignment';
import {AnomalyTemplate, HydratedLogElement} from '../../../../../openapi-schema/schemaTS';
import {SystemVariableData} from './SystemVariable';
import {useRecoilValue} from 'recoil';
import {usedAliasesState} from '../../../../../atoms/usedAliases';
import AccordionToggle from '../../../../Katana/components/AccordionToggle/AccordionToggle';

interface Props {
  variablesArray?: HydratedLogElement[] | SystemVariableData[] | undefined;
  template?: AnomalyTemplate;
}

export default function ShowAssignmentToggle({variablesArray, template}: Props) {
  const usedAliases = useRecoilValue(usedAliasesState);

  const variableCount = useMemo(() => {
    return Object.values(usedAliases).filter(
      alias =>
        alias.template_id === template?.template_id &&
        alias.template_version === template?.template_version
    ).length;
  }, [usedAliases, template]);

  return (
    <AccordionToggle
      toggleText={
        variableCount ? `Attachments in script (${variableCount})` : 'Attachments in script'
      }
      openOnRender={true}
    >
      <VariableAssignment variablesArray={variablesArray} template={template} />
    </AccordionToggle>
  );
}
