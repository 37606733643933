/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */
import {fingerprintRequestHandler} from '../api/axiosInstances';
export type GetFingerprintsForDateRangeParams = {
  /**
   * The start date and time of the date range to query against. The date/time format is YYYY-MM-DDTHH:MM:SS.sssZ
   */
  startDateTime?: string;
  /**
   * The end date and time of the date range to query against. The date/time format is YYYY-MM-DDTHH:MM:SS.sssZ
   */
  endDateTime?: string;
  /**
   * A boolean value indicating if scripts associated to fingerprints should be included. true = include scripts.
   */
  includeScripts?: boolean;
  /**
   * The maximum number of rows returned. When zero, all records for the date range are returned.
   */
  size?: number;
  /**
   * The paging row offset to limit rows from.
   */
  offset?: number;
};

export interface FingerprintAutomationCases {
  /** A list encapsulating automation case data for all fingerprints in the system. */
  automation_cases?: FingerprintCaseData[];
}

/**
 * The result state for the script output.
 */
export type AnsibleScriptOutputResponseExecutionResult =
  typeof AnsibleScriptOutputResponseExecutionResult[keyof typeof AnsibleScriptOutputResponseExecutionResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnsibleScriptOutputResponseExecutionResult = {
  error: 'error',
  success: 'success',
} as const;

/**
 * Response object for returning output for an Ansible script that was executed.
 */
export interface AnsibleScriptOutputResponse {
  /** The fingerprint match ID the script output is associated to. */
  fingerprint_match_id?: string;
  /** The unique identifier for the script output record in the database.. */
  output_id?: string;
  /** The unique ID for the Ansible script that the output is associated to. */
  ansible_script_id?: string;
  /** The version of the Ansible script. */
  ansible_script_version?: number;
  /** The result state for the script output. */
  execution_result?: AnsibleScriptOutputResponseExecutionResult;
  /** The output from executing the script. If there was a runtime exception and where no output exists, the exception message is returned in this field instead. */
  script_output?: string;
  /** The date/time the output was persisted in the database. */
  time_stamp?: string;
}

/**
 * The status of the execution of the associated script.
 */
export type FingerprintObservationStatus =
  typeof FingerprintObservationStatus[keyof typeof FingerprintObservationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FingerprintObservationStatus = {
  NA: 'NA',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  SCHEDULED: 'SCHEDULED',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
} as const;

/**
 * The list of detail records for observations matching a fingerprint.
 */
export interface FingerprintObservation {
  /** The observation priority classification. */
  priority?: string;
  /** The status of the execution of the associated script. */
  status?: FingerprintObservationStatus;
  /** The ID of the observation related to the fingerprint match. */
  observation_id?: string;
  /** The ID of the change request that is visible in the SN UI. This is the ID/Name to display in the UI. */
  change_form_id?: string;
  /** The actual SN internal ID that references the change request ticket in the SN database. */
  sys_id?: string;
  /** The fingerprint match ID for the fingerprint match record in the database. Provided in case it is needed for retrieving fingerprint match details down the line. */
  match_id?: string;
  /** The URL for the change request ticket opened for the fingerprint match to the observation. */
  change_form_url?: string;
  /** The record timestamp for the observation. */
  observation_timestamp?: string;
  /** The list of affected resource host names or IP addresses. */
  affected_resources?: string[];
}

export interface FingerprintObservations {
  /** The total number of observations that match search criteria. */
  total_number_of_observations?: number;
  /** The list of detail records for observations matching a fingerprint. */
  observation_details?: FingerprintObservation[];
}

/**
 * Sortable field values for fingerprint observations.
 */
export type SortFieldName = typeof SortFieldName[keyof typeof SortFieldName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortFieldName = {
  timestamp: 'timestamp',
  affected_resources: 'affected_resources',
  number: 'number',
  priority: 'priority',
  status: 'status',
} as const;

/**
 * Object containing search field name and sorting order.
 */
export interface SortField {
  /** Sortable field values for fingerprint observations. */
  name?: SortFieldName;
  /** Sorting order is DESC. */
  desc?: boolean;
}

/**
 * Structured object for requesting observation details for a specified fingerprint.
 */
export interface FingerprintObservationsRequest {
  /** The page offset to paginate data from. */
  offset?: number;
  /** The page size limit used for paginating data that is retrieved. */
  limit?: number;
  /** The ID for the fingerprint to retrieve observation detail data for. */
  fingerprint_id?: string;
  /** The start of the date range to query for. */
  start_date?: string;
  /** The end of the date range to query for. */
  end_date?: string;
  /** A list of fields, determining the sort specification for the query. The order of the fields in the list determines the sort order. Defaults to timestamp DESC if not provided. */
  sort_fields?: SortField[];
}

/**
 * Response object for returning a list of Fingerprint records.
 */
export interface Fingerprints {
  /** The text result for the response. */
  result?: string;
  /** The list of fingerprints returned. */
  fingerprints?: Fingerprint[];
  /** The total number of fingerprint matches that have scripts associated to it. */
  total_number_of_fingerprints?: number;
}

/**
 * The classification code that determines operating context for a fingerprint.
 */
export type FingerprintCaseDataClassification =
  typeof FingerprintCaseDataClassification[keyof typeof FingerprintCaseDataClassification];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FingerprintCaseDataClassification = {
  SILENT: 'SILENT',
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
} as const;

/**
 * The aggregated data for use in the automated case screen of the UI.
 */
export interface FingerprintCaseData {
  /** The description of the fingerprint. */
  description?: string;
  /** The classification code that determines operating context for a fingerprint. */
  classification?: FingerprintCaseDataClassification;
  /** The unique ID for the fingerprint. */
  fingerprint_id?: string;
  /** The name of the fingerprint. */
  fingerprint_name?: string;
  /** The number of times the fingerprint has matched an observation. */
  fingerprint_match_count?: number;
  /** The number of times an Ansible script has been executed for the fingerprint. */
  script_execution_count?: number;
  /** The number of times an Ansible script has been executed but errored out for the fingerprint. */
  script_error_count?: number;
  /** The number of Service Now tickets that have been created for the fingerprint. */
  tickets_created?: number;
}

/**
 * Value indicating if the fingerprint is enabled/active for match processing. 'Y' = enabled, 'N' = disabled
 */
export type FingerprintEnabled = typeof FingerprintEnabled[keyof typeof FingerprintEnabled];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FingerprintEnabled = {
  Y: 'Y',
  N: 'N',
} as const;

/**
 * The classification code that determines operating context for a fingerprint.
 */
export type FingerprintClassification =
  typeof FingerprintClassification[keyof typeof FingerprintClassification];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FingerprintClassification = {
  SILENT: 'SILENT',
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
} as const;

/**
 * Response object for returning information for an active Ansible script.
 */
export interface AnsibleScript {
  /** The version of the Ansible script. Leave as 0 for auto-derivation of version numbers. */
  version?: number;
  /** The text description of the Ansible Script. */
  description?: string;
  /** The unique ID for the Ansible script. */
  ansible_script_id?: string;
  /** The list of host name or IP address for the device to run the script against. If more than one host, delimit with commas. */
  host_names?: string;
  /** The Ansible yaml script body, in template form. The script to execute via Ansible once data is mapped to variables within the script template.. */
  script_body?: string;
  /** The variables data structure providing all of the variables to match against log templates for data extraction and substitution in Ansible script templates.. */
  script_variables?: string;
  /** The name of the user who created/uploaded the script. Note: this will be populated by the JWT when it is integrated via auth. For now it is referenced in the API as a placeholder. */
  created_by?: string;
  /** The date/time that the script was made active for processing. */
  active_time?: string;
  /** The date/time that the script was created/uploaded. */
  created_time?: string;
  /** The unique ID for the fingerprint the script is associated to. */
  fingerprint_id?: string;
  /** The version of the fingerprint the script is associated to. */
  fingerprint_version?: number;
}

/**
 * Response object for returning information for an Fingerprint relative to a search.
 */
export interface Fingerprint {
  /** The version of the fingerprint. Leave as 0 for auto-derivation of version numbers. */
  version?: number;
  /** The text name of the fingerprint. */
  name?: string;
  /** The text description of the fingerprint. */
  description?: string;
  /** The classification code that determines operating context for a fingerprint. */
  classification?: FingerprintClassification;
  /** Value indicating if the fingerprint is enabled/active for match processing. 'Y' = enabled, 'N' = disabled */
  enabled?: FingerprintEnabled;
  /** The unique ID for the fingerprint. */
  fingerprint_id?: string;
  /** The Service Now category that tickets can be opened for regarding the fingerprint. */
  service_now_category?: string;
  template_info?: TemplateInfo;
  /** The observation ID that the fingerprint was established from. */
  observation_id?: string;
  ansible_script?: AnsibleScript;
  /** The name of the user who created/uploaded the fingerprint. Note: this will be populated by the JWT when it is integrated via auth. For now it is referenced in the API as a placeholder. */
  created_by?: string;
  /** The date/time that the fingerprint was made active for processing. */
  active_time?: string;
  /** The time the fingerprint was created. */
  created_time?: string;
  automation_case?: FingerprintCaseData;
}

/**
 * Sortable field values for fingerprints.
 */
export type FingerprintSortSpecificationName =
  typeof FingerprintSortSpecificationName[keyof typeof FingerprintSortSpecificationName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FingerprintSortSpecificationName = {
  name: 'name',
  description: 'description',
  matches: 'matches',
  tickets: 'tickets',
  errors: 'errors',
  automation: 'automation',
} as const;

/**
 * This defines the sort options for a fingerprint search.
 */
export interface FingerprintSortSpecification {
  /** Sortable field values for fingerprints. */
  name?: FingerprintSortSpecificationName;
  /** A boolean value indicating the sort direction for the column/field name. */
  desc?: boolean;
}

/**
 * Structured object for defining the search terms for retrieving fingerprints.
 */
export interface FingerprintSearchRequest {
  /** The page offset to paginate data from. */
  offset?: number;
  /** The page size limit used for paginating data that is retrieved. */
  limit?: number;
  /** The start of the date range to query for. */
  start_date?: string;
  /** The end of the date range to query for. */
  end_date?: string;
  /** A boolean value indicating if scripts associated to fingerprints should be included. true = include scripts. */
  include_scripts?: boolean;
  /** A list of fields, determining the sort specification for the query. The order of the fields in the list determines the sort order. */
  sort_fields?: FingerprintSortSpecification[];
}

/**
 * Response object for returning information relative to adding a new Fingerprint into the system.
 */
export interface FingerprintResponse {
  /** The text result for the response. */
  result?: string;
  /** The version for Fingerprint that was added. */
  version?: number;
  /** The ID for Fingerprint that was added. */
  fingerprint_id?: string;
}

/**
 * Value indicating if the fingerprint is enabled/active for match processing. 'Y' = enabled, 'N' = disabled
 */
export type FingerprintRequestEnabled =
  typeof FingerprintRequestEnabled[keyof typeof FingerprintRequestEnabled];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FingerprintRequestEnabled = {
  Y: 'Y',
  N: 'N',
} as const;

/**
 * The classification code that determines operating context for a fingerprint.
 */
export type FingerprintRequestClassification =
  typeof FingerprintRequestClassification[keyof typeof FingerprintRequestClassification];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FingerprintRequestClassification = {
  SILENT: 'SILENT',
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
} as const;

/**
 * Request object for querying the service for Ansible scripts.
 */
export interface FingerprintAnsibleScriptRequest {
  /** The version of the Ansible script. Leave as 0 for auto-derivation of version numbers. */
  version?: number;
  /** The text description of the Ansible Script. */
  description?: string;
  /** The unique ID for the Ansible script. */
  ansible_script_id?: string;
  /** The list of host name or IP address for the device to run the script against. If more than one host, delimit with commas. */
  host_names?: string;
  /** The Ansible yaml script body, in template form. The script to execute via Ansible once data is mapped to variables within the script template.. */
  script_body?: string;
  /** The variables data structure providing all of the variables to match against log templates for data extraction and substitution in Ansible script templates.. */
  script_variables?: string;
}

/**
 * Structured object containing data for inserting a new staged fingerprint.
 */
export interface FingerprintRequest {
  /** The version of the fingerprint. Leave as 0 for auto-derivation of version numbers. */
  version?: number;
  /** The text name of the fingerprint. */
  name?: string;
  /** The text description of the fingerprint. */
  description?: string;
  /** The classification code that determines operating context for a fingerprint. */
  classification?: FingerprintRequestClassification;
  /** Value indicating if the fingerprint is enabled/active for match processing. 'Y' = enabled, 'N' = disabled */
  enabled?: FingerprintRequestEnabled;
  /** The unique ID for the fingerprint. Leave blank for adding new Fingerprints. Set for updating existing fingerprints. */
  fingerprint_id?: string;
  /** The Service Now category that tickets can be opened for regarding the fingerprint. */
  service_now_category?: string;
  template_info?: TemplateInfo;
  /** The observation ID that the fingerprint was established from. */
  observation_id?: string;
  ansible_script?: FingerprintAnsibleScriptRequest;
}

/**
 * Response object for returning information for the Ansible script that was signalled to be executed.
 */
export interface ExecuteScriptAsyncResponse {
  /** The unique ID for the fingerprint associated to the script. */
  fingerprint_id?: string;
  /** The version for the fingerprint associated to the script. */
  fingerprint_version?: number;
  /** The unique ID for the Ansible script. */
  ansible_script_id?: string;
  /** The version for the Ansible script. */
  ansible_script_version?: number;
  /** The description for the script that was signalled to be executed. */
  script_description?: string;
  /** A boolean value indicating if the script was signalled to be executed successfully. true = the script was signalled to be executed successfully. */
  is_success?: boolean;
  /** A text description of what occurred with the script being signalled for execution. */
  result_message?: string;
  /** The host name or IP address the script is to be executed against. */
  host_name?: string;
  /** The reason for a failure in signalling for a script execution. */
  failure_reason?: string;
}

/**
 * The status of the execution of the associated script.
 */
export type ScriptExecutionInfoStatus =
  typeof ScriptExecutionInfoStatus[keyof typeof ScriptExecutionInfoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScriptExecutionInfoStatus = {
  NA: 'NA',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  SCHEDULED: 'SCHEDULED',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
} as const;

/**
 * The list of detail records for script execution information for each fingerprint match associated to a specific fingerprint ID.
 */
export interface ScriptExecutionInfo {
  /** The observation priority classification. */
  priority?: string;
  /** The status of the execution of the associated script. */
  status?: ScriptExecutionInfoStatus;
  /** The unique ID for the associated fingerprint. */
  fingerprint_id?: string;
  /** The version of the associated fingerprint. */
  fingerprint_version?: number;
  /** The name of the fingerprint associated to the script information match instance. Note: A fingerprint name can be changed. For a given fingerprint match, the name of the fingerprint at that point of time is recorded in the match record. If the fingerprint name is changed after the fact, the name for the match instance prior to the name change WILL NOT be updated. */
  fingerprint_name?: string;
  /** The ID of the observation related to the fingerprint match. */
  observation_id?: string;
  /** The ID of the change request that is visible in the SN UI. This is the ID/Name to display in the UI. */
  change_form_id?: string;
  /** The actual SN internal ID that references the change request ticket in the SN database. */
  sys_id?: string;
  /** The fingerprint match ID for the fingerprint match record in the database. Provided in case it is needed for retrieving fingerprint match details down the line. */
  match_id?: string;
  /** The URL for the change request ticket opened for the fingerprint match to the observation. */
  change_form_url?: string;
  /** The timestamp for the last reported state of an Ansible Script. */
  script_state_timestamp?: string;
  /** The affected resource host name or IP address. */
  affected_resource?: string;
  /** The unique ID for associated ansible script template. */
  ansible_script_id?: string;
  /** The version of the associated ansible script template. */
  ansible_script_version?: number;
}

export interface ScriptExecutionDetail {
  /** The total number of fingerprint matches that have scripts associated to it. */
  total_number_of_matches?: number;
  /** The list of detail records for script execution information for each fingerprint match associated to a specific fingerprint ID. */
  script_execution_details?: ScriptExecutionInfo[];
}

/**
 * Sortable field values for Script Info details.
 */
export type ScriptInfoSortSpecificationName =
  typeof ScriptInfoSortSpecificationName[keyof typeof ScriptInfoSortSpecificationName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScriptInfoSortSpecificationName = {
  timestamp: 'timestamp',
  fingerprint_name: 'fingerprint_name',
  priority: 'priority',
  affected: 'affected',
  ticket: 'ticket',
  status: 'status',
} as const;

/**
 * This defines the sort options for a fingerprint search.
 */
export interface ScriptInfoSortSpecification {
  /** Sortable field values for Script Info details. */
  name?: ScriptInfoSortSpecificationName;
  /** A boolean value indicating the sort direction for the column/field name. */
  desc?: boolean;
}

/**
 * Structured object for requesting script execution information detail data for a specified fingerprint.
 */
export interface AnsibleScriptInformationRequest {
  /** The page offset to paginate data from. */
  offset?: number;
  /** The page size limit used for paginating data that is retrieved. */
  limit?: number;
  /** The start of the date range to query for. */
  start_date?: string;
  /** The end of the date range to query for. */
  end_date?: string;
  /** A list of fields, determining the sort specification for the query. The order of the fields in the list determines the sort order. */
  sort_fields?: ScriptInfoSortSpecification[];
}

/**
 * Response object for returning information relative to adding a new Ansible script into the system.
 */
export interface ScriptResponse {
  /** The text result for the response. */
  result?: string;
  /** The version of the Ansible script that was added. */
  version?: number;
  /** The ID for Ansible script that was added. */
  ansible_script_id?: string;
}

/**
 * Structured object containing data for inserting a new staged Ansible Script.
 */
export interface AnsibleScriptRequest {
  /** The version of the Ansible script. Leave as 0 for auto-derivation of version numbers. */
  version?: number;
  /** The text description of the Ansible Script. */
  description?: string;
  /** The unique ID for the Ansible script. */
  ansible_script_id?: string;
  /** The list of host name or IP address for the device to run the script against. If more than one host, delimit with commas. */
  host_names?: string;
  /** The Ansible yaml script body, in template form. The script to execute via Ansible once data is mapped to variables within the script template.. */
  script_body?: string;
  /** The variables data structure providing all of the variables to match against log templates for data extraction and substitution in Ansible script templates.. */
  script_variables?: string;
  /** The unique ID for the fingerprint the script is associated to. */
  fingerprint_id?: string;
  /** The version of the fingerprint the script is associated to. */
  fingerprint_version?: number;
}

export interface ObservationResponse {
  observationId?: string;
  kafkaLogIds?: string[];
}

/**
 * This object encapsulates a template ID and version, for a template assigned to the fingerprint.
 */
export interface TemplateId {
  /** The ID for the log template. */
  template_id?: string;
  /** The version of the template id. */
  template_version?: number;
}

/**
 * This object encapsulates a List of Template ID and version pairs.
 */
export interface TemplateInfo {
  /** The List of Template ID and version pairs associated to a fingerprint. */
  template_ids?: TemplateId[];
}

/**
 * Structured object containing data for inserting a new test observation.
 */
export interface ObservationInfo {
  hostName?: string;
  templateInfo?: TemplateInfo;
}

/**
 * @summary For test purposes only! Do not use on any system other than a dev instance! Insert a new test observation into the system to test fingerprint matching.
 */
export const insertNewTestObservation = (observationInfo: ObservationInfo) => {
  return fingerprintRequestHandler<ObservationResponse>({
    url: `/auto-remediation/test/observation`,
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    data: observationInfo,
  });
};

/**
 * @summary Insert a new Ansible script into the Ansible script table. When Ansible script ID is blank, a new Ansible script ID is generated. When the Ansible script ID is set to an existing ID, this signifies it is an update to an existing script. If the version is < 1, the version is derived and incremented from the last version created. Otherwise when the version is > 0, the request can explicitly set the version.
 */
export const insertNewAnsibleScript = (ansibleScriptRequest: AnsibleScriptRequest) => {
  return fingerprintRequestHandler<ScriptResponse>({
    url: `/auto-remediation/script`,
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    data: ansibleScriptRequest,
  });
};

/**
 * @summary Retrieve Script execution information detail data for a specified fingerprint, within a date range.
 */
export const getScriptExecutionInformationDetail = (
  ansibleScriptInformationRequest: AnsibleScriptInformationRequest
) => {
  return fingerprintRequestHandler<ScriptExecutionDetail>({
    url: `/auto-remediation/script/execution/detail`,
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    data: ansibleScriptInformationRequest,
  });
};

/**
 * @summary Execute an Ansible script for the specified associated Fingerprint match id. This is an aysync call. The script process is signalled to execute and a response is returned immediately.
 */
export const executeAsyncAnsibleScript = (fingerprintMatchId: string) => {
  return fingerprintRequestHandler<ExecuteScriptAsyncResponse>({
    url: `/auto-remediation/script/async/match/execute/${fingerprintMatchId}`,
    method: 'post',
  });
};

/**
 * @summary Insert a new fingerprint into the fingerprint table. When Fingerprint ID is blank, a new Fingerprint ID is generated. When the Fingerprint ID is set to an existing ID, this signifies it is an update to an existing Fingerprint. If the version is < 1, the version is derived and incremented from the last version created. Otherwise when the version is > 0, the request can explicitly set the version. You may optionally include an Ansible script template in the request. If the Ansible Script field is defined with all of the parameters to persist an Ansible Script, it will be inserted into the database and associated to the fingerprint automatically. When the Ansible script ID is blank, a new Ansible script ID is generated. When the Ansible Script ID is set to an existing ID, this signifies it is an update to an existing Ansible Script. If the Ansible Script version is < 1, the Ansible Script version is derived and incremented.
 */
export const insertNewFingerprint = (fingerprintRequest: FingerprintRequest) => {
  return fingerprintRequestHandler<FingerprintResponse>({
    url: `/auto-remediation/fingerprint`,
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    data: fingerprintRequest,
  });
};

/**
 * @summary Search for all fingerprints within a date range. Associated Ansible scripts may or may not be included in the fingerprints returned. Paging with offset reference is supported. If no date range is specified the start time will default to the beginning of the Unix Epoch Time and the end time will default to a point in the future, which will return all fingerprints in the database. To limit results in this case, specify parameters for paging. If either the start time or end time for the range is not specified, it will be defaulted on your behalf as per the rules aforementioned.
 */
export const getFingerprintsForDateRange = (params?: GetFingerprintsForDateRangeParams) => {
  return fingerprintRequestHandler<Fingerprints>({
    url: `/auto-remediation/fingerprint/search`,
    method: 'get',
    params,
  });
};

/**
 * @summary Search for all fingerprints within a date range. Associated Ansible scripts may or may not be included in the fingerprints returned. Paging with offset reference is supported. If no date range is specified the start time will default to the beginning of the Unix Epoch Time and the end time will default to a point in the future, which will return all fingerprints in the database. To limit results in this case, specify parameters for paging. If either the start time or end time for the range is not specified, it will be defaulted on your behalf as per the rules aforementioned.
 */
export const getFingerprintsForSearchSpecification = (
  fingerprintSearchRequest: FingerprintSearchRequest
) => {
  return fingerprintRequestHandler<Fingerprints>({
    url: `/auto-remediation/fingerprint/search`,
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    data: fingerprintSearchRequest,
  });
};

/**
 * @summary Retrieve observation detail data for a specified fingerprint, within a date range.
 */
export const getObservationDetailsForFingerprint = (
  fingerprintObservationsRequest: FingerprintObservationsRequest
) => {
  return fingerprintRequestHandler<FingerprintObservations>({
    url: `/auto-remediation/fingerprint/observations`,
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    data: fingerprintObservationsRequest,
  });
};

/**
 * @summary Retrieve a Ansible Script for the specified Ansible Script ID and version. The script is retrieved from the active processing table.
 */
export const getAnsibleScriptByIdAndVersion = (ansibleScriptId: string, version: number) => {
  return fingerprintRequestHandler<AnsibleScript>({
    url: `/auto-remediation/script/${ansibleScriptId}/${version}`,
    method: 'get',
  });
};

/**
 * @summary Retrieve the output from the last execution of the Ansible script associated to the specified Fingerprint Match ID.
 */
export const getAnsibleScriptOutput = (fingerprintMatchId: string) => {
  return fingerprintRequestHandler<AnsibleScriptOutputResponse>({
    url: `/auto-remediation/script/output/${fingerprintMatchId}`,
    method: 'get',
  });
};

/**
 * @summary Retrieve the script execution detail info for a specified fingerprint match ID.
 */
export const getScriptInfoForFingerprintMatchId = (fingerprintMatchId: string) => {
  return fingerprintRequestHandler<ScriptExecutionInfo>({
    url: `/auto-remediation/script/execution/info/${fingerprintMatchId}`,
    method: 'get',
  });
};

/**
 * @summary Retrieve all Fingerprint versions for the specified Fingerprint ID.
 */
export const getFingerprintsById = (fingerprintId: string) => {
  return fingerprintRequestHandler<Fingerprints>({
    url: `/auto-remediation/fingerprints/${fingerprintId}`,
    method: 'get',
  });
};

/**
 * @summary Retrieve a Fingerprint for the specified Fingerprint ID and version. The fingerprint is retrieved from the active processing table. If an Ansible script is associated to it, it is also retrieved and included in the response.
 */
export const getFingerprintByIdAndVersion = (fingerprintId: string, version: number) => {
  return fingerprintRequestHandler<Fingerprint>({
    url: `/auto-remediation/fingerprint/${fingerprintId}/${version}`,
    method: 'get',
  });
};

/**
 * @summary Retrieve a the most current Fingerprint for the specified Fingerprint ID. If an Ansible script is associated to it, it is also retrieved and included in the response.
 */
export const getCurrentFingerprintById = (fingerprintId: string) => {
  return fingerprintRequestHandler<Fingerprint>({
    url: `/auto-remediation/fingerprint/current/${fingerprintId}`,
    method: 'get',
  });
};

/**
 * @summary Retrieve Automation Case data for all fingerprints in the system. This feeds the Automated Cases screen in the UI.
 */
export const getFingerprintAutomationCases = () => {
  return fingerprintRequestHandler<FingerprintAutomationCases>({
    url: `/auto-remediation/fingerprint/automation/cases`,
    method: 'get',
  });
};

export type InsertNewTestObservationResult = NonNullable<
  Awaited<ReturnType<typeof insertNewTestObservation>>
>;
export type InsertNewAnsibleScriptResult = NonNullable<
  Awaited<ReturnType<typeof insertNewAnsibleScript>>
>;
export type GetScriptExecutionInformationDetailResult = NonNullable<
  Awaited<ReturnType<typeof getScriptExecutionInformationDetail>>
>;
export type ExecuteAsyncAnsibleScriptResult = NonNullable<
  Awaited<ReturnType<typeof executeAsyncAnsibleScript>>
>;
export type InsertNewFingerprintResult = NonNullable<
  Awaited<ReturnType<typeof insertNewFingerprint>>
>;
export type GetFingerprintsForDateRangeResult = NonNullable<
  Awaited<ReturnType<typeof getFingerprintsForDateRange>>
>;
export type GetFingerprintsForSearchSpecificationResult = NonNullable<
  Awaited<ReturnType<typeof getFingerprintsForSearchSpecification>>
>;
export type GetObservationDetailsForFingerprintResult = NonNullable<
  Awaited<ReturnType<typeof getObservationDetailsForFingerprint>>
>;
export type GetAnsibleScriptByIdAndVersionResult = NonNullable<
  Awaited<ReturnType<typeof getAnsibleScriptByIdAndVersion>>
>;
export type GetAnsibleScriptOutputResult = NonNullable<
  Awaited<ReturnType<typeof getAnsibleScriptOutput>>
>;
export type GetScriptInfoForFingerprintMatchIdResult = NonNullable<
  Awaited<ReturnType<typeof getScriptInfoForFingerprintMatchId>>
>;
export type GetFingerprintsByIdResult = NonNullable<
  Awaited<ReturnType<typeof getFingerprintsById>>
>;
export type GetFingerprintByIdAndVersionResult = NonNullable<
  Awaited<ReturnType<typeof getFingerprintByIdAndVersion>>
>;
export type GetCurrentFingerprintByIdResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentFingerprintById>>
>;
export type GetFingerprintAutomationCasesResult = NonNullable<
  Awaited<ReturnType<typeof getFingerprintAutomationCases>>
>;
