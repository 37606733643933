import {
  useSelectedAnomalyRelatedAnomaliesUtilization,
  useSelectedAnomalyRelatedAnomaliesUtilizationCount,
} from '../../../../data/InterfaceUtilization';
import AssociatedAnomalies from '../../common/AssociatedAnomalies/AssociatedAnomalies';

export default function AssociatedAnomaliesInterface() {
  const anomalyListQuery = useSelectedAnomalyRelatedAnomaliesUtilization;
  const anomalyCountQuery = useSelectedAnomalyRelatedAnomaliesUtilizationCount;

  return (
    <AssociatedAnomalies
      anomalyListQuery={anomalyListQuery}
      anomalyCountQuery={anomalyCountQuery}
    />
  );
}
