import TableSortArrow from 'components/common/TableSortArrow/TableSortArrow';
import {useMemo} from 'react';
import styled from 'styled-components';
import {
  Container as TableWrapper,
  Table,
  HeaderWrapper,
  HeaderRow,
  Header,
  TableBody,
  TableData,
  TableRow,
} from '../../../../common/TableElements/TableElements';
import {
  MtbfDeviceChild,
  MtbfDeviceComponents,
  MtbfDeviceComponentsResponseResult,
} from '../../../../../openapi-schema/schemaTS';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getHeaderStyles} from 'components/common/TableElements/utils/getHeaderStyles';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const DeviceCount = styled.p`
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 19px;
  border-bottom: 1px solid rgba(50, 47, 51, 1);
`;

const columnHelper = createColumnHelper<MtbfDeviceChild>();

interface Props {
  deviceData: MtbfDeviceComponentsResponseResult | MtbfDeviceComponents | undefined;
}
export default function DeviceComponentsTable({deviceData}: Props) {
  const data = useMemo(() => (deviceData ? [...deviceData.children] : []), [deviceData]);

  const columns = [
    columnHelper.accessor('resource_index', {
      header: 'Resource Index',
      size: 1,
    }),
    columnHelper.accessor('class_type', {
      header: 'Module',
      size: 1,
    }),
    columnHelper.accessor('serial_number', {
      header: 'Serial Number',
      size: 1,
      enableSorting: false,
    }),
  ];
  const {getHeaderGroups, getRowModel} = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: true,
    enableSortingRemoval: false,
  });

  return (
    <Container>
      <DeviceCount>{data.length} Device Components</DeviceCount>
      <TableWrapper>
        <Table>
          <HeaderWrapper>
            {getHeaderGroups().map(headerGroup => (
              <HeaderRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Header key={header.id} style={{...getHeaderStyles(header)}}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <TableSortArrow column={header} />
                  </Header>
                ))}
              </HeaderRow>
            ))}
          </HeaderWrapper>
          <TableBody>
            {getRowModel().rows.map(row => (
              <TableRow key={row.id} noSelect={true}>
                {row.getVisibleCells().map(cell => (
                  <TableData key={cell.id} style={{...getCellStyles(cell)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Container>
  );
}
