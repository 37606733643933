import Stylesheet from './defaults/Stylesheet';
import DagreOptions from './defaults/DagreHorizontalOptions';

export default {
  name: 'Horizontal Hierarchy',
  layout: {
    options: DagreOptions,
    stylesheet: Stylesheet,
  },
};
