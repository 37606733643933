import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: white;
  position: absolute;
  bottom: 10px;
  left: 10px;
  line-height: 14px;
`;

const Title = styled.p`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

interface BarProps {
  barColor: string;
}

const Bar = styled.div<BarProps>`
  min-width: 20px;
  min-height: 5px;
  background: ${props => props.barColor};
`;

const RowText = styled.p`
  font-size: 12px;
`;

const NoteText = styled.p`
  font-size: 10px;
  line-height: 12px;
`;

export default function NetworkRingLegend() {
  return (
    <Container>
      <Title>Legend</Title>
      <Row>
        <Bar barColor="rgba(205, 62, 56, 1)" />
        <RowText>Selected connection</RowText>
      </Row>
      <Row>
        <Bar barColor="rgba(61, 106, 126, 1)" />
        <RowText>Available connections to this site</RowText>
      </Row>
      <NoteText>* Line thickness denotes traffic volume between sites</NoteText>
    </Container>
  );
}
