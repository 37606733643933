import {SenseiQuestionRequest, SenseiQuestionResponse} from 'openapi-schema/senseiSchemaTS';
import {atom} from 'recoil';

export const senseiChatSessionState = atom({
  key: 'senseiChatSession',
  default: [] as (SenseiQuestionRequest | SenseiQuestionResponse)[],
});

export const LifecycleStatus = {
  isLoading: 'isLoading',
  isError: 'isError',
  isSuccess: 'isSuccess',
} as const;
export type SenseiLifecycleStatus = (typeof LifecycleStatus)[keyof typeof LifecycleStatus];

export const senseiLifecycleState = atom({
  key: 'senseiLifecycle',
  default: LifecycleStatus.isLoading as SenseiLifecycleStatus,
});

interface SenseiChatActions {
  createWebSocket: () => void;
  sendMessage: (request: SenseiQuestionRequest) => void;
}

export const senseiChatActionsState = atom({
  key: 'senseiChatActions',
  default: undefined as SenseiChatActions | undefined,
});
