import * as fs from '../openapi-schema/fingerprintSchemaTS';

export async function getScriptExecutionInfo(payload: fs.AnsibleScriptInformationRequest) {
  const post = await fs.getScriptExecutionInformationDetail(payload);
  return post.data;
}

export async function executeScript(payload: string) {
  const post = await fs.executeAsyncAnsibleScript(payload);
  return post.data;
}

export async function postFingerprint(payload: fs.FingerprintRequest) {
  const post = await fs.insertNewFingerprint(payload);
  return post.data;
}

export async function getFingerprintObservations(payload: fs.FingerprintObservationsRequest) {
  const post = await fs.getObservationDetailsForFingerprint(payload);
  return post.data;
}

export async function getAnsibleScript(ansibleScriptId: string, version: number) {
  const post = await fs.getAnsibleScriptByIdAndVersion(ansibleScriptId, version);
  return post.data;
}

export async function getAnsibleScriptOutput(payload: string) {
  const post = await fs.getAnsibleScriptOutput(payload);
  return post.data;
}

export async function getScriptExecutionInfoSingleMatch(payload: string) {
  const post = await fs.getScriptInfoForFingerprintMatchId(payload);
  return post.data;
}

export async function getFingerprintsList(payload: fs.GetFingerprintsForDateRangeParams) {
  const post = await fs.getFingerprintsForDateRange(payload);
  return post.data;
}
