import {useCallback} from 'react';
import styled from 'styled-components';
import {useSelectedAnomaly} from '../../../data/Anomalies';
import KPIErrorState from '../../Katana/components/KPIErrorState/KPIErrorState';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';
import config from '../../../utils/AppConfigurationService';
import {
  useBGPKPIs,
  useExchangeLogAnomalyKPIs,
  useFiberChannelKPIs,
  useIncidentKPIs,
  useInterfaceUtilizationKPIs,
  useISISKPIs,
  useLatencyKPIs,
  useLogAnomalyKPIs,
  useOverviewKPIs,
} from '../../../data/KPI';
import {formatIncidentTime, formatNeighborDropTime} from '../../../utils/formatTime';
import {Duration} from 'luxon';
import {
  AnomalyTypeBucket,
  HotlErAnomalyPathChange,
  PriorityClassificationBucket,
} from '../../../openapi-schema/schemaTS';
import {useDevicesPathChange} from '../../../data/PathChange';
import {startCase, uniq} from 'lodash';
import SentimentTooltip from 'components/AnomalyDetail/common/AnomalyDetailKPI/SentimentTooltip';
import CriticalityTooltip from 'components/AnomalyDetail/common/AnomalyDetailKPI/CriticalityTooltip';
import {useVTCKPIs} from '../../../data/KPI';
import KPIBox from 'components/common/KPIBox/KPIBox';
import ObservationTypeIcon from 'components/common/ObservationTypeIcon/ObservationTypeIcon';
import ObservationPriorityIcon from 'components/common/ObservationPriorityIcon/ObservationPriorityIcon';
import {useRecoilValue} from 'recoil';
import {ReviewPanel, reviewPanelContentState} from '../../../atoms/ObservationSelectionPageContent';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  background-color: rgba(34, 36, 41, 1);
`;

const OverviewValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const OverviewCountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingAndErrorWrapper = styled.div`
  height: 100%;
  border-radius: 8px;
  border: 1px solid #4b454d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  width: 100%;
`;

interface RenderLoadingAndErrorStatesProps {
  isLoading: boolean;
  refetch: () => void;
}
const renderLoadingAndErrorStates = ({isLoading, refetch}: RenderLoadingAndErrorStatesProps) => {
  return (
    <LoadingAndErrorWrapper>
      {isLoading ? <LoadingSpinner /> : <KPIErrorState retry={refetch} />}
    </LoadingAndErrorWrapper>
  );
};

function LatencyKPIList() {
  const {data, isError, isLoading, refetch} = useLatencyKPIs();

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  return (
    <Container>
      <KPIBox
        measure="7 Day Delay Avg"
        value={`${data?.delay_historical_average?.toFixed(2)} ms`}
      />
      <KPIBox
        measure="Observation Delay Avg"
        value={`${data?.delay_current_average?.toFixed(2)} ms`}
      />
      <KPIBox measure="Related Anomalous Devices" value={data.related_device_count} />
    </Container>
  );
}

function LogAnomalyKPIList() {
  const {data, isError, isLoading, refetch} = useLogAnomalyKPIs();

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  return (
    <Container>
      <KPIBox
        measure="Template Count"
        value={data?.template_log_count}
        tooltipContent={
          <p>
            The number of unique <b>Log Templates</b> in the selected time window.
          </p>
        }
        tooltipMaxWidth="320px"
      />
      <KPIBox
        measure="Log Volume"
        value={data?.host_log_count}
        tooltipContent={'The number of individual log messages in the selected time window.'}
        tooltipMaxWidth="320px"
      />
      <KPIBox
        measure="7 Day Sentiment"
        value={data?.negative_sentiment?.toFixed(2)}
        tooltipContent={<SentimentTooltip />}
        tooltipMaxWidth="350px"
      />
      <KPIBox
        measure="7 Day Criticality"
        value={data?.criticality?.toFixed(2)}
        tooltipContent={<CriticalityTooltip />}
        tooltipMaxWidth="350px"
      />
    </Container>
  );
}

function ExchangeLogAnomalyKPIList() {
  const {data, isError, isLoading, refetch} = useExchangeLogAnomalyKPIs();

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  return (
    <Container>
      <KPIBox
        measure="Template Count"
        value={data?.template_log_count}
        tooltipContent={
          <p>
            The number of unique <b>Log Templates</b> in the selected time window.
          </p>
        }
        tooltipMaxWidth="320px"
      />
      <KPIBox
        measure="Log Volume"
        value={data?.host_log_count}
        tooltipContent={'The number of individual log messages in the selected time window.'}
        tooltipMaxWidth="320px"
      />
      <KPIBox
        measure="7 Day Sentiment"
        value={data?.negative_sentiment?.toFixed(2)}
        tooltipContent={<SentimentTooltip />}
        tooltipMaxWidth="350px"
      />
      <KPIBox
        measure="7 Day Criticality"
        value={data?.criticality?.toFixed(2)}
        tooltipContent={<CriticalityTooltip />}
        tooltipMaxWidth="350px"
      />
    </Container>
  );
}

function InterfaceUtilizationKPIList() {
  const {data, isError, isLoading, refetch} = useInterfaceUtilizationKPIs();
  const formatRange = useCallback((min: number, max: number) => {
    return `${(min ?? 0).toFixed(1)} - ${(max ?? 0).toFixed(1)}%`;
  }, []);

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  const {min_in, max_in, min_out, max_out} = data?.utilization_range ?? {};
  return (
    <Container>
      <KPIBox measure="Utilization Range In" value={formatRange(min_in, max_in)} />
      <KPIBox measure="Utilization Range Out" value={formatRange(min_out, max_out)} />
      <KPIBox measure="Related Device Count" value={data?.related_device_count} />
    </Container>
  );
}

function FiberChannelKPIList() {
  const {data, isError, isLoading, refetch} = useFiberChannelKPIs();
  const formatRange = useCallback((min: number, max: number) => {
    return `${(min ?? 0).toFixed(1)} - ${(max ?? 0).toFixed(1)}%`;
  }, []);

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  const {min_in, max_in, min_out, max_out} = data?.utilization_range ?? {};
  return (
    <Container>
      <KPIBox measure="Utilization Range In" value={formatRange(min_in, max_in)} />
      <KPIBox measure="Utilization Range Out" value={formatRange(min_out, max_out)} />
      <KPIBox
        measure="7 Day Avg In"
        value={`${data?.utilization_historical_average.avg_in.toFixed(1)}%`}
      />
      <KPIBox
        measure="7 Day Avg Out"
        value={`${data?.utilization_historical_average.avg_out.toFixed(1)}%`}
      />
    </Container>
  );
}

function BGPNeighborKPIList() {
  const {data, isError, isLoading, refetch} = useBGPKPIs();

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  return (
    <Container>
      <KPIBox measure="BGP Identifier" value={data.bgp_identifier} />
      <KPIBox measure="Local AS" value={data.local_as} />
      <KPIBox
        measure="System Uptime"
        value={formatNeighborDropTime(Duration.fromObject({milliseconds: data.sys_up_time_ms}))}
        small
      />
      <KPIBox measure="External BGP Peers" value={data.external_bgp_peers} />
      <KPIBox measure="Internal BGP Peers" value={data.internal_bgp_peers} />
    </Container>
  );
}

function ISISNeighborKPIList() {
  const {data, isError, isLoading, refetch} = useISISKPIs();

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  return (
    <Container>
      <KPIBox measure="System Name" value={data.system_name} />
      <KPIBox
        measure="System Uptime"
        value={formatNeighborDropTime(Duration.fromObject({milliseconds: data.sys_up_time_ms}))}
      />
    </Container>
  );
}

interface PathChangeKPIProps {
  observation: HotlErAnomalyPathChange;
}
function getReasonText(agg_subtype: string[]) {
  return uniq(agg_subtype)
    .filter(s => s === 'path_change' || s === 'delay_increase')
    .map(subType => {
      return startCase(subType);
    })
    .join(' & ');
}
function PathChangeKPIList({observation}: PathChangeKPIProps) {
  const {data, isError, isLoading, refetch} = useDevicesPathChange();

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  const reasonText = getReasonText(observation?.explanatory_reasons?.agg_subtype);
  const anomalousHops = observation?.explanatory_reasons?.agg_hop_number || [];
  const anomalousHopsDisplay =
    anomalousHops.length > 6
      ? `${anomalousHops.slice(0, 6).join(', ')}...`
      : anomalousHops.join(', ');
  return (
    <Container>
      <KPIBox measure="Observation Reason" value={reasonText} small={reasonText.includes('&')} />
      <KPIBox
        measure={`Anomalous Hop${anomalousHops.length > 1 ? 's' : ''}`}
        value={anomalousHopsDisplay}
      />
      <KPIBox measure="Typical Path Hops" value={data.typical_path_edge_ids?.length} />
    </Container>
  );
}

function VTCKPIList() {
  const {data, isError, isLoading, refetch} = useVTCKPIs();

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  const {median_callee_mos, median_caller_mos} = data.aggregated_metrics;
  const {callee_mos, caller_mos} = data.point_in_time_metrics;
  return (
    <Container>
      <KPIBox
        measure="Caller/Callee EMOS"
        value={`${caller_mos.toFixed(2)} / ${callee_mos.toFixed(2)}`}
      />
      <KPIBox
        measure="Caller/Callee Median EMOS"
        value={`${median_caller_mos.toFixed(2)} / ${median_callee_mos.toFixed(2)}`}
      />
      <KPIBox measure="Path Trace Hops" value={data.path_trace_hops} />
      <KPIBox
        measure="Average Ping"
        value={`${Math.max(data.caller_to_callee_ping.ping_avg_ms, 0).toFixed(2)} ms`}
      />
    </Container>
  );
}

function OverviewKPIList() {
  const {data, isError, isLoading, refetch} = useOverviewKPIs();

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  const formattedPriorityDistrubtion = (data?.priority_distribution || []).filter(
    v => v.bucket !== 'undefined'
  );

  return (
    <Container>
      <KPIBox measure="Observation Count" value={data?.anomaly_count} />
      <KPIBox
        measure="Count by Priority"
        value={
          <OverviewValueWrapper>
            {formattedPriorityDistrubtion.map(pd => {
              return (
                <OverviewCountWrapper key={pd.bucket}>
                  <ObservationPriorityIcon
                    priority={pd.bucket as PriorityClassificationBucket}
                    color
                  />
                  {pd.count}
                </OverviewCountWrapper>
              );
            })}
          </OverviewValueWrapper>
        }
        small
      />
      <KPIBox
        measure="Count by Type"
        value={
          <OverviewValueWrapper>
            {data?.type_distribution.map(pd => {
              return (
                <OverviewCountWrapper key={pd.bucket}>
                  <ObservationTypeIcon
                    type={pd.bucket as AnomalyTypeBucket}
                    className="text-[20px] text-white"
                  />
                  {pd.count}
                </OverviewCountWrapper>
              );
            })}
          </OverviewValueWrapper>
        }
        small
      />
    </Container>
  );
}

function IncidentsKPIList() {
  const {data, isError, isLoading, refetch} = useIncidentKPIs();

  if (isError || isLoading || !data) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  return (
    <Container>
      <KPIBox measure="Active Incidents" value={data?.activeIncidents} />
      <KPIBox
        measure="Last Incident"
        value={formatIncidentTime(
          Duration.fromObject({seconds: data?.timeSinceLastIncidentSeconds})
        )}
      />
      <KPIBox measure="Incidents YTD" value={data?.incidentsYTD} />
    </Container>
  );
}

export default function AnomalySelectionKPI() {
  const reviewPanelContent = useRecoilValue(reviewPanelContentState);
  const {data, isError, isLoading, isIdle, refetch} = useSelectedAnomaly();

  if (isError || isLoading) {
    return renderLoadingAndErrorStates({isLoading, refetch});
  }

  if (reviewPanelContent === ReviewPanel.Incidents) {
    return <IncidentsKPIList />;
  }
  if (isIdle) return <OverviewKPIList />;
  if (data) {
    let queriesList = <KPIErrorState retry={refetch} />;
    if (data.anomaly_type === config.AnomalyTypes.LOG) {
      queriesList = <LogAnomalyKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.LATENCY) {
      queriesList = <LatencyKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.INTERFACE) {
      queriesList = <InterfaceUtilizationKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.NEIGHBOR_DROP_BGP) {
      queriesList = <BGPNeighborKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.NEIGHBOR_DROP_ISIS) {
      queriesList = <ISISNeighborKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.PATH_CHANGE) {
      queriesList = <PathChangeKPIList observation={data} />;
    } else if (data.anomaly_type === config.AnomalyTypes.VIDEO_VOIP) {
      queriesList = <VTCKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.FIBER_CHANNEL) {
      queriesList = <FiberChannelKPIList />;
    } else if (data.anomaly_type === config.AnomalyTypes.EXCHANGE_LOG) {
      queriesList = <ExchangeLogAnomalyKPIList />;
    }

    return <Container>{queriesList}</Container>;
  } else {
    return <OverviewKPIList />;
  }
}
