import {atom} from 'recoil';
import config from 'utils/AppConfigurationService';

export const ExplorePanel = {
  Topology: 'Topology',
  Sensei: 'Sensei',
} as const;
export type ExplorePanelOptions = (typeof ExplorePanel)[keyof typeof ExplorePanel];

export const explorePanelContentState = atom({
  key: 'explorePanelContent',
  default: (config.featureFlags.Sensei
    ? ExplorePanel.Sensei
    : ExplorePanel.Topology) as ExplorePanelOptions,
});

export const ReviewPanel = {
  Incidents: 'Incidents',
  Observations: 'Observations',
} as const;
export type ReviewPanelOptions = (typeof ReviewPanel)[keyof typeof ReviewPanel];

export const reviewPanelContentState = atom({
  key: 'reviewPanelContent',
  default: (config.featureFlags.incidents
    ? ReviewPanel.Incidents
    : ReviewPanel.Observations) as ReviewPanelOptions,
});
