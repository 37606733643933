import {useRef, useCallback, useEffect, useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {whatIfSliderValueState} from '../../../atoms/whatIfSliderValue';
import TopologyBase from '../../common/TopologyBase/TopologyBase';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';
import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import {getMaxScaledNodeValue, getMaxScaledEdgeValue} from './utils/whatIfScalingFunctions';
import {getEdgeColor, getNodeColors, whatIfTopologyColors} from './utils/whatIfColorFunctions';
import {selectedWhatIfStatState} from '../../../atoms/selectedWhatIfStat';
import {whatIfThresholdLevelState} from '../../../atoms/whatIfThresholdLevel';
import {selectedWhatIfMetricState} from '../../../atoms/selectedWhatIfMetric';
import {
  InterfaceMetrics,
  NodeMetrics,
  WhatIfNodeMetricsKeys,
} from '../../../constants/WhatIfMetrics';
import useWhatIfTopologyActions from '../../../helpers/hooks/useWhatIfTopologyActions';
import {WhatIfStatDataKeys} from '../../../constants/WhatIfStats';
import {TopologyEdgeData, TopologyElements, TopologyNodeData} from '../../../data/types';
import useWhatIfTopologyData from './useWhatIfTopologyData';
import {SummaryInterfaceUtilization} from '../../../openapi-schema/schemaTS';
import {
  whatIfScalingFunctionEdgeTooltip,
  whatIfScalingFunctionNodeTooltip,
} from './utils/whatIfScalingFunctionsTooltip';
import {EventObjectEdge, EventObjectNode} from 'cytoscape';
import {changeCursorToPointer} from '../../common/TopologyBase/utils/changeCursorToPointer';
import {changeCursorToDefault} from '../../common/TopologyBase/utils/changeCursorToDefault';
import WhatIfNodeTooltip from '../../common/TopologyTooltips/WhatIfNodeTooltip';
import WhatIfEdgeTooltip from '../../common/TopologyTooltips/WhatIfEdgeTooltip';
import {
  whatIfSelectedEdgeDataState,
  whatIfSelectedNodeDataState,
} from '../../../atoms/whatIfSelectedTopologyElements';
import {useTopologyData} from 'data/Topology';

export default function TopologyWhatIf() {
  const {data: topologyElements, isLoading, isError, refetch} = useTopologyData();
  const whatIfData = useWhatIfTopologyData();
  const [tooltipComponent, setTooltipComponent] = useState<JSX.Element | undefined>();
  const whatIfDataRef = useRef(whatIfData);
  whatIfDataRef.current = whatIfData;

  const selectedWhatIfStat = useRecoilValue(selectedWhatIfStatState);
  const whatIfSliderValue = useRecoilValue(whatIfSliderValueState);
  const whatIfThresholdLevel = useRecoilValue(whatIfThresholdLevelState);
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);

  const whatIfSliderRef = useRef(whatIfSliderValue);
  const selectedWhatIfStatRef = useRef(selectedWhatIfStat);
  const whatIfThresholdLevelRef = useRef(whatIfThresholdLevel);
  const selectedWhatIfMetricRef = useRef(selectedWhatIfMetric);

  const whatIfSelectedNodeData = useRecoilValue(whatIfSelectedNodeDataState);
  const whatIfSelectedEdgeData = useRecoilValue(whatIfSelectedEdgeDataState);
  const {updateStateNode, updateStateEdge, updateStateClickBackground} = useWhatIfTopologyActions();

  whatIfSliderRef.current = whatIfSliderValue;
  selectedWhatIfStatRef.current = selectedWhatIfStat;
  whatIfThresholdLevelRef.current = whatIfThresholdLevel;
  selectedWhatIfMetricRef.current = selectedWhatIfMetric;

  const onNodeClick = useCallback(
    (data: TopologyNodeData) => {
      updateStateNode(data, selectedWhatIfMetricRef.current in InterfaceMetrics);
    },
    [updateStateNode]
  );

  const onEdgeClick = useCallback(
    (data: TopologyEdgeData) => {
      updateStateEdge(data, selectedWhatIfMetricRef.current in NodeMetrics);
    },
    [updateStateEdge]
  );

  const clickBackground = useCallback(() => {
    updateStateClickBackground();
  }, [updateStateClickBackground]);

  const formatNodes = useCallback((topologyElements: TopologyElements) => {
    const metric =
      WhatIfNodeMetricsKeys[selectedWhatIfMetricRef.current as keyof typeof WhatIfNodeMetricsKeys];
    const stat = WhatIfStatDataKeys[selectedWhatIfStatRef.current];

    return topologyElements?.nodes?.map(node => {
      const maxScaledValue = getMaxScaledNodeValue(
        whatIfDataRef.current[node.data.id]?.[0],
        stat,
        whatIfSliderRef.current,
        metric
      );

      const nodeColors = getNodeColors(
        maxScaledValue,
        whatIfThresholdLevelRef.current,
        selectedWhatIfMetricRef.current
      );

      return {
        ...node,
        data: {
          ...node.data,
          bgColor: nodeColors.background,
          borderColor: nodeColors.border,
          selectedBackgroundColor: whatIfTopologyColors.NodeSelectedBackground,
          selectedBorderColor: whatIfTopologyColors.NodeSelectedBorder,
        },
      };
    });
  }, []);

  const formatEdges = useCallback((topologyElements: TopologyElements) => {
    const stat = WhatIfStatDataKeys[selectedWhatIfStatRef.current];

    return topologyElements?.edges?.map(edge => {
      const int1Data =
        whatIfDataRef.current[`${edge.data.source_node_name}_${edge.data.source_int_name}`]?.[0];
      const int2Data =
        whatIfDataRef.current[`${edge.data.target_node_name}_${edge.data.target_int_name}`]?.[0];

      const utilization = {
        int1: int1Data as SummaryInterfaceUtilization | undefined,
        int2: int2Data as SummaryInterfaceUtilization | undefined,
      };

      const maxScaledValue = getMaxScaledEdgeValue(utilization, stat, whatIfSliderRef.current);

      return {
        ...edge,
        data: {
          ...edge.data,
          lineColor: getEdgeColor(
            maxScaledValue,
            whatIfThresholdLevelRef.current,
            selectedWhatIfMetricRef.current
          ),
          selectedLineColor: whatIfTopologyColors.EdgeSelected,
        },
      };
    });
  }, []);

  const mouseOverEdge = useCallback((event: EventObjectEdge) => {
    changeCursorToPointer(event.cy.container());
    const element = event.target;

    const int1Data =
      whatIfDataRef.current[
        `${element.data().source_node_name}_${element.data().source_int_name}`
      ]?.[0];
    const int2Data =
      whatIfDataRef.current[
        `${element.data().target_node_name}_${element.data().target_int_name}`
      ]?.[0];

    const utilization = {
      int1: int1Data as SummaryInterfaceUtilization | undefined,
      int2: int2Data as SummaryInterfaceUtilization | undefined,
    };

    const scaledMetricData = whatIfScalingFunctionEdgeTooltip(
      utilization,
      selectedWhatIfStatRef.current,
      whatIfSliderRef.current
    );

    setTooltipComponent(<WhatIfEdgeTooltip event={event} scaledMetricData={scaledMetricData} />);
  }, []);

  const mouseOverNode = useCallback((event: EventObjectNode) => {
    changeCursorToPointer(event.cy.container());

    const scaledMetricData = whatIfScalingFunctionNodeTooltip(
      whatIfDataRef.current[event.target.data().id]?.[0],
      selectedWhatIfStatRef.current,
      whatIfSliderRef.current,
      selectedWhatIfMetricRef.current
    );

    setTooltipComponent(<WhatIfNodeTooltip event={event} scaledMetricData={scaledMetricData} />);
  }, []);

  const mouseOutEdge = useCallback((event: EventObjectEdge) => {
    changeCursorToDefault(event.cy.container());
    setTooltipComponent(undefined);
  }, []);

  const mouseOutNode = useCallback((event: EventObjectNode) => {
    changeCursorToDefault(event.cy.container());
    setTooltipComponent(undefined);
  }, []);

  const defaultNodes = useMemo(() => {
    return topologyElements?.nodes?.map(node => {
      return {
        ...node,
        data: {
          ...node.data,
          bgColor: whatIfTopologyColors.NodeDefaultBackground,
          borderColor: whatIfTopologyColors.NodeDefaultBorder,
          selectedBackgroundColor: whatIfTopologyColors.NodeSelectedBackground,
          selectedBorderColor: whatIfTopologyColors.NodeSelectedBorder,
        },
      };
    });
    // defaultNodes is being mutated somewhere else in the app. Adding selectedWhatIfMetrics
    // to the dependency array here is a temporary fix for the bug listed in SLC-1497
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topologyElements, selectedWhatIfMetric]);

  const defaultEdges = useMemo(() => {
    return topologyElements?.edges?.map(edge => {
      return {
        ...edge,
        data: {
          ...edge.data,
          lineColor: whatIfTopologyColors.EdgeDefault,
          selectedLineColor: whatIfTopologyColors.EdgeSelected,
          color: 'white',
        },
      };
    });
  }, [topologyElements]);

  const graphElements = useMemo<TopologyElements>(() => {
    return {
      nodes:
        selectedWhatIfMetric in InterfaceMetrics ? defaultNodes : formatNodes(topologyElements),
      edges: selectedWhatIfMetric in NodeMetrics ? defaultEdges : formatEdges(topologyElements),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    topologyElements,
    whatIfData,
    selectedWhatIfMetric,
    selectedWhatIfStat,
    whatIfSliderValue,
    whatIfThresholdLevel,
    defaultEdges,
    defaultNodes,
    formatNodes,
    formatEdges,
  ]);

  useEffect(() => {
    return () => {
      updateStateClickBackground();
    };
  }, [updateStateClickBackground]);

  if (isError) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading || !topologyElements) {
    return <LoadingSpinner />;
  }

  return (
    <TopologyBase
      mouseOverEdge={mouseOverEdge}
      mouseOutEdge={mouseOutEdge}
      mouseOverNode={mouseOverNode}
      mouseOutNode={mouseOutNode}
      onNodeClick={onNodeClick}
      tooltipComponent={tooltipComponent}
      onEdgeClick={onEdgeClick}
      clickBackground={clickBackground}
      selectedNodeIds={whatIfSelectedNodeData ? [whatIfSelectedNodeData.id] : []}
      selectedEdgeIds={
        whatIfSelectedEdgeData
          ? [`${whatIfSelectedEdgeData.targetIp}_${whatIfSelectedEdgeData.target_int_name}`]
          : []
      }
      graphElements={graphElements}
    />
  );
}
