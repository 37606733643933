import {ChartData, ChartDataset} from 'chart.js';
import {max} from 'lodash';
import {useMemo} from 'react';
import {UseQueryResult} from 'react-query';
import {useRecoilValue} from 'recoil';
import {timelineState} from '../../../../atoms/dashboard';
import {whatIfThresholdLevelState} from '../../../../atoms/whatIfThresholdLevel';
import WhatIfChart from '../WhatIfChart';
import {getRandomColors} from '../../../../utils/randomColor';
import {FormattedData} from './GetWhatIfChartData';

interface Props {
  query: UseQueryResult<any, unknown>;
  actualData: {[key: string]: FormattedData[]} | never[];
}

export default function ChartSetupTCAM({query, actualData}: Props) {
  const {selectedInterval} = useRecoilValue(timelineState);
  const whatIfThresholdLevel = useRecoilValue(whatIfThresholdLevelState);

  const color = useMemo(
    () =>
      getRandomColors({
        seed: 8,
        count: Object.keys(actualData).length,
        alpha: 1,
      }),
    [actualData]
  );

  const data: ChartData<'line'> = useMemo(() => {
    const datasets = [] as ChartDataset<any>[];

    Object.entries(actualData).forEach(([key, value], index) => {
      datasets.push({
        label: key,
        data: value,
        borderColor: color[index],
        backgroundColor: color[index],
        yAxisID: 'y',
        pointRadius: 0,
        hitRadius: 6,
        hoverRadius: 10,
        hoverBorderWidth: 2,
        pointHoverBackgroundColor: color[index],
        pointHoverBorderColor: 'white',
        borderJoinStyle: 'bevel',
      });
    });

    return {
      datasets,
    };
  }, [actualData, color]);

  const dataLength = Object.values(actualData)?.[0]?.length;

  const scaleSizes = {
    xmin:
      dataLength === 1 ? selectedInterval.start.toMillis() : Object.values(actualData)?.[0]?.[0]?.x,
    xmax:
      dataLength === 1
        ? selectedInterval.end.toMillis()
        : Object.values(actualData)?.[0]?.[dataLength - 1]?.x,
    ymax: Number(
      (
        (max(
          Object.values(actualData)
            .flatMap(metric => metric.map(element => element.y))
            .concat(whatIfThresholdLevel)
        ) as number) * 1.1
      ).toFixed()
    ),
  };

  const singlePoints =
    dataLength === 1
      ? Object.values(actualData).map((element, index) => {
          return {
            type: 'point',
            xValue: element[0].x,
            yValue: element[0].y,
            backgroundColor: color[index],
            radius: 4,
            borderWidth: 0,
          };
        })
      : [];

  return (
    <WhatIfChart query={query} data={data} scaleSizes={scaleSizes} singlePoints={singlePoints} />
  );
}
