import {UseQueryResult} from 'react-query';
import {HotlAnomaly, IntValue} from '../../../../openapi-schema/schemaTS';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import AssociatedAnomaliesTable from './components/AssociatedAnomaliesTable';
import {Container, ComponentTitleWrapper} from 'components/common/TableElements/TableElements';

interface Props {
  anomalyListQuery: (pageIndex?: number, limit?: number) => UseQueryResult<HotlAnomaly[], unknown>;
  anomalyCountQuery: () => UseQueryResult<IntValue, unknown>;
}

export default function AssociatedAnomalies({anomalyListQuery, anomalyCountQuery}: Props) {
  return (
    <Container>
      <ComponentTitleWrapper>
        <ComponentTitle title="Associated Observations" />
      </ComponentTitleWrapper>
      <AssociatedAnomaliesTable
        anomalyListQuery={anomalyListQuery}
        anomalyCountQuery={anomalyCountQuery}
      />
    </Container>
  );
}
