import IconComponent, {IconProps} from './IconComponent';

export default function WarningIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 16}} className={className}>
      <svg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="WarningIcon"
      >
        <path
          d="M8 1.5C7.14641 1.5 6.30117 1.66813 5.51256 1.99478C4.72394 2.32144 4.00739 2.80023 3.40381 3.40381C2.18482 4.62279 1.5 6.27609 1.5 8C1.5 9.72391 2.18482 11.3772 3.40381 12.5962C4.00739 13.1998 4.72394 13.6786 5.51256 14.0052C6.30117 14.3319 7.14641 14.5 8 14.5C9.72391 14.5 11.3772 13.8152 12.5962 12.5962C13.8152 11.3772 14.5 9.72391 14.5 8C14.5 7.14641 14.3319 6.30117 14.0052 5.51256C13.6786 4.72394 13.1998 4.00739 12.5962 3.40381C11.9926 2.80023 11.2761 2.32144 10.4874 1.99478C9.69882 1.66813 8.85359 1.5 8 1.5Z"
          fill="#CD3E38"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 4H7V9H9V4ZM9 10H7V12H9V10Z"
          fill="white"
        />
      </svg>
    </IconComponent>
  );
}
