import Tooltip from '../components/Katana/components/Tooltip/Tooltip';
import {ReactElement} from 'react';
import styled from 'styled-components';
import {
  ExchangeLogTemplate,
  HydratedLogElement,
  LogTemplate,
  WindowsLogTemplate,
} from '../openapi-schema/schemaTS';

const LogText = styled.div`
  white-space-collapse: preserve;
`;

interface VariableProps {
  observed: boolean;
}

const Variable = styled.span<VariableProps>`
  background: ${props => (props.observed ? 'rgb(255 20 20 / 40%)' : 'rgb(255 255 255 / 20%)')};
  line-height: 16px;

  &:hover {
    background: ${props => (props.observed ? 'rgb(255 0 0 / 60%)' : 'rgb(255 255 255 / 40%)')};
    text-decoration: underline;
  }
`;

const BlankSpan = styled.span``;

const InnerWrapper = styled.div`
  white-space: break-spaces;
`;

export interface FormattedLogTemplate
  extends Partial<LogTemplate>,
    Partial<ExchangeLogTemplate>,
    Partial<WindowsLogTemplate> {
  formatted_template_literal?: JSX.Element;
}

export type AnyLog = Pick<LogTemplate, 'hydrated' | 'observed'> | null | undefined;

export interface FormatLogOptions {
  highlightObserved?: boolean;
}

interface HydrateLogParams {
  log: AnyLog;
  options?: FormatLogOptions;
}

export const HydratedLog = ({
  log,
  options = {
    highlightObserved: true,
  },
}: HydrateLogParams) => {
  if (!log) return <LogText data-testid="logText"></LogText>;
  const {highlightObserved} = options;
  const components: ReactElement[] = [];

  log.hydrated?.elements?.forEach((element: HydratedLogElement, elementIndex: number) => {
    if (element.replaced) {
      components.push(
        <Tooltip
          tooltipContent={
            <p>
              {element.variables_key ||
                (element.template_variable_index !== undefined && '<<<$>>>') ||
                ''}
            </p>
          }
          mainContent={
            <Variable observed={Boolean(highlightObserved) && Boolean(log.observed)}>
              {element.value}
            </Variable>
          }
          key={`${element.value}-${elementIndex}`}
        />
      );
    } else {
      components.push(
        <BlankSpan key={`${element.value}-${elementIndex}`}>{element.value}</BlankSpan>
      );
    }
  });
  return (
    <LogText data-testid="logText">
      <InnerWrapper>{components}</InnerWrapper>
    </LogText>
  );
};

export const formatLog = (
  data: AnyLog[] | undefined,
  options: FormatLogOptions = {
    highlightObserved: true,
  }
) => {
  if (!data) return [];
  return data.map((row: AnyLog) => {
    return {
      ...row,
      formatted_template_literal: HydratedLog({log: row, options: options}),
    };
  });
};
