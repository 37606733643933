import {useState} from 'react';
import styled from 'styled-components';
import EditIcon from './components/EditIcon';
import {InputComponent} from './components/InputWrapper';

interface ContainerProps {
  maxWidth: number | undefined;
}

const StackedContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
  gap: 2px;
  color: white;
`;

const StackedLabelRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const StackedInputRow = styled.div`
  position: relative;
`;

interface ContainerProps {
  maxWidth: number | undefined;
}

const InlineContainer = styled.div<ContainerProps>`
  display: flex;
  padding: 4px 0;
  color: white;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
`;

interface InlineInputRowProps {
  inputOpen: boolean;
}

const InlineInputRow = styled.div<InlineInputRowProps>`
  flex: 1;
  position: relative;
  min-width: ${props => (props.inputOpen ? '100px' : 'auto')};
  margin-right: 20px;
  min-width: 0px;
`;

interface LabelProps {
  variant: 'inline' | 'stacked';
  inputOpen: boolean;
  labelWidth: number;
}

const Label = styled.p<LabelProps>`
  user-select: none;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-right: ${props => (props.variant === 'inline' ? '30px' : 0)};
  color: ${props => (props.inputOpen ? 'rgba(215, 153, 251, 1)' : 'rgba(255, 255, 255, 0.6)')};
  text-transform: uppercase;
  width: ${props => (props.variant === 'inline' ? `${props.labelWidth}px` : 'auto')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface DisplayTextProps {
  hide: boolean;
}

const DisplayText = styled.p<DisplayTextProps>`
  font-weight: 500;
  line-height: 16px;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  opacity: ${props => (props.hide ? 0 : 1)};
`;

const IconWrapper = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
`;

type InputFieldVariant = 'inline' | 'stacked';

interface TextInputFieldProps {
  label: string;
  inputValue: string;
  onSubmit: (input: string) => void;
  variant?: InputFieldVariant;
  labelWidth?: number;
  maxWidth?: number;
}

export default function TextInputField({
  inputValue,
  onSubmit,
  label,
  variant = 'stacked',
  labelWidth = 80,
  maxWidth,
}: TextInputFieldProps) {
  const [inputOpen, setInputOpen] = useState(false);

  if (variant === 'stacked') {
    return (
      <StackedContainer maxWidth={maxWidth}>
        <StackedLabelRow>
          <Label variant={variant} inputOpen={inputOpen} labelWidth={labelWidth}>
            {label}
          </Label>
          <IconWrapper onClick={() => setInputOpen(true)}>
            <EditIcon inputOpen={inputOpen} />
          </IconWrapper>
        </StackedLabelRow>
        <StackedInputRow onClick={() => setInputOpen(true)}>
          <DisplayText hide={inputOpen}>{inputValue || 'None'}</DisplayText>
          {inputOpen && (
            <InputComponent
              inputOpen={inputOpen}
              setInputOpen={setInputOpen}
              inputValue={inputValue}
              onSubmit={onSubmit}
            />
          )}
        </StackedInputRow>
      </StackedContainer>
    );
  }

  return (
    <InlineContainer maxWidth={maxWidth}>
      <Label variant={variant} inputOpen={inputOpen} labelWidth={labelWidth}>
        {label}
      </Label>
      <InlineInputRow inputOpen={inputOpen} onClick={() => setInputOpen(true)}>
        <DisplayText hide={inputOpen}>{inputValue || 'None'}</DisplayText>
        {inputOpen && (
          <InputComponent
            inputOpen={inputOpen}
            setInputOpen={setInputOpen}
            inputValue={inputValue}
            onSubmit={onSubmit}
          />
        )}
      </InlineInputRow>
      <IconWrapper onClick={() => setInputOpen(true)}>
        <EditIcon inputOpen={inputOpen} />
      </IconWrapper>
    </InlineContainer>
  );
}
