import ErrorState from 'components/Katana/components/ErrorState/ErrorState';
import {EventObjectEdge, EventObjectNode} from 'cytoscape';
import {flatMap, groupBy} from 'lodash';
import {ErPathTraceDataGraph, PathTraceDataGraph} from 'openapi-schema/schemaTS';
import {useCallback, useMemo, useState} from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TopologyBase from '../TopologyBase/TopologyBase';
import {getEdgeProperties, getNodeProperties} from './TopologyElementStyles';
import PathChangeEdgeTooltip from '../TopologyTooltips/PathChangeEdgeTooltip';
import PathChangeNodeTooltip from '../TopologyTooltips/PathChangeNodeTooltip';
import PathChangeLayout from '../CytoscapeViewer/layouts/defaults/PathChangeOptions';
import PathChangeStylesheet from '../CytoscapeViewer/layouts/defaults/PathChangeStylesheet';
import {UseQueryResult} from 'react-query';

interface Props {
  query:
    | UseQueryResult<ErPathTraceDataGraph, unknown>
    | UseQueryResult<PathTraceDataGraph, unknown>;
  startIcon?: string;
  endIcon?: string;
}

export default function TopologyPathTrace({query, startIcon, endIcon}: Props) {
  const {data: APIdata, isLoading, isError, refetch} = query;
  const [tooltipComponent, setTooltipComponent] = useState<JSX.Element | undefined>();

  const mouseOverNode = useCallback((event: EventObjectNode) => {
    event.target.addClass('node-hover');
    setTooltipComponent(<PathChangeNodeTooltip event={event} />);
  }, []);

  const mouseOutNode = useCallback((event: EventObjectNode) => {
    event.target.removeClass('node-hover');
    setTooltipComponent(undefined);
  }, []);

  const mouseOverEdge = useCallback((event: EventObjectEdge) => {
    event.target.addClass('edge-hover');
    setTooltipComponent(<PathChangeEdgeTooltip event={event} />);
  }, []);

  const mouseOutEdge = useCallback((event: EventObjectEdge) => {
    event.target.removeClass('edge-hover');
    setTooltipComponent(undefined);
  }, []);

  const mouseDownNode = useCallback((event: EventObjectNode) => {
    setTooltipComponent(undefined);
    const nodeId = event.target.data().merge_id;
    event.cy.edges().forEach(edge => {
      if (edge.data().source === nodeId || edge.data().target === nodeId) {
        edge.addClass('edge-hover');
      }
    });
  }, []);

  const mouseUpNode = useCallback((event: EventObjectNode) => {
    event.cy.edges().forEach(edge => {
      edge.removeClass('edge-hover');
    });
  }, []);

  const formatNodes = useCallback(
    (APIdata: PathTraceDataGraph | undefined) => {
      if (!APIdata) return [];

      const nodesList = APIdata.nodes;
      const groupedHopNumbers = groupBy(nodesList, 'hop_number');
      let columnShift = 0;
      const groupedNodes = Object.values(groupedHopNumbers).map(nodesByHop => {
        return nodesByHop.map((node, idx, arr) => {
          const rowShift = Math.floor(Number(arr.length) / 2);
          const nodeData = {
            data: {
              ...node,
              ...getNodeProperties(node, APIdata, startIcon, endIcon),
              id: node.merge_id,
              col: node.hop_number - columnShift,
              row: idx - rowShift,
            },
          };

          if (node.compressed) {
            columnShift = columnShift + node.compressed.end - node.compressed.start;
          }

          return nodeData;
        });
      });
      return flatMap(groupedNodes);
    },
    [startIcon, endIcon]
  );

  const formatEdges = useCallback((APIdata: PathTraceDataGraph | undefined) => {
    if (!APIdata) return [];

    const edgesList = APIdata.edges;
    return edgesList.map(edge => {
      return {
        data: {
          ...edge,
          ...getEdgeProperties(edge, APIdata),
          source: edge.source_merge_id,
          target: edge.target_merge_id,
          id: edge.merge_id,
        },
      };
    });
  }, []);

  const selectedEdgeIds = useMemo(() => {
    if (!APIdata) return [];
    return APIdata.edges.flatMap(edge => (edge.observed ? [edge.merge_id] : []));
  }, [APIdata]);

  const graphElements = useMemo(() => {
    return {
      nodes: formatNodes(APIdata),
      edges: formatEdges(APIdata),
    };
  }, [APIdata, formatEdges, formatNodes]);

  if (isError) {
    return <ErrorState retry={refetch} />;
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <TopologyBase
      mouseOverNode={mouseOverNode}
      mouseOutNode={mouseOutNode}
      mouseOverEdge={mouseOverEdge}
      mouseOutEdge={mouseOutEdge}
      mouseDownNode={mouseDownNode}
      mouseUpNode={mouseUpNode}
      graphElements={graphElements}
      selectedNodeIds={[]}
      selectedEdgeIds={selectedEdgeIds}
      layout={PathChangeLayout}
      stylesheet={PathChangeStylesheet}
      tooltipComponent={tooltipComponent}
    />
  );
}
