import ReactWordcloud, {Word} from 'react-wordcloud';
import {useRecoilState} from 'recoil';
import styled from 'styled-components';
import {selectedWordCloudWordsState} from '../../../../../atoms/selectedWordCloudWords';
import {updateSelectedWordCloudWords} from '../utils/updateSelectedWordCloudWords';
// CSS for the wordcloud tooltips
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import {useEffect, useRef} from 'react';
import {WordcloudItemTemplates} from '../../../../../openapi-schema/schemaTS';

const CloudWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 10px 10px 10px;
  // Prevents wordcloud from becoming too small and crashing the app
  min-width: 50px;
  min-height: 50px;
  overflow: auto;

  text {
    outline: none;
  }
`;

const colors = ['rgba(210, 0, 0, 1)', 'rgba(226, 0, 95, 1)', 'rgba(255, 104, 167, 1)'];

interface Props {
  data: WordcloudItemTemplates[];
}

export default function Cloud({data}: Props) {
  const [selectedWordCloudWords, setSelectedWordCloudWords] = useRecoilState(
    selectedWordCloudWordsState
  );
  // Callbacks for the wordcloud must use refs
  const wordRef = useRef(selectedWordCloudWords);

  useEffect(() => {
    wordRef.current = selectedWordCloudWords;
  }, [selectedWordCloudWords]);

  const callbacks = {
    getWordColor: (wordData: Word) => {
      if (wordRef.current.some(word => word === wordData.text)) {
        return 'white';
      }
      const colorIndex = data.findIndex(word => word.text === wordData.text) % colors.length;
      return colors[colorIndex];
    },
    onWordClick: (word: Word) => {
      const updatedWordList = updateSelectedWordCloudWords(wordRef.current, word.text);
      setSelectedWordCloudWords(updatedWordList);
    },
    // TODO - add tooltip if we can match the CSS with the rest of the app
    // getWordTooltip: word => `${word.value} occurances.`,
  };
  const options = {
    rotations: 0,
    fontSizes: [20, 50] as [number, number],
    transitionDuration: 200,
    padding: 10,
    enableOptimizations: true,
    deterministic: true,
    enableTooltip: false,
    fontFamily: 'Roboto',
    fontWeight: '700',
  };

  return (
    <CloudWrapper>
      <ReactWordcloud words={data} options={options} callbacks={callbacks} />
    </CloudWrapper>
  );
}
