import {useState} from 'react';
import Slider from '@mui/material/Slider';
import {RecoilState, useRecoilState} from 'recoil';
import {
  Container,
  SliderWrapper,
  InputWrapper,
  Input,
  Sign,
  Percentage,
  SliderTitle,
} from './SliderComponents';

interface data {
  marks: {value: number; label: string}[];
  maxValue: number;
  atomState: RecoilState<number>;
  title: string;
  step: number;
  showSign: boolean;
}

interface Props {
  data: data;
}

export default function UtilizationSlider({data}: Props) {
  const [sliderValue, setSliderValue] = useRecoilState(data.atomState);
  const [inputValue, setInputValue] = useState<string>(sliderValue.toString());

  const handleSliderChange = (event: Event) => {
    setSliderValue(parseInt((event.target as HTMLInputElement).value));
    setInputValue((event.target as HTMLInputElement).value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue((event.target as HTMLInputElement).value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/\d|Enter|Backspace/.test(event.key)) {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  const handleBlur = () => {
    if (Number(inputValue) < 0) {
      setSliderValue(Number(0));
      setInputValue('0');
    } else if (Number(inputValue) > data.maxValue) {
      setSliderValue(data.maxValue);
      setInputValue(data.maxValue.toString());
    } else {
      setSliderValue(Number(inputValue));
    }
  };

  return (
    <Container>
      <SliderTitle>{data.title}</SliderTitle>
      <SliderWrapper>
        <Slider
          value={sliderValue}
          step={data.step}
          marks={data.marks}
          min={0}
          max={data.maxValue}
          onChange={handleSliderChange}
        />
      </SliderWrapper>
      <InputWrapper>
        <Sign>{data.showSign ? '+' : ''}</Sign>
        <Input
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      </InputWrapper>
      <Percentage>%</Percentage>
    </Container>
  );
}
