import {deviceInformationOpenState} from 'atoms/deviceInformationOpen';
import {useRecoilValue} from 'recoil';
import DeviceInformation from './components/DeviceInformation/DeviceInformation';
import {HardwareCatalog} from './components/HardwareCatalog';

export default function HardwareCatalogPage() {
  const deviceInformationOpen = useRecoilValue(deviceInformationOpenState);

  return deviceInformationOpen ? <DeviceInformation /> : <HardwareCatalog />;
}
