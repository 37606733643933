import calculateTimelineInterval from '../../utils/calculateTimelineInterval';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {timelineState, rangeTimelineState} from '../../atoms/dashboard';
import {useSearchParams} from 'react-router-dom';
import {useEffect, useRef} from 'react';
import useNewUrlTime from './useNewUrlTime';
import {overviewTimelineState} from '../../atoms/overviewTimeline';
import {Interval, DateTime} from 'luxon';
import config from '../../utils/AppConfigurationService';
import {getValidTimestamp} from '../../utils/getValidTimestamp';

const useURLSynchronizedTimeline = () => {
  const [timeline, setTimeline] = useRecoilState(timelineState);
  const setRangeTimeline = useSetRecoilState(rangeTimelineState);
  const [searchParams] = useSearchParams();
  const setNewUrlTime = useNewUrlTime();
  const setOverviewTimeline = useSetRecoilState(overviewTimelineState);

  const startRef = useRef<string | null>('');
  const endRef = useRef<string | null>('');
  const selectedIntervalRef = useRef(timeline.selectedInterval);
  startRef.current = searchParams.get('start');
  endRef.current = searchParams.get('end');
  selectedIntervalRef.current = timeline.selectedInterval;

  useEffect(() => {
    let start = startRef.current || selectedIntervalRef.current.start.toISO();
    let end = endRef.current || selectedIntervalRef.current.end.toISO();

    start = start.slice(0, 10) + start.slice(10, 23).replaceAll('-', ':') + start.slice(23);
    end = end.slice(0, 10) + end.slice(10, 23).replaceAll('-', ':') + end.slice(23);

    if (isNaN(new Date(start).getTime())) {
      start = getValidTimestamp(start, selectedIntervalRef.current.start.toISO());
    }
    if (isNaN(new Date(end).getTime())) {
      end = getValidTimestamp(end, selectedIntervalRef.current.end.toISO());
    }

    setNewUrlTime(start, end);

    const startTime = DateTime.fromISO(start);
    const endTime = DateTime.fromISO(end);

    if (startTime >= endTime) {
      const newTimeline = {
        selectedInterval: config.defaultTimelineWindow.selectedInterval,
        timelineInterval: calculateTimelineInterval(
          config.defaultTimelineWindow.selectedInterval.start,
          config.defaultTimelineWindow.selectedInterval.end
        ),
      };
      setRangeTimeline(newTimeline);
      setTimeline(newTimeline);
      setOverviewTimeline(newTimeline);
      setNewUrlTime(
        config.defaultTimelineWindow.selectedInterval.start.toISO(),
        config.defaultTimelineWindow.selectedInterval.end.toISO()
      );
      return;
    }

    const newTimelineInterval = calculateTimelineInterval(startTime, endTime);
    const newTimeline = {
      selectedInterval: Interval.fromDateTimes(startTime, endTime),
      timelineInterval: newTimelineInterval,
    };
    setRangeTimeline(newTimeline);
    setTimeline(newTimeline);
    setOverviewTimeline(newTimeline);
  }, [setNewUrlTime, setOverviewTimeline, setRangeTimeline, setTimeline]);
};

export default useURLSynchronizedTimeline;
