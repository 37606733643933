import IconComponent, {IconProps} from '../IconComponent';

export default function InterfaceIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 14}} className={className}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="InterfaceIcon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 4C13 5.65685 11.6569 7 10 7C9.53689 7 9.0983 6.89507 8.70669 6.70768L6.70768 8.70669C6.89507 9.0983 7 9.53689 7 10C7 11.6569 5.65685 13 4 13C2.34315 13 1 11.6569 1 10C1 8.34315 2.34315 7 4 7C4.46319 7 4.90186 7.10497 5.29352 7.29242L7.29242 5.29352C7.10497 4.90186 7 4.46319 7 4C7 2.34315 8.34315 1 10 1C11.6569 1 13 2.34315 13 4ZM4 11C4.55228 11 5 10.5523 5 10C5 9.44772 4.55228 9 4 9C3.44772 9 3 9.44772 3 10C3 10.5523 3.44772 11 4 11Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
