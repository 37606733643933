import IconComponent, {IconProps} from './IconComponent';

export default function ColumnToggleIcon({className}: IconProps) {
  return (
    <IconComponent sx={{color: 'white', fontSize: 20}} className={className}>
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="ColumnToggleIcon"
      >
        <path
          d="M4 4H9.33333V5.33333H4V4ZM10.6667 4H16V5.33333H10.6667V4ZM4 6.66667H9.33333V8H4V6.66667ZM10.6667 6.66667H16V8H10.6667V6.66667ZM4 9.33333H9.33333V10.6667H4V9.33333ZM10.6667 9.33333H16V10.6667H10.6667V9.33333ZM4 12H9.33333V13.3333H4V12ZM10.6667 12H16V13.3333H10.6667V12ZM4 14.6667H9.33333V16H4V14.6667ZM10.6667 14.6667H16V16H10.6667V14.6667Z"
          fill="currentColor"
        />
      </svg>
    </IconComponent>
  );
}
