import styled from 'styled-components';
import GoldenLayoutType, {GoldenLayoutComponent} from '@annotationhub/react-golden-layout';
import '@annotationhub/react-golden-layout/dist/css/goldenlayout-base.css';
import '@annotationhub/react-golden-layout/dist/css/themes/goldenlayout-dark-theme.css';
import './goldenLayoutStyleOverrides.css';
import Fallback from '../Fallback/Fallback';
import {FallbackProps} from 'react-error-boundary';

interface ContainerProps {
  $background?: string;
  $width?: string;
  $padding?: string;
}

const Container = styled.div<ContainerProps>`
  width: ${props => props.$width || '100%'};
  height: 100%;
  padding: ${props => props.$padding || '10px'};
  background: ${props => props.$background || 'rgba(34, 36, 41, 1)'};
  overflow: hidden;

  .lm_item {
    background: ${props => props.$background || 'rgba(34, 36, 41, 1)'};
  }
`;

type ItemConfigTypeWithFallback = (
  | ItemConfigWithFallback
  | GoldenLayoutType.ComponentConfig
  | GoldenLayoutType.ReactComponentConfig
) & {
  fallback?: React.ComponentType<FallbackProps>;
};

interface ItemConfigWithFallback extends GoldenLayoutType.ItemConfig {
  content?: ItemConfigTypeWithFallback[];
}

interface Props {
  content: ItemConfigTypeWithFallback[] | undefined;
  background?: string;
  width?: string;
  padding?: string;
}

const withFallback = (
  content: ItemConfigTypeWithFallback[] | undefined
): GoldenLayoutType.ItemConfigType[] | undefined => {
  if (!content) return content;

  for (const item of content) {
    if ('component' in item && item.component) {
      const Child = item.component;
      item.component = () => (
        <Fallback fallbackComponent={item.fallback}>
          <Child />
        </Fallback>
      );
    }
    if (item.content) {
      item.content = withFallback(item.content);
    }
  }
  return content;
};

export default function GoldenLayout({content, background, width, padding}: Props) {
  const contentWithFallback = withFallback(content);
  return (
    <Container $width={width} $background={background} $padding={padding}>
      <GoldenLayoutComponent
        config={{
          dimensions: {
            borderWidth: 10,
          },
          settings: {
            hasHeaders: false,
            constrainDragToContainer: true,
            reorderEnabled: false,
            selectionEnabled: false,
            popoutWholeStack: false,
            blockedPopoutsThrowError: true,
            closePopoutsOnUnload: true,
            showPopoutIcon: false,
            showMaximiseIcon: false,
            showCloseIcon: false,
          },
          content: contentWithFallback,
        }}
        autoresize={true}
        debounceResize={100}
      />
    </Container>
  );
}
