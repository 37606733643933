import {useMemo} from 'react';
import styled from 'styled-components';
import {Line} from 'react-chartjs-2';
import {useAnomalyCountFill} from '../../../../data/Anomalies';
import {timelineState} from '../../../../atoms/dashboard';
import {useRecoilValue} from 'recoil';
import {DateTime} from 'luxon';
import {binnedTimestampFormat} from '../../../../constants/timeFormats';
import {
  chartHorizontalAxisOptions,
  chartVerticalAxisOptions,
  coreChartOptions,
} from '../../../../constants/chartOptions';
import {max} from 'lodash';
import {BinnedCount} from '../../../../openapi-schema/schemaTS';
import {ChartOptions} from 'chart.js';

const Container = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  top: -100%;
  z-index: 3;
  pointer-events: none;
`;

export default function Histogram() {
  const {data: APIdata} = useAnomalyCountFill();
  const {timelineInterval} = useRecoilValue(timelineState);

  const tableData = useMemo(() => {
    if (APIdata?.length) {
      return APIdata.map((element: BinnedCount) => {
        return {
          x: DateTime.fromFormat(element.bin_key, binnedTimestampFormat, {zone: 'UTC'}).toMillis(),
          y: element.value,
        };
      });
    } else {
      return [
        {x: timelineInterval.start.toMillis(), y: 0},
        {x: timelineInterval.end.toMillis(), y: 0},
      ];
    }
  }, [APIdata, timelineInterval]);

  const largestDataPoint = (max(tableData?.map((element: {x: number; y: number}) => element?.y)) ||
    1) as number;

  const options: ChartOptions<'line'> = {
    elements: {
      line: {
        tension: 0.3,
      },
    },
    ...coreChartOptions,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        ...chartVerticalAxisOptions,
        max: largestDataPoint * 1.1,
        display: false,
      },
      x: {
        ...chartHorizontalAxisOptions,
        min: timelineInterval.start.toMillis(),
        max: timelineInterval.end.toMillis(),
        display: false,
      },
    },
  };

  const data = {
    datasets: [
      {
        data: tableData,
        borderColor: 'rgba(255, 255, 255, 0.6)',
        borderWidth: 2,
        yAxisID: 'y',
        pointRadius: 0,
        hitRadius: 0,
      },
    ],
  };

  return (
    <Container>
      <Line options={options} data={data} />
    </Container>
  );
}
