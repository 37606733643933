import EditIconMUI from '@mui/icons-material/Edit';

interface Props {
  inputOpen: boolean;
}

export default function EditIcon({inputOpen}: Props) {
  return (
    <EditIconMUI
      sx={{
        fontSize: '14px',
        opacity: inputOpen ? '1' : '0.4',
        color: inputOpen ? 'rgba(215, 153, 251, 1)' : 'white',
        '&:hover': {opacity: '1'},
      }}
    />
  );
}
