import {Select as MuiSelect, SelectChangeEvent} from '@mui/material';
import MenuItem from '../MenuItem/MenuItem';
import {useEffect, useState} from 'react';
import classNames from 'classnames';

const baseClasses = 'rounded-full [&>svg]:text-[20px] [&>svg]:right-2 [&>fieldset]:rounded-full';
const lightClasses =
  'bg-light-surface [&>fieldset]:border-light-outline [&>svg]:text-light-primary';
const darkClasses =
  'dark:bg-dark-surface-container-lowest [&>fieldset]:dark:border-dark-outline [&>svg]:dark:text-dark-primary';
const disabledClasses = 'opacity-40';
const fullWidthClasses = 'w-full';

interface Props {
  options: string[];
  onChange: (value: string, prevValue: string) => void;
  initialSelectedValue?: string;
  disabled?: boolean;
  fullWidth?: boolean;
}

export default function Select({
  options,
  onChange,
  initialSelectedValue,
  disabled = false,
  fullWidth = false,
}: Props) {
  const [value, setValue] = useState(initialSelectedValue ?? options[0]);

  useEffect(() => {
    setValue(initialSelectedValue ?? options[0]);
  }, [options, initialSelectedValue]);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    onChange(event.target.value as string, value);
  };

  return (
    <MuiSelect
      className={classNames(baseClasses, lightClasses, darkClasses, {
        [disabledClasses]: disabled,
        [fullWidthClasses]: fullWidth,
      })}
      sx={{'.MuiSelect-select.Mui-disabled': {WebkitTextFillColor: 'inherit'}}}
      SelectDisplayProps={{
        className:
          'text-label-large text-light-on-surface dark:text-dark-on-surface py-2 pl-4 pr-9',
      }}
      MenuProps={{
        MenuListProps: {
          className: 'bg-light-inverse-on-surface dark:bg-dark-surface-container',
        },
      }}
      onChange={handleChange}
      value={value}
      disabled={disabled}
    >
      {options.map(option => {
        return (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        );
      })}
    </MuiSelect>
  );
}
