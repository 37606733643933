import {useMutation, useQueryClient} from 'react-query';
import {useSetRecoilState} from 'recoil';
import {snackbarDataState} from '../../atoms/snackbarData';
import {postUserFeedback} from '../../utils/PythonApiUtils';
import {BaseUserFeedback, UserFeedback} from '../../openapi-schema/schemaTS';
import {useCallback} from 'react';

function useUserFeedbackMutate() {
  const queryClient = useQueryClient();
  const setSnackbarData = useSetRecoilState(snackbarDataState);

  interface MutationData {
    payload: BaseUserFeedback;
    showSnackbar: boolean;
  }

  return useMutation(
    (data: MutationData): Promise<UserFeedback | any> => postUserFeedback({...data.payload}),
    {
      onSuccess: (updatedFeedback: UserFeedback, variables: MutationData) => {
        queryClient.setQueryData(['anomaly', updatedFeedback.anomaly_id], (oldAnomaly: any) => {
          return {
            ...oldAnomaly,
            feedback: updatedFeedback,
            category: updatedFeedback?.category || oldAnomaly.category,
            priority_classification:
              updatedFeedback?.priority_classification || oldAnomaly.priority_classification,
          };
        });
        queryClient.setQueriesData('anomalyList', (oldList: any) => {
          if (!oldList) return oldList;
          // The query cache (oldList) is READ-only
          const idx = oldList.findIndex(
            (anomaly: any) => anomaly.anomaly_id === updatedFeedback?.anomaly_id
          );
          if (idx > -1) {
            const anomaly = {
              ...oldList[idx],
              feedback: updatedFeedback,
              category: updatedFeedback?.category || oldList[idx].category,
              priority_classification:
                updatedFeedback?.priority_classification || oldList[idx].priority_classification,
            };
            return [...oldList.slice(0, idx), anomaly, ...oldList.slice(idx + 1)];
          }
          return [...oldList];
        });
        if (variables.showSnackbar) {
          setSnackbarData({
            severity: 'success',
            message: 'Update Successful',
            open: true,
          });
        }
      },
      onError: error => {
        setSnackbarData({
          severity: 'error',
          message: 'Update Failed',
          open: true,
        });
      },
    }
  );
}

const useSaveFeedback = () => {
  const {mutate} = useUserFeedbackMutate();

  return useCallback(
    (feedbackUpdate: BaseUserFeedback, showSnackbar: boolean) => {
      mutate({
        payload: feedbackUpdate,
        showSnackbar,
      });
    },
    [mutate]
  );
};

export default useSaveFeedback;
