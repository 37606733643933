import Tooltip from '../../../../Katana/components/Tooltip/Tooltip';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {
  ScriptVariableDisplayName,
  scriptVariableDisplayState,
  ScriptVariableDisplayValue,
} from '../../../../../atoms/scriptVariableDisplay';
import {usedAliasesState} from '../../../../../atoms/usedAliases';
import useARActions from '../../../../../helpers/hooks/useARActions';
import {useState} from 'react';
import {LinkIcon, LinkOffIcon, ScriptVariableUnusedIcon} from 'components/Katana/Icons';

interface VariableUsage {
  assigned: boolean;
}

export const Container = styled.div<VariableUsage>`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 2px;
  background: ${props => (props.assigned ? 'rgba(120, 83, 203, 1)' : 'rgba(205, 62, 56, 1)')};
  gap: 4px;
  margin: 0 2px;
  height: 20px;
`;

const Text = styled.p``;

interface Props {
  children: string;
}

export default function ScriptVariable({children: variableName}: Props) {
  const [linkIcon, setLinkIcon] = useState<'link' | 'linkOff'>('link');
  const usedAliases = useRecoilValue(usedAliasesState);
  const scriptVariableDisplay = useRecoilValue(scriptVariableDisplayState);
  const {removeAliasFromUsedAliasList} = useARActions();

  const assigned = variableName in usedAliases;

  const getScriptVariableText = (variableName: string) => {
    if (scriptVariableDisplay === ScriptVariableDisplayName) return variableName;
    return usedAliases[variableName]?.example_value ?? variableName;
  };

  const getScriptVariableTooltipText = (variableName: string) => {
    if (!(variableName in usedAliases)) return 'Variable is unassigned';
    if (scriptVariableDisplay === ScriptVariableDisplayValue) return `Script name: ${variableName}`;
    return (
      `Example value: ${usedAliases[variableName]?.example_value}` ?? `Script name: ${variableName}`
    );
  };

  const handleClick = () => {
    if (!assigned) return;
    removeAliasFromUsedAliasList(variableName);
    setLinkIcon('link');
  };

  return (
    <Tooltip
      tooltipContent={<p>{getScriptVariableTooltipText(variableName)}</p>}
      mainContent={
        <Container assigned={assigned}>
          <Text>{getScriptVariableText(variableName)}</Text>
          <div
            onClick={handleClick}
            onMouseEnter={e => {
              if (assigned) setLinkIcon('linkOff');
            }}
            onMouseLeave={e => {
              if (assigned) setLinkIcon('link');
            }}
            className="flex items-center"
          >
            {!assigned && <ScriptVariableUnusedIcon className="text-[16px]" />}
            {assigned && linkIcon === 'link' && <LinkIcon className="cursor-pointer text-[16px]" />}
            {assigned && linkIcon === 'linkOff' && (
              <LinkOffIcon className="cursor-pointer text-[16px]" />
            )}
          </div>
        </Container>
      }
    />
  );
}
