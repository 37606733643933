import SectionGroup, {ButtonDataFilter} from './SectionGroup';
import config from '../../../../utils/AppConfigurationService';
import {
  CountPercentDistribution,
  PriorityClassificationBucket,
} from '../../../../openapi-schema/schemaTS';
import {startCase} from 'lodash';
import {defaultSelectedReportingKPI} from 'atoms/selectedReportingKPI';
import ObservationPriorityIcon from 'components/common/ObservationPriorityIcon/ObservationPriorityIcon';

interface Props {
  data: CountPercentDistribution[] | undefined;
  anomalyCount: number | undefined;
}

export default function PriorityGroup({data, anomalyCount}: Props) {
  const dataByBucket = data?.reduce((acc, currVal) => {
    return {...acc, [currVal.bucket]: currVal.count};
  }, {} as {[key in PriorityClassificationBucket]: number});

  const allPrioritiesData = {...defaultSelectedReportingKPI, value: anomalyCount};

  const filteredPriorityData = Object.keys(dataByBucket || {}).filter(
    priority => priority !== config.Priority.UNDEFINED
  );

  const prioritiesData = filteredPriorityData.map(priority => {
    return {
      text: priority,
      filter: {
        priority_types: [priority],
      } as ButtonDataFilter,
      icon: (
        <ObservationPriorityIcon
          priority={priority as PriorityClassificationBucket}
          color
          className="text-[15px]"
        />
      ),
      title: `${startCase(priority)} Priority Observations`,
      value: dataByBucket?.[priority],
    };
  });

  const buttonGroupData = [allPrioritiesData, ...prioritiesData];

  return <SectionGroup data={buttonGroupData} title={'Priority'} />;
}
