import {WselFlaggedUser} from '../../../../openapi-schema/schemaTS';
import {TableData, TableRow as Container} from '../../../common/TableElements/TableElements';
import {useState} from 'react';
import ManageUserModal from './ManageUserModal';
import {useSetUserFlag} from '../../../../data/Security';
import {Row, flexRender} from '@tanstack/react-table';
import {getCellStyles} from 'components/common/TableElements/utils/getCellStyles';

interface Props {
  row: Row<WselFlaggedUser>;
}

export default function TableRow({row}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const setUserFlag = useSetUserFlag();

  const onClick = (row: Row<WselFlaggedUser>) => {
    if (dialogOpen) return;
    setDialogOpen(true);
    if (row.getIsSelected()) return;
    row.toggleSelected(true);
  };

  const unflagUser = () => {
    setUserFlag({
      payload: {
        user_sid: row.original.user_sid,
        flagged: false,
      },
      showSnackbar: true,
      index: row.index,
    });
    closeDialog();
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Container onClick={() => onClick(row)} isRead={true} isSelected={row.getIsSelected()}>
      {row.getVisibleCells().map(cell => (
        <TableData key={cell.id} style={{...getCellStyles(cell)}}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableData>
      ))}
      <ManageUserModal
        isOpen={dialogOpen}
        close={closeDialog}
        user={row.original}
        action={unflagUser}
      />
    </Container>
  );
}
