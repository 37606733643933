import {VtcTableArrow} from 'constants/imageLinks';
import {useVTCKPIs} from 'data/KPI';
import {useVTCAnomalyData} from 'data/VTC';
import styled from 'styled-components';

interface ContainerProps {
  showLeftEdge?: boolean;
}

const Container = styled.div<ContainerProps>`
  text-transform: none;
  font-weight: 400;
  width: 100%;
  padding: 0 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  border-left: ${props => (props.showLeftEdge ? '1px solid rgb(73, 15, 106)' : 'none')};
`;

const MetricTitle = styled.p`
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
`;

const MetricTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Arrow = styled.img`
  position: relative;
  right: 22px;
`;

const LatencyText = styled.p`
  font-size: 26px;
  line-height: 33px;
`;

const HostInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const HostInfoText = styled.p`
  font-size: 14px;
  line-height: 16px;
`;

export function RouteLatency() {
  const {data: VtcKpiData} = useVTCKPIs();
  const ping = VtcKpiData?.caller_to_callee_ping.ping_avg_ms;

  return (
    <Container>
      <MetricTitle>Route Latency</MetricTitle>
      <LatencyText>{!VtcKpiData || ping === -1 ? '—' : `${ping?.toFixed(2)} ms`}</LatencyText>
    </Container>
  );
}

export function CallerMetrics() {
  const {data} = useVTCAnomalyData();

  return (
    <Container showLeftEdge>
      <MetricTitleWrapper>
        <MetricTitle>Origin</MetricTitle>
        <Arrow src={VtcTableArrow} />
      </MetricTitleWrapper>
      <HostInfoWrapper>
        <HostInfoText>{data?.caller_host}</HostInfoText>
        <HostInfoText>{data?.caller_ip_address}</HostInfoText>
      </HostInfoWrapper>
    </Container>
  );
}

export function CalleeMetrics() {
  const {data} = useVTCAnomalyData();

  return (
    <Container showLeftEdge>
      <MetricTitle>Destination</MetricTitle>
      <HostInfoWrapper>
        <HostInfoText>{data?.callee_host}</HostInfoText>
        <HostInfoText>{data?.callee_ip_address}</HostInfoText>
      </HostInfoWrapper>
    </Container>
  );
}
