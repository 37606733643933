import {useRecoilValue} from 'recoil';
import {timelineState} from '../atoms/dashboard';
import {useQuery} from 'react-query';
import {
  getHistoricalUtilizationMetricsByAnomalyId,
  getUtilizationMetricsByAnomalyId,
  getRelatedAnomaliesUtilization,
  getLogTemplatesUtilization,
  getUtilizationMetricsByAnomalyIdCount,
  getRelatedAnomaliesUtilizationCount,
  getLogTemplatesUtilizationCount,
  getAnomaliesByDatacenterId,
} from '../utils/PythonApiUtils';
import config from '../utils/AppConfigurationService';
import {selectedAnomalyDataState, selectedAnomalyIdState} from '../atoms/selectedAnomaly';
import {selectedDataCenterId} from '../atoms/selectedDatacenter';
import {associatedAnomaliesSortingState} from '../atoms/associatedAnomaliesSorting';
import {logTableSortingState} from '../atoms/logTableSorting';
import useDebouncedInput from '../helpers/hooks/useDebouncedInput';
import {AnomalyTypeBucket} from '../openapi-schema/schemaTS';
import {LogListQuery, SortedPagedQuery} from './types';

export const useSelectedAnomalyUtilizationMetrics: SortedPagedQuery = (
  pageIndex = 0,
  limit = config.apiResultLimit,
  sortFields = []
) => {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'utilizationMetricsByAnomalyId',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      sortFields,
    ],
    () =>
      getUtilizationMetricsByAnomalyId({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset,
        limit,
        sort_fields: sortFields,
      })
  );
};

export function useSelectedAnomalyUtilizationMetricsCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['utilizationMetricsByAnomalyIdCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getUtilizationMetricsByAnomalyIdCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useSelectedAnomalyPerformanceMetrics() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['utilizationPerformanceMetricsByAnomalyId', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getHistoricalUtilizationMetricsByAnomalyId({
        start: start.minus({minutes: 5}).setZone('UTC').toISO(),
        end: end.plus({minutes: 5}).setZone('UTC').toISO(),
        anomaly_id: selectedAnomalyId,
        total_bins: 100,
      })
  );
}

export function useSelectedAnomalyRelatedAnomaliesUtilization(
  pageIndex = 0,
  limit = config.apiResultLimit
) {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const associatedAnomaliesSorting = useRecoilValue(associatedAnomaliesSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'relatedAnomaliesUtilization',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      associatedAnomaliesSorting,
    ],
    () =>
      getRelatedAnomaliesUtilization({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: associatedAnomaliesSorting,
      })
  );
}
export function useSelectedAnomalyRelatedAnomaliesUtilizationCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['relatedAnomaliesUtilizationCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getRelatedAnomaliesUtilizationCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export const useLogTemplatesUtilization: LogListQuery = (
  pageIndex = 0,
  limit = config.apiResultLimit
) => {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const sortFields = useRecoilValue(logTableSortingState);
  const offset = pageIndex * limit;
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'logTemplatesUtilization',
      selectedAnomalyId,
      debouncedStart,
      debouncedEnd,
      offset,
      limit,
      sortFields,
    ],
    () =>
      getLogTemplatesUtilization({
        anomaly_id: selectedAnomalyId,
        offset: offset,
        limit: limit,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        sort_fields: sortFields,
      })
  );
};

export function useLogTemplatesUtilizationCount() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['logTemplatesUtilizationCount', selectedAnomalyId, debouncedStart, debouncedEnd],
    () =>
      getLogTemplatesUtilizationCount({
        anomaly_id: selectedAnomalyId,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useUtilizationChartAnomalies() {
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const DCId = useRecoilValue(selectedDataCenterId);
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);
  const affected_resource = selectedAnomalyData?.affected_resources?.[0];
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['utilizationChartAnomalies', debouncedStart, debouncedEnd, affected_resource],
    () =>
      getAnomaliesByDatacenterId({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_types: [AnomalyTypeBucket.Interface_Utilization],
        dc_id: DCId,
        affected_resources: affected_resource ? [affected_resource] : [],
        priority_types: [
          config.Priority.EXTREME,
          config.Priority.HIGH,
          config.Priority.MEDIUM,
          config.Priority.LOW,
        ],
        classification_types: [config.Class.ANOMALY, config.Class.ISSUE],
      })
  );
}
