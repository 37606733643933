import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  column-gap: 16px;
  row-gap: 8px;
  padding: 8px 10px 10px 10px;
  color: white;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ItemGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
`;

const PathChangeSymbol = styled.div`
  min-width: 16px;
  min-height: 16px;
  background: rgba(205, 62, 56, 0.35);
  /* border: 1px solid rgba(205, 62, 56, 1); */
`;

const ObservedDelaySymbol = styled.div`
  min-width: 10px;
  min-height: 10px;
  border-radius: 100%;
  background: rgba(24, 160, 251, 1);
`;

const TypicalDelaySymbol = styled.div`
  min-width: 10px;
  min-height: 10px;
  border-radius: 100%;
  background: rgba(174, 51, 247, 1);
  /* border-bottom: 3px dashed rgba(174, 51, 247, 1); */
`;

const legendItems = [
  {text: 'Path Change', symbol: <PathChangeSymbol />},
  {text: 'Observed Delay', symbol: <ObservedDelaySymbol />},
  {text: 'Typical Delay', symbol: <TypicalDelaySymbol />},
];

export default function PathDelayLegend() {
  return (
    <Container>
      {legendItems.map(item => (
        <ItemGroup key={item.text}>
          {item.symbol}
          <Label>{item.text}</Label>
        </ItemGroup>
      ))}
    </Container>
  );
}
