import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import ErrorState from '../../Katana/components/ErrorState/ErrorState';
import {sendErrorTrace} from '../../../tracing/errorReporting';

interface Props {
  children: JSX.Element;
  fallbackComponent?: React.ComponentType<FallbackProps>;
}

export function DefaultFallback({error, resetErrorBoundary}: FallbackProps) {
  return <ErrorState retry={resetErrorBoundary} />;
}

export default function Fallback({children, fallbackComponent = DefaultFallback}: Props) {
  return (
    <ErrorBoundary onError={sendErrorTrace} FallbackComponent={fallbackComponent}>
      {children}
    </ErrorBoundary>
  );
}
