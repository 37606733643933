import GoldenLayout from 'components/common/GoldenLayout/GoldenLayout';
import AnomalyDetailKPI from '../common/AnomalyDetailKPI/AnomalyDetailKPI';
import GetLogTableComponent from '../Log/GetLogTableComponent/GetLogTableComponent';
import WordCloudComponent from '../Log/WordCloud/WordCloudComponent';
import AssociatedAnomaliesExchangeLog from './AssociatedAnomaliesExchangeLog';

export default function ExchangeLogDetailDashboard() {
  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              component: () => <GetLogTableComponent />,
              width: 74,
            },
            {
              type: 'column',
              width: 26,
              content: [
                {
                  component: () => <AnomalyDetailKPI />,
                  height: 25,
                },
                {
                  component: () => <WordCloudComponent />,
                  height: 37.5,
                },
                {
                  component: () => <AssociatedAnomaliesExchangeLog />,
                  height: 37.5,
                },
              ],
            },
          ],
        },
      ]}
      padding="7px 10px 10px 10px"
    />
  );
}
