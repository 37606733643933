import {useRecoilState} from 'recoil';
import {
  defaultSecurityEventSelectionFilters,
  securityAffectedResourcesSearchState,
  securityFiltersState,
  securityFilterPopupOpenState,
  securityFilterGroupKeys,
} from 'atoms/securityState';
import ObservationPriorityIcon from 'components/common/ObservationPriorityIcon/ObservationPriorityIcon';
import {PriorityClassificationBucket} from 'openapi-schema/schemaTS';
import TableSearchWithFilters from 'components/Katana/patterns/TableSearchWithFilters/TableSearchWithFilters';

const filtersData = [
  [
    {
      category: securityFilterGroupKeys.Priority,
      groupedCategoryLabel: 'Priorities',
      filterIconFunction: (priority: string) => (
        <ObservationPriorityIcon priority={priority as PriorityClassificationBucket} />
      ),
    },
  ],
];

export default function TableTitleRow() {
  const [securityFilterOpen, setSecurityFilterOpen] = useRecoilState(securityFilterPopupOpenState);
  const [securityFilters, setSecurityFilters] = useRecoilState(securityFiltersState);
  const [affectedResourcesSearch, setAffectedResourcesSearch] = useRecoilState(
    securityAffectedResourcesSearchState
  );

  return (
    <TableSearchWithFilters
      tableTitle="Security Events"
      onSearchBarSubmit={input => {
        setAffectedResourcesSearch(input);
      }}
      menuOpen={securityFilterOpen}
      setMenuOpen={setSecurityFilterOpen}
      inputTextValue={affectedResourcesSearch}
      filtersData={filtersData}
      filterValues={securityFilters}
      setFilterValues={setSecurityFilters}
      defaultFilters={defaultSecurityEventSelectionFilters}
    />
  );
}
