import {Fingerprint, FingerprintRequest} from '../openapi-schema/fingerprintSchemaTS';

export const createFingerprintRequestObject = (
  fingerprint: Fingerprint | undefined
): FingerprintRequest => {
  const request = {
    version: 0,
    name: fingerprint?.name,
    description: fingerprint?.description,
    classification: fingerprint?.classification,
    enabled: fingerprint?.enabled,
    fingerprint_id: fingerprint?.fingerprint_id,
    service_now_category: fingerprint?.service_now_category,
    template_info: fingerprint?.template_info,
    observation_id: fingerprint?.observation_id,
    ansible_script: {
      version: 0,
      description: fingerprint?.ansible_script?.description,
      ansible_script_id: fingerprint?.ansible_script?.ansible_script_id,
      host_names: fingerprint?.ansible_script?.host_names,
      script_body: fingerprint?.ansible_script?.script_body,
      script_variables: fingerprint?.ansible_script?.script_variables,
    },
  };

  return request;
};
