import {timelineState} from 'atoms/dashboard';
import {selectedAnomalyIdState} from 'atoms/selectedAnomaly';
import useDebouncedInput from 'helpers/hooks/useDebouncedInput';
import {useQuery} from 'react-query';
import {useRecoilValue} from 'recoil';
import {
  getLogsByAnomalyId,
  getLogsByAnomalyIdCount,
  getTemplatesByAnomalyId,
  getTemplatesByAnomalyIdCount,
  getWordcloudByTemplates,
} from 'utils/PythonApiUtils';
import {LogCountQuery, LogListQuery, LogTemplateCountQuery, LogTemplateListQuery} from './types';
import config from '../utils/AppConfigurationService';
import {logTableSortingState, templateTableSortingState} from 'atoms/logTableSorting';
import {selectedWordCloudWordsState} from 'atoms/selectedWordCloudWords';

export const useSelectedLogAnomalyLogMessageList: LogListQuery = (
  pageIndex = 0,
  limit = config.apiResultLimit,
  templateId = undefined,
  templateVersion = undefined
) => {
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const sortFields = useRecoilValue(logTableSortingState);
  const offset = pageIndex * limit;
  const selectedWordCloudWords = useRecoilValue(selectedWordCloudWordsState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'LogList',
      templateId,
      templateVersion,
      debouncedStart,
      debouncedEnd,
      selectedAnomalyId,
      offset,
      limit,
      sortFields,
      selectedWordCloudWords,
    ],
    () =>
      getLogsByAnomalyId({
        anomaly_id: selectedAnomalyId,
        template_id: templateId,
        template_version: templateVersion,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        offset: offset,
        limit: limit,
        sort_fields: sortFields,
        words: selectedWordCloudWords,
      })
  );
};

export const useSelectedLogAnomalyLogMessageListCount: LogCountQuery = (
  templateId = undefined,
  templateVersion = undefined
) => {
  const selectedAnomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const selectedWordCloudWords = useRecoilValue(selectedWordCloudWordsState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    [
      'LogListCount',
      templateId,
      templateVersion,
      debouncedStart,
      debouncedEnd,
      selectedAnomalyId,
      selectedWordCloudWords,
    ],
    () =>
      getLogsByAnomalyIdCount({
        anomaly_id: selectedAnomalyId,
        template_id: templateId,
        template_version: templateVersion,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        words: selectedWordCloudWords,
      }),
    {
      keepPreviousData: true,
    }
  );
};

export const useSelectedLogAnomalyTemplateList: LogTemplateListQuery = (
  pageIndex = 0,
  limit = config.apiResultLimit
) => {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const selectedWordCloudWords = useRecoilValue(selectedWordCloudWordsState);
  const templatesTableSorting = useRecoilValue(templateTableSortingState);
  const offset = pageIndex * limit;

  return useQuery(
    [
      'templateLogList',
      anomalyId,
      debouncedStart,
      debouncedEnd,
      selectedWordCloudWords,
      offset,
      templatesTableSorting,
      limit,
    ],
    () =>
      getTemplatesByAnomalyId({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_id: anomalyId,
        words: selectedWordCloudWords,
        offset: offset,
        limit: limit,
        sort_fields: templatesTableSorting,
      })
  );
};

export const useSelectedLogAnomalyTemplateListCount: LogTemplateCountQuery = () => {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);
  const selectedWordCloudWords = useRecoilValue(selectedWordCloudWordsState);

  return useQuery(
    ['templateLogListCount', anomalyId, debouncedStart, debouncedEnd, selectedWordCloudWords],
    () =>
      getTemplatesByAnomalyIdCount({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_id: anomalyId,
        words: selectedWordCloudWords,
      }),
    {
      keepPreviousData: true,
    }
  );
};

export function useWordCloudData() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['wordCloudData', anomalyId, debouncedStart, debouncedEnd],
    () =>
      getWordcloudByTemplates({
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_id: anomalyId,
      }),
    {
      enabled: !!anomalyId,
    }
  );
}
