import {useRecoilState} from 'recoil';
import {anomalyFilterPopupOpenState} from '../../../../atoms/AnomalyFilterPopup';
import {
  anomalySelectionFiltersState,
  defaultAnomalySelectionFilters,
  filterGroupKeys,
} from '../../../../atoms/anomalySelectionFilters';
import useTopologyActions from '../../../../helpers/hooks/useTopologyActions';
import {affectedResourcesSearchState} from '../../../../atoms/affectedResourcesSearch';
import ObservationStatusIcon from 'components/common/ObservationStatusIcon/ObservationStatusIcon';
import ObservationClassIcon from 'components/common/ObservationClassIcon/ObservationClassIcon';
import {
  AnomalyClassificationBucket,
  AnomalyTypeBucket,
  PriorityClassificationBucket,
} from 'openapi-schema/schemaTS';
import ObservationTypeIcon from 'components/common/ObservationTypeIcon/ObservationTypeIcon';
import ObservationPriorityIcon from 'components/common/ObservationPriorityIcon/ObservationPriorityIcon';
import TableSearchWithFilters from 'components/Katana/patterns/TableSearchWithFilters/TableSearchWithFilters';

const filtersData = [
  [
    {
      category: filterGroupKeys.Status,
      groupedCategoryLabel: 'Statuses',
      filterIconFunction: (status: string) => <ObservationStatusIcon status={status} />,
    },
    {
      category: filterGroupKeys.Class,
      groupedCategoryLabel: 'Classes',
      filterIconFunction: (obsClass: string) => (
        <ObservationClassIcon obsClass={obsClass as AnomalyClassificationBucket} />
      ),
    },
  ],
  [
    {
      category: filterGroupKeys.Type,
      groupedCategoryLabel: 'Types',
      filterIconFunction: (type: string) => (
        <ObservationTypeIcon type={type as AnomalyTypeBucket} />
      ),
    },
  ],
  [
    {
      category: filterGroupKeys.Priority,
      groupedCategoryLabel: 'Priorities',
      filterIconFunction: (priority: string) => (
        <ObservationPriorityIcon priority={priority as PriorityClassificationBucket} />
      ),
    },
  ],
];

export default function TableTitleRow() {
  const [anomalyFilterOpen, setAnomalyFilterOpen] = useRecoilState(anomalyFilterPopupOpenState);
  const [anomalySelectionFilters, setSnomalySelectionFilters] = useRecoilState(
    anomalySelectionFiltersState
  );
  const [affectedResourcesSearch, setAffectedResourcesSearch] = useRecoilState(
    affectedResourcesSearchState
  );
  const {unselectElement} = useTopologyActions();

  return (
    <TableSearchWithFilters
      tableTitle="Browse Observations"
      onSearchBarSubmit={input => {
        unselectElement();
        setAffectedResourcesSearch(input);
      }}
      menuOpen={anomalyFilterOpen}
      setMenuOpen={setAnomalyFilterOpen}
      inputTextValue={affectedResourcesSearch}
      filtersData={filtersData}
      filterValues={anomalySelectionFilters}
      setFilterValues={setSnomalySelectionFilters}
      defaultFilters={defaultAnomalySelectionFilters}
    />
  );
}
