import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: white;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Typical = styled.div`
  min-width: 20px;
  min-height: 4px;
  background: rgba(141, 145, 146, 1);
`;

const Change = styled.div`
  min-width: 20px;
  min-height: 4px;
  background: rgba(205, 62, 56, 1);
`;

const Possible = styled.div`
  min-width: 20px;
  min-height: 2px;
  background: rgba(50, 50, 50, 1);
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 26px;
`;

export default function PathLegend() {
  return (
    <Container>
      <Row>
        <Typical />
        <Text>Typical Path</Text>
      </Row>
      <Row>
        <Possible />
        <Text>Possible Path</Text>
      </Row>
      <Row>
        <Change />
        <Text>Path Anomaly</Text>
      </Row>
    </Container>
  );
}
