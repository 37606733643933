import styled from 'styled-components';
import config from '../../../../utils/AppConfigurationService';
import Tooltip from '../../../Katana/components/Tooltip/Tooltip';
import {AnomalyTypeBucket} from '../../../../openapi-schema/schemaTS';
import ObservationTypeIcon from 'components/common/ObservationTypeIcon/ObservationTypeIcon';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  overflow: hidden;
`;

const Type = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  type: AnomalyTypeBucket;
}

export default function TypeData({type}: Props) {
  let text: string = type;
  let alternateText: string | null = null;
  if (type === config.AnomalyTypes.INTERFACE) {
    text = 'Interface';
    alternateText = type;
  }
  return (
    <Tooltip
      tooltipContent={<p>{alternateText ? alternateText : text}</p>}
      mainContent={
        <Container>
          <ObservationTypeIcon type={type} />
          <Type>{text}</Type>
        </Container>
      }
    />
  );
}
