import {useDeviceCPU, useDeviceTCAM, useDeviceUtilization} from '../../../../data/Topology';
import {DateTime} from 'luxon';
import {binnedTimestampFormat} from '../../../../constants/timeFormats';
import {useRecoilValue} from 'recoil';
import {selectedWhatIfMetricState} from '../../../../atoms/selectedWhatIfMetric';
import {
  WhatIfCPU,
  WhatIfMemory,
  WhatIfNodeMetricsKeys,
  WhatIfTCAM,
  WhatIfUtilization,
} from '../../../../constants/WhatIfMetrics';
import {selectedWhatIfStatState, WhatIfStats} from '../../../../atoms/selectedWhatIfStat';
import {useCallback, useMemo} from 'react';
import ChartSetupDefault from './ChartSetupDefault';
import ChartSetupUtilization from './ChartSetupUtilization';
import {WhatIfStatDataKeys} from '../../../../constants/WhatIfStats';
import ChartSetupTCAM from './ChartSetupTCAM';
import {SummaryNodeTcamUsageMetric} from '../../../../openapi-schema/schemaTS';
import {scaleValue} from 'components/WhatIfCalculator/TopologyWhatIf/utils/whatIfScalingFunctions';
import {whatIfSliderValueState} from '../../../../atoms/whatIfSliderValue';

const selectAndFormatData = (
  APIdata: any,
  metric: string,
  selectedWhatIfStat: WhatIfStats
): FormattedData[] => {
  const stat = WhatIfStatDataKeys[selectedWhatIfStat];

  return APIdata?.length
    ? APIdata.map((element: any) => {
        return {
          x: DateTime.fromFormat(element.bin_key, binnedTimestampFormat, {
            zone: 'UTC',
          }).toMillis(),
          y: element[metric]?.[stat] || 0,
        };
      })
    : [];
};

export interface FormattedData {
  x: number;
  y: number;
}

export default function GetWhatIfChartData() {
  const selectedWhatIfMetric = useRecoilValue(selectedWhatIfMetricState);
  const selectedWhatIfStat = useRecoilValue(selectedWhatIfStatState);
  const whatIfSliderValue = useRecoilValue(whatIfSliderValueState);
  const CpuMemoryQuery = useDeviceCPU();
  const UtilizationQuery = useDeviceUtilization();
  const TCAMQuery = useDeviceTCAM();

  const selectedQuery = useMemo(() => {
    if (selectedWhatIfMetric === WhatIfCPU || selectedWhatIfMetric === WhatIfMemory) {
      return CpuMemoryQuery;
    }
    if (selectedWhatIfMetric === WhatIfTCAM) {
      return TCAMQuery;
    }
    return UtilizationQuery;
  }, [selectedWhatIfMetric, CpuMemoryQuery, UtilizationQuery, TCAMQuery]);

  const getFormattedDataDefault = useCallback(
    (APIdata: any): FormattedData[] => {
      const metric =
        WhatIfNodeMetricsKeys[selectedWhatIfMetric as typeof WhatIfCPU | typeof WhatIfMemory];
      return selectAndFormatData(APIdata, metric, selectedWhatIfStat);
    },
    [selectedWhatIfStat, selectedWhatIfMetric]
  );

  const getFormattedUtilizationData = useCallback(
    (APIdata: any) => {
      const utilizationInData = selectAndFormatData(
        APIdata,
        'input_utilization',
        selectedWhatIfStat
      );
      const utilizationOutData = selectAndFormatData(
        APIdata,
        'output_utilization',
        selectedWhatIfStat
      );

      return {
        utilizationIn: utilizationInData,
        utilizationOut: utilizationOutData,
      };
    },
    [selectedWhatIfStat]
  );

  const getFormattedTCAMData = useCallback(
    (APIdata: any) => {
      if (!APIdata || !APIdata.length) return [];
      const metrics = APIdata[0].metrics as SummaryNodeTcamUsageMetric[];
      const data = {} as {[key: string]: FormattedData[]};

      metrics.forEach((metric, index) => {
        data[metric.resource_description] = APIdata.map((element: any) => {
          return {
            x: DateTime.fromFormat(element.bin_key, binnedTimestampFormat, {
              zone: 'UTC',
            }).toMillis(),
            y:
              scaleValue(
                element.metrics[index].numeric_summary[WhatIfStatDataKeys[selectedWhatIfStat]],
                whatIfSliderValue
              ) || 0,
          };
        });
      });

      return data;
    },
    [selectedWhatIfStat, whatIfSliderValue]
  );

  if (selectedWhatIfMetric === WhatIfUtilization) {
    return (
      <ChartSetupUtilization
        query={selectedQuery}
        actualData={getFormattedUtilizationData(selectedQuery.data)}
      />
    );
  }

  if (selectedWhatIfMetric === WhatIfTCAM) {
    return (
      <ChartSetupTCAM query={selectedQuery} actualData={getFormattedTCAMData(selectedQuery.data)} />
    );
  }

  return (
    <ChartSetupDefault
      query={selectedQuery}
      actualData={getFormattedDataDefault(selectedQuery.data)}
    />
  );
}
