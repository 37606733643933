import {isEmpty} from 'lodash';
import styled from 'styled-components';
import {VAR_MATCHING_REGEX} from '../../../../../constants/AutoRemediation';
import {EmptyLogsMessage} from '../../../../../constants/queryLifecycleMessages';
import {useFingerprintCreationLogs} from 'data/AutoRemediation';
import {AnomalyTemplate} from '../../../../../openapi-schema/schemaTS';
import {injectComponent} from '../../../../../utils/injectComponent';
import QueryLifecycleWrapper from '../../../../common/QueryLifecycleWrapper/QueryLifecycleWrapper';
import ShowAssignmentToggle from './ShowAssignmentToggle';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 10px 10px 10px;
  gap: 10px;
`;

export const TemplateLiteral = styled.div`
  word-break: break-all;
  line-height: 30px;
  font-size: 13px;

  * {
    font-family: 'Roboto Mono';
  }
`;

const TemplateContainer = styled.div``;

export const Variable = styled.span`
  background: rgba(47, 47, 47, 1);
  padding: 4px;
  border-radius: 2px;
`;

const NoVariablesText = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  color: rgba(120, 120, 120, 1);
`;

interface Props {
  template: AnomalyTemplate;
}

export default function TemplateDisplayContent({template}: Props) {
  const query = useFingerprintCreationLogs(template.template_id, template.template_version);
  const variablesArray = query?.data?.[0]?.hydrated?.elements?.filter(e => e.replaced);

  return (
    <Container>
      <TemplateContainer>
        <TemplateLiteral>
          {injectComponent(template.template_literal, VAR_MATCHING_REGEX, Variable)}
        </TemplateLiteral>
      </TemplateContainer>
      <QueryLifecycleWrapper
        query={useFingerprintCreationLogs(template.template_id, template.template_version)}
        isEmpty={isEmpty(query.data)}
        emptyMessage={EmptyLogsMessage}
        wrapWithTableRow={false}
      >
        {isEmpty(variablesArray) ? (
          <NoVariablesText>No variables available</NoVariablesText>
        ) : (
          <ShowAssignmentToggle variablesArray={variablesArray} template={template} />
        )}
      </QueryLifecycleWrapper>
    </Container>
  );
}
