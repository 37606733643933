import GoldenLayout from 'components/common/GoldenLayout/GoldenLayout';
import TopologyDetailPage from '../common/TopologyDetailPage/TopologyDetailPage';
import AssociatedAnomaliesVTC from './AssociatedAnomaliesVTC';
import AssociatedLogsVTC from './AssociatedLogsVTC';
import ServicePerformanceChart from './ServicePerformanceChart';
import VTCAnomalyTable from './VTCAnomalyTable/VTCAnomalyTable';

export default function VTCDetailDashboard() {
  return (
    <GoldenLayout
      content={[
        {
          type: 'row',
          content: [
            {
              type: 'column',
              width: 67,
              content: [
                {
                  component: () => <VTCAnomalyTable />,
                  height: 60,
                },
                {
                  component: () => <AssociatedLogsVTC />,
                  height: 40,
                },
              ],
            },
            {
              type: 'column',
              width: 33,
              content: [
                {
                  component: () => <ServicePerformanceChart />,
                  height: 30,
                },
                {
                  component: () => <AssociatedAnomaliesVTC />,
                  height: 40,
                },
                {
                  component: () => <TopologyDetailPage />,
                  height: 30,
                },
              ],
            },
          ],
        },
      ]}
      padding="7px 10px 10px 10px"
    />
  );
}
