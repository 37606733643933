import MuiCheckbox from '@mui/material/Checkbox';
import classNames from 'classnames';
import {ReactNode} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: white;
  margin-left: 10px;
  white-space: nowrap;
  text-transform: capitalize;
  user-select: none;
`;

interface Props {
  checked: boolean;
  onChange?: () => void;
  label?: string | ReactNode;
}

export default function Checkbox({checked, onChange, label}: Props) {
  return (
    <Container onClick={onChange}>
      <MuiCheckbox
        className={classNames(
          'h-[18px] w-[18px] rounded p-0',
          checked ? 'bg-white text-[rgb(120,83,203)]' : 'text-[rgb(98,98,98)]'
        )}
        checked={checked}
      />
      <Label>{label}</Label>
    </Container>
  );
}
