import {useSetRecoilState} from 'recoil';
import {timelineState, rangeTimelineState, Timeline} from '../../atoms/dashboard';
import {overviewTimelineState} from '../../atoms/overviewTimeline';
import useNewUrlTime from './useNewUrlTime';
import {useCallback} from 'react';

const useSetTimelineStates = () => {
  const setTimeline = useSetRecoilState(timelineState);
  const setOverviewTimeline = useSetRecoilState(overviewTimelineState);
  const setRangeTimeline = useSetRecoilState(rangeTimelineState);
  const setNewUrlTime = useNewUrlTime();

  return useCallback(
    (newTimeline: Timeline) => {
      if (!newTimeline.selectedInterval.isValid || !newTimeline.timelineInterval.isValid) {
        console.warn('useSetTimelineStates hook: Attempted setting timeline with invalid interval');
        return;
      }
      if (!window.location.pathname.includes('anomaly')) {
        setOverviewTimeline(newTimeline);
      }
      setTimeline(newTimeline);
      setRangeTimeline(newTimeline);
      setNewUrlTime(
        newTimeline.selectedInterval.start.toISO(),
        newTimeline.selectedInterval.end.toISO()
      );
    },
    [setNewUrlTime, setOverviewTimeline, setRangeTimeline, setTimeline]
  );
};

export default useSetTimelineStates;
