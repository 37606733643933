import {
  useSelectedAnomalyRelatedAnomaliesISIS,
  useSelectedAnomalyRelatedAnomaliesISISCount,
} from '../../../../data/ISIS';
import AssociatedAnomalies from '../../common/AssociatedAnomalies/AssociatedAnomalies';

export default function AssociatedAnomaliesISIS() {
  const anomalyListQuery = useSelectedAnomalyRelatedAnomaliesISIS;
  const anomalyCountQuery = useSelectedAnomalyRelatedAnomaliesISISCount;

  return (
    <AssociatedAnomalies
      anomalyListQuery={anomalyListQuery}
      anomalyCountQuery={anomalyCountQuery}
    />
  );
}
