import {Container, TitleRow, Title, BadgeText} from './InfoBoxStyles';

interface Props {
  anomaly_classification: string;
}

export default function Class({anomaly_classification}: Props) {
  return (
    <Container>
      <TitleRow>
        <Title>Class</Title>
      </TitleRow>
      <BadgeText style={{marginTop: '2px'}}>{anomaly_classification}</BadgeText>
    </Container>
  );
}
