import {atom} from 'recoil';
import {HydratedLogElementValue} from '../openapi-schema/schemaTS';

interface UsedAlias {
  template_id: string | undefined;
  template_version: number | undefined;
  index: number;
  example_value: HydratedLogElementValue | string | undefined;
  variable_name: string;
  variable_key: string | undefined | null;
  // TODO: deprecate key_type. Variables in the DB are no longer split between 'non-/numerical'
  key_type: 'non-numerical';
}

export interface UsedAliases {
  [key: string]: UsedAlias;
}

export const usedAliasesState = atom({
  key: 'usedAliases',
  default: {} as UsedAliases,
});
