import {useAvailableRoles, useUserList} from '../data/Admin';

const useUserManagement = () => {
  const rolesQuery = useAvailableRoles();
  const usersQuery = useUserList();

  return {
    roles: rolesQuery,
    users: usersQuery,
  };
};

export default useUserManagement;
