import {SortingState} from '@tanstack/react-table';

interface sortByAPI {
  name: string;
  desc: boolean;
}

export const convertSortToApiSchema = (
  sortingState: SortingState,
  replacements: {[key: string]: string} = {}
) => {
  const newSortBy = sortingState.map(element => {
    return {
      name: replacements[element.id] || element.id,
      desc: element.desc,
    };
  }) as sortByAPI[];

  return newSortBy;
};
