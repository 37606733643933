import {isEmpty} from 'lodash';
import {selector} from 'recoil';
import {anomalySelectionFiltersState, filterGroupKeys} from '../atoms/anomalySelectionFilters';
import config from '../utils/AppConfigurationService';

export const anomalyFilterArraysState = selector({
  key: 'anomalyFilterArrays',
  get: ({get}) => {
    const anomalySelectionFilters = get(anomalySelectionFiltersState);

    const {READ, UNREAD, STARRED} = config.Status;
    const {Type, Priority, Status, Class} = filterGroupKeys;

    const getPriorityArray = () => {
      const priorityArray = config.DefaultPriorities.filter(
        priority => anomalySelectionFilters[Priority][priority]
      );
      return isEmpty(priorityArray) ? config.DefaultPriorities : priorityArray;
    };

    const getReadFilter = () => {
      if (
        (anomalySelectionFilters[Status][READ] && anomalySelectionFilters[Status][UNREAD]) ||
        (!anomalySelectionFilters[Status][READ] && !anomalySelectionFilters[Status][UNREAD])
      ) {
        return undefined;
      }
      return anomalySelectionFilters[Status][READ];
    };

    const getClassFilter = () => {
      const classArray = config.DefaultClasses.filter(
        observationClass => anomalySelectionFilters[Class][observationClass]
      );

      return isEmpty(classArray) ? config.DefaultClasses : classArray;
    };

    const anomalyTypeFilter = Object.values(config.AnomalyTypes).filter(
      anomalyType => anomalySelectionFilters[Type][anomalyType]
    );
    const priorityFilter = getPriorityArray();
    // readFilter and starredFilter are of type boolean | null. If null, then the filter is ignored. If a boolean, the filter is applied
    const readFilter = getReadFilter();
    const starredFilter = anomalySelectionFilters[Status][STARRED] ? true : undefined;
    const classFilter = getClassFilter();

    return {
      anomalyTypeFilter,
      priorityFilter,
      readFilter,
      starredFilter,
      classFilter,
    };
  },
});
