import {useState, useEffect} from 'react';
import Cloud from './components/Cloud';
import styled from 'styled-components';
import List from './components/List';
import {useWordCloudData} from 'data/Log';
import ErrorState from '../../../Katana/components/ErrorState/ErrorState';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import NoResults from '../../../common/NoResults/NoResults';
import {useRecoilState, useRecoilValue} from 'recoil';
import {selectedWordCloudWordsState} from '../../../../atoms/selectedWordCloudWords';
import {isEmpty} from 'lodash';
import Dropdown from '../../../common/Dropdown/Dropdown';
import ComponentTitle from '../../../common/ComponentTitle/ComponentTitle';
import {UseQueryResult} from 'react-query';
import {WordcloudItemTemplates} from 'openapi-schema/schemaTS';
import {selectedAnomalyDataState} from 'atoms/selectedAnomaly';
import config from '../../../../utils/AppConfigurationService';
import {useExchangeLogWordCloudData} from 'data/ExchangeLog';

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 0 10px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Content = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  position: relative;
`;

const ClearFilter = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  padding: 6px 10px;
  margin-left: auto;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border: none;
  background: rgba(43, 43, 43, 1);
  color: white;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

const VISUAL = 'Visual';
const LIST = 'List';
const dropdownData = [VISUAL, LIST];

interface ResultsPaneProps {
  wordcloudToggle: string;
  query: () => UseQueryResult<WordcloudItemTemplates[], unknown>;
}

function ResultsPane({wordcloudToggle, query}: ResultsPaneProps) {
  const {data, isError, isLoading, refetch} = query();

  if (isError) return <ErrorState retry={refetch} />;
  if (isLoading) return <LoadingSpinner />;
  if (data?.length) {
    return (
      <>
        <Cloud data={data} />
        {wordcloudToggle === LIST && <List data={data} />}
      </>
    );
  } else return <NoResults message="No bad words in this Observation" />;
}

export default function WordCloudComponent() {
  const [wordcloudToggle, setWordCloudToggle] = useState(VISUAL);
  const [selectedWordCloudWords, setSelectedWordCloudWords] = useRecoilState(
    selectedWordCloudWordsState
  );
  const selectedAnomalyData = useRecoilValue(selectedAnomalyDataState);

  useEffect(() => {
    // Clear selected words on unmount
    return () => setSelectedWordCloudWords([]);
  }, [setSelectedWordCloudWords]);

  const handleClick = () => {
    setSelectedWordCloudWords([]);
  };

  return (
    <Container>
      <Header>
        <ComponentTitle title="Word Cloud" />
        <Dropdown
          dropdownOptions={dropdownData}
          onChange={value => setWordCloudToggle(value)}
          title={'View'}
        />
        <ClearFilter disabled={isEmpty(selectedWordCloudWords)} onClick={handleClick}>
          Clear Filter
        </ClearFilter>
      </Header>
      <Content>
        <ResultsPane
          wordcloudToggle={wordcloudToggle}
          query={
            selectedAnomalyData?.anomaly_type === config.AnomalyTypes.LOG
              ? useWordCloudData
              : useExchangeLogWordCloudData
          }
        />
      </Content>
    </Container>
  );
}
