import {useCallback} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {LocationState} from '../../../helpers/PrivateRoute';
import {useAuth} from 'react-oidc-context';
import {every} from 'lodash';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Splash from './Splash';

const getRequestedParams = (location: LocationState) => {
  const search = location?.from?.search;
  const params = new URLSearchParams(search);
  const authParams = ['state', 'session_state', 'code'];

  if (every(authParams, p => params.has(p))) {
    // Sending a redirect call with existing auth state params can result in errors, so clear them
    return '';
  } else {
    return params.toString();
  }
};
const getRequestedPath = (location: LocationState) => {
  return location?.from?.pathname || '';
};

export default function LoginPage() {
  const auth = useAuth();
  const location = useLocation();

  const handleLogin = useCallback(() => {
    const state = location.state as LocationState;
    // Store so we can return the user to their originally requested url
    localStorage.setItem('redirectPathname', getRequestedPath(state));
    localStorage.setItem('redirectSearch', getRequestedParams(state));

    return auth.signinRedirect({});
  }, [auth, location.state]);

  if (auth.isAuthenticated && !auth.error) {
    return <Navigate to={'/'} />;
  }

  return (
    <Splash>
      <div className="h-10">
        {auth.isLoading ? (
          <LoadingSpinner bgColor={'transparent'} />
        ) : (
          <button
            className="rounded-md bg-purple-500 px-4 py-2 text-14 font-medium text-white shadow-sm hover:bg-purple-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
            type="button"
            onClick={handleLogin}
          >
            Get Started
          </button>
        )}
      </div>
    </Splash>
  );
}
