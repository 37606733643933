import {useCallback, useEffect, useRef, useState} from 'react';
import IconButton from '../IconButton/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {ClickAwayListener, TextField} from '@mui/material';
import Divider from '../Divider/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import classNames from 'classnames';

const ContainerBaseClasses =
  'relative rounded-t-[28px] bg-light-surface-container-high text-light-on-surface-variant dark:bg-dark-surface-container-low dark:text-dark-on-surface-variant';
const ContainerClosedClasses = 'rounded-b-[28px] w-[350px]';
const ContainerOpenClasses = 'w-full';

export interface SearchBarMenuComponentProps {
  inputText: string;
  closeMenu: () => void;
}

interface Props {
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
  inputTextValue: string;
  onSubmit: (input: string) => void;
  MenuComponent: (props: SearchBarMenuComponentProps) => JSX.Element;
  placeholderText?: string;
}

export default function SearchBar({
  menuOpen,
  setMenuOpen,
  inputTextValue,
  onSubmit,
  MenuComponent,
  placeholderText = 'Search',
}: Props) {
  const [inputText, setInputText] = useState(inputTextValue);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const openMenu = useCallback(() => setMenuOpen(true), [setMenuOpen]);
  const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen]);

  useEffect(() => {
    return () => closeMenu();
  }, [closeMenu]);

  return (
    <div
      className={classNames(ContainerBaseClasses, {
        [ContainerClosedClasses]: !menuOpen,
        [ContainerOpenClasses]: menuOpen,
      })}
    >
      <div className="flex items-center gap-4 px-2 py-1">
        {menuOpen ? (
          <IconButton icon={<ArrowBackIcon />} onClick={closeMenu} label="Close search bar menu" />
        ) : (
          <IconButton icon={<MenuIcon />} onClick={openMenu} label="Open search bar menu" />
        )}
        <TextField
          className="[&>*]:text-inherit [&_fieldset]:border-none"
          inputProps={{
            className: 'py-3 px-0 h-6 focus:ring-0 focus:border-none text-body-large',
            onKeyDown: e => {
              if (e.key === 'Enter') {
                inputRef.current?.blur();
              }
            },
            onBlur: e => {
              if (e.target.value === inputTextValue) return;
              onSubmit(e.target.value);
            },
          }}
          inputRef={inputRef}
          onChange={e => {
            setInputText(e.target.value);
          }}
          placeholder={placeholderText}
          value={inputText}
          autoComplete="off"
          fullWidth
        />
        {inputText ? (
          <IconButton
            icon={<ClearIcon />}
            label="Clear search input"
            onClick={() => {
              setInputText('');
              onSubmit('');
            }}
          />
        ) : (
          <div className="p-2">
            <SearchIcon className="text-inherit" />
          </div>
        )}
      </div>
      {menuOpen && (
        <ClickAwayListener onClickAway={closeMenu}>
          <div className="absolute top-full z-10 w-full rounded-b-[28px] bg-inherit">
            <Divider />
            <div className="px-4 pb-3 pt-2">{MenuComponent({closeMenu, inputText})}</div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
