import {Flag, MapMarker} from 'constants/imageLinks';
import {
  defaultPathChangeEdgeAttributes,
  defaultPathChangeNodeAttributes,
} from '../../../constants/TopologyContants';
import {
  PathTraceDataEdge,
  PathTraceDataGraph,
  PathTraceDataNode,
} from '../../../openapi-schema/schemaTS';
import {getRandomColors} from '../../../utils/randomColor';

export const getEdgeProperties = (edge: PathTraceDataEdge, pathData: PathTraceDataGraph) => {
  if (pathData.typical_path_edge_ids?.includes(edge.merge_id)) {
    return {
      lineColor: 'rgba(141, 145, 146, 1)',
      lineWidth: 4,
    };
  }
  return {...defaultPathChangeEdgeAttributes};
};

export const getNodeProperties = (
  node: PathTraceDataNode,
  pathData: PathTraceDataGraph,
  startIcon: string | undefined,
  endIcon: string | undefined
) => {
  if (node.hop_ip_address === pathData.origin_ip_address) {
    return {
      ...defaultPathChangeNodeAttributes,
      ...getNodeColors(node),
      backgroundImageOpacity: 1,
      icon: startIcon || MapMarker,
    };
  }
  if (node.hop_ip_address === pathData.destination_ip_address) {
    return {
      ...defaultPathChangeNodeAttributes,
      ...getNodeColors(node),
      icon: endIcon || Flag,
      backgroundImageOpacity: 1,
    };
  }
  if (node.compressed) {
    return {
      ...defaultPathChangeNodeAttributes,
      label: `${node.compressed.start} - ${node.compressed.end}`,
      width: 50,
      height: 50,
    };
  }
  return {
    ...defaultPathChangeNodeAttributes,
    ...getNodeColors(node),
    label: node.hop_number,
  };
};

const getNodeColors = (node: PathTraceDataNode) => {
  if (node.provider) {
    return {
      borderStyle: 'solid',
      bgColor: getRandomColors({seed: node.provider}),
      borderColor: calculateBorderColor(node.provider),
    };
  }
  if (node.hop_ip_address) {
    return {
      borderStyle: 'solid',
      bgColor: 'rgba(65, 71, 74, 1)',
      borderColor: 'rgba(141, 145, 146, 1)',
    };
  }
};

export const calculateBorderColor = (provider: string) => {
  const color = getRandomColors({seed: provider, format: 'rgb'});
  const colorWithOpacity = [...color.matchAll(/\d+/g)].map(color =>
    Math.round(color[0] * 0.6 + 0.4 * 255)
  );
  const borderColor = `rgb(${colorWithOpacity[0]}, ${colorWithOpacity[1]}, ${colorWithOpacity[2]})`;
  return borderColor;
};
