import {SvgIcon, SxProps} from '@mui/material';
import {ReactNode} from 'react';

export interface IconProps {
  className?: string;
}

interface Props extends IconProps {
  sx?: SxProps;
  children: ReactNode;
}

export default function IconComponent({children, sx, className}: Props) {
  return (
    <SvgIcon inheritViewBox sx={sx} className={className}>
      {children}
    </SvgIcon>
  );
}
