import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 300px;
  height: 36px;

  .MuiSvgIcon-root {
    color: rgba(128, 128, 128, 1);
  }

  .MuiOutlinedInput-root.MuiInputBase-root {
    height: 16px;
  }

  .MuiSelect-select.MuiSelect-outlined {
    padding: 0;
    outline: none;
    border: none;
    display: flex;
  }

  fieldset {
    border: none !important;
    padding: 0 !important;
  }

  .MuiPaper-root {
    background-color: rgba(32, 34, 38, 1);
    border: 1px solid rgba(93, 93, 93, 1);

    .MuiSvgIcon-root {
      color: white;
    }
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    display: flex;
    gap: 5px;
    height: 28px;

    :hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
  }

  .MuiMenuItem-root.Mui-selected {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
`;

export const Title = styled.p`
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
`;

export const BadgeWrapper = styled.div`
  display: flex;
  background: ${props => props.color};
  padding: 2px 6px;
  border-radius: 10000px;
  align-items: center;
  gap: 5px;
  height: 20px;
  cursor: pointer;

  .MuiSvgIcon-root {
    color: white;
  }
`;

export const BadgeText = styled.p`
  font-weight: 500;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
`;

export const DropdownTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  margin-left: 16px;
  text-transform: uppercase;
  color: white;
`;

export const DropdownText = styled.p`
  font-size: 14px;
  line-height: 26px;
  color: white;
`;
