import {EventObjectNode} from 'cytoscape';
import {TopologyNodeData} from '../../../data/types';
import Tooltip from '../../Katana/components/Tooltip/Tooltip';
import {Container, GroupWrapper, Row, Header, Text, HiddenElement} from './TopologyTooltipElements';

interface Props {
  event: EventObjectNode;
}

export default function SimpleNetworkDeviceTooltip({event}: Props) {
  const node = event.target.data() as TopologyNodeData;

  return (
    <Tooltip
      tooltipContent={
        <Container>
          <GroupWrapper>
            <Row>
              <Header>Name:</Header>
              <Header>{node.name}</Header>
            </Row>
            <Row>
              <Text>Device:</Text>
              <Text>{node.device_category}</Text>
            </Row>
            <Row>
              <Text>IP:</Text>
              <Text>{node.ip}</Text>
            </Row>
            <Row>
              <Text>Status:</Text>
              <Text>{node.status}</Text>
            </Row>
          </GroupWrapper>
        </Container>
      }
      mainContent={
        <HiddenElement x={event.originalEvent.offsetX} y={event.originalEvent.offsetY} />
      }
      onCanvas={true}
      maxWidth={'none'}
    />
  );
}
