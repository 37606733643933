import DefinitionTooltip from '../DefinitionTooltip';

export default function LogTemplatesDefinition() {
  return (
    <DefinitionTooltip
      word="Log Templates"
      definition={
        <span>
          A <b>Log Template</b> is a text pattern extracted from similar logs that defines the
          structure and format of log messages. The <b>Log Template</b> can be used to parse and
          extract information from log messages, enabling easier analysis and monitoring.
        </span>
      }
    />
  );
}
