import DefinitionTooltip from '../DefinitionTooltip';

export default function TemplateVariablesDefinition() {
  return (
    <DefinitionTooltip
      word="Template Variables"
      definition={
        <span>
          <b>Template Variables</b> are extracted values from <b>Log Templates</b> used to represent
          unique data elements in <b>Observations</b>. These variables can be linked or referenced
          by variables in Ansible scripts and used in the <b>Fingerprinting</b> process to automate
          remediation tasks.
        </span>
      }
    />
  );
}
