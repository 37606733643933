import {useQuery} from 'react-query';
import {useRecoilValue} from 'recoil';
import {
  getInterfaceUtilizationKPIs,
  getLogAnomalyKPIs,
  getOverviewKPIs,
  getLatencyKPIs,
  getBGPKPIs,
  getISISKPIs,
  getFiberChannelKPIs,
  getVTCKPIs,
  getExchangeLogAnomalyKPIs,
} from '../utils/PythonApiUtils';
import {selectedDataCenterId} from '../atoms/selectedDatacenter';
import {timelineState} from '../atoms/dashboard';
import {selectedAnomalyIdState} from '../atoms/selectedAnomaly';
import {anomalyFilterArraysState} from '../selectors/anomalyFilterArrays';
import useDebouncedInput from '../helpers/hooks/useDebouncedInput';
import {tokenizedSearch} from '../selectors/tokenizedAffectedResourceSearch';

export function useLogAnomalyKPIs() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);

  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['logAnomalyKPIs', anomalyId, debouncedStart, debouncedEnd], () =>
    getLogAnomalyKPIs({
      anomaly_id: anomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
}

export function useInterfaceUtilizationKPIs() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['interfaceUtilizationPIs', anomalyId, debouncedStart, debouncedEnd], () =>
    getInterfaceUtilizationKPIs({
      anomaly_id: anomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
}

export function useLatencyKPIs() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['LatencyKPIs', anomalyId, debouncedStart, debouncedEnd], () =>
    getLatencyKPIs({
      anomaly_id: anomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
}

export function useBGPKPIs() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['BGPKPIs', anomalyId, debouncedStart, debouncedEnd], () =>
    getBGPKPIs({
      anomaly_id: anomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
}

export function useISISKPIs() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['ISISKPIs', anomalyId, debouncedStart, debouncedEnd], () =>
    getISISKPIs({
      anomaly_id: anomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
}

export function useOverviewKPIs() {
  const DC_ID = useRecoilValue(selectedDataCenterId);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const filters = useRecoilValue(anomalyFilterArraysState);
  const searchTokens = useRecoilValue(tokenizedSearch);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['overviewKPIs', DC_ID, debouncedStart, debouncedEnd, filters, searchTokens],
    () =>
      getOverviewKPIs({
        dc_id: DC_ID,
        start: start.setZone('UTC').toISO(),
        end: end.setZone('UTC').toISO(),
        anomaly_types: filters.anomalyTypeFilter,
        priority_types: filters.priorityFilter,
        read: filters.readFilter,
        starred: filters.starredFilter,
        affected_resources: searchTokens,
        classification_types: filters.classFilter,
      }),
    {
      keepPreviousData: true,
    }
  );
}

export function useIncidentKPIs() {
  const DC_ID = useRecoilValue(selectedDataCenterId);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(
    ['incidentKPIs', DC_ID, debouncedStart, debouncedEnd],
    () => {
      return {
        activeIncidents: 2,
        timeSinceLastIncidentSeconds: 41700,
        incidentsYTD: 48,
      };
    },
    {
      keepPreviousData: true,
    }
  );
}

export function useFiberChannelKPIs() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['fiberChannelKPIs', anomalyId, debouncedStart, debouncedEnd], () =>
    getFiberChannelKPIs({
      anomaly_id: anomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
}
export function useVTCKPIs() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);
  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['VTCKPIs', anomalyId, debouncedStart, debouncedEnd], () =>
    getVTCKPIs({
      anomaly_id: anomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
}

export function useExchangeLogAnomalyKPIs() {
  const anomalyId = useRecoilValue(selectedAnomalyIdState);
  const {
    selectedInterval: {start, end},
  } = useRecoilValue(timelineState);

  const debouncedStart = useDebouncedInput(start.toMillis(), 500);
  const debouncedEnd = useDebouncedInput(end.toMillis(), 500);

  return useQuery(['exchangeLogAnomalyKPIs', anomalyId, debouncedStart, debouncedEnd], () =>
    getExchangeLogAnomalyKPIs({
      anomaly_id: anomalyId,
      start: start.setZone('UTC').toISO(),
      end: end.setZone('UTC').toISO(),
    })
  );
}
