import IconComponent, {IconProps} from './IconComponent';

export default function ScriptVariableUnusedIcon({className}: IconProps) {
  return (
    <IconComponent sx={{fontSize: 16}} className={className}>
      <svg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="ScriptVariableUnusedIcon"
      >
        <path
          d="M8.00037 1.33203C7.66703 1.33203 7.3337 1.4587 7.06037 1.72536L1.72703 7.0587C1.20036 7.5787 1.20036 8.4187 1.72703 8.9387L7.06037 14.272C7.58037 14.7987 8.42036 14.7987 8.94036 14.272L14.2737 8.9387C14.8004 8.4187 14.8004 7.5787 14.2737 7.0587L8.94036 1.72536C8.66703 1.4587 8.3337 1.33203 8.00037 1.33203ZM8.00037 4.63203C9.80037 4.70536 10.5804 6.5187 9.52036 7.87203C9.24037 8.20537 8.7937 8.42536 8.56703 8.71203C8.3337 8.9987 8.3337 9.33203 8.3337 9.66537H7.3337C7.3337 9.0987 7.3337 8.62536 7.56703 8.29203C7.78703 7.9587 8.2337 7.7587 8.5137 7.5387C9.3337 6.78537 9.12037 5.72536 8.00037 5.6387C7.4537 5.6387 7.00037 6.08536 7.00037 6.64536H6.00036C6.00036 5.53203 6.90037 4.63203 8.00037 4.63203ZM7.3337 10.332H8.3337V11.332H7.3337V10.332Z"
          fill="white"
        />
      </svg>
    </IconComponent>
  );
}
