import {useRecoilState} from 'recoil';
import styled from 'styled-components';
import {selectedReportingKPIstate} from '../../../../atoms/selectedReportingKPI';
import {ButtonData} from './SectionGroup';

const Container = styled.div`
  border-radius: 2px;
  background: rgba(30, 30, 30, 1);
  height: 68px;
  cursor: pointer;
`;

interface WrapperProps {
  active: boolean;
  warning: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  border-radius: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => {
    if (props.active) return 'rgba(78, 61, 114, 1)';
    if (props.warning) return 'rgba(205, 62, 56, 0.5)';
    return 'inherit';
  }};
`;

const Number = styled.p`
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  text-transform: capitalize;
  text-align: center;
  max-width: 120px;
`;

interface Props {
  data: ButtonData;
  value: number | undefined;
}

export default function Button({data, value}: Props) {
  const [selectedReportingKPI, setSelectedReportingKPI] = useRecoilState(selectedReportingKPIstate);

  return (
    <Container onClick={() => setSelectedReportingKPI(data)}>
      <Wrapper warning={false} active={selectedReportingKPI.text === data.text}>
        <Number>{value || 0}</Number>
        <TextContainer>
          {data.icon}
          <Text>{data.text}</Text>
        </TextContainer>
      </Wrapper>
    </Container>
  );
}
