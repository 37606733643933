import {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {useRecoilValue} from 'recoil';
import Name from './components/Name';
import Priority from './components/Priority';
import Category from './components/Category';
import SerivceNowTicket from './components/ServiceNowTicket';
import {selectedTimezoneState} from '../../../../atoms/selectedTimezone';
import {DateTime} from 'luxon';
import {dateFull} from '../../../../constants/timeFormats';
import ServiceNowStatus from './components/ServiceNowStatus';
import {isEmpty} from 'lodash';
import OptionsDropdown from './components/OptionsDropdown';
import useSaveFeedback from '../../../../helpers/hooks/useSaveFeedback';
import Class from './components/Class';
import {LogAnomalyExplanatoryReasons} from '../../../../openapi-schema/schemaTS';
import ERIndicator from './components/ERIndicator';
import config from '../../../../utils/AppConfigurationService';
import DetailViewStar from './components/DetailViewStar';
import {NonNullHotlAnomalyResponse} from 'data/types';

const Container = styled.div`
  width: 100%;
  background: rgba(34, 36, 41, 1);
  padding: 5px 10px 0 10px;
  display: flex;
  align-items: start;
  color: white;
`;

const HumanInLoopInputs = styled.div`
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 30px;
  margin-right: 10px;
`;

interface Props {
  selectedAnomaly: NonNullHotlAnomalyResponse;
}

export default function DetailViewHeader({selectedAnomaly}: Props) {
  const isRead = useRef(selectedAnomaly.feedback?.read);
  const anomalyId = selectedAnomaly.anomaly_id;

  const selectedTimezone = useRecoilValue(selectedTimezoneState);
  const initialName =
    selectedAnomaly.feedback?.name ||
    `${selectedAnomaly.anomaly_type} Observation: ${DateTime.fromISO(selectedAnomaly.min_timestamp)
      .setZone(selectedTimezone)
      .toFormat(dateFull)}`;
  const category = selectedAnomaly.category || selectedAnomaly.system_category;
  const priorityBucket =
    selectedAnomaly.priority_classification || selectedAnomaly.system_priority_classification;
  const starred = selectedAnomaly.feedback?.starred || false;
  const serviceNowLink = selectedAnomaly.feedback?.service_now_link || '';
  const saveFeedback = useSaveFeedback();

  useEffect(() => {
    if (!isRead.current) {
      saveFeedback({anomaly_id: anomalyId, read: true}, false);
    }
  }, [anomalyId, saveFeedback]);

  return (
    <Container>
      <DetailViewStar starred={starred} anomalyId={anomalyId} />
      {selectedAnomaly.anomaly_type === config.AnomalyTypes.LOG && (
        <ERIndicator
          explanatory_reasons={selectedAnomaly.explanatory_reasons as LogAnomalyExplanatoryReasons}
        />
      )}
      <HumanInLoopInputs>
        <Name name={initialName} anomalyId={anomalyId} />
        <Class anomaly_classification={selectedAnomaly.anomaly_classification} />
        <Category initialCategory={category} anomalyId={anomalyId} />
        <Priority initialPriorityBucket={priorityBucket} anomalyId={anomalyId} />
        <SerivceNowTicket initialServiceNowLink={serviceNowLink} anomalyId={anomalyId} />
        {!isEmpty(selectedAnomaly.feedback) && (
          <ServiceNowStatus serviceNowStatus={selectedAnomaly.feedback?.service_now_status} />
        )}
      </HumanInLoopInputs>
      <OptionsDropdown selectedAnomaly={selectedAnomaly} />
    </Container>
  );
}
